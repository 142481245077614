<template>
    <nestable-modal modal-name="collectionAdvSettings" size="md collectionAdvSettingsModal" class="" @shown="isOpen = true" @closed="isOpen = false">
        <template v-slot:header><h5>Collection Advanced Settings</h5></template>
        <collection-fallback-image v-if="false" :collection-id="collectionId" ref="image"></collection-fallback-image>
        <content-policy v-if="isOpen" :collection-id="collectionId" ref="policy"></content-policy>

        <content-index v-if="isOpen && accountFeatures.advCollectionIndexSettings" :collection-id="collectionId" ref="indexingSettings"></content-index>&nbsp;

        <template>
            <div class="buttons">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary ms-2"
                        @click="save">
                    Save Advanced Settings
                </button>
            </div>
        </template>&nbsp;

    </nestable-modal>
</template>

<script>
import ContentPolicy from "../ContentPolicy";
import ContentIndex from "../ContentIndex";
import NestableModal from "@/components/Controls/NestableModal"
import {mapActions, mapGetters} from "vuex";
import CollectionFallbackImage from "./CollectionFallbackImage";
export default {
    name: "CollectionAdvancedSettingsModal",
    components: {CollectionFallbackImage, ContentIndex, ContentPolicy, NestableModal},
    data() {
        return {
            collectionId: null,
            isOpen: false,
        }
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            accountFeatures: 'accountFeatures'
        })
    },
    mounted() {
        window.Events.$on('show_collectionAdvSettings', data => {
            console.log('show_collectionAdvSettings', data)
            this.collectionId = data && data.collectionId || this.currentCollection && this.currentCollection.id;
        });
    },
    methods: {
        ...mapActions({
            loadAdvancedSettings: 'loadAdvancedSettings'
        }),

        async save() {
            if(this.$refs.policy) await this.$refs.policy.save();
            if(this.$refs.image) await this.$refs.image.save();
            if(this.$refs.indexingSettings) await this.$refs.indexingSettings.save();
            this.loadAdvancedSettings();
            window.Events.$emit('close_collectionAdvSettings');
        }
    }
}
</script>

<style scoped>

</style>
