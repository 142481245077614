<template>
    <button  v-if="features.editFilterViewPageAddEdit" type="button" class="text-nowrap" @click="edit">
        <i class="fa fa-edit"/> Edit
    </button>
</template>

<script>
import features from "@/Features";

export default {
    name: "EditFiltersButton",
    computed: {
        features() {
            return features
        }
    },
    props: {
        filters: {},
        hasContentType: {},
        hasContentId: {},
        editSelectedTarget: {default: null}
    },
    methods: {
        edit() {
            window.Events.$once('save_filterViewEdit', result => {
                this.selected = result;
                console.log('EditFiltersButton.saved')
                this.$emit('saved', result)
            })
            window.Events.$emit('show_filterViewEdit', {
                target: this.editSelectedTarget,
                value: this.filters.id,
                hasContentId: this.hasContentId,
                hasContentType: this.hasContentType
            })
        }
    }
}
</script>

<style scoped>

</style>
