<template>
    <div>
        <label class="">
            Background Color
            <color-picker v-model="backgroundColor"></color-picker>
        </label>
        <!--<label class="">-->
            <!--Border Color-->
            <!--<color-picker v-model="borderColor"></color-picker>-->
        <!--</label>-->

        <fieldset>
            <legend>Padding (px)</legend>
            <padding v-model="padding"></padding>
        </fieldset>
        <fieldset>
            <legend>Margin (px)</legend>
            <padding v-model="margin"></padding>
        </fieldset>
        <!--<label class="d-block">-->
            <!--Rounded Corners Radius (px)-->
            <!--<input type="range" min="0" max="25" step="1" v-model="cornerRoundRadius" class="form-control" >-->
            <!--<input type="number" min="0" max="25" step="1" v-model="cornerRoundRadius" class="form-control form-control-sm" >-->
        <!--</label>-->
        <!--<label class="d-block">-->
            <!--Shadow-->
            <!--<select v-model="shadow" class="form-control form-control-sm" >-->
                <!--<option>None</option>-->
                <!--<option v-for="o in shadowOptions" :key="o">{{o}}</option>-->
                <!--&lt;!&ndash;<option>3px, Dark</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>5px, Dark</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>10px, Dark</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>3px, Light</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>5px, Light</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>10px, Light</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>3px, White</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>5px, White</option>&ndash;&gt;-->
                <!--&lt;!&ndash;<option>10px, White</option>&ndash;&gt;-->
            <!--</select>-->
        <!--</label>-->
    </div>
</template>

<style scoped>
    legend {
        border-bottom: 1px solid var(--bs-default);
        font-size: 110%
    }
    fieldset {
        margin-bottom: 1.5em;
        margin-top: .75em;
    }
</style>

<script>
    import ColorPicker from "@/components/Controls/ColorPicker"
    import {Toggle, ToggleItem} from "@/components/Controls/Toggle"
    import {shadowSizes, shadowColors} from "@/models/themes";
    import defaultTextStyle from "@/services/DefaultTextStyle";
    import Padding from "@/components/Galleries/Padding";
    export default {
        props: ['value'],
        components: {Padding, ColorPicker, Toggle, ToggleItem},

        mounted() {
            if (this.value)
                Object.assign(this, this.value);
        },
        data() {
            return {
                size: 300,
                cornerRoundRadius: 0,
                shadow: 'None',
                backgroundColor: '',
                borderColor: '',
                ...defaultTextStyle(),
                ...this.value
            }
        },
        watch: {
            value() {
                this.inline = undefined;
                Object.assign(this, {...defaultTextStyle(), ...this.value})
            },
            backgroundColor() {
                this.$emit('input', {...this.value, backgroundColor: this.backgroundColor})
            },
            hidden() {
                this.$emit('input', {...this.value, hidden: this.hidden})
            },
            inline() {
                this.$emit('input', {...this.value, inline: this.inline})
            },
            padding() {
                this.$emit('input', {...this.value, padding: this.padding})
            },
            margin() {
                this.$emit('input', {...this.value, margin: this.margin})
            },

            cornerRoundRadius() {this.$emit('input', {...this.value, cornerRoundRadius: this.cornerRoundRadius})},
            shadow() {this.$emit('input', {...this.value, shadow: this.shadow})},
            borderColor() {this.$emit('input', {...this.value, borderColor: this.borderColor})},
        },
        computed: {
            shadowOptions() {
                return shadowColors.flatMap(c =>
                    shadowSizes.map(s =>
                        `${s}px, ${c.description}`
                    )
                )
            }
        },
    }
</script>
