import * as types from '../mutation-types'

const state = {
    allNotes: [],
    showAccountNotesReader : false,
    isDownloadingNotes: false
}

const getters = {
   notes : state => state.allNotes,
   showAccountNotesReader : state => state.showAccountNotesReader,
   isDownloadingNotes : state => state.isDownloadingNotes,
}

const actions = {
    fetchAccountNotes: async ({commit, getters, state, rootState, rootGetters}, {accountId}) => {
        let [account, user] = [rootGetters.account, rootGetters.currentUser];
        let accountMeta = {
            plan: account.plan,
            user_email: user.email,
            account_id: account.id,
            account_name: account.name,
            account_created: account.created
        }
    }
}

const mutations = {
    [types.TOGGLE_ACCOUNT_NOTES_READER](state){
        state.showAccountNotesReader = ! state.showAccountNotesReader
    },
    [types.ADD_NOTE](state, { note }){
        let existing = state.allNotes.find(n => n.id == note.id);
        if ( ! existing){
            state.allNotes.push(note)
        } else {
            let index = state.allNotes.indexOf(existing);
            state.allNotes.splice(index, 1, note);
        }
    },
    [types.DELETE_NOTE](state, {note}){
        state.allNotes = state.allNotes.filter(n => n.id !== note.id);
    },
    [types.USER_DID_LOG_OUT](state){
        state.allNotes = [];
        state.showAccountNotesReader = false;
    },
    [types.BEGIN_DOWNLOADING_NOTES](state) {
        state.isDownloadingNotes = true;
    },
    [types.COMPLETE_DOWNLOADING_NOTES](state) {
        state.isDownloadingNotes = false;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}