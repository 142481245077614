function log(...args) {
    // $loggy.log('name', variable, variable, variable...)
    // OR
    // $loggy.log(variable, variable, variable
    if (args.length === 0) {
        console.log(`%c>_ ‥…━━━━━━━☆☆━━━━━━━━━━━━━━☆☆━━━━━━━━━━━━━━☆☆━━━━━━━…‥・`, 'color:#a0d2ff');
        return;
    }
    if (args.length === 1) {
        let result = '';
        switch (typeof args[0]) {
            case 'string':
                result = 'str';
                break;
            case 'number':
                result = (Number.isInteger(args[0]) ? 'int' : 'num');
                break;
            case 'boolean':
                result = 'bln';
                break;
        }
        if(result !== '') {
            console.log(`%c>_ `, 'color:#ffb86c', result, args[0]);
            return;
        }
    }
    const isOdd = args.length > 1 && (args.length & 1)

    // Assume the first value is a name and display it next to the >_
    const name = isOdd || (args.length === 2 && typeof args[0] === 'string') ? `${args[0]}` : '';
    const arg = isOdd ? args.slice(1) : args;

    let log = [`%c>_ ${name}`, 'color:#ffb86c'];

    arg.every((v, i) => {
        if ((i & 1)) {
            return true;
        }

        let obj = {};
        let prefix = '';

        switch (typeof arg[i + 1]) {
            case 'object':
                prefix = Array.isArray(arg[i + 1]) ? 'arr' : 'obj';
                break;
            case 'string':
                prefix = 'str';
                break;
            case 'number':
                prefix = Number.isInteger(arg[i + 1]) ? 'int' : 'num';
                break;
            case 'boolean':
                prefix = 'bln';
                break;
            default:
                prefix = typeof v;
        }

        obj[prefix + "_" + v] = arg[i + 1];

        log = [...log, obj];
        return true;
    });
    console.log(...log);
}

export default {
    install: (Vue) => {
        Vue.prototype.$loggy = {
            log
        };
    }
}
