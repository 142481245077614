<!-- Forked from: https://github.com/euvl/vue-js-toggle-button#readme-->
<template>
    <span role="checkbox" @click.stop="toggle" tabindex="0"
           :class="className"
          :style="{borderRadius: `${(Math.round(height / 2))}px`}"
           :aria-checked="ariaChecked">
        <input type="checkbox"
               class="v-switch-input"
               @change.stop="toggle">
        <div class="v-switch-core"
             :style="coreStyle">
            <div class="v-switch-button"
                 :style="buttonStyle"/>
        </div>
        <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: no user entered data-->
        <template v-if="labels">
            <span class="v-switch-label v-left"
                  :style="labelStyle"
                  v-if="toggled"
                  aria-hidden="true"
                  v-text="labelChecked"/>
            <span class="v-switch-label v-right"
                  :style="labelStyle"
                  v-else
                  aria-hidden="true"
                  v-text="labelUnchecked"/>
        </template>
    </span>
</template>

<script>
    const constants = {
        colorChecked: 'var(--success)',
        colorUnchecked: 'var(--steel-50)',
        cssColors: false,
        labelChecked: 'on',
        labelUnchecked: 'off',
        width: 50,
        height: 22,
        margin: 3,
        switchColor: '#fff'
    }

    const contains = (object, title) => {
        return typeof object === 'object' && object.hasOwnProperty(title)
    }

    const px = v => v + 'px'

    export default {
        name: 'ToggleButton',
        props: {
            value: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            sync: {
                type: Boolean,
                default: true
            },
            speed: {
                type: Number,
                default: 300
            },
            color: {
                type: [String, Object],
                validator (value) {
                    return typeof value === 'object'
                        ? (value.checked || value.unchecked)
                        : typeof value === 'string'
                }
            },
            switchColor: {
                type: [String, Object],
                validator (value) {
                    return typeof value === 'object'
                        ? (value.checked || value.unchecked)
                        : typeof value === 'string'
                }
            },
            cssColors: {
                type: Boolean,
                default: false
            },
            labels: {
                type: [Boolean, Object],
                default: true,
                validator (value) {
                    return typeof value === 'object'
                        ? (value.checked || value.unchecked)
                        : typeof value === 'boolean'
                }
            },
            height: {
                type: Number,
                default: constants.height
            },
            width: {
                type: Number,
                default: constants.width
            }
        },
        computed: {
            className () {
                let { toggled, disabled } = this

                return ['vue-js-switch', { toggled, disabled }]
            },

            ariaChecked () {
                return this.toggled.toString()
            },

            coreStyle () {
                return {
                    width: px(this.width),
                    height: px(this.height),
                    backgroundColor: this.cssColors ? null : this.colorCurrent,
                    borderRadius: px(Math.round(this.height / 2))
                }
            },

            buttonRadius () {
                return this.height - constants.margin * 2;
            },

            distance () {
                return px(this.width - this.height + constants.margin)
            },

            buttonStyle () {
                return {
                    width: px(this.buttonRadius),
                    height: px(this.buttonRadius),
                    transition: `transform ${this.speed}ms`,
                    transform: this.toggled
                        ? `translate3d(${this.distance}, 3px, 0px)`
                        : null,
                    background: this.switchColor ? this.switchColorCurrent : undefined
                }
            },

            labelStyle () {
                return {
                    lineHeight: px(this.height)
                }
            },

            colorChecked () {
                let { color } = this

                if (typeof color !== 'object') {
                    return color || constants.colorChecked
                }

                return contains(color, 'checked')
                    ? color.checked
                    : constants.colorChecked
            },

            colorUnchecked () {
                let { color } = this

                return contains(color, 'unchecked')
                    ? color.unchecked
                    : constants.colorUnchecked
            },

            colorCurrent () {
                return this.toggled
                    ? this.colorChecked
                    : this.colorUnchecked
            },

            labelChecked () {
                return contains(this.labels, 'checked')
                    ? this.labels.checked
                    : constants.labelChecked
            },

            labelUnchecked () {
                return contains(this.labels, 'unchecked')
                    ? this.labels.unchecked
                    : constants.labelUnchecked
            },

            switchColorChecked () {
                let { switchColor } = this

                return contains(switchColor, 'checked')
                    ? switchColor.checked
                    : constants.switchColor
            },

            switchColorUnchecked () {
                let { switchColor } = this

                return contains(switchColor, 'unchecked')
                    ? switchColor.unchecked
                    : constants.switchColor
            },

            switchColorCurrent () {
                let { switchColor } = this

                if (typeof switchColor !== 'object') {
                    return switchColor || constants.switchColor
                }

                return this.toggled
                    ? this.switchColorChecked
                    : this.switchColorUnchecked
            }

        },
        watch: {
            value (value) {
                if (this.sync) {
                    this.toggled = !!value
                }
            }
        },
        data () {
            return {
                toggled: !!this.value
            }
        },
        methods: {
            toggle (event) {
                this.toggled = !this.toggled
                this.$emit('input', this.toggled)
                this.$emit('change', {
                    value: this.toggled,
                    srcEvent: event
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    $margin: 3px;

    .vue-js-switch:focus .v-switch-core {
    }

    .vue-js-switch:focus {
        outline: none;
        box-shadow: 0 0 5px 2px var(--bs-success);
    }

    .vue-js-switch {
        display: inline-block;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        user-select: none;
        font-size: 10px;
        cursor: pointer;

        .v-switch-input {
            display: none;
        }

        .v-switch-label {
            position: absolute;
            top: 0;
            font-weight: var(--font-weight-semibold);
            color: white;

            &.v-left {
                left: 10px;
            }

            &.v-right {
                right: 10px;
            }
        }

        .v-switch-core {
            display: block;
            position: relative;
            box-sizing: border-box;

            outline: 0;
            margin: 0;

            transition: border-color .3s, background-color .3s;
            user-select: none;

            .v-switch-button {
                display: block;
                position: absolute;
                overflow: hidden;

                top: 0;
                left: 0;

                transform: translate3d($margin, $margin, 0);
                border-radius: 100%;
                background-color: #fff;
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.6;
        }
    }
</style>
