<script>
import { mapGetters } from 'vuex';
export default {
    props : ['id'],
    computed : {
        ...mapGetters({
            accounts : 'allAccounts'
        })
    },
    mounted() {
        this.setAccount();
    },
    methods : {
        async setAccount(){
            console.log('* SetFromRoute.vue setAccount this.accounts', this.accounts)
            let account = this.accounts.find(a => a.id === this.id);
            console.log('* SetFromRoute.vue setAccount account', account)
            if (account){
                console.log(`* SetFromRoute entrypoint about to dispatch('changeAccount') with:`, account)
                await this.$store.dispatch('changeAccount', { overview: account })
                this.$router.push('/')
            }
        }
    },
    render(h){
        return h();
    }
}
</script>

<style>
</style>
