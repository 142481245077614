<template>
    <div class="mb-3">
        <div class="row">
            <div class="col d-flex">
                <label class="label-calm" :for="$refs[`selection-${fieldTitle}`]">
                    <span>{{ fieldName }}</span>
                    <span v-if="isOptional" title="Field is optional" class="text-lowercase fw-light bottom-1">
                        (optional)</span>
                </label>

            </div>
            <div class="col-4 col-sm-3 text-end d-flex justify-content-end">
                <template v-if="!isEditEnabled()">
                    <button type="button"
                            v-if="canReset()"
                            class="ui-link font-size-13 text-nowrap"
                            @click="resetToOriginal()"
                            :aria-label="`Reset ${fieldName} to the original ${fieldName} value.`"
                            :title="`Reset to '${originalValue}'`"
                    >
                        <i class="fa fa-undo me-1" aria-hidden="true"></i>
                        <span class="font-size-12">Reset</span>
                    </button>
                    <button type="button"
                            v-if="canEdit"
                            class="ui-link font-size-13 text-nowrap"
                            @click="toggleArticleEdit(true)" :aria-label="`Edit ${fieldName}`">
                        <i class="fa fa-edit me-1 start-1" aria-hidden="true"></i>
                        <span class="font-size-12">Edit</span>
                    </button>
                </template>
                <template  v-if="isEditEnabled()">
                    <button type="button"
                            class="ui-link font-size-13 text-nowrap"
                            @click="toggleArticleEdit(false)" v-if="!autoEditMode">
                        <i class="fa fa-window-close me-1"></i>
                        <span class="font-size-12 bottom-1">Cancel</span>
                    </button>
<!--                    <button type="button"-->
<!--                            class="ui-link font-size-13 text-nowrap ms-3"-->
<!--                            @click="save" v-if="!autoEditMode">-->
<!--                        <i class="fa fa-save me-1 bottom-1"></i>-->
<!--                        <span class="font-size-12 bottom-1">Save</span>-->
<!--                    </button>-->
                </template>
            </div>
        </div>
        <div class="row">
            <div class="col-12" :class="{hide: isEditEnabled()}">
                <div class=" editable-field font-size-12">
                    <span v-if="!$slots.display">
                        <span v-text="displayValue"></span>
                        <span v-if="!displayValue">&nbsp;</span>
                    </span>
                    <slot name="display"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 form-control -input-sizing font-size-12" :class="{hide: !isEditEnabled()}">
                <slot
                      name="default"
                      :ref="`selection-${fieldTitle}`"
                      :readonly="isReadOnlyEnabled()"
                      :value="value">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "EditableReadonlyField",
    props: {
        fieldTitle: {type: String, required: true},
        fieldName: {type: String, required: true},
        originalValue: {type: String, default: null}, // Optional, see canReset()
        initialValue: {type: String, default: null},
        autoEditMode: {type: Boolean, default: false},
        displayAltValue: {type: String, default: null},
        isOptional: {type: Boolean, default: false},
        canEdit: {type: Boolean, default: true},
        value: {default: null},
    },
    data() {
        return {
            isEditing: false,
            isReadOnly: false,
            fieldValue: null,
        }
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            topic: 'currentTopic',
        }),
        displayValue() {
            // Display an alternative value if one is provided, for stuff like the icons.

            let displayValue = '';
            if(typeof this.value === 'string') {
                return this.value
            }
            if(typeof this.displayAltValue === 'string') {
                return this.displayAltValue
            }

            // But I'm intentionally passing HTML into this...
            // display = this.$strings.escapeHtml(displayValue);
            // Handle when the value is empty to prevent text input from self-collapsing.
            // let display = display + '' !== '' ? display : '&nbsp;';
            // return display;
        },
    },
    watch: {
        currentCollection() {
            this.toggleArticleEdit(false);
        },
        currentTopic() {
            this.toggleArticleEdit(false);
        },
    },
    methods: {
        isEditEnabled() {
            return (this.autoEditMode || this.isEditing)
        },
        isReadOnlyEnabled() {
            return (this.isReadOnly)
        },
        canReset() {
            // Info on Cancel vs Reset:
            // Original value is used with article editing.
            // Imagine an article with a title: 'alpha'.
            // Now edit and change that title to: 'beta'. Save changes.
            // Now open up the edit article panel again.
            // You will see the title is 'beta'.
            // If you click the reset option, it will change to 'alpha'.
            // If instead of clicking to reset to 'alpha', instead click edit.
            // Change the title to 'charlie'.  Don't save.
            // If you click cancel, the title will revert to 'beta'.
            // Original = the first value captured by the product.
            // Initial = the current value stored by the product.
            // Reset > Change to Original.  Cancel > Change to Initial.
            // Yeet.
            let og = this.originalValue;

            if (og && ((typeof og == 'string' && og !== '') || og != null) && og !== this.initialValue) {
                return true;
            }
            return false;
        },
        resetToOriginal() {
            this.$data.fieldValue = this.originalValue;
            this.isReadOnly = true;
            this.toggleArticleEdit(true);
        },
        toggleArticleEdit(editing) {
            this.isEditing = editing;

            if (!editing) {
                this.$data.fieldValue = this.initialValue;
                this.isReadOnly = false;
                return;
            }

            if (this.isReadOnly) {
                return;
            }

            setTimeout(() => {
                if (this.$data.fieldValue
                    && this.$refs[`selection-${this.fieldTitle}`]
                    && this.$refs[`selection-${this.fieldTitle}`].firstElementChild) {
                    this.$refs[`selection-${this.fieldTitle}`].firstElementChild.focus()
                }
            }, 75);
        },
        save() {
            this.$emit('save', () => {
                this.toggleArticleEdit(false);
            })
        },
    }
}
</script>

<style scoped type="text/css">
div.-input-sizing, button.-input-sizing {
    border-color: transparent;
}

.-label-height {
    min-height: 2rem;
}

.btn.btn-outline-dark:hover {
    background: var(--bs-light);
    color: var(--bs-dark)
}

.-input-sizing input {
    font-size: var(--font-size-12);
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    padding: .375rem .75rem;
    line-height: 1.5;
}

.-summary {
    min-height: 20ex;
}

.-title {
    min-height: 11ex;
}

.fw-semi {
    font-weight: 500;
}

.-date-input {
    /* width: 13em !important; */
}
</style>
