<template>
    <integration-item :key="integration.name" :title="integration.title" :icon="integration.name" :class="{hide: !metaIntegrationsEnabled || !integrationEnabled(integration.name)}">
        <label v-if="usage" class="float-end integration-usage text-dark">
            Usage:
            <output class="fw-normal">{{usage.used}}/{{usage.limit}}</output>
        </label>
        <connect-disconnect-button v-if="showConnectButton" :service="OAuth(integration.name)"  :team="team"></connect-disconnect-button>
        <button type="button" class="btn btn-success btn-sm" v-if="showContactButton" @click="$intercom.showNewMessage(`I'd like to purchase a ${integration.title} subscription`)">Purchase/Contact</button>
        <div class="integration-features" v-if="isConnected(integration)">
            <label class="text-muted small text me-2" title="Features are enabled based on which features you have in the integrated app">
                <a onclick="alert('Features are enabled based on which features you have in the integrated app')">
                    Available Features <span class="fa fa-info-circle text-muted"></span>:
                </a>
            </label>
            <div class="integration-feature-tags">
                    <span :class="classes(['tag', 'integration-feature-tag', 'small', 'ms-1'], { inactive: !f.active, inuse: f.inUse, })" v-for="f in integrationFeatures(integration)" :key="f.name"
                          :title="f.active ? 'We have access to this feature in the integrated app' : 'You don\'t have access to this feature in the integrated app or you may need to reauthorize to give access'">
                        <i :class="f.icon" class="me-1"></i>
                        <span class="feature-title me-1">{{f.scopedTitle}}</span>
                        <span v-if="f.inUse" class="me-1">(In Use)</span>
                        <span v-else-if="f.active && f.isCollection"  class="me-1">(Authorized, <button type="button" class="ui-link" @click="showNewCollection">Create Collection</button>)</span>
                        <span v-else-if="f.active"  class="me-1">(Authorized, <button type="button" class="ui-link" @click="makeDefaultShare(integration)">Set as Default</button>)</span>
                        <span v-else  class="me-1">(Not Authorized, <button type="button" class="ui-link" @click="authorize(integration)">Authorize</button>)</span>
                    </span>
            </div>
        </div>
    </integration-item>
</template>

<style scoped lang="scss">
    .integration-usage {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        margin: 15px 0;
    }

    .integration-feature-tag.inactive a{
    }

    .inuse {
        border-color: var(--bs-primary);
        color: var(--bs-primary);
    }

</style>

<script>
    import IntegrationItem from './IntegrationItem'
    import ConnectDisconnectButton from './ConnectDisconnectButton'
    import UcSelect from '@/components/Controls/UpcontentSelect'
    import IntegrationLoader from '@/components/DataManagement/IntegrationLoader'
    import {mapGetters} from 'vuex'
    import {featuresForAccount as _featuresForAccount} from '@/store/modules/accounts'

    export default {
        name: 'MetaIntegrationItem',
        props : ['integration', 'team', 'usage'],
        mixins: [IntegrationLoader],
        components: {IntegrationItem, ConnectDisconnectButton, UcSelect},
        data() {
            return {
                account: {...this.team},
            }
        },
        methods: {
            classes(always, sometimes) {
                return [...always, Object.keys(sometimes).filter(c => sometimes[c])]
            },
        },
        computed:{
            ...mapGetters({
                accountFeatures: 'accountFeatures',
                subscriptions: 'subscriptions', features: 'accountFeatures', currentAccountFeatureData: 'currentAccountFeatureData'
            }),
            accountData() {
               return this.apis[this.integration.name] && this.apis[this.integration.name][this.account.id]
            },
            showConnectButton() {
                return !this.accountUsesGlobal && !this.forceGlobal && this.integration && this.featuresForAccount[this.integration.name];
            },
            showContactButton() {
                return (!this.accountUsesGlobal && this.integrationHasGlobal) || (this.integration && !this.featuresForAccount[this.integration.name]);
            },
            accountUsesGlobal() {
                return this.accountData && this.accountData['can_access_global_auth'];
            },
            integrationHasGlobal() {
                return this.integration['has_global_account'];
            },
            forceGlobal() {
                return this.integration['force_global_account']
            },
            featuresForAccount() {
                return _featuresForAccount(this.subscriptions, this.features, this.currentAccountFeatureData)(this.account)
            }
        },
        watch : {
            team(){
                this.account = {...this.team}
            },
            integration() {
                console.log('integration loaded', this.integration.name, this.integration)
            }
        },

    }
</script>
