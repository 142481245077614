<template>
    <div v-if="loaded" class="small" style="overflow: hidden;">
        <h5>Plan Features</h5>
        <ul class="list-inline no-bullets d-block d-flex flex-wrap">
            <li v-for="r in flat" :key="r.name" style="min-width: 12em;" class="mb-2">
                <label  class="d-inline fw-semibold text-dark">
                    {{r.description}}:
                    <output class="fw-normal">{{r.used}}/{{r.limit}}</output>
                </label>
            </li>
        </ul>
        <h5>{{period}}ly Addons ({{periodStartDisplay}} - {{periodEndDisplay}})</h5>
        <ul class="list-inline no-bullets d-flex flex-wrap">
            <li v-for="r in perPeriod" :key="r.name" style="min-width: 12em;" class="mb-2">
                <label  class="d-inline fw-semibold  text-dark">
                    {{r.description}}:
                    <output class="fw-normal">{{r.used}}/{{r.limit}}</output>
                </label>
            </li>
        </ul>
    </div>
</template>

<style>

</style>

<script>
    export default {
        name: "SubscriptionUsage",
        props: { value: {} },
        computed: {
            loaded() { return Boolean(this.resources.length); },
            perPeriod() { return this.resources.filter(r => r.perPeriod)},
            flat() { return this.resources.filter(r => !r.perPeriod)},
        },
        data() {
            return {
                // loaded: Boolean(this.value),
                resources: [],
                currentPeriodStart: null,
                currentPeriodEnd: null,
                periodStartDisplay: '',
                periodEndDisplay: '',
                period: 'Month',
                ...(this.value||{})
            }
        },
        watch: {
            value() {
                Object.assign(this, this.value)
            }
        }
    }
</script>
