<template>
    <div class="row">
        <label class="col-12">
            Domain <span class="text-muted small">(Required)</span>
            <input type="text" pattern="(?:[\w](?:[\w-]{0,61}[\w])?\.)+[a-z0-9][\w-]{0,61}[\w]" required v-model="domain" placeholder="Ex: example.com" class="form-control mb-2" title="Must be alphanumeric, underscores, dashes, and periods only" />
        </label>
        <label class="col-12">
            Staging Domain <span class="text-muted small">(Optional)</span>
            <input type="text" pattern="(?:[\w](?:[\w-]{0,61}[\w])?\.)+[\w][\w-]{0,61}[\w]" v-model="staging_domain" placeholder="Ex: staging.example.com" class="form-control mb-2" title="Must be alphanumeric, underscores, dashes, and periods only" />
        </label>
        <label class="col-12">
            Description <span class="text-muted small">(Optional)</span>
            <input type="text" v-model="description" placeholder="This is just for you to keep track of which site this is on" class="form-control mb-2" />
        </label>
    </div>
</template>

<style>

</style>

<script>
    import CopyTheme from "@/components/Galleries/ThemeCopy";
    export default {
        components: {CopyTheme},
        props: {
            value: Object
        },
        data() {
            return {
                domain: '',
                staging_domain: '',
                description: '',
                ...this.value
            }
        },
        watch: {
            value() { Object.assign(this, this.value)},
            domain() { this.$emit('input', {...this.value, domain: this.domain})},
            staging_domain() { this.$emit('input', {...this.value, staging_domain: this.staging_domain})},
            description() { this.$emit('input', {...this.value, description: this.description})}
        }
    }
</script>
