<script>
import {mapGettersTo} from '@/store/helpers';

export default {
    created() {
        mapGettersTo({
            currentCollection: 'currentCollection',
            currentTopic: 'currentTopic',
            role : 'currentRole'
        }, this)
    },
    computed : {
        isReviewerForHasSuggestions() {
            let hasSuggestionsType = this.hasSuggestions && this.hasSuggestions.type;
            let hasSuggestions = hasSuggestionsType == 'topic' ? this.currentTopic : this.currentCollection;
            let hasSuggestionsRole = hasSuggestions && hasSuggestions.role;

            return this.isReviewer || hasSuggestionsRole === 'reviewer';
        },
        isReviewer(){
            return this.role == 'reviewer';
        }
    },
}
</script>