<template>
    <div class="add-url-button-container" :class="{'animated tada infinite text-danger' : grabAttention}">
<!--        <button class="btn btn-lg btn-link d-none d-md-block pt-md-1" @click="addUrlToCollections">-->
<!--            <i class="fa fa-plus"></i> Add Article-->
<!--        </button>-->
        <button class="d-none d-md-block btn btn-primary ms-auto me-0" @click="addUrlToCollections">
            <i class="fa fa-plus"></i> Add Article
        </button>
        <button class="d-block d-md-none btn btn-link font-size-14 ms-auto me-0 p-0" @click="addUrlToCollections">
            <i class="fa fa-plus me-1"></i>
            <span class="btn-link-text">Add Article</span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'AddUrlButton',
    props : [],
    data(){
        return {
            grabAttention : false
        }
    },
    mounted(){
        window.Events.$on('showAddUrlAttentionGrabber', () => {
            this.grabAttention = true;
        })
        window.Events.$on('hideAddUrlAttentionGrabber', () => {
            this.grabAttention = false;
        })
    },
    methods : {
        addUrlToCollections(){
            window.Events.$emit('showAddUrlToCollectionModal')            
        }
    }
}
</script>

<style scoped>
    .add-url-button-container {
        display: inline-block;
        position: relative;
    }

    .animated.tada .btn-link {
        color:inherit;
    }

    .attention-grabber {
        position: absolute;
        left: 17px;
        top: -9px;
    }
    @media screen and (max-width: 575.98px) {
        .add-url-button-container {
            min-width: 100%;
        }
    }
</style>
