import moment from "moment";
import {StringUtils} from "@/utilities/Strings";
import {hasValue} from "../utilities/utils";

export class Filters {
    id = null;
    name = null;
    contentType = 'All';
    sortBy = 'Relevance';
    time = '2 Months';
    age = 60;
    customTime = '';
    geography = ['US'];
    sources = [];
    limit = 100;
    required = [];
    excludedSources = [];
    tags = [];
    evergreen = false;
    favorites = false;
    includeNewSources = true;
    includeAllSources = true;
    minPredictedPerformance = 0;
    minFullTextLength = 0;
    excludePaywalled = false;


    static fromUi(source, includeLimit = true, includeOffset = null) {
        includeOffset = includeOffset !== null ? includeOffset : includeLimit;
        return Object.assign(new Filters(), source, {
            limit: includeLimit ? source.limit : null,
            offset: includeOffset ? source.offset : null
        })
    }

    static baseTime(age) {
        if (typeof age === 'string') age = parseInt(age);
        switch (age) {
            case -1:
                return 'Forever';
            case 1:
                return 'Day';
            case 7:
                return 'Week';
            case 30:
                return 'Month';
            case 31:
                return 'Month';
            case 60:
                return '2 Months';
            default:
                return 'custom';
        }
    }

    static fromApi(s) {
        let time = Filters.baseTime(s.age);
        let displayTime = s.age == -1 ? null : moment().subtract(s.age, "days").fromNow().replace(/^[a-z]/, s => StringUtils.capitalizeWords(s));
        let customTime = time === 'custom' ? displayTime : null;
        return Object.assign(new Filters(), s, {
            contentType: StringUtils.capitalize(s.type || 'all'),
            sortBy: StringUtils.capitalize(s.sort || 'recency'),
            age: s.age,
            name: s.name,
            filterViewId: s.id,
            time,
            customTime,
            displayTime,
            limit: s.limit,
            required: s.required,
            offset: s.offset,
            favorites: s.favorited,
            undated: s.undated,
            excludedSources: s.excludes,
            sources: s.sources,
            evergreen: s.undated,
            includeAllSources: ![...(s.sources||[]), ...(s.excludes||[])].length,
            includeNewSources: !s.sources,
            minPredictedPerformance: s.min_predicted_performance,
            minFullTextLength: s.min_full_text_length,
            excludePaywalled: s.exclude_paywalled,
        })
    }

    toApi() {
        let f = this;
        return {
            id: f.id,
            name: f.name,
            type: f.contentType && f.contentType.toLowerCase(),
            sort: f.sortBy && f.sortBy.toLowerCase(),
            age: f.age,
            limit: f.limit,
            offset: f.offset,
            favorited: f.favorites,
            required: f.required,
            undated: f.evergreen,
            tags: f.tags,
            excludes: f.includeNewSources && !f.includeAllSources ? f.excludedSources : undefined,
            sources: !f.includeNewSources && !f.includeAllSources ? f.sources : undefined,
            min_predicted_performance: f.minPredictedPerformance,
            min_full_text_length: f.minFullTextLength,
            exclude_paywalled: f.excludePaywalled,
        }
    }
}
