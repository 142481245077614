<template>
    <div class="plan-container mb-3" :class="{'current' : isSelected}">
        <div aria-hidden="true">
            <img v-if="plan.icon.includes('/')" :src="plan.icon" class="plan-icon" />
            <i v-else :class="`fa fa-${plan.icon} plan-icon`"></i>
        </div>

        <h4>{{plan.name}}</h4>

        <div class="cost font-size-30 d-flex align-items-start justify-content-center">
            <span class="font-size-13 me-1 top-5">$</span>
            <span class="bottom-2">{{planCost}}</span>
        </div>

        <div class="plan-description text-start fw-light d-flex justify-content-center align-items-start p-1 ps-2 pe-2">
            <span class="text-steel-33 font-size-12">{{plan.description}}</span>
        </div>

        <ul class="list mb-4">
            <li><b>{{ plan.userCount }}</b> {{$strings.plural('user', plan.userCount)}}</li>
            <li><b>{{ plan.topicCount }}</b> {{$strings.plural('topic', plan.topicCount)}}</li>
            <li><b>{{ plan.collectionCount }}</b> {{$strings.plural('collection', plan.collectionCount)}}</li>
            <li v-if="!sharer">RSS Feeds</li>
            <li v-if="!sharer">All Integrations</li>
            <li v-else>Select Integrations</li>
            <li v-if="!sharer">Website Gallery</li>
            <li v-if="level >= levels.Engager">
                Custom Call-to-Actions
            </li>
            <li v-if="level >= levels.Engager">
                Custom Article Imagery
            </li>
        </ul>

        <div v-if="networkActivity && isSelected">
            {{networkActivity}}
        </div>
        <div class="" v-else>
            <button v-if="isCurrent" class="btn btn-white" @click="$emit('setPlan', planDuration.id)"><i class="fa fa-star-o"></i> Current</button>
            <button v-if="!isSelected &&  ! isCurrent" @click="$emit('setPlan', planDuration.id)" class="btn btn-primary">Choose</button>
            <button v-if="!isSelected &&  ! isCurrent && enterprise" @click="$intercom.showNewMessage('I\'m interested in the enterprise plan')" class="btn btn-primary">Contact Sales</button>
            <button v-if="isSelected && ! isCurrent" class="btn btn-success" @click="$emit('confirmPlan')"><i class="fa fa-check"></i> Confirm</button>
        </div>


    </div>
</template>

<script>

import PlanGroups from '@/utilities/PlanList'

let levels = ['Sharer', 'Curator', 'Engager', 'Orchestrator', 'Enterprise'].reduce((levels, name, index) => ({...levels, [name]: index}), {});

export default {
    props : ['planName', 'duration', 'currentPlan', 'selectedPlan', 'networkActivity'],
    data() { return { levels }},
    computed : {
        isSelected(){
            return this.planDuration && this.planDuration.id.includes(this.selectedPlan);
        },
        isCurrent(){
            return this.planDuration && this.planDuration.id.includes(this.currentPlan);
        },
        planDuration(){
            return this.duration == 'monthly' ? this.plan.durations[0] : this.plan.durations[1]
        },
        plan(){
            return PlanGroups.find(p => p.name == this.planName)
        },
        cost(){
            return this.planDuration.cost;
        },
        planCost(){
            return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    }).format(this.cost/100).replace("$", "");
        },
        sharer() {
            return this.planName === 'Sharer'
        },
        curator() {
            return this.planName === 'Curator'
        },
        level() {
            return levels[this.planName];
        },
        enterprise() {
            return this.planName === 'Enterprise'
        }
    }
}
</script>

<style scoped>


.plan-container {
    border: 1px solid #888;
    width: 150px;
    margin: 5px auto;
    text-align: center;
    padding: 1.2rem 0;
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2),
    0px 2px 6px 3px rgba(0,0,0,0.1);
}

@media screen and (min-width: 964px)
{
   .plan-container {
       width: 190px;
   }
}

@media screen and (max-width: 767px)
{
   .plan-container {
       width: 100%;
   }
}

.plan-container.current {
    background: var(--water-100);
    border-color: var(--primary-50);
    box-shadow: none;
}

.plan-description {
    min-height: 6em;
}


.plan-container h4 {
    color : var(--steel-33);
    font-size: var(--font-size-13);
    text-transform: uppercase;
}

.plan-container h4 small {
    text-transform: none;
    display: block;
    margin-top: 5px;
}

.plan-container .cost {
    font-weight: var(--font-weight-semibold);
}

.plan-icon {
    color: #496FF5;
    font-size: 2.7rem;
    margin: 0 0 1.7rem;
    height: 33px;
}

ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: var(--font-size-13);
    color: var(--steel-33);
}

li {
    margin: 5px 0;
}

.list {
    height: 16em;
}

.btn {
    padding: 5px 10px;
    font-size: var(--font-size-13);
    text-transform: uppercase;
}

.btn.btn-secondary {
    background: white;
    color: inherit;
}
</style>
