<template>
    <div v-if="show">
        <h4 class="mt-3">Boosters</h4>
        <hr>
        <ul class="no-bullets d-flex flex-wrap ms-0 ps-0 text-center">
            <li v-for="booster in boosters" :key="booster.id" class="me-4 mb-4" style="width: 225px;">
                <booster :value="booster" :account="account" @updateComplete="$emit('boosterUpdated')" :subscription="subscription"></booster>
            </li>
        </ul>
    </div>
</template>

<style>

</style>

<script>
    import {mapGetters} from 'vuex';
    import Booster from "@/components/Subscriptions/Booster";
    export default {
        components: {Booster},
        props: {
            account: {},
            subscription: {},
            hasCardOnFile: {},
        },
        name: 'Boosters',
        computed:{
            ...mapGetters({
                features: 'features',
                subscriptions: 'subscriptions'
            }),

            currentPlans(){ return  (this.subscription||{}).plan_items||[]},
            currentPackage() { return this.currentPlans.find(p => p.plan_key === this.subscription.plan_key)},
            show() {
                return this.features.purchaseAddons
                    && this.currentPackage && this.currentPackage.can_have_boosters
                    && this.boosters && this.boosters.length
            }
        },
        data() {
            return {
                availableBoosters: [],
                boosters: [],
            }
        },
        methods: {
            async load() {
                if (!(this.account && this.account.id)) return;
                let resp = await this.$api.get(`/accounts/${this.account.id}/subscription/boosters/available`);
                this.availableBoosters = resp.data;
                this.boosters = this.availableBoosters.map(booster => {
                    let planSub = this.currentPlans.find(p => p.id == booster.id);
                    return {
                        ...booster,
                        amount_disp: (booster.amount/100).toFixed(2).split('.'),
                        quantity: planSub ? planSub.quantity : 0
                    }
                })
            }
        },
        watch: {
            account() {
                this.load()
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
