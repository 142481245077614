export function unique(selector) {
    if (selector) {
        return function (item, index, array) {
            return !array.slice(0, index).some(x => selector(x) === selector(item))
        }
    }
    return function (item, index, array) {
        return !array.slice(0, index).includes(item)
    }
}

export function groupAndMerge(key, merge) {
    return function (results, item) {
        let existingItem = results.find(x => key(x) === key(item));
        let otherItems = results.filter(x => key(x) !== key(item));
        let merged = existingItem ? merge(item, existingItem) : item;
        return [...otherItems, merged]
    }
}
