<template>
    <div class="d-flex flex-column align-items-end">
        <div class="text-danger small fw-semibold text-wrap">
            <template v-if="score < 3 && password.length">
                <i class="fa fa-info-circle me-1"></i>
                <span>
                    Your password is weak. Create a stronger password to continue.
                </span>
            </template>
            <template v-else>
                &nbsp;
            </template>
        </div>

        <div>
            <a class="small ux-generate-passphrase" href="#" @click.prevent="generatePassword(4)">
                <i class="fa fa-refresh"></i> Generate a passphrase for me</a>
        </div>

        <div class="position-relative">
            <drop-panel :panel-name="'Why a passphrase instead of a password?'"
                        :panel-title="'passphraseExplanation'"
                        :overlap="true"
                        drop-panel-class="alert-blue small text-start">
                <template v-slot:header>
                    <h4 class="text-blue-25 font-size-14">
                        Why a passphrase instead of a password?
                    </h4>
                </template>
                <template v-slot:link>
                <span class="small text-muted fw-light text-end">
                    Why a passphrase instead of a password?
                </span>
                </template>
                <template v-slot:default>
                    Passphrases are easy for humans to remember but hard for computers to guess.
                </template>
            </drop-panel>
        </div>

    </div>
</template>

<script>
import DropPanel from "@/components/Controls/DropPanel";

export default {
    components: {DropPanel},
    props: ['score', 'password'],
    data() {
        return {
            showHelpText: false
        }
    },
    methods: {
        generatePassword(numberOfWords) {
            // Cryptographically generated random numbers
            numberOfWords = parseInt(numberOfWords);
            var array = new Uint32Array(numberOfWords);
            var crypto = window.crypto || window.msCrypto;
            crypto.getRandomValues(array);

            // Empty array to be filled with wordlist
            var generatedPasswordArray = [];


            // Grab a random word, push it to the password array
            for (var i = 0; i < array.length; i++) {
                var index = (array[i] % 5852);
                generatedPasswordArray.push(wordlist[index]);
            }

            this.$emit('passphraseGenerated', generatedPasswordArray.join(' '));
        }
    }
}

const wordlist = [
    'ado',
    'aft', 'age', 'aid', 'aim', 'arb', 'aww', 'aye', 'bag', 'bib', 'bio', 'biz', 'bog', 'bow', 'bum', 'buy',
    'cat',
    'cob', 'con', 'coy', 'cry', 'cub', 'dam', 'dba', 'doe', 'dud', 'due', 'dun', 'ebb', 'elm', 'far', 'fax',
    'fen',
    'fez', 'fig', 'fit', 'fix', 'flu', 'fly', 'foe', 'fog', 'frog', 'git', 'guy', 'gym', 'hat', 'haw', 'hen',
    'her',
    'hie', 'his', 'hog', 'hot', 'hum', 'icy', 'ill', 'ire', 'irk', 'jab', 'jar', 'jot', 'lap', 'law', 'lax',
    'lay',
    'lea', 'lik', 'lip', 'lot', 'lug', 'lye', 'map', 'may', 'men', 'mil', 'mop', 'mug', 'nap', 'nay', 'net',
    'nil',
    'nit', 'not', 'nub', 'oar', 'ode', 'off', 'oil', 'oke', 'orb', 'ore', 'out', 'par', 'pat', 'pec', 'peg',
    'per',
    'pig', 'pin', 'pro', 'pyx', 'ram', 'rap', 'rat', 'raw', 'rec', 'rig', 'rip', 'rue', 'rut', 'sac', 'sad',
    'sap',
    'say', 'set', 'sir', 'sit', 'sky', 'sly', 'sop', 'sow', 'spy', 'sue', 'tan', 'tax', 'tea', 'tee', 'thy',
    'tic',
    'too', 'top', 'toy', 'tug', 'ugh', 'vet', 'vie', 'vow', 'wad', 'way', 'wed', 'wee', 'wry', 'yaw', 'yen',
    'yin',
    'yon', 'you', 'yum', 'zip', 'able', 'acid', 'acts', 'adds', 'aged', 'ages', 'aide', 'aids', 'aims',
    'alan', 'alex',
    'ally', 'also', 'alto', 'amid', 'andy', 'anne', 'ansi', 'aoun', 'arab', 'area', 'ariz', 'arms', 'army',
    'arts',
    'asia', 'asks', 'auto', 'away', 'axis', 'baby', 'back', 'bags', 'bail', 'ball', 'band', 'bank', 'bars',
    'base',
    'bass', 'bays', 'bcci', 'beam', 'bear', 'beat', 'beds', 'beef', 'been', 'beer', 'bell', 'belt', 'best',
    'beta',
    'bias', 'bids', 'bill', 'bios', 'bird', 'bits', 'blew', 'bloc', 'blow', 'blue', 'blvd', 'boat', 'body',
    'bold',
    'bomb', 'bond', 'bone', 'bonn', 'book', 'boom', 'boot', 'born', 'boss', 'both', 'bowl', 'boys', 'bugs',
    'bulk',
    'bull', 'burn', 'bush', 'busy', 'buys', 'byrd', 'byte', 'call', 'calm', 'came', 'camp', 'cans', 'cape',
    'capt',
    'card', 'care', 'carl', 'cars', 'case', 'cash', 'cast', 'cell', 'cent', 'chip', 'cics', 'cite', 'city',
    'clip',
    'club', 'cmos', 'coal', 'code', 'coke', 'cold', 'cole', 'come', 'conn', 'cook', 'cool', 'cope', 'copy',
    'core',
    'corn', 'corp', 'cost', 'coup', 'cpus', 'cray', 'crew', 'crop', 'cruz', 'cuba', 'curb', 'cure', 'cuts',
    'dale',
    'dark', 'data', 'date', 'dave', 'dawn', 'days', 'dbms', 'dead', 'deal', 'dean', 'debt', 'deck', 'deep',
    'dell',
    'deng', 'deny', 'desk', 'dial', 'dick', 'died', 'diet', 'dirt', 'disc', 'disk', 'does', 'dogs', 'dole',
    'done',
    'door', 'dose', 'dots', 'doug', 'down', 'drag', 'dram', 'draw', 'drew', 'drop', 'drug', 'drum', 'dual',
    'duke',
    'dumb', 'dump', 'dust', 'duty', 'each', 'earn', 'ease', 'east', 'easy', 'edge', 'edit', 'eggs', 'eisa',
    'else',
    'ends', 'eric', 'esdi', 'even', 'ever', 'exit', 'expo', 'eyes', 'face', 'fact', 'fail', 'fair', 'fall',
    'fans',
    'fare', 'farm', 'fast', 'fate', 'fddi', 'fdic', 'fear', 'feed', 'feel', 'fees', 'feet', 'fell', 'felt',
    'figs',
    'file', 'fill', 'film', 'find', 'fine', 'fire', 'firm', 'fish', 'fits', 'five', 'flag', 'flat', 'fled',
    'flee',
    'flew', 'flow', 'flux', 'font', 'food', 'foot', 'ford', 'form', 'fort', 'four', 'fred', 'free', 'from',
    'fuel',
    'full', 'fund', 'gain', 'game', 'gang', 'gary', 'gate', 'gave', 'gaza', 'gear', 'gene', 'gets', 'gift',
    'girl',
    'give', 'glad', 'goal', 'goes', 'gold', 'golf', 'gone', 'good', 'gore', 'grab', 'gray', 'greg', 'grew',
    'grid',
    'grip', 'grow', 'gulf', 'guns', 'guys', 'hair', 'half', 'hall', 'halt', 'hand', 'hang', 'hard', 'harm',
    'hart',
    'pear', 'have', 'hdtv', 'head', 'hear', 'heat', 'held', 'hell', 'help', 'here', 'hero', 'hide', 'high',
    'hill',
    'hire', 'hits', 'hold', 'hole', 'home', 'hong', 'hook', 'hope', 'host', 'hour', 'hubs', 'huge', 'hung',
    'hunt',
    'hurt', 'icon', 'idea', 'idle', 'ieee', 'inch', 'into', 'ions', 'iowa', 'iran', 'iraq', 'iron', 'isdn',
    'item',
    'ivan', 'jack', 'jail', 'jane', 'jazz', 'jean', 'jeff', 'jets', 'jews', 'joan', 'jobs', 'joel', 'john',
    'join',
    'joke', 'jose', 'juan', 'july', 'jump', 'june', 'junk', 'jury', 'just', 'keen', 'keep', 'kemp', 'kent',
    'kept',
    'keys', 'kick', 'kids', 'kill', 'kind', 'king', 'kits', 'knee', 'knew', 'know', 'koch', 'kohl', 'kong',
    'labs',
    'lack', 'lady', 'laid', 'lake', 'land', 'lane', 'lans', 'last', 'late', 'lawn', 'laws', 'lead', 'leak',
    'lean',
    'leap', 'left', 'legs', 'lend', 'leon', 'less', 'lets', 'levy', 'lies', 'life', 'lift', 'like', 'lima',
    'line',
    'link', 'lisa', 'list', 'live', 'load', 'loan', 'lock', 'logo', 'logs', 'long', 'look', 'loop', 'lord',
    'lose',
    'loss', 'lost', 'lots', 'loud', 'love', 'lows', 'lsqb', 'luck', 'luis', 'lung', 'lure', 'lynn', 'macs',
    'made',
    'mail', 'main', 'make', 'male', 'mall', 'many', 'maps', 'mark', 'mary', 'mask', 'mass', 'mate', 'math',
    'meal',
    'mean', 'meat', 'meet', 'memo', 'menu', 'merc', 'mere', 'mesa', 'mess', 'mice', 'mich', 'mike', 'mild',
    'mile',
    'milk', 'mill', 'mind', 'mine', 'minn', 'mips', 'miss', 'mode', 'mood', 'moon', 'more', 'most', 'move',
    'much',
    'must', 'name', 'nasa', 'nato', 'navy', 'near', 'neck', 'need', 'neil', 'news', 'next', 'nice', 'nine',
    'node',
    'none', 'noon', 'nose', 'note', 'nunn', 'odds', 'oems', 'ohio', 'oils', 'once', 'ones', 'only', 'onto',
    'opec',
    'open', 'oral', 'osha', 'over', 'owed', 'owen', 'owes', 'owns', 'pace', 'pack', 'pact', 'page', 'paid',
    'pain',
    'pair', 'palm', 'palo', 'park', 'part', 'pass', 'past', 'path', 'paul', 'pays', 'peak', 'penn', 'peru',
    'pete',
    'phil', 'pick', 'pict', 'pill', 'pink', 'pipe', 'pits', 'plan', 'play', 'plea', 'plot', 'plug', 'plus',
    'poll',
    'pont', 'pool', 'poor', 'pope', 'pork', 'port', 'pose', 'post', 'pull', 'pulp', 'pump', 'pure', 'push',
    'puts',
    'quit', 'race', 'raid', 'rail', 'rain', 'rank', 'rare', 'rate', 'rats', 'read', 'real', 'rear', 'reed',
    'refs',
    'rely', 'rent', 'rest', 'rice', 'rich', 'rick', 'rico', 'ride', 'ring', 'riot', 'risc', 'rise', 'risk',
    'road',
    'rock', 'role', 'roll', 'rome', 'roof', 'room', 'root', 'rose', 'ross', 'rows', 'rsqb', 'rule', 'runs',
    'rush',
    'ryan', 'safe', 'said', 'sale', 'salt', 'same', 'sand', 'sang', 'sank', 'save', 'says', 'scan', 'scsi',
    'seal',
    'seat', 'seed', 'seek', 'seem', 'seen', 'sees', 'sell', 'send', 'sent', 'sept', 'sets', 'shaw', 'shed',
    'ship',
    'shop', 'shot', 'show', 'shut', 'sick', 'side', 'sign', 'sikh', 'sing', 'site', 'sits', 'size', 'skin',
    'slid',
    'slim', 'slip', 'weave', 'slow', 'snap', 'snmp', 'snow', 'soft', 'soil', 'sold', 'sole', 'some', 'song',
    'sons',
    'sony', 'soon', 'sort', 'span', 'spin', 'spot', 'spur', 'star', 'stay', 'stem', 'step', 'stop', 'such',
    'sued',
    'suit', 'sums', 'sure', 'swap', 'tabs', 'tail', 'take', 'tale', 'talk', 'tall', 'tank', 'tape', 'task',
    'tass',
    'team', 'tear', 'tech', 'tell', 'tend', 'tenn', 'tens', 'term', 'test', 'text', 'thai', 'than', 'that',
    'them',
    'then', 'they', 'thin', 'this', 'thus', 'tide', 'tied', 'ties', 'tiff', 'time', 'tiny', 'tips', 'tire',
    'told',
    'toll', 'tone', 'tons', 'tony', 'took', 'tool', 'tops', 'tory', 'tour', 'town', 'toys', 'trap', 'tree',
    'trim',
    'trip', 'troy', 'true', 'tube', 'tune', 'turn', 'twin', 'type', 'unit', 'unix', 'upon', 'urge', 'usda',
    'used',
    'user', 'uses', 'utah', 'vars', 'vary', 'vast', 'very', 'veto', 'vice', 'view', 'visa', 'void', 'vote',
    'wage',
    'wait', 'wake', 'walk', 'wall', 'walt', 'sean', 'want', 'ward', 'warm', 'warn', 'wars', 'wary', 'wash',
    'wave',
    'ways', 'weak', 'wear', 'webb', 'week', 'well', 'went', 'were', 'west', 'what', 'when', 'whom', 'wide',
    'wife',
    'wild', 'will', 'wind', 'wine', 'wing', 'wins', 'wire', 'wise', 'wish', 'with', 'woes', 'wolf', 'wood',
    'word',
    'wore', 'work', 'yale', 'yard', 'year', 'york', 'your', 'zero', 'zone', 'about', 'above', 'abuse',
    'acres', 'acted',
    'actor', 'acute', 'adams', 'adapt', 'added', 'admit', 'adobe', 'adopt', 'adult', 'after', 'again',
    'agent', 'aging',
    'agree', 'ahead', 'aided', 'aides', 'aimed', 'alarm', 'album', 'aldus', 'alert', 'alike', 'alive',
    'allen', 'allow',
    'alloy', 'alone', 'along', 'alpha', 'alter', 'altos', 'amend', 'among', 'anger', 'angle', 'angry',
    'apart', 'apple',
    'apply', 'april', 'arabs', 'areas', 'arena', 'argue', 'arise', 'armed', 'array', 'ascii', 'asian',
    'aside', 'asked',
    'asset', 'atoms', 'audio', 'audit', 'avoid', 'award', 'aware', 'awful', 'backs', 'badly', 'baker',
    'bands', 'banks',
    'barry', 'based', 'bases', 'basic', 'basin', 'basis', 'batch', 'beach', 'beams', 'bears', 'began',
    'begin', 'begun',
    'being', 'below', 'bench', 'bible', 'bills', 'billy', 'birds', 'birth', 'black', 'blame', 'blank',
    'blast', 'blaze',
    'blend', 'blind', 'block', 'blood', 'board', 'boats', 'bombs', 'bonds', 'bones', 'bonus', 'books',
    'boost', 'booth',
    'boris', 'bound', 'boxes', 'brady', 'brain', 'brand', 'bread', 'break', 'brian', 'brief', 'bring',
    'broad', 'broke',
    'brown', 'bruce', 'brush', 'bryan', 'build', 'built', 'bunch', 'burns', 'burst', 'buses', 'buyer',
    'bytes', 'cable',
    'cache', 'cairo', 'calif', 'calls', 'camps', 'canal', 'canon', 'cards', 'cargo', 'carlo', 'carol',
    'carry', 'cases',
    'casey', 'catch', 'cause', 'cells', 'cents', 'chain', 'chair', 'chaos', 'chart', 'chase', 'cheap',
    'check', 'chest',
    'chief', 'child', 'chile', 'china', 'chips', 'chose', 'chris', 'chuck', 'cited', 'cites', 'civil',
    'claim', 'clark',
    'clash', 'class', 'clean', 'clear', 'clerk', 'click', 'climb', 'clock', 'clone', 'close', 'cloud',
    'clout', 'clubs',
    'coach', 'coals', 'coast', 'cobol', 'codes', 'cohen', 'color', 'comes', 'comex', 'comic', 'corps',
    'costa', 'costs',
    'could', 'count', 'court', 'cover', 'crack', 'craft', 'craig', 'crash', 'crazy', 'cream', 'creek',
    'crews', 'crime',
    'crops', 'cross', 'crowd', 'crude', 'cuban', 'cubic', 'cuomo', 'curve', 'cycle', 'daily', 'dairy',
    'dance', 'dated',
    'dates', 'david', 'davis', 'dbase', 'deals', 'dealt', 'debts', 'debut', 'decay', 'delay', 'delta',
    'depth', 'diego',
    'dirty', 'disks', 'dodge', 'doing', 'doors', 'doses', 'doubt', 'dozen', 'draft', 'drain', 'drama',
    'drawn', 'draws',
    'dream', 'dress', 'drift', 'drink', 'drive', 'drops', 'drove', 'drugs', 'dutch', 'dying', 'eager',
    'eagle', 'early',
    'earth', 'eased', 'eddie', 'edged', 'edges', 'edwin', 'egypt', 'eight', 'elect', 'elite', 'empty',
    'ended', 'enemy',
    'enjoy', 'enter', 'entry', 'epson', 'equal', 'error', 'evans', 'event', 'every', 'exact', 'excel',
    'exile', 'exist',
    'extra', 'exxon', 'faced', 'faces', 'facto', 'facts', 'fails', 'faith', 'falls', 'false', 'fancy',
    'fares', 'farms',
    'fatal', 'fault', 'favor', 'faxes', 'fears', 'feels', 'fence', 'ferry', 'fewer', 'fiber', 'field',
    'fifth', 'fight',
    'filed', 'files', 'fills', 'films', 'final', 'finds', 'fined', 'fines', 'fired', 'fires', 'firms',
    'first', 'fixed',
    'flags', 'flame', 'flash', 'flaws', 'fleet', 'float', 'flood', 'floor', 'flown', 'flows', 'fluid',
    'focus', 'foley',
    'folks', 'fonts', 'foods', 'force', 'forms', 'forth', 'forum', 'found', 'frame', 'franc', 'frank',
    'fraud', 'freed',
    'fresh', 'front', 'fruit', 'fuels', 'fully', 'funds', 'funny', 'gains', 'games', 'gamma', 'gases',
    'gates', 'gatos',
    'gauge', 'genes', 'giant', 'gifts', 'gilts', 'girls', 'given', 'gives', 'glass', 'glenn', 'goals',
    'going', 'goods',
    'grade', 'grain', 'grand', 'grant', 'graph', 'grass', 'grave', 'great', 'greek', 'green', 'gross',
    'group', 'grown',
    'grows', 'guard', 'guess', 'guest', 'guide', 'haiti', 'hands', 'handy', 'happy', 'harry', 'harsh',
    'hayes', 'heads',
    'heard', 'heart', 'heavy', 'hefty', 'helms', 'helps', 'hence', 'henry', 'highs', 'hills', 'hired',
    'holds', 'holes',
    'holly', 'homes', 'honda', 'honor', 'hoped', 'hopes', 'horse', 'hosts', 'hotel', 'hours', 'house',
    'human', 'humor',
    'icahn', 'icons', 'idaho', 'ideal', 'ideas', 'image', 'index', 'india', 'inner', 'input', 'intel',
    'iraqi', 'irish',
    'issue', 'italy', 'items', 'james', 'japan', 'jerry', 'jesse', 'jesus', 'jimmy', 'joins', 'joint',
    'jokes', 'jones',
    'judge', 'juice', 'kabul', 'karen', 'keeps', 'keith', 'kelly', 'kevin', 'khmer', 'kinds', 'klein',
    'klerk', 'knife',
    'known', 'knows', 'kodak', 'korea', 'label', 'labor', 'lacks', 'lakes', 'lands', 'large', 'larry',
    'laser', 'later',
    'latin', 'layer', 'leads', 'leaks', 'learn', 'lease', 'least', 'leave', 'legal', 'level', 'lewis',
    'libya', 'light',
    'liked', 'likes', 'likud', 'limit', 'linda', 'lined', 'lines', 'links', 'lists', 'lived', 'liver',
    'lives', 'lloyd',
    'loads', 'loans', 'lobby', 'local', 'logic', 'looks', 'loops', 'loose', 'loses', 'lotus', 'louis',
    'loved', 'lower',
    'loyal', 'lucas', 'lucky', 'lunch', 'lying', 'lynch', 'macro', 'magic', 'maine', 'major', 'maker',
    'makes', 'march',
    'maria', 'mario', 'marks', 'mason', 'match', 'maybe', 'mayor', 'meals', 'means', 'meant', 'media',
    'meese', 'meets',
    'menlo', 'menus', 'merge', 'merit', 'metal', 'miami', 'micro', 'midst', 'might', 'milan', 'miles',
    'mills', 'minds',
    'mines', 'minor', 'minus', 'mixed', 'mobil', 'model', 'modem', 'modes', 'money', 'monte', 'month',
    'moore', 'moral',
    'motif', 'motor', 'mount', 'mouse', 'mouth', 'moved', 'moves', 'movie', 'music', 'myers', 'named',
    'names', 'nancy',
    'naval', 'needs', 'never', 'newer', 'newly', 'niche', 'night', 'ninth', 'nixon', 'nobel', 'nodes',
    'noise', 'north',
    'noted', 'notes', 'novel', 'nubus', 'nurse', 'occur', 'ocean', 'offer', 'often', 'older', 'opens',
    'opera', 'optic',
    'orbit', 'order', 'oscar', 'other', 'ought', 'ounce', 'outer', 'owned', 'owner', 'oxide', 'ozone',
    'pages', 'paint',
    'pairs', 'panel', 'panic', 'paper', 'paris', 'parks', 'parts', 'party', 'paste', 'patch', 'paths',
    'peace', 'pence',
    'peres', 'perez', 'perry', 'peter', 'phase', 'phone', 'photo', 'piano', 'picks', 'piece', 'pilot',
    'pipes', 'pitch',
    'pixel', 'place', 'plain', 'plane', 'plans', 'plant', 'plate', 'plays', 'plaza', 'plots', 'point',
    'polls', 'pools',
    'ports', 'posed', 'poses', 'posts', 'pound', 'power', 'press', 'price', 'pride', 'prime', 'print',
    'prior', 'prize',
    'probe', 'proof', 'proud', 'prove', 'proxy', 'pulse', 'pumps', 'quake', 'queen', 'query', 'queue',
    'quick', 'quiet',
    'quite', 'quota', 'races', 'radar', 'radio', 'raids', 'rains', 'raise', 'rally', 'ralph', 'ranch',
    'range', 'ranks',
    'rapid', 'rated', 'rates', 'ratio', 'rdbms', 'reach', 'react', 'reads', 'ready', 'rebel', 'refer',
    'relay', 'repay',
    'reply', 'reset', 'rhode', 'ridge', 'rifle', 'right', 'rigid', 'rings', 'riots', 'risen', 'rises',
    'risks', 'risky',
    'rival', 'river', 'roads', 'robin', 'rocks', 'rocky', 'roger', 'roles', 'roman', 'rooms', 'roots',
    'rouge', 'rough',
    'round', 'route', 'royal', 'ruled', 'rules', 'rumor', 'rural', 'sachs', 'safer', 'sales', 'santa',
    'sauce', 'saudi',
    'saved', 'saves', 'scale', 'scans', 'scene', 'scope', 'score', 'scott', 'scrap', 'sears', 'seats',
    'seeds', 'seeks',
    'seems', 'seize', 'sells', 'sends', 'sense', 'seoul', 'serve', 'setup', 'seven', 'shake', 'shall',
    'shape', 'share',
    'sharp', 'sheet', 'shelf', 'shell', 'shift', 'ships', 'shirt', 'shock', 'shoes', 'shook', 'shoot',
    'shops', 'shore',
    'short', 'shots', 'shown', 'shows', 'sides', 'sight', 'signs', 'simon', 'since', 'singh', 'sites',
    'sixth', 'sizes',
    'skill', 'slain', 'slash', 'sleep', 'slide', 'weaves', 'slump', 'small', 'smart', 'smile', 'smith',
    'smoke',
    'solar',
    'solid', 'solve', 'songs', 'sorry', 'sorts', 'sound', 'south', 'space', 'spain', 'sparc', 'spare',
    'spark', 'speak',
    'speed', 'spell', 'spend', 'spent', 'spill', 'spite', 'split', 'spoke', 'sport', 'spots', 'squad',
    'stack', 'staff',
    'stage', 'stake', 'stamp', 'stand', 'stars', 'start', 'state', 'stays', 'steal', 'steam', 'steel',
    'steep', 'stems',
    'steps', 'steve', 'stick', 'stiff', 'still', 'stock', 'stole', 'stone', 'stood', 'stops', 'store',
    'storm', 'story',
    'strip', 'stuck', 'study', 'stuff', 'style', 'sugar', 'suite', 'suits', 'super', 'surge', 'susan',
    'sweep', 'sweet',
    'swept', 'swing', 'swiss', 'syria', 'table', 'taken', 'takes', 'talks', 'tamil', 'tampa', 'tandy',
    'tanks', 'tapes',
    'tasks', 'taste', 'taxes', 'teach', 'teams', 'tears', 'teeth', 'tells', 'tends', 'terms', 'terry',
    'tests', 'texas',
    'thank', 'theft', 'their', 'theme', 'there', 'these', 'thick', 'thing', 'think', 'third', 'those',
    'three', 'threw',
    'throw', 'tight', 'times', 'tired', 'tires', 'title', 'today', 'token', 'tokyo', 'tonne', 'tools',
    'topic', 'total',
    'touch', 'tough', 'tower', 'towns', 'toxic', 'trace', 'track', 'trade', 'trail', 'train', 'trash',
    'treat', 'trees',
    'trend', 'trial', 'trick', 'tried', 'tries', 'trips', 'troop', 'truce', 'truck', 'truly', 'trump',
    'trust', 'truth',
    'tubes', 'tumor', 'turbo', 'turns', 'twice', 'types', 'tyson', 'under', 'union', 'units', 'unity',
    'until', 'upper',
    'upset', 'urban', 'urged', 'usage', 'usair', 'users', 'using', 'usual', 'vague', 'valid', 'value',
    'valve', 'vapor',
    'vegas', 'video', 'views', 'vines', 'virus', 'visas', 'visit', 'vital', 'voice', 'voted', 'voter',
    'votes', 'vowed',
    'wages', 'wales', 'walls', 'walsh', 'wants', 'warns', 'waste', 'watch', 'water', 'waves', 'wayne',
    'weeks', 'weigh',
    'weiss', 'wells', 'wheat', 'wheel', 'where', 'which', 'while', 'white', 'whole', 'whose', 'wider',
    'widow', 'width',
    'winds', 'wines', 'wings', 'wires', 'woman', 'women', 'woods', 'words', 'works', 'world', 'worry',
    'worse', 'worst',
    'worth', 'would', 'wound', 'write', 'wrong', 'wrote', 'xenix', 'xerox', 'yards', 'years', 'yield',
    'young', 'youth',
    'zones', 'aboard', 'abroad', 'absent', 'absorb', 'abuses', 'accept', 'access', 'accord', 'across',
    'acting',
    'action', 'active', 'actors', 'actual', 'adding', 'adjust', 'admits', 'adults', 'advice', 'advise',
    'affair',
    'affect', 'afford', 'afghan', 'afraid', 'africa', 'agency', 'agenda', 'agents', 'agreed', 'agrees',
    'ailing',
    'aiming', 'airbus', 'alaska', 'albert', 'alfred', 'allied', 'allies', 'allows', 'alloys', 'almost',
    'always',
    'amount', 'analog', 'andrew', 'animal', 'annual', 'answer', 'anyone', 'anyway', 'apollo', 'appeal',
    'appear',
    'aquino', 'arabia', 'arafat', 'argued', 'argues', 'arnold', 'around', 'arrays', 'arrest', 'arrive',
    'arthur',
    'artist', 'asking', 'aspect', 'assess', 'assets', 'assign', 'assist', 'assume', 'assure', 'asylum',
    'atomic',
    'attach', 'attack', 'attend', 'august', 'austin', 'author', 'autumn', 'avenue', 'awards', 'babies',
    'backed',
    'backup', 'bakker', 'ballot', 'baltic', 'banker', 'banned', 'banyan', 'barely', 'barney', 'barred',
    'barrel',
    'battle', 'beaten', 'beauty', 'became', 'become', 'before', 'begins', 'behalf', 'behind', 'beirut',
    'belief',
    'belong', 'benson', 'berlin', 'better', 'beyond', 'bidder', 'bigger', 'billed', 'binary', 'bitter',
    'blacks',
    'blamed', 'blocks', 'bloody', 'boards', 'boasts', 'bodies', 'boeing', 'boesky', 'boiler', 'bomber',
    'border',
    'borrow', 'boston', 'bother', 'bottle', 'bottom', 'bought', 'branch', 'brands', 'brazil', 'breach',
    'breaks',
    'breast', 'bridge', 'bright', 'brings', 'broken', 'broker', 'brooks', 'budget', 'buffer', 'builds',
    'bullet',
    'bundle', 'burden', 'bureau', 'burial', 'buried', 'burned', 'bushel', 'butler', 'butter', 'button',
    'buyers',
    'buying', 'buyout', 'bypass', 'cables', 'called', 'caller', 'camera', 'campus', 'canada', 'cancel',
    'cancer',
    'cannot', 'capped', 'carbon', 'career', 'carlos', 'cartel', 'carter', 'casino', 'castle', 'castro',
    'casual',
    'cattle', 'caught', 'caused', 'causes', 'cement', 'census', 'center', 'centre', 'chains', 'chairs',
    'chance',
    'change', 'chapel', 'charge', 'charts', 'checks', 'cheese', 'cheney', 'choice', 'choose', 'chosen',
    'church',
    'circle', 'cities', 'citing', 'claims', 'claris', 'clarke', 'clause', 'clever', 'client', 'clinic',
    'clones',
    'closed', 'closer', 'clouds', 'cloudy', 'coding', 'coffee', 'colony', 'colors', 'colour', 'column',
    'combat',
    'comdex', 'comedy', 'coming', 'commit', 'common', 'compaq', 'comply', 'config', 'contra', 'cooper',
    'copied',
    'copies', 'copper', 'corner', 'costly', 'cotton', 'counts', 'county', 'couple', 'coupon', 'course',
    'courts',
    'covers', 'cracks', 'create', 'credit', 'crimes', 'crisis', 'critic', 'crowds', 'cruise', 'curfew',
    'cursor',
    'curves', 'custom', 'cycles', 'cyprus', 'dakota', 'dallas', 'damage', 'danger', 'daniel', 'danish',
    'dating',
    'dayton', 'deadly', 'dealer', 'deaths', 'debate', 'debris', 'decade', 'decent', 'decide', 'decnet',
    'decree',
    'deemed', 'deeper', 'deeply', 'defeat', 'defect', 'defend', 'define', 'degree', 'delays', 'delete',
    'demand',
    'denied', 'denies', 'dennis', 'denver', 'depend', 'deputy', 'desert', 'design', 'desire', 'detail',
    'detect',
    'device', 'dialog', 'diesel', 'differ', 'dinner', 'dipped', 'direct', 'disney', 'divide', 'docket',
    'doctor',
    'dollar', 'domain', 'donald', 'donors', 'double', 'doubts', 'dozens', 'dreams', 'drexel', 'drinks',
    'driven',
    'driver', 'drives', 'dubbed', 'dumped', 'during', 'duties', 'earned', 'easier', 'easily', 'easing',
    'easter',
    'eating', 'edison', 'edited', 'editor', 'edward', 'effect', 'effort', 'eighth', 'either', 'emerge',
    'empire',
    'employ', 'enable', 'ending', 'energy', 'engage', 'engine', 'enough', 'ensure', 'enters', 'entire',
    'entity',
    'equals', 'equity', 'errors', 'escape', 'estate', 'ethics', 'ethnic', 'eugene', 'europe', 'events',
    'everex',
    'exceed', 'except', 'excess', 'excuse', 'exempt', 'exists', 'exotic', 'expand', 'expect', 'expert',
    'expire',
    'export', 'extend', 'extent', 'facing', 'factor', 'failed', 'fairly', 'fallen', 'family', 'famous',
    'farmer',
    'faster', 'father', 'faults', 'faulty', 'favors', 'favour', 'feared', 'fellow', 'felony', 'female',
    'fields',
    'fierce', 'figure', 'filing', 'filled', 'filter', 'finder', 'finger', 'finish', 'finite', 'firing',
    'firmly',
    'fiscal', 'fisher', 'fitted', 'flames', 'flight', 'floors', 'floppy', 'flying', 'folder', 'follow',
    'forced',
    'forces', 'forest', 'forget', 'formal', 'format', 'formed', 'former', 'foster', 'fought', 'fourth',
    'foxpro',
    'frames', 'france', 'francs', 'freely', 'freeze', 'french', 'friday', 'friend', 'frozen', 'fueled',
    'funded',
    'fusion', 'future', 'gained', 'gallon', 'gandhi', 'garage', 'garcia', 'garden', 'gather', 'geared',
    'geneva',
    'george', 'gerald', 'german', 'giants', 'giving', 'global', 'golden', 'gordon', 'gotten', 'grades',
    'graham',
    'grains', 'grants', 'graphs', 'greece', 'greene', 'ground', 'groups', 'growth', 'guards', 'guests',
    'guides',
    'guilty', 'gunman', 'gunmen', 'habits', 'hailed', 'halted', 'hammer', 'handed', 'handle', 'hanson',
    'happen',
    'harbor', 'harder', 'hardly', 'harold', 'harris', 'harvey', 'having', 'hawaii', 'hazard', 'headed',
    'header',
    'health', 'hearts', 'heated', 'height', 'helmut', 'helped', 'herald', 'heroin', 'hidden', 'hiding',
    'higher',
    'highly', 'hilton', 'hinted', 'hiring', 'holder', 'holmes', 'honest', 'hoping', 'horses', 'hotels',
    'hourly',
    'houses', 'howard', 'hudson', 'hughes', 'humans', 'hunger', 'hunter', 'hutton', 'hybrid', 'ignore',
    'images',
    'immune', 'impact', 'import', 'impose', 'inches', 'income', 'indeed', 'indian', 'infant', 'inform',
    'ingres',
    'injury', 'inputs', 'insert', 'inside', 'insist', 'intact', 'intend', 'intent', 'invest', 'invoke',
    'iraqis',
    'irvine', 'irving', 'island', 'israel', 'issued', 'issues', 'itself', 'jacket', 'jacobs', 'jailed',
    'jersey',
    'jewish', 'joined', 'jordan', 'joseph', 'judged', 'judges', 'jumped', 'junior', 'jurors', 'kansas',
    'kernel',
    'kicked', 'kidder', 'kidney', 'killed', 'killer', 'korean', 'kuwait', 'labels', 'labour', 'lacked',
    'landed',
    'laptop', 'larger', 'lasers', 'lasted', 'lately', 'latest', 'latter', 'launch', 'lawyer', 'layers',
    'laying',
    'layout', 'leader', 'league', 'leased', 'leases', 'leaves', 'lehman', 'length', 'lesser', 'lesson',
    'letter',
    'levels', 'levine', 'liable', 'lifted', 'lights', 'likely', 'limits', 'linear', 'linked', 'liquid',
    'liquor',
    'listed', 'listen', 'little', 'living', 'loaded', 'locate', 'locked', 'london', 'longer', 'looked',
    'losers',
    'losing', 'losses', 'lowest', 'luxury', 'macros', 'madrid', 'mailed', 'mainly', 'makers', 'makeup',
    'making',
    'manage', 'manila', 'manner', 'manual', 'manuel', 'marcos', 'margin', 'marine', 'marion', 'marked',
    'market',
    'marlin', 'martin', 'masses', 'master', 'matrix', 'matter', 'mature', 'mbytes', 'mecham', 'median',
    'medium',
    'member', 'memory', 'mental', 'merely', 'merged', 'merger', 'merits', 'messrs', 'metals', 'meters',
    'method',
    'metric', 'mexico', 'michel', 'midday', 'middle', 'milken', 'miller', 'miners', 'mining', 'minute',
    'mirror',
    'missed', 'mixing', 'mobile', 'models', 'modems', 'modern', 'modest', 'modify', 'module', 'moment',
    'monday',
    'months', 'morgan', 'morris', 'morton', 'grove', 'refactor', 'mostly', 'mother', 'motion', 'motive',
    'motors',
    'movies', 'moving', 'murder', 'murphy', 'murray', 'muscle', 'museum', 'mutual', 'myself', 'naming',
    'narrow',
    'nasdaq', 'nation', 'native', 'nature', 'nearby', 'nearly', 'needed', 'nelson', 'neural', 'nevada',
    'newark',
    'newest', 'nickel', 'nights', 'nikkei', 'nippon', 'nissan', 'nobody', 'nomura', 'normal', 'norman',
    'norton',
    'norway', 'notice', 'notify', 'noting', 'notion', 'novell', 'nuclei', 'number', 'nurses', 'object',
    'obtain',
    'occurs', 'offers', 'office', 'offset', 'oldest', 'oliver', 'online', 'opened', 'openly', 'oppose',
    'option',
    'oracle', 'orange', 'orders', 'oregon', 'organs', 'origin', 'ortega', 'others', 'ottawa', 'ounces',
    'ousted',
    'outlet', 'output', 'owners', 'oxford', 'oxygen', 'packed', 'packet', 'palace', 'panama', 'panels',
    'papers',
    'parade', 'parent', 'parity', 'parked', 'parker', 'parole', 'partly', 'pascal', 'passed', 'passes',
    'patent',
    'patrol', 'paying', 'people', 'period', 'permit', 'person', 'phases', 'philip', 'phones', 'photos',
    'phrase',
    'picked', 'pickup', 'pieces', 'pierce', 'pilots', 'pixels', 'placed', 'places', 'plains', 'planes',
    'planet',
    'plants', 'plasma', 'plates', 'played', 'player', 'please', 'pledge', 'plenty', 'plunge', 'pocket',
    'points',
    'poison', 'poland', 'police', 'policy', 'polish', 'poorly', 'ported', 'postal', 'posted', 'pounds',
    'poured',
    'powder', 'powell', 'powers', 'prague', 'praise', 'prayer', 'prefer', 'pretax', 'pretty', 'priced',
    'prices',
    'priest', 'prince', 'prints', 'prison', 'profit', 'prompt', 'proper', 'proved', 'proven', 'proves',
    'public',
    'puerto', 'pulled', 'purely', 'pursue', 'pushed', 'quayle', 'quebec', 'quotas', 'quoted', 'quotes',
    'racial',
    'racing', 'radius', 'raised', 'raises', 'random', 'ranged', 'ranges', 'ranked', 'rarely', 'rather',
    'rating',
    'ratios', 'reader', 'reagan', 'really', 'reason', 'rebels', 'recall', 'recent', 'record', 'redeem',
    'reduce',
    'refers', 'reform', 'refuge', 'refund', 'refuse', 'regain', 'regard', 'regime', 'region', 'reject',
    'relate',
    'relied', 'relief', 'relies', 'remain', 'remote', 'remove', 'rental', 'reopen', 'repair', 'repeat',
    'report',
    'rescue', 'reside', 'resign', 'resist', 'resort', 'result', 'resume', 'retail', 'retain', 'retire',
    'return',
    'reveal', 'review', 'revise', 'revive', 'revolt', 'reward', 'riding', 'rifles', 'rights', 'rising',
    'rivals',
    'rivers', 'robert', 'robins', 'robust', 'rocket', 'rogers', 'rolled', 'ronald', 'rounds', 'router',
    'routes',
    'rubber', 'ruling', 'rumors', 'runoff', 'runway', 'rushed', 'russia', 'saddam', 'safely', 'safety',
    'salary',
    'salmon', 'sample', 'samuel', 'saving', 'saying', 'scaled', 'scales', 'scarce', 'scared', 'scenes',
    'scheme',
    'school', 'scored', 'scores', 'screen', 'script', 'scroll', 'sealed', 'search', 'season', 'second',
    'secret',
    'sector', 'secure', 'seeing', 'seemed', 'seized', 'seldom', 'select', 'seller', 'senate', 'senior',
    'serial',
    'series', 'served', 'server', 'serves', 'settle', 'severe', 'metro', 'shades', 'shadow', 'shamir',
    'shapes',
    'shared', 'shares', 'sharon', 'sheets', 'shells', 'shield', 'shifts', 'shiite', 'should', 'showed',
    'shrink',
    'shultz', 'sierra', 'signal', 'signed', 'silent', 'silver', 'simple', 'simply', 'singer', 'single',
    'sister',
    'skills', 'slated', 'slides', 'slight', 'slowed', 'slower', 'slowly', 'smooth', 'soared', 'soccer',
    'social',
    'socket', 'sodium', 'solely', 'solved', 'sooner', 'sought', 'sounds', 'source', 'soviet', 'spaces',
    'speaks',
    'speech', 'speeds', 'spends', 'spirit', 'spoken', 'sports', 'spread', 'spring', 'sprint', 'square',
    'stable',
    'stacks', 'staged', 'stages', 'stakes', 'stalin', 'stance', 'stands', 'starts', 'stated', 'states',
    'static',
    'status', 'stayed', 'steady', 'steven', 'stocks', 'stolen', 'stones', 'stored', 'stores', 'storms',
    'strain',
    'stream', 'street', 'stress', 'strict', 'strike', 'string', 'stroke', 'strong', 'struck', 'stuart',
    'studio',
    'styles', 'submit', 'subset', 'subtle', 'suburb', 'subway', 'sudden', 'suffer', 'suited', 'sulfur',
    'summer',
    'summit', 'sunday', 'sunset', 'supply', 'surely', 'surged', 'survey', 'sweden', 'switch', 'sybase',
    'sydney',
    'symbol', 'syntax', 'syrian', 'system', 'tables', 'tackle', 'taiwan', 'taking', 'talent', 'talked',
    'tandem',
    'tanker', 'target', 'tariff', 'taught', 'taylor', 'tehran', 'temple', 'tended', 'tender', 'tennis',
    'tenure',
    'tested', 'texaco', 'thanks', 'themes', 'theory', 'things', 'thinks', 'thomas', 'though', 'thread',
    'threat',
    'thrift', 'thrown', 'thrust', 'ticket', 'timber', 'timely', 'timing', 'tissue', 'titles', 'tonnes',
    'topics',
    'topped', 'totals', 'toward', 'toyota', 'tracks', 'traded', 'trader', 'trades', 'trains', 'travel',
    'treaty',
    'trends', 'trials', 'tribal', 'triple', 'troops', 'trucks', 'trusts', 'trying', 'tumors', 'tunnel',
    'turkey',
    'turned', 'turner', 'typing', 'unable', 'unfair', 'unions', 'unique', 'united', 'unless', 'unlike',
    'unrest',
    'update', 'upheld', 'upward', 'urgent', 'urging', 'usable', 'useful', 'vacant', 'vacuum', 'valley',
    'valued',
    'values', 'varied', 'varies', 'vector', 'vendor', 'verify', 'versus', 'vessel', 'viable', 'victim',
    'victor',
    'vienna', 'viewed', 'virgin', 'vision', 'visits', 'visual', 'voices', 'volume', 'voters', 'voting',
    'waited',
    'walesa', 'walked', 'walker', 'walter', 'wanted', 'warned', 'warner', 'warren', 'warsaw', 'wastes',
    'waters',
    'watson', 'weaken', 'weaker', 'wealth', 'weapon', 'weekly', 'weighs', 'weight', 'whites', 'wholly',
    'widely',
    'wilson', 'window', 'winner', 'winter', 'wiring', 'wisdom', 'wishes', 'within', 'witter', 'wonder',
    'wooden',
    'worked', 'worker', 'wounds', 'wright', 'writer', 'writes', 'yearly', 'yellow', 'yields', 'youths',
    'zenith',
    'zurich', 'abandon', 'ability', 'absence', 'academy', 'accepts', 'account', 'accused', 'achieve',
    'acquire',
    'actions', 'actress', 'adapted', 'adapter', 'address', 'adopted', 'advance', 'adverse', 'advised',
    'adviser',
    'affairs', 'affects', 'african', 'against', 'airline', 'airport', 'airways', 'alabama', 'alberta',
    'alcohol',
    'alleged', 'alleges', 'allowed', 'already', 'altered', 'amazing', 'amended', 'america', 'amnesty',
    'amounts',
    'analyst', 'analyze', 'ancient', 'angeles', 'angular', 'animals', 'another', 'answers', 'anthony',
    'antonio',
    'anxious', 'anybody', 'anymore', 'apparel', 'appeals', 'appears', 'applied', 'applies', 'approve',
    'arguing',
    'arising', 'arizona', 'armenia', 'armored', 'arrange', 'arrests', 'arrival', 'arrived', 'arrives',
    'article',
    'artists', 'aspects', 'whittle', 'asserts', 'assumed', 'assumes', 'assured', 'atlanta', 'attacks',
    'attempt',
    'attract', 'auction', 'austria', 'authors', 'autocad', 'average', 'avoided', 'awarded', 'awkward',
    'backers',
    'backing', 'backlog', 'backups', 'baghdad', 'bailout', 'balance', 'balloon', 'ballots', 'bancorp',
    'bankers',
    'banking', 'banning', 'barbara', 'bargain', 'barrels', 'barrier', 'barring', 'battery', 'battles',
    'beaches',
    'bearing', 'bearish', 'beating', 'because', 'becomes', 'beijing', 'belgian', 'belgium', 'believe',
    'belongs',
    'beneath', 'benefit', 'bennett', 'bentsen', 'bernard', 'besides', 'betting', 'between', 'beverly',
    'bidders',
    'bidding', 'biggest', 'billing', 'billion', 'binding', 'bishops', 'blanket', 'blocked', 'bolster',
    'bombing',
    'bonuses', 'booming', 'boosted', 'borders', 'borland', 'bottles', 'boycott', 'bradley', 'brennan',
    'bridges',
    'briefly', 'britain', 'british', 'broaden', 'broader', 'broadly', 'brokers', 'brother', 'brought',
    'budgets',
    'buffalo', 'buffers', 'buildup', 'bullets', 'bullion', 'bullish', 'bundled', 'pickle', 'burning',
    'bushels',
    'buttons', 'cabinet', 'cabling', 'caching', 'callers', 'calling', 'cameras', 'campeau', 'capable',
    'capital',
    'capitol', 'captain', 'capture', 'carbide', 'careers', 'careful', 'carried', 'carrier', 'carries',
    'catalog',
    'causing', 'caution', 'ceiling', 'centers', 'central', 'centres', 'century', 'ceramic', 'certain',
    'chamber',
    'chances', 'changed', 'changes', 'channel', 'chapter', 'charged', 'charges', 'charity', 'charles',
    'charter',
    'chassis', 'cheaper', 'checked', 'checker', 'chevron', 'chicago', 'chicken', 'chinese', 'choices',
    'chronic',
    'circles', 'circuit', 'citizen', 'claimed', 'clarify', 'clashes', 'classes', 'classic', 'clayton',
    'cleanup',
    'cleared', 'clearly', 'clients', 'climate', 'climbed', 'clinics', 'clinton', 'clipper', 'closely',
    'closest',
    'closing', 'closure', 'clothes', 'cluster', 'coastal', 'cocaine', 'coleman', 'collect', 'college',
    'collins',
    'columns', 'combine', 'comfort', 'command', 'comment', 'commons', 'compact', 'company', 'compare',
    'compete',
    'compile', 'complex', 'concede', 'concept', 'concern', 'concert', 'conduct', 'confirm', 'conform',
    'connect',
    'consent', 'consist', 'console', 'contact', 'contain', 'contend', 'content', 'contest', 'context',
    'contras',
    'control', 'convert', 'cooking', 'cooling', 'copying', 'correct', 'costing', 'council', 'counsel',
    'counted',
    'counter', 'country', 'coupled', 'couples', 'courier', 'courses', 'covered', 'crashed', 'crashes',
    'created',
    'creates', 'credits', 'critics', 'crossed', 'crowded', 'crucial', 'crushed', 'crystal', 'cuellar',
    'culture',
    'current', 'custody', 'customs', 'cutting', 'damaged', 'damages', 'dancing', 'dangers', 'dealers',
    'dealing',
    'decades', 'decided', 'decides', 'declare', 'decline', 'default', 'defects', 'defence', 'defense',
    'deficit',
    'defined', 'defines', 'degrees', 'delayed', 'deleted', 'deliver', 'demands', 'denmark', 'density',
    'denying',
    'depends', 'deposit', 'derived', 'deserve', 'designs', 'desired', 'desktop', 'despite', 'destroy',
    'details',
    'detroit', 'develop', 'devices', 'devised', 'devoted', 'diagram', 'dialing', 'diamond', 'differs',
    'digital',
    'dilemma', 'diluted', 'dioxide', 'discuss', 'disease', 'dismiss', 'display', 'dispute', 'distant',
    'diverse',
    'divided', 'divorce', 'doctors', 'dollars', 'donated', 'doubled', 'douglas', 'drafted', 'dragged',
    'drastic',
    'drawing', 'dressed', 'drivers', 'driving', 'dropped', 'drought', 'cucumber', 'dumping', 'durable',
    'dynamic',
    'earlier', 'earning', 'easiest', 'eastern', 'eastman', 'economy', 'editing', 'edition', 'editors',
    'edwards',
    'effects', 'efforts', 'elderly', 'elected', 'elegant', 'element', 'embargo', 'embassy', 'emerged',
    'employs',
    'emulate', 'enabled', 'enables', 'enacted', 'endorse', 'enemies', 'enforce', 'engaged', 'engines',
    'england',
    'english', 'enhance', 'enjoyed', 'ensures', 'entered', 'entries', 'episode', 'equally', 'erosion',
    'erupted',
    'escaped', 'essence', 'ethical', 'evening', 'evident', 'evolved', 'exactly', 'examine', 'example',
    'exceeds',
    'excited', 'exclude', 'execute', 'exhaust', 'exhibit', 'existed', 'expects', 'expense', 'experts',
    'expired',
    'expires', 'explain', 'kindness', 'explore', 'exports', 'exposed', 'express', 'extends', 'extract',
    'extreme',
    'faction', 'factors', 'factory', 'faculty', 'failing', 'failure', 'falling', 'farmers', 'farming',
    'fashion',
    'fastest', 'fatigue', 'favored', 'feature', 'federal', 'feeding', 'feeling', 'fighter', 'figured',
    'figures',
    'filings', 'filling', 'filters', 'finally', 'finance', 'finding', 'fingers', 'finland', 'fishing',
    'fission',
    'fleeing', 'flights', 'flooded', 'florida', 'flowers', 'flowing', 'focused', 'focuses', 'folders',
    'follows',
    'forcing', 'foreign', 'forests', 'forever', 'formats', 'forming', 'formula', 'fortran', 'fortune',
    'forward',
    'founded', 'founder', 'fragile', 'francis', 'freedom', 'freeman', 'freight', 'fremont', 'friends',
    'fujitsu',
    'fulfill', 'funding', 'funeral', 'furnace', 'further', 'futures', 'gainers', 'gaining', 'gallery',
    'gallons',
    'garbage', 'gateway', 'general', 'generic', 'genetic', 'genuine', 'georgia', 'germans', 'germany',
    'gesture',
    'getting', 'gilbert', 'glasses', 'goldman', 'grabbed', 'gradual', 'granted', 'graphic', 'gravity',
    'greater',
    'greatly', 'greeted', 'gregory', 'grocery', 'grounds', 'growers', 'growing', 'gunfire', 'handful',
    'handled',
    'handles', 'hanging', 'hanover', 'happens', 'hardest', 'harvard', 'harvest', 'hazards', 'heading',
    'healthy',
    'hearing', 'heating', 'heavily', 'helpful', 'helping', 'herbert', 'herself', 'higgins', 'highest',
    'highway',
    'himself', 'history', 'hitting', 'hoffman', 'holders', 'holding', 'holiday', 'honored', 'hopeful',
    'hopkins',
    'horizon', 'hostage', 'hostile', 'housing', 'houston', 'however', 'hundred', 'hungary', 'hunting',
    'husband',
    'hussein', 'hyundai', 'ignored', 'illegal', 'illness', 'imagine', 'imaging', 'impacts', 'implies',
    'imports',
    'imposed', 'improve', 'include', 'incomes', 'indexed', 'indexes', 'indiana', 'indians', 'induced',
    'initial',
    'injured', 'inmates', 'inquiry', 'insider', 'insists', 'install', 'instant', 'instead', 'insured',
    'insurer',
    'integer', 'intends', 'intense', 'interim', 'invaded', 'invited', 'invoked', 'involve', 'iranian',
    'ireland',
    'islamic', 'islands', 'israeli', 'issuing', 'italian', 'jackson', 'january', 'jeffrey', 'jewelry',
    'jobless',
    'johnson', 'joining', 'jointly', 'journal', 'journey', 'jumping', 'justice', 'justify', 'keating',
    'keeping',
    'kennedy', 'kenneth', 'killing', 'kingdom', 'kitchen', 'knocked', 'knowing', 'kremlin', 'kuwaiti',
    'labeled',
    'lacking', 'lambert', 'landing', 'laptops', 'largely', 'largest', 'lasting', 'lattice', 'lawsuit',
    'lawyers',
    'layoffs', 'leaders', 'leading', 'learned', 'leasing', 'leaving', 'lebanon', 'leftist', 'legally',
    'lenders',
    'lending', 'lengths', 'lengthy', 'leonard', 'lesions', 'lessons', 'letters', 'letting', 'liberal',
    'liberty',
    'library', 'licence', 'license', 'lifting', 'lighter', 'limited', 'lincoln', 'linking', 'listing',
    'loading',
    'locally', 'located', 'locking', 'logging', 'logical', 'longest', 'looking', 'lorenzo', 'lottery',
    'lowered',
    'loyalty', 'machine', 'macweek', 'madison', 'mailing', 'managed', 'manager', 'manages', 'mandate',
    'mandela',
    'manuals', 'mapping', 'marched', 'margins', 'marines', 'markets', 'marking', 'married', 'martial',
    'marxist',
    'massive', 'matched', 'matches', 'matters', 'maximum', 'maxwell', 'meaning', 'measure', 'medical',
    'meeting',
    'members', 'memphis', 'mention', 'mercury', 'mergers', 'merging', 'merrill', 'message', 'methane',
    'methods',
    'mexican', 'michael', 'mideast', 'midland', 'midwest', 'migrate', 'mikhail', 'militia', 'million',
    'mineral',
    'minimal', 'minimum', 'minutes', 'missile', 'missing', 'mission', 'mistake', 'mixture', 'modular',
    'modules',
    'moments', 'monitor', 'montana', 'monthly', 'morning', 'industrial', 'mothers', 'mounted', 'murders',
    'musical',
    'mystery', 'nabisco', 'namibia', 'nations', 'natural', 'nearest', 'neither', 'nervous', 'netbios',
    'netview',
    'netware', 'network', 'neutral', 'neutron', 'newport', 'newwave', 'nominal', 'nominee', 'noriega',
    'notable',
    'notably', 'nothing', 'noticed', 'notices', 'nowhere', 'nuclear', 'numbers', 'numeric', 'nursing',
    'oakland',
    'objects', 'obscure', 'observe', 'obvious', 'october', 'offense', 'offered', 'officer', 'offices',
    'olympic',
    'ongoing', 'ontario', 'opening', 'operate', 'opinion', 'opposed', 'opposes', 'optical', 'optimal',
    'optimum',
    'options', 'ordered', 'organic', 'orlando', 'orleans', 'outcome', 'outdoor', 'outlets', 'outline',
    'outlook',
    'outside', 'overall', 'overlay', 'oversee', 'pacific', 'package', 'packets', 'painful', 'painted',
    'palette',
    'paradox', 'parents', 'parking', 'partial', 'parties', 'partner', 'passage', 'passing', 'passive',
    'patents',
    'patient', 'patrick', 'pattern', 'payable', 'payment', 'payroll', 'peabody', 'penalty', 'pending',
    'pension',
    'percent', 'perfect', 'perform', 'perhaps', 'periods', 'permits', 'persian', 'persons', 'philips',
    'phoenix',
    'physics', 'picking', 'picture', 'placing', 'plagued', 'planned', 'planted', 'plastic', 'players',
    'playing',
    'pleaded', 'pleased', 'pledged', 'plunged', 'pockets', 'pointed', 'pointer', 'polling', 'popular',
    'portion',
    'posting', 'poverty', 'powered', 'praised', 'precise', 'predict', 'prefers', 'premier', 'premium',
    'prepare',
    'present', 'pressed', 'prevent', 'preview', 'pricing', 'priests', 'primary', 'printed', 'printer',
    'privacy',
    'private', 'problem', 'proceed', 'process', 'produce', 'product', 'profile', 'profits', 'program',
    'project',
    'promise', 'promote', 'prompts', 'propose', 'protect', 'protein', 'protest', 'provide', 'proving',
    'publish',
    'pulling', 'pumping', 'purpose', 'pursued', 'pursuit', 'pushing', 'putting', 'qualify', 'quality',
    'quantum',
    'quarter', 'quattro', 'queries', 'quickly', 'quietly', 'radical', 'railway', 'raising', 'rallied',
    'rallies',
    'ranging', 'ranking', 'rapidly', 'ratings', 'raymond', 'reached', 'reaches', 'reacted', 'reactor',
    'readers',
    'readily', 'reading', 'reality', 'realize', 'reasons', 'rebound', 'rebuild', 'recalls', 'receipt',
    'receive',
    'records', 'recover', 'reduced', 'reduces', 'refined', 'reflect', 'reforms', 'refugee', 'refusal',
    'refused',
    'refuses', 'regions', 'regular', 'related', 'relaxed', 'release', 'relying', 'remains', 'remarks',
    'removal',
    'removed', 'renewal', 'renewed', 'repairs', 'replace', 'replied', 'reports', 'request', 'require',
    'rescued',
    'reserve', 'resides', 'resolve', 'respect', 'respond', 'restore', 'results', 'resumed', 'retains',
    'retired',
    'retreat', 'returns', 'reuters', 'reveals', 'revenue', 'reverse', 'reviews', 'revised', 'revival',
    'rewards',
    'richard', 'richter', 'robbery', 'roberts', 'rockets', 'rolling', 'romania', 'roughly', 'routers',
    'routine',
    'routing', 'rulings', 'running', 'russell', 'russian', 'saatchi', 'sailors', 'salinas', 'salomon',
    'samples',
    'satisfy', 'savings', 'scaling', 'scandal', 'scanned', 'scanner', 'schemes', 'schools', 'science',
    'scoring',
    'scratch', 'screens', 'scripts', 'seagate', 'seasons', 'seattle', 'seconds', 'secrets', 'section',
    'sectors',
    'secured', 'seeking', 'segment', 'seismic', 'sellers', 'selling', 'seminar', 'senator', 'sending',
    'sensors',
    'serious', 'servers', 'service', 'serving', 'session', 'setback', 'setting', 'settled', 'seventh',
    'several',
    'shallow', 'sharing', 'sharply', 'shelter', 'shelves', 'shifted', 'shipped', 'shocked', 'shorter',
    'shortly',
    'shouted', 'showers', 'showing', 'shuttle', 'siemens', 'signals', 'signing', 'silence', 'silicon',
    'similar',
    'simmons', 'simpler', 'simpson', 'singing', 'singled', 'sitting', 'sizable', 'skilled', 'slashed',
    'slaying',
    'slipped', 'slowing', 'slumped', 'smaller', 'smoking', 'soaring', 'society', 'soldier', 'solvent',
    'solving',
    'somehow', 'someone', 'sorting', 'sounded', 'sources', 'soviets', 'soybean', 'spacing', 'spanish',
    'sparked',
    'spatial', 'speaker', 'special', 'species', 'specify', 'spectra', 'spencer', 'spirits', 'sponsor',
    'spotted',
    'springs', 'spurred', 'squeeze', 'stadium', 'staging', 'stalled', 'stanley', 'started', 'startup',
    'station',
    'statute', 'staying', 'stearns', 'stemmed', 'stephen', 'stepped', 'stevens', 'stewart', 'stomach',
    'stopped',
    'storage', 'stories', 'storing', 'strains', 'strange', 'streams', 'streets', 'stretch', 'strikes',
    'strings',
    'student', 'studied', 'studies', 'subject', 'subsidy', 'suburbs', 'succeed', 'success', 'suffers',
    'suggest',
    'suicide', 'summary', 'support', 'suppose', 'supreme', 'surface', 'surgery', 'surplus', 'surveys',
    'survive',
    'suspect', 'suspend', 'sustain', 'swedish', 'symbols', 'systems', 'tactics', 'talking', 'tankers',
    'targets',
    'tariffs', 'taxable', 'teacher', 'telecom', 'telling', 'tenants', 'tension', 'testify', 'testing',
    'textile',
    'theater', 'therapy', 'thereby', 'thermal', 'thomson', 'thought', 'threats', 'thrifts', 'through',
    'tickets',
    'tighten', 'tighter', 'tightly', 'timothy', 'tobacco', 'tonight', 'toolkit', 'toronto', 'toshiba',
    'totaled',
    'totally', 'touched', 'tougher', 'tourism', 'tourist', 'towards', 'traders', 'trading', 'traffic',
    'tragedy',
    'trained', 'transit', 'trapped', 'treated', 'tribune', 'trigger', 'tritium', 'trouble', 'trustee',
    'tuesday',
    'tumbled', 'turbine', 'turkish', 'turmoil', 'turning', 'twisted', 'typical', 'ukraine', 'unaware',
    'unclear',
    'undergo', 'unhappy', 'unified', 'uniform', 'unknown', 'unusual', 'updated', 'updates', 'upgrade',
    'uranium',
    'usually', 'utility', 'utilize', 'vaccine', 'variety', 'various', 'varying', 'vatican', 'vehicle',
    'vendors',
    'ventura', 'venture', 'verdict', 'vermont', 'version', 'vessels', 'veteran', 'victims', 'victory',
    'vietnam',
    'viewers', 'viewing', 'village', 'vincent', 'violate', 'violent', 'virtual', 'viruses', 'visible',
    'visited',
    'voltage', 'volumes', 'waiting', 'walking', 'wallace', 'wanting', 'warburg', 'warfare', 'warming',
    'warning',
    'warrant', 'watched', 'watkins', 'wealthy', 'weapons', 'wearing', 'weather', 'wedding', 'weekend',
    'weighed',
    'welcome', 'welfare', 'western', 'whereas', 'whether', 'whitney', 'widened', 'william', 'willing',
    'windows',
    'winners', 'winning', 'without', 'witness', 'workers', 'working', 'worried', 'worries', 'wounded',
    'wrapped',
    'writers', 'writing', 'written', 'wyoming', 'wysiwyg', 'yeltsin', 'yielded', 'yitzhak', 'younger',
    'zealand',
    'abortion', 'absolute', 'absorbed', 'abstract', 'academic', 'accepted', 'accessed', 'accident',
    'accounts',
    'accuracy', 'accurate', 'accusing', 'achieved', 'acquired', 'actively', 'activist', 'activity',
    'actually',
    'adapters', 'addition', 'adequate', 'adjacent', 'adjusted', 'admitted', 'adollars', 'adopting',
    'adoption',
    'advanced', 'advances', 'advisers', 'advisory', 'advocate', 'affected', 'agencies', 'agreeing',
    'aircraft',
    'airlines', 'airplane', 'airports', 'alleging', 'alliance', 'allocate', 'allowing', 'although',
    'aluminum',
    'american', 'amounted', 'analyses', 'analysis', 'analysts', 'analyzed', 'analyzer', 'andersen',
    'anderson',
    'animated', 'announce', 'annually', 'answered', 'anything', 'anywhere', 'apparent', 'appealed',
    'appeared',
    'applying', 'approach', 'approval', 'approved', 'argument', 'arkansas', 'armenian', 'arranged',
    'arrested',
    'arriving', 'articles', 'artistic', 'asbestos', 'assembly', 'asserted', 'assessed', 'assigned',
    'assuming',
    'atlantic', 'atlantis', 'attached', 'attacked', 'attempts', 'attended', 'attitude', 'attorney',
    'audience',
    'auditors', 'austrian', 'automate', 'autonomy', 'averaged', 'averages', 'aviation', 'avoiding',
    'awaiting',
    'backbone', 'backward', 'bacteria', 'balanced', 'barclays', 'barriers', 'baseball', 'basement',
    'battered',
    'battling', 'becoming', 'behavior', 'believed', 'believes', 'benefits', 'benjamin', 'berkeley',
    'billions',
    'birthday', 'blocking', 'bombings', 'boosting', 'borrowed', 'boundary', 'branches', 'breaking',
    'briefing',
    'bringing', 'broadway', 'brooklyn', 'brothers', 'brussels', 'building', 'bulgaria', 'bulletin',
    'business',
    'calendar', 'cambodia', 'campaign', 'campbell', 'canadian', 'canceled', 'capacity', 'captured',
    'cardinal',
    'carolina', 'carriers', 'carrying', 'cassette', 'catalyst', 'category', 'catholic', 'cautious',
    'cdollars',
    'cellular', 'cemetery', 'centered', 'ceremony', 'chairman', 'chambers', 'champion', 'changing',
    'channels',
    'chapters', 'charging', 'checking', 'chemical', 'children', 'choosing', 'chrysler', 'churches',
    'circuits',
    'citicorp', 'citizens', 'civilian', 'claiming', 'cleaning', 'clearing', 'clicking', 'climbing',
    'clinical',
    'closings', 'clothing', 'clusters', 'collapse', 'colleges', 'colombia', 'colorado', 'columbia',
    'columbus',
    'combined', 'combines', 'commands', 'comments', 'commerce', 'commonly', 'compared', 'compares',
    'compiled',
    'compiler', 'complain', 'complete', 'composed', 'compound', 'comprise', 'computed', 'computer',
    'conceded',
    'concedes', 'concepts', 'concerns', 'conclude', 'concrete', 'confined', 'conflict', 'confused',
    'congress',
    'connects', 'consider', 'consists', 'constant', 'consumed', 'consumer', 'contacts', 'contains',
    'contends',
    'contents', 'continue', 'contract', 'contrary', 'contrast', 'controls', 'converts', 'convince',
    'counties',
    'counting', 'coupling', 'coverage', 'covering', 'cracking', 'creating', 'creation', 'creative',
    'credited',
    'creditor', 'criminal', 'criteria', 'critical', 'crossing', 'crystals', 'cultural', 'currency',
    'customer',
    'cutbacks', 'cyclical', 'damaging', 'database', 'daughter', 'deadline', 'dealings', 'debugger',
    'december',
    'deciding', 'decision', 'declared', 'declined', 'declines', 'decrease', 'defaults', 'defeated',
    'defended',
    'deferred', 'deficits', 'defining', 'definite', 'delaware', 'delegate', 'delicate', 'delivers',
    'delivery',
    'demanded', 'democrat', 'deployed', 'deposits', 'deputies', 'describe', 'deserves', 'designed',
    'designer',
    'detailed', 'detained', 'detected', 'detector', 'deutsche', 'develops', 'diagrams', 'dialogue',
    'diameter',
    'dictator', 'diplomat', 'directed', 'directly', 'director', 'disabled', 'disagree', 'disaster',
    'disclose',
    'discount', 'discover', 'discrete', 'diseases', 'diskette', 'diskless', 'displays', 'disposal',
    'disputed',
    'disputes', 'distance', 'distinct', 'district', 'dividend', 'division', 'document', 'domestic',
    'dominant',
    'dominate', 'doubling', 'download', 'downtown', 'downturn', 'downward', 'drafting', 'dramatic',
    'drawback',
    'drawings', 'drilling', 'drinking', 'dropping', 'duration', 'dynamics', 'earliest', 'earnings',
    'economic',
    'editions', 'egyptian', 'election', 'electric', 'electron', 'elements', 'elevated', 'eligible',
    'embedded',
    'emerging', 'emission', 'emphasis', 'employed', 'employee', 'employer', 'enabling', 'endorsed',
    'energies',
    'engineer', 'enhanced', 'enormous', 'ensuring', 'entering', 'entirely', 'entities', 'entitled',
    'entrance',
    'envelope', 'equation', 'equipped', 'equities', 'estimate', 'ethernet', 'european', 'evaluate',
    'eventual',
    'everyone', 'evidence', 'examined', 'examines', 'examples', 'exceeded', 'exchange', 'exciting',
    'excluded',
    'executed', 'exercise', 'exhibits', 'existing', 'expanded', 'expected', 'expelled', 'expenses',
    'explains',
    'explicit', 'exploded', 'exported', 'exposure', 'extended', 'external', 'facility', 'factions',
    'failures',
    'familiar', 'families', 'favorite', 'feasible', 'featured', 'features', 'february', 'feedback',
    'feelings',
    'festival', 'fidelity', 'fighters', 'fighting', 'financed', 'finances', 'findings', 'finished',
    'flagship',
    'flexible', 'floating', 'flooding', 'focusing', 'followed', 'football', 'forecast', 'formally',
    'formerly',
    'formulas', 'fortunes', 'founding', 'fraction', 'fracture', 'franklin', 'freezing', 'frequent',
    'friction',
    'friendly', 'function', 'gambling', 'gasoline', 'gateways', 'gathered', 'generate', 'generous',
    'geometry',
    'gephardt', 'gillette', 'gonzalez', 'governor', 'graduate', 'granting', 'graphics', 'greatest',
    'guidance',
    'guinness', 'hamilton', 'hampered', 'handlers', 'handling', 'happened', 'hardware', 'harrison',
    'hartford',
    'headline', 'hearings', 'hercules', 'heritage', 'highways', 'hispanic', 'historic', 'holdings',
    'holidays',
    'homeland', 'homeless', 'hometown', 'honduras', 'hospital', 'hostages', 'hundreds', 'hydrogen',
    'identify',
    'identity', 'illinois', 'imminent', 'immunity', 'imperial', 'imported', 'imposing', 'improper',
    'improved',
    'improves', 'incident', 'inclined', 'included', 'includes', 'incoming', 'increase', 'incurred',
    'indexing',
    'indicate', 'indicted', 'indirect', 'industry', 'infected', 'informal', 'informed', 'informix',
    'infrared',
    'inherent', 'initiate', 'injected', 'injuries', 'injuring', 'innocent', 'inserted', 'insiders',
    'insisted',
    'inspired', 'instance', 'insurers', 'integral', 'intended', 'interact', 'interest', 'interior',
    'internal',
    'internet', 'interval', 'invasion', 'invented', 'invested', 'investor', 'invoices', 'involved',
    'involves',
    'iranians', 'isolated', 'israelis', 'issuance', 'japanese', 'jetliner', 'johnston', 'jonathan',
    'judgment',
    'judicial', 'justices', 'kentucky', 'keyboard', 'khomeini', 'killings', 'kohlberg', 'landmark',
    'language',
    'laserjet', 'launched', 'lawrence', 'lawsuits', 'learning', 'lebanese', 'leverage', 'licensed',
    'licenses',
    'lifetime', 'lighting', 'limiting', 'listings', 'lobbying', 'location', 'lockheed', 'longtime',
    'lowering',
    'machines', 'magazine', 'magellan', 'magnetic', 'mainland', 'maintain', 'majority', 'malaysia',
    'managers',
    'managing', 'manually', 'margaret', 'marginal', 'marketed', 'marriage', 'marshall', 'martinez',
    'maryland',
    'matching', 'material', 'maturity', 'mccarthy', 'meantime', 'measured', 'measures', 'medicaid',
    'medicare',
    'medicine', 'meetings', 'megabyte', 'memorial', 'memories', 'merchant', 'messages', 'michigan',
    'microvax',
    'midnight', 'midrange', 'military', 'millions', 'minimize', 'minister', 'ministry', 'minority',
    'missiles',
    'missions', 'missouri', 'mistakes', 'mitchell', 'mixtures', 'modeling', 'moderate', 'modified',
    'mohammed',
    'moisture', 'momentum', 'monetary', 'monitors', 'monopoly', 'montreal', 'moreover', 'morrison',
    'mortgage',
    'motorola', 'mountain', 'mounting', 'movement', 'multiple', 'narrowed', 'narrowly', 'national',
    'nebraska',
    'negative', 'neighbor', 'networks', 'nicholas', 'nintendo', 'nitrogen', 'normally', 'northern',
    'notebook',
    'notified', 'november', 'numerous', 'objected', 'observed', 'observer', 'obsolete', 'obstacle',
    'obtained',
    'occasion', 'occupied', 'occurred', 'offering', 'officers', 'official', 'offshore', 'oklahoma',
    'olivetti',
    'olympics', 'openness', 'operated', 'operates', 'operator', 'opinions', 'opponent', 'opposing',
    'opposite',
    'optimism', 'optimize', 'optional', 'ordering', 'ordinary', 'organize', 'oriented', 'original',
    'outgoing',
    'outlawed', 'outlined', 'outlines', 'outright', 'overcome', 'overhaul', 'overhead', 'override',
    'overseas',
    'oversees', 'overtime', 'overview', 'packaged', 'packages', 'painting', 'pakistan', 'parallel',
    'particle',
    'partners', 'password', 'patients', 'patricia', 'patterns', 'payments', 'peaceful', 'pennzoil',
    'pensions',
    'pentagon', 'performs', 'periodic', 'personal', 'persuade', 'peterson', 'petition', 'phillips',
    'physical',
    'pictures', 'pipeline', 'planners', 'planning', 'planting', 'plastics', 'platform', 'platinum',
    'pleasure',
    'plotting', 'pointers', 'pointing', 'polaroid', 'policies', 'politics', 'portable', 'portions',
    'portland',
    'portrait', 'portugal', 'position', 'positive', 'possible', 'possibly', 'potatoes', 'powerful',
    'practice',
    'precious', 'predicts', 'pregnant', 'premiums', 'prepared', 'presence', 'presents', 'preserve',
    'pressing',
    'pressure', 'prevents', 'previous', 'printers', 'printing', 'priority', 'prisoner', 'probable',
    'probably',
    'problems', 'proceeds', 'produced', 'producer', 'produces', 'products', 'profiles', 'programs',
    'progress',
    'prohibit', 'projects', 'promised', 'promises', 'promoted', 'prompted', 'promptly', 'properly',
    'property',
    'proposal', 'proposed', 'proposes', 'prospect', 'protests', 'protocol', 'provided', 'provider',
    'provides',
    'province', 'publicly', 'punitive', 'purchase', 'purposes', 'pursuant', 'pursuing', 'quantity',
    'quarters',
    'question', 'radicals', 'railroad', 'reaching', 'reaction', 'reactors', 'realized', 'recalled',
    'receipts',
    'received', 'receiver', 'receives', 'recently', 'recorded', 'recorder', 'recovery', 'reducing',
    'referred',
    'refinery', 'refining', 'reflects', 'refugees', 'refusing', 'regarded', 'regional', 'register',
    'regulate',
    'rejected', 'relating', 'relation', 'relative', 'released', 'releases', 'relevant', 'reliable',
    'relieved',
    'religion', 'remained', 'remember', 'removing', 'repeated', 'replaced', 'replaces', 'reported',
    'reporter',
    'republic', 'requests', 'required', 'requires', 'research', 'reseller', 'reserved', 'reserves',
    'resident',
    'residual', 'resigned', 'resisted', 'resolved', 'resource', 'respects', 'response', 'restored',
    'restrict',
    'resulted', 'retailer', 'retained', 'retiring', 'retrieve', 'returned', 'revealed', 'revenues',
    'reversal',
    'reversed', 'reviewed', 'revision', 'reynolds', 'rhetoric', 'richards', 'richmond', 'robinson',
    'rockwell',
    'romanian', 'rotation', 'routines', 'russians', 'salaries', 'salesman', 'salvador', 'sampling',
    'saturday',
    'scalable', 'scanners', 'scanning', 'scenario', 'schedule', 'sciences', 'scotland', 'scottish',
    'scrutiny',
    'searched', 'searches', 'seasonal', 'sections', 'security', 'segments', 'selected', 'seminars',
    'senators',
    'sensible', 'sentence', 'separate', 'sequence', 'services', 'sessions', 'settings', 'settling',
    'severely',
    'metropolis', 'shanghai', 'shearson', 'shelters', 'shifting', 'shipment', 'shipping', 'shooting',
    'shoppers',
    'shopping', 'shortage', 'shoulder', 'shutdown', 'simplify', 'simulate', 'sleeping', 'slightly',
    'slowdown',
    'sluggish', 'smallest', 'smoothly', 'software', 'soldiers', 'solution', 'somebody', 'sometime',
    'somewhat',
    'southern', 'soybeans', 'speakers', 'speaking', 'specific', 'spectral', 'spectrum', 'speeches',
    'spelling',
    'spending', 'sponsors', 'staffers', 'standard', 'standing', 'stanford', 'starring', 'starting',
    'stations',
    'steadily', 'steering', 'stemming', 'stepping', 'sterling', 'sticking', 'stopping', 'straight',
    'strategy',
    'strength', 'stressed', 'stresses', 'strictly', 'strikers', 'striking', 'stripped', 'stronger',
    'strongly',
    'struggle', 'students', 'studying', 'subjects', 'suburban', 'succeeds', 'suddenly', 'suffered',
    'suggests',
    'suitable', 'sullivan', 'superior', 'supplied', 'supplier', 'supplies', 'supports', 'supposed',
    'surfaced',
    'surfaces', 'surprise', 'surveyed', 'survival', 'survived', 'suspects', 'sweeping', 'swimming',
    'switched',
    'switches', 'symantec', 'symbolic', 'sympathy', 'symphony', 'symptoms', 'syndrome', 'tailored',
    'takeover',
    'targeted', 'taxpayer', 'teachers', 'teaching', 'template', 'tendency', 'tendered', 'tensions',
    'terminal',
    'terrible', 'thailand', 'thatcher', 'theaters', 'theories', 'thinking', 'thompson', 'thorough',
    'thoughts',
    'thousand', 'threaten', 'throwing', 'thursday', 'together', 'tomorrow', 'totaling', 'tourists',
    'tracking',
    'training', 'transfer', 'transmit', 'traveled', 'treasury', 'treating', 'trillion', 'tropical',
    'troubled',
    'troubles', 'truetype', 'trustees', 'turnover', 'tutorial', 'ultimate', 'umbrella', 'universe',
    'unlikely',
    'unstable', 'unveiled', 'upcoming', 'updating', 'upgraded', 'upgrades', 'uprising', 'utilized',
    'vacation',
    'validity', 'valuable', 'variable', 'vehicles', 'velocity', 'ventures', 'versions', 'vertical',
    'veterans',
    'villages', 'violated', 'violence', 'virginia', 'visiting', 'visitors', 'volatile', 'warnings',
    'warrants',
    'warranty', 'watching', 'weakened', 'weakness', 'weighing', 'weighted', 'welcomed', 'whatever',
    'whenever',
    'wildlife', 'williams', 'wireless', 'withdraw', 'withdrew', 'withheld', 'wordstar', 'worrying',
    'yielding',
    'yourself'
];

</script>
