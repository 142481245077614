<template>
    <div>
        <folder-navigation-actions type="topics"
                                   v-if="accountFeatures.newCollectionUx"></folder-navigation-actions>
        <template v-else>
            <p v-if=" !isReviewer && accountFeatures.newCollectionUx">
                <button @click="showTopicBuilder"
                        class="btn btn-outline-danger btn-sm full-width mt-2"><i
                    class="fa fa-plus"></i> New Topic
                </button>
            </p>
            <h3 class="subtitle d-flex">
            <span>
                Topics
            </span>
                <router-link
                    to="/topics"
                    class="text-danger sidebar-link ms-auto"
                >
                    Manage
                </router-link>
            </h3>
        </template>
        <topics-by-folder/>
        <p v-if=" !isReviewer && !accountFeatures.newCollectionUx">
            <button @click="showTopicBuilder"
                    class="btn btn-outline-danger btn-sm full-width mt-2"><i
                class="fa fa-plus"></i> New Topic
            </button>
        </p>

    </div>
</template>

<script>

import TopicsByFolder from './TopicsByFolder'
import Roles from '@/utilities/Roles'
import {mapGetters} from "vuex";
import FolderNavigationActions from "@/components/Folders/FolderNavigationActions";

export default {
    mixins: [Roles],
    components: {
        FolderNavigationActions,
        TopicsByFolder
    },
    methods: {
        showTopicBuilder() {
            window.Events.$emit('showQueryBuilderModal', {topic: false});
        },
    },
    computed: {
        ...mapGetters({
            accountFeatures: 'accountFeatures'
        })
    }
}
</script>

<style>
</style>
