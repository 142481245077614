<template>
    <section v-if="features.apiAdmin">
        <div class="row align-bottom">
            <label class="col col-12 col-lg-3">
                Name
                <input type="text" class="form-control form-control-sm"/>
            </label>
            <label class="col col-12 col-lg-3">
                Icon
                <input type="file" class="form-control form-control-sm"/>
            </label>
            <label class="col col-12 col-lg-3">
                <input type="checkbox"/>
                Publish
            </label>
        </div>
        <details>
            <summary >
                API Access
            </summary>
            <fieldset class="">
                <legend>Type of Access</legend>
                <ul class="ps-0">
                    <label class="d-flex align-items-center">
                        <input type="radio" name="apiType" value="client" v-model="apiAccess.type" class="me-2 form-check-input">
                        <div>I'm using the API only to access my account and want the simplest way to do that</div>
                    </label>
                    <label class="d-flex align-items-center">
                        <input type="radio" name="apiType" value="integration" v-model="apiAccess.type" class="me-2 form-check-input">
                        <div>I'm using the API to create an integration with another app or system to be available to all UpContent accounts. Users will have to approve access to UpContent for each account.</div>
                    </label>
                </ul>
            </fieldset>
            <ul class="no-bullets ps-0 d-flex flex-row flex-wrap" style="overflow: hidden;">
                <li v-for="resource in apiAccess.requested.scopes" :key="resource.label" style="width: 200px; border: 1px solid var(--bs-primary); border-radius: 1em; padding: 1em 1em; margin: .5em;">
                    <fieldset class="">
                        <legend style="font-size: 1.2em;" class="mb-0">{{resource.label}} </legend>
                        <label class="d-block small text-muted" v-if="resource.explanation === 'rw' && (resource.read || resource.write)">Reason <textarea class="d-block" type="text" v-model="resource.reason"></textarea></label>

                        <div>
                            <label class="d-block">
                                <input type="checkbox" v-model="resource.read"/> Read
                            </label>
                            <label class="d-block small text-muted" v-if="resource.explanation === 'r' && (resource.read)">Reason <textarea  class="d-block" type="text" v-model="resource.reason"></textarea></label>
                        </div>
                        <div>
                            <label class="d-block">
                                <input type="checkbox" v-model="resource.write"/> Write
                            </label>
                            <label class="d-block small text-muted" v-if="resource.explanation === 'w' && (resource.write)">
                                Reason
                                <textarea class="d-block" type="text" v-model="resource.reason"></textarea>
                            </label>
                        </div>
                    </fieldset>
                </li>
            </ul>
            <fieldset>
                <legend>Access Info</legend>
                <label class="d-block">
                    Client ID
                    <output class="form-control" >{{apiAccess.client_id}}</output>
                </label>
                <label class="d-block">
                    Client Secret
                    <output class="form-control">{{apiAccess.client_secret}}</output>
                </label>
            </fieldset>
        </details>
        <details>
            <summary>Share Configuration</summary>
            <label class="d-block">
                Mode
                <select class="form-control form-select form-control-sm">
                    <option>API/Webhook</option>
                    <option>Redirect</option>
                </select>
            </label>
            <label class="d-block">
                URL Template
                <input type="url" class="form-control form-control-sm"/>
            </label>
        </details>
        <details>
            <summary>Webhooks</summary>
            <label class="d-block">
                Authentication
                <select class="form-control form-select form-control-sm">
                    <option>OAUTH2</option>
                    <option>Static Auth Header</option>
                    <option>Basic HTTP</option>
                    <option>None</option>
                </select>
            </label>

            <label class="d-block">
                Client ID
                <input class="form-control form-control-sm"/>
            </label>
            <label class="d-block">
                Client Secret
                <input class="form-control form-control-sm"/>
            </label>
            <button class="btn btn-primary full-width" type="button">Add Webhook</button>
            <ul class="list-group no-bullets">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col col-12 col-lg-4">
                            <label class="d-block">
                                Name
                                <input type="text" class="form-control form-control-sm"  />
                            </label>
                            <label class="d-block">
                                <input type="checkbox"  />
                                Public
                            </label>
                        </div>
                        <div class="col col-12 col-lg-8">
                            <label class="d-block">
                                URL/URL Template
                                <input type="text" class="form-control form-control-sm"  />
                            </label>
                            <label class="d-block">
                                Optional Body Template
                                <textarea type="text" class="form-control form-control-sm"  ></textarea>
                            </label>
                        </div>
                    </div>
                </li>
                <li class="list-group-item bg-light">
                    <div class="row">
                        <div class="col col-12 col-lg-4">
                            <label class="d-block">
                                Name
                                <input type="text" class="form-control form-control-sm"  />
                            </label>
                            <label class="d-block">
                                <input type="checkbox"  />&nbsp;
                                Public
                            </label>
                        </div>
                        <div class="col col-12 col-lg-8">
                            <label class="d-block">
                                URL/URL Template
                                <input type="text" class="form-control form-control-sm"  />
                            </label>
                            <label class="d-block">
                                Optional Body Template
                                <textarea type="text" class="form-control form-control-sm"  ></textarea>
                            </label>
                        </div>
                    </div>
                </li>
            </ul>
        </details>
        <a :href="`mailto:${this.infoEmail}?subject=${encodeURIComponent(appName)} API Request Plan&body=${encodeURIComponent(apiMessage)}`" target="_blank" class="btn btn-primary">Request</a>
    </section>
</template>

<style>

</style>

<script>
    import { mapGetters } from 'vuex';
    import Integrations from './Integrations';

    export default {
        props : ['team'],
        components: {Integrations},
        computed : {
            ...mapGetters({
                apis: 'externalApis',
                features: 'features',
                appName: 'appName',
                infoEmail: 'infoEmail'
            }),
            apiMessage() {
                return 'Will show scopes and stuff here later'
            },
        },
        data() {
            return {
                apiAccess: {
                    client_id: 'aldskfjasldf',
                    client_secret: 'asdflaksdfj',
                    requested: {
                        type: 'client', //|'integration',
                        scopes: [
                            {label:'User Profile',resource: 'user',  explanation: 'w', read: true, write: false, reason: '', },
                            {label:'Accounts', resource: 'accounts', explanation: 'w', read: true, write: false, reason: '', },
                            {label:'Collections', resource: 'collections', explanation: 'w', read: true, write: false, reason: '', },
                            {label:'Collection Content', resource: 'collection_content', explanation: '', read: true, write: false, reason: '', },
                            {label:'Topics', resource: 'topics', explanation: 'rw', read: false, write: false, reason: '', },
                            {label:'Topic Content', resource: 'topic_content', explanation: 'rw', read: false, write: false, reason: '', },
                            {label:'Galleries', resource: 'galleries', explanation: 'rw', read: false, write: false, reason: '', },
                        ]
                    }
                },
            }
        }
    }
</script>
