<template>
    <modal class="modal fade" tabindex="-1" role="dialog" ref="modal" @close="$emit('change', '')">
                <template v-slot:header>
                    <h5 class="modal-title">Filters</h5>
                </template>
                <div>
                    <filter-list/>
                </div>
                <template v-slot:footer>
                    <button type="button" class="btn btn-secondary" @click="dismiss">Close</button>
                </template>
    </modal>
</template>

<script>

import * as $ from 'jquery'

import FilterList from './Suggestions'
import Modal from "../Controls/Modal";


export default {
    props: ['show'],
    components: {
        Modal,
        FilterList
    },
    data() {
        return {
            query: ''
        }
    },
    watch: {
        show(updated) {
            if (updated) {
                this.present()
            } else {
                this.dismiss()
            }
        }
    },
    mounted() {
        this.$refs.modal.$el.addEventListener('hidden.bs.modal', () => {
            this.$emit('closed')
        })
    },
    methods: {
        dismiss() {
            this.$refs.modal.hide()
        },
        present() {
            this.$refs.modal.show()
        }
    }
}
</script>

<style>
</style>
