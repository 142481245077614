<template>
    <div>
        <div class="mb-4">
        <filtered-collection
            @addSource="addSource"
            type="collection"
            collectionId=""
            :sources-busy="sourcesBusy"
            ref="addSourceForm"
        ></filtered-collection>
        <div class="error mb-2">{{ sourceErrorMessage }}</div>
        </div>
        <div v-if="sourcesBusy">
            <spinner class="list-spinner"></spinner>
        </div>
        <div v-else>
            <h3 v-if="isShowingSources">Collections in this Gallery</h3>
        <ul  class="list-group source-list">
            <gallery-collection-display  v-bind="source" v-for="source in sources" :key="source.key"
                                       @filtersUpdated="modifySource(source, $event)"
                                       :show-update-info="showUpdateInfo"
                                       @deleteSource="() => deleteSource(source)"
                                       @refreshSource="() => refreshSource(source)"
            >
            </gallery-collection-display>
        </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
h3 {
    font-size: 1em;
    color: var(--bs-dark);
    font-weight: 400;
}
</style>

<script>

import {mapGetters} from 'vuex';
import ProgressBar from '@/utilities/ProgressBar'
import Filters from '@/components/FilteringAndSorting/FiltersForm.vue'
import GalleryCollectionDisplay from '@/components/Galleries/GalleryCollectionDisplay'
import FilteredCollection from "@/components/Galleries/FilteredCollection";
// eslint-disable-next-line no-unused-vars
import {CollectionSourceViewModel} from "@/models/CollectionSourceViewModel";
import * as Controls from "@/components/Controls/index"

export default {
    components: {
        ...Controls, FilteredCollection, GalleryCollectionDisplay, Filters, ProgressBar
    },
    props: {
        type: {type: String},
        value: {type: Array},
        showUpdateInfo: {type: Boolean, default: false}
    },
    computed: {
        ...mapGetters({}),
        isShowingSources() {
            return this.sources && this.sources.length > 0;
        }
    },
    watch: {
        value() {
            this.sources = this.value;
        }
    },
    data() {
        return {
            sourcesBusy: false,
            sourceErrorMessage: '',
            sources: this.value || []
        }
    },
    methods: {
        addSource(source) {
            // this.sources.push();
            this.$emit('addSource', {
                collectionId: source.id,
                filterViewId: source.filterViewId,
                filters: {id: source.filterViewId}
            });
            // this.$emit('input', this.sources)
        },
        deleteSource(source) {
            this.$emit('deleteSource', source);
        },
        modifySource(source, value) {
            this.$emit('modifySource', {
                id: source.id,
                collectionId: source.collectionId,
                filterViewId: value.filterViewId,
                filters: {id: value.filterViewId}
            });
        },
        refreshSource() {

        }
    }
}
</script>
