<template>
    <div>
        <div class="text-dark">{{name}}</div>
        <div class="text-muted small">{{display}}</div>
    </div>
</template>

<script>
import {filterString} from "@/services/Filters";
export default {
    name: "FilterDisplayCompact",
    props: ['filters'],
    computed: {
        display() { return this.filters ? filterString(this.filters) : ''},
        name() { return this.filters ? this.filters.name : ''}
    }
}
</script>

<style scoped>
    h2 {
        font-size: 1.1rem;
    }
</style>
