<template>
    <div class="position-relative">
        <a class="btn btn-secondary btn-sm full-width d-block d-md-none mb-2"
           href="#filtersSectionHorizontal"
           data-bs-toggle="collapse"
           role="button" aria-expanded="false" aria-controls="filtersSectionHorizontal">
            Modify Filters
            <span class="dropdown-toggle"></span>
        </a>
        <div class="collapse d-flex flex-wrap justify-content-between" id="filtersSectionHorizontal">
            <!-- summary class="d-none d-block-sm">Filters</summary> -->
            <add-save-filter-view  class="flex-grow filter-dropdown"  v-if="hasSuggestions.type"/>
            <horizontal-filter-dropdowns  class="flex-grow pb-1 filters-bar" v-if="filters"/>
        </div>
        <div v-if="loadingFilterBar" class="position-absolute top-0 bottom-0 start-0 end-0 bg-white text-center opacity-50">
            <spinner></spinner>
        </div>
    </div>
</template>
<style scoped>

    #filtersSectionHorizontal {
        margin-left: -1em;
        margin-right: -1em;
    }
    .filter-dropdown {
        min-width: 15em;
        max-width: 900px;
        margin-left: 1em;
        margin-right: 1em;
        width: 20em;
    }
    .filters-bar{
        margin-left: 1em;
        margin-right: 1em;
    }
    #filtersSectionHorizontal.collapse.d-flex {
        display: none !important;
    }
    #filtersSectionHorizontal.collapse.show.d-flex {
        display: flex !important;
    }
    @media screen and (min-width: 768px) {
        #filtersSectionHorizontal.collapse.d-flex {
            display: flex !important;
        }
    }
</style>
<style>
</style>
<script>
import { mapGetters } from 'vuex';
import {languagesSupportingContentType} from "@/Constants";
import AddSaveFilterView from "@/components/Suggestions/AddSaveFilterView";
import HorizontalFilterDropdowns from "@/components/FilteringAndSorting/HorizontalFilterDropdowns";
import Spinner from "../Controls/Spinner";

export default {
    components : {
        Spinner,
        HorizontalFilterDropdowns,
        AddSaveFilterView,
    },
    data(){
        return {
            active : false
        }
    },
    computed : {
        ...mapGetters({
            features: 'features',
            hasSuggestions: 'hasSuggestions',
            currentTopic: 'currentTopic',
            filters : 'currentFilters',
            loadingFilterBar: 'loadingFilterBar'
        }),
        languageSupportsContentType() {
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },
    },
    methods : {
    }
}
</script>
