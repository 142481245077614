<template>
    <form id="content-policy" class="full-width">
        <form-group-card title="Text Length">
            <label class="label-calm col full-width">
                Max Abstract Length
                <input type="number" step="1" min="0" class="form-control" v-model="abstractLimit">
            </label>
            <div>
                <button class="btn btn-link d-block text-start btn-sm" type="button" @click="abstractLimit=280">
                    <i class="fa fa-twitter"/> &nbsp;
                    Twitter: 280
                </button>
                <button class="btn btn-link d-block text-start btn-sm" type="button" @click="abstractLimit=3000">
                    <i class="fa fa-linkedin"/> &nbsp;
                    LinkedIn: 3000
                </button>
            </div>
        </form-group-card>
        <form-group-card title="Quality Checks" v-if="false">
            <div class="card-body">
                <collection-quality-checks :collectionId="collectionId" ></collection-quality-checks>
            </div>
        </form-group-card>
    </form>
</template>

<script>
import CollectionQualityChecks from "./V2/CollectionQualityChecksEdit";
import ContentPolicyBase from "./V2/ContentPolicyBase";
import FormGroupCard from "../Controls/FormGroupCard";
export default {
    name: "ContentPolicy",
    components: {FormGroupCard, CollectionQualityChecks},
    // created() {this.saveUnchangedDefaults = true;},
    watch: {
        collectionId() { this.load() }
    },
    methods:{
        getData() {
            return {
                abstractLimit: parseInt(this.abstractLimit),
                userBehavior: 'warn',
                automationBehavior: 'truncate',
                userAbstractTruncate: null
            }
        },
    },
    mounted() { this.load() },
    mixins: [ContentPolicyBase]
}
</script>

<style scoped>

    legend {
        font-size: var(--font-size-16);
    }
    /*fieldset {*/
    /*    border-radius: 0.25rem;*/
    /*    border: 1px solid var(--steel-50);*/
    /*    padding: 1em;*/
    /*}*/
</style>
