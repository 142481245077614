<template>
    <div>
        <h6>Deactivated Topics in {{account.name}}</h6>

        <input class="form-control mb-3 input-sm" v-model="deactivatedTopicsSearchText" placeholder="Search deactivated topics..." />

        <progress-bar v-if="loadingDeactivatedTopics" />
        
        <div class="list-group deactivated-topics small text-muted mb-3">
            <div class="list-group-item d-flex" v-for="topic in paginatedDeactivatedTopics" :key="'deactivated-topic-'+topic.id">
                <span>{{topic.name}}</span>
                <span class="ms-auto">
                <a href="#" aria-label="Reactivate topic" @click.prevent="reactivate(topic)" class="me-3">Reactivate</a>
                <a href="#" aria-lable="Delete topic" @click.prevent="deleteTopic(topic)">Delete</a>
                    
                </span>
            </div>
        </div>

        <paginator :stay-on-first-page="true" :size="20" :items="filteredDeactivatedTopics" @update="paginatedDeactivatedTopics = $event" />
    </div>
</template>

<script>
import Paginator from '@/utilities/Paginator'
import ProgressBar from '@/utilities/ProgressBar';
export default {
    props : ['account', 'currentTopic', 'accountTopics'],
    components : {
         Paginator, ProgressBar
    },
    data(){
        return {
            paginatedDeactivatedTopics : [],
            loadingDeactivatedTopics : false,
            deactivatedTopics : [],
            deactivatedTopicsSearchText : ''            
        }
    },
    watch : {
        account(){
            this.loadDeactivatedTopics()
        }
    },
    computed : {
        filteredDeactivatedTopics(){
            return this.$sort.alphabetize(this.deactivatedTopics.filter(t => {
                if (this.deactivatedTopicsSearchText){
                    return t.name.toLowerCase().includes(this.deactivatedTopicsSearchText.toLowerCase());
                }
                return true;
            }))
        }
    },
    mounted(){
        this.loadDeactivatedTopics()
    },
    methods : {
        loadDeactivatedTopics(){
            this.deactivatedTopics = [];
            this.loadingDeactivatedTopics = true;
            this.$api.get('/accounts/'+this.account.id+'/topics?deactivated=true', {ignoreCache : true}).then(response => {
                let deactivatedUrls = response.data

                if (deactivatedUrls.length == 0) this.loadingDeactivatedTopics = false;

                deactivatedUrls.forEach((url, index) => {
                    this.$api.get(url+"?only=name,id", {ignoreCache : true}).then(response => {
                        this.deactivatedTopics.push(response.data)
                        if(index + 1 == deactivatedUrls.length){
                            this.loadingDeactivatedTopics = false;
                        }
                    }).catch(() => {});
                })
            })
            .catch(() => {});
        },
        async reactivate(topic){
            this.$api.put('/active-topics/'+topic.id);

            await this.$store.dispatch('addTopic', {topic});
            this.deactivatedTopics.splice(this.deactivatedTopics.indexOf(topic), 1);
        },
        push(topic){
            this.deactivatedTopics.push(topic);
        },
        deleteTopic(topic){
            let x = confirm("Are you sure you want to permanently delete this topic?")
            if ( ! x ) return;
            
            this.deactivatedTopics.splice(this.deactivatedTopics.indexOf(topic), 1);

            this.$api.delete('/topics/'+topic.id).catch(async() => {
                await this.$store.dispatch('addTopic', {topic : topic})
            });
            
            if (topic === this.currentTopic){
                if (this.accountTopics.length > 0){
                    console.log('* DeactivatedTopic.vue deleteTopic about to setCurrentTopic this.accountTopics.length > 0')
                    this.$store.dispatch("setCurrentTopic", {topic: this.accountTopics[0]});
                } else {
                    console.log('* DeactivatedTopic.vue deleteTopic about to setCurrentTopic')
                    this.$store.dispatch("setCurrentTopic", {topic: false})
                }
            }
        }
    }
}
</script>

<style>
</style>
