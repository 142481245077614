<template>
    <form @submit.prevent.stop="create()" @reset.prevent.stop="cancel()">
        <label>
            Campaign Name
            <input type="text" v-model="name" class="form-control form-control-sm"/>
        </label>
        <fieldset class="ps-0 mt-3 mb-1">
            <label class="form-check form-check-label ms-0">
                <input type="radio" class="form-check-input" name="sendType" v-model="send" value="draft"/> Draft
            </label>
            <label class="form-check  form-check-label">
                <input type="radio" class="form-check-input" name="sendType" v-model="send" value="now"/> Send Now
            </label>
            <label class="form-check form-check-label">
                <input type="radio" class="form-check-input" name="sendType" v-model="send" value="schedule"/> Schedule
            </label>
        </fieldset>
        <label :class="{'disabled': draft}">
            Send Date
            <input type="datetime-local" v-model="date" min="minDate" :required="!draft" :disabled="!schedule" class="form-control form-control-sm"/>
        </label>
        <button type="submit" class="btn btn-primary me-2">{{draft ? 'Create Draft' : schedule ? 'Schedule Email' : 'Send Email'}}</button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">Cancel</button>
    </form>
</template>

<style>

</style>

<script>
    import moment from 'moment'
    import * as toastr from 'toastr'
    const probableAuthErrorNotify = action => () =>
        toastr.error(
            `Couldn't ${action}, this is usually due to authentication expiring, due to not being accessed within Constant Contact's expiration time period, to refresh go to Integrations and disconnect and reconnect`, `Couldn't ${action}`,  {timeOut: 10000}
        )
    export default {
        props: {
            campaign: Object,
            collection: Object,
        },
        data() {
            return {
                name: [this.campaign && this.campaign.name || '',  moment().format('YYYY-MM-DD')].filter(x => x).join(' - '),
                send: 'draft',
                date: moment().add(1, 'days').format('YYYY-MM-DDTHH:mm'),
                minDate: moment().format()
            }
        },
        watch: {
            campaign() {
                this.resetFormData()
            }
        },
        computed: {
            draft() {
                return this.send === 'draft'
            },

            schedule() {
                return this.send === 'schedule'
            }
        },
        methods: {
            confirmSend() {
                if (this.schedule)
                    return confirm("This will send the email to all recipients at the specified time, are you sure?")
                else
                    return confirm("This will send the email to all recipients immediately, are you sure?")
            },
            async create() {
                if (!this.draft) {
                    if (!this.confirmSend()) return;
                }
                let collection = this.collection.id;
                let campaign = this.campaign.id;
                let path = `/collections/${collection}/integrations/constant_contact/campaigns/${campaign}/email`;
                let data = {
                    draft: this.draft,
                    campaign_name: this.name,
                    date:
                        this.draft ? null :
                        this.schedule ? moment(this.date).utc().format() :
                        "0"
                };
                await this.$api.post(path, data).catch(probableAuthErrorNotify('save email'))
                this.$emit('cancel')
            },
            cancel() {
                this.resetFormData();
                this.$emit('cancel')
            },
            resetFormData() {
                Object.assign(this, {
                    name: [this.campaign && this.campaign.name || '',  moment().format('YYYY-MM-DD')].filter(x => x).join(' - '),
                    send: 'schedule',
                    date: moment().add(1, 'days').format('YYYY-MM-DDTHH:mm'),
                    minDate: moment().format()
                })
            }
        }
    }
</script>
