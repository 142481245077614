import * as types from '../mutation-types'
import moment from 'moment'

const state = {
    currentTopic: false,
    allTopics: [],
}

const getters = {
    currentTopic: state => {
        console.log('* topics.js currentTopic', state.currentTopic)
        return state.allTopics.find(t => t.id === state.currentTopic?.id)
    },
    currentTopicRole: (state, getters) => getters.currentTopic && getters.currentTopic.role,
    canManageTopic: (state, getters) => ['manager', 'administrator'].includes(getters.currentTopicRole),
    topics: state => state.allTopics,
    firstAccountTopic: (state, getters) => {
        if(getters.currentAccountOverview) {
            console.log('* topics.js firstAccountTopic state.allTopics', state.allTopics)
            if(!state.allTopics) return {}
            const topics = state.allTopics.filter(t => t.account.includes(getters.currentAccountOverview.id));
            if(topics.length > 0) return topics[0];
        }
    },

}

const actions = {
}

const mutations = {
    [types.SET_CURRENT_TOPIC](state, {topic}){
        console.log('* topics.js SET_CURRENT_TOPIC')
        console.log('* topics.js SET_CURRENT_TOPIC topic', topic)
        if(state.allTopics.length === 0) state.currentTopic = {}; //FIXME: do we want to do this?
        if(!topic) state.currentTopic = {}; //FIXME: short circuit for condition where topic is not in the account
        if(state.allTopics.length > 0 && topic) {
            console.log('* topics.js types.SET_CURRENT_TOPIC state.allTopics', state.allTopics)
            state.currentTopic = state.allTopics.find(t => t.id === topic.id);
            console.log('* topics.js types.SET_CURRENT_TOPIC state.currentTopic', state.currentTopic)
        }
    },
    [types.ADD_TOPICS](state, {topics}){
        console.log('* topics.js types.ADD_TOPICS ', state.allTopics.concat(topics.filter(t => ! state.allTopics.find(e => e.id === t.id))))
        console.log('* topics.js types.ADD_TOPICS state.allTopics', state.allTopics)
        state.allTopics = state.allTopics.concat(topics.filter(t => ! state.allTopics.find(e => e.id === t.id))); //FIXME why showing as state.allTopics undefined?
        console.log('* topics.js state.allTopics after ADD TOPICS', state.allTopics)
        console.log('* topics.js state.allTopics after ADD TOPICS state.currentTopic', state.currentTopic)
    },
    [types.ADD_TOPIC](state, {topic}){
        console.log('* topics.js types.ADD_TOPIC')
        console.log('* topics.js types.ADD_TOPIC state.allTopics', state.allTopics)
        let existing = state.allTopics.find(t => topic.id === t.id)
        console.log('* topics.js ADD_TOPIC existing', existing)
        if ( ! existing){
            state.allTopics.push(topic)
        } else if(existing.updated < topic.updated || ! existing.updated) {
            let index = state.allTopics.indexOf(existing);
            state.allTopics.splice(index, 1, topic);
        }
        refreshCurrentTopic(state);
    },
    [types.USER_DID_LOG_OUT](state) {
        state.allTopics = [];
        state.currentTopic = false;
    },
    [types.DELETE_TOPIC](state, {topic}){
        state.allTopics = state.allTopics.filter(t => {
            return t.id !== topic.id
        })
        refreshCurrentTopic(state);
    },
    [types.UPDATE_TOPIC](state, {topic}){
        let currentTopic = state.currentTopic;
        state.allTopics = state.allTopics.map(t => {
            if (t.id === topic.id){
                
                let topicToReturn = {...topic, updated : moment.utc(), folder : t.folder}
                
                if (topic.id === currentTopic.id) {
                    currentTopic = topicToReturn;
                }
                return topicToReturn;
            }
            return t;
        })


        state.currentTopic = currentTopic;
    },
    [types.MOVE_TOPICS_TO_FOLDER](state, {topics, folder}){
        topics.forEach(topic => {
            let existing = state.allTopics.find(t => t.id === topic.id);
            if (existing){
                let updated = {...topic, folder: folder.id}
                state.allTopics.splice(state.allTopics.indexOf(existing), 1, updated);
            }
        })
    }
}

function refreshCurrentTopic(state) {
    if (state.currentTopic)
        state.currentTopic = state.allTopics.find(t => t.id === state.currentTopic.id);
}

export default {
    state,
    getters,
    actions,
    mutations
}
