<template>
    <div>
        <button 
            class="btn btn-success"
            @click="$emit('showCollectionsPopover')"
        >   
            Send to {{batch.length}} {{$strings.plural('card', batch.length)}} to Collection
        </button>
        <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: sanitization-->
        <span class="small" v-html="myCollectionList"></span>
    </div>
</template>

<script>
import * as $ from 'jquery';

export default {
    props : ['collectionList', 'batch'],
    computed : {
        myCollectionList(){
            if (this.collectionList.length){
                return "In Collections: " + this.collectionList.map(c => '<a class="set-collection-link" href="#" data-collection-id="'+c.id+'">'+this.$strings.escapeHtml(c.name)+'</a>').join(', ');
            }
            return ''
        }
    },
    mounted(){
        $(document).on('click', '.set-collection-link', (e) => {
            e.preventDefault();
            let id = $(e.target).attr('data-collection-id');
            this.$store.dispatch('setCurrentCollection', {collection : {id}});
        })
    }
}
</script>

<style scoped>
.btn {
    font-size: 85%;
    display: block;
    width: 100%;
    padding: 0.3em;
    margin-bottom: 0.8em;
}
</style>
