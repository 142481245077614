<template>
    <nestable-modal class="edit-collection-modal" ref="modal" modal-name="editCollectionModal" size="md edit-collection-modal" @shown="shown" @closed="collection = currentCollection">
        <template v-slot:header>
            <h5>
                <span v-if="collection && collection.id">
                    Edit {{ collection.name }}
                </span>
                <span v-else>
                    Create a New Collection
                </span>
            </h5>
        </template>
        <div>
            <edit-collection @saved="$refs.modal.hide()"
                ref="editCollection"
                 @networkActivity="networkActivity = $event"
                :collection-id="collection && collection.id"
            >

            </edit-collection>
        </div>
        <template v-if="networkActivity" v-slot:footer>
            <progress-bar style="height: 30px; width: 100%;"/>
        </template>
        <template v-else v-slot:footer>
            <div class="buttons d-flex justify-content-end save-button-container">
                <button
                    v-if="collection && collection.id"
                    type="button"
                    class="btn btn-primary save-button"
                    @click="() => {$refs.editCollection.saveCollection()}"
                    :disabled="networkActivity">Save Collection
                </button>
                <button
                    v-else
                    type="button"
                    class="btn btn-primary save-button"
                    @click="() => {$refs.editCollection.createCollection()}"
                    :disabled="networkActivity">Create Collection
                </button>
            </div>
        </template>

<!--        <template v-slot:footer v-else>-->
<!--            <div class="buttons">-->
<!--                <button type="button" class="btn btn-secondary btn-sm me-auto" data-bs-dismiss="modal">Cancel</button>-->
<!--                <button type="button" class="btn btn-primary float-end" v-if="collection.id"-->
<!--                        @click="$refs.editCollection.saveCollection">Save Collection-->
<!--                </button>-->
<!--                <button type="button"-->
<!--                        class="btn btn-primary float-end"-->
<!--                        v-else-->
<!--                        @click="$refs.editCollection.createCollection"><i class="fa fa-plus"></i> Create Collection-->
<!--                </button>-->
<!--            </div>-->
<!--        </template>-->
    </nestable-modal>
</template>

<script>
import NestableModal from "@/components/Controls/NestableModal";
import * as $ from "jquery";
import EditCollection from "./EditCollection";
import Vue from 'vue'
import {mapGetters} from "vuex";
import ProgressBar from "../../../utilities/ProgressBar";

export default {
    name: "EditCollectionModal",
    components: {ProgressBar, EditCollection, NestableModal},
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection'
        })
    },
    data() {
        return {
            collection: null,
            networkError: false,
            networkActivity: false
        }
    },
    methods: {
        shown() {
            console.log('shown()', this.collection);
            setTimeout(() => document.getElementById('edit-collection-modal--name').focus(), 600)
        }
    },
    mounted() {
        window.Events.$on('show_editCollectionModal', data => {
            this.collection = data.collection || {};
            console.log('show_editCollectionModal', data)
        });
    },
}
</script>

<style lang="scss">
// Do not scope.
.edit-collection-modal {
    .icon-dropdown .vs__dropdown-menu {
        max-height: 12em!important;
    }
}
</style>
