<template>
    <div>
        <div v-if="isUpContent">
            <label v-for="x in allItems" :key="x">
                <input type="checkbox" :value="x" :checked="selectedItems.includes(x)" @change="toggle(x, $event)"/>
                {{x}}
            </label>
        </div>
        <suggestion-tag :tag="tag"></suggestion-tag>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {isUpContent} from "../services/Context";
import SuggestionTag from "../components/Suggestions/SuggestionTag";

export default {
    name: "Playground",
    components: {SuggestionTag},
    computed: {
        ...mapGetters({
            currentUser: 'currentUser',
        }),
        isUpContent() {
            return this.currentUser.email.includes('@upcontent.com')
        }
    },
    data() {
        return {
            allItems:[
                'a', 'b', 'c'
            ],
            selectedItems: [
                'b'
            ],
            tag: {
                tagClass: 'bg-success',
                iconClass: 'fa fa-check-circle',
                label: 'Success',
                link_url: 'https://example.com'
            }
        }
    },
    methods: {
        toggle(x, $event) {
            console.log('changed', x, $event.target.checked)
        }
    }
}
</script>

<style scoped>

</style>
