<template>

</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        powerupSearchQuery: { type: String, default: ''},
    },
    computed: {
        ...mapGetters({
            bannedIntegrationsForVendor: 'bannedIntegrationsForVendor',
        })
    },
    methods: {
        powerupSearchMatches(searchableTerms) {
            if (!this.powerupSearchQuery) return true;
            let searchTerms = this.powerupSearchQuery.split(' ');
            searchableTerms = searchableTerms.split(' ');
            return searchTerms.every(
                term => searchableTerms.some(word => word.toLowerCase().includes(term.toLowerCase())));
        },
        powerUpEnabled(name) {
            return !this.bannedIntegrationsForVendor.includes(name)
        },
    },
}
</script>

<style scoped>

</style>
