<template>
        <form class="mailchimp-form" @submit.prevent="finish" @reset.prevent="cancel" v-show="!loading">
            <spinner class="" v-if="loading"></spinner>
            <scheduler v-model="scheduleData">
            </scheduler>
            <fieldset>
                <legend>Campaign Settings</legend>
                <div>
                    <label class="d-block">
                        Name
                        <input type="text" v-model="name" class="form-control form-control-sm" required/>
                    </label>
                    <label class="d-block">
                        Subject
                        <input type="text" v-model="subject" class="form-control form-control-sm" required/>
                    </label>
                    <label class="d-block">
                        List/Audience
                        <select class="form-control form-select form-select-sm" v-model="selectedListId" required>
                            <option v-for="item in lists" :key="item.id" :value="item.id">
                                {{item.name}} ({{item.stats.member_count}} Members)
                            </option>
                        </select>
                    </label>
                </div>
            </fieldset>
            <fieldset >
                <legend>Collection Filter Settings</legend>
                <select-filter-view
                    v-model="filterViewId"
                    has-content-type="collection"
                    :has-content-id="collection.id"
                    class="mb-2"
                    ref="filterViewEdit"
                >
                </select-filter-view>
            </fieldset>
            <button id="mailchimp-email-rss-finish"
                    type="submit"
                    class="btn btn-primary"
                    ref="finishButton">
                Finish in Mailchimp
            </button>
            <button id="mailchimp-email-rss-reset"
                    type="reset"
                    class="btn btn-default ms-3"
                    ref="resetButton">
                Cancel
            </button>
        </form>
</template>

<script>
import Scheduler from "@/components/Controls/Scheduler.vue";
import Spinner from "@/components/Controls/Spinner.vue";
import {MailchimpConnection} from "@/services/Mailchimp";
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView.vue";
import FeedUrls from "@/utilities/FeedUrls.vue";
import MailchimpBase from "./MailchimpBase.vue";

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default {
    components: {Spinner, Scheduler, SelectFilterView},
    mixins: [FeedUrls, MailchimpBase],
    props: {
        collection: Object,
        team: Object,
        visible: {type: Boolean, default: null},
    },
    data() {
        return {
            showRssForm: false,
            open: this.authorized,
            lists: [],
            templates: [],
            selectedListId: 116,
            selectedTemplateId: null,
            templateHtml: this.defaultTemplateHtml,
            name: this.collection && this.collection.name || '',
            subject: "What We've Been Reading...",
            showCustomTemplate: false,
            loading: 0,
            loadingCampaigns: false,
            scheduleData: {
                frequency: 'monthly',
                dayOfWeek: 1,
                dayOfMonth: 1,
                daysToSend: [...Array(7).keys()].map(x => x + 1),
                time: '11:00',
                hour: 11,
                timeType: '24 Hour'
            },
            filterViewId: null,
            rssLink: null,
        };
    },
    watch: {
        team() {
            // this.open = this.val || this.authorized;
            if (this.authorized) {
                this.loadOptions();
            }
        },
        async collection() {
            if (this.authorized) {
                await this.loadOptions();
                this.rssLink = await this.getRssLink();
            }
            await this.reset();
        },
        async filterViewId() {
            this.rssLink = await this.getRssLink();
        }
    },
    computed: {
        defaultTemplateHtml() {
            return `<span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif">*|FEEDBLOCK:${this.rssLink}|* *|FEEDITEMS:[$count=5]|*</span>\n` +
                '\n' +
                '<h2 class="mc-toc-title"><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"><a href="*|FEEDITEM:URL|*" target="_blank"><span style="color:#008000">*|FEEDITEM:TITLE|*</span></a></span></h2>\n' +
                '\n' +
                '<table border="0" cellpadding="0" cellspacing="0">\n' +
                '\t<tbody>\n' +
                '\t\t<tr>\n' +
                '\t\t\t<td><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"><em>*|FEEDITEM:AUTHOR|*</em></span></td>\n' +
                '\t\t\t<td style="text-align: right;"><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"><em>*|FEEDITEM:DATE:F d|*</em></span></td>\n' +
                '\t\t</tr>\n' +
                '\t\t<tr>\n' +
                '\t\t\t<td colspan="2"><br />\n' +
                '\t\t\t<span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif">*|feedITEM:CONTENT|*</span><br />\n' +
                '\t\t\t&nbsp;</td>\n' +
                '\t\t</tr>\n' +
                '\t</tbody>\n' +
                '</table>\n' +
                '<span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"> *|END:FEEDITEMS|**|END:FEEDBLOCK|*</span>'
        },
        show() {
            return this.features.mailchimpEmail && (this.visible !== false);
        },
        list() {
            return this.lists.find(x => x.id === this.selectedListId);
        },
        template() {
            return this.lists.find(x => x.id === this.selectedTemplateId);
        }
    },
    methods: {
        getDailySend: function (selectedDays) {
            return selectedDays.reduce((result, day) => ({
                ...result,
                [days[day - 1]]: true
            }), days.reduce((r, x) => ({...r, [x]: false}), {}));
        },
        async finish() {
            if (!(this.collection && this.collection.id)) {
                return;
            }
            let endpoint = `/collections/${this.collection.id}/integrations/mailchimp/actions/create-rss-campaign`;
            let frequency = this.scheduleData.frequency;

            this.loading++;
            try {
                let resp = await this.$api.post(endpoint, {
                    type: 'rss',
                    content: {
                        html: ''
                    },
                    settings: {
                        title: this.name,
                        subject_line: this.subject,
                        from_name: this.list && this.list.from_name,
                        reply_to: this.list && this.list.reply_to,
                        template_id: this.selectedTemplateId
                    },
                    recipients: {
                        list_id: this.selectedListId
                    },
                    rss_opts: {
                        feed_url: this.rssLink,
                        frequency,
                        schedule: {
                            weekly_send_day:
                                frequency === 'weekly' ? days[this.scheduleData.dayOfWeek - 1] : undefined,
                            monthly_send_date: frequency === 'monthly' ? this.scheduleData.dayOfMonth : undefined,
                            daily_send:
                                frequency === 'daily' ? this.getDailySend(this.scheduleData.daysToSend) :
                                    frequency === 'weekly' ? this.getDailySend([this.scheduleData.dayOfWeek]) :
                                        this.getDailySend([1]),
                            hour: parseInt(this.scheduleData.time.split(':')[0])
                        },
                        constrain_rss_img: true
                    }
                });
                window.open(this.mailchimpEditLink(resp.data.web_id), '_blank')
            } catch {
                alert('There was an error saving to mailchimp')
            } finally {
                this.loading--;
                this.reset();
                this.$emit('submitted')
            }
        },
        mailchimpEditLink(id) {
            return `https://${this.dc || 'us18'}.admin.mailchimp.com/campaigns/wizard/neapolitan?id=${id}` // TODO: set the dc
        },
        async getLists() {
            if (this.team && this.team.id && this.collection && this.collection.id) {
                let endpoint = `/accounts/${this.team.id}/integrations/mailchimp/resources/lists`;
                let query = 'count=100';

                await this.$api.get(endpoint + '?' + query).then(resp => {
                    if (resp && resp.data) {
                        this.lists = resp.data.lists;
                        this.selectedListId = this.lists[0].id;
                    }
                })
            }
        },
        async getTemplate() {
            if (!this.team || !this.team.id) return;
            try {
                this.loading++;
                this.selectedTemplateId = await new MailchimpConnection(this).ensureRssTemplateExists(this.team.id);
            } finally {
                this.loading--;
            }

        },
        cancel() {
            this.reset()
            this.$emit('cancel')
        },
        reset() {
            this.name = this.collection && this.collection.name || '';
            this.subject = "What We've Been Reading...";
            // this.selectedTemplateId = 116;
            this.selectedListId = this.lists[0] && this.lists[0].id;
            this.scheduleData = {
                frequency: 'monthly',
                dayOfWeek: 1,
                dayOfMonth: 1,
                daysToSend: [...Array(7).keys()].map(x => x + 1),
                time: '11:00'
            };
            this.showCustomTemplate = false;
            this.templateHtml = this.defaultTemplateHtml;
        },
        async loadOptions() {
            try {
                this.loading++;
                await Promise.all([
                    this.getLists(),
                    // this.getTemplates()
                ]);
                this.loading--;
            } catch {
                this.loading--;
            }
        },
        async getRssLink() {
            if (this.collection) {
                return await this.feedUrlForFilterView('collection', this.collection.id, this.filterViewId);
            }

            return null;
        }
    },
    mounted() {
        if (this.authorized) {
            this.loadOptions();
            this.getTemplate();
        }
        this.reset();
    }
}
</script>
<style>
.powerup legend {
    font-size: 16px;
    margin-bottom: 5px;
}

.powerup fieldset {
    margin: 10px 0;
}
.mailchimp-form label {
    font-size: var(--font-size-12);
    font-weight: 500;
    color: var(--water-0);
    text-transform: uppercase;
}
.mailchimp-form .scheduler-main label:not(:last-child) {
    margin-right: 1em;
}
.mailchimp-form fieldset legend {
    font-size: 1.1em;
    color: var(--bs-dark)
}
.mailchimp-form fieldset  {
    margin-bottom: 1em;
}
.mailchimp-form ul.form-control>li:not(:last-child) {
    margin-right: .5em;
}
.mailchimp-form .scheduler-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mailchimp-form legend {
    border-bottom: 1px solid;
    display: block;
}
</style>

<style scoped>
.full-width-button {
    width: 100%;
}

.hide {
    display: none;
}

.uc-select-sm {
    font-size: 0.875rem;
    color: var(--bs-dark)
}
</style>
