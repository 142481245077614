<template>

</template>

<script>
import CollectionIntegrationDataBase from "@/components/Integrations/CollectionIntegrationDataBase.vue";

const defaultSettings = {
    on: false,
    group_id: "",
    share_commentary: "summary,title",
    image_added: false,
    expires_in: "",
    publish: true,
    everyone_social_settings: {
        disclosure_list: [
            {
                disclosure_id: "",
                network: "",
            }
        ],
        is_branded: false,
        is_shareable: false,
    },
};
export default {
    mixins: [CollectionIntegrationDataBase],
    data() {
        return {
            apiName: 'everyone_social',
            socialGroups: [],
            disclosures: [],
            savedSettings: {},
            integrationName: 'everyone_social',
            on: Boolean(this.savedSettings && this.savedSettings.on),
            isLoading: true,
        }
    },
    methods: {
        async loadForCollection() {
            this.isLoading = true;
            let groupsResponse = await this.fetchEveryoneSocialGroups();
            this.socialGroups = groupsResponse.items;
            let disclosuresResponse = await this.fetchEveryoneSocialDisclosureList()
            this.disclosures = disclosuresResponse.items;


            if (this.savedSettings && this.savedSettings === defaultSettings) {
                // want to trigger the watch on savedSettings() in EveryoneSocialCollectionSettings.vue
                this.savedSettings = null;
            }
            this.savedSettings = (await this.fetchSettings()) || defaultSettings;
            this.on = Boolean(this.savedSettings && this.savedSettings.on);
            this.isLoading = false;
        },
        async updateSettingsForCollection(apiObject) {
            return await this.updateSettings(apiObject)
        },
        async fetchEveryoneSocialGroups(){
            // pass through for https://developers.everyonesocial.com/reference/getgroups
            return await this.fetchResource('groups');
        },
        async fetchEveryoneSocialDisclosureList(){
            // pass through for https://developers.everyonesocial.com/reference/getdisclosurelist
            return await this.fetchResource('disclosure-list');
        },
    }
}
</script>

<style scoped>

</style>


