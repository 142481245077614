<template>
    <div>
        <label class="d-block mb-3">
            Width
            <input type="range" class="form-control form-control-sm" min="100" max="1000" step="1" v-model="size" style="calc(100% - 120px)"/>
            <div>
            <input type="number" class="form-control-sm form-control d-inline" min="100" max="1000" step="1" v-model="size" style="width: auto"/> px
            </div>
        </label>
        <label class="">
            Background Color
            <color-picker v-model="backgroundColor"></color-picker>
        </label>
        <label class="">
            Border Color
            <color-picker v-model="borderColor"></color-picker>
        </label>
        <fieldset>
            <legend>Padding (px)</legend>
            <label class="row">
                <div class="col col-6 col-lg-6">Vertical</div>
                <div class="col col-6 col-lg-6">
                    <input v-model="verticalPadding" class="form-control-sm form-control" type="number">
                </div>
            </label>

            <label class="row">
                <div class="col col-6 col-lg-6">Horizontal </div>
                <div class="col col-6 col-lg-6">
                    <input v-model="horizontalPadding" class="form-control-sm form-control" type="number">
                </div>
            </label>
        </fieldset>
        <fieldset v-if='type !== "carousel"'>
            <legend>Content Padding (px)</legend>
            <label class="row">
                <div class="col col-6 col-lg-6">Top</div>
                <div class="col col-6 col-lg-6">
                    <input v-model="contentTopPadding" class="form-control-sm form-control" type="number">
                </div>
            </label>
            <label class="row">
                <div class="col col-6 col-lg-6">Horizontal</div>
                <div class="col col-6 col-lg-6">
                    <input v-model="contentHorizontalPadding" class="form-control-sm form-control" type="number">
                </div>
            </label>
            <label class="row">
                <div class="col col-6 col-lg-6">Bottom</div>
                <div class="col col-6 col-lg-6">
                    <input v-model="contentBottomPadding" class="form-control-sm form-control" type="number">
                </div>
            </label>
        </fieldset>
        <label class="d-block">
            Rounded Corners Radius (px)
            <input type="range" min="0" max="25" step="1" v-model="cornerRoundRadius" class="form-control" >
            <input type="number" min="0" max="25" step="1" v-model="cornerRoundRadius" class="form-control form-control-sm" >
        </label>
        <label class="d-block">
            Shadow
            <select v-model="shadow" class="form-control form-control-sm form-select" >
                <option>None</option>
                <option v-for="o in shadowOptions" :key="o">{{o}}</option>
                <!--<option>3px, Dark</option>-->
                <!--<option>5px, Dark</option>-->
                <!--<option>10px, Dark</option>-->
                <!--<option>3px, Light</option>-->
                <!--<option>5px, Light</option>-->
                <!--<option>10px, Light</option>-->
                <!--<option>3px, White</option>-->
                <!--<option>5px, White</option>-->
                <!--<option>10px, White</option>-->
            </select>
        </label>
    </div>
</template>

<style scoped>
    legend {
        border-bottom: 1px solid var(--bs-default);
        font-size: 110%
    }
    fieldset {
        margin-bottom: 1.5em;
        margin-top: .75em;
    }
</style>

<script>
    import ColorPicker from "@/components/Controls/ColorPicker"
    import {Toggle, ToggleItem} from "@/components/Controls/Toggle"
    import {shadowSizes, shadowColors} from "@/models/themes";
    export default {
        props: ['value', 'type'],
        components: {
            ColorPicker,
            Toggle,
            ToggleItem,
        },
        data() {
            return {
                size: 300,
                horizontalPadding: 10,
                verticalPadding: 10,
                contentHorizontalPadding: 10,
                contentTopPadding: 10,
                contentBottomPadding: 10,
                cornerRoundRadius: 0,
                shadow: 'None',
                backgroundColor: '#FFFFFF',
                borderColor: '#000000',
                ...this.value
            }
        },
        watch: {
            value() { Object.assign(this, this.value)},

            size(v) {this.$emit('input', {...this.value, size: v})},
            horizontalPadding(v) {this.$emit('input', {...this.value, horizontalPadding: v})},
            verticalPadding(v) {this.$emit('input', {...this.value, verticalPadding: v})},
            cornerRoundRadius(v) {this.$emit('input', {...this.value, cornerRoundRadius: v})},
            shadow(v) {this.$emit('input', {...this.value, shadow: v})},
            backgroundColor(v) {this.$emit('input', {...this.value, backgroundColor: v})},
            contentTopPadding (v) {this.$emit('input', {...this.value, contentTopPadding: v})},
            contentBottomPadding(v) {this.$emit('input', {...this.value, contentBottomPadding: v})},
            contentHorizontalPadding(v) {this.$emit('input', {...this.value, contentHorizontalPadding: v})},
            borderColor(v) {this.$emit('input', {...this.value, borderColor: v})},
        },
        computed: {
            shadowOptions() {
                return shadowColors.flatMap(c =>
                    shadowSizes.map(s =>
                        `${s}px, ${c.description}`
                    )
                )
            }
        },
    }
</script>
