import {SortBy} from "../../../utilities/Sort";

const state = {
    brands: [],
    ctas: {},
    brandCount: 0,
    loadingMappings: false,
    loadingBrands: false,
    loadingCtas: false,
    legacyCampaigns: {},
    mappings: []
}

const getters = {
    sniplyBrands: state => state.brands,
    sniplyCtasByBrand: state => state.ctas,
    sniplyLegacyCampaignsById: state => state.legacyCampaigns,
    sniplyMappings: state => state.mappings,
    sniplyBrandLoadPercent: state => Math.round(state.brands.length/(state.brandCount||1)*100),
    sniplyLoadingBrands: state => state.loadingBrands,
    sniplyLoadingCtas: state => state.loadingCtas,
    sniplyLoadingMappings: state => state.loadingMappings,
}

const actions = {
    loadSniplyBrands: async ({commit, getters, state, dispatch}, {fetchNext}) => {
        if (!getters.externalApis['sniply']) return Promise.resolve();
        if (fetchNext === undefined)
            commit('SET_SNIPLY_BRANDS', {brands: []});

        let fetchFirst = (() => fetchBrands(getters.currentAccountOverview));
        let brands = await (fetchNext || fetchFirst)();
        commit('SET_SNIPLY_BRAND_COUNT', {brandCount: brands.count})
        commit('SET_SNIPLY_BRANDS', {brands: SortBy.alphabetize([...state.brands, ...brands.results])});

        if (brands.hasNext && state.brands.length < brands.count)
            await dispatch('loadSniplyBrands', {fetchNext: brands.next});
    },
    loadSniplyCtas: async ({commit, getters, dispatch}, {brand, fetchNext}) => {
        if (!getters.externalApis['sniply'] || !brand) return Promise.resolve();
        if (fetchNext === undefined)
            commit('SET_CTAS', { brand, ctas: []})

        commit('START_LOADING_SNIPLY_CTAS');
        let fetchFirst = (() => fetchCtas(getters.currentAccountOverview, brand));
        let ctas = await (fetchNext || fetchFirst)();
        console.log('loadSniplyCtas', brand, ctas)
        await commit('SET_CTAS', { brand, ctas: SortBy.alphabetize([...state.ctas[brand]||[], ...ctas.results])});
        let allCtas = state.ctas[brand] || []
        if (ctas.hasNext && allCtas.length < ctas.count)
            await dispatch('loadSniplyCtas', {brand, fetchNext: ctas.next});
        else
            commit('FINISH_LOADING_SNIPLY_CTAS');
    },
    async loadSniplyMappings({commit, getters}) {
        if (!(getters.externalApis['sniply'] && getters.currentAccountOverview && getters.currentAccountOverview.id)) {
            return Promise.resolve();
        }
        commit('START_LOADING_SNIPLY_MAPPINGS');
        try {
            let response = await window.$app.api.get(`/accounts/${getters.currentAccountOverview.id}/sniply/param-mappings`);
            if (response && response.data && response.data.mappings) {
                commit('SET_SNIPLY_MAPPINGS',  {mappings: response.data.mappings});
            } else {
                commit('SET_SNIPLY_MAPPINGS',  {mappings: []});
            }
        } finally {
            commit('FINISH_LOADING_SNIPLY_MAPPINGS')
        }
    },
    loadSniplyLegacyCampaign: async ({commit, getters, state, dispatch}, {campaignId}) => {
        let response = await window.$app.api.get(`/accounts/${getters.currentAccountOverview.id}/sniply/campaigns/${campaignId}`);
        commit("SET_LEGACY_SNIPLY_CAMPAIGN", {campaignId, campaign: {...response.data, type: 'campaign'}})
    }
}

const mutations = {
    SET_SNIPLY_BRANDS: (state, {brands}) =>{
        state.brands = brands;
    },
    SET_SNIPLY_BRAND_COUNT: (state, {brandCount}) => {
        state.brandCount = brandCount;
    },
    SET_CTAS: (state, {brand, ctas}) => {
        let all = {...state.ctas};
        all[brand] = ctas;
        state.ctas = all;
    },
    START_LOADING_SNIPLY_MAPPINGS: (state) => {
        state.loadingMappings = true;
    },
    FINISH_LOADING_SNIPLY_MAPPINGS: (state) => {
        state.loadingMappings = false;
    },
    SET_SNIPLY_MAPPINGS: (state, {mappings}) => {
        state.mappings = mappings;
    },
    SET_LEGACY_SNIPLY_CAMPAIGN(state, {campaignId, campaign}) {
        state.legacyCampaigns[campaignId] = campaign
    },
    START_LOADING_SNIPLY_CTAS(state) {
        state.loadingCtas = true;
    },
    FINISH_LOADING_SNIPLY_CTAS(state) {
        state.loadingCtas = false;
    }
}

async function fetchBrands(team, paging) {
    // let url = 'https://snip.ly/api/v2/campaigns';
    // let data = this.apiData(team);
    // let bearerToken = data&&data.token;
    // let response = await fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         "Authorization": `Bearer ${bearerToken}`,
    //         'Content-Type': 'text/plain'
    //     }
    // });

    let response = await window.$app.api.get(`/accounts/${team.id}/sniply/brands${paging?paging:""}`);
    // let fetchPage = (page) => this.fetchCampaigns(team, `?page=${page}`);
    // let pageCount = Math.ceil(response.data.count / 10);
    return {
        count: response.data.count,
        // pageCount, fetchPage,
        // pages: new Array(pageCount).map((x, i) => ({ index: i, number: i+1, fetch: ()=>fetchPage(i)})),
        next: () => fetchBrands(team, response.data.next),
        hasNext() { return !!response.data.next; },
        prev: () => fetchBrands(team, response.data.prev),
        hasPrev() { return !!response.data.prev; },
        results: response.data.results
    }
}

async function fetchCtas(team, brandId, paging = 1) {
    // let url = 'https://snip.ly/api/v2/campaigns';
    // let data = this.apiData(team);
    // let bearerToken = data&&data.token;
    // let response = await fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         "Authorization": `Bearer ${bearerToken}`,
    //         'Content-Type': 'text/plain'
    //     }
    // });
    let response = await window.$app.api.get(`/accounts/${team.id}/sniply/ctas?page=${paging}&brand=${brandId}`);
    // let fetchPage = (page) => this.fetchCampaigns(team, `?page=${page}`);
    // let pageCount = Math.ceil(response.data.count / 10);
    return {
        count: response.data.count,
        // pageCount, fetchPage,
        // pages: new Array(pageCount).map((x, i) => ({ index: i, number: i+1, fetch: ()=>fetchPage(i)})),
        next: () => fetchCtas(team, brandId, response.data.next ? ++paging : null),
        hasNext() { return !!response.data.next; },
        prev: () => fetchCtas(team, brandId, response.data.prev),
        hasPrev() { return !!response.data.prev; },
        results: response.data.results
    }
}

export default {
    state, mutations, getters, actions
}
