<template>
    <div>
        <progress-bar v-if="busy" />

        <empty-state 
            v-if="suggestions.length == 0" 
            :urls="suggestions" 
            :still-building="stillBuilding" 
            :busy="busy" 
        />

        <suggestion-display 
            v-else
            :suggestions="suggestions" 
            :collectionBatch="collectionBatch"
            @toggleCardInCollectionBatch="toggleCardInCollectionBatch"
            ref="suggestionDisplay"
            :display-type="displayType"
        />
    </div>
</template>

<script>
import SuggestionDisplay from './Display'
import ProgressBar from '@/utilities/ProgressBar'
import EmptyState from './EmptyState'
import { mapGetters } from 'vuex';

export default {
    props : ['suggestions', 'collectionBatch', 'stillBuilding', 'displayType'],
    components : { SuggestionDisplay, ProgressBar, EmptyState },
    computed : {
        ...mapGetters({
            status : 'suggestionTransferStatus',
            type : 'suggestionViewerType',
        }),
        busy(){
            //FIXME always true when changing accounts
            console.log('* List.vue loading bar this.status.list || this.status.cache;', this.status.list || this.status.cache)
            return this.status.list || this.status.cache;
        }
    },
    methods : {
        toggleCardInCollectionBatch({card}){
            this.$emit('toggleCardInCollectionBatch', {card});
        }
    },
    watch: {
        busy() {

        },
        stillBuilding() {

        },
    }
}
</script>

<style>
</style>
