import {OAuthConnection, connections} from "@/services/OAuth";
import {openNewAndAwaitReturn} from "@/utilities/externalNavigation";

// const defaultTemplateName = 'RSS Campaign (UpContent Created)';
const defaultTemplateShareUrl = 'https://us18.admin.mailchimp.com/templates/share?id=96181965_3b49b4593c9659bf3b41_us18'
const CREATED = 201;
const OK = 200;
// const NO_CONTENT = 204;
export class MailchimpConnection extends OAuthConnection {
    constructor(context) {
        super('mailchimp', context);
    }

    async doCustomAfterActions(accountData, accountId) {
        console.log('MailchimpConnection.doCustomAfterActions')
        await this.ensureRssTemplateExists(accountId, true);
    }

    async ensureRssTemplateExists(accountId, force=false) {
        console.log('MailchimpConnection.doCustomAfterActions', accountId)
        let templateId = await this.getRssTemplateById(accountId);
        let result = null;
        if (!templateId || force) {
            try {
                result = await this.storeTemplateId(accountId, force)
            } finally {
                if (result && (result.status === CREATED || result.status === OK))
                    templateId = result.data.id || await this.getRssTemplateById(accountId);
                else {
                    alert("Awesome! You’re about to head to Mailchimp so we can add a template that we’ll use when you create your campaigns. Come on back when you see the success message in Mailchimp.");
                    await openNewAndAwaitReturn(defaultTemplateShareUrl);
                    await this.storeTemplateId(accountId);
                    templateId = this.getRssTemplateById(accountId);
                }
            }
        }
        return templateId;
    }

    async storeTemplateId(accountId, force=false) {
        let url = `/accounts/${accountId}/integrations/mailchimp/templateId`;
        return force ? await this.$api.post(url) : await this.$api.put(url)
    }

    async getRssTemplateById(accountId) {
        return await this.$api.get(
            `/accounts/${accountId}/integrations/mailchimp/templateId`).then(r => r.data.id)

    }
}

connections['mailchimp'] = MailchimpConnection
