<template>
    <div>
        <div v-if="isOpen">
            <div class="d-flex">
                <h6>{{ member.name }}</h6>
                <a class="ms-auto small" href="#" @click.prevent="isOpen = !isOpen">Hide Info</a>
            </div>
            <div class="details">
                <p class="d-flex">
                    Email
                    <span class="ms-auto">{{ member.email }}</span>
                </p>
                <p class="d-flex">
                    Role
                    <span class="ms-auto">
                        <select
                            :value="role"
                            class="form-control"
                            @change="updatePrivileges($event)"
                            v-if="(!team.owner.includes(member.id) && canManageTeam) && roles.includes(member.role) && isNotCurrentUser(member)"
                        >
                            <option
                                :value="role"
                                v-for="role in roles"
                                :key="'member'+member.id+'-role-selection-'+role"
                            >
                                {{ toCapitalized(role) }}
                            </option>
                        </select>
                        <span v-else>{{ showMemberRole }}</span></span>
                </p>
                <p class="d-flex">
                    Assignments
                    <span class="ms-auto" v-if="canManageTeam">
                        <a v-if="user.id != member.id" @click.prevent="editAssignments" href="#">Edit</a>
                    </span>
                </p>
            </div>
        </div>
        <div v-else class="d-flex">
            <h6 class="text-break">{{ member.name }}</h6>
            <span class="ms-auto small text-nowrap">
                <a href="#" @click.prevent="isOpen = !isOpen">View Info</a>&nbsp;
                <a href="#" class="ms-3 text-muted" @click.prevent="removeMember"><i class="fa fa-times-circle"></i></a>
            </span>

        </div>
    </div>
</template>

<script>
import Base from './BaseMixin';
import capitalize from "capitalize";

export default {
    mixins: [Base],
    data() {
        return {
            isOpen: false
        }
    },
    computed: {
        showMemberRole() {
            return this.toCapitalized(this.member.role);
        }
    },
    methods: {
        toCapitalized(string) {
            return typeof string === 'string' ? capitalize(string) : '';
        }
    }
}
</script>

<style scoped>
.details {
    font-size: var(--font-size-13);
}

select.form-control {
    font-size: 14px;
    height: 26px !important;
}
</style>
