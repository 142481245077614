/* eslint-disable no-unused-vars */
import {StringUtils} from "@/utilities/Strings";
import {OAuthConnection} from "./OAuth";

export class LatelyConnection extends OAuthConnection{
    constructor(context) {
        super('lately', context);
    }
    async fetchDashboards(team) {
        return this.$api.get(`/accounts/${team.id}/lately/dashboards`)
    }
    async fetchCampaigns(team, dashboard) {
        return this.$api.get(`/accounts/${team.id}/lately/dashboards/${dashboard}/campaigns`)
    }
    fetchCollectionSettings(collection) {
        return this.$api.get('/collections/'+collection.id+'/lately-settings')
    }
    connectUrl(){
        let scope = 'lately%3Auser%2Fprofile%20lately%3Auser%2Fdashboards%20lately%3Acontent%2Fgenerate';
        let query = [
            `response_type=code`,
            `redirect_uri=${encodeURIComponent(this.returnUrl())}`,
            `scope=${scope}`,
            `client_id=${process.env.VUE_APP_LATELY_CLIENT_ID}`].join('&');

        return `https://dashboard.lately.ai/v1/apps/oauth/authorize?${query}`
    }

    dataFromQuery(query) {
        let { state, code } = query;
        return { state, code };
    }

    authError(query) {
        return query.error;
    }
}