<template>
    <fieldset>
        <legend v-if="label">{{label}}</legend>

        <!--<toggle class=" mb-3 mt-2" v-model="alignment">-->
            <!--<toggle-item value="left">-->
                <!--<i class="fa fa-align-left"/> Left-->
            <!--</toggle-item>-->
            <!--<toggle-item value="center">-->
                <!--<i class="fa fa-align-center"/> Center-->
            <!--</toggle-item>-->
            <!--<toggle-item value="right">-->
                <!--<i class="fa fa-align-right"/> Right-->
            <!--</toggle-item>-->
        <!--</toggle>-->
        <label>
            Font Size (% of root)
            <input type="number" v-model="fontSize" min="10" max="300" step="5"  class="form-control form-control-sm"/>
            <input type="range" v-model="fontSize" min="10" max="300" step="5" class="mt-1 mb-2 form-control form-control-sm"/>
        </label>
        <div class="row">
            <label class="col col-12 col-xl-6">
                Custom Font
                <input type="text" v-model="customFont"  class="form-control form-control-sm"/>
            </label>
            <label class="col col-12 col-xl-6">
                Fallback Font
                <select v-model="presetFont" class="form-control form-control-sm form-select">
                    <option>Helvetica, Arial, sans-serif</option>
                    <option>Tacoma</option>
                    <option>Georgia, serif</option>
                    <option>Times, serif</option>
                    <option>Courier New, Courier</option>
                    <option>Verdana</option>
                    <option>Impact</option>
                    <option>"Palatino Linotype", "Book Antiqua", Palatino, serif</option>
                    <option>"Arial Black", Gadget, sans-serif</option>
                </select>
            </label>
        </div>
        <div class="row">
            <label class="col col-12 col-xl-6">
                Color
                <color-picker v-model="color"/>
            </label>
            <label class="col col-12 col-xl-6">
                Background
                <color-picker v-model="backgroundColor"/>
            </label>
        </div>
        <label class="checkbox-label fw-semibold">
            <input type="checkbox" v-model="bold" class="" /> Bold</label>
        <label class="checkbox-label font-italic">
            <input type="checkbox" v-model="italics" class="" /> Italics</label>
        <label class="checkbox-label ">
            <input type="checkbox" v-model="underline" /> <span class="underline">Underline</span></label>
        <label class="checkbox-label text-uppercase">
            <input type="checkbox" v-model="allCaps" /> All Caps</label>
        <label class="checkbox-label" v-if="hideable">
            <input type="checkbox" v-model="hidden" /> Hidden
        </label>
        <label class="checkbox-label" v-if="inline !== null">
            <input type="checkbox" v-model="inline" /> Inline
        </label>

        <div class="row">
            <label v-if="lineHeight !== null"  class="col col-12 col-xl-6">
                Line Height
                <input type="number" v-model="lineHeight" min="1" max="2" step=".1"  class="form-control form-control-sm"/>
                <input type="range" v-model="lineHeight" min="1" max="2" step=".1" class="mt-1 mb-2 form-control form-control-sm"/>
            </label>
            <div v-if="fixedHeight !== null"  class="col col-12 col-xl-6">
                <div>
                    <label>
                        Height (Lines)
                        <input type="number" v-model="height" min="1" max="4" step="1"  class="form-control form-control-sm"/>
                        <input type="range" v-model="height" min="1" max="4" step="1" class="mt-1 mb-2 form-control form-control-sm"/>
                        <label class="checkbox-label d-block">
                            <input type="checkbox" v-model="fixedHeight"/> Fixed</label>
                    </label>
                </div>
            </div>
        </div>
        <fieldset>
            <legend>Padding (px)</legend>
            <padding v-model="padding"></padding>
        </fieldset>
        <fieldset>
            <legend>Margin (px)</legend>
            <padding v-model="margin"></padding>
        </fieldset>
    </fieldset>
</template>

<style scoped>
    /*label { display: block; }*/
    /*label input, label select { display: block; width: 400px; }*/
    label input[type="checkbox"] { display: inline; width: auto; }
    /*.checkbox-label { display: inline; margin-right: 20px; }*/
    /*legend {display: block;}*/

    .underline {
        text-decoration: underline;
    }
    .checkbox-label {
        margin-right: 10px;
    }

    legend {
        border-bottom: 1px solid var(--bs-default);
        font-size: 110%
    }
    fieldset {
        margin-bottom: 2em;
    }
    fieldset fieldset:last-child { margin-bottom: 0;}
</style>

<script>
    import ColorPicker from '@/components/Controls/ColorPicker';
    import defaultTextStyle from "../../services/DefaultTextStyle";
    import {Toggle, ToggleItem} from "@/components/Controls/Toggle"
    import Padding from "@/components/Galleries/Padding";

    export default {
        props: ['value', 'label', 'showLabel'],
        mounted() {
            if (this.value)
                Object.assign(this, this.value);
        },
        components: {Padding, ColorPicker, Toggle, ToggleItem},
        data() { return {
            ...defaultTextStyle()
        }},
        watch: {
            value() {
                this.inline = undefined;
                Object.assign(this, {...defaultTextStyle(), ...this.value})
            },
            fontSize() {
                this.$emit('input', {...this.value, fontSize: this.fontSize})
            },
            italics() {
                this.$emit('input', {...this.value, italics: this.italics})
            },
            bold() {
                this.$emit('input', {...this.value, bold: this.bold})
            },
            allCaps() {
                this.$emit('input', {...this.value, allCaps: this.allCaps})
            },
            underline() {
                this.$emit('input', {...this.value, underline: this.underline})
            },
            presetFont() {
                this.$emit('input', {...this.value, presetFont: this.presetFont})
            },
            customFont() {
                this.$emit('input', {...this.value, customFont: this.customFont})
            },
            color() {
                this.$emit('input', {...this.value, color: this.color})
            },
            backgroundColor() {
                this.$emit('input', {...this.value, backgroundColor: this.backgroundColor})
            },
            hidden() {
                this.$emit('input', {...this.value, hidden: this.hidden})
            },
            inline() {
                this.$emit('input', {...this.value, inline: this.inline})
            },
            lineHeight() {
                this.$emit('input', {...this.value, lineHeight: this.lineHeight})
            },
            fixedHeight() {
                this.$emit('input', {...this.value, fixedHeight: this.fixedHeight})
            },
            height() {
                this.$emit('input', {...this.value, height: this.height})
            },
            padding() {
                this.$emit('input', {...this.value, padding: this.padding})
            },
            margin() {
                this.$emit('input', {...this.value, margin: this.margin})
            }
        }
    }
</script>
