
import {fieldsThatReloadPublishers, mergePublishers, publishersNeedReload} from "../../services/Publishers";
import {knownTags} from "../../Constants";
import {defaultFilters} from "../../services/Filters";
import * as types from "../mutation-types";
import {migrateFilters} from "../../services/migrations/filterMigrations";

let state = {
    filters: null,
    loadingFilters: false,
    hasContentTags: [],
    isLoadingTags: false,
}


function taskDoer(getters, dispatch) {
    let idAtStart = getters.hasSuggestions && getters.hasSuggestions.id;
    return async function doTask(action) {
        let currentId = getters.hasSuggestions && getters.hasSuggestions.id;
        if (idAtStart !== currentId) {
            console.log('view reloaded, cancel filters load', {idAtStart, currentId})
            throw "canceled"
        }
        return await action()
    }
}

let getters = {
    currentFilters: state => state.filters,
    loadingFilterBar: state => state.loadingFilters,
    filterPublishers: (state, getters) =>
        getters.includeNewSources ? state.filters.excludedSources : state.filters.sources || [],
    tags: (state) => {
        let tags = Array.from(state.hasContentTags||[]).map(x=>({...x,
            iconClass: x.icon_class,
            iconUrl: x.icon_url,
            tagClass: x.tag_class,
            showInFilters: x.show_in_filters
        }));
        Object.keys(knownTags).forEach(kt => {
            let tag = tags.find(t => t.key === kt);
            if (tag) {
                Object.assign(tag, knownTags[kt])
            } else {
                tags.push(knownTags[kt])
            }
        });
        return tags.filter(t => t.showInFilters);
    },
    required: state => state.filters.required||[],
    minFullTextLength: state => state.filters.minFullTextLength || 0,
    excludePaywalled: state => state.filters.excludePaywalled || false,
    selectedTags : state => {
        return (state.filters.tags||[]).filter(x=>x);
    },

    showFavoriteSuggestions : state => state.filters.favorites,
    showExcludePaywalledSuggestions: state => state.filters.excludePaywalled,
    showMinFullTextLength: state => state.filters.minFullTextLength,
    defaultFilters: (state, getters) => defaultFilters(getters.hasSuggestions.type)
}

let actions = {
    async loadFilterModuleOnCollectionChange({dispatch}) {
    },
    async filterValueChange({commit, dispatch, state}, {key, value}) {
        console.log('filterValueChange.start', JSON.stringify(state.filters[key]), JSON.stringify(value))
        if (JSON.stringify(state.filters[key]) === JSON.stringify(value)) {
            console.log('no filter view change');
            return;
        }
        console.log('filterValueChange', {key, value, old: state.filters[key]})
        await commit(mutations.SET_FILTER_VALUE.name, {key, value})
        await commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST)
        dispatch('updateSettings') // No need to await
        console.log('filterValueChange.loadPublishers', {key, value, fieldsThatReloadPublishers})
        if (fieldsThatReloadPublishers.includes(key))
            await dispatch('loadPublishers', {})
        await dispatch('loadSuggestions', {keep: true})
    },
    async filtersChanged({commit, dispatch, getters}, {filters}) {
        let oldFilters = getters.currentFilters;
        await commit(mutations.SET_FILTER_DATA.name, {filters})
        await commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST)
        dispatch('updateSettings') // No need to await
        if (publishersNeedReload(oldFilters, filters))
            await dispatch('loadPublishers')
        await dispatch('loadSuggestions', {keep: true})
    },
    async loadFiltersFromSettings({commit, dispatch, getters}) {
        let doTask = taskDoer(getters)
        let filters = getters.hasSuggestionsSettings.filters || getters.preferences.filters || defaultFilters(getters.hasSuggestions.type);
        filters = await dispatch('migrateFilters', {filters});
        let filterViewId = filters.id || filters.filterViewId || null;
        await doTask(() => commit(mutations.SET_FILTER_DATA.name, {filters, type: getters.suggestionViewerType, overwrite: true}))
        doTask(() => dispatch('loadFilterView', {filterViewId}))
        doTask(() => dispatch('loadPublishers', {})) // This takes almost 6 seconds to load compared to the rest taking under 1sec and doesn't stop the rest of the UI from loading
        await doTask(() => dispatch('loadPublishersFilters', {}))
        await commit('FINISH_LOADING_FILTERS')
    },
    async migrateFilters({commit, dispatch, getters}, { filters: originalFilters }) {
        let {filters, awaitPublishers} = migrateFilters(originalFilters, {});
        if (awaitPublishers) {
            let doTask = taskDoer(getters)
            await doTask(() => commit(mutations.SET_FILTER_DATA.name, {filters, overwrite: true}));
            await doTask(() => dispatch('loadPublishers', {}));
            ({filters, awaitPublishers} = migrateFilters(filters, {publishers: getters.publishers}));
        }
        return filters;
    },

    registerTag: ({commit}, {tag}) => {
        commit('REGISTER_TAG', {tag})
    },
    loadTags: async ({commit}, {type, id}) => {
        let response = await window.$app.api.get(`/${type}s/${id}/tags`);
        let tags = response.data.tags;
        commit('SET_TAGS', {tags})
    },
    toggleShowFavorites: async ({commit, dispatch, getters}) => {
        await commit(mutations.SET_FILTER_VALUE.name, {key: 'favorites', value: !getters.showFavoriteSuggestions});
        dispatch('updateSettings') // No need to await
        await commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST);
        await dispatch('loadSuggestions', {keep: true});
    },
    toggleExcludePaywalled: async ({commit, dispatch, getters}) => {
        await commit(mutations.SET_FILTER_VALUE.name, {key: 'excludePaywalled', value: !getters.showExcludePaywalledSuggestions});
        dispatch('updateSettings') // No need to await
        await commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST);
        await dispatch('loadSuggestions', {keep: true});
    },
    toggleMinFullTextLength: async ({commit, dispatch, getters}) => {
        await commit(mutations.SET_FILTER_VALUE.name, {key: 'minFullTextLength', value: !getters.showMinFullTextLength ? 300 : 0});
        dispatch('updateSettings') // No need to await
        await commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST);
        await dispatch('loadSuggestions', {keep: true});
    },
};

let mutations = {
    SET_FILTER_VIEW_ID(state, {filterViewId}) {
        state.filters = {...state.filters, filterViewId, id: filterViewId}
    },
    SET_FILTER_DATA(state, {filters, type=null, overwrite=false}) {
        if (overwrite)
            state.filters = Object.assign({}, defaultFilters(type), filters)
        else
            state.filters = Object.assign({}, state.filters, filters)
    },
    SET_FILTER_VALUE(state, {key, value}) {
        state.filters = {...state.filters, [key]: value}
    },

    SET_IS_LOADING_TAGS : (state, bool) => {
        state.isLoadingTags = bool;
    },
    SET_TAGS: (state, {tags}) => {
        state.hasContentTags = tags;
    },
    SET_SELECTED_TAGS: (state, {tags}) => {
        var updated = { ...state.filters };
        updated.tags = tags;
        state.filters = updated;
    },
    SET_REQUIRED_FILTER: (state, {required}) => {
        var updated = { ...state.filters };
        updated.required = required;
        state.filters = updated;
    },
    SET_MIN_FULL_TEXT_LENGTH_FILTER: (state, {minFullTextLength}) => {
        var updated = { ...state.filters };
        updated.minFullTextLength = minFullTextLength;
        state.filters = updated;
    },
    SET_EXCLUDE_PAYWALLED_FILTER: (state, {excludePaywalled}) => {
        const updated = { ...state.filters };
        updated.excludePaywalled = excludePaywalled;
        state.filters = updated;
    },
    REGISTER_TAG: (state, {tag}) => {
        state.tags.add(tag)
    },
    [types.SET_SUGGESTION_TAG_LIST](state, {tags}){
        state.tags = tags;
    },
    START_LOADING_SUGGESTION_VIEW(state) {
        console.log('start loading filters bar')
        state.loadingFilters = true;
        state.loadingSuggestions = true;
    },
    FINISH_LOADING_FILTERS(state) {
        console.log('finish loading filters bar')
        state.loadingFilters = false
    },
    [types.SET_INCLUDE_NEW](state, { includeNew }) {
        let includeNewSources = includeNew !== false;
        state.filters = {...state.filters, includeNewSources}
    },
};
export default {
    state,
    getters,
    actions,
    mutations
}
