<template>
    <div class="d-flex align-items-center">
        <i v-if="icon" :class="icon"/>
        <topics-svg v-else-if="type === 'topics'" class="singular"></topics-svg>
        <collections-svg v-else-if="type === 'collections'" class="singular"></collections-svg>
    </div>
</template>

<script>
import {getIconClass} from "@/services/Icons";
import TopicsSvg from "../../../assets/svg/topics";
import CollectionsSvg from "../../../assets/svg/collections";

export default {
    name: "HasContentIcon",
    components: {CollectionsSvg, TopicsSvg},
    props: {
        hasContent: Object,
        type: String,
    },
    computed: {
        icon() {
            return this.type === 'collections'
            && this.hasContent
            && this.hasContent.icon
                ? getIconClass(this.hasContent.icon) : null
        }
    }
}
</script>

<style scoped>

</style>
