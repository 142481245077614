
<script>
    import {mapGetters} from 'vuex'
    export default {
        computed: {
            ...mapGetters({
                user : 'currentUser',
            })
        },
        methods: {
            newFileName(id, extension) {
                let date = new Date().toISOString().replace(/[\W.:-]/g, '');
                return [`${date}-${this.user.id.replace(/[\W.:-]/g, '')}-${id.replace(/[\W.:-]/g, '')}`, extension].filter(x=>x).join('.')
            }
        }
    }
</script>
