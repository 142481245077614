<template>
    <div class="collection-source-display-compact">
        <i class="source-icon"
           :class="`${findCollectionIcon(source.sourceCollectionId)}`"
           v-if="source.type === 'collection'"></i>
        <topics-svg class="source-icon top-1 me-2" v-if="source.type == 'topic'"/>
        <i class="fa fa-rss  source-icon" v-if="source.type == 'rss'"></i>

        <span>{{ displayName }}</span>
    </div>
</template>

<script>
import CollectionsSvg from "@/assets/svg/collections";
import TopicsSvg from "@/assets/svg/topics";
import {getIconClass} from "@/services/Icons";
import {mapGetters} from "vuex";
export default {
    name: "CollectionSourceDisplayCompact",
    props: ['source'],
    components: {TopicsSvg},
    computed: {
        ...mapGetters({
            collectionSources: 'collectionSources',
            accountFeatures: 'accountFeatures',
            collections: 'collections',
        }),
        displayName() {
            if (this.source.name) return this.source.name;
            if (this.source.url) return this.domain(this.source.url);
            return '';
        }
    },
    methods: {
        domain(url) {
            return url.replace(/https?:\/\//, '').replace(/\/.*/, '')
        },
        icon(iconName) {
            return getIconClass(iconName || 'bolt')
        },
        findCollectionIcon(sourceId) {
            if (!sourceId) {
                return '';
            }

            const collection = this.collections.find(c => c.id == sourceId);

            return collection && collection.icon ? this.icon(collection.icon) : 'bolt';
        },
    }
}
</script>

<style scoped lang="scss">
.collection-source-display-compact {
    // Set this up so text wrap to the next line is indented.
    padding-left: 1.25em;
}
.nav-icon, .source-icon {
    //font-size: 1rem;
    margin-left: -1.25em;
}

.nav-icon {
    .singular {
        visibility: hidden;
    }

    .multiple {
        visibility: visible;
    }

}

svg.source-icon {
    .singular {
        visibility: visible;
    }

    .multiple {
        visibility: hidden;
    }


}

.nav-icon, .source-icon {
    width: 1em;
    height: 1em;
    left: 1px;
}
</style>
