<!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: sanitization-->
<template>
    <popover-button
        v-show="usages.length"
        :title="title"
        :html="true"
        :content="content"
    >
        <i class="fa fa-info-circle"/>
        {{usages.length}}
        Usages
    </popover-button>
</template>
<script>
import FilterUsagesData from "./FilterUsagesData";
import * as $ from 'jquery'
import PopoverButton from "../Controls/PopoverButton";
import {sanitizeHtml} from "../../utilities/utils";
export function renderItems(categories) {
    let forEach = (items, map) => items.map(map).join('\n')
    let showIf = (condition, content) => condition ? content : ''
    //style='max-height:500px; overflow:auto;  margin-left:0; padding-left:0;'  class='font-size-12'
    return `
        ${forEach(categories, c => `
            <div class="fw-semibold">${sanitizeHtml(c.name)}</div>
            <ul class="list-group-flush mb-3 ps-0">
                ${forEach(c.usages, u => `
                    <li class="list-group-item small p-2">
                        <div>From: ${sanitizeHtml(u.display_data.source_name)} (${u.display_data.source_type})</div>
                        <div>To: ${sanitizeHtml(u.display_data.destination_name)} (${u.display_data.destination_type})</div>
                    </li>`
        )}
        </ul>`
    )}
    `
}
export default {
    name: "FilterUsagesLink",
    components: {PopoverButton},
    mixins: [FilterUsagesData],
    props: {
        filterViewId: { type: String }
    },
    computed: {
        title() {
            let n = this.usageCount;
            let usages = this.$strings.plural('Usage', n)
            // let other = n > 1 ? 'other ' : ''
            return `${n} ${usages}`
        },
        content() { return renderItems(this.categories)}
    },
}
</script>

<style scoped>

</style>
