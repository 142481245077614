<template>
    <form @submit.stop.prevent class="edit-selection p-0">
        <div class="container ps-1 pe-3" v-if="selection">
            <div class="row">
                <div class="col-12 col-sm">
                    <div class="mb-3">
                        <div class="row">
                            <div class="col">
                                <label class="label-calm" :for="$refs['selection-title']">Title</label>
                            </div>
                            <div class="col-3 col-sm-3 text-end" :class="{hide:
                            isEditEnabled('title')}">
                                <button type="button"
                                        v-if="canReset('title')"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="resetToPublisher('title')"
                                        aria-label="Reset title to the original publisher value."
                                        :title="`Reset to '${title}'`"
                                >
                                    <i class="fa fa-undo me-1" aria-hidden="true"></i>
                                    <span class="font-size-12">Reset</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-2 text-end" :class="{hide: isEditEnabled('title')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('title',true)" aria-label="Edit Title">
                                    <i class="fa fa-edit me-1 start-1" aria-hidden="true"></i>
                                    <span class="font-size-12">Edit</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-5 text-end" :class="{hide: !isEditEnabled('title')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('title',false)" v-if="!autoEditMode">
                                    <i class="fa fa-window-close me-1"></i>
                                    <span class="font-size-12 bottom-1">Cancel</span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" :class="{hide: isEditEnabled('title')}">
                                <div class="-title editable-field font-size-15 fw-semi">
                                    {{ title }}
                                </div>
                            </div>
                            <div class="col-12" :class="{hide: !isEditEnabled('title')}">
                        <textarea type="text"
                                  v-model="title"
                                  :class="{hide: !isEditEnabled('title')}"
                                  class="col -title form-control -input-sizing font-size-15 fw-semi"
                                  ref="selection-title"
                                  :readonly="isReadOnlyEnabled('title')"
                        />
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="row">
                            <div class="col">
                                <label class="label-calm" :for="$refs['selection-publisher']">Publisher</label>
                            </div>
                            <div class="col-4 col-sm-3 text-end" :class="{hide:
                            isEditEnabled('publisher')}">
                                <button type="button"
                                        v-if="canReset('publisher')"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="resetToPublisher('publisher')"
                                        aria-label="Reset publisher to the original publisher value."
                                        :title="`Reset to '${publisher}'`"
                                >
                                    <i class="fa fa-undo me-1" aria-hidden="true"></i>
                                    <span class="font-size-12">Reset</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-2 text-end" :class="{hide: isEditEnabled('publisher')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('publisher',true)" aria-label="Edit Publisher">
                                    <i class="fa fa-edit me-1 start-1" aria-hidden="true"></i>
                                    <span class="font-size-12">Edit</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-5 text-end" :class="{hide: !isEditEnabled('publisher')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('publisher',false)" v-if="!autoEditMode">
                                    <i class="fa fa-window-close me-1"></i>
                                    <span class="font-size-12 bottom-1">Cancel</span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" :class="{hide: isEditEnabled('publisher')}">
                                <div class=" editable-field font-size-12">
                                    {{ publisher }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" :class="{hide: !isEditEnabled('publisher')}">
                                <input type="text"
                                       v-model="publisher"
                                       :class="{hide: !isEditEnabled('publisher')}"
                                       class="col form-control -input-sizing font-size-12"
                                       ref="selection-publisher"
                                       :readonly="isReadOnlyEnabled('publisher')"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="row">
                            <div class="col">
                                <label class="label-calm" :for="$refs['selection-datePublished']">Publish Date</label>
                            </div>
                            <div class="col-4 col-sm-3 text-end" :class="{hide:
                            isEditEnabled('datePublished')}">
                                <button type="button"
                                        v-if="canReset('datePublished')"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="resetToPublisher('datePublished')"
                                        aria-label="Reset publish date to the original publisher value."
                                        :title="`Reset to '${displayDatePublishedText}'`"
                                >
                                    <i class="fa fa-undo me-1" aria-hidden="true"></i>
                                    <span class="font-size-12">Reset</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-2 text-end" :class="{hide: isEditEnabled('datePublished')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('datePublished',true)"
                                        aria-label="Edit Publish Date">
                                    <i class="fa fa-edit me-1 start-1" aria-hidden="true"></i>
                                    <span class="font-size-12">Edit</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-5 text-end" :class="{hide: !isEditEnabled('datePublished')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('datePublished',false)"
                                        v-if="!autoEditMode">
                                    <i class="fa fa-window-close me-1"></i>
                                    <span class="font-size-12 bottom-1">Cancel</span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" :class="{hide: isEditEnabled('datePublished')}">
                                <div class=" editable-field font-size-12">
                                    <template v-if="displayDatePublished">
                                        {{ displayDatePublished }}
                                    </template>
                                    <template v-else>
                                        <span>Undated</span>
                                        <i class="ms-1 fa fa-tree text-green-50 font-size-10 bottom-1"
                                           aria-hidden="true"></i>
                                    </template>
                                </div>
                            </div>
                            <div class="col-12" :class="{hide: !isEditEnabled('datePublished')}">
                                <input type="date"
                                       v-model="datePublished"
                                       :class="{hide: !isEditEnabled('datePublished')}"
                                       class="col -date-input form-control -input-sizing font-size-12"
                                       ref="selection-datePublished"
                                       :readonly="isReadOnlyEnabled('datePublished')"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">

                        <div class="row">
                            <div class="col">
                                <label class="label-calm" :for="$refs['selection-summary']">Abstract</label>
                            </div>
                            <div class="col-4 col-sm-5 text-end" :class="{hide:
                            isReadOnlyEnabled('summary')}">
                                <button type="button"
                                        v-if="canReset('summary')"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="resetToPublisher('summary')"
                                        aria-label="Reset abstract to the original publisher value."
                                        :title="`Reset to '${displayTrimmedSummaryText}'`"
                                >
                                    <i class="fa fa-undo me-1" aria-hidden="true"></i>
                                    <span
                                        class="font-size-12">Reset</span>
                                </button>
                            </div>
                            <div class="col-4 col-sm-5 text-end" :class="{hide: !isEditEnabled('summary')}">
                                <button type="button"
                                        class="ui-link font-size-13 text-nowrap"
                                        @click="toggleArticleEdit('summary',false)" v-if="!autoEditMode">
                                    <i class="fa fa-window-close me-1"></i>
                                    <span class="font-size-12 bottom-1">Cancel</span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <textarea type="text"
                                          v-model="summary"
                                          class="col -summary form-control -input-sizing font-size-12"
                                          ref="selection-summary"
                                          :readonly="isReadOnlyEnabled('summary')"
                                />
                                <output class="small text-muted pull-right" >
                                    <span :class="{'text-danger': abstractOverLength, 'fw-bold': abstractOverLength}">{{summary && summary.length || 0}}</span>
                                    <span v-if="collectionAbstractLimit">/{{collectionAbstractLimit}}</span>
                                </output>

                            </div>
                        </div>

                    </div>

                </div><!-- class="col" -->

                <div class="col-12 col-md-4">
                    <spinner v-if="imageLoading" size="155"></spinner>
                    <hero-image v-else :url="imagePreviewUrl" height="155px"/>
                    <label class="d-block mt-2" v-if="accountFeatures.customSelectionImageText">
                        <div class="custom-file file-compact small">
                            <input-type-file name="inputGroupFile01"
                                             ref="fileInput"
                                             accept="image/gif, image/jpeg, image/png, image/svg"
                                             @changed="fileSelected"
                            />
                        </div>
                        <div class="mt-1" v-if="!imageIsPublisher">
                            <button type="button"
                                    class="btn btn-secondary btn-sm full-width"
                                    @click="resetImage()">Reset to Publisher
                            </button>
                        </div>
                        <div class="mt-1"
                             v-if="accountFeatures.getty  && !isBrowserExtension">
                            <button type="button"
                                    class="btn btn-outline-dark full-width"
                                    @click="editImage()">
                                <div class="small">From
                                    <img src="../../assets/images/integrations/getty-word-mark-light.png"
                                         alt="Getty Images"
                                         style="height: 1em"/>
                                </div>
                            </button>
                        </div>
                    </label>
                    <span class="small"><suggestion-tags :page="selection" class="tags-left mt-3"></suggestion-tags></span>
                    <div class=" font-size-12 text-water-0 font-italic mt-4">
                        <p>By adding this content, you are agreeing to our <a
                            :href="termsOfServiceUrl">terms of service</a>, including those
                            which
                            govern the use of copyrighted materials. </p>
                    </div>
                </div>

            </div><!-- class="row" -->

        </div>
    </form>
</template>


<script>
import {mapGetters} from 'vuex'
import HeroImage from "@/components/Suggestions/HeroImage"
import {base64ToFile, extension} from "@/services/files";
import Spinner from "@/components/Controls/Spinner";
import {knownTags} from "@/Constants";
import SuggestionTags from "@/components/Suggestions/SuggestionTags";
import ImageDownloader from "@/components/ImageDownloader";
import FileName from '@/utilities/FileName'
import {isBrowserExtension} from "@/services/Context";
import {editImage} from "@/services/editImage";
import {base64Pad} from "../../services/files";
import Booster from "@/components/Subscriptions/Booster";
import moment from "moment"
import InputTypeFile from "@/utilities/InputTypeFile";

export default {
    name: 'EditSelection',
    mixins: [FileName],
    components: {InputTypeFile, ImageDownloader, SuggestionTags, Spinner, HeroImage},
    props: {
        selection: {},
        collectionAbstractLimit: {type: Number, default: null},
        defaultTitle: {type: String, default: null},
        defaultSummary: {type: String, default: null},
        defaultPublisher: {type: String, default: null},
        defaultDatePublished: {type: String, default: null},
        page: {},
        autoEditMode: {type: Boolean, default: false},
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            features: 'accountFeatures',
            qa: 'qa',
            prod: 'prod',
            accountFeatures: 'accountFeatures',
            termsOfServiceUrl: 'termsOfServiceUrl'
        }),
        abstractLength() { return this.summary && this.summary.length || 0; },
        abstractOverLength() {return this.abstractLength > this.collectionAbstractLimit && this.collectionAbstractLimit },
        isBrowserExtension,
        imageFormat() {
            return this.imageFile ? extension(this.imageFile.name) : this.base64ImageExtension
        },
        uploadMode() {
            return this.accountFeatures.getty;
        },
        tags() {
            return this.selection.tags.map(t => {
                if (knownTags[t.key]) {
                    return Object.assign({}, t, knownTags[t.key])
                } else return t;
            })
        },
        imageLoading() {
            return this.imgPreviewSrc === '/assets/images/spinner.svg'
        },
        imagePreviewUrl() {
            return this.imgPreviewSrc;
        },
        imageHasBeenChanged() {
            return this.imageUrl !== this.uneditedImageUrl
        },
        imageIsPublisher() {
            return (this.imageUrl === this.publisherImage || !this.imageUrl) && !this.imageFile
        },
        displayDatePublished() {
            if (this.selection.date_published && this.selection.date_published.length >= 1) {
                return moment(this.selection.date_published).format('LL');
            }
            return false;
        },
        displayDatePublishedText() {
            if (this.selection.date_published && this.selection.date_published.length >= 1) {
                return moment(this.selection.date_published).format('LL');
            }
            return "Undated";
        },
        displayTrimmedSummaryText() {
            if (this.selection.summary && this.selection.summary.length >= 1) {
                // Using a 150/180 threshold for readability.
                let summary = this.selection.summary;

                if (summary.length <= 150) {
                    return summary;
                }

                return (summary.length > 150 && summary.length <= 180) ? summary : `${summary.substring(0, 180)}…`;
            }
            return "";
        },
    },
    data() {
        return {
            url: this.defaultURL || this.selection && this.selection.url || '',
            title: this.defaultTitle || this.selection && this.selection.title || '',
            summary: this.defaultSummary || this.selection && this.selection.summary || '',
            publisher: this.defaultPublisher || this.selection && this.selection.publisher || '',
            datePublished: this.defaultDatePublished || this.selection && this.selection.date_published || '',
            imageUrl: null,
            uneditedImageUrl: null,
            imageFile: null,
            base64ImageExtension: null,
            base64ImageData: null,
            imgPreviewSrc: this.selection && this.selection.image_url,
            shutterStockResult: null,
            editSummary: true,
            publisherImage: this.selection && this.selection.image_url_from_publisher || null,
            publisherExcerpt: this.selection && this.selection.summary_from_publisher || null,
            original: {
                title: this.selection && this.selection.original_title || false,
                summary: this.selection && this.selection.summary_from_publisher || false,
                publisher: this.selection && this.selection.original_publisher || false,
                datePublished: this.selection && this.selection.original_published || false,
            },
            isEditing: {
                title: false,
                summary: false,
                publisher: false,
                datePublished: false,
            },
            isReadOnly: {
                title: false,
                summary: false,
                publisher: false,
                datePublished: false,
            },
            initialValue: {
                title: this.defaultTitle || this.selection && this.selection.title || '',
                summary: this.defaultSummary || this.selection && this.selection.summary || '',
                publisher: this.defaultPublisher || this.selection && this.selection.publisher || '',
                datePublished: this.defaultDatePublished || this.selection && this.selection.date_published || '',
            },
        }
    },
    watch: {
        selection() {
            Object.assign(this, {
                url: this.defaultURL || this.selection && this.selection.url || '',
                title: this.defaultTitle || this.selection && this.selection.title || '',
                summary: this.defaultSummary || this.selection && this.selection.summary || '',
                publisher: this.defaultPublisher || this.selection && this.selection.publisher || '',
                datePublished: this.defaultDatePublished || this.selection && this.selection.date_published || '',
                imageUrl: (this.selection.dontReset) ? this.imageUrl : null,
                uneditedImageUrl: null,
                imageFile: (this.selection.dontReset) ? this.imageFile : null,
                imgPreviewSrc: this.selection.image_url,
                editSummary: true,
                publisherImage: this.selection && this.selection.image_url_from_publisher || null,
                publisherExcerpt: this.selection && this.selection.summary_from_publisher || null,
                initialValue: {
                    title: this.defaultTitle || this.selection && this.selection.title || '',
                    summary: this.defaultSummary || this.selection && this.selection.summary || '',
                    publisher: this.defaultPublisher || this.selection && this.selection.publisher || '',
                    datePublished: this.defaultDatePublished || this.selection && this.selection.date_published || '',
                },
                original: {
                    title: this.selection && this.selection.original_title || false,
                    summary: this.selection && this.selection.summary_from_publisher || false,
                    publisher: this.selection && this.selection.original_publisher || false,
                    datePublished: this.selection && this.selection.original_published || false,
                },
                isEditing: {
                    title: false,
                    summary: false,
                    publisher: false,
                    datePublished: false,
                },
            });
            this.loadAdditionalData();
            this.$refs.fileInput.value = null;
        }
    },
    methods: {
        async loadAdditionalData() {
            if(!this.selection || !this.selection.id) return;

            let selection = await this.$api.get(`/selections/${this.selection.id}`).then(r => r.data);
            this.publisherImage = selection && selection.image_url_from_publisher || null;
            let selectionImageUrl = selection && selection.raw_image_url && selection.raw_image_url;
            this.imageUrl = selectionImageUrl !== this.publisherImage ? selectionImageUrl : null;
            this.uneditedImageUrl = this.imageUrl;
            this.publisherExcerpt = selection && selection.summary_from_publisher || null;
            Object.assign(this, {
                original: {
                    title: selection && selection.original_title || false,
                    summary: selection && selection.summary_from_publisher || false,
                    publisher: selection && selection.original_publisher || false,
                    datePublished: selection && selection.original_published || false,
                }
            })
        },
        async uploadAndSave() {
            await this.upload();
            return await this.saveEditedData();
        },
        async saveEditedData(selection = null) {
            selection = selection || this.selection;
            await Promise.all([
                this.saveImageUrl(selection),
                this.saveSummary(selection),
                this.saveTitle(selection),
                this.savePublisher(selection),
                this.saveDatePublished(selection),
            ])
            return (await this.$api.get(`/suggestions/${selection.id}`)).data
        },
        async saveImageUrl(selection) {
            if (this.imageHasBeenChanged) {
                let image = this.imageIsPublisher ? null : this.imageUrl || null;
                return this.$api.put(`/selections/${selection.id}/image`, {image});
            }
        },
        async saveSummary(selection) {
            if (this.summary !== (this.selection && this.selection.summary))
                return this.$api.put(
                    `/selections/${selection.id}/summary`,
                    {
                        summary: this.summary || null
                    });
        },
        async saveTitle(selection) {
            if (this.title !== (this.selection && this.selection.title))
                return this.$api.put(
                    `/selections/${selection.id}/title`,
                    {title: this.title || null}
                );
        },
        async savePublisher(selection) {
            if (this.publisher !== (this.selection && this.selection.publisher))
                return this.$api.put(
                    `/selections/${selection.id}/publisher`,
                    {publisher: this.publisher || null}
                );
        },
        async saveDatePublished(selection) {
            if (this.datePublished !== (this.selection && this.selection.date_published))
                return this.$api.put(
                    `/selections/${selection.id}/publish_date`,
                    {publish_date: this.datePublished || null}
                );
        },
        async upload() {
            // if (this.shutterStockResult) {
            // this.shutterstockCommit();
            // }
            if (this.imageFile || this.base64ImageData) {
                return await this.uploadImageFile()
            }
        },
        async uploadImageFile() {
            const id = this.selection && this.selection.id || this.page && this.page.id;
            const fileName = this.newFileName(id, this.imageFormat);
            const formData = this.getImageUploadData(fileName);
            let imageResult = await this.$api.post(`/accounts/${this.currentAccountOverview.id}/images`, formData);
            let imageUrl = imageResult && imageResult.data && imageResult.data.link;
            this.imgPreviewSrc = imageUrl;
            this.imageUrl = imageUrl;
            return imageUrl;
        },
        getImageUploadData(fileName) {
            let formData = new FormData();
            if (this.imageFile) {
                const file = new File([this.imageFile], fileName);
                formData.append('file', file, fileName);
            } else if (this.base64ImageData) {
                // formData = {
                //     base64_data: this.base64ImageData,
                //     filename: fileName
                // };
                // formData.set('base64_data', base64Pad(this.base64ImageData));
                // formData.set('filename', fileName)
            }

            return formData;
        },
        fileSelected() {
            this.imageFile = event.target.files[0];
            let reader = new FileReader();
            reader.onload = e => this.imgPreviewSrc = e.target.result;
            reader.readAsDataURL(this.imageFile);
            this.shutterStockResult = null;
        },
        resetImage() {
            this.imageUrl = '';
            this.imageFile = null;
            this.imgPreviewSrc = this.publisherImage;
        },
        async editImage() {
            let {base64Data, image, format, file} = await editImage(
                this.imageFile ? {file: this.imageFile} : {path: this.imageUrl});
            this.imgPreviewSrc = image.src;
            this.imageFile = file
            this.base64ImageData = base64Data;
            this.base64ImageExtension = format;
        },
        resetToPublisher(valueEditing) {
            this.$data[valueEditing] = this.original[valueEditing];
            this.isReadOnly[valueEditing] = true;
            this.toggleArticleEdit(valueEditing, true);
        },
        toggleArticleEdit(valueEditing, editing) {
            this.isEditing[valueEditing] = editing;

            if (!editing) {
                this.$data[valueEditing] = this.initialValue[valueEditing];
                this.isReadOnly[valueEditing] = false;
                return;
            }

            if (this.isReadOnly[valueEditing]) {
                return;
            }

            setTimeout(() => {
                if (this.$data[valueEditing]) {
                    this.$refs[`selection-${valueEditing}`].focus()
                }
            }, 75);
        },
        resetArticleEdit() {
            Object.keys(this.isEditing).forEach(v => this.toggleArticleEdit(v, false));
        },
        isEditEnabled(valueEditing) {
            return (this.autoEditMode || this.isEditing && this.isEditing[valueEditing])
        },
        isReadOnlyEnabled(valueEditing) {
            return (this.isReadOnly && this.isReadOnly[valueEditing])
        },
        canReset(valueEditing) {
            let og = this.original && this.original[valueEditing];
            if (og && (og !== '' || og !== null) && og !== this.initialValue[valueEditing]) {
                return true;
            }
            return false;
        },
        getInputData() {
            return {
                "url": this.url,
                "title": this.title,
                "summary": this.summary,
                "publisher": this.publisher,
                "publish_date": this.datePublished,
            }
        }
    },
    mounted() {
        this.loadAdditionalData();
    },
}

</script>

<style scoped type="text/css">
.tags-left{
    text-align: left !important;
}
div.-input-sizing, button.-input-sizing {
    border-color: transparent;
}

.-label-height {
    min-height: 2rem;
}
.btn.btn-outline-dark:hover {
    background: var(--bs-light);
    color: var(--bs-dark)
}

.-input-sizing {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    padding: .375rem .75rem;
    line-height: 1.5;
}

.-summary {
    min-height: 20ex;
}

.-title {
    min-height: 11ex;
}

.fw-semi {
    font-weight: 500;
}

.-date-input {
    /* width: 13em !important; */
}
</style>
