<template>
    <fieldset class="card form-group-card">
        <legend class="card-header"><slot name="legend"></slot><span>{{title}}</span></legend>
        <div class="card-body">
            <slot/>
        </div>
    </fieldset>
</template>

<script>
export default {
    name: "FormGroupCard",
    props: {
        title: {type: String, default: ""}
    }
}
</script>

<style scoped>
    legend.card-header {
        font-size: 1rem;
    }
    .form-group-card {
        margin-bottom: 1.5rem;
    }
</style>
