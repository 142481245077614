<template>
    <div v-if="group">
        <h3 v-if="isSuggestion" class="group-title" :id="'group-'+group.id">
            <a href="#" @click="loadReader(group)">
                {{group.name}} <i class="fa fa-arrow-circle-right" />
            </a><br/>
            <small v-if="topic">Topic: {{topic.name}}</small>
        </h3>
        <h3 v-if="isTopic || isUser" class="group-title">
            {{group.name}}
        </h3>

        <div v-if="isUser">
            <note-viewer 
                :note="note" 
                v-for="note in pagedNotes" 
                :key="note.id+'-grouped-in-'+group.id"
                :show-target="true"
            />
        </div>
        <div v-else>
            <note-viewer 
                :note="note" 
                v-for="note in pagedNotes" 
                :key="note.id+'-grouped-in-'+group.id"
            />
        </div>


        <paginator :size="5" :items="notes" @update="setPagedNotes" />

        <div v-if="!isUser">
            <notes-creator v-if=" ! networkActivity " :target="group" @noteAdded="saveNote" />
            <progress-bar v-else />
        </div>

    </div>
</template>

<script>
import NoteViewer from '../Note'
import NotesCreator from '../Creator'
import ProgressBar from '@/utilities/ProgressBar'
import Paginator from '@/utilities/Paginator'
import * as $ from 'jquery'

// import uuid from 'uuid';
import {mapGetters} from 'vuex'

export default {
    props : ['group'],
    data(){
        return {
            networkActivity : false,
            temporaryNote : false,
            pagedNotes : []
        }
    },
    components : {
        NoteViewer, NotesCreator, ProgressBar, Paginator
    }, 

    computed : {
        ...mapGetters({
            suggestions : 'suggestionsCache',
            currentUser : 'currentUser',
            topics : 'topics',
            suggestionViewerType : 'suggestionViewerType'
        }),
        notes(){
            return this.group.notes.filter( note => {
                if (this.suggestionViewerType == 'collections'){
                    return !! note.collection
                } else {
                    return !! note.topic;
                }
            })
        },
        isSuggestion(){
            return !!this.group.title;
        },
        isUser(){
            return !!this.group.role
        },
        isTopic(){
            return !!this.group.queries;
        },
        topic(){
            return this.topics.find(t => this.group.topic && this.group.topic.includes(t.id));
        }
    },
    methods : {
        setPagedNotes(notes){
            this.pagedNotes = notes;
        },
        loadReader(group){
            let suggestion = this.suggestions.find(s => s.id == group.id);
            if (suggestion ){
                this.$store.dispatch('setReadingSuggestion', {suggestion});
            }
        },
        saveNote({note, target}){
            this.networkActivity = 'Saving note...'
            this.temporaryNote = note;
            var url;
            if ( this.isSuggestion ){
                url = `/suggestions/${target.id}/notes`
            } else {
                url = `/topics/${target.id}/notes`
            }
            this.$api.post(url, {text : note.text})
            .then(response => {
                this.temporaryNote = false;
                this.networkActivity = false;
                let newNote = response.data;
                this.$store.dispatch('addNote', {note : newNote});
                $('#notes-reader-modal').animate({'scrollTop': 0}, 500);
            })
        },
    }
}
</script>


<style scoped>

.group-title {
    font-size: 14px;
    margin: 8px 0 16px;
}
</style>
