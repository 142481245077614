export const CACHE_FULL_SUGGESTION = "CACHE_FULL_SUGGESTION"
export const EMPTY_SUGGESTIONS = "EMPTY_SUGGESTIONS"
export const SET_SUGGESTION_URLS = "SET_SUGGESTION_URLS"
export const APPEND_URL_TO_SUGGESTIONS = "APPEND_URL_TO_SUGGESTIONS"
export const SET_SUGGESTION_SOURCE_LIST = "SET_SUGGESTION_SOURCE_LIST"
export const SET_SUGGESTION_TAG_LIST = 'SET_SUGGESTION_TAG_LIST'
export const ADD_SOURCE_FOR_SUGGESTION = "ADD_TO_SUGGESTION_SOURCE_LIST"
export const SET_EXCLUDED_SUGGESTION_SOURCE_LIST = "SET_EXCLUDED_SUGGESTION_SOURCE_LIST"
export const SET_INCLUDED_SUGGESTION_SOURCE_LIST = "SET_INCLUDED_SUGGESTION_SOURCE_LIST"
export const SET_SOURCE_SELECTION = "SET_SOURCE_SELECTION"
export const SET_INCLUDE_NEW = "SET_INCLUDE_NEW"
export const EXCLUDE_SOURCE = "EXCLUDE_SOURCE"
export const SET_SELECTED_TAGS = 'SET_SELECTED_TAGS'
export const SET_REQUIRED_FILTER = 'SET_REQUIRED_FILTER'
export const BEGIN_DOWNLOADING_SUGGESTIONS_LIST = "BEGIN_DOWNLOADING_SUGGESTIONS_LIST"
export const BEGIN_DOWNLOADING_SUGGESTIONS = "BEGIN_DOWNLOADING_SUGGESTIONS"
export const COMPLETE_DOWNLOADING_SUGGESTIONS_LIST = "COMPLETE_DOWNLOADING_SUGGESTIONS_LIST"
export const COMPLETE_DOWNLOADING_SUGGESTIONS = "COMPLETE_DOWNLOADING_SUGGESTIONS"
export const INCREMENT_SUGGESTIONS_QUERY_PAGE_OFFSET = "INCREMENT_SUGGESTIONS_QUERY_PAGE_OFFSET"
export const RESET_SUGGESTIONS_QUERY_PAGE_OFFSET = "RESET_SUGGESTIONS_QUERY_PAGE_OFFSET"

export const BEGIN_DOWNLOADING_NOTES = "BEGIN_DOWNLOADING_NOTES"
export const COMPLETE_DOWNLOADING_NOTES = "COMPLETE_DOWNLOADING_NOTES"
export const SET_READING_SUGGESTION = "SET_READING_SUGGESTION"
export const RESET_SCROLL_TO_NOTES = "RESET_SCROLL_TO_NOTES"
export const TOGGLE_ACCOUNT_NOTES_READER = "TOGGLE_ACCOUNT_NOTES_READER"
// export const TOGGLE_SHOW_FAVORITES = "TOGGLE_SHOW_FAVORITES"

export const UPDATE_SOURCE_LIST = "UPDATE_SOURCE_LIST"
export const TOGGLE_ITEM_IN_SELECTED_SOURCE_LIST = "TOGGLE_ITEM_IN_SELECTED_SOURCE_LIST"
export const RESET_SELECTED_SOURCE_LIST = "RESET_SELECTED_SOURCE_LIST"
export const TOGGLE_SUGGESTION_FAV_STATE = "TOGGLE_SUGGESTION_FAV_STATE"
export const SET_CURRENT_TOPIC = "SET_CURRENT_TOPIC"
export const SET_SELECTED_SOURCE_LIST = "SET_SELECTED_SOURCE_LIST"

export const USER_DID_LOG_IN = "USER_DID_LOG_IN"
export const USER_DID_LOG_OUT = "USER_DID_LOG_OUT"
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const SET_INVITE_TOKEN = "SET_INVITE_TOKEN"
export const SET_ACCOUNT = "SET_ACCOUNT"
export const SET_FOLDER = 'SET_FOLDER'
export const SET_TOPIC = 'SET_TOPIC'
export const SELECT_FIRST_TOPIC_IN_CURRENT_FOLDER = "SELECT_FIRST_TOPIC_IN_CURRENT_FOLDER"
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE"

export const ADD_ACCOUNT = "ADD_ACCOUNT"
export const ADD_ACCOUNT_OVERVIEW = "ADD_ACCOUNT_OVERVIEW"
export const ADD_FOLDER = "ADD_FOLDER"
export const ADD_FOLDERS = "ADD_FOLDERS"
export const ADD_COLLECTIONS = "ADD_COLLECTIONS"
export const ADD_FOLDER_TO_FOLDER = "ADD_FOLDER_TO_FOLDER"
export const ADD_LOCAL_FOLDER_TO_ACCOUNT = "ADD_LOCAL_FOLDER_TO_ACCOUNT"
export const ADD_TOPIC = "ADD_TOPIC"
export const ADD_TOPICS = "ADD_TOPICS"
export const ADD_NOTE = "ADD_NOTE"
export const ADD_NOTE_TO_TOPIC = "ADD_NOTE_TO_TOPIC"
export const ADD_SUBSCRIPTION_TO_ACCOUNT = "ADD_SUBSCRIPTION_TO_ACCOUNT"

export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER"
export const ADD_TEAM_MEMBER_TO_ACCOUNT = "ADD_TEAM_MEMBER_TO_ACCOUNT"
export const UPDATE_TEAM_MEMBER_IN_ACCOUNT = "UPDATE_TEAM_MEMBER_IN_ACCOUNT"
export const REMOVE_TEAM_MEMBER_FROM_ACCOUNT = "REMOVE_TEAM_MEMBER_FROM_ACCOUNT"
export const MOVE_TOPICS_TO_FOLDER = "MOVE_TOPICS_TO_FOLDER"
export const MOVE_COLLECTIONS_TO_FOLDER = "MOVE_COLLECTIONS_TO_FOLDER"

export const DELETE_TOPIC = "DELETE_TOPIC"
export const DELETE_SUGGESTION = "DELETE_SUGGESTION"
export const DELETE_FOLDER = "DELETE_FOLDER"
export const DELETE_NOTE = "DELETE_NOTE"
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER"
export const DELETE_COLLECTION = "DELETE_COLLECTION"
export const DELETE_FILTER_VIEW = "DELETE_FILTER_VIEW"

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"
export const UPDATE_TOPIC = "UPDATE_TOPIC"
export const UPDATE_SUGGESTION = "UPDATE_SUGGESTION"
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"

export const REMOVE_COLLECTION_FROM_SUGGESTION_CONTEXTS = "REMOVE_COLLECTION_FROM_SUGGESTION_CONTEXTS"

export const SET_USER_ARTICLE_READER_PREFERENCES = "SET_USER_ARTICLE_READER_PREFERENCES"
export const SET_SUGGESTION_FILTERS = "SET_SUGGESTION_FILTERS"

export const BEGIN_DOWNLOADING_ACCOUNT = "BEGIN_DOWNLOADING_ACCOUNT"
export const COMPLETE_DOWNLOADING_ACCOUNT = "COMPLETE_DOWNLOADING_ACCOUNT"
export const SET_LATEST_ACCOUNTS_FETCHED_AT = "SET_LATEST_ACCOUNTS_FETCHED_AT"

export const SET_USER_PREFERENCE = "SET_USER_PREFERENCE"
export const REMOVE_USER_PREFERENCE = "REMOVE_USER_PREFERENCE"
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES"
export const SET_USER_SIGNUP_PLAN = "SET_USER_SIGNUP_PLAN"
export const SET_USER_SIGNUP_PATH = "SET_USER_SIGNUP_PATH"

export const SET_SIGNUP_PLAN = "SET_SIGNUP_PLAN"
export const SET_SIGNUP_FREQUENCY = "SET_SIGNUP_FREQUENCY"
export const SET_TERMS_AND_PRIVACY_ACCEPTED_DATE = "SET_TERMS_AND_PRIVACY_ACCEPTED_DATE"
export const SET_PLAN_AND_FREQUENCY_FROM_STRIPE_KEY = "SET_PLAN_AND_FREQUENCY_FROM_STRIPE_KEY"

//Split Testing Options

export const SET_SHOW_IMAGES_IN_LIST_VIEW = "SET_SHOW_IMAGES_IN_LIST_VIEW"
export const SET_VENDOR = "SET_VENDOR"
