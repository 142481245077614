
export function base64ToDataUrl(base64) {
    return `data:image/png;base64,${base64}`
}
export function base64ToImageDataUrl(format, base64) {
    return `data:image/${format};base64,${base64}`
}
export function dataFromBase64PngDataUrl(url) {
    return url.replace('data:image/png;base64,', '')
}
export function dataFromBase64JpegDataUrl(url) {
    console.log('dataFromBase64JpegDataUrl', url.substring(0, 20))
    return url.replace('data:image/jpeg;base64,', '')
}

export function dataFromBase64ImageUrl(url) {
    let format = url.startsWith('data:image/png') ? 'png' : url.startsWith('data:image/jpeg') ? 'jpeg' : null;
    if (format === 'png') return {format, data: dataFromBase64PngDataUrl(url)}
    if (format === 'jpeg') return {format, data: dataFromBase64JpegDataUrl(url)}
    throw "Invalid Image Format"
}

export function base64ToBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}


export function base64ToFile(base64, name, contentType='') {
    let blob = base64ToBlob(base64, contentType);
    let file = new File([blob], name);
    console.log('base64ToFile.file', file)
    return file;
}

export function extension(fileName) {
    let parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : null
}

export function base64Pad(value) {
    while ((value.length % 4) !== 0) {
        value += '='
    }
    console.log('base64Pad', value)
    return value
}
