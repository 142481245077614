import {base64ToFile, dataFromBase64ImageUrl} from "./files";

export function editImage({path, file}) {
    return new Promise((resolve, reject) => {
        window.Events.$once('imglyImageExport', image => {
            console.log('window.imglyImageExport', image)
            let dataUrl = image.src;
            console.log('dataUrl', dataUrl)
            let {format, data} = dataFromBase64ImageUrl(dataUrl);
            let file = base64ToFile(data, `${new Date().toISOString()}.${format}`, `image/${format}`)
            window.Events.$off('imglyClose')
            window.Events.$emit('close_photoEdit')

            resolve({image, base64Data: data, format, file})
        });
        window.Events.$once('imglyClose', () => {
            console.log('imglyClose')
            window.Events.$emit('close_photoEdit')
            window.Events.$off('imglyImageExport')
            reject()
        })
        require.ensure([], require => {
            require('../../public/assets/images/white-800x600.png')
        })
        // let path = require.resolve('../assets/images/white-800x600.png');
        // console.log('assetPath', path)
        // web
        window.Events.$emit('show_photoEdit', {path: '/assets/images/white-800x600.png'})
    })
}
