<template>
    <div>
        <div v-if="isOpen">
            <div class="d-flex align-items-center">
                <h6>Email</h6>
                <a href="#" class="ms-auto small" @click.prevent="isOpen = !isOpen">Hide Info</a>
            </div>
            <p>{{ invitation.email }}</p>
            <div class="d-flex justify-content-between">
                <div>
                    <h6>Role</h6>
                    <p>{{ showRole }}</p>
                </div>
                <div>
                    <h6>Sent</h6>
                    <p> {{ showCreated }}</p>
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h6>Expires</h6>
                    <p v-if=" invitation.expires">
                        {{ showExpires }}
                    </p>
                    <p v-else>
                        in 7 days
                    </p>
                </div>
                <div>
                    <div class="btn-group btn-group-sm">
                        <button @click="resendInvitation" class="btn btn-success btn-sm border">
                            <i class="fa fa-paper-plane text-white"></i> Resend
                        </button>
                        <button @click="rescindInvitation" class="btn btn-danger btn-sm border">
                            <i class="fa fa-times text-white"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <div v-else>
            <span>{{ invitation.email }}</span>
            <br/>
            <span class="d-flex align-items-center">
                <small class="text-muted">Sent {{ showCreated }}</small>
                <a href="#" class="small ms-3" @click.prevent="isOpen = !isOpen">View Info</a>
                <a href="#"
                   class="ms-auto text-muted"
                   @click.prevent="rescindInvitation"><i class="fa fa-times-circle"></i></a>
            </span>
        </div>
    </div>
</template>

<script>
import BaseMixin from './Base';
import moment from "moment";
import capitalize from "capitalize";

export default {
    mixins: [BaseMixin],
    data() {
        return {
            isOpen: false
        }
    },
    computed: {
        showRole() {
            return capitalize(this.invitation.role);
        },
        showCreated() {
            return this.prettyDate(this.invitation.created);
        },
        showExpires() {
            return this.prettyDate(this.invitation.expires);
        },
    },
    methods: {
        prettyDate(date) {
            return date ? moment.utc(date).fromNow() : '';
        },
    },
}
</script>

<style>
</style>
