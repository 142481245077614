import {Filters} from "../../models/Filters";
import {defaultFilters} from "../../services/Filters";
import {defaultSortForCollection, disallowedSortsForTopic,disallowedSortsForCollection,defaultSortForTopic} from "@/Constants";
import * as types from "@/store/mutation-types";

const fieldsThatReloadPublishers = ['contentType', 'age', 'evergreen']

let state = {
    filterViewId: null,
    filterView: null,
    filterViews: [],
}

let getters = {
    currentFilterViewId: state => state.filterViewId,
    currentFilterView: state =>
        state.filterView instanceof Filters ? state.filterView : Object.assign(new Filters(), state.filterView),
    filterViewPublishers: (state, getters) =>
        state.filterView ?
            state.filterView.includeNewSources ?
                state.filterView.excludedSources :
                state.filterView.sources :
            [],
    filterViewOptions: state => state.filterViews
        .filter(filterView =>filterView.name !== null || state.filterViewId === filterView.id)
        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
    allFilterViews: state => state.filterViews,
}

async function fetchFilterViews(accountId) {
    if (!accountId) return [];
    let filterViews = await window.$app.api.get(`/accounts/${accountId}/filter-views`).then(response => {
        return response.data.map(Filters.fromApi);
    });

    filterViews = [...filterViews];

    return filterViews;
}

async function fetchFilterView(filterViewId) {
    if (!filterViewId) return null;
    let result = await window.$app.api.get(`/filter-views/${filterViewId}`);
    let filterView = Filters.fromApi(result.data);
    console.log('fetchFilterView', {filterView, toApi: filterView.toApi})
    return filterView;
}

let actions = {
    async loadFilterViewList({commit, getters}) {
        if (!getters.currentAccountOverview) return;
        await commit(mutations.SET_FILTER_VIEW_LIST.name, {filterViews: await fetchFilterViews(getters.currentAccountOverview.id)})
    },
    async loadFilterView({commit, dispatch}, {filterViewId}) {
        commit('SET_FILTER_VIEW_ID', {filterViewId})
        let filterView = await fetchFilterView(filterViewId);
        await commit(mutations.SET_FILTER_VIEW_DATA.name, {filterView})
        return filterView
    },
    async filterViewSelected({commit, dispatch, state, getters}, {filterViewId}) {
        if (filterViewId === state.filterViewId || getters.loadingFilterBar) return;
        let newFilters = await dispatch(actions.loadFilterView.name, {filterViewId})
        const isCollection = getters.hasSuggestions.type === 'collection';
        const isTopic = getters.hasSuggestions.type === 'topic';
        if (isCollection && disallowedSortsForCollection.includes(newFilters.sortBy)) {
            newFilters.sortBy = defaultSortForCollection;
        }
        if (isTopic && disallowedSortsForTopic.includes(newFilters.sortBy)) {
            newFilters.sortBy = defaultSortForTopic;
        }
        if (isTopic && newFilters.age === null || newFilters.age === -1) {
            newFilters.age = 60;
            newFilters.time = "2 Months";
            newFilters.customTime = "";
        }
        await commit('SET_FILTER_DATA' , {filters: newFilters})
        dispatch('updateSettings')

        await dispatch('loadPublishers', {})
        await dispatch('loadSuggestions', {keep: true})
    },
    async resetFiltersToView({commit, dispatch, state}, ) {
        let filterViewId = state.filterViewId;
        let oldFilters = getters.currentFilters;
        let newFilters = await dispatch(actions.loadFilterView.name, {filterViewId})
        await commit('SET_FILTER_DATA' , {filters: newFilters})
        dispatch('updateSettings')

        await dispatch('loadPublishers', {})
        await dispatch('loadSuggestions', {keep: true})
    },
    async clearFilterViewSelection({commit, dispatch, state, getters}, {}) {
        let newFilters = defaultFilters(getters.hasSuggestions.type);
        await commit('SET_FILTER_VIEW_ID', {filterViewId: null});
        await commit(mutations.SET_FILTER_VIEW_DATA.name, {filterView: newFilters})
        await commit('SET_FILTER_DATA' , {filters: newFilters})
        dispatch('updateSettings')

        await dispatch('loadPublishers', {})
        await dispatch('loadSuggestions', {keep: true})
    },
    deleteFilterView({commit, rootGetters}, {filterView}) {
        const filterViewURI = `${rootGetters.apiHost}/filter-views/${filterView.id}`
        commit(types.DELETE_FILTER_VIEW, {filterView, filterViewURI})
    }
};

let mutations = {
    SET_FILTER_VIEW_LIST(state, {filterViews}) {
        state.filterViews = filterViews;
    },
    SET_FILTER_VIEW_ID(state, {filterViewId}) {
        state.filterViewId = filterViewId;
    },
    SET_FILTER_VIEW_DATA(state, {filterView}) {
        state.filterView = filterView;
    },
    [types.DELETE_FILTER_VIEW](state, {filterView}){
        state.filterViews = state.filterViews.filter(filterView => filterView.id !== filterView.id);
    },
};
export default {
    state,
    getters,
    actions,
    mutations
}
