<template>
    <div>
        <form @submit.prevent.stop="save" class="mb-3 form-horizontal form">
            <div>
                <label class="label-calm">
                    I am a
                    <textarea
                        required
                        type="text"
                        class="agent-description form-control form-control-sm d-inline-block"
                        v-model="currentAgentPromptText"
                        placeholder="Enter agent description (e.g. 'Content Manager working for a content curation software, I mainly talk about how content curation is essential to content marketing and why mixing in high-quality third-party content is crucial to building authenticity.')"
                        style="height: 6.5em;"
                    />
                </label>
                <label class="label-calm">
                    And call that voice
                    <input required type="text" class="agent-name form-control form-control-sm d-inline-block" v-model="currentAgentName" placeholder="Enter agent name"/>
                </label>
                <div class="form-footer">
                    <button type="submit" class="btn btn-primary pull-right">Save</button>
                    <button type="button" class="btn btn-primary pull-right me-2" @click="clearInputs">Clear</button>
                    <button v-if="isShowDeleteButton" type="button" class="btn btn-danger pull-right me-2" @click="deleteAgent">Delete</button>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name:'PostTextAgentForm',
    props : {
        value: {},
        addingNewAgent: false,
    },
    data() {
        return {
            currentAgentPromptText: this.value && this.value.agent_prompt_text || '',
            savedAgentPromptText: this.value && this.value.agent_prompt_text || '',
            currentAgentName: this.value && this.value.name || '',
            savedAgentName: this.value && this.value.name || '',
            valueChanging: false
        }},
    watch : {
        value() {
            this.valueChanging = true;
            this.currentAgentPromptText = this.value && this.value.agent_prompt_text || '';
            this.currentAgentName = this.value && this.value.name || '';
            this.valueChanging = false;
        },
        currentAgentName() {
            if (!this.valueChanging)
                this.$emit('fieldChanged', {name: this.currentAgentName})
        },
        currentAgentDescription() {
            if (!this.valueChanging)
                this.$emit('fieldChanged', {agent_prompt_text: this.currentAgentPromptText})
        }
    },
    computed : {
        ...mapGetters({
            // currentAccount: 'currentAccount',
            currentAccountOverview: 'currentAccountOverview',
        }),
        isShowDeleteButton() {
            return !this.addingNewAgent && this.value.id;
        }
    },
    methods: {
        async deleteAgent() {
            if (this.value && this.value.id) {
                await this.$api.delete(`/agents/${this.value.id}`)
                this.$emit('deleted');
            }
        },
        clearInputs() {
            this.currentAgentPromptText = '';
            this.currentAgentName = '';
        },
        handleAgentDescriptionInput() {
            // check this.currentAgentDescription for logic around a vs an
        },
        async save(){
            if (this.value && this.value.id) {
                if (this.currentAgentPromptText && this.currentAgentName) {
                    await this.$api.put(`/agents/${this.value.id}`, {
                        account_id: this.currentAccountOverview.id,
                        name: this.currentAgentName,
                        agent_prompt_text: this.currentAgentPromptText
                    });
                    this.$emit('saved');
                }
            } else {
                if (this.currentAgentPromptText && this.currentAgentName) {
                    await this.$api.post(`/accounts/${this.currentAccountOverview.id}/agents`, {
                        account_id: this.currentAccountOverview.id,
                        name: this.currentAgentName,
                        agent_prompt_text: this.currentAgentPromptText
                    });
                    this.$emit('saved');
                }
            }
        }

    },
    mounted() {
    }
}
</script>

<style lang="scss">
label textarea {
    display: inline-block;
}

input.agent-description {
    margin: 0.3rem
}

</style>
