// let windowReturnFocusIndex = 0;

export function windowReturnFocus(action = () => {}) {
    return new Promise((resolve) => {
        // if (window.localStorage)
        //     window.localStorage.setItem('windowReturnFocus', new Date().toISOString());
        let handleAfterNewWindowClose = () => {
            if (!document.hidden) {
                document.removeEventListener("visibilitychange", handleAfterNewWindowClose);
                resolve()
            }
        };
        document.addEventListener("visibilitychange", handleAfterNewWindowClose);
        action();
    })
}



export function openNewAndAwaitReturn(url) {
    window.open(url, '_blank');
    return windowReturnFocus();
}
