<template>
    <nestable-modal ref="modal" modal-name="editCollectionPowerupsModal" size="md-lg" @shown="shown" @closed="closed">
        <template v-slot:header>
            <h5>
                Manage Powerups
            </h5>
        </template>
        <div class="power-ups-panel-scrolling">
            <power-ups-panel :collection="currentCollection" ref="powerUpsPanel" @saved="$refs.modal.hide()" />
        </div>
        <template v-if="networkActivity" v-slot:footer>
            <progress-bar style="height: 30px; width: 100%;"/>
        </template>
        <template v-slot:footer v-else>
            <ul class="errors" v-if="$refs.powerUpsPanel && $refs.powerUpsPanel.errorMessages &&
            $refs.powerUpsPanel.errorMessages.length">
                <li v-for="error in $refs.powerUpsPanel.errorMessages" class="error" :key="error">{{ error }}</li>
            </ul>
            <div class="buttons">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary ms-2"
                        v-if="currentCollection && currentCollection.id"
                        @click="$refs.powerUpsPanel.saveCollection()">
                    Save Collection
                </button>
            </div>
        </template>
    </nestable-modal>
</template>

<script>
import NestableModal from "@/components/Controls/NestableModal";
import {mapActions, mapGetters} from "vuex";
import PowerUpsPanel from "@/components/Collections/V2/PowerUpsPanel";

export default {
    name: "EditCollectionPowerupsModal",
    components: {PowerUpsPanel, NestableModal},
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection'
        })
    },
    data() {
        return {
            collection: {},
            networkError: false,
            networkActivity: false,
        }
    },
    methods: {
        ...mapActions([
            'loadCollectionPowerups',
        ]),
        loadSources() {
            console.log('loadSources()', 'do something');
        },
        shown() {
            this.$refs.powerUpsPanel.loadPowerUps();
            console.log('shown()', this.onlyType);
        },
        async closed() {
            try {
                await this.loadCollectionPowerups()
            } catch (e) {
                console.error('loadCollectionPowerups()', e);
            }
        }
    },
    mounted() {
        window.Events.$on('show_editCollectionPowerupsModal', data => {
            console.log('show_editCollectionPowerupsModal', data)
        });
    },
}
</script>

<style scoped>
</style>

