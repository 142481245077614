import * as types from '../mutation-types'
import * as context from '@/services/Context'
import {appByUpcontent} from "@/services/Context";
import {hasValue} from "../../utilities/utils";

const state = {
    showImagesInListView : true,
    vendor : context.vendor(),
    subvendor: context.subvendor(),
    loginMessage : '',
    isEmbed: context.isEmbed(),
    suggestionViewerType : 'topics'
}

const getters = {
    showImagesInListView: state => state.showImagesInListView,
    vendor : state => state.vendor,
    subvendor: state => state.subvendor,
    loginMessage : state => state.loginMessage,
    suggestionViewerType : state => state.suggestionViewerType,
    isUpContent: state => context.isUpContent(state.vendor),
    isDealerVenom: state => context.isDealerVenom(state.vendor),
    isHearsay: state => context.isHearsay(state.vendor),
    isEveryoneSocial: state => context.isEveryoneSocial(state.vendor),
    isSocialAdvisors: state => context.isSocialAdvisors(state.vendor),
    isHootsuite: () => context.isHootsuite(state.vendor),
    vendorEmbedShareProvider: state => context.vendorEmbedShareProvider(state.vendor),
    bannedIntegrationsForVendor: state => context.bannedIntegrationsForVendor(state.vendor),
    appByUpcontent: state => appByUpcontent(state.vendor),
    isAmplify: () => state.subvendor === 'amplify',
    isLocal: () => context.isLocal(),
    isHttps: () => context.isHttps(),
    isQa: () => context.isQa(),
    topLevelDomain: () => context.topLevelDomain(),
    isWhiteLabel: state => context.isDealerVenom(state.vendor),
    isEmbed: state => state.isEmbed,
    whitelabelConfig: state => context.whitelabelConfig(state.vendor),
    title: (s, g) =>
        g.whitelabelConfig && g.whitelabelConfig.title ? g.whitelabelConfig.title :
        g.appByUpcontent ? `${g.appByUpcontent} by UpContent` :
        'UpContent',
    appName: (s, g) => g.title,
    legalEntityShort: (s, g) => g.whitelabelConfig && g.whitelabelConfig.legalEntityShort || 'UpContent',
    legalEntityFull: (s, g) => g.whitelabelConfig && g.whitelabelConfig.legalEntityFull || 'UpContent, Inc',
    privacyPolicyUrl: (s, g) => g.whitelabelConfig && g.whitelabelConfig.privacyPolicyUrl || 'https://www.upcontent.com/privacy-policy',
    termsOfServiceUrl: (s, g) => g.whitelabelConfig && g.whitelabelConfig.termsOfUseUrl || 'https://www.upcontent.com/terms-of-service',
    marketingSiteUrl: wlConfig(c => c.marketingSiteUrl, 'https://upcontent.com'),
    infoEmail: wlConfig(c => c.infoEmail, 'info@upcontent.com'),
    upcontentEnvDomain: (s, g) => g.isQa ? 'qa.upcontent.com' : 'upcontent.com',
    feedsDomain: (s, g) =>
        g.isProd && g.whitelabelConfig && g.whitelabelConfig.feedsDomain || `feeds.${g.upcontentEnvDomain}`,
    hideSubscription: wlConfig(g => g.hideSubscription, false),
    hideSupportApps: wlConfig(g => g.hideSupportApps, false),
    hideShare: wlConfig(g => g.hideShare, false),
    hideZapier: wlConfig(g => g.hideZapier, false),
    hideIntegrations: wlConfig(g => g.hideIntegrations, false),
    hideGalleries: wlConfig(g => g.hideGalleries, false),
    hideContentTemplates: wlConfig(g => g.hideContentTemplates, false),
    hideFeedLink: wlConfig(g => g.hideFeedLink, false),
}

function wlConfig(getter, defaultVal) {
    let val = g => g.whitelabelConfig && getter(g.whitelabelConfig);
    return (s, g) => hasValue(val(g)) ? val(g) :defaultVal
}

const actions = {
    setSuggestionViewerType : async ({commit}, {type}) => {
        await commit('SET_SUGGESTION_VIEWER_TYPE', {type});
    },
    setVendor : ({commit}, {vendor}) => {
        commit("SET_VENDOR", {vendor});
    },
    resetVendor : ({commit}) => {
        commit("RESET_VENDOR");
    },
    resetSubvendor : ({commit}) => {
        commit("RESET_SUBVENDOR");
    },
    setSubvendor : ({commit}, {subvendor}) => {
        commit("SET_SUBVENDOR", {subvendor});
    },
    resetEmbed : ({commit}) => {
        commit("RESET_EMBED");
    },
    setEmbed : ({commit}, {isEmbed}) => {
        commit("SET_EMBED", {isEmbed});
    }
}

const mutations = {
    SET_SUGGESTION_VIEWER_TYPE : (state, {type}) => {
        state.suggestionViewerType = type;
    },
    [types.SET_SHOW_IMAGES_IN_LIST_VIEW](state, {boolean}){
        state.showImagesInListView = boolean
    },
    [types.SET_VENDOR](state, {vendor}){
        state.vendor = vendor
    },
    ['SET_SUBVENDOR'](state, {subvendor}){
        state.subvendor = subvendor
    },
    ['RESET_VENDOR'](state){
        state.vendor = context.vendor()
    },
    ['RESET_SUBVENDOR'](state){
        state.subvendor = context.subvendor()
    },
    ['SET_EMBED'](state, {isEmbed}){
        state.isEmbed = isEmbed;
    },
    ['RESET_EMBED'](state){
        state.isEmbed = context.isEmbed()
    },
    [types.SET_LOGIN_MESSAGE](state, {message}){
        state.loginMessage = message;
    },
    [types.USER_DID_LOG_IN](state){
        state.loginMessage = '';
    },
    [types.USER_DID_LOG_OUT](state) {
        state.loginMessage = '';
        state.suggestionViewerType = 'topics'
    }
}

export default {
    state,
    actions,
    getters,
    mutations
}
