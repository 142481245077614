<template>
    <div>
        <div>
            <h4 class="subtitle">Published Since</h4>
            <select class="form-select-sm form-select" @change="updateTime()" v-model="time">
                <option
                    @selected="customTime = ''"
                    v-for="thisType in availableTimeTypes" :key="'content-types-'+thisType"
                    :value="thisType"
                >
                    {{thisType}}
                </option>
            </select>
            <input
                class="form-control-sm form-control small mt-1 mb-1 p-1"
                @change="updateCustomTime()"
                v-model="customTime"
                @keyup="checkCustomTime"
                placeholder="e.g. 1 year ago, 4 months ago"
            />
            <p class="filter-item">
                <label @click.stop class="undated-filter">
                    <input type="checkbox" v-model="evergreen" @change="updateEvergreen" />&nbsp;
                    Undated Suggestions
                    <span class="text-success" v-if="evergreen">
                        <i class="fa fa-tree ms-1"></i>
                    </span>
                </label>
            </p>
        </div>
    </div>
</template>

<script>


import Sugar from 'sugar'
import moment from 'moment'
import {mapGetters} from 'vuex'

import Base from '../SuggestionFilters/FilterMixin'

export default {
    mixins : [Base],
    computed : {
        ...mapGetters(['suggestionViewerType', 'hasSuggestions']),
        availableTimeTypes(){
            var types =  [
                'Day',
                'Week',
                'Month',
                '2 Months'
            ];

            if (this.suggestionViewerType == 'collections'){
                types.unshift('Forever')
            }

            return types;
        },
        customDays() {
                var customTime = this.customTime
                if ( ! this.customTime.includes('ago')) customTime += ' ago';
                let date = Sugar.Date.create(customTime)
                let now = moment();
                let hours = now.diff(date, 'hours')
                return Math.round(hours / 24)

        },
        daysSelection() {
            let hours = 0;
            if (this.time == 'Day'){
                hours =  24;
            } else if (this.time == 'Week'){
                hours =  168
            } else if (this.time == 'Month'){
                hours =  730
            } else if (this.time == '2 Months'){
                hours =  730 * 2 - 20 //for some reason if we don't subtract some time we get 61 hours
            } else if (this.time == 'Forever'){
                hours = -24;
            }
            return Math.round(hours / 24)
        },
        age(){
            var days = 0;

            if (this.customTime) {
                days = this.customDays
            } else {
                days = this.daysSelection
            }

            return days;
        },
    },
    watch: {
        hasSuggestions(){
            this.setContextAppropriateAge()
        },
        // customTime(changed){
        //     if (changed){
        //         this.time = 'custom'
        //     }
        // },
        // time(changed){
        //     if (changed != 'custom'){
        //         this.customTime = '';
        //     }
        // },
        filters(){
            if ( this.filters){
                this.time = this.filters.time
                this.customTime = this.filters.customTime
                this.evergreen = this.filters.evergreen
            }
        }
    },
    data(){
        return {
            tempTime : '',
            time : '',
            customTime : '',
            useDays : true,
            evergreen : false
        }
    },
    mounted(){
        this.time = this.filters.time
        if (this.time !== 'custom'){
            this.customTime = this.filters.customTime
        } else {
            this.customTime = '';
        }

        this.evergreen = this.filters.evergreen

        this.setContextAppropriateAge();
    },
    methods : {
        checkCustomTime(){
            if (this.customTime == '') this.time = 'Day'
        },
        setContextAppropriateAge(){
            if (this.suggestionViewerType == 'topics' && this.time == 'Forever'){
                this.time = '2 Months'
            }
            if(this.suggestionViewerType == 'collections'){
                this.time = 'Forever';
            }
        },
        updateTime() {
            this.update({
                age: this.daysSelection,
                customTime: '',
                time: this.time
            })
        },
        updateCustomTime() {
            this.update({
                age: this.customDays,
                customTime: this.customTime,
                time: 'custom'
            })
        },
        updateEvergreen() {
            this.updateFilter('evergreen', this.evergreen);
        },
        update(data){
             if (isNaN(data.age)){
                alert('The time frame you entered is not supported');
                return;
            }

            var upperLimit = 8760;
            upperLimit = Math.round(upperLimit / 24)

            if (data.age > upperLimit){
                alert("You cannot search further than 1 year ago");
                return;
            }

            this.updateFilters(data);
        }
    }
}
</script>

<style>
    .undated-filter {
        min-width: 13.5em; /* Using em so that if font size changes this still keeps it from wrapping, there is probably a better prop but none of the one's I know are working */
    }
</style>
