<template>
    <ul class="no-bullets ps-0 list-group">
        <li v-for="check in checks" class="" :key="check.id">
            <label>
                <input type="checkbox" v-model="checksEnabled" :value="check.id">&nbsp;
                {{check.name}}
            </label>
        </li>
    </ul>
</template>

<style scoped>
</style>

<script>
import CollectionContentCampaign from "@/components/Collections/powerups/CollectionContentCampaign";
import {mapGetters} from 'vuex'
import CollectionQualityChecksBase from "./CollectionQualityChecksBase";
export default {
    components: {CollectionContentCampaign},
    mixins: [CollectionQualityChecksBase]
}
</script>
