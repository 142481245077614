<template>
    <div v-if="hasCustomImage"  @click="tryDownloadImage">
        <a :href="agreed ? imageDownloadUrl : 'javascript:void(0)'" :class="linkClass" @click.prevent.stop="tryDownloadImage"><slot></slot></a>
        <!--<OkCancel ref="dialog">-->
            <!--<div>-->
                <!--By copying this link, you agree that:-->
                <!--<ul>-->
                    <!--<li>Content is for digital use only and may not be used for printing.</li>-->
                    <!--<li>You may not use the content as a trademark for a business.</li>-->
                    <!--<li>You may not portray a person in a way that may be offensive, including: in connection with adult-oriented services or ads for dating services; in connection with the promotion of tobacco products; in connection with political endorsements; with pornographic, defamatory, unlawful, offensive or immoral content; and as suffering from, or being treated for, a physical or mental ailment.</li>-->
                    <!--<li>You may only use the content in campaigns and content created on {{appName}}, and not with other website or content services.</li>-->
                    <!--<li>Image is to be used only in conjunction with the article in which it is licensed. Any other use could result in a breach of the license or copyright and be subject to penalty.</li>-->
                <!--</ul>-->
            <!--</div>-->
        <!--</OkCancel>-->
        <!--<copy-paste :value="hasCustomImage" ref="clipboard"></copy-paste>-->
    </div>
</template>

<style>

</style>

<script>
    import OkCancel from "@/components/Controls/OkCancel";
    import CopyPaste from '@/components/Controls/CopyPaste';
    import * as toastr from "toastr";
    import {mapGetters} from "vuex";
    export default {
        components: {OkCancel, CopyPaste},
        props: {
            selection: Object,
            linkClass: String
        },
        data() {
            return {
                agreed: false
            }
        },
        computed: {
            ...mapGetters({
                appName: 'appName',
            }),
            hasCustomImage() {
                return this.selection && (this.selection.raw_image_url !== this.selection.image_url_from_publisher);
            },
            imageDownloadUrl() {
                return this.selection && this.selection.image_url;
            },
        },
        methods: {
            async tryDownloadImage() {
                if (this.agreed || confirm(
                    'By copying this link, you agree that:\n' +
                    '- Content is for digital use only and may not be used for printing.\n' +
                    '- You may not use the content as a trademark for a business.\n' +
                    '- You may not portray a person in a way that may be offensive, including: in connection with adult-oriented services or ads for dating services; in connection with the promotion of tobacco products; in connection with political endorsements; with pornographic, defamatory, unlawful, offensive or immoral content; and as suffering from, or being treated for, a physical or mental ailment.\n' +
                    `- You may only use the content in campaigns and content created on ${this.appName}, and not with other website or content services.\n` +
                    '- Image is to be used only in conjunction with the article in which it is licensed. Any other use could result in a breach of the license or copyright and be subject to penalty.')) {
                    this.agreed = true;
                    this.copy()
                }
            },
            copy() {
                this.$clipboard.copy(this.imageDownloadUrl);
                toastr.success('Copy to clipboard')
            }
        },
        watch: {
            selection() {
                this.agreed = false;
            }
        }
    }
</script>
