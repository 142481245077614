<script>

import {mapGetters} from 'vuex';

export default {
    computed : {
        ...mapGetters(['appVersion', 'needsLogout', 'authToken'])
    },
    watch  : {
        async appVersion(incoming, previous){
            if (previous && incoming.number > previous.number && incoming.requiresReload){
                await this.$store.dispatch('logoutUser');
                this.$store.dispatch('setLoginMessage', { message: "Your session has expired. Please log in again." });
                this.$router.push("/login");
            }
        },
        async needsLogout(){
            if (this.needsLogout && this.authToken){
                console.log('* AppVersion.vue needsLogout watcher')
                await this.$store.dispatch('logoutUser');
                this.$store.dispatch('setLoginMessage', { message: "Your session has expired. Please log in again." });
                this.$router.push("/login");
            }
        }
    },
    async mounted() {
        if (this.needsLogout && this.authToken){
            console.log('* AppVersion.vue needsLogout mounted')
            await this.$store.dispatch('logoutUser');
            this.$store.dispatch('setLoginMessage', { message: "Your session has expired. Please log in again." });
            this.$router.push("/login");
        }

        this.$store.dispatch('setAppVersion', { number: 2, requiresReload : true });
    },
    render(h){
        return h();
    }
}
</script>
