<template>
    <div>
        <constant-contact-campaign v-if="editCampaign" :collection="collection" :campaign="editCampaign"  @save="save" @cancel="editCampaign = null">

        </constant-contact-campaign>
        <constant-contact-email v-else-if="createEmailCampaign" :collection="collection" :campaign="createEmailCampaign" @cancel="createEmailCampaign = null">

        </constant-contact-email>
        <div v-else-if="loaded && collection">
            <ul class="no-bullets no-indent list-group mb-2">
                <li v-for="campaign in campaigns" :key="campaign.id" class="list-group list-group-item d-flex flex-row justify-content-between flex-wrap">
                    <div style="min-width: 12em; flex-grow: 100" class="text-start flex-grow">
                        <div>{{campaign.name}}</div>
                        <div>
                            <button class="btn btn-sm btn-link text-primary ms-0 ps-0" type="button" @click="edit(campaign)">
                                <i class="fa fa-edit"></i>
                                Edit
                            </button>
                            <button class="btn btn-sm btn-link text-danger" type="button" @click="deleteCampaign(campaign)">
                                <i class="fa fa-trash"></i>
                                Delete
                            </button>
                        </div>
                    </div>
                    <!--style="min-width: 17em; width: 17em;" -->
                    <div class="text-end flex-grow" style="flex-grow: 1">
                        <button class="btn btn-primary btn-sm full-width" type="button" @click="createEmail(campaign)">
                            <i class="fa fa-envelope"></i>
                            Create Email
                        </button>
                    </div>
                </li>
            </ul>
            <button class="btn btn-primary full-width" @click="newCampaign">Add Campaign</button>
        </div>
        <div v-else-if="!collection">
            Please save the collection before adding campaigns
        </div>
        <div v-else>
            <spinner></spinner>
        </div>
    </div>
</template>

<style>

</style>

<script>
    import ConstantContactCampaign from "@/components/Integrations/ConstantContact/ConstantContactCampaign";
    import ConstantContactEmail from "@/components/Integrations/ConstantContact/ConstantContactEmail";
    import {UpcCCCampaign} from './ConstantContactViewModels';
    import Spinner from "@/components/Controls/Spinner";
    import * as toastr from "toastr";

    // noinspection JSUnusedGlobalSymbols
    export default {
        components: {Spinner, ConstantContactEmail, ConstantContactCampaign},
        props: {
            collection: Object,
            needsReset: {},
            parentModal: Object
        },
        data() {
            return {
                campaigns: [],
                editCampaign: null,
                maxId: 0,
                loaded: false,
                createEmailCampaign: null
            }
        },
        watch: {
            collection () {
                this.loadCampaigns();
            },
            needsReset() {
                console.log('needsReset')
                this.reset();
            }
        },
        methods: {
            reset() {
                this.editCampaign = null;
                this.createEmailCampaign = null;
                this.loaded = false;
                this.loadCampaigns();
            },
            fetchCampaigns() {
                return this.$api.get(`/collections/${this.collection.id}/integrations/constant_contact/settings`)
            },
            loadCampaigns() {
                if (this.collection.id)
                    return this.fetchCampaigns().then(resp => {
                        let settings = resp.data && resp.data.settings || {campaigns:[], maxId:0};
                        this.maxId = settings.maxId || 0;
                        this.campaigns = settings.campaigns.map(UpcCCCampaign.fromApi)
                        this.loaded = true;
                        return resp.data;
                    });
                else
                    return Promise.resolve(null)
            },
            newCampaign() {
                this.editCampaign = new UpcCCCampaign();
            },
            edit(campaign) {
                this.editCampaign = campaign;
            },
            async save(campaign) {
                if (!campaign.id) {
                    campaign.id = ++this.maxId;
                }

                let campaigns = this.campaigns.filter(c => c.id !== campaign.id);
                campaigns.push(campaign);
                this.campaigns = campaigns;

                this.editCampaign = null;
                if (this.collection.id) {
                    await this.saveAll();
                    toastr.success('Saved')

                }
            },
            async deleteCampaign(campaign) {
                if (confirm("Are you sure you want to delete this campaign?")){
                    this.campaigns = this.campaigns.filter(c => c.id !== campaign.id);
                    if (this.collection.id) {
                        await this.saveAll()
                        toastr.success('Deleted')
                    }
                }
            },
            // showModal() {
            //     this.parentModal.hide();
            //     setTimeout(300, () => {this.$refs.modal.show()})
            // },
            createEmail(campaign) {
                this.createEmailCampaign = campaign;
            },
            saveAll() {
                return this.$api.post(`/collections/${this.collection.id}/integrations/constant_contact/settings`, {
                    on: true,
                    maxId: this.maxId,
                    campaigns: this.campaigns.map(x => x.toApi())
                })
            }
        },
        mounted() {
            this.loadCampaigns();
        }
    }

    </script>
