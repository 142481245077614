

<script>
    import {mapGetters} from 'vuex'
    import {featuresForAccount as _featuresForAccount} from '@/store/modules/accounts'
    export default {
        computed: {
            ...mapGetters({
                // account: 'account',
                apis: 'externalApis',
                subscriptions: 'subscriptions', features: 'features', currentAccountFeatureData: 'currentAccountFeatureData'
            }),
            authenticatedProofpoint() {
                return this.apis.proofpoint && this.apis.proofpoint[this.account.id]
            },
            canCreateProofpointQualityChecks() {
                return this.authenticatedProofpoint && this.featuresForAccount.proofpoint && this.featuresForAccount.qualityChecks
            },
            featuresForAccount() {
                return _featuresForAccount(this.subscriptions, this.features, this.currentAccountFeatureData)(this.account)
            }
        },
        methods: {
            async fetchChecks() {
                return await this.$api.get(`/accounts/${this.account.id}/quality-checks`).then(r => r.data);
            },
            async initializeChecks() {
                console.log('fetchChecksAndInitializeDoNotExist', this.canCreateProofpointQualityChecks)
                if (!this.canCreateProofpointQualityChecks) return false;
                await this.$api.post(`/accounts/${this.account.id}/quality-checks`, {
                    name: 'Proofpoint Default Policy Check',
                    default: true,
                    type: 'proofpoint',
                    meta: {
                        external_ids: {
                            success_flag: null,
                            flag_fail: null
                        }
                    }
                });
            },
            async fetchStatuses(check) {
                return await this.$api.get(`/quality-checks/${check.id}/statuses`).then(r => r.data||[])
            },
            async initializeStatuses(check) {
                return await this.$api.put(`/quality-checks/${check.id}/statuses/initialize`, {type: 'proofpoint'});
            },
            async upgradeCheck(c) {
                if (!c.meta)
                    c.meta = {};

                if (!c.meta.external_ids)
                    c.meta.external_ids = {
                        success_flag: null,
                        flag_fail: null
                    }
            },
            async fetchChecksAndInitializeDoNotExist() {
                console.log('fetchChecksAndInitializeDoNotExist')
                let checks = await this.fetchChecks();
                if (!checks || !checks.length) {
                    await this.initializeChecks();
                    checks = await this.fetchChecks();
                }
                await Promise.all(checks.map(async check => {
                    check.statuses = await this.fetchStatuses(check);
                    if (!check.statuses || !check.statuses.length) {
                        await this.initializeStatuses(check);
                        check.statuses = await this.fetchStatuses(check);
                    }
                }));
                checks.forEach(c => this.upgradeCheck(c));
                return checks;
            }
        }
    }
</script>
