<template>
    <div class="custom-plan-container">
        <h5><img src='/assets/images/subscriptions/enterprise.png' style="width: 2em;" class="me-2"/> Enterprise</h5>
        <small>for large teams and organizations</small>
        <div class="row">
            <div class="col-sm-6">
                <p>1) Fill out your desired numbers</p>
                <div class="d-flex numbers">
                    <div>
                        <input class="form-control input-sm" placholder="##" type="number" v-model="topicCount" />
                        Topics
                    </div>
                    <div>
                        <input class="form-control input-sm" placholder="##" type="number" v-model="collectionCount" />
                        Collections
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <p>2) Send a message to our team</p>
                <textarea class="form-control" placeholder="Write a message..." v-model="message"></textarea>
            </div>
        </div>
        
        <div class="d-flex">
            <button class="btn btn-primary ms-auto mt-3" :disabled=" ! isReady" @click="send()">Send Message</button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props : [],
    data(){
        return {
            userCount : 1,
            topicCount: 0,
            collectionCount: 0,
            message : ''
        }
    },
    computed : {
        ...mapGetters({
            appName: 'appName',
            infoEmail: 'infoEmail'
        }),
        isReady(){
            return this.userCount && this.topicCount && this.collectionCount && this.message
        },
        intercomAvailable(){
            return window.Intercom.name !== 'placeholder';
        }
    },
    methods : {
        send(){
            var message = `I'm interested in a custom plan with the following options: 
    Users: ${this.userCount}
    Topics: ${this.topicCount}
    Collections: ${this.collectionCount}
    Additional notes: ${this.message}`

            if (this.intercomAvailable){
                message += `
(Please remember to press send!)`

                this.$intercom.showNewMessage(message);                
            } else {
                let location = `mailto:${this.infoEmail}?subject=Custom+${encodeURIComponent(appName)}+Plan&body=` + encodeURIComponent(message)
                window.location = location
            }
        }
    }
}
</script>

<style scoped>
.custom-plan-container {
    border: 1px solid #888;
    margin: 5px auto;
    padding: 1.2rem;
    font-size: var(--font-size-13);
    max-width: 96%;
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2),
    0px 2px 6px 3px rgba(0,0,0,0.1);
}

.numbers > div {
    margin-right: 8px;
}

.numbers .form-control {
    padding: 4px;
    font-size: var(--font-size-13);
}

.numbers {
    color: var(--bs-secondary);
}
</style>
