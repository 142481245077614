<template>
    <nestable-modal ref="modal" size="size-filter-view" modal-name="filterViewEdit">
        <template v-slot:header>
            <h3>{{ filterViewId ? 'Edit' : 'Create' }} Filter View</h3>
        </template>
        <filter-view-edit v-model="filterViewId"
                          :hasContentType="hasContentType"
                          :hasContentId="hasContentId"
                          :currentUsage="currentUsage"
                          ref="filterViewEdit"
                          @saved="onSave"
                          :id="formId">

        </filter-view-edit>
        <template v-slot:footer>
            <div class="text-start flex-grow">
                <button class="btn btn-secondary btn-sm me-auto" type="button" @click="cancelEdit()"><i
                    class="fa fa-close me-1"/> Cancel
                </button>
                <div class="d-inline-block float-end">
                    <button class="btn btn-secondary btn-sm me-1 " type="submit" name="action" value="duplicate"
                            v-if="filterViewId" :form="formId"><i class="fa fa-copy me-1"/> Duplicate
                    </button>
                    <button class="btn btn-primary btn-sm" type="submit" name="action" value="save" :form="formId"><i
                        class="fa fa-save me-1"/> Save
                    </button>
                </div>
            </div>
        </template>
    </nestable-modal>
</template>

<script>
import NestableModal from "@/components/Controls/NestableModal";
import FilterViewEdit from "@/components/FilteringAndSorting/FilterViewEdit";

export default {
    name: "FilterViewModal",
    components: {FilterViewEdit, NestableModal},
    created() {
        this.formId = `${this.$instanceId()}Form`;
    },
    data() {
        return {
            filterViewId: null,
            hasContentType: null,
            hasContentId: null,
            currentUsage: null
        }
    },
    methods: {
        // async save() {
        //     console.log('filterViewEdit save')
        //     this.$refs.filterViewEdit.save();
        // },
        // async saveNew() {
        //     console.log('filterViewEdit save')
        //     this.$refs.filterViewEdit.saveNew();
        // },
        onSave(result) {
            window.Events.$emit('save_filterViewEdit', result)
            this.$refs.modal.hide();
            return result
        },
        async cancelEdit() {
            console.log('filterViewEdit cancel')
            window.Events.$emit('cancel_filterViewEdit')
            this.$refs.modal.hide();
        }
    },
    mounted() {
        window.Events.$on('show_filterViewEdit', data => {
            //console.log('show_filterViewEdit', data)
            this.filterViewId = data.value
            this.hasContentType = data.hasContentType
            this.hasContentId = data.hasContentId
            this.currentUsage = data.currentUsage || null
        });
    }
}
</script>

<style>
/* Tech Debt! Dec 1 2021 */
/*
    Fragmented class name.  This class name is passed into <nestable-modal> as a property
   as "size-filter-view".  It should be passed in as "modal-size-filter-view" so it
   can be searchable.
*/
.modal-size-filter-view {
    max-width: 650px !important;
}

</style>
