<script setup>  //FIXME: add ts
import {computed, onMounted, ref} from "vue";
import store from '@/store'
import FormGroupCard from "../../Controls/FormGroupCard";
import Spinner from "@/components/Controls/Spinner.vue";

const emits = defineEmits([
    'mandatoryPolicySelected',
]);

const isLoadingContentPoliciesList = ref(false);
const contentPoliciesList = ref([]);
const selectedMandatoryContentPolicy = ref('');

const currentCollection = computed(() => store.getters.currentCollection)

const loadContentPoliciesFromStore = () => store.dispatch('loadAccountContentPolicies');
const loadSavedCollectionContentPoliciesFromStore = (collectionId) => store.dispatch('loadCollectionContentPolicies', collectionId);

const noContentPolicyOption = {
    id: null,
    name: 'No Content Policy'
};
const loadContentPolicies = async() => {
    isLoadingContentPoliciesList.value = true;
    const existingContentPolicies = await loadContentPoliciesFromStore();
    contentPoliciesList.value = [...existingContentPolicies, noContentPolicyOption];
    const savedContentPolicies = await loadSavedCollectionContentPoliciesFromStore(currentCollection.value.id);
    const savedMandatoryPolicy = savedContentPolicies
        .find(p => p.enforcement_level === 'MANDATORY')
    if(savedMandatoryPolicy) {
        selectedMandatoryContentPolicy.value = contentPoliciesList.value.find(p => p.id === savedMandatoryPolicy?.policy_id);
    }
    isLoadingContentPoliciesList.value = false;
};

const mandatoryPolicySelected = (mandatoryPolicy, isSelected) => {
    emits('mandatoryPolicySelected', {...mandatoryPolicy})
};

onMounted(() => {
    loadContentPolicies();
});
</script>

<template>
    <form id="content-policy" class="full-width">
        <FormGroupCard else="!isLoadingContentPoliciesList" title="Content Policy">
            <div v-if="isLoadingContentPoliciesList">
                <Spinner/>
            </div>
            <div v-else>
                <label class="label-calm col full-width">

                    <select
                        v-model="selectedMandatoryContentPolicy"
                        class="form-control form-select"
                        @change="event => mandatoryPolicySelected(selectedMandatoryContentPolicy, event.target.value)"
                    >
                        <option
                            v-for="policy in contentPoliciesList"
                            :key="policy.id"
                            :value="policy"
                        >
                            {{ policy.name }}
                        </option>
                    </select>
                </label>
            </div>
        </FormGroupCard>
    </form>
</template>

<style scoped>

</style>
