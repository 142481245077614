<template>
    <button role="radio" type="button" @click="itemClicked"
            :class="{ btn:true, 'btn-primary': selected, 'btn-light': !selected, 'border-default': true, 'btn-sm': $parent.small, selected: selected }"
    >
        <slot />
    </button>
</template>

<style>

</style>

<script>
    export default {
        props: ['value'],
        data: () => ({ selected: false,  }),
        methods: {
            itemClicked() {
                this.$parent.itemClicked(this.value);
            }
        }
    }
</script>
