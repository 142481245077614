<template>

</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        collectionId: String,
    },
    computed: {
        ...mapGetters({
            accountFeatures: 'accountFeatures'
        })
    },
    data() {
        return {
            loadedData: {}
        }
    },
    methods: {
        async load() {
            if (this.collectionId) {
                let resp = await this.$api.get(`/collections/${this.collectionId}/data/${this.metadataKey}`)

                if (resp.data) {
                    Object.assign(this, resp.data)
                    this.loadedData = resp.data || {};
                }

                else if (this.defaultValues) {
                    Object.assign(this, this.defaultValues)
                    this.loadedData = {};
                }
                // this.loadedData = resp.data || {};

            } else if (this.defaultValues) {
                Object.assign(this, this.defaultValues)
                this.loadedData = {};
            }
        },
        getData() {
            let keys = Object.keys(this.defaultValues);
            return keys.reduce((result, key) => ({...result, [key]: this.$data[key]}), {})
        },
        async save() {
            let data = this.getData();
            let isSame = x => JSON.stringify(data) === JSON.stringify(x)
            let isSameAsSave = isSame(this.loadedData)
            let isSameAsDefaults = isSame(this.defaultValues);
            if (isSameAsSave && isSameAsDefaults) return;
            if (!isSameAsSave && isSameAsDefaults && !this.saveUnchangedDefaults) return;
            return await this.$api.put(`/collections/${this.collectionId}/data/${this.metadataKey}`, data)
        }
    }
}
</script>

<style scoped>

</style>
