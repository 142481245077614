import Vue from "vue";
import VueIntercom from "vue-intercom";
import { StripePlugin } from '@vue-stripe/vue-stripe';
import VueAnalytics from "vue-analytics";
import VueGtm from "vue-gtm";
import router from "@/router";

Vue.use(VueIntercom, {appId: process.env.VUE_APP_INTERCOM_APP_ID});

Vue.use(VueGtm, {
    debug: process.env.NODE_ENV != 'production', // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['Galleries', '/galleries']
})

if (window.Stripe)
    Vue.use(StripePlugin, {pk: process.env.VUE_APP_STRIPE_KEY});


Vue.use(VueAnalytics, {
    id: 'UA-54282328-4'
})

router.afterHooks = router.afterHooks.map(x => (...args) => {
    try {
        return x(...args)
    } catch (e) {
        console.warn(e)
    }
})
