<template>
    <div class="gal-carousel" role="presentation" :style="cardStyle">
        <div class="carousel-container" :style="cardContainerStyle">
            <div class="carousel">
                <h3 class="headline"  @click="elementSelected('headline', $event)" :style="headlineStyle">
                    <span class="headline-inner"  @click.stop="elementSelected('headline-inner', $event)" :style="headlineInnerStyle">Headline. {{index}} Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</span>
                </h3>
                <div class="img-container">
                    <div class="image" @click="elementSelected('image', $event)" :style="imageStyle">
                        <div class="img-text">Image</div>
                    </div>
                </div>
                <div class="source" :style="sourceStyle" @click="elementSelected('source', $event)">Publisher</div>
            </div>
            <div class="content">
                <h3 class="title"  @click="elementSelected('title', $event)" :style="titleStyle">Title. {{index}} Lorem ipsum dolor sit amet.</h3>
                <div class="count" @click="elementSelected('count', $event)" :style="countStyle">
                    50 Articles
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .carousel-container {
        position: relative;
    }
    .carousel {
        position: relative;
    }
    .gal-card {
        /*border: 1px solid black;*/
    }
    .img-text {
        position: absolute;
        top: 30%; left: 0; bottom: 0; right: 0;
        padding: 30px auto;
        text-align: center;
    }
    .img-container {
        width: 100%;
    }
    .image {
        width: 100%;
        padding-bottom: 75%;
        background: gold;
        font-size: 30px;
        display: block;
        background: gray;
        color: lightgray;
        text-align: center;
        position: relative;
    }
    .content {
        display: block;
        width: 100%;
        overflow: hidden;
    }
    .headline, .title  { text-overflow: ellipsis;}
    .headline {
        z-index: 1;
        border-bottom: none;
        font-size: 1.2em;
        position: absolute;
        left: 0px;
        top: 0px;
        display: inline;
        overflow: hidden;
    }
    .headline-inner {
        display: block;
        background: white;
        line-height: 1.5em;
    }
    .source {
        position: absolute;
        right: 0;
        background: lightblue;
        bottom: -.75em;
        padding: .25em 1em;
    }

    /*.source { float: right; }*/
    .headline:hover, .count:hover, .title:hover, .source:hover, .image:hover, .selected {
        /*border: 1px solid mediumpurple;*/
        box-shadow: mediumpurple 0 0 10px;
    }
</style>

<script>
    export default {
        props: ['index', 'elementStyles'],
        computed: {
            headlineInnerStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='headline-inner')||{}).styles },
            titleStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='title')||{}).styles },
            headlineStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='headline')||{}).styles },
            countStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='count')||{}).styles},
            sourceStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='source')||{}).styles},
            imageStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='image')||{}).styles},
            cardStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='cardStyle')||{}).styles},
            cardContainerStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='cardContainerStyle')||{}).styles},
        },
        methods: {
            elementSelected(el) {
                this.$emit('selectElement', el);
                Array.from(document.querySelectorAll('.gal-carousel .selected'))
                    .forEach(e => e.classList.toggle('selected', false));

                Array.from(document.querySelectorAll('.'+el))
                    .forEach(e => e.classList.toggle('selected', true));
            }

        },
    }
</script>
