<template>
    <div class="ps-4 pe-4">
        <div class="card">
            <div class="card-body text-center">
                <canvas ref="codeContainer"></canvas>
            </div>
        </div>
    </div>
</template>

<script>

import QRCode from 'qrcode';
import {mapGetters} from 'vuex';

export default {
    computed : {
        ...mapGetters(['authToken', 'currentUser'])
    },
    mounted(){
        let canvas = this.$refs.codeContainer;
        let data = JSON.stringify({token : this.authToken, user: this.currentUser})

        QRCode.toCanvas(canvas, data);
    },
}
</script>

<style scoped>
canvas {
    max-width: 300px;
    max-height: 300px;
}
</style>
