<template>
    <ul role="tablist" class="tab-bar">
        <button role="tab" :aria-selected="tab.active" :aria-controls="tab.id"
            v-for="tab in tabs" :key="tab.label" @click="tabClick(tab)"
            :class="{tab:true, active: tab.active, hide: !tab.visible}"
        >
            <div class="tab-bar-tab-content">
                <span class="tab-icon-container">
                    <i :class="tab.iconClass"></i>
                </span>
                <span class="tab-label">
                    {{tab.label}}
                    <span v-if="tab.tag" class="tab-tag">{{tab.tag}}</span>
                </span>
            </div>
        </button>
    </ul>
</template>

<style scoped>
    /*ul, ul > li {*/
        /*border-style: solid;*/
        /*border-color: var(--bs-default);*/
    /*}*/
    /*ul      { border-width: 1px  0   0  1px }*/
    /*ul > li { border-width:  0  1px 1px  0  }*/
    .tab.hide {
        display: none;
    }
    .tab-bar {
        background: var(--bs-light);
        border: 1px solid var(--bs-default)
    }
    .tab-bar, .tab { list-style: none; }
    .tab-bar {
        padding-left: 0px;
        display: flex;
        width: 100%;
    }
    .tab {
        display: block;
        padding: 7px 10px 3px 10px;
        text-align: center;
        min-width: 30px;
        max-width: 100%;
        flex-grow: 1;
        cursor: pointer;
        background: none;
        border: none;
    }

    .tab-tag {
        vertical-align: super;
        font-size: .15em;
        text-transform: uppercase;
        color: var(--bs-blue);
        max-width: 0;
        display: inline-block;
        overflow-x: visible;
    }

    .tab.active {
        border-bottom: 5px solid var(--bs-primary);
    }
    .tab-label {
        display: block;
        text-align: center;
        font-size: .75em;
    }
    .tab-icon-container {
        text-align: center;
        font-size: 1.2em;
        width:20px;
        max-height:20px;
        display: inline-block;
        margin-right: 2px;
    }
    .tab-bar-tab-content {
        text-align: center;
        display: block;
    }
    @media (min-width: 700px) {
        .tab-label {
            display: inline-block;
            text-align: left;
            font-size: 1.1em;
            margin-left: 3px;
        }
        .tab-icon-container {
            text-align: center;
            font-size: 1.1em;
        }
        .tab {
            text-align: left;
            flex-grow: 1;
            padding: 8px 14px 4px 14px;
        }
        .tab-tag {
            max-width: 1em;
            font-size: .4em;
        }
        /*.tab.active {*/
            /*margin-top:-2px;*/
            /*border-top: 5px solid var(--bs-primary);*/
            /*border-bottom: 1px solid  var(--bs-default);*/
        /*}*/
        /*.tab-bar {*/
            /*margin-top: 5px;*/
        /*}*/
    }
</style>

<script>
    export default {
        props: {
            tabs: Array // { label, iconClass, active }
        },
        methods: {
            tabClick(item) {
                this.$emit('tabChange', item)
            }
        }
    }
</script>
