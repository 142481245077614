<template xmlns="http://www.w3.org/1999/html">
    <nestable-modal class="folder-assignments-modal"
                    ref="modal"
                    modal-name="folderAssignmentsModal"
                    size="lg">
        <h5 slot="header">Edit {{ currentFolder && currentFolder.name }} Folder Assignments</h5>
        <div v-if="canEditAssignments">
            <div class="mb-3 mt-2" v-if="availableUsers.length && !loadingCurrentFolderAssignments">
                <div class="d-flex">
                    <div class="flex-grow select-user-container pe-2">
                        <select class="form-select" v-model="selectedUserId">
                            <option disabled value="">Select a user</option>
                            <option :value="user.id"
                                    v-for="user in availableUsers"
                                    :key="user.id">
                                {{ toCapitalized(user.name) }}
                            </option>
                        </select>
                    </div>
                    <div class="role-column pe-2">
                        <select class="form-select" v-model="selectedUserRole">
                            <option value="">Select a role</option>
                            <option :value="role"
                                    v-for="role in availableAssignmentRoles"
                                    :key="'role-in-assignments-table-'+role">
                                {{ toCapitalized(role) }}
                            </option>
                        </select>
                    </div>
                    <div class="actions-column">
                        <button
                            @click="addAssignment"
                            class="ms-md-auto btn btn-primary full-width"
                            :disabled=" ! readyToAssign"
                        >
                            Assign User
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="availableUsers.length === 0 && !loadingCurrentFolderAssignments">
                <h6 class="small"><em>All users on this account are assigned to this folder or parent folders</em></h6>
            </div>
        </div>
        <div v-else>
            <h5> Folder Assignments </h5>
        </div>
        <progress-bar v-if="loadingCurrentFolderAssignments" />
        <table class="table table-striped table-condensed mb-3" v-if="assignments.length">
            <thead>
            <tr>
                <th>User</th>
                <th class="role-column">Role</th>
                <th v-if="canEditAssignments" class="actions-column">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="assignment in assignments" :key="assignment.id+'-assignment'">
                <td>
                    {{ assignment.user.name }}
                </td>
                <td class="role-column">
                    {{ toCapitalized(assignment.role) }}
                </td>
                <td v-if="canEditAssignments" class="actions-column">
                    <button class="btn btn-sm btn-danger" @click="removeAssignment(assignment)"><i
                        class="fa fa-times"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
        <section class="pt-5" v-if="parentAssignments && parentAssignments.length">
            <h5 class="text-muted">Parent Folder Assignments</h5>
            <table class="table table-striped table-condensed">
                <thead>
                <tr>
                    <th class="text-muted">User</th>
                    <th class="role-column text-muted">Role</th>
                    <th class="text-muted actions-column">Folder</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="assignment in parentAssignments" :key="assignment.id+'-assignment'">
                    <td class="text-muted">
                        {{ assignment.user.name }}
                    </td>
                    <td class="role-column text-muted">
                        {{ toCapitalized(assignment.role) }}
                    </td>
                    <td class="actions-column text-muted">
                        {{assignment.folder && assignment.folder.name}}
                    </td>
                </tr>
                </tbody>
            </table>

        </section>
        <div slot="footer" class="full-width">
            <button class="btn btn-primary float-start" type="button" @click="$refs.modal.hide()">OK</button>
        </div>
    </nestable-modal>
</template>

<script>
import NestableModal from "../Controls/NestableModal.vue";
import capitalize from "capitalize";
import ProgressBar from "@/utilities/ProgressBar.vue";
import {mapGetters} from "vuex";


export default {
    name: "FolderAssignmentsModal",
    components: {ProgressBar, NestableModal },
    data () {
        return {
            folderData: null,
            folderUserIdsData: [],
            accountUsersData: [],
            selectedUserId: '',
            selectedUserRole: '',
            newAssignment: null,
            currentAssignments: [],
            accountFolderList: [],
            loadingCurrentFolderAssignments: false,
        }
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
        }),
        currentFolder() { return this.accountFolderList.find(folder => (this.folderData && this.folderData.id) === folder.id)},
        currentTeam() { return this.currentAccountOverview},
        availableUsers() {
            const availableUsers = this.accountUsersData.filter(u => !this.isAssigned(u) && !this.isOwner(u));
            return this.$sort.alphabetize(availableUsers, 'name');
        },
        ownerId() { return this.folderData.owner && this.folderData.owner.slice(-36); },
        availableAssignmentRoles() {
            let roles = this.$accessControl.rolesForRole(this.currentTeam.role);
            if (this.selectedUserRole === 'reviewer') {
                roles = roles.filter(role => role === 'reviewer');
            }
            return roles
        },
        readyToAssign() {
            return this.selectedUserId !== '' && this.selectedUserRole !== '';
        },
        canEditAssignments() {
            return this.$accessControl.can(this.currentTeam.role, 'deleteFolderAssignments');
        },
        assignments() {
            return this.currentAssignments
                .filter(assignment => assignment.folder.includes(this.folderData && this.folderData.id))
                .map(assignment => {
                    let user = this.accountUsersData.find(u => assignment.assignee.includes(u.id))
                    return {
                        id: assignment.id,
                        user,
                        role: assignment.role
                    };
                });
        },
        parentAssignments() {
            return this.currentAssignments
                .filter(assignment => !assignment.folder.includes(this.folderData && this.folderData.id))
                .map(assignment => {
                    let user = this.accountUsersData.find(u => assignment.assignee.includes(u.id))
                    let folder = this.accountFolderList.find(u => assignment.folder.includes(u.id))
                    return {
                        id: assignment.id,
                        user, folder,
                        role: assignment.role
                    };
                });
        },
    },
    methods: {
        isAssigned(user) {
            return this.folderUserIdsData.includes(user.id)
        },
        isOwner(user) {
            return this.ownerId === user.id;
        },
        addAssignment() {
            const fetch = this.$api.post(`/accounts/${this.currentTeam.id}/assignments`, {
                assignee: this.selectedUserId,
                folder: this.folderData.id,
                role: this.selectedUserRole
            });
            this.selectedUserId = '';
            this.selectedUserRole = '';
            fetch.then(response => {
                this.newAssignment = response.data;
                this.getAssigneeUserIdsForCurrentFolder(this.folderData.id)
            }).catch(e => {
                console.error(`addAssignment failed to fetch: ${e}`)
            })
        },
        removeAssignment(assignment) {
            this.$api.delete( `/assignments/${assignment.id}`).then(() => {
                this.getAssigneeUserIdsForCurrentFolder(this.folderData.id)
            });
            this.$emit('changed', {assignment});
        },
        toCapitalized(string) {
            return typeof string === 'string' ? capitalize(string) : '';
        },
        async getDataFromAssignmentsUrl(urlArray)  {
            const arrayOfPromises = urlArray.map(url => this.$api.get(url));
            let userIdsArray = [];
            let assignments = [];
            await Promise.all(arrayOfPromises).then(responses => {
                return responses.map(response => {
                    assignments.push(response.data);
                    // only want the last 36 characters of the assignee string since it is the user id
                    userIdsArray.push(response.data.assignee.slice(-36));
                });
            }).catch(e => {
                console.error(`getDataFromAssignmentsUrl failed to fetch: ${e}`)
            });
            this.currentAssignments = assignments;
            this.folderUserIdsData = userIdsArray;
        },
        getAssigneeUserIdsForCurrentFolder(folderId) {
            return this.$api.get(`/folders/${folderId}`).then(async response => {
                this.folderData = response.data;
                await this.getDataFromAssignmentsUrl(response.data.assignments)
                // this.$store.dispatch('')
            }).catch(e => {
                console.error(`getFolderData failed to fetch: ${e}`)
            })
        },
        getAccountData(accountId) {
            return this.$api.get(`/accounts/${accountId}/overview`).then(response => {
                this.accountUsersData = response.data.users;
                this.accountFolderList = response.data.folders;
                this.loadingCurrentFolderAssignments = false;
            }).catch(e => {
                console.error(`getAccountData failed to fetch: ${e}`)
            })
        },
    },
    mounted() {
        window.Events.$on('show_folderAssignmentsModal', data =>{
            this.loadingCurrentFolderAssignments = true;
            this.getAssigneeUserIdsForCurrentFolder(data.folderId);
            this.getAccountData(data.account.id);
        });
    }
}
</script>

<style scoped>

.role-column {
    width: 13rem;
}

.actions-column {
    width: 7rem;
}

</style>
