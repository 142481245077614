<template>
    <div class="folder" :class="{'open' : showChildren}">
        <div class="d-flex align-items-center folder-header" >
            <button  class="btn btn-link folder-name mb-0 me-auto" @click="showChildren = !showChildren">
                    <i class="fa fa-folder text-primary folder-icon me-1" ></i>
                    <span class="me-2">
                        <span v-if="!isRoot">{{folder.name}}</span>
                        <span v-else>Active</span>
                    </span>
                    <small class="d-none d-sm-block d-lg-inline">Collections ({{myCollections.length}}) Topics ({{myTopics.length}}) Folders ({{children.length}})</small>

            </button>


            <span class="d-flex align-items-center text-center" style="line-height: 15px">
                <span class="add-folder" style="min-width:90px;">
                    <a v-if="features.editAssignmentsModal && !isRootFolder" class="d-block d-sm-inline" :aria-label="'Change folder Assignments'" href="#" @click.prevent="editAssignments">
                        <i class="fa fa-lock"></i> Assignments
                    </a>

                    <a v-if="canAddFolders" class="d-block d-sm-inline ms-0 ms-sm-3" :aria-label="'Add folder to '+folder.name" href="#" @click.prevent="newFolder">
                        <i class="fa fa-plus"></i> Add folder
                    </a>

                    <a v-if="!isRoot  && ! isReviewer" class="d-xs-block d-sm-inline ms-0 ms-sm-3" :aria-label="'Delete '+folder.name" href="#" @click.prevent="deleteFolder(folder)">
                        <i class="fa fa-trash"></i> Delete folder
                    </a>
                </span>

                <a href="#" :aria-label="'Toggle open '+folder.name" v-if="!isRoot" class="c-pointer ms-3"  @click.prevent="showChildren = !showChildren">
                    <i class="fa fa-chevron-up" v-if="showChildren"></i>
                    <i class="fa fa-chevron-down" v-else></i>
                </a>
            </span>
        </div>


        <div class="children" :class="{'open' : showChildren || isMovingCollections}" v-if="showChildren || isMovingCollections">
            <button :aria-label="'Move selected collections to '+folder.name" @click="moveCollectionsHere" class="mt-2 btn btn-success btn-sm mb-2" v-if="isMovingCollections">
                <i class="fa fa-arrow-up"></i> Move collections here
            </button>
            <div class="empty-folder mt-2" v-if="myCollections.length == 0 && children.length == 0 && !isMovingCollections">This folder is empty</div>
            <div class="empty-folder mt-2" v-if="myTopics.length > 0">
                Contains <b>{{myTopicsString}}</b> {{$strings.plural('topic', myTopics.length)}}</div>
            <template v-if="!isMovingCollections">
            <div
                v-for="(collection) in myPaginatedCollections"
                :key="collection.id+'-collection-item'"
                class="collection-container d-flex"
                :class="{'selected' : selectedCollections.indexOf(collection.id) > -1}"
                @click="toggleCollection(collection)"
                :tabindex="0"
                :aria-label="'Collection: '+collection.name"
                @keyup.enter="toggleCollection(collection)"
            >
                <div>
                    <b class="mt-2" v-text="$strings.truncate(collection.name)"></b>
                    <p v-if="collection.description">
                        {{collection.description}}
                    </p>
                </div>
                <span class="collection-controls ms-auto" v-if="!isReviewer && ! collection.incomplete">
                    <a class="small" href="#" @click.prevent.stop="editCollection(collection)">
                        <i class="fa fa-arrow-left"></i> Go to
                    </a>
                    <a class="ms-2 small" href="#" @click.prevent.stop="deleteCollection(collection)">
                        <i class="fa fa-trash"></i> Delete
                    </a>
                </span>
            </div>
            </template>
            <paginator class="mb-2"
                       :stay-on-first-page="true"
                       :small="true"
                       :size="10"
                       :items="myCollections"
                       @update="myPaginatedCollections = $event" />


            <folder-display
                @collectionsSelected="childrenCollectionsSelected"
                v-for="folder in children"
                :key="folder.id+'-folder'"
                :folder="folder"
                :folders="folders"
                :isMovingCollections="isMovingCollections"
                @collectionAdded="addCollection"
                @collectionRemoved="removeCollection"
                @createFolderWithNameInFolder="createFolderWithNameInFolder"
                @deleteCollection="deleteCollection"
                @childOpened="showChildren = true;"
            />
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import Paginator from '@/utilities/Paginator'
import Roles from '@/utilities/Roles'
import features from "@/Features";
// import {limitRequests} from "@/utilities/requests";

export default {
    name : 'folder-display',
    mixins : [Roles],
    props : ['folder', 'isRoot', 'isMovingCollections'],
    components : {
        Paginator
    },
    computed : {
        features() {
            return features
        },
        accountCollections(){
            if (!this.currentAccountOverview) return [];
            return this.currentAccountOverview.collections.filter(t => t.account.includes(this.currentAccountOverview.id));
        },
        children(){
            let children = this.folders.filter(f => f.parents.find(p => p.includes(this.folder.id)));
            return this.$sort.alphabetize(children);
        },
        myCollections(){
            return this.$sort.alphabetize(this.currentAccountOverview.collections.filter(t => t.folder.includes(this.folder.id)))
        },
        items(){
            return this.children.concat(this.myCollections)
        },
        canAddFolders(){
            return this.subscriptionPlan;
        },
        myTopics(){
            return this.topics.filter(t => t.folder.includes(this.folder.id));
        },
        myTopicsString(){
            if(this.myTopics.length > 3){
                return this.myTopics.slice(0,3).map(t => t.name).join(", ") + " and " + (this.myTopics.length - 3) + " more"
            }

            return this.myTopics.slice(0,3).map(t => t.name).join(", ");
        },
        isRootFolder() {
            return this.folder.parents.length === 0;
        },
        ...mapGetters({
            folders : 'folders',
            currentAccountOverview: 'currentAccountOverview',
            currentCollection : 'currentCollection',
            topics : 'topics',
            subscriptionPlan : 'subscriptionPlan',
            features: 'accountFeatures',
        })
    },
    data(){
        return {
            showChildren : this.isRoot,
            selectedCollections : [],
            myPaginatedCollections : []
        }
    },
    watch : {
        myPaginatedCollections(){
            let incomplete = this.myPaginatedCollections.filter(t => t.incomplete);
            incomplete.forEach( t => {
                this.$api.limit.get(`/collections/${t.id}`)
                .then(async(response) => {
                    let collection = response.data;
                    await this.$store.dispatch('addCollection', {collection});

                })
                .catch( () => {})
            })
        }
    },
    mounted(){
        window.Events.$on('selectedCollectionsMovedToNewFolder', () => {
            this.selectedCollections = [];
        })
    },
    methods : {
        ...mapActions({
            setCurrentCollection: 'setCurrentCollection'
        }),
        childrenCollectionsSelected({collections}){
            this.selectedCollections = this.selectedCollections.concat(collections);
        },
        moveCollectionsHere(){
            window.Events.$emit('folderSelectedForCollections', {folder : this.folder});
            this.showChildren = true;
            this.$emit('childOpened')
        },
        async deleteFolder(){
            if (this.items.length){
                var confirmationMessage = "Are you sure? Everything in this folder will be deleted, and you won't be able to recover it.";
                if (this.myTopics.length){
                    confirmationMessage = "Are you sure? All collections and topics (including "+this.myTopicsString+" " + this.$strings.plural('topic', this.myTopics.length+" will be deleted");
                }
                let x = confirm(confirmationMessage);
                if (! x ) return;
            }

            let folder = this.folder;

            await this.$store.dispatch('deleteFolder', {folder});
            this.$api.delete('/accounts/'+this.currentAccountOverview.id+'/folders/'+folder.id).catch(async() => {
                alert("You are not allowed to delete this folder");
                await this.$store.dispatch('addFolder', {folder});
            })

        },
        addCollection(collection){
            this.$emit('collectionAdded', collection);
        },
        removeCollection(collection){
            this.$emit('collectionRemoved', collection);
        },
        toggleCollection(collection){
            let index = this.selectedCollections.indexOf(collection.id);
            if (index > -1){
                this.selectedCollections.splice(index, 1)
                this.removeCollection(collection)
            } else {
                this.selectedCollections.push(collection.id)
                this.addCollection(collection)
            }
        },
        createFolderWithNameInFolder({name, folder}){
            this.$emit('createFolderWithNameInFolder', {name, folder})
        },
        newFolder(){
            let x = prompt('Enter a name for the new folder');
            if (x){
                this.createFolderWithNameInFolder({name : x, folder : this.folder});
                this.showChildren = true;
            }
        },
        async editCollection(collection){
            await this.setCurrentCollection({collection});
            console.log('* CollectionFolderDisplay editCollection about to router.push(/)')
            await this.$router.push('/');
        },
        deleteCollection(collection){
            console.log('* CollectionFolderDisplay.vue deleteCollection', collection)
            this.$emit('deleteCollection', collection);
        },
        editAssignments() {
            window.Events.$emit('show_folderAssignmentsModal', {folderId: this.folder.id, account: this.currentAccountOverview})
        },
    }
}
</script>

<style scoped>

.folder {
    margin-bottom: 5px;
}

.folder-icon {
    opacity: 0.8;
}

.collection-container {
    padding: 4px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collection-container {
    display: flex;
}

.collection-container .collection-controls a {
    opacity: 0;
    font-size: var(--font-size-13);
    color: rgb(114, 112, 131);
}

.collection-container .collection-controls a:focus,
.collection-container:hover .collection-controls a,
.collection-container:focus .collection-controls a
{
    opacity: 1;
}

@media screen and (max-width: 575px) {
    .collection-container {
        display:block !important;
        border-bottom: 1px solid #aeaeae;
        margin-bottom: 1em;
    }
    .collection-container .collection-controls {
        display: block !important;
    }
    .collection-container .collection-controls a {
        opacity: 1
    }
}

.collection-container.selected,
#app .collection-container.selected a  {
    background: var(--primary-50);
    color: white;
}
.collection-container.selected:hover {
    background: var(--primary-50);
    color: rgb(255, 255, 255);
}

.collection-container:hover {
    background: rgba(150, 146, 157, 0.319);
}

.collection-container {
    background: white;
    padding: 5px 10px;
}

.collection-container:hover .collection-controls, .collection-container:focus .collection-controls {
    opacity: 1;
}

.selected .collection-controls a {
    color: white;
}

.collection-container label {
    margin-bottom: 0;
    cursor: pointer;
    flex: 1;
    margin-right: 5px;
}

.folder-header {
    background: linear-gradient(rgb(231, 231, 234) 40%, #F0F0F0 80% );
    border-bottom: 1px solid rgba(255,255,255,0.2);
    font-weight: 400;
    height: 40px;
    padding: 4px 10px;
}

.folder-name {
    font-size: var(--font-size-14);
    margin-bottom: 3px;
    margin-top: 3px;
    padding: 0;
    color: var(--steel-0);
    text-decoration: none;
    box-shadow: none!important;
}

.children {
    padding-left: 10px;
    /*max-height: 0;*/
    overflow-y: auto;
}

.children.open {
    /*max-height: 500px;*/
}

.empty-folder {
    color: #7b7b7b;
    font-style: italic;
    font-size: var(--font-size-13);
    padding: 10px;
}

.add-folder a {
    font-size: var(--font-size-13);
    color: #555;
}

</style>
