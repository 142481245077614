<script setup>  // FIXME: ts issues
import store from '@/store'
import {computed, onMounted, ref, watch} from "vue";
import SidebarMenuLayout from "@/components/Layouts/SidebarMenuLayout.vue";
import SearchableGroupableList from "@/components/Controls/SearchableGroupableList.vue";
import PolicyEditor from "@/components/Settings/Team/PolicyEditor.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    hasClassifierUpdates: Boolean
});

const emits = defineEmits([
    'resetHasClassifierUpdates'
]);

const currentPolicy = ref(null);
const policiesList = ref([]);
const isLoadingPoliciesList = ref(false);
const isAddingNewPolicy = ref(false);
const selectedSavedPolicy = ref(null);

const readyToRenderPolicyDetails = computed(() => {
    const isReady = isAddingNewPolicy.value === true || selectedSavedPolicy.value !== null;
    return currentPolicy !== null && isReady;
})

const loadPoliciesFromStore = () => store.dispatch('loadAccountContentPolicies');
const loadContentPolicyFromStore = (id) => store.dispatch('loadContentPolicy', id);

watch(() => selectedSavedPolicy.value, () => {
    if(selectedSavedPolicy.value !== null) isAddingNewPolicy.value = false;
}, {immediate: true});

const loadPolicies = async() => {
    isLoadingPoliciesList.value = true;
    policiesList.value = await loadPoliciesFromStore();
    isLoadingPoliciesList.value = false;
};

const search = (searchText, item) => {
    return !searchText || item.name.toLowerCase().includes(searchText);
};

const selectPolicyItem = async(policyItem) => {
    currentPolicy.value = policyItem;
    const selectedPolicyId = currentPolicy.value.id;
    selectedSavedPolicy.value = await loadContentPolicyFromStore(selectedPolicyId);
};

const add = () => {
    isAddingNewPolicy.value = true;
    selectedSavedPolicy.value = null;
    currentPolicy.value = {
        name: '',
        classifiers: []
    }
};

const handleUpdatePoliciesList = () => {
    isAddingNewPolicy.value = false;
    loadPolicies();
    currentPolicy.value = null;
};

const resetHasClassifierUpdates = () => {
    emits('resetHasClassifierUpdates');
};

onMounted(() => {
    loadPolicies();
});
</script>

<template>
    <div>
        <h4>
            Content Policies
        </h4>
        <SidebarMenuLayout>
            <template #sidebar>
                <div v-if="isLoadingPoliciesList">
                    <Spinner/>
                </div>
                <SearchableGroupableList
                    v-if="!isLoadingPoliciesList"
                    :items="policiesList"
                    @select="selectPolicyItem"
                    :search-matches="search"
                >
                    <template v-slot:default="item">
                        <div>{{item.name}}</div>
                    </template>
                </SearchableGroupableList>
                <div>
                    <button class="btn-primary btn full-width" type="button" @click="add">Add Policy</button>
                </div>
            </template>
            <template #main>
                <div>
                    <PolicyEditor
                        v-if="readyToRenderPolicyDetails"
                        @updatePoliciesList="handleUpdatePoliciesList"
                        :saved-policy="selectedSavedPolicy"
                        :is-adding-new-policy="isAddingNewPolicy"
                        :has-classifier-updates="hasClassifierUpdates"
                        @resetHasClassifierUpdates="resetHasClassifierUpdates"
                    />
                </div>
            </template>
        </SidebarMenuLayout>
    </div>
</template>

<style scoped>

</style>
