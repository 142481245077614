import _Modal from './Modal'
import _Spinner from './Spinner'
import _Accordion from './Accordion'
import _Dropdown from './Dropdown'
import _ColorPicker from './ColorPicker'
import _UcSelect from './UpcontentSelect';
import _ToggleSwitch from './ToggleSwitch'

export const Modal = _Modal;
export const Spinner = _Spinner;
export const Accordion = _Accordion;
export const Dropdown = _Dropdown;
export const ColorPicker = _ColorPicker;
export const UcSelect = _UcSelect;
export const ToggleSwitch = _ToggleSwitch;

import * as TabModule from './Tabs'
export const { Tab, Tabs } = TabModule;


import * as ToggleModule from './Toggle'
export const { Toggle, ToggleItem } = ToggleModule;

