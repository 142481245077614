import SuggestionTags from "@/components/DataManagement/SuggestionTags";
import axios from "axios";
import Vue from "vue";

const filterMap = {
    'contentType' : 'type',
    'age' : 'age',
    'sortBy' : 'sort',
    'evergreen' : 'undated',
    'required': 'required',
    'tags': 'tags',
    'minFullTextLength': 'min_full_text_length',
    'excludePaywalled': 'exclude_paywalled',
}

function filterQueryObject({filters, showFavorites, page, suggestionsPerPage}){
    var query = {}
    if (filters){
        for (var key in filterMap){
            let varName = filterMap[key]
            var value = filters[key]
            if (typeof value == 'string'){
                value = value.toLowerCase();
            }
            if (typeof value == 'boolean' && ! value){
                continue;
            }
            query[varName] = value
        }
    }
    if (filters.includeNewSources) {
        query.excludes = filters.excludedSources;
    } else if (filters.sources && filters.sources.length) {
        query.sources = filters.sources
    }
    if (showFavorites){
        query['favorited'] = true;
        query['sources'] = null;
    }

    query['offset'] = page * suggestionsPerPage;
    query['limit'] = suggestionsPerPage;

    return query;
}

export async function fetchSuggestionURls(hasSuggestions, {filters, showFavorites, page, suggestionsPerPage, cancelToken}){
    console.log('fetchSuggestionURls', {hasSuggestions, filters})
    let url = `/suggestions?${hasSuggestions.type}=${hasSuggestions.id}`;
    let filterQuery = filterQueryObject({filters, showFavorites, page, suggestionsPerPage})
    console.log('fetchSuggestionURls.url', {url, filterQuery})
    try {
        let response = await window.$app.api.limit.post(url, filterQuery, {cancelToken}, 10)
        console.log('fetchSuggestionURls.response', {response})

        return response.data
    }
    catch(e) {
        if (e.response){
            console.error(e.response.data)
        }
        throw e;
    }
}

