<template>
    <div>
        <amplify-collection-settings :value="value" :all-topics="allTopics" :topics-loaded="topicsLoaded"></amplify-collection-settings>
    </div>
</template>

<style>

</style>

<script>
    import AmplifyCollectionSettings from "@/components/Integrations/Amplify/AmplifyCollectionSettings.vue";
    import {AmplifyConnection} from "@/services/Amplify";
    export default {
        components: {AmplifyCollectionSettings},
        props: {
            value: Object,
        },
        data() {
            return {
                allTopics: [],
                topicsLoaded: false
            }
        },
        computed: {
            accountHasAuthorizedAmplify() {
                return this.account && this.amplifyApiSettings.token;
            },
            amplifyApiSettings() {
                return this.account && this.account.id && this.apis.amplify[this.account.id] ?
                    this.apis.amplify[this.account.id] : {};
            },
        },
        methods: {
            async loadAmplifyTopics() {
                if (!this.accountHasAuthorizedAmplify) return Promise.resolve();
                let response = await this.Amplify.fetchTopics(this.account);
                this.allTopics = response.data.data.map(x => x.name);
                this.amplifyTopicsLoaded = true;
            },
        },
        mounted() {
            this.loadAmplifyTopics()
        },
        created() {
            this.Amplify = new AmplifyConnection(this)
        }
    }
</script>
