<template>
    <ul class="card-tags no-bullets ps-0 ms-0 text-end" v-if="tags && tags.length">
        <suggestion-tag v-for="tag in tags" :key="tag.id" :tag="tag"></suggestion-tag>
    </ul>
</template>

<style>

</style>

<script>
    import SuggestionTag from './SuggestionTag'
    import {formatTag} from "@/services/entity-tags";

    export default {
        props: {
            page: Object,
        },
        components: {SuggestionTag},
        computed: {
            tags() {
                return this.page.tags.map(formatTag)
            }
        }
    }
</script>
