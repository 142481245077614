<script>

import Paginator from '@/utilities/Paginator'
import {mapGetters} from 'vuex'

export default {
    props : ['hasSuggestions', 'hasSuggestionsType', 'hasNotesType'],
    components : {
        Paginator
    },
    data(){
        return {
            pagedItems : [],
            selectedId : false
        }
    },
    watch : {
        hasSuggestions(){
            this.selectedId = false;
        },
        groups(){
            this.$emit('setItemsWithNotes', this.groups)
        }
    },
    mounted(){
        this.$emit('setItemsWithNotes', this.groups)
    },
    computed : {
        ...mapGetters({
            suggestions : 'suggestionsCache',
            notes : 'notes',
            authors : 'teamMembers',
            isDownloadingNotes: 'isDownloadingNotes',
        }),
        groups(){
            if ( ! this.selectedId){
                return this.items
            }

            return this.items.filter(suggestions => suggestions.id == this.selectedId)
        }
    }
}
</script>
