<template>
    <div class="component-root">
        <label class="me-2">
            Medium
            <input type="text" value="website_gallery" class="form-control form-control-sm"/>
            <label v-if="defaultFrom" class="small text-muted">
                <input type="checkbox"/>&nbsp;
                Use default from {{defaultFrom}}
            </label>
        </label>
        <label class="me-2">
            Source
            <input type="text" :value="defaultSource" class="form-control form-control-sm"/>
            <label v-if="defaultFrom" class="small text-muted">
                <input type="checkbox"/>&nbsp;
                Use default from {{defaultFrom}}
            </label>
        </label>
        <label class="me-2">
            Content
            <input type="text" :value="defaultContent" class="form-control form-control-sm"/>
            <label v-if="defaultFrom" class="small text-muted">
                <input type="checkbox"/>&nbsp;
                Use default from {{defaultFrom}}
            </label>
        </label>
        <label class="me-2">
            Campaign
            <input type="text" value="" class="form-control form-control-sm"/>
            <label v-if="defaultFrom" class="small text-muted" :disable="true">
                <input type="checkbox"  :checked="true"/>&nbsp;
                Use default from {{defaultFrom}}
            </label>
        </label>
    </div>
</template>

<style scoped>
    .component-root>label {
        width: 230px;
    }
</style>

<script>
    export default {
        name: "UtmEditor",
        props: {
            defaultFrom: {type: String, default: ""},
            defaultSource: {type: String, default: ""},
            defaultContent: {type: String, default: ""}
        }
    }
</script>
