<template>
    <div v-if="topic">
        <a class="ui-link p-0 m-0"
           title="Notes"
           :class="{'active' : showingNotes}"
           href="#"
           @click.prevent="toggleNotes"
           v-show="showContentWorkflow"
           aria-label="notes">
            <i class="fa fa-comments me-1"></i>
            <span v-if="accountFeatures.newCollectionUx">Notes</span>
        </a>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import RouteContext from '@/utilities/RouteContext'

export default {
    name: 'SuggestionManagerNotesLink',
    replace: false,
    mixins: [RouteContext],
    computed: {
        ...mapGetters({
            topic: 'currentTopic',
            showingNotes: 'showAccountNotesReader',
            accountFeatures: 'accountFeatures',
        }),
    },
    methods: {
        ...mapActions({
            toggleNotes: 'toggleAccountNotesReader'
        }),
    }
}
</script>
