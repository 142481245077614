import * as moment from 'moment'
import Roles from "@/utilities/Roles"
import {showSelectionEdit} from "@/events";
import {mapGettersTo} from '@/store/helpers';
import {mapActions} from "vuex";

const BaseCard = {
    props : ['optionKeyIsPressed', 'collections', 'collectionBatch'],
    mixins : [Roles],
    created() {
        mapGettersTo({
            accountFeatures: 'accountFeatures',
            allCollections: 'collections',
            currentCollection: 'currentCollection',
            features: 'features',
            hasSuggestions: 'hasSuggestions',
            preferences: 'preferences',
            user: 'currentUser',
            userIsUpcontent: 'userIsUpcontent'
        }, this)
    },
    data(){
        return {
            showCollections : false
        }
    },
    computed : {
        filters(){
            return this.preferences.filters;
        },
        canEdit() {
            return this.accountFeatures.customSelectionImageText
                && this.features.editSelection
                && this.containerType==='collection'
                && !this.isReviewerForHasSuggestions;
        },
        hasEditPrivileges() {
            return !this.isReviewerForHasSuggestions
        },
        isInCollectionBatch(){
            return this.collectionBatch.indexOf(this.card) > -1;
        },
        displayText() {
            var text = this.card.summary
            if (text.length > this.summaryLength) {
                text = text.substring(0, this.summaryLength) + "…"
            }
            return text
        },
        displayTitle() {
            var text = this.card.title;
            if (!text) return "No Title";
            if (text.length > this.titleLength && this.titleLength) {
                text = text.substring(0, this.titleLength) + "…"
            }
            return text
        },
        displayPublisher(){
            var text = this.card.publisher
            if (text && text.length > this.publisherLength && this.publisherLength) {
                text = text.substring(0, this.publisherLength) + "…"
            }
            return text
        },
        prettyDate() {

            if (this.card.date_published) {
                let published = moment.utc(this.card.date_published);
                let today = moment().subtract(1, "day");
                if (published.isSameOrAfter(today)) {
                    return "Less than a day ago"
                }
                return published.fromNow()
            }
        },
        isFavoritedByCurrentUser(){
            return this.favoritedIndex > -1;
        },
        favoritedBy() {
            return this.card.favorited_by || []
        },
        isFavorited(){
            return this.favoritedBy.length
        },
        favoritedIndex(){
            return this.favoritedBy.map(d => d.id).indexOf(this.user.id);
        },
        favoritedByText(){
            if (this.favoritedBy.length)
                return "Favorited by " + this.favoritedBy.map(u => u.id == this.user.id ? 'you' : u.name).join(", ");

            return "Toggle Favorite"
        },
        myCollections(){
            if ( ! this.card.contexts || ! this.card.contexts.collections ) return [];

            return this.card.contexts.collections.map(url => this.allCollections.find( c => url.includes(c.id) )).filter(c => c);
        },
        myCollectionList(){
            if (this.myCollections.length){
                var string = "In Collections: "
                string += this.myCollections.map(c => c.name).join(", ");
                return string;
            }
        },
        containerType(){
            return this.hasSuggestions && this.hasSuggestions.type;
        },
        noteCount() {
            return (this.card.notes && this.card.notes.length) || 0;
        }
    },
    methods : {
        ...mapActions({uncheckPublisher:'uncheckPublisher'}),
        toggleInCollectionBatch(){
            this.$emit('toggleCardInCollectionBatch', {card : this.card});
        },
        toggleFavorite() {
            this.$emit('toggleFavorite', {card : this.card})
        },
        loadArticleWithNotes(){
            this.$store.dispatch('setReadingSuggestion', { suggestion: this.card, scrollToNotes : true });
        },
        loadArticle(){
            this.$store.dispatch('setReadingSuggestion', {suggestion : this.card});
        },
        editSelection() {
            showSelectionEdit(this.card)
        },
        excludeSource() {
            if (confirm(`Exclude ${this.card.publisher} from the current view?`)) {
                this.uncheckPublisher({publisher: this.card.publisher})
                // this.$store.dispatch('excludeSource', {source : this.card.publisher, hasSuggestionsId: this.hasSuggestions.id});
                // let sources = this.filters.sources.filter(x => x !== this.card.publisher)
                // this.updateFilter('sources', sources);
            }
        },

        updateFilter(key, value){
            var filters = {...this.filters}
            filters[key] = value;
            this.$store.dispatch('setUserPreference', {key : "filters", value : filters});
        },
        hideSuggestion(){

            if (!this.optionKeyIsPressed) {
                let x = confirm("Are you sure you want to remove this suggestion? You'll never see it again.")
                if (!x) return;
            }

            if (this.containerType == 'topic'){
                this.$intercom.trackEvent('Delete suggestion', { suggestion: this.card.id });
                window.Intercom('update');
            }

            if (this.containerType == 'collection' && this.card && this.card.id){
                this.$api.get('/suggestions/'+this.card.id+'/contexts')
                .then(response => {
                    response.data.suggestions.forEach(url => {
                        let id = url.substr(url.lastIndexOf('/') + 1);
                        this.$store.dispatch('removeCollectionFromSuggestionContexts', { suggestion : {id}, collectionId : this.currentCollection.id});
                    })
                })
            }

            this.$store.dispatch('removeSuggestion', { suggestion: this.card });

            let endPoint = this.containerType == 'topic' ? 'suggestions' : 'selections';
            this.$api.delete(`/${endPoint}/` + this.card.id);


        },
        setClassification(changeEvent){
            let classification = changeEvent.target.value;

            var suggestion = JSON.parse(JSON.stringify(this.card));
            this.$api.put('/suggestions/' + this.card.id + '/type', { type: classification});
            suggestion.classification = classification;
            this.$store.dispatch('updateSuggestion', { suggestion });
        },
        featureArticle() {
            return this.$api.post('/suggestions/' + this.card.id + '/feature')
        }
    }
}

export default BaseCard
