import * as types from '../mutation-types'

const defaultFilters = () => {
    return {
        contentType: 'All',
        sortBy: 'Relevance',
        time: '2 Months',
        age: 60,
        customTime: '',
        geography: ['US'],
        sources: [],
        evergreen : false
    }
}

const state = {
    preferences : {
        filters : defaultFilters(),
        excludedSources : {},
        suggestionDisplayType: 'grid',
        languageCulture: false,
        deviceShare: localStorage.getItem('deviceShare')
    },
    lastHasSuggestions: {}
}

const getters = {
    preferences : state => state.preferences,
    suggestionDisplayType : state => state.preferences.suggestionDisplayType,
    languageCulture : state => state.preferences.languageCulture,
    deviceShare: state => state.preferences.deviceShare
}

const actions = {
    setDeviceShare : ({commit}, {value}) => {
        console.log('setDeviceShare', value)
        commit('SET_DEVICE_SHARE', {value})
        localStorage.setItem('deviceShare', value);
    }
}

const mutations = {
    SET_SUGGESTION_VIEWER_TYPE: (state, { type }) => {
        state.lastHasSuggestions.type = type;
    },
    [types.SET_CURRENT_TOPIC](state, { topic }) {
        if (topic)
            state.lastHasSuggestions.id = topic.id
    },
    SET_CURRENT_COLLECTION: (state, { collection }) => {
        if (collection)
            state.lastHasSuggestions.id = collection.id
    },
    [types.SET_USER_PREFERENCE](state, {key, value}){
        state.preferences[key] = value;
    },
    [types.REMOVE_USER_PREFERENCE](state, {key}){
        delete state.preferences[key];
    },
    [types.SET_USER_PREFERENCES](state, {preferences}){
        state.preferences = preferences;
        checkForDefaults(state);
    },
    [types.USER_DID_LOG_OUT](state){
        state.preferences.lastHasSuggestions = {}
    },

    SET_DEVICE_SHARE: (state, { value }) => {
        state.preferences.deviceShare = value;
    },
}

function checkForDefaults(state){
    if ( ! state.preferences.filters){
        state.preferences.filters = defaultFilters()
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
