
export function initializePendo({ userId, email, ...user }, { accountId, role, plan, ...account }) {
    function initialize() {
        window.pendo.initialize({
            visitor: {
                id: userId, // Pendo
                email, // Pendo
                usersName: user.name,
                role // Pendo

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id: accountId, // Pendo
                name: account.name, // Pendo
                planLevel: plan, // Pendo
                // planPrice:    // Optional
                creationDate: account.created // Pendo

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
    }
    if (window.pendo)
        initialize();
    else {
        var watcher = setInterval(() => {
            if (window.pendo) { clearInterval(watcher); initialize(); }
        },100)
    }

}
