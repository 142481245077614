<template>
    <div class="folder-nav">
        <toggle small="true" v-model="type" class="folder-nav--type-toggle full-width font-size-14">
            <toggle-item value="collections" class="folder-nav--type-toggle-item ">
                <collections-svg class="multiple light-variation icon-dynamic folder-nav--type-icon top-1"/>
                Collections
            </toggle-item>
            <toggle-item value="topics" class="folder-nav--type-toggle-item">
                <topics-svg class="multiple light-variation icon-dynamic folder-nav--type-icon top-1"/>
                Topics
            </toggle-item>
        </toggle>
        <div class="mb-2 sidebar-section ">
            <topic-sidebar-folder-list
                v-if="type === 'topics'"
            />
            <collections-sidebar-list
                v-if="type === 'collections'"
            />
        </div>
    </div>
</template>

<script>
import Toggle from "../../Controls/Toggle/Toggle";
import ToggleItem from "../../Controls/Toggle/ToggleItem";
import CollectionsSvg from "../../../assets/svg/collections";
import TopicsSvg from "../../../assets/svg/topics";
import TopicSidebarFolderList from '@/components/Topics/SidebarList'
import CollectionsSidebarList from "@/components/Collections/SidebarList"
import {mapGetters} from "vuex";

export default {
    name: "FolderNavigator",
    components: {
        TopicsSvg,
        CollectionsSvg,
        ToggleItem,
        Toggle,
        TopicSidebarFolderList,
        CollectionsSidebarList
    },
    data() {
        return {
            type: this.suggestionViewerType
        }
    },
    computed: {
        ...mapGetters({
            suggestionViewerType: 'suggestionViewerType',
        })
    },
    watch: {
        suggestionViewerType() {
            this.type = this.suggestionViewerType
        }
    },
    mounted() {
        this.type = this.suggestionViewerType
    }
}
</script>
<style lang="scss">
.folder-nav {
    .folder-nav--type-toggle-item {
        outline: 0 !important;
        box-shadow: none !important;
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        width: 50%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }


    // Do not scope these styles or they won't work.
    .folder-nav--type-icon {
        color: var(--primary);

        .singular {
            visibility: hidden;
        }

        .multiple {
            visibility: visible;
        }

    }

    // Do not scope these styles or they won't work.
    .folder-nav--type-toggle-item.selected .folder-nav--type-icon {
        color: #fff;

        .singular {
            visibility: hidden;
        }

        .multiple {
            visibility: visible;
        }

        //.path-fill {
        //    fill: #fff;
        //}
        //
        //.path-stroke {
        //    stroke: #fff;
        //}
    }
}
</style>
