import {groupAndMerge} from "../utilities/Arrays";
import {getChangedKeys, jsonDiffers} from "../utilities/json";
import {hasValue} from "../utilities/utils";

export const fieldsThatReloadPublishers = ['contentType', 'age', 'evergreen']

export function mergePublishers(...publisherLists) {
    let merge = (a ,b) => ({ source: a.source, count: a.count + b.count, name: a.name });
    let groupAndSumCounts = groupAndMerge(x => x.source, merge);
    let ensurePublisherFormat = p => typeof p === 'object' ? p : typeof p === 'string' ? { source: p, name: p, count: 0 } : null;
    let ensurePublisherListFormat = pl => pl.map(ensurePublisherFormat).filter(hasValue)
    //console.log('mergePublishers', publisherLists)
    let result = publisherLists
        .filter(hasValue)
        .map(ensurePublisherListFormat)
        .flat()
        .reduce(groupAndSumCounts, [])
    //console.log('mergePublishers.result', result)
    return result

}

export function publishersNeedReload(oldFilters, newFilters) {
    if (!oldFilters && newFilters) return true;
    if (!newFilters) return false
    let changedKeys = getChangedKeys(oldFilters, newFilters, jsonDiffers);
    //console.log('publishersNeedReload', {changedKeys, newFilters, oldFilters})
    return changedKeys.some(key => fieldsThatReloadPublishers.includes(key));
}
