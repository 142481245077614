<template>
    <div>
        <textarea type="text" ref="hiddenVal" :value="value" style="display: none; width: 100%"/>
    </div>
</template>

<style>

</style>

<script>
    export default {
        props: {
            value: {},
            copyOn: String,
        },
        methods: {
            copyFromHiddenElement(el) {
                // let el = event.target
                console.log('el.value', el.value)
                el.style.display = 'block'
                el.select();
                document.execCommand("Copy");
                el.style.display = 'none'

            },
            copy() {
                this.copyFromHiddenElement(this.$refs.hiddenVal);
            },
        }
    }
</script>
