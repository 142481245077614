ps-4 pe-4 container<template>
    <main id="filter-view-management-page" class="pt-4 ps-4 pe-4 container">
        <div class="row">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-header bg-primary text-white">
                        <h3 class="card-title">Accounts</h3>
                    </div>
                    <div class="card-body">

                        <div class="list-group">
                            <a
                                href="#"
                                @click.prevent="changeAccount(account)"
                                class="list-group-item small"
                                v-for="account in accounts"
                                :key="account.id"
                                :class="{'current' : currentAccountOverview && currentAccountOverview.id === account.id}"
                            >
                                <i v-if="currentAccountOverview && currentAccountOverview.id === account.id"
                                   class="fa fa-check-circle text-primary pe-1"/>
                                <span>{{ account.name }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="text-container">
                            <div class="d-flex justify-content-between align-items-center">
                                <h1 class="m-0">Filter Views</h1>
                                <button v-if="features.editFilterViewPageAddEdit" type="button" class="btn btn-link font-size-16" @click="createFilterView">
                                    <i class="fa fa-plus pe-1"/>
                                    <span>Create Filter View</span>
                                </button>
                            </div>

                            <input
                                v-if="filterViews && filterViews.length"
                                type="search"
                                class="form-control mb-2"
                                v-model="searchText"
                                placeholder="Search Filter Views">
                            <div v-else-if="loaded" class="card p-4 text-center bg-light">
                                <h4 class="text-dark">You have no filter views at the moment.</h4>
                                <p>Add your first filter view here.</p>
                                <div class="mb-3">
                                    <button type="button" class="btn btn-primary d-inline-block" @click="createFilterView">
                                        <i class="fa fa-plus"/>
                                        Create Filter View
                                    </button>
                                </div>
                                <p class="text-dark">Questions? Check out <a :href="learnUrls.filterViews" v-if="learnUrls.filterViews" class="fw-semibold">our help center.</a></p>
                            </div>
                            <div v-else>
                                <spinner></spinner>
                            </div>
                            <div class="list-group">
                                <div class="list-group-item"
                                     v-for="filterView in filterViews"
                                     :key="filterView.id"
                                     v-show="!searchText || filterView.name.includes(searchText)"
                                >
                                    <filter-view-row
                                        :filters="filterView"
                                        :has-content-id="currentAccountOverview && currentAccountOverview.id"
                                        has-content-type="account"
                                        @deleted="loadFilterViews"
                                        @changed="loadFilterViews">
                                    </filter-view-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

</template>
<style scoped>
    h1 {
        font-size: 1.5rem;
    }
</style>
<script>
import {mapGetters, mapActions} from 'vuex'
import FilterViewRow from "../components/FilteringAndSorting/FilterViewRow";
import {hasNonWhitepsaceValue} from "../utilities/utils";
import {Filters} from "../models/Filters";
import Spinner from "../components/Controls/Spinner";
import {unique} from "../utilities/Arrays";
import features from "@/Features";

export default {
    components: {Spinner, FilterViewRow},
    computed: {
        ...mapGetters({
            accounts: 'allAccounts',
            currentAccountOverview: 'currentAccountOverview',
            folders: 'folders',
            collections: 'collections',
            learnUrls: 'learnUrls',
            topics: 'topics',
        }),
        features() {
            return features
        },
    },
    watch: {
        currentAccountOverview() {
            this.loadFilterViews();
        }
    },
    data() {
        return {
            loaded: false,
            searchText: '',
            filterViews: [],
            networkActivity: false,
            editorIsVisible: false,
        }
    },
    mounted() {
        this.loadFilterViews();
    },
    methods: {
        async changeAccount(account) {
            console.log('* FilterViewManage changeAccount about to dispatch changeAccount with:', account)
            await this.$store.dispatch('changeAccount', {overview: account});
            await this.loadFilterViews(account);
        },
        async loadFilterViews() {
            await this.$api.get(`/accounts/${this.currentAccountOverview.id}/filter-views`)
                .then(response => {
                    this.networkActivity = false;
                    this.loaded = true;
                    this.filterViews = this.$sort.alphabetize(response.data
                        .filter(f => hasNonWhitepsaceValue(f.name))
                        .filter(unique(x => x.id))
                        .map(f => Filters.fromApi(f)), 'name');

                }).catch(() => {
                    this.networkActivity = false;
                });
        },
        async createFilterView(){
            let result = await this.$views.filterViewEdit.showSaveCancel({
                target: null,
                value: null,
                hasContentId: this.currentAccountOverview.id,
                hasContentType: 'account'
            })
            this.$emit('saved', result)
            // window.Events.$once('save_filterViewEdit', result => {
            //     this.selected = result;
            //     this.$emit('saved', result)
            // })
            // window.Events.$emit('show_filterViewEdit', {
            //     target: null,
            //     value: null,
            //     hasContentId: this.currentAccountOverview.id,
            //     hasContentType: 'account'
            // })
        }

    }
}

</script>
