<template>
    <li class="list-group-item flex-column align-items-start">
        <div class="d-flex justify-content-between">
            <div class="mb-0 d-flex align-items-center">
                <h6 class="m-0">{{ displayName }}</h6>
                <div class="font-size-11 fw-light text-steel-50">
                    <span v-if="topicId" class="ms-1 tag outline-secondary">
                        <topics-svg class="singular em-1 top-1"/> Topic
                    </span>
                    <span v-if="sourceCollectionId" class="ms-1 tag outline-secondary">
                        <collections-svg class="singular em-1 top-1"/> Collection
                    </span>
                    <span v-if="rssFeedId" class="ms-1 tag outline-secondary">
                        <span class="fa fa-rss"></span> RSS
                    </span>
                </div>

                <button v-if="canRefresh"
                        type="button"
                        class="btn btn-link text-muted m-0 p-0 ms-1 pb-1"
                        style="font-size: 100%"
                        @click="refreshSource">
                    <small><span class="fa fa-refresh"></span></small>
                </button>
            </div>
            <div class="">
                <button type="button" class="btn ui-link text-danger m-0 p-0 em1" @click="deleteSource">
                    <span class="fa fa-trash me-1 bottom-1"></span>
                    <span class="btn-link-text">Remove</span>
                </button>
            </div>
        </div>
        <div class="mb-1 text-muted">
            <a class="text-muted" :href="url" target="_blank" v-show="url">{{ url }}</a>
        </div>
        <div v-if="showUpdateInfo">
            <small v-if="hasBeenChecked" class="row text-muted">
            <span class="col col-sm-12 col-md-5 col-lg-5">
                Last Checked: {{ lastCheckedDisplay }}
            </span>
                <span class="col col-sm-12 col-md-7 col-lg-7">
                Last Published: {{ lastPublishedDisplay }}
            </span>
            </small>
            <small v-else class="text-muted">
            <span v-if="url">
                Queued for processing (generally takes a few minutes)
            </span>
                <span v-else-if="!(topicId||sourceCollectionId)">
                Queued for processing (generally takes less than a minute)
            </span>
                <span v-else-if="topicId">
                Articles from topic sources show up an hour after they show up in the topic
            </span>
                <span v-else-if="sourceCollectionId">
                Articles from collection sources generally show up within a minute of being added to the source collection
            </span>
            </small>
            <small v-if="failed" class="text-danger">
                {{ failureReasonDisplay }}
                (<a :href="learnUrls.rssErrors" v-if="learnUrls.rssErrors"
                    target="_blank"
                    class="learn-more">Learn More</a>)
            </small>
            <small v-if="unfinished" class="text-warning">
                {{ failureReasonDisplay }}
                (<a :href="learnUrls.rssErrors" v-if="learnUrls.rssErrors"
                    target="_blank"
                    class="learn-more">Learn More</a>)
            </small>

        </div>


        <div v-if="features.editSourceSources && filters && type !== 'rss' && sourceId && filters.id"
             class="mt-3"
             style="overflow: visible">
            <select-filter-view
                v-model="filterViewId"
                :has-content-type="sourceCollectionId ? 'collection' : 'topic'"
                :has-content-id="id"
                :currentUsage="sourceId"
                class="mb-2"
                ref="filterViewEdit"
            >

            </select-filter-view>


        </div>
    </li>
</template>

<style scoped>
.learn-more {
    white-space: nowrap
}
</style>

<script lang="js">

// import { Prop } from 'vue-property-decorator'
import moment from "moment";
import {mapGetters} from 'vuex'
import TopicsSvg from "@/assets/svg/topics";
import CollectionsSvg from "@/assets/svg/collections";
// import Component from "vue-class-component";
import * as Controls from '@/components/Controls'
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";

export default {
    components: {SelectFilterView, ...Controls, TopicsSvg, CollectionsSvg},
    props: {
        sourceId: {default: null},
        destinationCollection: {type: Object},
        lastChecked: {default: null},
        lastPublished: {default: null},
        url: {default: ''},
        added: {default: null},
        name: {default: ''},
        topicId: {default: null},
        sourceCollectionId: {default: null},
        rssFeedId: {default: null},
        filters: {Object, default: null},
        failureReason: {default: ''},
        showUpdateInfo: {type: Boolean, default: true}
    },
    data() {
        return {
            showEditForm: false,
            loadingFilters: false,
            allSources: [],
            id: this.sourceCollectionId || this.topicId || '',
            validating: false,
            filterViewId: this.filters && this.filters.id || null,
        }
    },
    computed: {
        ...mapGetters({
            features: 'accountFeatures',
            collections: 'collections',
            topics: 'topics',
            learnUrls: 'learnUrls',
            configLimits: 'configLimits'
        }),
        type() {
            return this.url ? 'rss' : this.topicId ? 'topic' : this.sourceCollectionId ? 'collection' : null;
        },
        rssFeedRefreshLimit() {
            return (this.configLimits && this.configLimits.rssFeedRefresh) || 60
        },
        displayName() {
            if (this.name) return this.name;
            if (this.url) return this.domain(this.url);
            if (this.collection) return this.collection.name;
            if (this.topic) return this.topic.name;
            return '';
        },
        lastCheckedDisplay() {
            return this.formatFeedDate(this.lastChecked);
        },
        lastPublishedDisplay() {
            return this.lastPublished ? this.formatFeedDate(this.lastPublished) : 'No articles found';
        },
        hasBeenChecked() {
            return !!this.lastChecked && this.lastChecked || '' > this.added || '';
        },
        failureReasonDisplay() {
            if (!this.failureReason) return '';
            switch (this.failureReason) {
                case 'TooManyRedirects':
                    return 'The RSS feed URL provided redirected too many times.';
                case 'parse':
                    return 'The RSS feed could not be parsed and may not be formatted properly';
                case 'process_parsed_feed':
                    return 'The RSS feed encountered an error and may not be formatted properly';
                case 'process_pages':
                    return 'None of the articles could be processed/found';
                case 'unfinished':
                    return 'This RSS started processing but took too long will resume shortly';
                case 'request':
                    return 'The RSS feed no longer exists, is down, or does not allow crawlers to access';
                default:
                    return 'An unknown error occurred processing this feed';
            }
        },
        unfinished() {
            return this.failureReason === 'unfinished'
        },
        failed() {
            return !!this.failureReason && !this.unfinished
        },
        topic() {
            return this.topics && this.topicId && this.topics.find(t => t.id === this.topicId)
        },
        collection() {
            return this.collections && this.sourceCollectionId &&
                this.collections.find(c => c.id === this.sourceCollectionId)
        },
        canRefresh() {
            let featureEnabled = this.features.refreshSources;
            let lastUpdate = moment.utc(this.lastChecked).local();

            let nextAllowed = lastUpdate.add(this.rssFeedRefreshLimit, 'minutes');
            return featureEnabled && (nextAllowed < moment());
        }
    },
    watch: {
        async filterViewId() {
            let collectionId = this.destinationCollection && this.destinationCollection.id;
            let sourceId = this.sourceId;
            let loadedAndReady = collectionId && sourceId && this.filterViewId;
            let filtersChanged = this.filters && this.filterViewId !== this.filters.id
            if (loadedAndReady && filtersChanged) {
                let data = {filter_view_id: this.filterViewId};
                let collectionSourceUrl = `/collections/${collectionId}/sources/${sourceId}/filter-view`
                await this.$api.put(collectionSourceUrl, data);
                await this.refreshSource(collectionId, sourceId)
            }
            this.$emit('filtersUpdated', {filterViewId: this.filterViewId, sourceId: this.sourceId})
        }
    },
    methods: {
        async refreshSource() {
            await this.$api.post(
                `/collections/${this.destinationCollection.id}/sources/${this.sourceId}/refresh`);
            this.lastChecked = null;
        },
        domain(url) {
            return url.replace(/https?:\/\//, '').replace(/\/.*/, '')
        },
        formatFeedDate(date) {
            return date ? moment.utc(date).local().fromNow().replace(/^[a-z]/, s => s.toUpperCase()) : ''
        },
        deleteSource() {
            this.$emit('deleteSource')
        },
        defaultValue() {
            return {
                id: '',
                unmodifiedFilters: {
                    age: -1,
                    time: 'Forever',
                    contentType: 'All',
                    favorites: false,
                    sortBy: 'Recency',
                    evergreen: true
                },
                filterModel: {
                    filters: {
                        age: -1,
                        time: 'Forever',
                        contentType: 'All',
                        favorites: false,
                        sortBy: 'Recency',
                        evergreen: true
                    }, url: ''
                }
            }
        },
        setTopicCollectionId(id) {
            this.id = id;
        },
    }
}
</script>
