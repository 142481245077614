<template>
    <div class="collection-menu">
        <router-link class="dropdown-item" to="/" v-if="showBackToCollectionLink">
            <div class="font-size-16"><i class="fa fa-arrow-left"></i> Back to Collection</div>
        </router-link>
        <a href="#scrolling-suggestions-container"
           v-else-if="collection"
           class="dropdown-item screen-reader-only">Focus Article List</a>

        <a href="#" v-if="collection" @click.prevent="showEditCollectionModal" class="dropdown-item">
            <div class="font-size-14"><i class="fa fa-edit"></i> Edit <span v-text="$strings.truncate(collection.name, 30)"/></div>
            <small class="text-muted">Edit your collection's name and description</small>
        </a>

        <router-link to="/collections" class="dropdown-item">
            <div class="font-size-14">
                <i class="fa fa-folder"></i>
                Manage My Collections
            </div>
            <small class="text-muted">Edit and manage your collections</small>
        </router-link>

        <a href="#" @click.prevent="showNewCollectionModal" class="dropdown-item">
            <div class="font-size-14"><i class="fa fa-plus"></i> Create New Collection</div>
        </a>


        <a v-if="features.experimental" href="#" @click.prevent="showNewWorkflowModal" class="dropdown-item">
            <div class="font-size-14"><i class="fa fa-plus"></i> Create Wrokflow Step</div>
        </a>


        <div v-if="accountCollections.length">

            <div class="dropdown-divider"></div>

            <div class="dropdown-item" @click.prevent.stop>
                <div class="font-size-16">
                    <collections-svg class="global-nav-svg multiple em-1"/>
                    Collections
                </div>
                <small class="text-muted">Select a Collection to view suggestions</small>
            </div>

            <div>
                <collections class="collections-nav"
                             :folder="rootFolder"
                             :is-root="true"
                             :isCheckable="false"/>
            </div>

        </div>
    </div>
</template>

<script>

import Collections from "@/components/Collections/CollectionsByFolder";
import CollectionsSvg from "@/assets/svg/collections";

import {mapGetters} from 'vuex';

export default {
    props: [],
    components: {
        Collections,
        CollectionsSvg,
    },
    computed: {
        ...mapGetters({
            collections: 'collections',
            features: 'features',
            folders: 'folders',
            currentAccountOverview: 'currentAccountOverview',
            collection: 'currentCollection'
        }),
        rootFolder() {
            return this.folders.find(f => this.currentAccountOverview.root.includes(f.id));
        },
        accountCollections() {
            return this.collections.filter(c => c.account.includes(this.currentAccountOverview.id))
        },
        showBackToCollectionLink() {
            return this.$route.name !== 'Suggestions';
        }
    },
    methods: {
        switchToTopics() {
            this.$store.dispatch('setSuggestionViewerType', {type: 'topics'});
        },
        showNewCollectionModal() {
            window.Events.$emit('showCollectionCreationModal');
        },
        showEditCollectionModal() {
            window.Events.$emit('showCollectionCreationModal', this.collection);
        },
        showNewWorkflowModal() {
            window.Events.$emit('showNewWorkflowModal', this.collection);
        }
    }
}
</script>

<style scoped>

.collections-nav {
    padding: 0 1.55rem;
}

</style>
