<template>
    <fieldset>
        <legend>Themes</legend>
        <fieldset>
            <label class="small me-4">
                <input type="radio" value="default" v-model="mode"/>&nbsp;
                From default {{ appName }} theme
                <input type="radio" class="form-check-input" value="default" v-model="mode"/>&nbsp;
                From default UpContent theme
            </label>

            <label class="small">
                <input type="radio" class="form-check-input" value="account" v-model="mode"/>&nbsp;
                From one of your themes
            </label>
        </fieldset>
        <fieldset v-if="mode === 'account'">
            <label class="full-width">
                Site
                <select v-model="site" required class="form-control form-select full-width">
                    <option v-for="s in sites" :key="s.id" :value="s.id">
                        {{s.description||s.domain}}
                    </option>
                </select>
            </label>
            <label v-if="site" class="full-width">
                Card Theme
                <select v-model="cardTheme" required class="form-control form-select full-width">
                    <option v-for="t in cardThemes"  :key="t.id" :value="t.id">
                        {{t.name}}
                    </option>
                </select>
            </label>
            <label v-if="site" class="full-width">
                Carousel Theme
                <select v-model="carouselTheme" required class="form-control form-select full-width">
                    <option v-for="t in carouselThemes" :key="t.id" :value="t.id">
                        {{t.name}}
                    </option>
                </select>
            </label>
        </fieldset>
    </fieldset>
</template>

<style>

</style>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "CopyTheme",
        props: { sites: { type: Array}, value: {} },
        data() {
            return {
                mode: 'default',
                site: null,
                cardTheme: null,
                cardThemes: [],
                carouselTheme: null,
                carouselThemes: [],
            }
        },
        computed: {
            ...mapGetters({
                appName: 'appName',
            })
        },
        methods: {
            update(values) {
                this.$emit('input', {...this.value, ...values})
            },
        },
        watch: {
            mode() {
                if (this.mode === 'default') {
                    this.update({ card: 'default', carousel: 'default' });
                } else {
                    this.update({ card: null, carousel: null })
                    this.site = this.sites[0].id;
                }
            },
            async site() {
                let [cards, carousels] = await Promise.all([
                    this.$api.get(`/galleries/sites/${this.site}/themes?type=card`),
                    this.$api.get(`/galleries/sites/${this.site}/themes?type=carousel`)
                ]).then(_ => _.map(resp => resp.data));

                this.cardThemes = cards;
                this.carouselThemes = carousels;
                this.cardTheme = cards[0].id;
                this.carouselTheme = carousels[0].id;
            },
            cardTheme() {
                this.update({ card: this.cardTheme })
            },
            carouselTheme() {
                this.update({ carousel: this.carouselTheme })
            },
        }
    }
</script>
