<template>
    <async-modal id="photo-editor-model" modalName="photoEdit" ref="modal" :component="componentLoader" size="full" :no-footer="true" :no-header="true" :remount-on-show="true">

    </async-modal>
</template>

<script>
import AsyncModal from "../../Controls/AsyncModal";
import {shallowRef} from 'vue'

export default {
    name: "PhotoEditorModal",
    created() {
        //         const componentLoader = shallowRef(() => import(/* webpackChunkName: "photoEditor" */ './PhotoEditorLoader'));

        this.componentLoader = shallowRef(() => import(/* webpackChunkName: "photoEditor" */ './PhotoEditorLoader'));
    },
    components: {AsyncModal},
    // Uncomment for vue3
    // setup() {
    //     const componentLoader = shallowRef(() => import(/* webpackChunkName: "photoEditor" */ './PhotoEditorAsync'));
    //     return {
    //         componentLoader
    //     }
    // },
    computed: {
        open() {
            return this.loaded && (this.$refs.modal.state !== 'closed')
        }
    },
    data() {
        return {
            loaded: false
        }
    },
    mounted() {
        this.loaded = true;
    },
    watch: {
    },
}
</script>

<style>
    #photo-editor-model {
        z-index: 2000 !important;
    }
</style>
