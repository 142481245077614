<template>
    <div>
        <div v-show="showHtml" class="code-snippet">
            <code>{{galleryCode}}</code>
        </div>
        <div>
            <label v-if="!gallery.carousel_theme_id && gallery.type !== 'card'" class="small text-muted mb-0">Carousels</label>
            <div v-if="!gallery.carousel_theme_id && gallery.type !== 'card'" class="code-snippet mb-2">
                <code>{{carouselJs(gallery)}}</code>
            </div>
            <label v-if="!gallery.card_theme_id" class="small text-muted mb-0">Collection Cards</label>
            <div v-if="!gallery.card_theme_id" class="code-snippet">
                <code>{{cardJs(gallery, gallery.type !== 'card' ? '${collectionId}' : 'only')}}</code>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
    import GalleryBase from './GalleryBase'
    import {cardJs, galleryElement, rootElementForType, carouselJs} from "@/services/Gallery";
    export default {
        mixins: [GalleryBase],
        name: "GalleryCodeSnippet",
        props: { gallery: Object },
        computed: {
            showHtml() {
                return this.gallery.type === 'card' ? this.gallery.card_theme_id : this.gallery.card_theme_id || this.gallery.carousel_theme_id
            },
            galleryCode() {
                let g = this.gallery;
                let themes = [g.card_theme_id, ...(g.type !== 'card' ? [g.carousel_theme_id]:[])].filter(t=>t);
                return `<div>\n  ${galleryElement(rootElementForType(g.type), g.id, themes)}\n</div>`
            },
        },
        methods: {
            cardJs(g, c) { return cardJs(g, c) },
            carouselJs(g) { return carouselJs(g) }
        }
    }
</script>
