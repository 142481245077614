<template>
    <div>
        <div class="form-header">
            <h1>
                Preview Gallery 'My Gallery'
                <span class="form-footer-buttons">
                    <button class="btn btn-secondary me-3" type="button" @click="cancel">Back to Edit</button>
                    <button class="btn btn-primary" type="button" @click="showPurchase">Purchase</button>
                </span>
            </h1>
        </div>
        <div>
            <img src="/src/assets/images/Fake-Gallery.png" style="width: 1000px"/>
        </div>
        <gallery-purchase ref="modal"></gallery-purchase>
    </div>
</template>

<style scoped>
    h1 {
        border-bottom: 1px solid #463179;
        color: #463179;
        padding-bottom: 5px;
        margin-bottom: 20px;
        margin-top: 0px;
    }
    .form-footer-buttons {
        float: right;
        margin-bottom: 10px;
    }
</style>

<script>
    import GalleryPurchase from './GalleryPurchase';
    export default {
        components: { GalleryPurchase },
        data: () => ({
            purchaseOpen: false,
        }),
        methods: {
            showPurchase() {
                this.$refs.modal.show();
            },
            cancel() {
                this.$router.push('/galleries/create')
            }
        }
    }
</script>
