import Vue from "vue";

function api() {
    return Vue.prototype.$api
}

let state = {
    cancelTokens: [],
    hasSuggestionsSettings: {},
    justSwitched: false
}

let getters = {
    hasSuggestionsSettings : (state, getters, rootState, rootGetters) => {
        let storedSettings = state.hasSuggestionsSettings[rootGetters.hasSuggestions && rootGetters.hasSuggestions.id];
        if (storedSettings) {
            return storedSettings
        }
        return {filters: rootGetters.defaultFilters}
    }
}

let actions = {
    async loadSettingsForHasContent({dispatch}) {
        await dispatch(actions.fetchSettings.name)
    },
    async changeSetting({getters, dispatch}) {
        if (!getters.hasSuggestions.id || !getters.currentUser) return;

        getters.updateSettings()
        await dispatch('setUserPreference', {key: 'filters', value: filters})
    },
    async storeResult({dispatch, getters}, {settings}) {
        await dispatch('setSettingsForHasSuggestions', {settings, hasSuggestions: getters.hasSuggestions});
        var filters = getters.hasSuggestionsSettings.filters;
        if (!filters) {
            filters = getters.defaultFilters();
        }
    },
    async fetchSettings({getters, dispatch, state, commit}) {
        if (!getters.hasSuggestions || !getters.hasSuggestions.id || !getters.currentUser) return;

        let cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: 'loadSuggestionSettings'})

        let url = `/${getters.hasSuggestions.type}s/${getters.hasSuggestions.id}/settings`
        await api().get(url, {cancelToken}).then(async (response) => {
            var settings = response.data;

            if (!(settings instanceof Object)) {
                settings = {filters: getters.defaultFilters};
            }

            if (!settings.filters) {
                settings['filters'] = getters.defaultFilters

            }

            for (var key in getters.defaultFilters) {
                if (settings.filters[key] === undefined && key !== 'version') {
                    settings.filters[key] = getters.defaultFilters[key];
                }
            }

            await dispatch(actions.storeResult.name, {settings})

            Vue.nextTick(() => {
                commit(mutations.SET_JUST_SWITCHED.name, {justSwitched: false});
            })
        }).catch(() => {
            Vue.nextTick(() => {
                commit(mutations.SET_JUST_SWITCHED.name, {justSwitched: false});
            })
        })
    },
    async updateSettings({getters, dispatch, state}) {
        if (state.justSwitched || !getters.hasSuggestions || !getters.currentUser) {
            return;
        }
        console.log('** updateSettings2', getters.currentFilters)
        const filters = {...getters.currentFilters}
        filters['excludedSources'] = getters.excludedSources;
        filters['sources'] = getters.selectedSources;
        filters['includeNewSources'] = getters.includeNewSources;

        let settings = {filters}

        if (getters.lastPost === JSON.stringify(settings)) return;

        getters.lastPost = JSON.stringify(settings);

        let cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: 'updateSettings'});

        let url = `/${getters.hasSuggestions.type}s/${getters.hasSuggestions.id}/settings`
        console.log('** updateSettings.apiCall', {url, settings})
        await api().put(url, settings, {cancelToken}).catch(() => {
        });
        window.Events.$emit('filterSettingsChanged');
        if (!settings.filters || Object.keys(settings.filters).length === 0) {
            //This should handle a bogus response from the server
            await dispatch('setSettingsForHasSuggestions',
                {settings: {filters: getters.defaultFilters}, hasSuggestions: getters.hasSuggestions});
        } else {
            await dispatch('setSettingsForHasSuggestions', {settings, hasSuggestions: getters.hasSuggestions});
        }
    },
    setSettingsForHasSuggestions : ({commit, getters}, {settings, hasSuggestions}) => {
        commit("SET_SETTINGS_FOR_HAS_SUGGESTIONS", {settings, hasSuggestions: hasSuggestions || getters.hasSuggestions});
    },
}

let mutations = {
    SET_SETTINGS_FOR_HAS_SUGGESTIONS : (state, {settings, hasSuggestions}) => {
        var updated = {...state.hasSuggestionsSettings}
        updated[hasSuggestions.id] = settings;
        state.hasSuggestionsSettings = updated;
    },
    SET_JUST_SWITCHED(state, {justSwitched}) {
        state.justSwitched = justSwitched;
    },

    SET_CANCEL_TOKENS(state, {cancelToken}) {
        state.cancelTokens = [state.cancelToken]
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
