<template>
    <li class="d-inline-block">
        <a :href="tag.link_url||'javascript:void(0)'" :class="`tag ${tag.tagClass||''}`" :target="tag.link_url ? '_blank' : ''" @click="onClick">
            <!--<upcontent-logo v-if="tag.key === 'upcontent_featured'" class="upcontent-logo"></upcontent-logo>-->
            <slot name="before"></slot>
            <i v-if="tag.iconClass" :class="`icon-dynamic ${tag.iconClass||''}`"></i>
            <img v-else-if="tag.icon_url" :src="tag.icon_url||''" height="1em" width="1em">
            {{tag.label||''}}
            <!--<icon-checkbox icon-class="fa fa-filter" v-model="filterByZest" class="m-0"></icon-checkbox>-->
            <slot name="after"></slot>
        </a>
    </li>
</template>

<style>

</style>

<script>
    import IconCheckbox from "@/components/Controls/IconCheckbox";
    import UpcontentLogo from '@/components/Navigation/Logo';
    import {unique} from "@/utilities/Arrays";
    export default {
        props: {
            tag: { type: Object }
        },
        components: {IconCheckbox, UpcontentLogo},
        model: {
            prop: 'tag'
        },
        data() {
            return {
                filterByZest: false,
            }
        },
        methods: {
            async onClick(){
                if (this.tag.category === 'quality_tag') {
                    let reasons = await this.$api.get(`/quality-tags/${this.tag.id}/reasons`).then(r=>r.data);
                    let displayReasons = reasons.map(r => r.explanation).filter(unique()).join('\n');
                    if (displayReasons.length)
                        alert(displayReasons);
                }
            }
        }
    }
</script>
