export function formatCents(cents, includeDollarSign = false) {
    // E.g. 1200 = 12.00
    let withDollarSign = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(cents / 100);
    return includeDollarSign ? withDollarSign : withDollarSign.replace("$", "")
}
export function getDollarAmount(amount) {
    if(isNaN(amount)) {
        console.error('getDollarAmount()', 'A number is required for this function.');
    }
    /* This always returns a number */
    return amount > 0 ? Math.trunc(amount) : 0;
}
export function getDecimalAmount(amount) {
    /* This always returns a string so the trailing 0 isn't lost.  */
    /* Sorry James, this returns '' instead of '00' or '0'. */

    if(isNaN(amount)) {
        console.error('getDecimalAmount()', 'A number is required for this function.');
    }

    const dollars = getDollarAmount(amount);

    // Extract decimal part to two decimal places with normal rounding.
    const cents = Number((amount - dollars).toFixed(2));

    //
    if (cents === 0) {
        return '';
    }

    // Convert to string, get the amount
    const textStuff = cents.toString().split('.')[1];

    // Add a zero if we need it.
    return textStuff.length === 1 ? textStuff + '0' : textStuff;
}
