<template>
    <form class="add-source" @submit.prevent="addSource">
        <collection-topic-selector
            class="add-source--cts mb-2"
            :required="required"
            :exclude="exclude"
            type="collection"
            :value="id"
            ref="collectionTopicSelector"
            @input="setTopicCollectionId"
        ></collection-topic-selector>
        <div v-if="id" class="gallery-builder-form-row">
            <label>Filter View</label>
            <select-filter-view
                v-model="filterViewId"
                has-content-type="collection"
                :has-content-id="id"
                ref="filterViewEdit"
            >
            </select-filter-view>
        </div>
        <!--<input type="submit" v-if="type !== 'rss' && id && !(feedSuggestions && feedSuggestions.length)" class="btn btn-primary full-width" value="Add Source" :disabled="sourcesBusy || validating">-->
        <div class="text-danger">
            {{ sourceErrorMessage }}
        </div>
    </form>
</template>

<script>
import CollectionTopicSelector from "@/components/Controls/CollectionTopicSelector";
import HasFilters from '@/components/Collections/HasFilters';
import {mapGetters} from 'vuex'
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";

export default {
    name: "SelectCollectionTopic",
    mixins: [HasFilters],
    components: {SelectFilterView, CollectionTopicSelector},
    props: {
        sourcesBusy: {type: Boolean, default: false},
        exclude: String,
        onlyType: {type: String},
        required: {type: Boolean, default: false},
        value: {type: Object, default: null}
    },
    computed: {
        ...mapGetters({
            features: 'features'
        }),
    },
    data() {
        return {
            valueChanging: false,
            type: this.onlyType || 'collection',
            sourceErrorMessage: '',
            id: this.value && this.value.id || '',
            validating: false,
            filterViewId: null,
        }
    },
    watch: {
        id(val) {
            if (this.valueChanging) return;
            console.log('id', this.id, this.value)
            this.$emit('input', this.value ? {...this.value, id: this.id} : {...this.defaultValue(), id: this.id})
        },
        value() {
            this.valueChanging = true;
            console.log('value', this.value)
            if (this.value) {
                this.id = this.value.id;
                this.filterViewId = this.value.filterViewId;
            } else {
                this.id = null;
                this.filterViewId = null;
            }
            this.valueChanging = false;
        },
        filterViewId() {
            this.$emit('input', Object.assign({}, this.value, {filterViewId: this.filterViewId}))
        }
    },
    methods: {
        defaultValue() {
            return {
                id: '',
                unmodifiedFilters: {
                    age: -1,
                    time: 'Forever',
                    contentType: 'All',
                    favorites: false,
                    sortBy: 'Recency',
                    evergreen: true
                },
                filterModel: {
                    filters: {
                        age: -1,
                        time: 'Forever',
                        contentType: 'All',
                        favorites: false,
                        sortBy: 'Recency',
                        evergreen: true
                    }, url: ''
                }
            }
        },
        typeChange(val) {
            this.type = val;
        },
        async validate() {
            let notSelected = !this.id;
            if (notSelected) {
                this.sourceErrorMessage = `Must select a ${this.type}`;
                return false;
            }
            return true;
        },
        setError(error) {
            this.sourceErrorMessage = error;
        },
        resetForm() {
            this.resetErrors();
            this.id = '';
        },
        resetErrors() {
            this.sourceErrorMessage = '';
        },
        setTopicCollectionId(id) {
            this.id = id;
        },
    }
}
</script>

<style scoped>
hr {
    border: 1px solid var(--bs-dark);
}

.gallery-builder-form-row > label, .gallery-builder-form-section label {
    margin-bottom: 0;
    margin-left: 1px;
}

label {
    color: var(--bs-dark);
    display: block;
}
</style>

<style lang="scss" scoped>
.add-source {
    .input-group.button-group {
        display: flex;
    }

    .filter-form {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .uc-select {
        width: auto;
    }

    .uc-select {
        input[type=search] {
            width: 0 !important;
        }

        &.open {
            input[type=search] {
                width: auto !important;
            }
        }
    }

    .content-source-type-select.uc-select {
        max-width: 125px;

        &.src-collection {
            max-width: 170px;
        }

        .icon-container {
            margin-right: .2em;
        }
    }

    .content-source-type-select.open {
        max-width: 120px;
    }

    .add-source--cts, .add-source--url {
        flex-grow: 1;
    }
}
</style>
