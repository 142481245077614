<template>
    <div class="article-container ps-sm-1 pe-sm-1">
        <div class="article-title-container full-width">
            <div class="d-flex justify-content-end align-items-end pt-1 pb-1">
                <div class="close-button-container bottom-2">
                    <button tabindex="1" type="button" @click="close" class="ui-link me-1 text-nowrap">
                        <span class="font-size-18 top-1 fw-normal me-1" aria-hidden="true">&times;</span>
                        <span class="font-size-12 fw-normal">Close Preview</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="article-body-container  full-width pt-1" id="suggestion-reader">

            <div class="article-text-container">
                <h1 class="article-title-text font-size-22 m-0 p-0"
                    :title="suggestion.title">{{ suggestion.title }}</h1>
                <div class="mb-3">
                    <span class="small">
                        <span v-if="suggestion.publisher">
                            <a :href="$shortener.link(suggestion)" target="_blank">
                                {{ suggestion.publisher }}
                                <i class="fa fa-external-link"/>&nbsp;
                            </a>
                            <span class="me-1 ms-1">•</span>
                        </span>
                        {{ prettyDate }}
                    </span>
                </div>

                <article-controls
                    @goToNotes="forceScrollToNotes"
                    :suggestion="freshSuggestion"
                    ref="topControls"
                    @toggleCardInCollectionBatch="$emit('toggleCardInCollectionBatch', $event)"
                    :is-in-collection-batch="isInCollectionBatch"
                />
            </div>

            <article-image height="400px" :url="suggestion.image_url"/>

            <div class="article-text-container">

                <div class="progress" v-if="networkActivity">
                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                         role="progressbar"
                         aria-valuenow="75"
                         aria-valuemin="0"
                         aria-valuemax="100"
                         style="width: 100%"></div>
                </div>

                <div class="btn-toolbar justify-content-center article-controls mt-3"
                     role="toolbar"
                     aria-label="Toolbar with button groups">


                    <div class="btn-group me-2" role="group" aria-label="Adjust font size">
                        <button class="btn btn-secondary" @click="adjustFontSize('-')">
                            <i class="fa fa-font small"></i>
                        </button>
                        <button class="btn btn-secondary" @click="adjustFontSize('+')">
                            <i class="fa fa-font"></i>
                        </button>
                    </div>

                    <div class="btn-group me-2" role="group" aria-label="Adjust font face">
                        <button class="btn btn-secondary" @click="fontFaceType = 'Georgia'">
                            <span style="font-family: Georgia">B</span>
                        </button>
                        <button class="btn btn-secondary" @click="fontFaceType = false">
                            B
                        </button>
                    </div>

                    <div class="btn-group me-2" role="group" aria-label="Adjust line height">
                        <button class="btn btn-secondary" @click="adjustLineHeight('-')">
                            <i class="fa fa-bars small"></i>
                        </button>
                        <button class="btn btn-secondary" @click="adjustLineHeight('+')">
                            <i class="fa fa-bars"></i>
                        </button>
                    </div>
                    <div class="btn-group" role="group" aria-label="Adjust margins">
                        <button class="btn btn-secondary" @click="adjustMargins('-')">
                            <i class="fa fa-arrows-h small"></i>
                        </button>
                        <button class="btn btn-secondary" @click="adjustMargins('+')">
                            <i class="fa fa-arrows-h"></i>
                        </button>
                    </div>
                </div>


                <div class="text-success small" v-if="favoritedByText">
                    <i class="fa fa-star"></i>
                    {{ favoritedByText }}
                    <hr>
                </div>

                <suggestion-tags v-if="suggestion.tags && suggestion.tags.length"
                                 :page="suggestion"
                                 class="mt-2 mb-2"></suggestion-tags>


                <div class="article-items">
                    <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: publisher data santized on backend, user entered santiized on frontend-->
                    <article class="article-body" :style="htmlStyle" v-html="bodyHTML"></article>

                    <article-controls
                        v-if="bodyHTML"
                        @goToNotes="forceScrollToNotes"
                        :suggestion="freshSuggestion"
                        @toggleCardInCollectionBatch="$emit('toggleCardInCollectionBatch', $event)"
                        :is-in-collection-batch="isInCollectionBatch"
                    />

                    <div class="notes" id="article-notes-container">
                        <div v-if=" ! currentUser">
                            <p>
                                <router-link to="/login"><b>Log in</b></router-link>
                                to see notes
                            </p>
                        </div>
                        <div v-else>

                            <div class="alert alert-danger" v-if="savingNoteError">
                                {{ savingNoteError }}
                            </div>

                            <note-creator
                                v-if="!savingNote"
                                :suggestion="suggestion"
                                :note-text="noteText"
                                @noteAdded="addNote"

                            />

                            <note
                                v-for="note in pagedNotes"
                                :key="'note-'+note.id"
                                :note="note"
                            />

                            <paginator :items="myNotes" @update="setPagedNotes" :size="5"/>

                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>

import * as moment from 'moment'
import {mapGetters} from 'vuex';
import uuid from 'uuid'
import * as $ from 'jquery'
import Vue from 'vue';

import Paginator from '@/utilities/Paginator'
import ArticleControls from './ArticleReaderControls'

import Note from '@/components/Notes/Note'
import NoteCreator from '@/components/Notes/Creator'
import ArticleImage from '@/components/Suggestions/HeroImage'
import SuggestionTags from "@/components/Suggestions/SuggestionTags";

export default {
    props: ['suggestion', 'collectionBatch'],
    components: {
        SuggestionTags,
        Note, NoteCreator, ArticleImage, Paginator, ArticleControls
    },
    data() {
        return {
            bodyHTML: '',
            summaryNetworkActivity: false,
            notesNetworkActivity: false,
            fontFaceType: false,
            fontSizeAdjustment: 0,
            marginAdjustment: 0,
            lineHeightAdjustment: 0,
            savingNote: false,
            temporaryNote: false,
            pagedNotes: [],
            savingNoteError: false,
            noteText: ''
        }
    },
    watch: {
        suggestion() {
            this.fetch()
            this.refresh();
            this.scrollToTop();
        },
        notes() {
            this.scrollToNotes()
        },
        scroll() {
            if (!this.scroll)
                this.scrollToNotes()
        },
        hasSuggestions(current, prev) {
            if (prev.id != current.id)
                this.close();
        }
    },
    computed: {
        ...mapGetters({
            apiHost: 'apiHost',
            notes: 'notes',
            suggestions: 'suggestionsCache',
            currentUser: 'currentUser',
            scroll: 'scrollToNotesInReadingSuggestion',
            topic: 'currentTopic',
            hasSuggestions: 'hasSuggestions'
        }),
        freshSuggestion() {
            return this.suggestions.find(s => s.id == this.suggestion.id);
        },
        htmlStyle() {
            return {
                lineHeight: 20 + this.lineHeightAdjustment + this.fontSizeAdjustment + 'px',
                fontSize: 16 + this.fontSizeAdjustment + 'px',
                paddingLeft: this.marginAdjustment + 'px',
                paddingRight: this.marginAdjustment + 'px',
                fontFamily: this.fontFaceType ? this.fontFaceType : 'inherit'
            }
        },
        prettyDate() {
            return moment.utc(this.suggestion.date_published).fromNow();
        },

        myNotes() {
            if (!this.freshSuggestion) return [];
            var notes = this.notes
                .filter(n => n.suggestion && n.suggestion.includes(this.freshSuggestion.id))

            notes.sort((a, b) => {
                return (new Date(b.created)) - (new Date(a.created))
            })

            if (this.temporaryNote) {
                notes.unshift(
                    {
                        text: this.temporaryNote.text,
                        user: this.currentUser.id,
                        temporary: true,
                        id: uuid.v4()
                    })
            }

            return notes;
        },
        networkActivity() {
            return this.summaryNetworkActivity || this.notesNetworkActivity
        },
        favoritedByText() {
            if (!this.freshSuggestion) return;

            if (this.freshSuggestion.favorited_by.length)
                return "Favorited by " +
                    this.freshSuggestion.favorited_by.map(u => u.id == this.currentUser.id ? 'you' : u.name)
                        .join(", ");
        },
        isInCollectionBatch() {
            return this.collectionBatch.indexOf(this.freshSuggestion) > -1;
        }
    },
    mounted() {
        if (!this.bodyHTML) {
            this.fetch()
        }
        window.Events.$on('closeArticleReader', this.close);
    },
    methods: {
        refresh() {
            window.Events.$emit("RefreshSuggestion", this.suggestion.id);
            this.savingNoteError = false;
            this.noteText = '';
        },
        setPagedNotes(notes) {
            this.pagedNotes = notes;
        },

        fetch() {
            // Fetch the suggestions summary & notes in separate
            this.summaryNetworkActivity = true;
            this.bodyHTML = '';

            // Summary...
            const summaryPromise = this.$api.get(this.suggestion.fulltext)
            summaryPromise.then(resp => {
                this.bodyHTML = resp.data
            }).catch(() => {
                this.bodyHTML = this.$strings.escapeHtml(this.suggestion.summary)
            }).finally(() => this.summaryNetworkActivity = false)

            // ..Notes..
            // TODO(casio): check against cached notes in localStorage and only fetch new(unknown, edited?) notes
            this.notesNetworkActivity = true
            const notesPromise = Promise.all(
                this.suggestion.notes
                    // Locally added notes will put just the `id` into suggestions, while the API return URIs
                    .map(noteIdOrURI => noteIdOrURI.startsWith('http') ? noteIdOrURI :
                        `${this.apiHost}/notes/${noteIdOrURI}`)
                    .map(noteURI => this.$api.get(noteURI)
                    )).then(
                responses => {
                    responses.forEach(res => this.$store.dispatch('addNote', {note: res.data}))
                }
            ).catch(err => {
                throw err
            }).finally(() => this.notesNetworkActivity = false)

            // .. finally scroll to the notes container eventually
            Promise.allSettled([summaryPromise, notesPromise]).finally(
                () => Vue.nextTick(this.scrollToNotes)
            )
        },
        forceScrollToNotes() {
            let el = document.getElementById('article-notes-container')
            if (el) {
                el.scrollIntoView({behavior: 'smooth', block: 'end'});
                $('.new-note:eq(0)').focus();
                // TODO(casio): clean up state handling, so a random 500ms guess isnt necessary, but eg `Vue.nextTick` suffices
                setTimeout(() => this.$store.dispatch('resetScrollToNotes'), 500);
            }
        },
        scrollToNotes() {
            if (this.scroll) {
                this.forceScrollToNotes()
            }
        },
        scrollToTop() {
            let el = document.getElementById('suggestion-reader')
            if (el) {
                let offset = el.offsetTop;
                $('.article-container').animate({scrollTop: offset}, 500);
            }
        },
        close() {
            this.$store.dispatch('setReadingSuggestion', {suggestion: false})
        },
        adjustFontSize(operand) {
            if (operand == '+') {
                this.fontSizeAdjustment += 2
            } else if (this.fontSizeAdjustment > -8) {
                this.fontSizeAdjustment -= 2
            }
        },
        adjustLineHeight(operand) {
            if (operand == '+') {
                this.lineHeightAdjustment += 4
            } else if (this.lineHeightAdjustment > 0) {
                this.lineHeightAdjustment -= 4
            }
        },
        adjustMargins(operand) {
            if (operand == '+') {
                this.marginAdjustment += 10
            } else if (this.marginAdjustment > 0) {
                this.marginAdjustment -= 10
            }
        },
        addNote({note}) {
            this.noteText = '';
            this.savingNoteError = false;
            this.savingNote = true;
            this.temporaryNote = note;
            this.$api.post('/suggestions/' + this.suggestion.id + '/notes', {text: note.text})
                .then(response => {
                    this.temporaryNote = false;
                    this.savingNote = false;
                    let newNote = response.data;
                    this.$store.dispatch('addNote', {note: newNote});
                })
                .catch(() => {
                    this.noteText = note.text;
                    this.savingNote = false;
                    this.temporaryNote = false;
                    this.savingNoteError = "Something went wrong saving your note"
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: unset !important;
}
.article-container {
    border-radius: var(--border-radius);
}

.article-title-container {
    z-index: 500;
    background: var(--water-33);
    margin: 0 auto;
    border: 1px solid var(--steel-100);
    border-bottom-width: 0;
    border-radius: var(--border-radius);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.article-body-container {
    border: 1px solid var(--steel-100);
    border-top-width: 0;
    border-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.article-body {
    font-size: inherit;
    overflow-wrap: break-word;
}

.article-title-container, .article-text-container {
    padding-left: .75rem;
    padding-right: .75rem;
}

.close-button-container {
    text-align: right;
}

.article-title-container .article-actions {
    padding: 0 !important;
}

.article-controls {
    margin-bottom: 1.9em;
}

.article-controls .btn {
    font-size: 18px;
    padding: 0.175rem 0.5rem;
}

.fa.small {
    font-size: 70%;
}

.progress {
    margin-bottom: 0.8em;
}
</style>
