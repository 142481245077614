<template>
    <div class="suggestion-source-tag font-size-11 fw-light text-steel-50 tag outline-secondary" v-if="suggestionType !== ''">
        <span v-if="suggestionType === 'topic'">
            <topics-svg class="sst-icon singular em-1 top-1 me-1"/>
            <span class="sst-text">Topic</span>
        </span>
        <span v-if="suggestionType === 'collection'">
            <collections-svg class="sst-icon singular em-1 top-1 me-1"/>
            <span class="sst-text">Collection</span>
        </span>
        <span v-if="suggestionType === 'rss'">
            <i class="sst-icon fa fa-rss me-1"></i>
            <span class="sst-text">RSS</span>
        </span>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import CollectionsSvg from "@/assets/svg/collections";
import TopicsSvg from "@/assets/svg/topics";

export default {
    name: "SuggestionSourceTag",
    props: {
        suggestionType: {type: String, required: true}, // One of Collection or Topic (or RSS cause sure).
    },
    data() {
        return {}
    },
    components: {
        CollectionsSvg,
        TopicsSvg,},
    computed: {
        ...mapGetters({}),
    },
    mounted() {

    },
    methods: {},

}
</script>
