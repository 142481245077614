<template>
    <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: sanitization-->
    <span v-html="queryHtml"></span>
</template>

<script>
export default {
    name: "FormattedQuery",
    props: ['queryString'],
    computed: {
        queryHtml(){
            return this.queryHtmlForString(this.queryString);

        },
    },
    methods: {
        queryHtmlForString(string){
            if ( ! string ) return '';
            string = this.$strings.escapeHtml(string)
            return string
                .replace(/ AND /g, " <span class='text-success'>AND</span> ")
                .replace(/ OR /g, " <span class='text-info'>OR</span> ")
                .replace(/ NOT /g, " <span class='text-danger'>NOT</span> ");
        }
    }
}
</script>

<style scoped>

</style>
