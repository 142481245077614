<template>
    <div>
        <div class="d-flex">
            <filters 
                class="order-sm-1 order-2"  
                @setHasSuggestions="selectedHasSuggestions = $event" 
                @setHasNotesType="hasNotesType = $event" 
            />
<!--            <button -->
<!--                class="btn btn-secondary btn-sm ms-auto order-sm-2 order-1 mb-sm-0 mb-2"-->
<!--                @click="closeNotes"-->
<!--            >-->
<!--                Close Notes-->
<!--            </button>-->
        </div>

        <hr />

        <div class="row my-3">
            <has-notes-selector 
                :selected-has-suggestions="selectedHasSuggestions" 
                :has-notes-type="hasNotesType" 
                :has-suggestions-type="hasSuggestions.type"
                @setHasNotes="hasNotesId = $event"
                @setItemsWithNotes="groups = $event"
                class="col-md-3"
            />
            <notes-display 
                class="col-md"
                :groups="groups"
            />
        </div>
    </div>
</template>

<script>

import {mapGetters} from 'vuex';
import Filters from './Filters'
import HasNotesSelector from './HasNotesSelector'
import NotesDisplay from './NotesDisplay'

export default {
    components : {
        Filters, HasNotesSelector, NotesDisplay
    },
    data(){
        return {
            selectedHasSuggestions : false,
            hasNotesType : 'suggestions',
            hasNotesId : false,
            groups : []
        }
    },
    computed : {
        ...mapGetters({
            suggestions : 'suggestionsCache',
            authors : 'teamMembers',
            hasSuggestions : 'hasSuggestions'
        }),
        hasNotes(){
            switch(this.hasNotesType){
                case 'suggestions':
                return this.suggestions.find(s => s.id == this.hasNotesId)
                case 'authors':
                return this.authors.find(a => a.id == this.hasNotesId)
            }
        }
    },
    methods : {
        closeNotes(){
            this.$store.dispatch('toggleAccountNotesReader');
        },
    }
}
</script>

<style>
</style>
