<template>
    <div>
        <label class="no-user-select" style="display: none">
            <input type="checkbox" v-model="on" /> Auto-Post to Amplify
        </label>
        <label>
            Amplify Topics
        </label>
        <spinner v-if="!topicsLoaded" class="dropdown"></spinner>
        <amp-topics-selector class="mt-2" v-model="topics" :topics="allTopics" />
        <div class="mt-2">
            <label class="">
                <input type="checkbox" v-model="isPublished"/> Publish to advocates immediately
            </label>
            <label class="">
                <input type="checkbox" v-model="isEditable"/> Advocates can edit
            </label>
        </div>
        <div class="mt-2" v-if="features.amplifyExpiry">
            <label class="">
                Expires in (Days)
                <input type="number" v-model="expiryDays" step="1" min="1" class="form-control">
                <span class="small text-muted">Leave blank to never expire</span>
            </label>

        </div>
        <fieldset class="mt-2">
            <legend class="text-muted small">Post Text</legend>
            <label class="d-block"><input type="radio" class="form-check-input" v-model="summary" value="title" name="amplifySummary"> Title</label>
            <label class="d-block"><input type="radio" class="form-check-input" v-model="summary" value="summary,title" name="amplifySummary"> User summary if entered, title as backup</label>
            <label class="d-block"><input type="radio" class="form-check-input" v-model="summary" value="summary,excerpt" name="amplifySummary"> User summary if entered, excerpt as backup</label>
        </fieldset>

    </div>
</template>

<style>

</style>

<script>

    import AmpTopicsSelector from "@/components/Collections/AmplifyTopicSelector"
    import Spinner from "@/components/Controls/Spinner";
    import moment from 'moment'
    import {mapGetters} from "vuex";
    export default {
        components: {Spinner, AmpTopicsSelector},
        computed: {...mapGetters({features:'accountFeatures'})},
        props: {
            value: Object,
            allTopics: Array,
            topicsLoaded: Boolean
        },
        data() {
            return {
                on: false,
                isPublished: false,
                isEditable: false,
                topics: [],
                ...this.value,
                expiryDays: this.value  && this.value.expiryDays ? parseInt(this.value.expiryDays) : null,
                summary: this.value.summary || 'title',
            }
        },
        watch: {
            value() {
                Object.assign(this, this.value, {
                    summary: this.value.summary || 'title',
                    expiryDays: this.value.expiryDays
                });
            },

            on() { this.$emit('input', {...this.value, on: this.on })},
            isPublished() { this.$emit('input', {...this.value, isPublished: this.isPublished })},
            isEditable() { this.$emit('input', {...this.value, isEditable: this.isEditable })},
            topics() { this.$emit('input', {...this.value, topics: this.topics })},
            summary() { this.$emit('input', {...this.value, summary: this.summary })},
            expiryDays() {
                this.$emit('input', {
                    ...this.value,
                    expiryDays:  this.expiryDays? parseInt(this.expiryDays) : null
                })
            }
        }
    }
</script>
