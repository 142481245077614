<template>
    <div v-show="on && ! networkActivity" >
        <label>
            Lately Dashboard
        </label>
        <spinner v-if="!dashboardsLoaded" class="dropdown"></spinner>
        <vue-select :options="dashboards" v-model="dashboard" label="name" class="mt-2">
        </vue-select>
        <label>
            Lately Campaign
        </label>
        <vue-select :options="campaigns" v-model="campaign" label="name" class="mt-2">
        </vue-select>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PowerUpContainer from '@/components/Controls/PowerUpContainer.vue'
import {LatelyConnection} from "@/services/Lately";
import Spinner from '@/components/Controls/Spinner.vue';
import SelectId from '@/components/Controls/SelectId.vue';
import {VueSelect} from 'vue-select'
    export default {
        name: "LatelyPowerup",
        props: {
            value: {
                default: () => ({on: false, dashboard: null, campaign: null})
            },
            beforeAuth: Function,
            dashboards: Array,
            dashboardsLoaded: Boolean,
            visible: { type: Boolean, default: null },
            disableReason: String
        },
        components: {
            Spinner, PowerUpContainer, SelectId,VueSelect
        },
        computed: {
            ...mapGetters({
                currentAccountOverview: 'currentAccountOverview',
                apis: 'externalApis',
                features: 'features',
                isHootsuite: 'isHootsuite',
                learnUrls: 'learnUrls'
            }),
            service() {
                return new LatelyConnection(this)
            },
            show() {
                return this.features.lately && (this.service.isConnected(this.currentAccountOverview) || !this.isHootsuite);
            },
        },
        data() {
            return {
                on: false,
                campaign: false,
                campaigns: [],
                dashboard: false,
                networkActivity: false,
            }
        },
        methods: {
        },
        watch: {
            dashboard() {
                this.campaigns = (this.dashboard && this.dashboard.campaigns) || [];
                this.$emit('input', {...this.value, dashboard: this.dashboard && this.dashboard.id || null});
            },
            on() {
                this.$emit('input', {...this.value, on: this.on});
            },
            campaign() {
                this.$emit('input', {...this.value, campaign: this.campaign && this.campaign.id || null});
            },
            value() {
                this.on = this.value.on;
                this.dashboard = this.dashboards.find(c => c.id === this.value.dashboard);
                this.campaigns = (this.dashboard && this.dashboard.campaigns) || [];
                this.campaign = this.campaigns.find(c => c.id === this.value.campaign);
            }
        }
    }
</script>

<style scoped>

</style>
