
<script>
    import {formatTag} from "@/services/entity-tags";
    import PublisherList from "@/components/FilteringAndSorting/PublisherList";
    import {Filters} from "../../models/Filters";

    export default {
        mixins: [PublisherList],
        methods:{
            async fetchSettings(type, id) {
                return this.$api.get(`/${type}s/${id}/settings`, {})
            },
            async fetchLastFilterSettings(type, id, currentFilters={}) {
                if (type && id && type !== 'account') {
                    let [settings, sources, tagData] = (await Promise.all([
                        this.fetchSettings(type, id)
                    ])).map(response => response.data);

                    if (settings.filters) {
                        return Filters.fromUi({
                            ...settings.filters,
                            sources: !settings.filters.includeNewSources ? settings.filters.sources : null,
                            excludedSources: settings.filters.includeNewSources ? settings.filters.excludedSources : null,
                            includeAllSources:
                                settings.filters.includeNewSources
                                && !(settings.filters.excludedSources && settings.filters.excludedSources.length)
                        }, false, false)
                    }
                }

                return {
                    age: type === 'collection' ? -1 : 30,
                    time: 'Forever',
                    contentType: 'All',
                    favorites: false,
                    sortBy: 'Recency',
                    evergreen: true,
                    // sources: Object.keys(sources),
                    // excludedSources: []
                }
            },
        }
    }
</script>
