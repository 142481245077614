import {StringUtils as $strings} from "../utilities/Strings";
import {currentFilterVersion} from "../Constants";

export function validateForCollection(filters) {
    let isRelevance = filters.sort.toLowerCase() === 'relevance';
    let messages = []
    if (isRelevance)
        messages.push(`Relevance sort can't be used for filters applied to a collection.`)
    return messages;
}

export function validateForTopic(filters) {
    let isAddedSort = filters.sort.toLowerCase() === 'added';
    let isUpdatedSort = filters.sort.toLowerCase() === 'update';
    let missingAge = filters.age === -1;
    // let missingLimit = !filters.limit || filters.limit > 100;

    let messages = []
    if (isAddedSort)
        messages.push(`Added sort can't be used for filters applied to a topic.`)
    if (isUpdatedSort)
        messages.push(`Updated sort can't be used for filters applied to a topic.`)
    if (missingAge)
        messages.push(`Age is required for filters applied to a topic.`)
    // if (missingLimit)
    //     messages.push(`Limit is required for filters applied to a topic.`)
    return messages;
}

export function filterString(filters) {
    let includeFields = [
        'sortBy',
        'required',
        'displayTime',
        'undated',
        'contentType',
        'favorites',
        'includeNewSources',
        'limit'
    ];
    let mapNames = {
        'sortBy': 'sort',
        'contentType': 'type',
        displayTime: 'time',
        'includeNewSources': 'new sources'
    };

    let excludeNewOnlyIfAllChecked = ([name]) =>
        !((name === 'includeNewSources') && filters.includeAllSources);

    let excludeFilterSetToAll = ([, value]) =>
        value != 'All' && value != '-1' && value != 'all';

    let formatFilterDisplay = ([name, value]) =>
        `${$strings.capitalize(name)}: ${$strings.capitalizeWords(formatFilterValue(value))}`;

    let formatFilterValue = value =>
        Array.isArray(value) ? value.join(', ')  :
            new String(value);

    return filters && Object
        .entries(filters)
        .filter(([, value]) => value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0))
        .filter(excludeNewOnlyIfAllChecked)
        .filter(([name]) => includeFields.includes(name))
        .map(([name, value]) => [mapNames[name]||name, value])
        .filter(excludeFilterSetToAll)
        .map(formatFilterDisplay)
        .concat(sourceFilterString(filters))
        .concat(tagsFilterString(filters))
        .filter(x=>x)
        .join(', ') || ''
}

export function sourceFilterString(filters) {
    return (filters && (
        filters.includeAllSources ? '' : sourceFilterSome(filters)
    ) || '' )
}

export function sourceFilterSome(filters) {
    return filters.includeNewSources ?
        `Exclude: ${formatSources(filters.excludedSources)}`:
        `Include: ${formatSources(filters.sources)}`
}
export function tagsFilterString(filters) {
    return filters && filters.tags && filters.tags.length && someTagsDisplay(filters)
}

export function someTagsDisplay(filters) {
    return `${filters.tags.length} tag criteria`
}

export function formatSources(sources) {
    if (!sources) return '';
    let verbose = sources.join(', ');
    if (verbose.length < 30) return verbose;
    else return `${sources.length} Sources`
}

export let defaultTopicFilters = {
    version: currentFilterVersion,
    contentType: 'All',
    sortBy: 'Relevance',
    time: '2 Months',
    age: 60,
    customTime: '',
    geography: ['US'],
    excludedSources: [],
    includeNewSources: true,
    evergreen: false,
    tags: [],
    sources: null,
    minPredictedPerformance: 0,
    minFullTextLength: 0,
    excludePaywalled: false,
}

export let defaultCollectionFilters = {
    version: currentFilterVersion,
    contentType: 'All',
    sortBy: 'Added',
    time: 'Forever',
    age: -1,
    customTime: '',
    geography: ['US'],
    sources: null,
    excludedSources: [],
    includeNewSources: true,
    tags: [],
    evergreen: true,
    minPredictedPerformance: 0,
    minFullTextLength: 0,
    excludePaywalled: false,
}

export function defaultFilters(type) {
    switch (type) {
        case "topic":
            return defaultTopicFilters;
        case "collection":
            return defaultCollectionFilters;
    }
    return {}
}
