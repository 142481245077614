<template>
    <div class="powerup details" :class="{hide: visible === false, open}">
        <div @click.prevent="toggleOpen" class="summary">

            <div class="power-up-label d-flex justify-content-between align-items-center mb-2">
                <div class="text-nowrap">
                    <i :class="iconClass" class="me-1"/>
                    <span>{{ label }}</span>
                </div>
                <div v-if="!disableReason" class="powerup-action">
                    <toggle-switch v-if="authorized && canTurnOnOff"
                                   v-model="val"
                                   class="powerup-toggle"
                                   :aria-label="`Connected to ${label}`"/>
                    <connect-button v-if="!authorized"
                                    :service="service"
                                    :team="team"
                                    :beforeAuth="beforeAuth"></connect-button>
                </div>
            </div>
            <div class="text-muted small text-wrap">
                <div class="mb-1">{{ description }}</div>
                <a @click.stop :href="learnMoreUrl" v-if="learnMoreUrl" target="_blank">Learn More</a>
            </div>
            <div class="error powerup-message" v-if="disableReason">{{ disableReason }}</div>
        </div>
        <div v-if="authorized" v-show="open" class="ps-3 pe-3">
            <slot/>
        </div>
    </div>
</template>

<style scoped>
.power-up-label .icon-dynamic {
    vertical-align: top;
    margin-top: .2em;
}

.power-up-label .amplify-icon, .power-up-label .sniply-icon {
    margin-top: .25em;
}

.power-up-label {
    display: inline-block;
}

.power-up-label a {
    text-decoration: underline;
}

.powerup-action {

}

.powerup .summary {
    background: var(--bs-light);
    border-bottom: 1px solid var(--bs-default);
    list-style: none;
    list-style-type: none;
    color: var(--bs-dark);
    position: relative;
}

.details.powerup .summary::-webkit-details-marker {
    display: none;
}

.powerup {
    border: 1px solid var(--bs-default);
}

.powerup.open {
    padding-bottom: 1em;
}


.powerup.open .summary {
    margin-bottom: 10px;
}

.powerup:not(:last-child) {
    border-bottom: none;
}

.powerup-message {
    display: inline-block;
    color: var(--bs-danger);
    font-size: 0.7em;
    max-width: 360px;
    margin-left: 10px;
    vertical-align: middle;
    white-space: pre;
}

.powerup .summary {
    vertical-align: middle;
}

.summary {
    padding: 0.5em;
}
</style>

<script>
import ToggleSwitch from '@/components/Controls/ToggleSwitch'
import ConnectButton from '@/components/Settings/Team/ConnectDisconnectButton'

export default {
    components: {ToggleSwitch, ConnectButton},
    props: {
        label: String,
        description: String,
        learnMoreUrl: String,
        iconClass: [String, Object],
        value: Boolean,
        canTurnOnOff: {type: Boolean, default: true},
        service: Object,
        team: Object,
        beforeAuth: Function,
        visible: {type: Boolean, default: null},
        disableReason: {type: [String, Boolean], default: ''}
    },
    data() {
        return {
            val: this.value,
            open: this.value && this.isAuthorized()
        };
    },
    watch: {
        value() {
            // console.log('powerup.active.changed', this.value, this.authorized)
            this.val = this.value;
            this.open = this.value && this.authorized;
        },
        val() {
            this.$emit('input', this.val);
            this.open = this.val && this.authorized;
        },
        authorized() {
            this.open = this.val && this.authorized;
        },
        team() {
            this.open = this.val && this.authorized;
        }
    },
    computed: {
        authorized() {
            return this.isAuthorized();
        },
        ready() {
            return this.service
        }
    },
    methods: {
        isAuthorized() {
            return !!this.service && !!this.service.isConnected(this.team)
        },
        toggleOpen() {
            // if (['span', 'div', 'summary'].includes(event.target.tagName)) {
            //     event.preventDefault()
            // }
            // this.open=this.authorized && this.val &&!open
        }
    }
}
</script>
