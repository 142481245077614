<template>
    <li class="integration-item">
        <span class="integration-item--name-logo">
            <i :class="`integration-logo icon-medium ${icon}-icon`"></i>
            <span class="integration-name">{{title}}</span>
        </span>
        <slot />
    </li>
</template>

<style>
    .integration-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .integration-features {
        width: 100%;
        flex-shrink: 0;
    }
    .integration-list>li {
        border-top: 1px solid var(--bs-default);
        vertical-align: middle;
    }
    .integration-list>li:last-child {
        border-bottom: 1px solid var(--bs-default);
    }
    .integration-logo {
        margin: 15px 10px 15px 0;
        vertical-align: middle;
    }
    .integration-list .btn{
        margin: 15px 0;
        vertical-align: middle;
    }
    /*.integration-list .integration-feature-tags {*/
        /*margin: 5px 0;*/
        /*vertical-align: middle;*/
    /*}*/
    /*.integration-list label {*/
        /*margin: 22px 0;*/
        /*vertical-align: middle;*/
    /*}*/
    .integration-name {
        vertical-align: middle;
    }
</style>

<script>
    export default {
        props: ['icon', 'title'],
        mounted() {
            this.$children.forEach(x => {
                x.$el.classList.add('float-end');
                if (x.$el.classList.contains('btn'))
                    x.$el.classList.add('btn-small')
            })
        }
    }
</script>
