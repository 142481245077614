<template>
    <div v-if="currentFilters">
        <div class="filter-section" v-if="languageSupportsContentType">
            <content-filter />
        </div>
        <div class="filter-section">
            <sort-filter />
        </div>
        <div class="filter-section">
            <age-filter />
        </div>
        <!-- <div class="filter-section">
            <geography-filter />
        </div> -->
        <div v-if="features.tags" class="filter-section">
            <tags />
        </div>
        <div v-if="!showFavoriteSuggestions" class="filter-section">
            <sources-filter />
        </div>
    </div>
</template>

<script>


import SourcesFilter from './SuggestionFilters/Sources'
import GeographyFilter from './SuggestionFilters/Geography'
import AgeFilter from "./SuggestionFilters/Age"
import SortFilter from './SuggestionFilters/Sort'
import ContentFilter from './SuggestionFilters/Content'
import Tags from './SuggestionFilters/Tags'
import { mapGetters } from 'vuex';
import { languagesSupportingContentType } from '@/Constants'

export default {
    computed : {
        ...mapGetters(['showFavoriteSuggestions', 'currentFilters', 'features', 'hasSuggestions', 'currentTopic']),

        languageSupportsContentType() {
            if (!this.currentTopic) return true;
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },

        topicLanguage() {
            if (!this.currentTopic) return null;
            if (this.hasSuggestions.type !== 'topic') return null;
            let culture = this.currentTopic.culture;
            return Boolean(culture) && culture.split('-')[0]
        },
    },
    components : {
        SourcesFilter, GeographyFilter, AgeFilter, SortFilter, ContentFilter, Tags
    }
}
</script>

<style>
</style>
