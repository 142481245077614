<template>
    <div>
        <div class="password-field-container">
            <span class="toggle-show" @click="showingPassword = !showingPassword">
                <span v-if="showingPassword">
                    <i class="fa fa-eye-slash"></i> Hide
                </span>
                <span v-else>
                    <i class="fa fa-eye"></i> Show
                </span>
            </span>
            <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: no user entered text-->
            <span class="password-strength-display" v-if="password.length" v-html="passwordStrengthHTML"></span>                        
            <input 
                :type="showingPassword ? 'text' : 'password'"
                class="form-control ux-password-field"
                v-model="password"
                ref="passwordField"
                @keyup="update"
                placeholder="Password"
                :required="required"
                @keyup.enter="$emit('enterPressed')"
            />
        </div>
        <div class="text-end">
            <password-generator :password="password" :score="passwordScore" class="mt-3" @passphraseGenerated="password = $event; showingPassword = true; update();" />
        </div>
    </div>
</template>

<script>

import zxcvbn from 'zxcvbn'
import PasswordGenerator from "@/components/DataManagement/PasswordGenerator"

export default {
    props: {
        value: {},
        required: {type: Boolean, default: false},
    },
    components : {
        PasswordGenerator
    },
    data(){
        return {
            password : '',
            showingPassword : false
        }
    },
    computed : {
        passwordScore(){
            return zxcvbn(this.password).score
        },
        passwordStrengthHTML(){
            var textClass = 'danger';
            var text = "weak"
            var icon = "remove"
            
            switch(this.passwordScore){
                case 0:
                case 1:
                    textClass = "danger"
                    text = "weak"
                    icon = "remove"
                break;
                case 2:
                    textClass = "warning";
                    text = "moderate"
                    icon = "warning"
                break;
                case 3:
                case 4:
                    textClass = "success"
                    text = "strong"
                    icon = "check"
                break;
            }

            return `<span class="text-${textClass}"><i class="fa fa-${icon}"></i> ${text}</span>`;
        },
    },

    watch :{
        value(){
            this.password = this.value;
        }
    },
    mounted(){

    },
    methods : {
        update(){
            this.$emit('input', this.password)
        }
    }
}
</script>

<style scope>

.password-field-container {
    position: relative;
}
.password-field-container .form-control {
    padding-right: 126px;
}

.toggle-show {
    position: absolute;
    display: block;
    cursor: pointer;
    right: 5px;
    top: 12px;
    font-size: 10px;
}
.password-strength-display {
    position: absolute;
    right: 50px;
    top: 12px;
    font-size: 10px;
}
</style>
