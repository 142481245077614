<template>
    <div v-if="topic">
        <div v-if="networkActivity">
            {{networkActivity}}
        </div>
        <div v-else>
            <div 
                class="query-input" 
                v-for="(query, index) in topic.queries" :key="'qb-i-'+index">
                
                <div class="row">
                    <div class="col-10 textarea-container">
                        <span class="characters-remaining text-muted small">{{(134 - query.value.length)}}</span>
                        <textarea 
                            v-if="query.editing"
                            class="form-control"
                            v-model="query.value"
                            placeholder="Enter a query or use the builder below"
                            maxlength="134"
                            rows="3"
                        ></textarea>
                        <formatted-query v-else :queryString="query.value"></formatted-query>
                    </div>
                    <div class="col-2 d-flex justify-content-end align-items-center">
                        <button 
                            @click="toggleQueryEditing(query)" 
                            class="btn btn-sm"
                            :class="{'btn-default' : !query.editing, 'btn-white text-success' : query.editing}"
                            type="button"
                        >
                            <i class="fa fa-edit"></i>
                        </button>
                            <button 
                            @click="removeQuery(query)" 
                            class="btn btn-sm btn-default ms-1"
                            type="button">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>

            <h5 class="text-steel-0 font-size-17">Each result must contain ALL of these terms</h5>
            <query-builder-term-entry 
                v-model="alls"
                placeholder="Type in a term and press enter"
                ref="primaryInput"
            />

            <h5 class="text-steel-0 font-size-17">Each result must contain at least ONE of these terms</h5>
            <query-builder-term-entry 
                v-model="ors"
                placeholder="Type in a term and press enter"
            />

            <h5 class="text-steel-0 font-size-17">Each result must NOT contain any these terms</h5>
            <query-builder-term-entry 
                v-model="nots"
                placeholder="Type in a term and press enter"
            />

            <div>
                <div class="d-flex align-items-center">
                    <h5 class="flex-2 text-steel-0 font-size-18 top-2"><strong>Topic&nbsp;Name</strong></h5>
                    <input class="form-control ms-3" :class="{'is-invalid' : !topic.name}" placeholder="What should we call this topic?" v-model="topic.name" />
                </div>

            </div>

            <div class="alert alert-gold mt-3" v-if="queryError">
                {{queryError}}
            </div>

            <div v-if="networkError" class="alert alert-gold mt-3">
                {{networkError}}
            </div>
        </div>
    </div>
</template>

<script>

import QueryBuilderTermEntry from './TermEntryField'
import * as $ from 'jquery'
import Vue from 'vue'
import Base from './BaseBuilder'
import FormattedQuery from "./FormattedQuery";

export default {
    mixins : [Base],
    components : {FormattedQuery, QueryBuilderTermEntry},
    mounted(){
        if (this.topic.queries.length){
            this.fetchParsedTopicQueries();
        }
    },
    methods : {
        fetchParsedTopicQueries(){
            this.networkActivity = "Parsing query...";
            let lastQuery = this.topic.queries[this.topic.queries.length - 1]
            
            this.$api.post(`/accounts/${this.account.id}/query`, {format: 'builder', query : lastQuery.value}, {ignoreCache: true})
            .then(response => {
                this.networkActivity = false;
                let latestQuery = response.data.query
                
                let string = this.queryStringForSet({alls : latestQuery.all_of, ors : latestQuery.any_of, nots : latestQuery.none_of});

                if (latestQuery.remainder.length > 0 || string.length > 134){
                    this.toggleQueryEditing(this.topic.queries[(this.topic.queries.length - 1)]);
                } else {
                    this.nots = latestQuery.none_of;
                    this.ors = latestQuery.any_of;
                    this.alls = latestQuery.all_of;
                    this.topic.queries.pop();
                }
                
            })
            .catch((e) => {
                this.networkActivity = false;
                if (e.response && e.response.data){
                    if (e.response.data.description == 'Query length exceeds limit') {
                        this.toggleQueryEditing(this.topic.queries[(this.topic.queries.length - 1)]);
                    } else {
                        this.networkError = e.response.data.description;
                    }
                }
            });
        },
        addEmptyQuery(){
            this.topic.queries.push({value : '', editing : true});
            Vue.nextTick(() => {
                let input = $(".query-input textarea").last();
                if (input)
                    input.focus();
            })
        },
        removeQuery(query){
            if (query.value){
                let x = confirm("Are you sure you want to delete this query? This cannot be undone");
                if (!x)return
            }
            this.topic.queries.splice(this.topic.queries.indexOf(query), 1)
        },
        toggleQueryEditing(query){
            this.topic.queries.splice(this.topic.queries.indexOf(query), 1, {...query, editing : !query.editing});
        },
        restart(){
            //Nothing.
        }
    }
}
</script>

<style scoped>
.progress {
    flex: 1;
}
.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}
.query-input {
    font-size: 70%;
    margin-bottom:1.8em;
    border-bottom: 1px solid #aeaeae;
}

.query-input .textarea-container {
    position: relative;
}

.query-input .textarea-container .characters-remaining {
    position: absolute;
    right: 23px;
    bottom: 23px;
}

.query-input textarea {
    border: 1px solid rgba(0,0,0,0.1);
    background: white;
    box-shadow: 0px 0px 6px rgba(97, 90, 109, 0.1);
    resize: none;
}

.query-input button, .query-input textarea {
    margin-bottom: 1em;    
}
</style>
