
<script>
    import {filterQueryString} from "@/services/RssLinks";
    import {mapGetters} from "vuex";

    export default {
        data(){
            return {
                limit: 100
            }
        },
        computed: {
            ...mapGetters({
                accountFeatures: 'accountFeatures',
                feedsDomain: 'feedsDomain'
            }),
            includeAllSources() {
                return this.includeNewSources && !(this.excludedSources && this.excludedSources.length)
            },
            filterQueryString() {
                let sourceFilters = {
                    sources: !this.includeNewSources ? this.selectedSources : null,
                    excludedSources: this.includeNewSources ? this.excludedSources : null
                }
                return filterQueryString({...this.filters, ...sourceFilters} , this.showingFavoriteSuggestions, this.includeAllSources, this.includeNewSources);
            },
            feedUrl() {
                let type = this.hasSuggestions.type;
                let id = this.hasSuggestions.id;
                let ext = this.extension || 'atom';
                if (type)
                    if (type === 'collection' && this.accountFeatures.newFeedUrl)
                        if (this.filterParameterMode === 'filterView' && this.filterViewId)
                            return `https://${this.feedsDomain}/collections/${id}.${ext}?filter_view=${this.filterViewId}`;
                        else
                            return `https://${this.feedsDomain}/collections/${id}.${ext}?${this.filterQueryString}`
                    else
                        return `https://${this.feedsDomain}/suggestions?${type}=${id}&${this.filterQueryString}`
            },
        },
    }
</script>
