<template>
    <div class="pt-4 ps-4 pe-4 container">
        <div class="row">
            <div class="col col-12 col-md-6 col-lg-4">
                <sniply-invite />
                <form class="form m-3 mt-4">
                    <h4>Account Actions</h4>
                    <hr>
                    <label class="d-block">
                        Accounts
                        <select class="form-control form-select" v-model="selectedAccountId">
                            <option v-for="a in accounts" :key="a.id" :value="a.id">{{ a.name }}</option>
                        </select>
                    </label>
                    <label class="d-block">
                        Account ID
                        <input class="form-control" v-model="accountId"/>
                    </label>
                    <button class="btn btn-primary full-width" disabled>Eventually Clear Cache Someday</button>
                </form>
            </div>
            <div class="col col-12 col-md-6 col-lg-8">


                <details>
                    <summary>Available Subscription Limits (integers)</summary>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>Singular</th>
                            <th>Plural</th>
                            <th>Default</th>
                            <th>Per Period</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="x in limitList" :key="x.key">
                            <td>{{ x.key }}</td>
                            <td>{{ x.singular }}</td>
                            <td>{{ x.plural }}</td>
                            <td>{{ x.default }}</td>
                            <td>{{ x.per_period }}</td>
                        </tr>
                        </tbody>
                    </table>
                </details>

                <details>
                    <summary>Available Subscription Features (true|false)</summary>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>Default</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="x in featureList" :key="x.key">
                            <td>{{ x.key }}</td>
                            <td>{{ x.default }}</td>
                        </tr>
                        </tbody>
                    </table>
                </details>

                <details>
                    <summary>Learn Urls</summary>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>URL</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="x in learnMoreUrlList" :key="x.key">
                            <td>{{ x.key }}</td>
                            <td>{{ x.url }}</td>
                        </tr>
                        </tbody>
                    </table>
                </details>

                <details>
                    <summary>Feature Toggles</summary>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>URL</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="x in featureListCode" :key="x.key">
                            <td>{{ x.key }}</td>
                            <td>{{ x.state }}</td>
                        </tr>
                        </tbody>
                    </table>
                </details>

                <details>
                    <summary>Query Parameter Mappings</summary>
                    <form @submit.prevent="handleUpdateParamMappingClick" class="pt-3 px-2">
                        <div class="mb-2">
                            <div class="mb-3">
                                <label for="googleSheetsURL" class="d-block">
                                    Google Sheets URL
                                    <input id="googleSheetsURL" class="form-control"
                                        v-model="paramMappingState.sheetURL"
                                        :readonly="paramMappingState.isUpdating"
                                        type="url"
                                        required
                                        placeholder="https://docs.google.com/spreadsheets/..."
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-8">
                                <div class="alert alert-lightss" role="alert"
                                    v-if="paramMappingState.isUpdating">
                                    <h5 class="alert-heading text-clay-100 font-size-15">Updating ...</h5>
                                </div>
                                <div class="alert alert-info" role="alert"
                                    v-if="!paramMappingState.isUpdating && paramMappingState.updateResult">
                                    <h5 class="alert-heading font-size-18">Update successful</h5>
                                    <p class="mb-1">Following mappings have been updated succesfully.</p>
                                    <hr class="mt-1">
                                    <p class="mb-0 font-monospace" 
                                        v-for="title in paramMappingState.updateResult.updated_mappings" :key="title">
                                        {{ title }}
                                    </p>
                                </div>
                                <div class="alert alert-danger" role="alert"
                                    v-else-if="!paramMappingState.isUpdating && paramMappingState.updateError">
                                    <h5 class="alert-heading font-size-18">Update error</h5>
                                    <hr class="mt-1">
                                    <code>
                                        <pre>{{ JSON.stringify(paramMappingState.updateError, null, 2) }}</pre>
                                    </code>
                                </div>
                            </div>
                            <div class="col col-4 text-md-end">
                                <button class="btn btn-primary" :disabled="paramMappingState.isUpdating">Update mappings for<br>"{{currentAccountOverview.name}}"</button>
                            </div>
                        </div>
                    </form>
                </details>
                <details>
                    <summary>Import/Export</summary>
                    <div class="d-flex flex-wrap">
                        <export-config-file extension="yaml"/>
                        <export-config-file extension="json"/>
                        <import-config-file>

                        </import-config-file>
                    </div>
                </details>
                <amplify-topic-import ref="amplifyTopicModal"/>
                <button class="btn btn-secondary full-width" type="button" @click="$refs.amplifyTopicModal.show">Open Amplify Import</button>
            </div>
        </div>
        <div>
            <h4>Publisher Parsing Config</h4>
            <ParsingConfig></ParsingConfig>
        </div>
    </div>
</template>

<script>
import SniplyInvite from "@/components/Integrations/Sniply/SniplyInvite";
import SniplyCampaignAssign from "@/components/Integrations/Sniply/SniplyCampaignAssign";
import {mapGetters} from 'vuex'
import {features} from "@/Features";
import ParsingConfig from "../components/Admin/ParsingConfig";
import ExportConfigFile from "@/components/Admin/ExportConfigFile";
import ImportConfigFile from "@/components/Admin/ImportConfigFile";
import AmplifyTopicImport from "@/components/Admin/AmplifyTopicImport.vue";

export default {
    components: {
        AmplifyTopicImport,
        ImportConfigFile, ExportConfigFile, ParsingConfig, SniplyCampaignAssign, SniplyInvite},
    data() {
        return {
            subscriptionFeatures: {},
            subscriptionLimits: {},
            paramMappingState: {
                sheetURL: null,
                isUpdating: false,
                updateResult: null,
                updateError: null
            },
            amplifyImportOpen: false,
            selectedAccountId: (this.currentAccountOverview && this.currentAccountOverview.id) || null,
            accountId: (this.currentAccountOverview && this.currentAccountOverview.id) || null
        }
    },
    async mounted() {
        try {
            let result = await this.$api.get('/features/subscription')
            this.subscriptionFeatures = result.data.features
            this.subscriptionLimits = result.data.limits
        } catch (e) {
            console.error('Error fetching subscription details', e)
        }
    },
    methods: {
        async handleUpdateParamMappingClick() {
            if (!confirm(
                    `About to update and reset mappings for account:\n` +
                    `"${this.currentAccountOverview.name}". Continue?`
                )) return
            const { paramMappingState: state } = this
            state.isUpdating = true
            state.updateResult = null
            state.updateError = null
            try {
                let result = await this.$api.post(
                    `/accounts/${this.currentAccountOverview.id}/sniply/param-mappings`,
                    { sheet_url: state.sheetURL }
                )
                state.updateResult = result.data
            } catch (error) {
                console.log('Error updating param mapping', error)
                state.updateError = error
            } finally {
                state.isUpdating = false
            }
        }
    },
    computed: {
        ...mapGetters({
            learnUrls: 'learnUrls',
            accounts: 'allAccounts',
            currentAccountOverview: 'currentAccountOverview',
        }),
        featureList() {
            return Object.entries(this.subscriptionFeatures || {}).map(([key, value]) => ({
                key,
                default: null,
                ...value,
            }))
        },
        limitList() {
            return Object.entries(this.subscriptionLimits || {}).map(([key, value]) => ({
                key,
                singular: '', plural: '', default: null, per_period: null,
                ...value,
            }))
        },
        learnMoreUrlList() {
            return Object.entries(this.learnUrls).map(([key, url]) => ({key, url}))
        },
        featureListCode() {
            return Object.entries(features).map(([key, state]) => ({key, state}))
        }
    },
    watch: {
        selectedAccountId() {
            this.accountId = this.selectedAccountId
        },

        currentAccountOverview() {
            this.selectedAccountId = this.currentAccountOverview && this.currentAccountOverview.id || null;
        }
    }
}
</script>
