<template>
    <main id="gallery-edit-page">
        <gallery-builder v-model="gallery" v-show="!loading" :site="site"></gallery-builder>
        <spinner v-if="loading"></spinner>
    </main>
</template>

<style>

</style>

<script>
    import GalleryBuilder from '@/components/Galleries/GalleryBuilder'
    import {mapGetters} from 'vuex'
    import {newGallery} from '@/types/Gallery.js'
    import {Filters} from "@/models/Filters";
    import Spinner from "@/components/Controls/Spinner";
    export default {
        components: {Spinner, GalleryBuilder},
        props: [
            'id'
        ],
        watch: {
            id() {
               this.loadGallery(this.id);
            }
        },
        data() {
            return {
                gallery: null,
                loading: false,
                site: null
            }
        },
        mounted() {
            this.loadGallery(this.id);
        },
        methods: {
            async loadGallery(id) {
                if (id) {
                    this.gallery = await this.$api.get(`/galleries/${id}`)
                        .then(r => r.data)
                        .then(g => ({
                            ...g,
                            stagingDomain: g.staging_domain,
                            collections: g.collections.map(c => ({
                                ...c,
                                sourceCollectionId: c.collection_id,
                                filters: Filters.fromApi(c.filters)
                            }))
                        }))
                    this.site = { id: this.gallery.site_id }
                } else{
                    this.gallery = newGallery(id);
                    this.site = { id: this.$route.query.siteId }
                }


            }
        }
    }
</script>
