import {knownTags} from "@/Constants";

export function formatTag(t) {
    t = Object.assign({}, t);
    t.iconUrl = t.icon_url;
    t.iconClass = t.icon_class;
    t.tagClass = t.tag_class;

    if (knownTags[t.key]) {
        return Object.assign({}, t, knownTags[t.key])
    } else return t;
}

async function fetchTagList(type, id) {
    let [accountTags, contentSetTags] = await Promise.all([
        $app.api.get(`/accounts/${this.account.id}/tags`),
        $app.api.get(`/${type}s/${id}/tags`).catch(() => {
        }).then(() => ({data: {tags: []}}))
    ]).then(responses => responses.map(resp => (resp.data.tags || []).map(formatTag)));
    // console.log('tags acquired', accountTags, contentSetTags)
    accountTags.forEach(tag => {
        let inContentSet = contentSetTags.find(x => x.key === tag.key)
        tag.count = inContentSet && inContentSet.count || 0;
    })
    return accountTags
}
