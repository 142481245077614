<template>
    <uc-select :value="value"
               @input="onInput"
               :items="options"
               class="content-source-type"
               ref="sel"
    >

    </uc-select>
</template>

<style>
    .content-source-type.vs--single.vs--open .vs__selected {
        display: block;
        position: static ;
    }
</style>

<script>
    import UcSelect from '@/components/Controls/UpcontentSelect';
    import {mapGetters} from 'vuex';

    export default {
        components: { UcSelect },
        props: {
            value: String
        },
        computed: {
            ...mapGetters({
                features: 'features'
            }),
            // zestCollectionSource()  { return this.features.zestCollectionSource },
            options() {
                return [
                    { value: 'rss', description: 'RSS', iconClass: 'fa fa-rss'},
                    { value: 'collection', description: 'Collection', iconClass: 'fa fa-bolt'},
                    { value: 'topic', description: 'Topic', iconClass: 'fa fa-th-list'},
                    // ...(this.zestCollectionSource ? [{ value: 'special:collection:zest topic', description: 'Zest', iconClass: 'zest-icon icon-dynamic'}] : [])
                ]
            }
        },
        methods: {
            onInput(val) {
                this.$emit('input', val)
            }
        },
        watch: {
            value(){
                document.querySelectorAll('.vs_selected').forEach(el => {
                    let p = el;
                    while (p.parentElement && p.parentElement !== this.$el) {p=p.parentElement}
                    if (p === this.$el)
                        el.addEventListener('click', () => {
                            this.$refs.sel.open()
                        })
                })
            }
        },
        mounted() {
            document.querySelectorAll('.vs_selected').forEach(el => {
                let p = el;
                while (p.parentElement && p.parentElement !== this.$el) {p=p.parentElement}
                if (p === this.$el)
                    el.addEventListener('click', () => {
                        this.$refs.sel.open()
                    })
            })
        }
    }
</script>
