<template>
    <div>
        <h4>Card Information for {{ account.name }}</h4>

        <hr/>

        <div class="progress" v-if="networkActivity">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
                 role="progressbar"
                 aria-valuenow="75"
                 aria-valuemin="0"
                 aria-valuemax="100"
                 style="width: 100%"></div>
        </div>


        <div v-if="currentCard">
            <credit-card-display :card="currentCard" @removeCard="removeCurrentCard"/>
        </div>

        <div v-if="noCard">
            <h5>We currently don't have a card on file for you. </h5>
            <p>Add a card to upgrade your plan.</p>
            <div class="cc-card-entry-wrapper">
                <credit-card-entry
                    :account="account"
                    @cardAdded="cardAdded"
                    @savingCard="networkActivity = true"
                    @cardError="handleError"
                    @focus="networkError = false"
                />
            </div>

        </div>

        <div class="alert alert-danger" v-if="networkError">
            {{ networkError }}
        </div>

    </div>
</template>

<script>

import CreditCardEntry from '@/components/Payments/CreditCardEntry'
import CreditCardDisplay from '@/components/Payments/CreditCardDisplay'

export default {
    props: ['account'],
    components: {CreditCardEntry, CreditCardDisplay},
    data() {
        return {
            currentCard: false,
            noCard: false,
            networkActivity: false,
            networkError: false
        }
    },
    watch: {
        account() {
            this.fetchCard()
            this.currentCard = false;
        }
    },
    mounted() {
        this.fetchCard()
    },
    methods: {
        cardAdded({card}) {
            this.currentCard = card
            this.noCard = false
            this.networkActivity = false;
            this.networkError = false
        },
        fetchCard() {
            this.networkActivity = true;
            this.$api.get(`/accounts/${this.account.id}/payment-method`)
                .then(response => {
                    this.cardAdded({card: response.data})
                })
                .catch(error => {
                    this.handleError({error})
                })
        },
        removeCurrentCard() {
            this.networkActivity = true
            this.$api.delete(`/accounts/${this.account.id}/payment-method`).then(() => {
                this.currentCard = false
                this.noCard = true;
                this.networkActivity = false;
            })
        },
        handleError({error}) {
            this.networkActivity = false;
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        this.noCard = true;
                        break
                }
            } else {
                this.networkError = "We cannot process your card at this time :("
            }

            this.currentCard = false
        }
    }
}
</script>

<style lang="scss">
@media (min-width: 576px) {
    .cc-card-entry-wrapper {
        width: 24em;
    }
}
</style>
