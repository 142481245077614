<template>
    <div class="d-flex-sm d-block">
        <div class="btn-group btn-group-sm me-2 mb-2 mb-lg-0 dropdown" role="HasNotesSelector">
            <button class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                {{ toCapitalized(hasSuggestions.type + "s") }}
                (<span v-if="selectedHasSuggestions" v-text="$strings.truncate(selectedHasSuggestions.name, 35)"></span>)
            </button>
            <div class="dropdown-menu">
                <div class="p-3" v-if="selectableHasSuggestions.length > 10">
                    <input v-model="hasSuggestionsSearch" class="form-control input-sm" placeholder="Search..."/>
                </div>
                <a v-if="hasSuggestions.type === 'topic'"
                   href="#"
                   @click.prevent="setSuggestionViewerType('collections')"
                   class="dropdown-item small">
                    <collections-svg class="multiple em-1"/>
                    Switch to Collections
                </a>
                <a v-if="hasSuggestions.type === 'collection'"
                   href="#"
                   @click.prevent="setSuggestionViewerType('topics')"
                   class="dropdown-item small">
                    <topics-svg class="multiple em-1"/>
                    Switch to Topics
                </a>
                <a class="dropdown-item"
                   href="#"
                   @click.prevent="selectedHasSuggestions = hasSuggestions"
                   v-for="hasSuggestions in pagedHasNotes"
                   :key="'has-notes-link-'+hasSuggestions.id">
                    <span v-text="$strings.truncate(hasSuggestions.name, 35)"></span>
                    <i class="fa fa-check ms-1"
                       v-if=" selectedHasSuggestions && hasSuggestions.id === selectedHasSuggestions.id"/>
                </a>

                <div class="align-center dropdown-item pt-3"
                     :class="{'d-none' :  filteredSelectableHasNotes.length < 11, 'd-flex' : filteredSelectableHasNotes.length > 10 }">
                    <paginator
                        :size="10"
                        class="mx-auto"
                        name="HasNotesSelector"
                        :small="true"
                        :items="filteredSelectableHasNotes"
                        @update="pagedHasNotes = $event"
                    />
                </div>
            </div>
        </div>

        <br class="d-none d-xs-block"/>

        <div class="btn-group btn-group-sm mb-2 mb-lg-0">

            <button class="btn"
                    :class="{'btn-primary' : type === 'suggestions', 'btn-default' : type !== 'suggestions'}"
                    @click="type = 'suggestions'">Suggestions
            </button>
            <button class="btn"
                    :class="{'btn-primary' : type === 'authors', 'btn-default' : type !== 'authors'}"
                    @click="type = 'authors'">Team Members
            </button>

        </div>
    </div>
</template>

<script>
import Paginator from '@/utilities/Paginator';
import {mapGetters} from 'vuex';
import capitalize from "capitalize";
import TopicsSvg from "@/assets/svg/topics";
import CollectionsSvg from "@/assets/svg/collections";
import {StringUtils as $strings} from "@/utilities/Strings";

export default {
    components: {
        Paginator, TopicsSvg, CollectionsSvg
    },
    data() {
        return {
            hasSuggestionsSearch: '',
            pagedHasNotes: [],
            selectedHasSuggestions: false,
            type: 'suggestions',
            lastViewedTopic: false,
            lastViewedCollection: false
        }
    },
    mounted() {
        this.lastViewedTopic = this.currentTopic;
        this.lastViewedCollection = this.currentCollection;

        this.autoSetSelectedHasSuggestions()
    },
    watch: {
        hasSuggestions() {
            this.autoSetSelectedHasSuggestions()

            this.hasSuggestionsSearch = '';
        },
        selectedHasSuggestions() {
            switch (this.hasSuggestions.type) {
                case "topic":
                    this.lastViewedTopic = this.selectedHasSuggestions;
                    break;
                case "collection":
                    this.lastViewedCollection = this.selectedHasSuggestions
                    break;
            }
            this.$emit('setHasSuggestions', this.selectedHasSuggestions)
        },
        type() {
            this.$emit('setHasNotesType', this.type)
        }
    },
    computed: {
        ...mapGetters({
            hasSuggestions: 'hasSuggestions',
            allTopics: 'topics',
            allCollections: 'collections',
            currentTopic: 'currentTopic',
            currentCollection: 'currentCollection',
            currentAccountOverview: 'currentAccountOverview',
        }),
        topics() {
            return this.allTopics.filter(topic => topic.account.includes(this.currentAccountOverview.id));
        },
        collections() {
            return this.allCollections.filter(collection => collection.account.includes(this.currentAccountOverview.id));
        },
        selectableHasSuggestions() {
            let set = [];

            switch (this.hasSuggestions.type) {
                case "topic":
                    set = this.$sort.alphabetize(this.topics);
                    break;
                case "collection":
                    set = this.$sort.alphabetize(this.collections);
                    break;
            }

            return set

        },
        filteredSelectableHasNotes() {
            if (!this.hasSuggestionsSearch) {
                return this.selectableHasSuggestions;
            }

            return this.selectableHasSuggestions.filter(item => {
                return item.name.toLowerCase().includes(this.hasSuggestionsSearch.toLowerCase())
            });
        },
    },
    methods: {
        $strings,
        autoSetSelectedHasSuggestions() {
            if (this.hasSuggestions.type === 'collection' && this.collections.length > 0) {
                this.selectedHasSuggestions = this.lastViewedCollection
            }
            if (this.hasSuggestions.type === 'topic' && this.topics.length > 0) {
                this.selectedHasSuggestions = this.lastViewedTopic
            }
        },
        setSuggestionViewerType(type) {
            this.$store.dispatch('setSuggestionViewerType', {type})
        },
        toCapitalized(string) {
            return typeof string === 'string' ? capitalize(string) : '';
        }
    }
}
</script>

<style>
</style>
