<template>
    <fieldset>
        <legend>Schedule</legend>
        <div class="scheduler-main">
            <label class="flex-grow">
                Frequency
                <select v-model="frequency" class="form-control form-select form-select-sm" required>
                    <option v-for="option in frequencyOptions" :key="option" :value="option">
                        {{ $strings.capitalize(option) }}
                    </option>
                </select>
            </label>
            <label v-if="frequency==='weekly'" class="flex-grow">
                Day of Week
                <select v-model="dayOfWeek" class="form-control form-select form-select-sm" required>
                    <option v-for="day in daysOfWeek" :key="day.num" :value="day.num">
                        {{day.description}}
                    </option>
                </select>
            </label>
            <label v-if="frequency==='monthly'">
                Day of Month
                <select v-model="dayOfMonth" class="form-control form-select form-select-sm" required>
                    <option :value="0">Last Day of Month</option>
                    <option v-for="n in daysOfMonth" :key="n" :value="n">{{n}}</option>
                </select>
            </label>
            <label v-if="frequency==='daily'" class="flex-grow flex-shrink-0 full-width">
                Days to Send
                <ul class="form-control form-control-sm no-bullets justify-content-between check-list-h">
                    <li v-for="day in daysOfWeek" :key="day.num">
                        <label>
                        <input type="checkbox" v-model="daysToSend"  :value="day.num"/>&nbsp;
                        <div class="check-label">{{day.description}}</div>
                        </label>
                    </li>
                </ul>
            </label>
            <label class="flex-grow">
                Time
                <input type="time" v-model="time" class="form-control form-control-sm" step="3600" required/>
            </label>
        </div>
    </fieldset>
</template>

<script>
    import moment from 'moment'
    const frequencyOptions = ["daily", "weekly", "monthly"]
    export default {
        name: "Scheduler",
        props: ['value', 'intHourTime'],
        data() {
            return {
                frequencyOptions,
                frequency: this.value&&this.value.frequency || "monthly",
                dayOfWeek: this.value&&this.value.dayOfWeek || 1,
                dayOfMonth: this.value&&this.value.dayOfMonth || 1,
                time: this.value&&this.value.time || '',
                daysOfWeek: [...Array(7).keys()].map(x => ({
                    num: x + 1,
                    description: moment().day(x).format('dddd')
                })),
                daysOfMonth: [...Array(31).keys()].map(x => x + 1),
                daysToSend: [...Array(7).keys()].map(x => x + 1),

            }
        },
        methods: {
            input(props) {
                this.$emit('input', {
                    ...this.value,
                    ...props,
                })
            },
        },
        watch: {
            dayOfWeek(val) {
                this.input({ dayOfWeek: val })
            },
            dayOfMonth(val) {
                this.input({ dayOfMonth: val })
            },
            daysToSend(val) {
                this.input({ daysToSend: val })
            },
            frequency(val) {
                this.input({ frequency: val })
            },
            time(val) {
                this.input({ time: val })
            },
            hour(val) {
                this.input({ time: val })
            },
            timeType(val) {
                this.input({ time: val })
            },
            value() {
                this.frequency = this.value&&this.value.frequency || "monthly";
                this.dayOfWeek = this.value&&this.value.dayOfWeek || 1;
                this.dayOfMonth = this.value&&this.value.dayOfMonth || 1;
                this.daysToSend = this.value&&this.value.daysToSend||[...Array(7).keys()].map(x => x + 1);
                this.time = this.value&&this.value.time || '';
            }
        }
    }
</script>

<style scoped lang="scss">
    .check-list-h {
        display: flex;
        label {
            text-align: center;
        }
    }
    @media screen and (max-width: 500px) {
        .check-list-h {
            display: block;
            .check-label {
                display: inline;
            }
            label {
                text-align: left;
            }
        }
    }
</style>
