<template>
    <div>
            <topic-builder-progress-bar
                @setStageIndex="stageIndex = $event"
                :stage="stage"
                :stages="stages"
                :stageIndex="stageIndex"
            ></topic-builder-progress-bar>

            <hr/>


            <div class="row">
                <div class="col-md-6 order-md-2">

                    <div v-if="stageIndex == 0">
                        <h5>How will this affect my results?</h5>
                        <p class="text-muted small">Your results will only contain suggestions that include this exact search term.</p>

                        <h5>Tips for Choosing an Interest Area:</h5>
                        <ol class="text-muted small mb-3">
                            <li>Be specific; we search for the exact criteria you enter.</li>
                            <li>Stick to one main search word or phrase.</li>
                            <li>Remember, this isn't a search engine (we're better!), so avoid typing in sentences or phrases like "how to X" or "news about X."</li>
                        </ol>
                    </div>
                    <div v-if="stageIndex == 1">
                        <h5>How will this affect my results?</h5>

                        <p class="text-muted small">Your results will also include suggestions that contain at least one of these search terms, in addition to the main search term entered previously.</p>
                    </div>
                    <div v-if="stageIndex == 2">
                        <h5>How will this affect my results?</h5>

                        <p class="form-text text-muted small">If there is something associated with your topic that you know you don't want to see, enter it here. We will exclude articles with these terms from your results</p>

                    </div>

                    <div v-if="stageIndex == 3">
                        <h5>Your final query</h5>
                        <small><formatted-query :queryString="queryString"></formatted-query> </small>
                    </div>

                </div>
                <div class="col-md-6">

                    <div v-if=" stageIndex == 0">
                        <h5>What are you interested in finding content about?</h5>
                        <input
                            v-model="primaryQuery"
                            @keyup.enter="selectedTopic = primaryQuery"
                            class="form-control my-3"
                            placeholder="Enter Main Criteria"
                            :class="{'is-invalid' : tooManySpaces || mainHasBooleans}"
                            ref="primaryInput"
                        />
                        <p class="help-text" v-if="primaryQuery.length">Press <b class="text-primary-75">Enter</b> to
                            set <i>{{primaryQuery
                            }}</i> as your main topic</p>
                        <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: sanitization-->
                        <div class="form-text text-danger small" v-if="tooManySpaces || mainHasBooleans">
                            <span v-if="tooManySpaces">
                                Enter only one search term or phrase here.<br/> You'll be able to narrow or expand your focus in the next step.
                            </span>
                            <span v-else-if="mainHasBooleans">
                                Don't use joining words like <b>and</b> and <b>or</b> here. We'll do that in the next step.
                            </span>
                        </div>
                        <div class="form-text text-muted small">Example: marketing; real estate; travel; etc.</div>

                        <div class="list-group" v-if="suggestedTopics.length">
                            <a class="list-group-item" v-for="topic in suggestedTopics" :key="topic" href="#" @click.prevent="setMainTopic(topic)">{{topic}}</a>
                        </div>
                    </div>

                    <div v-if="stageIndex == 1">
                        <query-string-helper
                            v-if="badQueryString"
                            :string="badQueryString"
                            @useSuggestions="replaceWithSuggestions($event, 'selectedSubTopics')"
                            @cancel="badQueryString = false"
                        />
                        <div v-else>
                            <h5>What about <span class="text-success" v-text="selectedTopic" /> interests you?</h5>
                            <div class="d-flex align-items-center mb-2" v-for="(topic,index) in selectedSubTopics" :key="'topic-'+topic+index">
                                <span class="me-3" :class="{'text-white' : index == 0, 'text-success' : index > 0}">OR</span>
                                <input class="form-control input-sm" :value="topic" @change="updateSubTopicAtIndex($event, index)" />
                                <span class="">
                                    <button @click="removeSubTopicAtIndex(index)" class="btn btn-white text-muted ms-3"><i class="fa fa-times-circle"></i></button>
                                </span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="text-success me-3" v-if="selectedSubTopics.length > 0">OR</span>
                                <input
                                    ref="subTopicInput"
                                    placeholder="Enter your additional criteria here"
                                    class="form-control input-sm sub-topic-input"
                                    v-model="newSubTopic"
                                    @keyup.enter="addTerm($event, 'selectedSubTopics', 'newSubTopic')"
                                    @keyup="preventCommas($event)"
                                    :class="{'is-invalid' : subHasBooleans}"
                                />

                            </div>
                            <p class="help-text" v-if="newSubTopic.length">Press <b class="text-primary-75">Enter</b>
                                to add <i>{{newSubTopic}}</i> as a sub-topic</p>
                            <div class="form-text text-danger small" v-if="subHasBooleans">
                                Don't use joining words like <b>and</b> and <b>or</b> in a single term.
                            </div>
                            <div class="form-text text-muted small">Try adding words like <em><b>trends</b></em>,
                                <em><b>strategies</b></em>, etc.....<br/><b>Press
                                    <span class="text-primary-75">Enter</span></b>
                                between each
                                phrase
                                .</div>
                        </div>
                    </div>
                    <div v-if="stageIndex == 2">
                        <query-string-helper
                            v-if="badQueryString"
                            :string="badQueryString"
                            @useSuggestions="replaceWithSuggestions($event, 'selectedExclusionTopics')"
                            @cancel="badQueryString = false"
                        />
                        <div v-else>
                            <h5>
                                What specifically <b>don't</b> you want included in your results about <span class="text-danger" v-text="selectedTopic" />? (this step is optional)
                            </h5>
                            <div class="d-flex align-items-center mb-2" v-for="(topic,index) in selectedExclusionTopics" :key="'topic-'+topic+index">
                                <span class="text-danger me-3">NOT</span>
                                <input class="form-control input-sm" :value="topic" @change="updateExcludedTopicAtIndex($event, index)" />
                                <span class="">
                                    <button @click="removeExcludedTopicAtIndex(index)" class="btn btn-white text-muted ms-3"><i class="fa fa-times-circle"></i></button>
                                </span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="text-danger me-3" >NOT</span>
                                <input
                                    v-model="newExcludedTopic"
                                    ref="exclusionTopicInput"
                                    placeholder="Enter criteria you don't want included"
                                    class="form-control input-sm exclusion-topic-input"
                                    @keyup.enter="addTerm($event, 'selectedExclusionTopics', 'newExcludedTopic')"
                                    @keyup="preventCommas($event)"
                                />
                            </div>
                            <p class="help-text" v-if="newExcludedTopic.length">Press <b class="text-primary-75">Enter
                            </b> to exclude <i>
                                {{newExcludedTopic}}</i> from your query</p>
                        </div>
                    </div>
                    <div v-if="stageIndex == 3">
                        <h5>What would you like to name your topic?</h5>
                        <div class="form-group">
                            <input ref="topicName" class="form-control" @keyup.enter="$emit('complete')" v-model="topic.name" placeholder="Name your topic" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="query-string mt-3" v-if="queryString.length > 5 && stageIndex < 3">
                <span class="text-muted">Your query so far:</span> <formatted-query :queryString="queryString"></formatted-query>
            </div>

            <div v-if="queryError" class="alert alert-gold mt-3 animated shake">
                {{queryError}}
            </div>

            <div v-if="networkError" class="alert alert-gold mt-3">
                {{networkError}}
            </div>


            <div class="d-flex mt-3 border-top pt-3" v-if="selectedTopic">
                <button class="btn btn-secondary btn-sm" @click="restart">Start Over</button>
                <div class="ms-auto">
                    <button
                        class="btn btn-sm btn-secondary me-3"
                        @click="stageIndex -= 1"
                        v-if="stageIndex > 0"
                    >
                        <i class="fa fa-arrow-left me-1"></i>
                        Back
                    </button>
                    <button
                        class="btn btn-sm btn-primary"
                        @click="goToNext"
                        v-if="stageIndex < 3 && (stageIndex > 0 || selectedTopic)">
                            Next&nbsp;
                            <i class="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
    </div>
</template>

<script>

import Vue from 'vue'
import TopicBuilderProgressBar from './TopicBuilderProgressBar'
import ListWidget from './ListWidget';
import Base from '@/components/QueryBuilder/BaseBuilder'
import QueryStringHelper from './QueryStringHelper'
import WordBlacklist from './NLP/oh-no-no-words'
import TopicLanguageSelect from "@/components/Topics/TopicCultureSelector";
import {mapGetters} from 'vuex';
import FormattedQuery from "./FormattedQuery";

export default {
    props : [],
    data(){
        return {
            stageIndex : 0,
            primaryQuery : '',
            selectedTopic : '',
            suggestedTopics : [],
            suggestedSubTopics : [],
            selectedSubTopics : [],
            newSubTopic : '',
            newExcludedTopic : '',
            suggestedExclusionTopics : [],
            selectedExclusionTopics : [],
            hasSeenTooFewOrsMessage : false,
            stages : [
                'Identify Interest Area',
                'Refine Interest Area',
                'Exclude Terms',
                'Name Topic'
            ],
            badQueryString: false
        }
    },
    mixins : [Base],
    components : {
        FormattedQuery,
        TopicLanguageSelect,
        TopicBuilderProgressBar, ListWidget, QueryStringHelper
    },
    watch : {
        stageIndex(){
            if (this.stageIndex == 2){
                Vue.nextTick(() => {
                    (this.$refs.exclusionTopicInput).focus();
                });
            }
            if (this.stageIndex == 3){
                 Vue.nextTick(() => {
                    (this.$refs.topicName).focus();
                });
            }

            this.$emit('stageReached', this.stageIndex);
        },
        selectedTopic(){
            if ( ! this.selectedTopic) return;
            Vue.nextTick(() => {
                this.alls = [this.selectedTopic]
                this.stageIndex += 1;
                Vue.nextTick(() => {
                    let subTopicInput = this.$refs.subTopicInput;
                    if ( subTopicInput){
                        subTopicInput.focus()
                    }
                });
            })
        },
        selectedSubTopics(){
            this.ors = this.selectedSubTopics
        },
        selectedExclusionTopics(){
            this.nots = this.selectedExclusionTopics
        },
        isExcludingTopics(){
            Vue.nextTick(() => {
                var input = this.$refs.subTopicExclusionInput ? this.$refs.subTopicExclusionInput : this.$refs.subTopicInput
                if (input)
                    input.focus()
            })
        },
        showDoneScreen(){
            Vue.nextTick(() => {
                var input = this.$refs.topicName
                if (input)
                    input.focus()
            })
        },
        primaryQuery(updated){
            this.primaryQuery = this.validateTermString(updated);
        },
    },
    computed : {
        ...mapGetters({
            features: 'features'
        }),
        stage(){
            return this.stages[this.stageIndex]
        },
        tooManySpaces() {
            return this.primaryQuery.split(" ").length > 3
        },
        mainHasBooleans() {
            return this.primaryQuery.includes(" and ") || this.primaryQuery.includes(" or ")
        },
        subHasBooleans() {
            return this.newSubTopic.includes(" and ") || this.newSubTopic.includes(" or ")
        },
        warningForMainTopic(){
            if (this.primaryQuery.split(" ").length > 3){
                return "Enter only one search term or phrase here.<br/> You'll be able to narrow or expand your focus in the next step.";
            }

            if (this.primaryQuery.includes(" and ") || this.primaryQuery.includes(" or ")){
                return "Don't use joining words like <b>and</b> and <b>or</b> here. We'll do that in the next step."
            }
        },
        warningForSubTopics(){
            if (this.newSubTopic.includes(" and ") || this.newSubTopic.includes(" or ")){
                return "Don't use joining words like <b>and</b> and <b>or</b> in a single term."
            }
        }
    },
    mounted(){
    },
    methods : {
        goToNext(){
            if (this.newSubTopic.length){
                this.selectedSubTopics.push(this.newSubTopic)
                this.newSubTopic = '';
            }

            if (this.newExcludedTopic.length){
                this.selectedExclusionTopics.push(this.newExcludedTopic)
                this.newExcludedTopic = '';
            }

            if (this.stageIndex == 1 && this.ors.length < 2 && ! this.hasSeenTooFewOrsMessage){
                this.queryError = "This criteria is broad and will not return very focused results.  Try adding a few terms here."
                this.hasSeenTooFewOrsMessage = true;
            } else {
                this.stageIndex += 1
            }
        },
        preventCommas(event){
            let commaSeparated = event.target.value.split(",");
            if (commaSeparated.length > 1){
                this.queryError = "Don't use commas here. Create separate terms by hitting \"enter\" after each one";
                event.target.value = event.target.value.replace(',', "");
            }
        },
        checkForBadString(string){
            WordBlacklist.forEach((word) => {
                let regex = new RegExp("\\b" + word + "\\b")
                if (regex.test(string)){
                    this.badQueryString = string;
                }
            });

            if (string.split(" ").length > 2){
                this.badQueryString = string;
            }

        },
        replaceWithSuggestions({string, recommendations}, target){
            let item = string;
            let suggestions = recommendations.map(r => r.trim());
            this[target].splice(this[target].indexOf(item), 1);
            this[target] = this[target].concat(suggestions);
            this.badQueryString = false;
        },
        addTerm(event, target, value){
            if (! event.target.value){
                this.goToNext();
                return;
            }
            this[target].push(this[value]);

            this.checkForBadString(this[value]);
            this[value] = '';
        },
        removeSubTopicAtIndex(index){
            this.selectedSubTopics.splice(index,1);
        },
        removeExcludedTopicAtIndex(index){
            this.selectedExclusionTopics.splice(index,1);
        },
        updateSubTopicAtIndex(event, index){
            let value = event.target.value;
            if (value){
                this.selectedSubTopics.splice(index, 1, value);
            } else {
                this.selectedSubTopics.splice(index, 1);
            }

            this.checkForBadString(value);
        },
        updateExcludedTopicAtIndex(event, index){
            event.target.blur()
            let value = event.target.innerText;
            if (value){
                this.selectedExclusionTopics.splice(index, 1, value);
            } else {
                this.selectedExclusionTopics.splice(index, 1);
            }
        },
        setSelectedSubTopics(topics){
            this.selectedSubTopics = topics;
        },
        setSelectedExclusionTopics(topics){
            this.selectedExclusionTopics = topics;
        },
        restart(){
            this.stageIndex = 0;
            this.primaryQuery = ''
            this.suggestedTopics = []
            this.suggestedSubTopics = []
            this.selectedTopic = ''
            this.selectedSubTopics = []
            this.additionalSubTopic = ''
            this.additionalExcludedSubtopic = ''
            this.suggestionExclusionTopics = []
            this.selectedExclusionTopics = []
            this.alls = [];
            this.nots = [];
            this.ords = [];
            this.hasSeenTooFewOrsMessage = false;
            Vue.nextTick(() => {
                let input = this.$refs.primaryInput;
                if ( input){
                    input.focus()
                }
            })

            this.$emit('startOver');

        },
    }
}
</script>

<style scoped>
ul, ol, li {
    padding: 0;
    margin: 0;
}

li {
    margin-left: 1rem;
}

.query-string {
    padding: 10px;
    border-radius: var(--border-radius);
    background: #f1e9f1;
    font-size: 13px;
}
.help-text {
    display: inline-block;
    font-size: 10px;
    margin: 5px 0;
}
</style>
