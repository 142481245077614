<template>
    <div class="suggestions-manager-container">
        <div class="actions d-flex justify-content-between align-items-center">
            <a href="#" :class="{'invisible' : collections}" @click.prevent="goToCollections()" role="menuitem">Go To Collections</a>
            <a href="#" :class="{'invisible' : topics}" @click.prevent="goToTopics()" role="menuitem">Go To Topics</a>
        </div>
        <div class="dropdown current" :class="{'left' : collections, 'right' : topics}">
            <span class="text-nowrap"  data-bs-toggle="dropdown" role="menuitem"  aria-haspopup="true"
                  aria-expanded="false"
                  :aria-label="`${type}, active is ${name}`" id="open-suggestions-dropdown">
                <span class="suggestion-toggle" :title="name">{{ name }}</span>
                <i class="suggestion-toggle-icon fa fa-caret-down bottom-4"></i>
            </span>
            <div class="dropdown-menu advanced-menu dropdown-menu-end" role="menu" aria-labelledby="open-suggestions-dropdown">
                <topic-menu v-if="topics"/>
                <collection-menu v-if="collections"/>
            </div>
        </div>


    </div>
</template>

<script>
import {mapGetters} from "vuex";
import TopicMenu from './TopicManager'
import CollectionMenu from './CollectionManager'

export default {

    components : {
        TopicMenu,
        CollectionMenu
    },

    computed : {
        ...mapGetters({
            type : 'suggestionViewerType',
            topic : 'currentTopic',
            collection : 'currentCollection'
        }),
        collections(){
            return this.type == 'collections'
        },
        topics(){
            return this.type == 'topics'
        },
        name(){

            switch(this.type){
                case 'topics':
                    return this.topic ? this.topic.name : 'Topic';
                case 'collections':
                    return this.collection ? this.collection.name : "Collection";
            }

        }
    },
    mounted(){

    },
    methods : {
        goToTopics(){
            this.$store.dispatch('setSuggestionViewerType', {type : 'topics'});
        },
        goToCollections(){
            this.$store.dispatch('setSuggestionViewerType', {type : 'collections'});
        }
    }
}
</script>

<style scoped>
.suggestions-manager-container {
    background: rgba(0,0,0,0.3);
    border-radius: 0.8em;
    color: white;
    width: 21.875em;
    position: relative;
}

.suggestion-toggle {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 9em;
    margin-right: .5em;
    display: inline-block;
    white-space: nowrap;
}

.suggestions-manager-container .actions {
    height: 40px;
    padding: 0 15px;
    font-size: var(--font-size-13);
}

.suggestions-manager-container .current {
    position: absolute;
    width: 50%;
    top:4px;
    background: rgba(88, 88, 88, 0.4);
    border-radius: 0.8em;
    padding: 5px 0 1px;
    text-align: center;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
    z-index: 100;
    font-size:14px;
}

.current.right {
    transform: translateX(98%);
}

.current.left {
    transform: translateX(2%);
}

.suggestions-manager-container a {
    color: inherit;
    opacity: 0.5;
    flex: 1;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
