<template>
    <div class="spinner-container">
        <svg :width="size" :height="size" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg" class="spinner" ref="spinner">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(2 1)" stroke="#333" stroke-width="1.5">
                    <circle cx="42.601" cy="11.462" r="5" fill-opacity="1" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="1;0;0;0;0;0;0;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="49.063" cy="27.063" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;1;0;0;0;0;0;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="42.601" cy="42.663" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;0;1;0;0;0;0;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="27" cy="49.125" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;0;0;1;0;0;0;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="11.399" cy="42.663" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;0;0;0;1;0;0;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="4.938" cy="27.063" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;0;0;0;0;1;0;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="11.399" cy="11.462" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;0;0;0;0;0;1;0" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                    <circle cx="27" cy="5" r="5" fill-opacity="0" fill="#333">
                        <animate attributeName="fill-opacity"
                                 begin="0s" dur="1.3s"
                                 values="0;0;0;0;0;0;0;1" calcMode="linear"
                                 repeatCount="indefinite" />
                    </circle>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "Spinner",
        props: ['size'],
        mounted() {
            if (this.size) {
                this.$refs.spinner.style = `width: ${this.size}px; height: ${this.size}px;`
            }
        }
    }
</script>

<style scoped lang="scss">
    .spinner {
        width: 20px;
        height: 20px;
    }

    .list-spinner {
        &.spinner-container {
            text-align: center;
            padding:  20px 0;
        }
        .spinner {
            width: 40px;
            height: 40px;
        }
    }
    .text-spinner {
        width: 1em;
        height: 1em;
        display: inline;
    }
</style>

<style>

    .dropdown .spinner {
        width: 20px;
        height: 20px;
        float: right;
        margin-top: 7px;
        margin-right: 30px;
    }
    .spinner-inline {display: inline}
    .spinner-inline .spinner, .dropdown .btn .spinner{
        width: 1.5em;
        height: 1.5em;
        margin-top: 0px;
        margin-right: 0px;
    }

    .dropdown.spinner-container {
        float: right;
        width: 100%;
        position: relative;
        height: 0;
    }

    .spinner-overlay {
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 100000;
    }

    .spinner-overlay .spinner {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .spinner-overlay .spinner-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto auto;
    }
</style>
