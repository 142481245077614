<template>
    <div>
        <input
            :type="type"
            :value="value"
            :required="required"
            ref="hiddenInput"
            @invalid="() => doValidation()"
            class="input-hidden"
            aria-hidden="true"
            :maxlength="maxlength"
            :max="max"
            :min="min"
            :pattern="pattern"
            :step="step"
            :name="$elementId('validator')">
<!--        style="border-top-left-radius: 0; border-top-right-radius: 0"-->
<!--        <div v-if="error" class="popover fade show bs-popover-bottom position-static alert-danger" >-->
<!--            <div class="arrow"></div>-->
        <div v-if="error" class=" alert alert-danger ps-2- pe-2 pt-1 pb-1 mt-1">
            {{error}}

        </div>
<!--        </div>-->
    </div>
</template>

<script>

export default {
    name: "Valdiator",
    props: {
        value: String,
        required: {type: Boolean, default: false},
        type: {type: String, default: 'text'},
        pattern: {type: String, default: null},
        maxlength: {type: Number, default: null},
        max: {type: Number, default: null},
        min: {type: Number, default: null},
        step: {type: Number, default: null},
    },
    data() {
        return {
            error: '',
        };
    },
    watch: {
        value() {
            setImmediate(() => {
                if (this.value)
                    this.$refs.hiddenInput.checkValidity()
                this.error = this.$refs.hiddenInput.validationMessage
            })
        }
    },
    computed: {
    },
    methods: {
        doValidation() {
            this.error = this.$refs.hiddenInput.validationMessage;
        }
    },
}
</script>

<style scoped>
.input-hidden{
    height:0;
    width:0;
    visibility: hidden;
    padding:0;
    margin:0;
    float:right;
}
</style>
