import {Filters} from "@/models/Filters";

export class CollectionSourceViewModel {
    url = null;
    topicId = null;
    sourceCollectionId = null;
    filters = null;
    filterViewId = null;

    constructor(source) {
        if (typeof source === "string") {
            Object.assign(this, {url: source, lastChecked: null, lastPublished: null})
        } else {
            Object.assign(this, source)
        }
    }

    static fromUi(source) {
        return new CollectionSourceViewModel(Object.assign({}, source, {
            key:source.url||(source.id+JSON.stringify(source.filters)),
            topicId: source.type === 'topic' ? source.id : null,
            sourceCollectionId: source.type === 'collection' ? source.id : null,
            lastPublished: null,
            lastChecked: null,
            rssFeedId: null,
            filterViewId: source.filterViewId,
            filters: source.filters && Filters.fromUi(source.filters),
            failureReason: null,
        }))
    }

    static fromApi(source) {
        return new CollectionSourceViewModel(Object.assign({}, source, {
            key: source.id,
            lastPublished: source.last_published,
            lastChecked: source.last_checked,
            topicId: source.topic_id,
            filters: source.filters && Filters.fromApi(source.filters),
            sourceCollectionId: source.source_collection_id,
            rssFeedId: source.rss_feed_id,
            failureReason: source.failure_reason,
            filterViewId: source.filter_view_id,
            excludedSources: source.excludes,
            type: source.url ? 'rss' : source.topic_id ? 'topic' : source.source_collection_id ? 'collection' : null
        }))
    }

    toApi() {
        return {
            topic_id: this.topicId,
            source_collection_id: this.sourceCollectionId,
            rss_feed: this.url,
            filter_view_id: this.filterViewId
        }
    }
}
