<template>
    <v-select v-model="val" :options="options" label="name" @input="change">
        <template v-slot:option="option">
            <img class="sniply-image option" v-if="!!option.brandPhotoUrl" :src="option.brandPhotoUrl">
            <span class="sniply-option-text">
                <div>{{ option.name }} <span v-if="isCampaign(val)">(Legacy Campaign)</span></div>
                <div v-if="option.brandName" class="text-muted">{{ option.brandName }}</div>
            </span>
        </template>
        <template v-slot:selected-option="option">
            <span class="selected-option">
                <img class="sniply-image" v-if="option.brandPhotoUrl" :src="option.brandPhotoUrl">
                {{ option.name }}
                <span v-if="option.brandName"> ({{ option.brandName }})</span>
            </span>
        </template>
    </v-select>
</template>

<script>
import {VueSelect} from 'vue-select'

export default {
    name: "SniplyCampaignSelect",
    components: {vSelect: VueSelect},
    props: {
        campaigns: Array,
        campaign: [String, Boolean],
        cta: [String, Boolean],
    },
    computed: {
        options() {
            return this.campaignsWithDummy();
        }
    },
    data() {
        return {
            val: this.getCampaign()
        }
    },
    watch: {
        campaign() {
            this.val = this.getCampaign();
        },
        cta() {
            this.val = this.getCampaign();
        },
        campaigns() {
            this.val = this.getCampaign();
        }
    },
    methods: {
        change(val) {
            this.$emit('input', {
                cta: this.isCta(val) ? val && val.id : null,
                campaign: this.isCampaign(val) ? val && val.id : null,
                brand: val && val.brand
            });
        },
        isCta(val) {
            return val.type !== 'campaign';
        },
        isCampaign(val) {
            return val.type === 'campaign';
        },
        getCampaign() {
            return this.campaignsWithDummy().find(x => x.id === (this.cta || this.campaign)) || {id: false, name: ''};
        },
        campaignsWithDummy() {
            let campaignListHasCurrentSelection = this.campaigns.some(x => x.id === (this.cta || this.campaign))
            const campaignsWithEmpty = [{id: null, name: 'No CTA'}, ...this.campaigns];
            const campaignsWithEmptyAndLoadingCurrent = [...campaignsWithEmpty, {id: this.value, name: 'Loading...'}]
            return campaignListHasCurrentSelection ? campaignsWithEmpty : campaignsWithEmptyAndLoadingCurrent;
        }
    }
}
</script>

<style scoped>
.option.sniply-image {
    max-height: 48px;
    max-width: 48px;
    vertical-align: top;
}

.sniply-option-text {
    display: inline-block;
}

.selected-option .sniply-image {
    max-height: 24px;
    max-width: 24px;
}

.selected-option {
    display: inline-block;
}
</style>
