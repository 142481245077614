<template>
    <div class="popover-container animate bounceIn text-start collection-popover"
         :class="{'showing-done': showingDoneMessage}">
        <!-- ToDo: Refactor this sending to collection popover.  All sorts of height issues, FRAGILE! -->
        <div class="card">
            <div v-if="!showingDoneMessage" class="card-header d-flex align-items-start">
                <p>Send
                    <b>
                        {{ title }}
                    </b>
                    <span v-if="cards.length > 1">
                        {{" and " + (cards.length - 1) + " more "}}
                    </span>
                    to …
                </p>
                <a href="" class="ms-auto" @click.prevent="$emit('close')">
                    <i class="fa fa-times"></i>
                </a>
            </div>

            <div class="suggestions-sent-body card-body" v-if="showingDoneMessage">
                <div class="done my-5">
                    <h4 class="text-success text-center">
                        <i class="fa fa-send-o"></i> <br/>Suggestions Sent!</h4>

                    <div class="d-flex justify-content-center flex-column">
                        <button @click="goToCollection(collection)"
                                class="btn btn-success d-block mb-2"
                                v-for="collection in linkedCollections"
                                :key="'go-to-collection-'+collection.id">
                            Go To <span v-text="$strings.truncate(collection.name, 30)"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 m-0 container" v-else>
                <div class="row">
                    <div v-if="collections.length == 0" class="col-12">
                        <p class="p-3 font-size-16">You don't have any Collections. Why don't you <a href="#"
                                                                            @click.prevent="createNewCollection">make
                            one now?</a></p>
                    </div>
                    <div v-else class="col-12 col-sm-4 col-md-3 background-water-50 collections-by-folder-scroll">
                        <div class="d-sm-none" v-if="networkActivity">
                            <progress-bar class="mt-2"/>
                            <div class="alert alert-blue">
                                <ul class="no-bullets">
                                    <li v-for="task in tasksToDisplay" :key="task.key">
                                        {{ task.label }}. Completed: {{ task.progress }}/{{ task.total }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <collections-by-folder class="p-3"
                                               :folder="folder"
                                               :is-root="true"
                                               :isCheckable="true"
                                               v-model="selectedCollections"/>
                    </div>
                    <div class="col-12 col-sm-8 col-md-9 edit-selection-scroll">
                        <div class="d-none d-sm-block" v-if="networkActivity">
                            <progress-bar class="mt-2"/>
                            <div class="alert alert-blue">
                                <ul class="no-bullets">
                                    <li v-for="task in tasksToDisplay" :key="task.key">
                                        {{ task.label }}. Completed: {{ task.progress }}/{{ task.total }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="alert alert-danger p-3" style="width: calc(100% - 6em)" v-if="networkError">
                            {{ networkError }}
                        </div>

                        <div v-if="accountFeatures.customSelectionImageText" class="pt-3 pb-3">
                            <edit-selection ref="selectionsEditors" v-for="s in cards" :key="s.id" :selection="s"
                                            :collectionAbstractLimit="maxAbstractLength"
                                            class="small mb-5"></edit-selection>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-if="!showingDoneMessage && collections.length">
                <div v-if="networkActivity">
                    <progress-bar class="mt-2"/>
                    <div class="alert alert-blue">
                        <ul class="no-bullets">
                            <li v-for="task in tasksToDisplay" :key="task.key">
                                {{ task.label }}. Completed: {{ task.progress }}/{{ task.total }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <button @click="send"
                            v-if="selectedCollections.length > 0"
                            class="card-footer-send-to-collection-button btn btn-success full-width btn-sm">Send to {{ selectedCollections.length }} {{
                            $strings.plural('Collection', selectedCollections.length)
                        }}
                    </button>
                    <div v-else :disabled="true" class="btn btn-disabled full-width btn-sm">Choose one or more
                        Collections, or
                        <button type="button" class="btn btn-link p-0 align-baseline font-size-inherit" @click.prevent="createNewCollection">create one</button>
                    </div>

<!--                    <button @click="createNewCollection" class="btn btn-link btn-sm full-width">-->
<!--                        <i class="fa fa-plus"></i> Create new Collection-->
<!--                    </button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from '@/utilities/ProgressBar'
import CollectionsByFolder from "./CollectionsByFolder";
import {mapGetters} from 'vuex';
import EditSelection from "@/components/Collections/EditSelection";
import * as toastr from "toastr";
import {ContentPoliciesService} from "@/services/contentPolicies";
import {unique} from "@/utilities/Arrays";

export default {
    components: {
        EditSelection, ProgressBar, CollectionsByFolder
    },
    props: ['cards', 'collections'],
    data() {
        return {
            selectedCollections: [],
            collectionAbstractLimits: {},
            pagedCollections: [],
            collectionsSearch: '',
            networkActivity: false,
            showingDoneMessage: false,
            networkError: false,
            preventLeaveListener: null,
            sendingArticles: false,
            contentPolicyFailure: false,
            tasks: {
                collectionMeta: {
                    label: 'Checking collection configuration',
                    started: false,
                    progress: 0,
                    total: 0
                },
                imageUpload: {
                    label: "Uploading image",
                    started: false,
                    progress: 0,
                    total: 0
                },
                addToCollections: {
                    label: 'Adding suggestions to collection',
                    started: false,
                    progress: 0,
                    total: 0
                },
                imageSave: {
                    label: "Saving your images and summary",
                    started: false,
                    progress: 0,
                    total: 0
                },
                qualityChecks: {
                    label: "Checking against your organization's content policy",
                    started: false,
                    progress: 0,
                    total: 0
                },
                publish: {
                    label: "Sending to content destinations configured for collection",
                    started: false,
                    progress: 0,
                    total: 0
                },
                updateCache: {
                    label: "Optimizing collection content display",
                    started: false,
                    progress: 0,
                    total: 0
                }
            }
        }
    },
    watch: {
        collections(existing, updated) {
            let newCollections = existing.filter(e => updated.indexOf(e) == -1).map(c => c.id);
            this.selectedCollections = this.selectedCollections.concat(newCollections);
        },
        selectedCollections() {
            let collectionsToFetch = this.selectedCollections.filter(x => !this.collectionAbstractLimits[x]);
            collectionsToFetch.forEach(async c => {
                let resp = await this.$api.get(`/collections/${c}/data/policy`).catch(() => 'error');
                if (resp === 'error') return;
                this.collectionAbstractLimits = {...this.collectionAbstractLimits, [c]: resp.data.abstractLimit}
            })
        }
    },
    computed: {
        ...mapGetters({
            folders: 'folders',
            currentAccountOverview: 'currentAccountOverview',
            accountFeatures: 'accountFeatures',
            suggestionsCache: 'suggestionsCache'
        }),
        maxAbstractLength() {
            let min = Math.min(...this.selectedCollections.map(x => this.collectionAbstractLimits[x]).filter(x => x));
            return min && isFinite(min) && !isNaN(min) ? min : null;
        },
        searchedCollections() {
            if (this.collectionsSearch) {
                return this.collections.filter(c => c.name.toLowerCase().includes(this.collectionsSearch.toLowerCase()))
            }
            return this.collections;
        },
        title() {
            return this.$strings.truncate(this.cards[0].title, 60);
        },
        linkedCollections() {
            return this.selectedCollections.map(id => this.collections.find(c => c.id == id));
        },
        tasksToDisplay() {
            return Object.entries(this.tasks).filter(([, task]) => task.started && (task.progress < task.total))
                .map(([key, task]) => Object.assign({key}, task));
        },
        someTaskIncomplete() {
            return Object.values(this.tasks).some(task => task.started && task.progress < task.total);
        },
        folder() {
            return this.folders.find(f => this.currentAccountOverview.root.includes(f.id));
        }
    },
    mounted() {
        this.listenForEscape();
        window.Events.$on('collectionCreationModalDismissed', () => {
            this.listenForEscape();
        })

        // Handle if user clicks the send button on the top of the page.
        window.Events.$on('sendArticleToCollectionConfirm', () => {
            // Prevent burst submissions.
            if(this.sendingArticles) {
                return;
            }
            this.sendingArticles = true;

            this.sendArticleToCollectionConfirm();
        })
    },
    beforeDestroy() {
        this.stopListeningForEscape();
    },
    methods: {
        getEditor(id) {
            if (!this.accountFeatures.customSelectionImageText) return null;
            return this.$refs.selectionsEditors.find(editor => editor.selection.id === id)
        },
        getImageUploadCount() {
            return this.cards.filter(c => this.hasImage(this.getEditor(c.id))).length;
        },
        hasImage(editor) {
            return editor && !!editor.imageFile
        },
        uploadSingleImage(card) {
            let editor = this.getEditor(card.id);
            let hasImage = this.hasImage(card)
            let result = editor ? editor.upload().then(url => ({
                id: card.id,
                link: url
            })) : {};
            if (hasImage)
                this.tasks.imageUpload.progress++;
            return result;
        },
        updateSelection(suggestion, selection) {
            let editor = this.getEditor(suggestion.id);
            return editor ? editor.saveEditedData(selection) : Promise.resolve(selection);
        },
        collectionName(collection) {
            var name = this.$strings.truncate(collection.name, 30);
            if (collection.amplifyTopics && collection.amplifyTopics.length) {
                name += " <i title='Auto-posts to Amplify: " + collection.amplifyTopics.join(', ') +
                    "' class='fa fa-info-circle'></i>"
            }
            return name;
        },
        listenForEscape() {
            document.addEventListener('keyup', this.handleKeyup)
        },
        stopListeningForEscape() {
            document.removeEventListener('keyup', this.handleKeyup);
        },
        handleKeyup(e) {
            if (e.keyCode == 27) {
                this.$emit('close');
            }
        },
        createNewCollection() {
            window.Events.$emit('showCollectionCreationModal');
            this.stopListeningForEscape();
        },
        showDoneMessage() {
            this.showingDoneMessage = true;
        },
        async getPolicy(id) {
            return await this.$apis.contentPolicy.contentPoliciesPolicyIdGet(id).then(resp => resp.data)
        },
        async getCollectionContentPolicy(collectionId) {
            return await this.$apis.contentPolicy.collectionsCollectionIdContentPolicyGet(collectionId).then(resp => resp.data)
        },
        async getContentPolices(selectedCollections) {
            const collectionsToCheck = await Promise.all(selectedCollections.flatMap(async c => ({ policy: await this.getCollectionContentPolicy(c.id), collection: c })))
            //filter out collections with no policies
            let collectionsWithPolicies = collectionsToCheck.filter(i => i.policy.length !== 0);
            if(collectionsWithPolicies.length === 0) return [];

            return await Promise.all((collectionsWithPolicies
                .filter(ccp => ccp.policy[0].enforcement_level === 'MANDATORY')
                .map(ccp => ccp.policy[0].policy_id)
                .filter(unique(policyId => policyId))
                .map(async policyId => ({
                    policy: (await this.getPolicy(policyId)),
                    collections: collectionsWithPolicies.filter(ccp => ccp.policy[0].policy_id === policyId)
                }))));
        },
        crossJoin(arrayA, arrayB, crossJoinedMapper) {
            return arrayA.flatMap(a => arrayB.map(b => crossJoinedMapper(a, b)));
        },
        async checkPolicies(policies, contentItems, onlyMandatory=true) {
            // TODO: short circuit for instance where warning policy but no mandatory policy?
            // TODO: consider case where more than 1 collectionContentPolicy?
            let isHasContentPolicies = false;
            policies.forEach(p => {
                if(p.policy && p.policy.id) {
                    isHasContentPolicies = true;
                }
            })
            if(!isHasContentPolicies) {
                return [];
            }

            let checksToPerform = this.crossJoin(policies, contentItems, (policy, contentItem) =>
                ({policyId: policy.policy.id, contentItemId: contentItem.id})
            )

            const policyService = new ContentPoliciesService(window.$app.apis);
            const results = await policyService.checkMany(checksToPerform);
            console.log('* Results for checking if content policies failed', results)
            // check results for if any policies failed
            let failedPolicies = [];
            results.forEach(r => {
                if(r.failed_content_policy) {
                    failedPolicies.push(r)
                }
            })
            return failedPolicies;
        },
        async errorsForPolicyResults(failures, policies, contentItems) {
            let messages = failures.flatMap(failure => {
                const policy = policies.find(ccp => ccp.policy.id === failure.policy_id);
                return policy.collections.map(c => ({
                    policy: policy.policy,
                    collection: c.collection,
                    selection: contentItems.find(ci => ci.id === failure.content_item_id)
                }));
            });
            const policyService = new ContentPoliciesService(window.$app.apis);
            policyService.warnMany(messages);
        },
        async contentPolicies(contentItems) {
            const contentPoliciesPerSelectionCollection = await this.getContentPolices(this.linkedCollections);
            // if no collections have content policies, short circuit
            if(contentPoliciesPerSelectionCollection.length === 0) return;
            const failedPolicies = await this.checkPolicies(contentPoliciesPerSelectionCollection, contentItems);
            if(failedPolicies.length === 0) {
                this.contentPolicyFailure = false;
                return;
            }
            this.contentPolicyFailure = true;
            await this.errorsForPolicyResults(failedPolicies, contentPoliciesPerSelectionCollection, contentItems);
        },
        async addSelectionsToCollection(collectionId) {
            return await this.$api.post('/collections/' + collectionId + '/suggestions',
                {suggestions: this.cards.map(c => c.id)})
                .then(async response => {
                    return response.data.suggestions.map((url, index) => {
                        var existing = JSON.parse(JSON.stringify(this.cards[index]));
                        existing.fromSuggestion = existing.id;
                        existing.id = url.substr(url.lastIndexOf('/') + 1);
                        existing.selectionUrl = existing.id;
                        delete existing.topic;
                        existing.collection = collectionId;
                        existing.contexts = [];
                        existing.favorited_by = [];

                        return existing;
                    });
                });
        },
        publish: async function (qualityCheck, selection, totalSelections) {
            if (qualityCheck)
                this.tasks.qualityChecks.started = true;
            this.tasks.publish.started = true;

            await this.$api.post(`/selections/${selection.id}`, {quality_check: totalSelections > 3 ? 'async' : 'sync', generate_post_text: true})
                .then(r => r.data);
            // if (qc.quality_checks_performed) {
            //     let withTags = await this.$api.get(`/selections/${selection.id}`).then(r => r.data)
            //     selection.tags = withTags.tags;
            // }

            if (qualityCheck)
                this.tasks.qualityChecks.progress++;
            this.tasks.publish.progress++;
        },
        finishSave: async function (newSuggestions, qualityCheck) {
            let totalSelections = this.selectedCollections.length * this.cards.length;
            await Promise.all(newSuggestions.map(async selection => {
                this.tasks.imageSave.started = true;
                let edited = await this.updateSelection({id: selection.fromSuggestion}, selection);
                this.tasks.imageSave.progress++;

                await this.publish(qualityCheck, selection, totalSelections);

                selection.image_url = edited.image_url;
                selection.summary = edited.summary;
            }));
        },
        updateSelectionCache: function (newSuggestions, collectionId) {
            let self = this;

            let updateSuggestionContexts = async (suggestion) => {
                console.log(suggestion)
                this.tasks.updateCache.started = true;
                if (suggestion && suggestion.id)
                    await self.$api.get('/suggestions/' + suggestion.id + '/contexts')
                        .then(response => {
                            var updatedSuggestion = {...suggestion, contexts: response.data}
                            self.$store.dispatch('addSuggestionToCache', {suggestion: updatedSuggestion});
                            this.tasks.updateCache.progress++;
                        })
            };
            let updateSelection = async (selection) => await self.$api.get(`/selections/${selection.id}`)
                .then(r => r.data)

            this.cards.forEach(updateSuggestionContexts);
            newSuggestions.forEach(async s => updateSuggestionContexts(await updateSelection(s)));

            this.$store.dispatch('addItemsToCollections', {
                collectionIDs: [collectionId],
                items: newSuggestions
            });
        },
        resetTasks() {
            console.log('resetTasks')
            Object.values(this.tasks).forEach(t => {
                if (!(typeof t === "object")) return;
                console.log('resetTask', t)
                t.started = false;
                t.total = 0;
                t.progress = 0;
            });
            console.log('resetTasks.finish', this.tasks)
            this.resetPreventLeave()
        },
        resetPreventLeave() {
            console.log('resetPreventLeave')
            window.onbeforeunload = null;
        },
        preventLeaving(message) {
            console.log('preventLeaving.listen')
            window.onbeforeunload = () => {
                console.log('preventLeaving.shouldPrevent', this.someTaskIncomplete)
                if (this.someTaskIncomplete) {
                    return message
                    // if (confirm(message)) {
                    //     event.preventDefault();
                    //     // Older browsers supported custom message
                    //     event.returnValue = '';
                    // }
                }
            }
            window.addEventListener('beforeunload', this.preventLeaveListener);
        },
        startProgress: function () {
            this.resetTasks();
            let totalSelections = this.selectedCollections.length * this.cards.length;
            let collectionCount = this.selectedCollections.length;
            this.tasks.addToCollections.total = collectionCount;
            this.tasks.imageSave.total = totalSelections;
            this.tasks.publish.total = totalSelections;
            this.tasks.updateCache.total = totalSelections;
            this.tasks.imageUpload.total = this.getImageUploadCount();
            this.tasks.collectionMeta.total = collectionCount;
            this.preventLeaving(
                "Leaving now could leave your selections unpublished, to fix this you will have manually trigger a save and republish on any articles not yet complete individually. Are you  want to do this?")
        },
        collectionsWithChecks: async function () {
            this.tasks.collectionMeta.started = true;
            let checks = await Promise.all(this.selectedCollections.map(async collectionId => {
                let checks = await this.$api.get(`/collections/${collectionId}/quality-checks`).then(r => r.data);
                this.tasks.collectionMeta++;
                return {checks, collectionId}
            }));
            let collectionsWithChecks = checks.filter(c => c.checks.length).map(c => c.collectionId);
            this.tasks.qualityChecks.total = collectionsWithChecks.length * this.cards.length;
            return collectionsWithChecks;
        },
        async sendArticleToCollectionConfirm() {
            // This has a few extra checks in it to prevent accidental sending of articles to collections.

            if(this.networkActivity || this.networkError) {
                toastr.warning(`Please wait for network activity to resolve.`,
                    'Network Activity');
                this.sendingArticles = false;
                return;
            }

            const collectionCount = this.selectedCollections.length;
            if(collectionCount <= 0) {
                toastr.warning(`Select at least one collection to send to and try again.`,
                    'No Collections Selected');
                this.sendingArticles = false;
                return;
            }

            const cardCount = this.cards.length;
            const articleText = this.cards.length === 1 ? `'${this.title}'` : `${cardCount} articles`;

            let message = `Ready to send ${articleText} to your ${collectionCount} selected collections?`;
            if(this.selectedCollections.length === 1) {
                const collection = this.collections.find(c => c.id === this.selectedCollections[0]);
                const collectionName = collection.name;
                message = `Ready to send ${articleText} to the collection '${collectionName}'?`;
            }

            if(confirm(message)) {
                await this.send();
            } else {
                this.sendingArticles = false;
            }
        },
        async send() {
            this.networkActivity = "Saving..."
            this.networkError = false;

            this.startProgress();

            let collectionsWithChecks = await this.collectionsWithChecks();

            this.tasks.imageUpload.started = true;
            await Promise.all(this.cards.map(this.uploadSingleImage));

            this.tasks.addToCollections.started = true;

            this.contentPolicyFailure = false;
            await this.contentPolicies(this.cards);
            if(this.contentPolicyFailure) {
                this.networkActivity = false;
                if (!this.networkError && !this.contentPolicyFailure)
                    this.showingDoneMessage = true;
                this.resetTasks();
                this.$emit('completed');
                this.sendingArticles = false;
                return; // do not add selections to collection
            }

            try {
                var results = await Promise.all(this.selectedCollections.map(async collectionId => {
                    try {
                        let newSuggestions = await this.addSelectionsToCollection(collectionId);

                        this.tasks.addToCollections.progress++;

                        let qualityCheck = collectionsWithChecks.includes(collectionId);

                        await this.finishSave(newSuggestions, qualityCheck);
                        this.updateSelectionCache(newSuggestions, collectionId);

                        return {collectionId, newSuggestions, qualityCheck, error: false}
                    } catch (e) {
                        console.error(e)
                        if (e.response.data) {
                            this.networkError = e.response.data.description;
                        }
                        return {collectionId, newSuggestions: [], qualityCheck: null, error: e};
                    }
                }));
            } catch (e) {
                console.error('send() error:', e);
                this.networkError = e && e.response && e.response.data && e.response.data.description || "Unknown error"// FIXME: seeing this error
            }
            console.log('send.finsihedMainStartTidyUp', {error: this.networkError, tasks: this.tasks})
            this.networkActivity = false;
            if (!this.networkError && !this.contentPolicyFailure)
                this.showingDoneMessage = true;
            this.resetTasks();
            setTimeout(() => {
                this.$emit('completed');
                this.sendingArticles = false;
            }, 5500)
            if (results) {
                let monitor = results.filter(
                    ({newSuggestions, qualityCheck, error}) => qualityCheck && !error && newSuggestions.length);
                this.monitorSelections(monitor)
            }
        },
        monitorSelections(monitor) {
            let totalToMonitor = monitor.reduce((sum, {newSuggestions}) => sum + newSuggestions.length, 0);
            let totalTime = totalToMonitor > 6 ? totalToMonitor * 4000 : 20 * 1000;
            let timeBetween = totalTime / totalToMonitor;
            console.log('monitorSelections', {timeBetween, totalTime, totalToMonitor})
            monitor.forEach(({newSuggestions}, collectionIndex) => {
                newSuggestions.forEach((selection, selectionIndex) => {
                    setTimeout(async () => {
                        try {
                            if (await this.checkSelection(selection.id)) return;
                            let token;
                            let timesChecked = 0;
                            token = setInterval(async () => {
                                try {
                                    if (await this.checkSelection(selection.id) || timesChecked > 6) {
                                        clearInterval(token);
                                    }
                                    timesChecked++;
                                } catch (e) {
                                    clearInterval(token);
                                    console.error(e)
                                }

                            }, totalTime)
                        } catch (e) {
                            console.error(e)
                        }
                    }, timeBetween * selectionIndex + timeBetween * collectionIndex * newSuggestions.length)
                })
            })
        },
        async checkSelection(selectionId) {
            let selection = await this.$api.get(`/selections/${selectionId}`).then(r => r.data);
            console.log('checkSelection', {selectionId, selection, cache: typeof this.suggestionsCache})
            if (selection.tags && selection.tags.length && selection.tags.some(t => t.category = 'quality_tag')) {
                let cached = this.suggestionsCache.find(s => selection.id == s.id);
                let update = Object.assign({}, cached || {}, {tags: selection.tags});
                this.$store.dispatch('addSuggestionToCache', {suggestion: update})
                return true
            }
            return false
        },
        setPagedCollections(collections) {
            this.pagedCollections = collections;
        },
        goToCollection(collection) {
            this.$store.dispatch('setCurrentCollection', {collection})
            this.$store.dispatch('setSuggestionViewerType', {type: 'collections'});
            this.$emit('completed');
        }
    }
}
</script>

<style lang="scss" scoped>

.input-sm {
    font-size: 85%;
    padding: 3px;
}

.card-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.card-body p {
    margin-bottom: 0;
    font-size: var(--font-size-12);
}

.card-title {
    font-size: 14px;
    margin: 0;
}

.popover-container {
    position: absolute;
    margin-top: 3px;
    margin-left: 3px;
    z-index: 150;
    right: 10px;
    top: 45px;
}

.popover-container.showing-done {
    width:400px!important;
}

.popover-container.collection-popover {
    width: calc(100vw - 8rem);
}

// XX-Large devices (larger desktops, 1400px and up)
@media screen and (min-width: 1400px) {
    .popover-container.popover-container.collection-popover {
        max-width: 880px!important;
    }
}

// X-Large devices (larger desktops, 1200px and up)
@media screen and (min-width: 1200px) {
    .popover-container.collection-popover {
        width:calc(100vw - 360px)!important;
    }
}


// Large devices (desktops, 992px and up)
@media screen and (min-width: 992px) {
    .popover-container.collection-popover {
        width:calc(100vw - 300px);
    }
    .collections-by-folder-scroll, .edit-selection-scroll {
        max-height: calc(100vh - 235px);
        overflow-y: scroll;
    }
}
// Medium devices (tablets, less than 992px)
@media screen and (max-width: 991.98px) {
    .popover-container.collection-popover {
        width: calc(100vw - 6em);
        right: .5em;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .collection-popover {
        padding-bottom: 5em !important;
    }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .collection-popover {
        width: calc(100% + 1px) !important;
        max-width: calc(100vw - 4.25em);
        left: -4px !important;
        top: 2.6em !important;
        padding-bottom: 5em !important;
    }
}

.popover-container .card {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.btn-sm {
    font-size: var(--font-size-12);
}
</style>
