<template>
    <form>
        <form-group-card title="Fallback Image">
            <label class="label-calm full-width">
                Fallback Image Url
                <input type="url" class="form-control">
            </label>
            <label class="form-check">
                <input type="checkbox" class="form-check-input">
                Always use this image
            </label>
        </form-group-card>
    </form>
</template>

<script>
import FormGroupCard from "../../Controls/FormGroupCard";
import CollectionDataEditor from "./CollectionDataEditor";
export default {
    name: "CollectionFallbackImage",
    components: {FormGroupCard},
    mixins: [CollectionDataEditor],
    computed: {
        defaultValues() {
            return {
                imageUrl: '',
                alwaysUseImage: false
            }
        },
    },
    data() {
        return {
            imageUrl: '',
            alwaysUseImage: false
        }
    },
    created() {
        this.metadataKey = 'imageSettings';
    }
}
</script>

<style scoped>

</style>
