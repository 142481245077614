<template>

</template>

<script>

import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
        }),
    },
    data() {
        return {
            loadedData: {},
        }
    },
    methods: {
        async loadAccountData() {
            if(!this.metadataKey) {
                throw('Error: No metadata key set for AccountDataEditor');
            }
            if (this.currentAccountOverview.id) {
                let resp = await this.$api.get(`/accounts/${this.currentAccountOverview.id}/data/${this.metadataKey}`)

                if (resp.data) {
                    Object.assign(this, resp.data)
                }
                this.loadedData = resp.data || {};

            } else if (this.defaultValues) {
                Object.assign(this, this.defaultValues)
                this.loadedData = {};
            }
        },
        getAccountData() {
            let keys = Object.keys(this.defaultValues);
            return keys.reduce((result, key) => ({...result, [key]: this.$data[key]}), {})
        },
        async saveAccountData() {
            let data = this.getAccountData();
            let isSame = x => JSON.stringify(data) === JSON.stringify(x)
            let isSameAsSave = isSame(this.loadedData)
            let isSameAsDefaults = isSame(this.defaultValues); // for openai this is really just a null check
            if (isSameAsSave && isSameAsDefaults) return;
            if (!isSameAsSave && isSameAsDefaults && !this.saveUnchangedDefaults) return;
            return await this.$api.put(`/accounts/${this.currentAccountOverview.id}/data/${this.metadataKey}`, data)
        }
    }
}
</script>

<style scoped>

</style>
