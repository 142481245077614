<template>
    <div class="auth-container">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Forgot Password</h3>

                <div class="form-group">
                    <input placeholder="Email Address" class="form-control" v-model="email" />
                </div>

                <div class="d-flex small justify-content-between align-items-center">
                    <button :disabled="networkActivity || ! this.email" @click="sendResetEmail" class="btn btn-primary">Send password reset link</button>
                    <router-link to="/login" class="btn btn-link btn-sm ms-auto">Sign In</router-link>
                </div>

                <div class="alert alert-blue" v-if="networkActivity">
                    {{networkActivity}}
                </div>

                <div class="alert alert-danger" v-if="errorMessage">
                    {{errorMessage}}
                </div>
            </div>
        </div>
    </div>
    <div v-if="!isHearsay">
        <AuthFooter/>
    </div>
</template>

<script>
import Base from './Base'
import AuthFooter from "@/components/Auth/AuthFooter.vue";
import {mapGetters} from "vuex";

export default {
    components: {AuthFooter},
    mixins : [
        Base
    ],
    computed: {
        ...mapGetters({
            isHearsay: 'isHearsay',
        }),
    },
}
</script>
