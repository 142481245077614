<template>
    <div class="source-checklist">
        <h4 class="subtitle d-flex">
            Sources
            <a v-if="showClear" href="#" @click.prevent.stop="clearSearch" class="sidebar-link ms-auto">All</a>
        </h4>

        <progress-bar v-if="isLoadingSources"></progress-bar>

        <div v-else>
            <input class="filter-search form-control"
                   placeholder="Filter sources"
                   v-model="sourcesSearch"
                   v-if="showSearchBar"/>

            <p class="filter-item d-flex align-items-start"
               v-for="publisher in pagedSources"
               :key="'publisher-'+publisher.source">
                <label @click.stop>
                    <input type="checkbox" :value="publisher.source" v-model="selectedSources">
                    &nbsp;
                    <span v-text="publisher.name"/>
                </label>
                <span @click.stop="selectOne(publisher)" class="ms-auto badge bg-secondary cursor-pointer"
                      :title="`${publisher.count} suggestions for this source. Click to select only this source.`">
                    {{ publisher.count }}
                </span>
            </p>
            <paginator :extraSmall="true"
                       :small="true"
                       :size="pageSize"
                       :items="filteredSources"
                       @update="pagedSources = $event"/>
        </div>

        <p class="small" v-if="showNoSourcesFound">
            No Sources Found
        </p>
    </div>
</template>

<script>

// @click.right.prevent="sourceShowingMessage = publisher" <-- Use this for the right-click-to-remove functionality

import {mapGetters} from 'vuex';

import Base from './FilterMixin'
import ProgressBar from "@/utilities/ProgressBar"
import Paginator from '@/utilities/Paginator'

export default {
    components: {ProgressBar, Paginator},
    props: [
        'value',
        'allSources',
        'isLoadingSources'
    ],
    data() {
        return {
            pageSize: 10,
            selectedSources: this.value || [],
            sourceShowingMessage: false,
            sourcesSearch: '',
            pagedSources: []
        }
    },
    watch: {
        value() {
            // console.debug('SelectSources.value.changed', this.value)
            if (JSON.stringify(this.selectedSources) !== JSON.stringify(this.value))
                this.selectedSources = this.value;
        },
        selectedSources() {
            // console.log('SelectSources.selectedSources.changed', this.selectedSources)
            if (JSON.stringify(this.value) !== JSON.stringify(this.selectedSources))
                this.$emit('input', this.selectedSources);
        },
    },
    computed: {
        ...mapGetters({}),
        showClear() {
            return this.sources && this.allSources && this.sources.length < this.allSources.length
        },
        showNoSourcesFound() {
            return this.filteredSources.length === 0 && !this.sourcesSearch && !this.isLoadingSources
        },
        calculatedSourceList() {
            return this.allSourcesSorted.filter(source => {
                return this.selectedSources.indexOf(source.source) >= -1
            })
        },
        filteredSources() {
            var sources = this.allSourcesSorted;

            if (this.sourcesSearch) {
                sources = sources.filter(s => s.name.toLowerCase().includes(this.sourcesSearch.toLowerCase()));
            }

            return sources;
        },

        allSourcesSorted() {
            return this.$sort.count((this.allSources || [])).reverse();
        },
        showSearchBar() {
            return (this.allSources || []).length > this.pageSize
        }
    },
    mounted() {
        this.sources = this.calculatedSourceList.map(s => s.source);
    },
    methods: {
        clearSearch() {
            this.sources = this.allSourcesSorted.map(s => s.source);
            this.update();
        },

        selectOne(publisher) {
            this.selectedSources = [publisher.source];
        }
    }
}
</script>

<style scoped>
.source-checklist {
    /*margin: 5px 15px;*/
}
</style>
