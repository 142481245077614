<template>
    <div class="d-flex justify-content-start align-items-center">
            <label for="filters-form-predicted-performance"
                   class="me-2 top-4">Performance&nbsp;Threshold</label>
            <div class="">
                <input id="filters-form-predicted-performance" type="number" v-model="minPredictedPerformance" required
                       class="form-control form-control-sm full-width text-end"
                       step="0.01"
                />
            </div>
    </div>
</template>

<script>

// @click.right.prevent="tagShowingMessage = publisher" <-- Use this for the right-click-to-remove functionality

import {mapGetters} from 'vuex';

import Base from './FilterMixin'

export default {
    mixins: [Base],
    components: {},
    props: ['value', 'hasContentId', 'hasContentType'],
    data() {
        return {
            valueChanging: false,
            minPredictedPerformance: this.value.minPredictedPerformance || 0,
        }
    },
    watch: {
        value() {
            //console.debug('minPredictedPerformance.value.change.before', this.value.minPredictedPerformance, this)
            this.valueChanging = true;
            this.minPredictedPerformance = this.value.minPredictedPerformance || 0;
            this.valueChanging = false;
            //console.debug('minPredictedPerformance.value.change.after', this.value.minPredictedPerformance, this)
        },
        minPredictedPerformance() {
            if (!isNaN(parseFloat(this.minPredictedPerformance)))
                this.update();
        },
        hasContentId() {
            this.load()
        }
    },
    computed: {
        ...mapGetters({
            features: 'features',
        }),
    },
    mounted() {
        this.load(this.hasContentType, this.hasContentId)
    },
    methods: {
        update() {
            if (this.valueChanging) return;
            let filters = {...this.value};
            filters.minPredictedPerformance = parseFloat(this.minPredictedPerformance) || 0;
            // console.debug('minPredictedPerformance.update', filters)
            this.$emit('input', filters);
        },
        async load() {
        }
    }
}
</script>

<style scoped>

</style>
