<template>
    <v-select :options="icons" v-model="icon" class="icon-dropdown" max-height="215px">
        <template  v-slot:option="option">
            <span class="icon-container me-2">
                    <i :class="option.iconClass"></i>
            </span>
            {{ option.label }}
        </template>
        <template  v-slot:selected-option="option">
            <span class="icon-container">
                    <i :class="option.iconClass"></i>
                </span>
            {{ option.label }}
        </template>
    </v-select>
</template>

<style scoped>
.icon-dropdown {
    width: 100%;
}
.icon-container {
    position: relative;
    top: -1px;
    width: 20px;
    text-align: center;
    max-height: 20px;
    display: inline-block;
    margin-right: 5px;
}
</style>

<script>
import {VueSelect} from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    components: {vSelect: VueSelect},
    props: {
        icons: Array, // { value: String, style: String, label: String },
        value: String
    },
    data() {
        return {
            icon: this.icons.find(i => i.value === this.value) || ''
        }
    },
    watch: {
        icon() {
            this.$emit('input', this.icon.value)
        },
        value() {
            if (this.value != this.icon && this.value != this.icon.value) {
                this.icon = this.icons.find(i => i.value === this.value) || ''
            }
        }
    }
}
</script>
