import store from "@/store";
import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import {featureNames, features} from "@/Features";
import {apis, appApi} from "@/init/ApiInit";

window['$store'] = store;
window['$app'] = {
    Vue, Vuex, store, router,
    api: appApi,
    apis: apis,
    rest: Vue.prototype.$rest,
    currentApis: {
        account() {
            return Vue.prototype.$rest.accounts(store.getters.currentAccountOverview.id)
        },
        collection() {
            return Vue.prototype.$rest.collections(store.getters.currentCollection.id)
        }
    },
};


const setFeatureFlag = window['setFeatureFlag'] = function (name, value) {
    store.dispatch('setFeatureFlag', {name, value});
};

const resetFeatureFlag = window['resetFeatureFlag'] = function (name) {
    setFeatureFlag(name, features[name])
};

export const resetAllFeatureFlags = window['resetAllFeatureFlags'] = function () {
    featureNames.forEach(feature => resetFeatureFlag(feature))
};

window['env'] = process.env;

window['setVendor'] = function (vendor) {
    store.dispatch('setVendor', {vendor});
};

window['setSubvendor'] = function (subvendor) {
    store.dispatch('setSubvendor', {subvendor});
};

window['resetSubvendor'] = function () {
    store.dispatch('resetSubvendor');
};

window['resetVendor'] = function () {
    store.dispatch('resetVendor');
};


window.$app = window.$app || {}
window.$app['setVendor'] = function (vendor) {
    store.dispatch('setVendor', {vendor});
};

window.$app['setSubvendor'] = function (subvendor) {
    store.dispatch('setSubvendor', {subvendor});
};

window.$app['resetSubvendor'] = function () {
    store.dispatch('resetSubvendor');
};

window.$app['resetVendor'] = function () {
    store.dispatch('resetVendor');
};

window.$app.resetEmbed = () => store.dispatch('resetEmbed')
window.$app.setEmbed = isEmbed => store.dispatch('setEmbed', {isEmbed})
