

<script>
import {OAuthConnection} from "../../../services/OAuth";
import {LatelyConnection} from "../../../services/Lately";
import {SniplyConnection} from "../../../services/Sniply";
import {AmplifyConnection} from "../../../services/Amplify";
import {mapGetters} from "vuex";
export default {
    name: "PowerupAuth",
    computed: {
        ...mapGetters({
            apis: 'externalApis'
        }),
        service() {
            switch (this.name) {
                case 'amplify': return new AmplifyConnection(this);
                case 'lately': return new LatelyConnection(this);
                case 'sniply': return new SniplyConnection(this);
                case 'mail':
                case 'icebreaker':
                    return null
                case 'constant_contact':
                case 'hubspot':
                case 'mailchimp':
                case 'proofpoint':
                default:
                    new OAuthConnection(this.name, this)
            }
        },
        usesToggle() {
            switch (this.name) {
                case 'amplify':
                case 'lately':
                case 'sniply':
                case 'icebreaker':
                case 'constant_contact':
                    return true
                case 'mail':
                case 'hubspot':
                case 'mailchimp':
                case 'proofpoint':
                default:
                    return false
            }
        }
    },
}
</script>

<style scoped>

</style>
