<template>
    <form class="add-source mb-2" @submit.prevent="addSource">
        <div class="input-group btn-group mb-2">
            <content-source-type-select v-if="upcSources && !onlyType"
                                        :value="computedType"
                                        @input="typeChange"
                                        class="content-source-type-select"
                                        :class="`src-${computedType}`"></content-source-type-select>
            <input v-show="computedType === 'rss'"
                   :required="computedType === 'rss'"
                   type="url"
                   v-model="urlToAddToSources"
                   ref="addRssTextBox"
                   placeholder="RSS Feed URL"
                   class="form-control add-source--url"
                   :readonly="sourcesBusy || validating"
                   @input="resetErrors">
            <collection-topic-selector
                class="add-source--cts"
                v-show="computedType !== 'rss'"
                :required="computedType !== 'rss'"
                :exclude="collectionId"
                :type="computedType"
                :cross-account="showSyndicatedAccountCollections"
                ref="collectionTopicSelector"
                @input="setTopicCollectionId"
            ></collection-topic-selector>

        </div>

        <label v-if="accountsWithCrossAccount.length && computedType === 'collection'" class="mb-2">
            <input type="checkbox" v-model="showSyndicatedAccountCollections"/>&nbsp;
               Show collections from syndication
            <template v-if="accountsWithCrossAccount.length>1">accounts</template>
            <template v-else>account</template>
        </label>

        <div v-if="computedType !== 'rss' && id" class="mb-2">

            <select-filter-view

                v-model="filterViewId"
                :has-content-type="computedType"
                :has-content-id="id"
                class="mb-2"
                ref="filterViewEdit"
            >
            </select-filter-view>

        </div>

        <input type="submit"
               v-if="computedType !== 'rss' && id && !(feedSuggestions && feedSuggestions.length)"
               class="btn btn-primary full-width"
               value="Add Source"
               :disabled="sourcesBusy || validating">


        <input type="submit"
               v-if="computedType == 'rss' && !id && !(feedSuggestions && feedSuggestions.length)"
               class="btn btn-primary full-width"
               :value="`Add ${computedType==='rss' ? 'Feed' : 'Source'}`"
               :disabled="sourcesBusy || validating">
        <!-- Add Feed / Add Source -->
        <div class="text-danger mt-2" v-if="sourceErrorMessage">
            {{ sourceErrorMessage }}
        </div>

        <div v-if="checkingForFeeds" class="mt-1">
            Checking HTML page metadata for valid RSS feed links
            <spinner class="text-spinner"></spinner>
        </div>

        <div class="mt-1" v-if="feedSuggestions && feedSuggestions.length">
            <p>We found the following RSS feed links in the HTML metadata for the page at the url you
               provided</p>
            <ul class="no-bullets ps-0 mt-1">
                <li v-for="feed in feedSuggestions" :key="feed">
                    <input type="checkbox" v-model="selectedFeeds" :value="feed.url">&nbsp;
                    <a :href="feed.url" target="_blank">{{ feed.url }}</a>
                </li>
            </ul>
            <button type="button" class="btn btn-primary" @click="addSelectedFeeds">
                Add Selected
            </button>
        </div>
        <!--<div v-else-if="recoverableError">-->
        <!--<button type="button" class="btn btn-danger" @click="forceAdd">-->
        <!--Add Feed URL Anyway-->
        <!--</button>-->
        <!--<p>Chances are this feed is wrong but just in case</p>-->
        <!--</div>-->
    </form>
</template>

<script>
import ContentSourceTypeSelect from '@/components/Controls/ContentSourceTypeSelect'
import CollectionTopicSelector from "@/components/Controls/CollectionTopicSelector";
import HasFilters from '@/components/Collections/HasFilters';
import {mapGetters} from 'vuex'
import Spinner from '@/components/Controls/Spinner'
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";

export default {
    name: "AddContentSourceForm",
    mixins: [HasFilters],
    components: {SelectFilterView, CollectionTopicSelector, ContentSourceTypeSelect, Spinner},
    props: {
        sourcesBusy: {type: Boolean, default: false},
        collectionId: String,
        onlyType: {type: String}
    },
    computed: {
        ...mapGetters({
            features: 'features',
            accountFeatures: 'accountFeatures',
            accountsWithCrossAccount: 'accountsWithCrossAccount',
            appName: 'appName',
        }),
        upcSources() {
            return this.features && this.features.upcSources;
        },
        internalType() {
            const regex = /special:([a-z]*):.*/;
            return regex.test(this.computedType) ? regex.exec(this.computedType)[1] : this.computedType;
        },
        computedType() {
            /* Better reactivity than doing this in data(). */
            return this.onlyType ? this.onlyType : this.legacyType;
        }
    },
    data() {
        return {
            showSyndicatedAccountCollections: false,
            legacyType: this.onlyType || 'rss',
            urlToAddToSources: '',
            sourceErrorMessage: '',
            id: '',
            allTags: [],
            feedSuggestions: [],
            selectedFeeds: [],
            checkingForFeeds: false,
            validating: false,
            recoverableError: false,
            defaultFilters: {
                age: -1,
                time: 'Forever',
                contentType: 'All',
                favorites: false,
                sortBy: 'Recency',
                evergreen: true
            },
            filterViewId: null,
        }
    },
    watch: {
        // id(val) {
        //     if (val) {
        //         this.loadFilters();
        //     }
        // },
        legacyType() {
            this.id = '';
            this.$refs.collectionTopicSelector.clearSelected()
        },
        value() {
            this.valueChanging = true;
            console.log('value', this.value)
            if (this.value) {
                this.id = this.value.id;
                this.filterViewId = this.value.filterViewId;
                // this.unmodifiedFilters = this.value.unmodifiedFilters || this.unmodifiedFilters;
                // this.filterModel = this.value.filterModel || this.filterModel;
            } else {
                this.id = null;
                this.filterViewId = null;
            }
            console.log('value complete', this.value,
                this.value && this.value.filterModel && this.value.filterModel.filters)
            this.valueChanging = false;
        },
        filterViewId() {
            this.$emit('input', Object.assign({}, this.value, {filterViewId: this.filterViewId}))
        }
    },
    methods: {
        typeChange(val) {
            this.legacyType = val;
        },
        async isUrlValid(url) {
            if (!url) {
                this.sourceErrorMessage = 'Feed must not be blank';
                return false;
            }

            if (url.match(/\s/g)) {
                if (!confirm(
                    "The RSS URL provided contains spaces which usually, but not always, indicates an invalid URL. Click \"Ok\" to proceed with adding the current feed."))
                    return false
            }
            //
            // try {
            //     let response = await axios.get(this.urlToAddToSources);
            //     console.log(response)
            //
            //     let isRss = responseIsFeed(response)
            //     if (!isRss) {
            //         this.sourceErrorMessage = "The link you provided exists but doesn't seem to be an RSS feed";
            //         return false;
            //     }
            // } catch(error) {
            //     console.error(error)
            //     if (!error.response) return true;
            //     if (error.response.status >= 400) {
            //         this.sourceErrorMessage = "This RSS feed doesn't seem to exist";
            //         return false;
            //     }
            // }
            return true;
        },
        async validate(url) {
            if (this.computedType === 'rss') {
                let urlIsValid = await this.isUrlValid(url);
                console.log({urlIsValid})
                if (!urlIsValid) {
                    return false;
                }
            }

            let notSelected = !this.id && this.computedType !== 'rss';
            if (notSelected) {
                this.sourceErrorMessage = `Must select a ${this.computedType}`;
                return false;
            }

            let isUpContentFeed = !!this.urlToAddToSources.match(/feeds\.([a-z]*\.)?upcontent\.com/gi);
            if (isUpContentFeed) {
                this.sourceErrorMessage =
                    `Sorry, ${this.appName} RSS feeds cannot currently be added as a Collection source due the constraints of our infrastructure. We're actively working on resolving, and will notify you when available.`;
                this.clearFeed();
                return false;
            }

            return true;
        },
        async addSource() {
            let url = this.urlToAddToSources && this.urlToAddToSources.trim();
            this.validating = true;
            let isValid = await this.validate(url);
            this.validating = false;

            console.log('emit addSource');
            if (isValid) {
                this.$emit('addSource', {
                    url: url,
                    type: this.internalType,
                    id: this.id,
                    filterViewId: this.computedType !== 'rss' && this.filterViewId,
                });
            }

        },
        async focusOnAddRssTextBox(selectText = false) {
            const textBox = this.$refs['addRssTextBox']

            textBox.focus();

            if (selectText) {
                textBox.select();
            }
        },
        async forceAdd() {
            let url = this.urlToAddToSources && this.urlToAddToSources.trim();
            this.validating = true;
            let isValid = await this.validate(url);
            this.validating = false;

            console.log('emit addSource');
            if (isValid) {
                this.$emit('addSource', {
                    url: url,
                    type: this.internalType,
                    id: this.id,
                    filterViewId: this.computedType !== 'rss' && this.filterViewId,
                    force: true
                });
            }
        },
        async addSelectedFeeds() {
            this.selectedFeeds.forEach(feed => {
                let url = feed && feed.trim();
                this.$emit('addSource', {
                    url: url,
                    type: 'rss',
                    id: null,
                    filters: null
                });
            })
        },
        setError(error, suggestions = [], recoverable = false) {
            this.sourceErrorMessage = error;
            this.feedSuggestions = suggestions;
            this.recoverableError = recoverable;
        },
        clearFeed() {
            this.urlToAddToSources = '';
            this.checkingForFeeds = false;
            this.feedSuggestions = [];
        },
        resetForm(full = true) {
            this.clearFeed();
            this.resetErrors();
            this.id = '';
            this.checkingForFeeds = false;

            if (full) {
                this.legacyType = 'rss';
            }
        },
        resetErrors() {
            this.sourceErrorMessage = '';
            this.checkingForFeeds = false;
            this.feedSuggestions = [];
            this.recoverableError = false;
        },
        setTopicCollectionId(id) {
            this.id = id;
        },
    }
}
</script>

<style scoped>
hr {
    border: 1px solid var(--bs-dark);
}

.full-width {
    width: 100%;
}
</style>

<style lang="scss">
.add-source {
    .input-group.button-group {
        display: flex;
    }

    .filter-form {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .uc-select {
        width: auto;
    }

    .uc-select {
        input[type=search] {
            width: 0 !important;
        }

        &.open {
            input[type=search] {
                width: auto !important;
            }
        }
    }

    .content-source-type-select.uc-select {
        max-width: 125px;

        &.src-collection {
            max-width: 170px;
        }

        .icon-container {
            margin-right: .2em;
        }
    }

    .content-source-type-select.open {
        max-width: 120px;
    }

    .add-source--cts, .add-source--url {
        flex-grow: 1;
    }
}
</style>
