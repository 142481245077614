<template>
    <form id="content-index" class="full-width">
        <form-group-card title="Index Collection">
            <input type="checkbox" v-model="indexCollection"/>&nbsp;
                Toggle to Index Collection

        </form-group-card>
    </form>
</template>

<script>
import CollectionQualityChecks from "./V2/CollectionQualityChecksEdit";
import CollectionDataEditor from "./V2/CollectionDataEditor";
import FormGroupCard from "../Controls/FormGroupCard";
export default {
    name: "ContentIndex",
    components: {FormGroupCard, CollectionQualityChecks},
    // created() {this.saveUnchangedDefaults = true;},
    props: {
        collectionId: String
    },
    watch: {
        collectionId() { this.load() }
    },
    data() {
        return {
                indexCollection: false
        }
    },
    methods:{
        getData() {
            return {
                indexCollection: this.indexCollection
            }
        },
    },
    created() {
        this.metadataKey = 'indexingSettings';
    },
    mounted() { this.load() },
    mixins: [CollectionDataEditor]
}
</script>

<style scoped>

    legend {
        font-size: var(--font-size-16);
    }
    /*fieldset {*/
    /*    border-radius: 0.25rem;*/
    /*    border: 1px solid var(--steel-50);*/
    /*    padding: 1em;*/
    /*}*/
</style>
