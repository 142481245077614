

function copy(value) {
    window.Events.$emit('clipboardCopy', value)
}

export default {
    install: (Vue) => {
        Vue.prototype.$clipboard = {
            copy
        };
    }
}
