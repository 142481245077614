<template>
    <tr>
        <td class="text-break max-width-em">{{ member.name }}</td>
        <td class="text-break max-width-em">{{ member.email }}</td>
        <td v-if="canManageTeam">
            <a v-if="user.id != member.id" @click.prevent="editAssignments" href="#">Edit</a>
        </td>
        <td class="member-editor-role">
            <select
                :value="role"
                class="form-control form-control-sm"
                @change="updatePrivileges($event)"
                v-if="(!team.owner.includes(member.id) && canManageTeam) && roles.includes(member.role) && isNotCurrentUser(member)"
            >
                <option
                    :value="role"
                    v-for="role in roles"
                    :key="'member'+member.id+'-role-selection-'+role"
                >
                    {{ toCapitalized(role) }}
                </option>
            </select>
            <span v-else>{{ showMemberRole }}</span>
        </td>
        <td v-if="canManageTeam" class="text-center">
            <button
                v-if="member.id !== user.id"
                @click="removeMember"
                class="btn btn-sm btn-danger"
            >
                <i class="fa fa-times"/>
            </button>
        </td>
    </tr>
</template>

<script>

import Base from './BaseMixin'
import capitalize from "capitalize";

export default {
    mixins: [Base],
    computed : {
        showMemberRole() {
            return this.toCapitalized(this.member.role);
        }
    },
    methods: {
        toCapitalized(string) {
          return typeof string === 'string' ? capitalize(string) : '';
        },
    }
}
</script>

<style scoped>
td, th {
    font-size: var(--font-size-13);
    padding: 4px 8px;
    border: none;
}

.btn-sm {
    padding: 0 4px;
}

.max-width-em {
    max-width: 20em;
}

.member-editor-role {
    min-width: 10em;
}

/*select.form-control {*/
/*font-size: 14px;*/
/*height: 26px !important;*/
/*}*/
</style>
