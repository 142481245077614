<template>
    <div class="article-actions" v-if="suggestion">
            <a href="#!" class="article-action-link" :title="favoritedByText" @click.prevent @click="toggleFavorite">
                <span v-if="isFavorited">
                    <span class="text-danger"><i class="fa fa-star"></i></span>
                </span>
                <span v-else>
                    <i class="fa fa-star-o"></i>
                </span>
                <br />
                Favorite
            </a>
            <a href="#" class="article-action-link" @click.prevent="$emit('goToNotes')">
                <i class="fa fa-comment" v-if="suggestion.notes.length > 0"></i>
                <i v-else class="fa fa-comment-o"></i>
                <br />  Notes
            </a>
            <share-link class="article-action-link" :suggestion="suggestion" text="Share" :type="containerType"/>
            <a  class="article-action-link view-original" :href="$shortener.link(suggestion)" target="_blank">
                <i class="fa fa-external-link me-1"></i>
                <br /> View Original <span class="d-none d-sm-inline">Source</span>
            </a>
            <a class="article-action-link" @click.prevent="toggleInCollectionBatch" title="Add to collections" href="#">
                <i v-if="isInCollectionBatch" class="fa fa-check-circle text-success"></i>
                <i v-else class="fa fa-plus"></i><br />  Add to Collections
            </a>
    </div>
</template>

<script>
import ShareLink from '@/components/Suggestions/SuggestionCards/ShareLink'
import {mapGetters} from 'vuex'

export default {
    props : ['suggestion', 'isInCollectionBatch'],
    components : {
        ShareLink
    },
    data(){
        return {

        }
    },
    computed : {
        ...mapGetters({
            user : 'currentUser',
            hasSuggestions : 'hasSuggestions',
            allNotes : 'notes'
        }),
        containerType(){
            return this.hasSuggestions && this.hasSuggestions.type;
        },
        isFavorited(){
            return this.favoritedIndex > -1
        },
        favoritedIndex(){
            if (this.suggestion)
            return this.suggestion.favorited_by.map(d => d.id).indexOf(this.user.id);
        },
        favoritedByText(){
            if (this.suggestion.favorited_by.length)
                return "Favorited by " + this.suggestion.favorited_by.map(u => u.id == this.user.id ? 'you' : u.name).join(", ");

            return "Toggle Favorite"
        },
        notes(){
            return this.allNotes.filter(note => note.suggestion && note.suggestion.includes(this.suggestion.id));
        }
    },
    mounted(){

    },
    methods : {
        toggleFavorite() {
            var suggestion = JSON.parse(JSON.stringify(this.suggestion));

            if (this.isFavorited){
                this.$api.delete('/favorite-suggestions/' + this.suggestion.id);
                suggestion.favorited_by.splice(this.favoritedIndex, 1)
            } else {
                this.$api.put('/favorite-suggestions/' + this.suggestion.id);
                suggestion.favorited_by.push(this.user)
            }

            this.$store.dispatch('updateSuggestion', { suggestion });

        },
        toggleInCollectionBatch(){
            this.$emit('toggleCardInCollectionBatch', {card : this.suggestion});
        },
    }
}
</script>

<style lang="scss">
.article-actions {
    display: flex;
    justify-content: center;
    margin: 10px auto 20px;
    flex-wrap: wrap;
    align-items: center;
}

.article-actions .article-action-link {
    display: block;
    border: 1px solid #918c96;
    border-radius: var(--border-radius);
    font-size:var(--font-size-11);
    white-space: nowrap;
    margin: 0 5px 5px;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .article-actions .article-action-link {
        padding: 3px 5px !important;
        font-size: var(--font-size-11) !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .article-actions .article-action-link {
        font-size:var(--font-size-11) !important;
        margin: 0 3px 3px !important;
    }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 1200px) {
    .article-actions .article-action-link {
        font-size: var(--font-size-12) !important;
        padding: 3px 5px !important;
        margin: 0 10px!important;
    }
}


.article-actions br {
    display: none !important;
}

@media screen and (max-width: 767px){
    .article-actions .article-action-link {
       text-align: center;
       border: none;
       min-width: unset !important;
    }
    .article-actions br {
        display: inherit !important;
    }
}

.article-actions a:hover:hover {
    text-decoration: none;
    background: #fff;
}

</style>
