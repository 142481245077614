import {features} from '@/Features'
export default class Shortener {
    constructor(domain) {
        this.domain = domain;
        let isHearsay = location.host.includes('hearsay');
        let isQa = domain.includes('qa') || domain.includes('localhost');
        if (isHearsay) {
            this.domain = isQa ? 'go.qa.content.hearsaysocial.com' : 'go.content.hearsaysocial.com'
        }

    }

    link(suggestion) {
        return features.shortenLinks ? `https://${this.domain}/${suggestion.id||suggestion}` : suggestion.source_url;
    }
}
