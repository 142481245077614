<template>
    <div class="full-height">
        <div v-if="!templateId" class="text-muted ms-4">Select a template to edit or create a new one</div>
        <div v-if="templateId && !loaded" class="full-width text-center position-relative">
            <spinner size="100" class="position-absolute" style="margin: auto; top:0;left:0;right:0;bottom:0;"></spinner>
        </div>
        <div id="bee-editor" ref="beeEditor">
        </div>
    </div>
</template>

<style>
    #bee-editor iframe {
        min-height: calc(100vh - 70px);
        min-height: max(calc(100vh - 70px), 500px);
    }
</style>

<script>
    import {mapGetters} from 'vuex'
    import Bee from '@mailupinc/bee-plugin'
    import defaultTemplate from '@/data/BeeDefaultTemplate'
    import HasBeeTemplates from './HasBeeTemplates'
    import Spinner from "@/components/Controls/Spinner";
    import * as toastr from "toastr";

    export default {
        components: {Spinner},
        mixins: [HasBeeTemplates],
        computed: {
            ...mapGetters({
                currentAccountOverview: 'currentAccountOverview',
           }),
            apiBasePath() { return `/accounts/${this.account&&this.account.id}/integrations/bee` },
            api() { return this.$rest.accounts(this.account&&this.account.id).integrations.bee; },
            account() { return this.useAccount || this.currentAccountOverview}
        },
        props: {
            useAccount: Object,
            templateId: {},
        },
        data() {
            return {
                loaded: false
            }
        },
        watch: {
            account() {
                this.init()
            },
            templateId() {
                this.init();
            }
        },
        async mounted() {
            if (this.account) this.init();
        },
        methods: {
            async init() {
                this.$refs.beeEditor.innerHTML = '';
                this.loaded = false;
                console.log('BeeEditor.init', this.templateId, this.account)
                if (!this.account.id || !this.templateId) return;
                let [tokenData, templateData] = await Promise.all([
                   this.$api.get(this.apiBasePath+'/authentication/full'),
                   this.$api.get(`/content-templates/${this.templateId}/bee/data`)

                ].map(p => p.then(r => r && r.data)));

                let token =  tokenData && tokenData.auth_data;

                console.log(templateData)

                let template = templateData || defaultTemplate;

                var config = {
                    uid: this.account.id,
                    container: 'bee-editor',
                    // contentDialog: {
                    //     externalContentURLs: {
                    //         label: 'UpContent Digest',
                    //         handler: async function(resolve, reject) {
                    //             try {
                    //                 resolve(await this.openBeeDialog())
                    //             } catch (e) {
                    //                 reject(e)
                    //             }
                    //         }
                    //     }
                    // },
                    rowsConfiguration: {
                        emptyRows: true,
                        defaultRows: true,
                        externalContentURLs: [{
                            name: "UpContent Selections",
                            value: `https://${process.env.VUE_APP_API_BASE_URL}/content-templates/bee/custom-rows`
                        }]
                    },


                    onSaveAsTemplate: (jsonFile) => {
                        this.saveJson(jsonFile)
                    },
                    onSave: (jsonFile, htmlFile) => {
                        this.saveJson(jsonFile)
                        this.saveHtml(htmlFile)
                        toastr.success('Saved')

                    },
                    onSend: (htmlFile)  => {
                        this.saveHtml(htmlFile)
                    }
                };
                console.log('token', token, config, template)
                let bee = new Bee(token);
                bee.start(config, template);
                this.loaded = true;

            },


            saveJson(jsonFile) {
                console.log(jsonFile)
                this.saveFile('email.json', jsonFile, `/content-templates/${this.templateId}/bee/data`)
            },
            saveHtml(htmlFile) {
                console.log(htmlFile)
                this.saveFile('email.html', htmlFile, `/content-templates/${this.templateId}/template`)
            },
            async saveFile(filename, content, path) {
                let blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
                let file = new File([blob], filename);
                const formData = new FormData();
                const fileName = filename.replace(/[\W.]+/g, '-');
                formData.append('file', file, fileName);
                let result = await this.$api.post(path, formData);
                return result
            },
            async openBeeDialog() {

            }
        }
    }
</script>
