<template>
    <div class="footer small pb-1">
        <ul class="no-bullets ps-0 text-center">
            <li class="ps-0 ms-2 me-2 d-inline">
                <a href="http://learn.upcontent.com" target="_blank">Help</a>
            </li>
            <li class="ps-0 ms-2 me-2 d-inline">
                <a href="https://www.upcontent.com/privacy-policy" target="_blank">Privacy</a>
            </li>
            <li class="ps-0 ms-2 me-2 d-inline">
                <a href="https://www.upcontent.com/terms-of-service" target="_blank">Terms</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
}
</style>
