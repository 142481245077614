export function showSelectionEdit(selection) {
    window.Events.$emit(showSelectionEdit.name, selection)
}

export function startPageLoad() {
    window.Events.$emit(startPageLoad.name)
}
export function finishPageLoad() {
    window.Events.$emit(finishPageLoad.name)
}
export function startPageLoadItem() {
    window.Events.$emit(startPageLoadItem.name)
}
export function finishPageLoadItem() {
    window.Events.$emit(finishPageLoadItem.name)
}
export function awaitPageLoadItem(itemPromise) {
    window.Events.$emit(awaitPageLoadItem.name, itemPromise)
    return itemPromise
}


[showSelectionEdit].forEach(x => x.$on = (callback) => window.Events.$on(x.name, callback))
