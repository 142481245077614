<template>
    <div>
        <div :class="{hide: !(connectionHasAccessToEmail && ! networkActivity)}">
            <button v-if="!showRssForm"
                    type="button"
                    class="btn btn-primary full-width-button"
                    @click.prevent="onShowRssForm">
                Create HubSpot Email Campaign
            </button>
            <div v-if="showRssForm">
                <div class="legend">Filter for this Collection</div>
                <select-filter-view
                    v-model="filterViewId"
                    has-content-type="collection"
                    :has-content-id="collection.id"
                    class="mb-2"
                    ref="filterViewEdit"
                >
                </select-filter-view>
            </div>
            <button v-if="accountFeatures.hubspotEmailV2" id="hubspot-email-rss-finish"
                    :class="{hide: !showRssForm}"
                    class="btn btn-primary full-width"
                    type="button"
                    target="_blank"
                    @click.prevent="finish($event)"
                    ref="finishButton">
                Finish in HubSpot
            </button>
            <a v-else id="hubspot-email-rss-finish"
               :class="{hide: !showRssForm}"
               class="btn btn-primary"
               :href="hubspotLinkOld"
               target="_blank"
               @click="finishOld"
               ref="finishButton">
                Finish in HubSpot
            </a>
            <div  v-if="existingEmails&&existingEmails.length" class="mt-4 card">
                <h6 class="card-header" v-if="existingEmails&&existingEmails.length">HubSpot Digests using this Collection</h6>
                <ul class="ps-0 mb-0 list-group">
                    <li v-for="email in existingEmails" :key="email.id" class=" list-group-item">
                        <a :href="hubspotEditLink(email.id)" target="_blank">{{ email.name }} (Edit in HubSpot)</a>
                        <div class="text-muted small" v-if="schedule(email)">{{ schedule(email) }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="!connectionHasAccessToEmail" class="alert alert-gold">
            <i class="fa fa-warning"></i>
            Hub Domain {{ hubDomain }}} does not have the capability to send marketing campaigns OR the individual who
            authenticated the Hub to {{appName}} does not have Super Admin rights. Please reconnect HubSpot to {{appName}}
            as a Super Admin.
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ToggleSwitch from '@/components/Controls/ToggleSwitch.vue'
import ConnectButton from '@/components/Settings/Team/ConnectDisconnectButton.vue'
import {OAuthConnection} from "@/services/OAuth";
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView.vue";
import FeedUrls from "@/utilities/FeedUrls.vue";

export default {
    components: {SelectFilterView},
    mixins: [FeedUrls],
    props: {
        collection: Object,
        team: Object,
        visible: {type: Boolean, default: null},
    },
    data() {
        return {
            open: this.authorized,
            iconClass: 'hubspot-icon icon-dynamic',
            networkActivity: false,
            label: "HubSpot Email",
            description: "Engage your audience with email digests.",
            learnMoreUrl: "http://learn.upcontent.com/en/articles/4436043-use-hubspot-to-craft-a-curated-email-digest",
            existingEmails: [],
            filterViewId: null,
            rssLink: null,
            showRssForm: false
        };
    },
    watch: {
        showRssForm() {
            setTimeout(() => document.getElementById('hubspot-email-rss-finish').scrollIntoView(), 100)
        },
        team() {
            // this.open = this.val || this.authorized;
            this.getEmails()
        },
        async collection() {
            await this.getEmails();
            this.rssLink = await this.getRssLink();
        },
        async filterViewId() {
            this.rssLink = await this.getRssLink();
        }
    },
    computed: {
        ...mapGetters({
            // account: 'currentAccount',
            currentAccountOverview: 'currentAccountOverview',
            apis: 'externalApis',
            features: 'features',
            accountFeatures: 'accountFeatures',
            isHootsuite: 'isHootsuite',
            hubspotLink: '',
            learnUrls: 'learnUrls',
            appName: 'appName',
        }),
        hubspotAccountData() {
            return this.apis && this.team && this.team.id && this.apis['hubspot'] && this.apis['hubspot'][this.team.id];
        },
        hubId() {
            return this.hubspotAccountData && this.hubspotAccountData.hub_id
        },
        hubDomain() {
            return this.hubspotAccountData && this.hubspotAccountData.hub_domain || ''
        },
        service() {
            return new OAuthConnection('hubspot', this)
        },
        show() {
            return this.features.hubspotEmail && (this.visible !== false);
        },
        authorized() {
            return !!this.service && !!this.service.isConnected(this.team)
        },
        connectionHasAccessToEmail() {
            return this.authorized && this.feature && this.feature.activated;
        },
        feature() {
            return this.hubspotAccountData
                && this.hubspotAccountData.collection_features
                && this.hubspotAccountData.collection_features.find(f => f.name.includes('email'))
        },
        ready() {
            return this.service
        },
        hubspotLinkOld() {
            return `https://app.hubspot.com/content/${this.hubId}/create/email/all?emailType=BLOG_RSS&rssUrl=${encodeURIComponent(
                this.rssLink)}`;
        }
    },
    methods: {
        schedule(email) {
            return email.rssToEmailTiming && email.rssToEmailTiming.repeats &&
                this.$strings.capitalize(email.rssToEmailTiming.repeats) || 'Not yet scheduled'
        },
        status(email) {
            return email.currentState &&
                this.$strings.capitalize(email.currentState.toLowerCase().replace(/_/g, ' ').replace('Rss ', ''))
        },
        onShowRssForm() {
            this.showRssForm = true;
        },
        async finish() {
            this.$emit('showRssFormChange', false);
            let response = await this.$api.post(`/accounts/${this.currentAccountOverview.id}/integrations/hubspot/resources/emails`,
                {rssLink: this.rssLink, collection_id: this.collection.id});
            let edit_link = response.data && response.data.edit_link;
            this.hubspotEditLink = edit_link;
            window.open(edit_link, '_blank');

        },
        finishOld() {
            this.showRssForm = false;
        },
        hubspotEditLink(id) {
            return `https://app.hubspot.com/email/${this.hubId}/edit-dd/${id}/content`
        },
        async getEmails() {
            try {
                if (this.team && this.team.id && this.collection && this.collection.id &&
                    this.connectionHasAccessToEmail && this.authorized) {
                    let emailEndpoint = `/accounts/${this.team.id}/integrations/hubspot/resources/emails`;
                    let query = 'limit=100';

                    await this.$api.get(emailEndpoint + '?' + query).then(resp => {
                        if (resp && resp.data) {
                            let emails = resp.data.objects;
                            let rssEmails = emails.filter(x => x.emailType === 'RSS_EMAIL');
                            let relatedEmails = rssEmails.filter(x => x.rssUrl && x.rssUrl.includes(this.collection.id))
                            this.existingEmails = relatedEmails;
                        }
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        async getRssLink() {
            if (this.collection) {
                return await this.feedUrlForFilterView('collection', this.collection.id, this.filterViewId);
            }

            return null;
        }
    },
    mounted() {
        this.getEmails()
    }
}
</script>

<style scoped>

.card>.list-group {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin: -1px;
}
.full-width-button {
    width: 100%;
}

.power-up-label .icon-dynamic {
    vertical-align: top;
    margin-top: .2em;
}

.power-up-label .amplify-icon, .power-up-label .sniply-icon {
    margin-top: .25em;
}

.power-up-label {
    display: inline-block;
}

.power-up-label a {
    text-decoration: underline;
}

.powerup-action {
    right: 0;
    left: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 10px auto auto;
    height: fit-content;
}

.powerup summary {
    background: var(--bs-light);
    border-bottom: 1px solid var(--bs-default);
    list-style: none;
    list-style-type: none;
    margin: -10px -10px -1px -10px;
    color: var(--bs-dark);
    position: relative;
}

details.powerup summary::-webkit-details-marker {
    display: none;
}

.powerup {
    border: 1px solid var(--bs-default);
    padding: 10px;
    padding-bottom: 0;
}

.powerup[open] {
    padding-bottom: 20px;
}


.powerup[open] summary {
    margin-bottom: 10px;
}

.hide {
    display: none;
}

.powerup:not(:last-child) {
    border-bottom: none;
}

.powerup-message {
    display: inline-block;
    color: var(--bs-danger);
    font-size: 0.7em;
    max-width: 360px;
    margin-left: 10px;
    vertical-align: middle;
    white-space: pre;
}

.powerup summary {
    vertical-align: middle;
}
</style>
