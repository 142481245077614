

const state = {
    pageLoading: false
}


const actions = {
    startPageLoad : ({commit}) => {
        commit("SET_PAGE_LOADING", true);
    },
    finishPageLoad : ({commit}) => {
        commit("SET_PAGE_LOADING", false);
    }
}

const getters = {
    pageLoading: state => state.pageLoading
}



const mutations = {
    SET_PAGE_LOADING : (state, val) => {
        state.pageLoading = val;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
