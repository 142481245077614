<template>

</template>

<script>
import {OAuthConnection} from "@/services/OAuth";
import {mapGetters} from "vuex";
import EveryoneSocialCollectionData from "@/components/Integrations/EveryoneSocial/EveryoneSocialCollectionData.vue";

export default {
    name: "CollectionIntegrationDataBase",
    props: {
        beforeAuth: { type: Function, default: () => {}},
        collection: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            integrationName: null,
            savedSettings: {},
            service: null
        }
    },
    created() {
        this.service = new OAuthConnection(this.apiName, this);
    },
    methods: {
        fetchSettings() {
            if (this.collection.id) {
                return this.$api
                    .get(`/collections/${this.collection.id}/integrations/${this.integrationName}/settings`)
                    .then(x => x.data && x.data.settings);
            }
            else {
                return Promise.reject()
            }
        },
        fetchResource(resourceName) {
            return this.$api
                .get(`/accounts/${this.currentAccountOverview.id}/integrations/${this.integrationName}/resources/${resourceName}`)
                .then(x => x.data)
        },
        updateSettings(body) {
            if (this.collection.id) {
                return this.$api.post(`/collections/${this.collection.id}/integrations/${this.integrationName}/settings`, body);
            }
            else {
                return Promise.reject()
            }
        }
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
        }),
        isAuthorized() {
            return !!this.service && !!this.service.isConnected(this.currentAccountOverview)
        },
    }
}
</script>

<style scoped>

</style>
