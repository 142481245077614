<template>
    <div>
        <div>
            <h4 class="subtitle">Sort By</h4>
            <p class="filter-item" v-for="thisType in availableSortByTypes" :key="'content-types-'+thisType">
                <label @click.stop><input
                    @change="update()"
                    type="radio"
                    class="form-check-input"
                    :value="thisType"
                    v-model="sortBy"
                >
                    {{ thisType }}
                </label>
            </p>
        </div>
    </div>
</template>

<script>

import Base from './FilterMixin'
import {sortTypes} from "@/Constants";

export default {
    mixins: [Base],
    data() {
        return {
            sortBy: '',
        }
    },
    computed: {
        availableSortByTypes() {
            var types = sortTypes(this.hasSuggestions.type, this.topicLanguage,
                this.accountFeatures.predictedPerformance);

            return types;
        },
    },
    watch: {
        filters() {
            if (this.filters)
                this.sortBy = this.filters.sortBy;
        }
    },
    mounted() {
        this.sortBy = this.filters.sortBy
    },
    methods: {
        update() {
            this.updateFilter('sortBy', this.sortBy);
        }
    }
}
</script>

<style>
</style>
