<template>
    <ApiKeyIntegrationSettingsContainer
        v-model="on"
        :accountData="accountData"
        :collectionData="loadedData"
        label="Generative AI Post Text"
        description="OpenAI for AI Post Text Generation"
        icon-class="openai-icon icon-dynamic"
        :visible="show && powerupSearchMatches('openai open ai machine learning generative') && powerUpEnabled('openai')"
        >
<!--        @connect="connect"-->
<!--    >-->
        <CollectionPostTextSettingsEditor
            v-show="on"
            :postTextSettings="loadedData"
            @fieldChange="fieldChanged">
        </CollectionPostTextSettingsEditor>
    </ApiKeyIntegrationSettingsContainer>
</template>

<script>
import ApiKeyIntegrationSettingsController
    from "@/components/Collections/powerups/ApiKeyIntegrationSettingsController.vue";
import ApiKeyIntegrationSettingsContainer
    from "@/components/Collections/powerups/ApiKeyIntegrationSettingsContainer.vue";
import CollectionPostTextSettingsEditor from "@/components/Collections/powerups/CollectionPostTextSettingsEditor.vue";
import IntegrationSearch from "@/components/Integrations/IntegrationSearch.vue";
import {mapGetters} from "vuex";

function defaultValues() {
    return {
        destination: null,
        agent: null,
        on: undefined
    }
}
export default {
    name: "CollectionPostTextSettingsPanel",
    components: {CollectionPostTextSettingsEditor, ApiKeyIntegrationSettingsContainer},
    mixins: [ApiKeyIntegrationSettingsController, IntegrationSearch],
    data: defaultValues,
    methods: {
        fieldChanged(data) {
            Object.assign(this, data);
        },
    },
    computed: {
        ...mapGetters({
            features: "accountFeatures",
        }),
        show() {
            return this.features.openaiIntegration
        },
        defaultValues
    },
    watch: {
        accountData() {
            this.on = Boolean(this.accountData && this.accountData.openai_api_key);
        }
    },
    created() {
        this.accountMetadataKey = 'openai';
        this.metadataKey = 'post_text_generation';
    },
    mounted() {
        this.accountDataLoad(); // FIXME: test edge case of user changing account data while logged in

    }
}
</script>

<style scoped>

</style>
