export const jsonDiffers = (v1, v2) => JSON.stringify(v1) !== JSON.stringify(v2)

export function getChangedKeys(m1, m2, valueChanged) {
    let keysMatchAndValuesDont = ({k2, v2, k1, v1}) => k1 === k2 && valueChanged(v1, v2)
    return Object.entries(m1)
        .filter(([k1, v1]) =>
            Object.entries(m2)
                .some(([k2, v2]) =>
                    keysMatchAndValuesDont({k2, v2, k1, v1})
                )
        ).map(([k,]) => k)
}
