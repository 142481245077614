import * as types from '../mutation-types'
import Vue from "vue";
import {fetchSuggestionURls} from "../../services/suggestions-api";
import moment from "moment";

const suggestionsPerPage = 30

const state = {
    suggestions : [],
    reloadQueue: [],
    suggestionsCache : [],
    loadingSuggestionList : false,
    downloadingSuggestions : false,
    suggestionsDownloading: [],
    suggestionsDownloaded: 0,
    readingSuggestion : false,
    scrollToNotes : false,
    queryOffset : 0,
    isAtLastPage : false,
    lastChecked: false,
    isFetching : false,
}

const getters = {
    suggestions: state => {
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
       return state.suggestions.filter(onlyUnique)
    },
    suggestionsObjects: (state, getters) => {
        return getters.suggestions.map(s => state.suggestionsCache.find(c => s.includes(c.id))).filter(s => s);
    },
    reloadQueue: state => state.reloadQueue,
    suggestionsCache : state => state.suggestionsCache,
    suggestionTransferStatus : state => {
        return {
            list : state.loadingSuggestionList, 
            cache : state.downloadingSuggestions
        }
    },
    readingSuggestion: state => state.readingSuggestion,
    scrollToNotesInReadingSuggestion : state => state.scrollToNotes,
    suggestionsQueryOffset : state => state.queryOffset,
    _suggestion_queueIsBlocked: (s, g, rs, rootGetters) => rootGetters.accountsBeingLoaded.length > 0,
    stillBuildingTopic: (s, getters, rs, rootGetters) => {
        if (!rootGetters.currentTopic || rootGetters.suggestionViewerType !== 'topics') return false;
        if (!rootGetters.currentTopic.created && !rootGetters.currentTopic.updated) return false;

        let createdDifference = moment().diff(moment.utc(rootGetters.currentTopic.created), 'seconds');
        let updatedDifference = moment().diff(moment.utc(rootGetters.currentTopic.updated), 'seconds');
        let limit = 60 * 4;
        //console.log('stillBuildingTopic', {createdDifference, updatedDifference, suggestionLength: getters.suggestions.length})
        let stillBuildingAfterCreation = createdDifference < limit && getters.suggestions.length < 20;
        let stillBuildingAfterUpdated = updatedDifference < limit && getters.suggestions.length < 20;

        return stillBuildingAfterCreation || stillBuildingAfterUpdated
    },
}

function isProcessing(s) {
    return s && ['added', 'pending'].includes(s.processing_status);
}

const actions = {
    async resetSuggestionLoadingState({commit, dispatch}) {
        await dispatch('completeDownloadingSuggestionList');
        await dispatch('cancelPreviousRequests', {category: 'loadSuggestionUrls'})
        await dispatch('completeDownloadingSuggestions');
        await commit('SET_FETCHING', {isFetching: false});
    },
    async clearSuggestionCache({commit}) {
        commit('CLEAR_SUGGESTION_CACHE')
    },
    async loadSuggestions({dispatch, commit, state, getters}, {keep}){
        console.log('loadSuggestions', getters.hasSuggestions.id, getters._suggestion_queueIsBlocked)
        await dispatch('cancelPreviousRequests', {category: 'loadSuggestionUrls'})
        await commit('SET_IS_AT_LAST_PAGE', {isAtLastPage: false})
        return new Promise(async resolve => {
            if (getters._suggestion_queueIsBlocked) return;

            if ( ! getters.hasSuggestions.id ) return;

            await commit('RESET_SUGGESTIONS_QUERY_PAGE_OFFSET', {});

            await dispatch('beginDownloadingSuggestionList');

            if ( ! keep){
                await dispatch('setSuggestionUrls', {urls : []});
                commit("CLEAR_SUGGESTION_CACHE")
            }

            let cancelToken = await dispatch('getNewCancelToken', {category: "loadSuggestionUrls"});

            Vue.nextTick( () => {
                console.log('fetchSuggestionURls', getters.hasSuggestions)
                // console.log('>_ suggestions.js > showFavorites', getters.showFavorites, getters.showFavoriteSuggestions, 'filters', getters.currentFilters)
                fetchSuggestionURls(getters.hasSuggestions, {
                    filters: getters.currentFilters,
                    showFavorites: getters.showFavoriteSuggestions,
                    page: state.queryOffset,
                    suggestionsPerPage,
                    cancelToken
                }).then(async urls => {
                    await dispatch('setSuggestionUrls', {urls});
                    dispatch('completeDownloadingSuggestionList');
                    await dispatch('downloadSuggestionUrls', {urls})
                    resolve(urls)
                }).catch(e => {
                    resolve([])
                })
            })
        })
    },
    async loadNextPageOfSuggestions({commit, dispatch, state, getters}){
        console.log('loadNextPageOfSuggestions.start', state.isAtLastPage, state.isFetching, state.lastChecked - new Date())
        let lessThan15SecondsAgo = date => date - new Date() < 15 * 1000;
        if (state.isAtLastPage && state.lastChecked && lessThan15SecondsAgo(state.lastChecked) || state.isFetching) return;

        await commit('INCREMENT_SUGGESTIONS_QUERY_PAGE_OFFSET', {});

        await dispatch('beginDownloadingSuggestionList');
        await commit('SET_FETCHING', {isFetching: true});

        console.log('loadNextPageOfSuggestions.beginFetch', state.queryOffset)
        await fetchSuggestionURls(getters.hasSuggestions, {
            filters: getters.currentFilters,
            showFavorites: getters.showFavoriteSuggestions,
            page: state.queryOffset,
            suggestionsPerPage,
            cancelToken: null
        })
            .then(async nextPage => {
                console.log('loadNextPageOfSuggestions.gotResponse', nextPage)
                commit('SET_FETCHING', {isFetching: false});
                let urls = [...getters.suggestions].concat(nextPage);
                await dispatch('setSuggestionUrls', {urls});
                await dispatch('downloadSuggestionUrls', {urls})
                await dispatch('completeDownloadingSuggestionList');

                if (nextPage.length == 0){
                    await commit('SET_IS_AT_LAST_PAGE', {isAtLastPage: true})
                } else if (state.isAtLastPage) {
                    await commit('SET_IS_AT_LAST_PAGE', {isAtLastPage: false})
                }
            }).catch(async e => {
                await dispatch('completeDownloadingSuggestionList');
            })
    },
    async downloadSuggestionUrls({dispatch, commit, getters}, {urls}){
        //console.log('downloadSuggestionUrls', {urls, suggestionsCache: getters.suggestionsCache})
        let urlsToDownload = urls.filter(url => ! getters.suggestionsCache.find(s => url.includes(s.id) && !isProcessing(s)));

        if (urlsToDownload.length > 0){
            await dispatch('beginDownloadingSuggestions');
        }

        await commit(mutations.RESET_SUGGESTIONS_DOWNLOADED.name)

        await Promise.all(urlsToDownload.map(url => {
            dispatch(actions.downloadUrl.name, {url})
        }))
        await dispatch('completeDownloadingSuggestions');
    },
    async downloadUrl({dispatch, commit, state}, {url, retries}) {
        console.log("downloadUrl", {url})
        if (state.suggestionsDownloading.indexOf(url) > -1 ) return;

        await commit(mutations.START_DOWNLOADING_URL.name, {url});

        let cancelToken = await dispatch('getNewCancelToken', {category: "loadSuggestionCache"});

        try {
            const response = await window.$app.api.get(url, { cancelToken })
            await commit(mutations.FINISH_DOWNLOADING_URL.name, {url});
            await commit(mutations.INCREMENT_SUGGESTIONS_DOWNLOADED.name);
            let suggestion = response.data
            await dispatch('addSuggestionToCache', {suggestion, fromServer: true});
            await dispatch(actions.fetchContexts.name, {suggestion, url});
            await dispatch(actions.queueForReloadIfNeeded.name, {suggestion, retries})
            return suggestion
        }
        catch {
            await commit(mutations.FINISH_DOWNLOADING_URL.name, {url})
            await dispatch('completeDownloadingSuggestions');
            return null;
        }
    },
    async queueForReloadIfNeeded({dispatch, commit, state}, {suggestion, retries}) {
        console.log('queueForReloadIfNeeded')
        //console.log('* minutesSinceUpdate', Math.abs(suggestion.seconds_since_update/60));
        const minutesSinceUpdate = suggestion ? Math.abs(suggestion.seconds_since_update/60) : null; // add back to if check to debug (minutesSinceLastUpdate === 10)
        if (suggestion && suggestion.processing_status === 'added' && suggestion.id && minutesSinceUpdate < 60) {
            await commit('ADD_TO_RELOAD_QUEUE', {suggestion, retries, lastAttempt: new Date()})
        }
    },
    async processNextReload({getters, commit, dispatch}) {
        const inCurrentContext = suggestion => getters.suggestions.some(s => s.includes(suggestion && suggestion.id));
        const isReloadCandidate = ({suggestion, nextRetry}) => inCurrentContext(suggestion) && nextRetry >= new Date();
        const byNextRetryAscending = (a, b) =>  a.nextRetry - b.nextRetry;

        let next = getters.reloadQueue.filter(isReloadCandidate).sort(byNextRetryAscending)[0];
        if (!next) return;
        if (state.suggestionsDownloading.includes(`/suggestions/${next.suggestion.id}`)) return;
        await commit('REMOVE_FROM_RELOAD_QUEUE', {suggestion: next.suggestion});
        if (inCurrentContext(next.suggestion)) {
            await dispatch('downloadUrl', {url: `/suggestions/${next.suggestion.id}`, retries: next.retries});
        }
    },
    async fetchContexts({dispatch, commit}, {suggestion, url}){
        let cancelToken = await dispatch('getNewCancelToken', {category: "loadSuggestionCache"});

        return window.$app.api.get(url + '/contexts', {cancelToken})
            .then(response => {
                dispatch('addSuggestionToCache',
                    {suggestion : {...suggestion, contexts : response.data}, fromServer: true}
                );
            }).catch(()=>{
            //Timeout out or canceled
        });
    },
    addSuggestionToCache ({ commit, dispatch, getters }, {suggestion, fromServer}) {
        if (!fromServer) {
            suggestion = {...suggestion, processing_status: "pending"}
        }
        commit(types.CACHE_FULL_SUGGESTION, {
            suggestion
        })
        if (!fromServer && suggestion.id && getters.suggestions.some(s => s.includes(suggestion.id)) && getters.accountFeatures.selectionProcessingUI) {
            dispatch('downloadUrl', {url: `/suggestions/${suggestion.id}`})
        }
    },
    setSuggestionUrls ({commit}, {urls})  {
        commit(types.SET_SUGGESTION_URLS, {
            urls
        });
    },
    addUrlToSuggestions({commit}, {url}){
        commit(types.APPEND_URL_TO_SUGGESTIONS, {
            url
        })
    },
    async clearSuggestions ({commit}) {
        await commit(types.EMPTY_SUGGESTIONS)
    },
    updateSuggestion({commit}, {suggestion}){
        commit(types.UPDATE_SUGGESTION, {
            suggestion
        })
    },
}

const mutations = {
    CLEAR_SUGGESTION_CACHE(state) {
        state.suggestionsCache = []
    },
    ADD_TO_RELOAD_QUEUE(state, {suggestion, retries, lastAttempt}) {
        const minutesSinceUpdate = suggestion ? Math.abs(suggestion.seconds_since_update/60) : null;
        retries = retries || 0;
        retries++;
        let delay = retries === 1 || minutesSinceUpdate < 3 ? retries*10*1000 : minutesSinceUpdate - 2;
        let nextRetry = new Date(lastAttempt);
        nextRetry.setSeconds(lastAttempt.getSeconds() + delay);
        state.reloadQueue.push({suggestion, retries, lastAttempt, nextRetry});
    },
    REMOVE_FROM_RELOAD_QUEUE(state, {suggestion}) {
        state.reloadQueue = state.reloadQueue.filter(s => s.suggestion.id === suggestion.id);
    },
    SET_FETCHING(state, {isFetching}) {
        state.isFetching = isFetching;
    },
    SET_IS_AT_LAST_PAGE(state, {isAtLastPage}) {
        state.isAtLastPage = isAtLastPage;
        state.lastChecked = new Date();
    },
    FINISH_DOWNLOADING_URL(state, {url}){
        state.suggestionsDownloading = state.suggestionsDownloading.filter(x => x !== url)
    },
    START_DOWNLOADING_URL(state, {url}) {
        state.suggestionsDownloading = [...state.suggestionsDownloading, url]
    },
    RESET_SUGGESTIONS_DOWNLOADED(state) {
         state.suggestionsDownloaded = 0;
    },
    [types.SET_READING_SUGGESTION](state, {suggestion, scrollToNotes}){
        state.readingSuggestion = suggestion;
        state.scrollToNotes = scrollToNotes
    },
    [types.RESET_SCROLL_TO_NOTES](state){
        state.scrollToNotes = false;
    },
    // START_LOADING_SUGGESTION_VIEW() {
    //     state.downloadingSuggestionsList = true;
    // },
    [types.BEGIN_DOWNLOADING_SUGGESTIONS](state){
        state.downloadingSuggestions = true
    },
    [types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST](state) {
        state.loadingSuggestionList = true;
    },
    [types.COMPLETE_DOWNLOADING_SUGGESTIONS](state) {
        state.downloadingSuggestions = false
    },
    [types.COMPLETE_DOWNLOADING_SUGGESTIONS_LIST](state) {
        state.loadingSuggestionList = false
    },
    [types.SET_SUGGESTION_URLS](state, {urls}){
        if (Array.isArray(urls))
            state.suggestions = urls;
    },
    [types.APPEND_URL_TO_SUGGESTIONS](state, {url}){
        state.suggestions.unshift(url);
    },
    [types.CACHE_FULL_SUGGESTION](state, {suggestion}){
        let existing = state.suggestionsCache.find(s => s.id == suggestion.id);
        if ( ! existing ){
            state.suggestionsCache.push(suggestion)
        } else {
            let index = state.suggestionsCache.indexOf(existing)
            state.suggestionsCache.splice(index, 1, suggestion);
        }
        
    },
    [types.DELETE_SUGGESTION](state, {suggestion}){
        state.suggestions = state.suggestions.filter(s => ! s.includes(suggestion.id));
        state.suggestionsCache = state.suggestionsCache.filter(s => s.id != suggestion.id);
    },
    [types.UPDATE_SUGGESTION](state, {suggestion}){
        state.suggestionsCache = state.suggestionsCache.map(s => {
            if (s.id == suggestion.id){
                return suggestion
            }
            return s
        })
    },
    [types.SET_CURRENT_TOPIC](state) {
        state.suggestions = []
    },
    [types.EMPTY_SUGGESTIONS](state){
        state.suggestions = [];
    },
    [types.USER_DID_LOG_OUT](state) {
        state.suggestions = [];
        state.suggestionsCache = [];
        state.queryOffset = 0;
        state.showFavorites = false;
        state.readingSuggestion = false;
        state.suggestionSources = {}
    },
    [types.DELETE_NOTE](state, {note, noteURI}){
        if ( ! note.suggestion ) return;
        
        let suggestion = state.suggestionsCache.find(s => note.suggestion.includes(s.id));
        let index = state.suggestionsCache.indexOf(suggestion);

        if (suggestion){
            let notes = suggestion.notes.filter(n => n !== noteURI);
            state.suggestionsCache.splice(index, 1, {...suggestion, notes});
        }
    },
    [types.ADD_NOTE](state, {note, noteURI}){
        if (!note.suggestion) return;
        let suggestion = state.suggestionsCache.find(s => note.suggestion.includes(s.id));
        let index = state.suggestionsCache.indexOf(suggestion);

        if (suggestion) {
            var notes = [...suggestion.notes];
            
            if (notes.find(n => n.includes(note.id))){
                return;
            }
            
            // TODO(casio): Matt, let's please discuss potential risks with this change
            notes.push(noteURI);
            // notes.push(note.id);
            
            state.suggestionsCache.splice(index, 1, { ...suggestion, notes });
        }
    },
    [types.INCREMENT_SUGGESTIONS_QUERY_PAGE_OFFSET](state){
        state.queryOffset += 1
        //console.log('INCREMENT_SUGGESTIONS_QUERY_PAGE_OFFSET.complete', state.queryOffset)
    },
    [types.RESET_SUGGESTIONS_QUERY_PAGE_OFFSET](state){
        state.queryOffset = 0;
    },
    [types.TOGGLE_SHOW_FAVORITES](state){
        state.showFavorites = ! state.showFavorites;
    },
    [types.REMOVE_COLLECTION_FROM_SUGGESTION_CONTEXTS](state, {suggestion, collectionId}){
        var existing = state.suggestionsCache.find(s => s.id == suggestion.id);
        let index = state.suggestionsCache.indexOf(existing);

        if (existing && existing.contexts && existing.contexts.collections){
            let existingCollection = existing.contexts.collections.find(c => c.includes(collectionId));
            let collectionIndex = existing.contexts.collections.indexOf(existingCollection);
            existing.contexts.collections.splice( collectionIndex, 1 );

            state.suggestionsCache.splice(index, 1, existing);
        }
    },
    INCREMENT_SUGGESTIONS_DOWNLOADED(state, ) {
        state.suggestionsDownloaded++;
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
