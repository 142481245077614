<template>
    <upcontent-select
        :items="options"
        :searchable="searchable"
        :multiple="multiple"
        :disabled="disabled"
        :filterable="filterable"
        :required="required"
        :placeholder="placeholder"
        ref="ucSelect"
        :value="value"
        @input="onInput"
    >

    </upcontent-select>
</template>

<style>

</style>

<script>
    import UpcontentSelect from "@/components/Controls/UpcontentSelect";
    import {mapGetters} from 'vuex';
    import {getIconClass} from "@/services/Icons";
    import 'vue-select/dist/vue-select.css';
    import {hasNonWhitepsaceValue, hasValue} from "@/utilities/utils";

    export default {
        components: {UpcontentSelect},
        props: {
            type: String,
            value: String,
            multiple: Boolean,
            searchable: {type: Boolean, default: true},
            filterable: {type: Boolean, default: true},
            disabled: Boolean,
            exclude: String,
            required: Boolean,
            crossAccount: {type: Boolean, default: true}
        },
        computed: {
            ...mapGetters({
                folders: 'folders',
                currentAccountOverview: 'currentAccountOverview',
                allLoadedCollections: 'collections',
                allLoadedTopics: 'topics',
                features: 'features',
                allAccounts: 'allAccounts',
                accountFeatures: 'accountFeatures',
                accountsWithCrossAccount: 'accountsWithCrossAccount'
            }),
            crossAccountSources() {
                return this.crossAccount
                    && this.features.crossAccount;
            },
            collections() {
                return this.allLoadedCollections
                    .map(this.addInfo)
                    .filter(x => x.isCurrentAccount || (this.crossAccountSources && x.isCrossAccount))
                    .sort(this.sortCompare)
            },
            topics() {
                return this.allLoadedTopics.filter(x => //FIXMEeeeee
                    x.account.includes(this.currentAccountOverview.id)
                    || this.accountsWithCrossAccount.includes(x.account_id))
            },
            items() {
                return (
                    this.type === 'collection' ? this.collections :
                    this.type === 'topic' ? this.topics :
                    this.type === 'special:collection:zest topic' ? this.zestCollections :
                    [...this.collections, ...this.topics]
                )
            },
            placeholder() {
                let types = this.type ? [this.type] : ['topic', 'collection'];
                    types = types.map(x => x.replace('special:collection:', ''))
                let pluralizeIfMultiple = t => this.multiple ? t+'s' : t;
                let thingsToSelect = types
                    .map(pluralizeIfMultiple)
                    .map(this.$strings.capitalize)
                    .join('or')
                thingsToSelect = this.multiple ? thingsToSelect : 'a ' + thingsToSelect
                return `Select ${thingsToSelect}`

            },
            options() {
                return this.items
                    .filter(x => x.id !== this.exclude)
                    .map(x => {
                        let folders = this.folderNames(x).filter(hasNonWhitepsaceValue).map(x => ({
                            icon: 'fa fa-folder-o',
                            name: x
                        }));
                        let accountName = x.account ? this.formatAccountName(x) : '';
                        let account = accountName ? {
                            name: accountName,
                            icon: 'fa fa-users'
                        } : null;
                        let path = [account, ...folders].filter(hasValue)
                        return {
                            value: x.id,
                            description: x.name,
                            iconClass:
                                x.icon !== undefined ? getIconClass(x.icon || 'bolt') : 'fa fa-th-list',
                            path

                        }
                    })
            }
        },
        data() {
            return {
                zestCollections: [],
            }
        },
        methods: {
            onInput(v) {
                this.$emit('input', v)
            },
            loadAccountCollections(accountId) {
                this.$api.get(`/accounts/${accountId}/overview`).then(response => {
                    let overview = response.data;
                    this.$store.dispatch('addTopics', {topics : overview.topics.map(o => {
                            return {...o, account : accountId, incomplete: true} //FIXME do we need entire overview here?
                        })})

                    this.$store.dispatch('addCollections', {collections : overview.collections.map(o => {
                            return {...o, account : accountId, incomplete: true}
                        })})
                    this.$store.dispatch('addFolders', {folders : overview.folders.map(o => {
                            return {...o, account : accountId, incomplete: true}
                        })})
                })
            },
            sortCompare(a, b) {
                let aFirst = -1;

                if (a.isCurrentAccount && b.isCurrentAccount)
                    return a.name < b.name ? aFirst : -aFirst;
                else if (a.isCurrentAccount)
                    return aFirst;
                else if (b.isCurrentAccount)
                    return -aFirst;
                else
                    if (a.accountInfo.name !== b.accountInfo.name)
                        return a.accountInfo.name < b.accountInfo.name ? aFirst : -aFirst;
                    else
                        return a.name < b.name ? aFirst : -aFirst;
            },
            isCurrentAccount(collection) {
                return collection.account.includes(this.currentAccountOverview.id);
            },
            isCrossAccount(collection) {
                return this.accountsWithCrossAccount.some(a => collection.account.includes(a))
            },
            addInfo(collection) {
                let isCurrentAccount = collection.account.includes(this.currentAccountOverview.id);
                let isCrossAccount = !isCurrentAccount && this.accountsWithCrossAccount.some(a => collection.account.includes(a));
                let accountInfo = this.allAccounts.find(a => collection.account.includes(a.id));
                return {
                    ...collection,
                    accountInfo,
                    isCurrentAccount,
                    isCrossAccount,
                }
            },
            folder(collection) {
                return this.folders.find(f => collection.folder && collection.folder.includes(f.id))
            },
            parents(folder) {
                return folder.parents && folder.parents.map(p => this.folders.find(f => p.includes(f.id))).filter(hasValue) || []
            },
            ancestors(folder) {
                let parents = this.parents(folder);
                return [...parents.flatMap(p => this.ancestors(p)), ...parents]
            },
            folderNames(collection) {
                let folder = this.folder(collection);
                if (!folder) return [];
                let parents = this.ancestors(folder);
                let folders = [...parents, folder];
                return folders.map(folder => folder && folder.name).filter(x => x !== 'root');
            },
            formatAccountName(c) {
                return c.isCrossAccount ? c.accountInfo.name : ''
            },
            clearSelected() {
                this.$refs.ucSelect.clearSelected()
            }
        },
        mounted() {
            if (this.crossAccountSources) {
                this.accountsWithCrossAccount.forEach(account => {
                    this.loadAccountCollections(account)
                })
            }
            // if (!this.zestCollections.length) {
            //     this.$api.limit.get('/collections/global/zest').then(result => {
            //         this.zestCollections = Array.from(result.data).map(x => {
            //             let name = x.name;
            //             name = name.replace('Zest Master Collection', "Zest: All Topics");
            //             return {
            //                 ...x,
            //                 icon: 'zest',
            //                 name
            //             }
            //         });
            //     })
            // }
        }
    }
</script>
