<template>
    <div class="pt-4 ps-4 pe-4 container">
        <div class="row">
            <div class="col-12 col-lg-3">
                <div class="card">
                    <div class="card-header bg-primary text-white">
                        <h3 class="card-title">Teams</h3>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" @click="currentTeam = team;" :class="{'current' : team.id == currentTeam.id}" v-for="team in teams" :key="team.id+'-menu-item'">
                            {{team.name}}&nbsp;
                            <i class="fa fa-users" v-if="team.plan.includes('team')"></i>
                        </li>
                    </ul>
                </div>

                <br/>
            </div>
            <div class="col-12 col-lg-9" v-if="currentTeam">
                <div v-if="editingAssignments">
                    <h3 class="subtitle"><a href="#" @click.prevent="editingAssignments = false"><i class="fa fa-arrow-left"></i> Back</a> Edit Assignments For {{editingAssignments.name}} ({{editingAssignments.email}})</h3>

                    <assignment-editor :team="currentTeam" :user="editingAssignments" @changed="refreshCurrentTeam" />
                </div>
                <team-editor :class="{'invisible' : editingAssignments}" @showAssignmentEditor="setEditingAssignmentsForTeamMember" :team="currentTeam" ref="currentTeamEditor" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TeamEditor from '@/components/Teams/Manager'
import AssignmentEditor from '@/components/Teams/AssignmentEditor'

export default {
    props : [],
    components : {
        TeamEditor, AssignmentEditor
    },
    computed : {
        ...mapGetters({
            accounts : 'allAccounts',
            subscriptions : 'subscriptions',
            currentAccountOverview: 'currentAccountOverview',
        }),
        teams(){
            return this.accounts.filter(account => {
                let manageableRoles = ['administrator', 'manager']
                    return account.plan && manageableRoles.includes(account.role);
            });
        },
    },
    watch : {
        currentAccountOverview(){
            console.log(`* TeamManager currentAccountOverview watch about to set local var currentTeam to currentAccountOverview with: ${this.currentTeam} =  ${this.currentAccountOverview}`)
            this.currentTeam = this.currentAccountOverview
        }
    },
    data(){
        return {
            currentTeam : false,
            editingAssignments : false
        }
    },
    mounted(){
        this.currentTeam = this.currentAccountOverview
    },
    methods : {
        setEditingAssignmentsForTeamMember({teamMember}){
            this.editingAssignments = teamMember;
        },
        refreshCurrentTeam(){
            this.$refs.currentTeamEditor.fetchTeamMembers();
        }
    }
}
</script>

<style scoped>

.list-group-item {
    cursor: pointer;
    padding-left: 15px;
}

.list-group-item.current, .list-group-item.current:hover {
    border-left: 3px solid purple;
    padding-left: 12px;
}

.list-group-item:hover {
    border-left: 3px solid #aeaeae;
    padding-left: 12px;
}

</style>
