<template>
    <div v-if="loadedComponent">
        <component :is="loadedComponent" v-bind="props" :key="renderKey"/>
    </div>
</template>

<script>


import {defineAsyncComponent} from "vue";

const AsyncComponent = loader => defineAsyncComponent({
    loader,
    // loadingComponent: LoadingComponent,
    // errorComponent: ErrorComponent,
    delay: 200, // default: 200
    timeout: 3000, // default: Infinity
    suspensible: false, // default: true
    onError(error, retry, fail, attempts) {
        if (error.message.match(/fetch/) && attempts <= 3) {
            retry();
        } else {
            fail();
        }
    }
});

export default {
    name: "AsyncLoader",
    components: {},
    props: {
        renderKey: {type: Number},
        show: {type: Boolean, default: false},
        component: {type: Function},
        props: {type: Object, default: () => {}}
    },
    data() {
        return {
            key: 0,
            loadedComponent: null
        }
    },
    watch: {
        async show() {
            if (!this.loadedComponent) {
                console.log('componentFunc', this.component)
                let module = await this.component.value();
                this.loadedComponent = module && module.default;
                console.log('module', module)
                console.log('loadedComponent', this.loadedComponent);
            }
        }
    }
}
</script>

<style scoped>

</style>
