<template>
    <nestable-modal ref="modal" modal-name="rssFeedModal" size="md-lg" @shown="shown" @closed="closed">
        <template v-slot:header>
            <h5>
                <suggestion-title />
                RSS Feed URL
            </h5>
        </template>
            <div v-if="accountFeatures.newFeedUrl && hasSuggestions && hasSuggestions.type === 'collection'">
                <label class="label-calm">Select Feed Format</label>
                <toggle class="full-width mb-4" v-model="extension">
                    <toggle-item value="atom" style="border: 1px solid var(--primary-50)">ATOM</toggle-item>
                    <toggle-item value="rss" style="border: 1px solid var(--primary-50)">RSS</toggle-item>
                    <toggle-item value="json" style="border: 1px solid var(--primary-50)">JSON</toggle-item>
                    <toggle-item value="csv" style="border: 1px solid var(--primary-50)">CSV</toggle-item>
                </toggle>
                <fieldset class="mb-3" v-if="filterView && filterView.id && filterView.name">
                    <legend class="label-calm">Select Filter Parameters</legend>
                    <label class="mb-0 d-flex">
                        <input type="radio" name="filterParameterMode" value="filterView" v-model="filterParameterMode" class="me-1 form-check-input">&nbsp;
                        <div>
                            <div>Use current filter view ({{filterView.name}})</div>
                            <div class="text-muted small mb-2">This will automatically update the filters in the feed when this filter view is updated.</div>
                        </div>
                    </label>
                    <label class="mb-0 d-flex">
                        <input type="radio" name="filterParameterMode" value="filterData" v-model="filterParameterMode" class="me-1 form-check-input">&nbsp;
                        <div>
                            <div>Use currently applied filters</div>
                            <div class="text-muted small mb-2" >These filters will remain regardless of changes made to filters or filter views.</div>
                        </div>
                    </label>
                </fieldset>
            </div>
            <label class="label-calm">Copy the feed URL below</label>
            <textarea @click.prevent.stop id="feed-container-text" class="full-width font-size-13 text-steel-25"
            rows="3" :value="feedUrl"></textarea>
<!--            <label @click.stop>-->
<!--                <input type="checkbox" v-model="includeAllSources"/>-->
<!--                Include All Sources (Including New)-->
<!--            </label>-->
<!--            <label @click.stop>-->
<!--                <input type="checkbox" v-model="includeNewSources" :disabled="includeAllSources" :class="{'text-muted': includeAllSources}"/>-->
<!--                Include New Sources-->
<!--            </label>-->
            <br/>
            <p class="text-muted small">To change filter parameters change the filters for the current collection</p>
            <a 
                href="#"
                class="btn btn-primary"
                @click.prevent.stop="copyFeedUrl"
            >
                 <span v-if="copiedText"><i class="fa fa-check-circle"></i> Copied to Clipboard</span>
                 <span v-if="!copiedText"><i class="fa fa-clipboard"></i> Copy Feed URL</span>
            </a>
    </nestable-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import RssFilterBase from '@/components/FilteringAndSorting/RssFilterBase'
import NestableModal from "@/components/Controls/NestableModal";
import SuggestionTitle from "@/components/Suggestions/ManageSuggestion/SuggestionTitle";
import ToggleBar from "../../../utilities/ToggleBar";
import Toggle from "../../Controls/Toggle/Toggle";
import ToggleItem from "../../Controls/Toggle/ToggleItem";

export default {
    name: 'RssFeedModal',
    props : ['topic'],
    mixins: [RssFilterBase],
    components: {
        ToggleItem,
        Toggle,
        ToggleBar,
        NestableModal,
        SuggestionTitle,
    },
    data(){
        return {
            copiedText : false,
            extension: 'atom',
            filterParameterMode: 'filterView'
        }
    },
    computed : {
        ...mapGetters({
            showingFavoriteSuggestions : "showFavoriteSuggestions",
            filters : 'currentFilters',
            selectedSources: 'selectedSources',
            excludedSources: 'excludedSources',
            includeNewSources: 'includeNewSources',
            hasSuggestions : 'hasSuggestions',
            filterView: 'currentFilterView',
            filterViewId: 'currentFilterViewId',
            accountFeatures: 'accountFeatures',
        }),
    },
    mounted() {
        window.Events.$on('show_rssFeedModal', data => {
            console.log('show_rssFeedModal', data)
        });
    },
    methods : {
        copyFeedUrl(){
            let copyText = document.getElementById("feed-container-text");
            copyText.select();
            document.execCommand("Copy");
            this.copiedText = true;
            setTimeout(() => {
                this.copiedText = false;
            }, 3000)
        },
        shown() {
            console.log('shown()', this.onlyType);
        },
        async closed() {

        }
    }
}
</script>
<style scoped>
    label.d-flex {
        align-items: baseline;
    }
</style>
<style>
.topic-rss-feed-container {
    padding: 20px !important;
    min-width: 19rem;
    font-size: 10px;
}

.topic-rss-feed-container h2 {
    font-size: 14px;
}

.topic-rss-feed-container textarea {
    border: none;
    font-size: 10px !important;
    width: 100%;
    resize: none;
}

.topic-rss-feed-container textarea:focus {
    outline: none;
}
</style>
