let title = "Hearsay Content+";
let learnUrls = {};

export default {
    title, learnUrls,
    legalEntityShort: "Hearsay",
    legalEntityFull: "Hearsay, Inc",
    privacyPolicyUrl: 'https://www.hearsaysystems.com/privacy-policy',
    termsOfUseUrl: '',
    infoEmail: '',
    marketingSiteUrl: '',
    feedsDomain: 'feeds.content.hearsaysocial.com',
    shortenerDomain: 'go.content.hearsaysocial.com',
    hideSubscription: true,
    hideSupportApps: true,
    hideShare: true,
    hideZapier: true,
    hideIntegrations: true,
    hideGalleries: true,
    hideContentTemplates: true,
    hideFeedLink: true,
}
