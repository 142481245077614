<template>
    <div>
        <h6 class="small">Card on file</h6>
        <div class="d-flex justify-content-start align-items-center col-margin-8">
            <div class="d-flex justify-content-start align-items-center col-margin-4">
                <i :class="cardIcon"></i>
                <div>{{formattedCardNumber}}</div>
                <div><span class="small" title="expiration">exp.</span> {{card.exp_month}}/{{card.exp_year}}</div>
            </div>
            <div>
                <button :class="{'disabled' : networkActivity}" @click="removeCard" class="btn btn-default btn-sm"><span class="text-danger">Remove Card</span></button>  
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : ['card'],
    data(){
        return {
            networkActivity : false
        }
    },
    computed : {
        cardIcon(){
            if (this.card)
                return 'fa fa-cc-'+this.card.brand.toLowerCase()
        },
        formattedCardNumber() {
            return this.card && this.card.cardnumber ? this.card.cardnumber.match(/.{1,4}/g).join(' ') : '';
        }
    },
    mounted(){

    },
    methods : {
        removeCard(){
            let x = confirm("Are you sure you want to remove this card? You'll have to enter another one to make changes to your plan.")
            if ( ! x ) return;
            this.$emit('removeCard');
        }
    }
}
</script>
