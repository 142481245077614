
export function randomId() {
    return Math.random().toString(36).substr(2, 9);
}

export function instanceId () {
    let vm = this;
    if (!vm._ucCompId)
        vm._ucCompId = randomId();
    return vm._ucCompId;
}

export function elementId(elementName) {
    return `${this.$instanceId()}-${elementName}`;
}