<template>
    <div class="sign-up-button-container"
         :class="{'thinking': networkActivity, 'party-time': partyTime}">
        <button
            @click="submitAction"
            :disabled="networkActivity"
            :class="{'btn-thinking': networkActivity}"
            class="sign-up-button btn btn-success text-nowrap full-width ux-sign-up-button">
        <span class="btn-content">
            <template v-if="!networkActivity">
                <span class="me-2"><slot name="default"></slot></span>
                <i class="fa fa-arrow-right"></i>
            </template>
            <template v-if="networkActivity">
                <span>{{ networkActivity }}</span>
            </template>
        </span>
        </button>
        <div class="spin-wrapper">
            <div class="spin-container">
                <spin-circle class="spin-circle"></spin-circle>
            </div>
        </div>
    </div>
</template>

<script>

import spinCircle from "@/assets/svg/spinCircle";

export default {
    name: "SignUpButton",
    components: {
        spinCircle,
    },
    props: {
        networkActivity: {default: false},
        partyTime: {default: false},
    },
    methods: {
        submitAction() {
            this.$emit('submitted')
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-thinking, .btn-thinking:hover, .btn-thinking:active {
    background: #fff !important;
    color: var(--green-50) !important;
    box-shadow: none !important;
}

.sign-up-button-container {
    padding: 2px;
    border-radius: var(--border-radius);
    position: relative;
}

.sign-up-button {
    position: relative;
    z-index: 2;
    border: 0!important;
}

.spin-circle {
    width: 100%;
    height: auto;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.spin-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius);
}
.spin-container {
    visibility: hidden;
    position: absolute;
    width: 992px;
    height: 992px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 1;
    transition: opacity 300ms ease-in-out;
}

.thinking .spin-container {
    visibility: visible;
    opacity: 1;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
<style lang="scss">
// Do not scope these styles or else they won't work.
.sign-up-button-container {
    .spin-circle-1 {
        fill: var(--green-50);
    }

    .spin-circle-2, .spin-circle-3, .spin-circle-4 {
        fill: transparent;
    }
}
.sign-up-button-container.party-time {
    .spin-circle {
        animation-duration: 1250ms;
    }
    .spin-circle-1 {
        fill: var(--blue-50);
    }

    .spin-circle-2 {
        fill: transparent;
    }
    .spin-circle-3 {
        fill: var(--green-50);
    }
    .spin-circle-4 {
        fill: var(--gold-50);
    }
}
</style>
