<template>
    <fieldset>
        <legend>{{label}}</legend>
        <table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Value</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="prop in properties" :key="prop.id">
                <td>
                    <input v-model="prop.name" type="text" list="cssProps" style="width: 100%; height: 100%"/>
                </td>
                <td>
                    <input v-model="prop.value" type="text" style="width: 100%; height: 100%"/>
                </td>
                <td>
                    <button type="button" @click="remove(prop)" class="btn btn-sm btn-danger pt-0 pb-0 d-block">&times;</button>
                </td>
            </tr>
            </tbody>
        </table>
        <button class="btn btn-primary btn-sm" type="button" @click="add">Add</button>
        <datalist id="cssProps">
            <option v-for="prop in validProps" :value="prop" :key="prop">{{prop}}</option>
        </datalist>
    </fieldset>
</template>

<script>
    export default {
        name: "PropEditors",
        props: {'value': {}, label: { default: 'CSS Properties', type: String}},
        watch: {
            value() {
                if (JSON.stringify(this.properties) !== JSON.stringify(this.valueAsArray())) {
                    this.properties = this.valueAsArray();
                }
            },
            properties: {
                handler() {
                    if (JSON.stringify(this.value) !== JSON.stringify(this.propsObject)) {
                        this.$emit('input', this.propsObject)
                    }
                },
                deep:true
            }
        },
        methods:{
            add() {
                this.properties.push({id: this.maxId + 1, name: '', value: ''})
            },
            remove(prop) {
                this.properties = this.properties.filter(p => p.id !== prop.id)
            },

            valueAsArray() {
                let val = this.value;
                let id = 0;
                return val ? Array.isArray(val) ? val: Object.entries(val).map(([k, v]) => ({
                    id: id++,
                    name:k,
                    value:v})) : []
            }
        },
        computed: {
            maxId() {
                return this.properties && this.properties.length ? Math.min(...this.properties.map(x => x.id||0)) : 0
            },
            propsObject() {
                return this.properties.reduce((result, prop) => ({...result, [prop.name]: prop.value}), {})
            },
        },
        data(){
            return {
                properties: this.valueAsArray(),
                validProps: [
                    'background',
                    'background-attachment',
                    'background-clip',
                    'background-color',
                    'background-image',
                    'background-origin',
                    'background-position',
                    'background-repeat',
                    'background-size',
                    'border',
                    'border-bottom',
                    'border-bottom-color',
                    'border-bottom-left-radius',
                    'border-bottom-right-radius',
                    'border-bottom-style',
                    'border-bottom-width',
                    'border-collapse',
                    'border-color',
                    'border-image',
                    'border-image-outset',
                    'border-image-repeat',
                    'border-image-slice',
                    'border-image-source',
                    'border-image-width',
                    'border-left',
                    'border-left-color',
                    'border-left-style',
                    'border-left-width',
                    'border-radius',
                    'border-right',
                    'border-right-color',
                    'border-right-style',
                    'border-right-width',
                    'border-spacing',
                    'border-style',
                    'border-top',
                    'border-top-color',
                    'border-top-left-radius',
                    'border-top-right-radius',
                    'border-top-style',
                    'border-top-width',
                    'border-width',
                    'bottom',
                    'box-shadow',
                    'caption-side',
                    'clear',
                    'clip',
                    'color',
                    'content',
                    'counter-increment',
                    'counter-reset',
                    'cursor',
                    'direction',
                    'display',
                    'empty-cells',
                    'float',
                    'font',
                    'font-family',
                    'font-size',
                    'font-size-adjust',
                    'font-stretch',
                    'font-style',
                    'font-synthesis',
                    'font-variant',
                    'font-weight',
                    'height',
                    'left',
                    'letter-spacing',
                    'line-height',
                    'list-style',
                    'list-style-image',
                    'list-style-position',
                    'list-style-type',
                    'margin',
                    'margin-bottom',
                    'margin-left',
                    'margin-right',
                    'margin-top',
                    'max-height',
                    'max-width',
                    'min-height',
                    'min-width',
                    'opacity',
                    'orphans',
                    'outline',
                    'outline-color',
                    'outline-style',
                    'outline-width',
                    'overflow',
                    'padding',
                    'padding-bottom',
                    'padding-left',
                    'padding-right',
                    'padding-top',
                    'page-break-after',
                    'page-break-before',
                    'page-break-inside',
                    'position',
                    'quotes',
                    'right',
                    'table-layout',
                    'text-align',
                    'text-decoration',
                    'text-decoration-color',
                    'text-decoration-line',
                    'text-decoration-style',
                    'text-indent',
                    'text-transform',
                    'top',
                    'transform',
                    'transform-origin',
                    'transition',
                    'transition-delay',
                    'transition-duration',
                    'transition-property',
                    'transition-timing-function',
                    'unicode-bidi',
                    'vertical-align',
                    'visibility',
                    'white-space',
                    'widows',
                    'width',
                    'word-spacing',
                    'z-index'
                ]
            }
        }
    }
</script>

<style scoped>

</style>
