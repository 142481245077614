<template>
    <div class="dp-container" :class="mode">
        <button class="dp-button ux-dp-link"
                role="menuitem"
                aria-haspopup="dialog"
                aria-expanded="false"
                @click.prevent.stop="togglePanel"
                :id="`toggle-${panelTitle}-drop-panel`"
                :class="[{'active': isOpen}, uxButtonLinkClass]"
                :disabled="disabled"
        >
            <slot name="link" :aria-label="panelName">
                <div class="text-nowrap">{{ panelName }}</div>
            </slot>
            <span class="ms-3" v-if="isAccordionMode"><i class="fa fa-chevron-down dp-accordion-chevron"></i></span>
        </button>
        <div class="dp-panel"
             role="menu"
             :aria-labelledby="`toggle-${panelTitle}-drop-panel`"
             ref="dpPanel"
             v-click-outside="handleClickOutside"
             :class="[{
                'active': isOpen,
                'fixed': positionFixed,
                'position-centered': positionCentered,
                'overlap': overlap
             }, dropPanelClass]"
             v-if="isAccordionMode || (isDropdownMode && isOpen)"
        >
            <div class="dp-panel-liner">
                <div v-if="showHeader"
                     class="dp-panel-header position-relative d-flex justify-content-between align-items-start full-width">
                    <slot name="header">
                        <h4 class="font-size-15">{{ panelName }}</h4>
                    </slot>

                    <button class="dp-close btn-close ux-dp-has-header-close"
                            :class="uxButtonCloseClass"
                            v-if="isDropdownMode"
                            @click.prevent="closeDropPanels"
                            aria-label="Close Dialog"></button>
                </div>
                <div v-if="!showHeader && isDropdownMode" class="dp-panel-no-header">
                    <button class="dp-close btn-close ux-dp-no-header-close"
                            :class="uxButtonCloseClass"
                            @click.prevent="closeDropPanels"
                            aria-label="Close Dialog"></button>
                </div>
                <div class="dp-panel-content">
                    <slot name="default"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DropPanel",
    props: {
        panelTitle: {type: String, required: true},
        panelName: {type: String, required: true},
        accordionMode: {type: Boolean, default: false},
        showHeader: {type: Boolean, default: true},
        activateOnHover: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        positionFixed: {type: Boolean, default: false},
        positionCentered: {type: Boolean, default: false},
        overlap: {type: Boolean, default: false},
        dropPanelClass: {type: String},
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        mode() {
            return this.accordionMode ? 'accordion-mode' : 'dropdown-mode';
        },
        isDropdownMode() {
            return !this.accordionMode;
        },
        isAccordionMode() {
            return this.accordionMode;
        },
        uxButtonCloseClass () {
            return 'ux-dp-close-' + this.panelTitle;
        },
        uxButtonLinkClass() {
            return 'ux-dp-link-' + this.panelTitle;
        }
    },
    methods: {
        togglePanel() {
            // Handle Closing
            if (this.isOpen) {
                this.isOpen = false;
                return;
            }

            // Handle Opening
            this.isOpen = true;
            window.Events.$emit('openedDropPanel', this.panelTitle);
        },
        closeDropPanels(exceptThisOne = '') {
            if (this.panelTitle !== exceptThisOne) {
                this.isOpen = false;
            }
        },
        openThisDropPanel(thisOne = '') {
            if (thisOne && thisOne !== '' && this.panelTitle === thisOne) {
                this.isOpen = true;
            }
        },
        handleClickOutside() {
            if (this.isDropdownMode) {
                this.closeDropPanels();
            }
        }
    },
    mounted() {
        window.Events.$on('openedDropPanel', this.closeDropPanels);
        window.Events.$on('closeDropPanels', this.closeDropPanels);
        window.Events.$on('openThisDropPanel', this.openThisDropPanel);
    },
}
</script>

<style lang="scss" scoped>
.dp-container {
    --sizing: 1.25em;
    box-sizing: border-box;
    //position: relative;  You (yes you!) need to add .position-relative
    // to where you want the opened panel to be positioned relative to
    // when using dropdown mode!
}

.dp-button, .dp-close {
    border: 0;
    background: transparent;
}

.dp-button {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    color: var(--primary);
}

.dp-button:disabled {
    cursor: default;
    color: var(--steel-25);
}

.dp-button:hover:disabled {
    cursor: default;
    color: var(--steel-50);
}

.dp-button:hover, .dp-button.active {
    color: var(--primary-75);
}

.dp-button:hover .path-stroke,
.dp-button:hover .path-fill,
.dp-button.active .path-stroke,
.dp-button.active .path-fill {

}

.dp-button:active {
    color: var(--primary-100);
}

.dp-button:active .path-stroke,
.dp-button:active .path-fill, {
    color: var(--primary-100);
}

.dropdown-mode {

    .dp-panel {
        position: absolute;
        top: calc(var(--sizing) + 2px);
        left: 0;
        background: #fff;
        z-index: 25;
        border: 1px solid var(--steel-100);
        border-radius: var(--border-radius);
        box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px, rgba(50, 50, 50, 0.2) 8px 8px 16px;
        width: 100%;
        box-sizing: border-box;
    }

    .dp-panel.overlap {
        top: 0;
    }

    .dp-panel.fixed {
        position: fixed !important;
    }

    .dp-panel.position-centered {
        top: 50% !important;
        left: 50% !important;;
        transform: translate(-50%, -50%) !important;;
    }

    $panel-padding: 1em;
    $panel-padding-mini: $panel-padding - .5em;

    .dp-panel-header {
        padding: $panel-padding $panel-padding $panel-padding-mini $panel-padding;
    }

    .dp-panel-no-header {
        padding: $panel-padding-mini;
        float: right;
        margin-right: $panel-padding-mini;
    }

    .dp-panel-content {
        padding: $panel-padding;
    }


    .dp-close {
        $sizing: 1em;
        margin-top: 0 - ($sizing * .5);
        margin-right: 0 - ($sizing * .5);
        padding: .5em;
        width: $sizing;
        height: $sizing;
    }

    .dp-close:hover {
        outline: 3px solid var(--water-33);
    }
}

.accordion-mode {
    .dp-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    // Open Close Transition
    .dp-panel {
        max-height: 0;
        overflow: hidden;
        transition: max-height 700ms ease-out;
    }

    .dp-panel.active {
        max-height: 100vw;
        transition: max-height 900ms ease-in;
    }

    .dp-panel-liner {
        max-height: 100vw;
        overflow-y: auto;
    }

    .dp-accordion-chevron {
        transition: all 300ms ease-in-out;
    }

    .active .dp-accordion-chevron {
        transform: rotate(-180deg);
    }
}

.dp-panel h4 {
    padding: 0;
    margin-top: 0;
    margin-bottom: var(--padding);
    color: var(--steel-25);
}

</style>
