<template>
    <div class="mailchimp-collection-settings" :class="{hide: !(authorized && ! networkActivity)}">
        <div class="d-flex">
            <div class="mailchimp-edit me-2" v-if="showRssForm">
                <mailchimp-create-email-campaign
                    v-bind="$props"
                    class="p-3"
                    @cancel="cancel"
                    @submitted="submitted">
                </mailchimp-create-email-campaign>
            </div>
            <div class="mailchimp-list" v-if="!showRssForm">
                <button
                        type="button"
                        class="btn btn-primary full-width-button mb-2"
                        @click.prevent="onShowRssForm">
                    Create Mailchimp Email Campaign
                </button>
                <div  v-if="existingEmails&&existingEmails.length" class="card">
                    <h6 class="card-header" v-if="existingEmails&&existingEmails.length">Mailchimp Digests <span v-if="!showRssForm">using this Collection</span></h6>
                    <spinner class="" v-if="loadingCampaigns"></spinner>
                    <ul class="ps-0 mb-0 list-group">
                        <li v-for="email in existingEmails" :key="email.id" class="list-group-item">
                            <a :href="mailchimpEditLink(email.web_id)" target="_blank">
                                {{ email.settings.title }}
                                <span v-if="!showRssForm">(Edit in Mailchimp)</span>
                            </a>
                            <div class="text-muted small" v-if="schedule(email)">{{ schedule(email) }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToggleSwitch from '@/components/Controls/ToggleSwitch.vue'
import ConnectButton from '@/components/Settings/Team/ConnectDisconnectButton.vue'
import Scheduler from "@/components/Controls/Scheduler.vue";
import UpcontentSelect from '@/components/Controls/UpcontentSelect.vue'
import moment from 'moment';
import Spinner from "@/components/Controls/Spinner.vue";
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView.vue";
import FeedUrls from "@/utilities/FeedUrls.vue";
import MailchimpBase from "./MailchimpBase.vue";
import MailchimpCreateEmailCampaign from "./MailchimpCreateEmailCampaign.vue";

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default {
    components: {
        MailchimpCreateEmailCampaign,
        Spinner, Scheduler, ToggleSwitch, ConnectButton, UcSelect: UpcontentSelect, SelectFilterView},
    mixins: [FeedUrls, MailchimpBase],
    props: {
        collection: Object,
        team: Object,
        visible: {type: Boolean, default: null},
    },
    data() {
        return {
            showRssForm: false,
            open: this.authorized,
            networkActivity: false,
            existingEmails: [],
            loading: 0,
            loadingCampaigns: false,
        };
    },
    watch: {
        showRssForm() {
            // setTimeout(() => document.getElementById('mailchimp-email-rss-finish').scrollIntoView(), 100)
        },
        team() {
            // this.open = this.val || this.authorized;
            if (this.authorized) {
                this.getEmails();
            }
        },
        async collection() {
            if (this.authorized) {
                await this.getEmails();
            }
        },
    },
    computed: {
        show() {
            return this.features.mailchimpEmail && (this.visible !== false);
        },
    },
    methods: {
        dayDisplay: function (dailySendObj) {
            let days = Object
                .entries(dailySendObj)
                .filter(([k, v]) => v && k)
                .map(([k,]) => this.$strings.capitalize(k))
            let dayDisplay = days.map(day => day.substring(0, 3)).join(', ');
            return dayDisplay;
        },
        schedule(email) {
            if (!email.rss_opts) return '';
            let rss = email.rss_opts;
            let frequency = rss.frequency;
            let detail =
                frequency === 'daily' ? this.dayDisplay(rss.schedule.daily_send || {}) || '' :
                    frequency === 'weekly' ? this.$strings.capitalize(rss.schedule.weekly_send_day) || '' :
                        frequency === 'monthly' ?
                            moment().month('september').date(rss.schedule.monthly_send_date).format('Do') || '' : '';
            return `${this.$strings.capitalize(frequency || '')} (${detail})`
        },
        status(email) {
            return email.currentState &&
                this.$strings.capitalize(email.currentState.toLowerCase().replace(/_/g, ' ').replace('Rss ', ''))
        },
        onShowRssForm() {
            this.showRssForm = true;
        },
        submitted() {
            this.getEmails()
            this.showRssForm = false;
        },
        cancel() {
            this.showRssForm = false;
        },
        mailchimpEditLink(id) {
            return `https://${this.dc || 'us18'}.admin.mailchimp.com/campaigns/wizard/neapolitan?id=${id}` // TODO: set the dc
        },

        async getEmails() {
            if (this.team && this.team.id && this.collection && this.collection.id) {
                this.loadingCampaigns = true;
                let emailEndpoint = `/accounts/${this.team.id}/integrations/mailchimp/resources/campaigns`;// TODO: for some reason ?type=rss isn't working
                let query = 'count=100';

                try {
                    await this.$api.get(emailEndpoint + '?' + query).then(resp => {
                        if (resp && resp.data) {
                            let emails = resp.data.campaigns
                            let rssEmails = emails.filter(x => x.rss_opts && x.type === 'rss');
                            let relatedEmails = rssEmails.filter(x =>
                                x.rss_opts.feed_url && x.rss_opts.feed_url.includes(this.collection.id))
                            this.existingEmails = relatedEmails;
                        }
                    })
                } finally {
                    this.loadingCampaigns = false;
                }

            }
        },
    },
    mounted() {
        if (this.authorized) {
            this.getEmails();
        }
    }
}
</script>
<style>
.powerup legend {
    font-size: 16px;
    margin-bottom: 5px;
}

.powerup fieldset {
    margin: 10px 0;
}
</style>

<style scoped>
.mailchimp-edit {
    flex-grow: 5;
    flex-shrink: 1;
}
.mailchimp-list {
    flex-grow: 1;
    flex-shrink: 5;
}

.card>.list-group {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin: -1px;
}
.full-width-button {
    width: 100%;
}

.powerup summary {
    background: var(--bs-light);
    border-bottom: 1px solid var(--bs-default);
    list-style: none;
    list-style-type: none;
    margin: -10px -10px -1px -10px;
    color: var(--bs-dark);
    position: relative;
}

details.powerup summary::-webkit-details-marker {
    display: none;
}


.hide {
    display: none;
}

.uc-select-sm {
    font-size: 0.875rem;
    color: var(--bs-dark)
}
</style>
