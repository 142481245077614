<template>
    <div class="ps-4 pe-4">
        <article-reader 
            :suggestion="suggestion"
            v-if="suggestion"
        />
        <div v-else class="alert alert-gold">
            <h4>Suggestion not found :(</h4>
        </div>
    </div>
</template>

<script>
import ArticleReader from '@/components/Suggestions/ArticleReader'
import { mapGetters } from 'vuex';

export default {
    props : ['suggestionId'],
    components : {
        ArticleReader
    },
    computed : {
        ...mapGetters({
            suggestions : 'suggestionsCache'
        }),
        suggestion(){
            return this.suggestions.find(s => s.id == this.suggestionId)
        }
    },
    mounted(){
        if ( ! this.suggestion) {
            this.$api.get('/suggestions/'+this.suggestionId).then(response => {
                let suggestion = response.data
                this.$store.dispatch('addSuggestionToCache', {suggestion})
                this.$store.dispatch('setReadingSuggestion', {suggestion})                
            }).catch(e => {
                if (e.response){
                    switch (e.response.status){
                        case 404:
                            alert('Not found! Cannot download.')
                        break;
                    }
                }
            })
        } else {
            this.$store.dispatch('setReadingSuggestion', {suggestion : this.suggestion})
        }
    }
}
</script>

<style>
</style>
