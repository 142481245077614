<template>
    <form @submit.prevent.stop>
        <input type="search" v-model="searchText" :placeholder="placeholder" class="form-control" :class="classes">
    </form>
</template>

<script>
export default {
    name: "EasySearch",
    props: {
        value: {type: String, default: ''},
        items: {type: Array},
        placeholder: {type: String, default: ''},
        matches: {type: Function},
        classes: {type: String, default: ''},
    },
    data() {
        return {
            searchText: this.value || ''
        }
    },
    computed: {
        results () {
            return this.items.filter(x => this.matches(this.searchText, x))
        }
    },
    watch: {
        results() {
            this.$emit('search', this.results)
        },
        value() {
            this.searchText = this.value
        },
        searchText() {
            this.$emit('input', this.searchText)
        }
    }
}
</script>

<style scoped>
    /*::after{*/
    /*    float: right;*/
    /*    vertical-align: top;*/
    /*    margin-right: .3em;*/
    /*    content: "\f002";*/
    /*    display: inline-block;*/
    /*    font: normal normal normal 14px/1 FontAwesome;*/
    /*    font-size: inherit;*/
    /*    text-rendering: auto;*/
    /*    -webkit-font-smoothing: antialiased;*/
    /*    -moz-osx-font-smoothing: grayscale;*/
    /*}*/
</style>
