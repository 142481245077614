<template>
    <div class="">
        <div class="row">
            <label for="filter-form-sort" class="col-12 col-sm-4 align-self-end top-3">Sort By</label>
            <div class="col-12 col-sm">
                <select id="filter-form-sort"
                        class="filter-item form-control form-control-sm form-select"
                        @change="update()"
                        v-model="sortBy">
                    <option :value="thisType" v-for="thisType in availableSortByTypes" :key="'content-types-'+thisType">
                        {{ thisType }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>

import Base from './FilterMixin'
import {sortTypes} from "@/Constants";

export default {
    mixins: [Base],
    props: {type: String},
    data() {
        return {
            sortBy: this.filters && this.filters.sortBy || '',
        }
    },
    computed: {
        availableSortByTypes() {
            const types = sortTypes(this.type, this.topicLanguage,
                this.accountFeatures.predictedPerformance);

            return types;
        }
    },
    watch: {
        type() {
            if (this.type == 'collection' && this.sortBy == 'Relevance') {
                this.sortBy = 'Recency';
                this.update();
            }
        },
        filters() {
            if (this.filters)
                this.sortBy = this.filters.sortBy;
        }
    },
    mounted() {
        this.sortBy = this.filters.sortBy
        if (this.type == 'collection' && this.sortBy == 'Relevance') {
            this.sortBy = 'Recency';
            this.update();
        }
    },
    methods: {
        update() {
            this.updateFilter('sortBy', this.sortBy);
        }
    }
}
</script>

<style scoped>
select {
    display: block;
}
</style>
