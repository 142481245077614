<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         xml:space="preserve"
         style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5"
         viewBox="0 0 52 52"
    >
        <g class="multiple-image">
            <path d="M43.7 9.8c0-2.2-.5-3.4-1-4.2-.6-.6-1.2-1-2-1.1-.8-.2-1.8-.2-3-.2l-25.1.1c-4 0-7.8 3-7.8 7V41c0 1.9.6 3.1 1.4 4 1.2 1 3 1.5 5.6 1.5v-3.3c-.9 0-1.6 0-2.3-.2a2 2 0 0 1-1-.4c-.3-.4-.3-.9-.3-1.6V11.4c0-2.2 2.2-3.6 4.4-3.6l25-.1h1.9l.6.1.1.4.1 1.6h3.4Z"
                  class="path-fill" fill="currentColor"/>
            <path d="M38.7 5.4c0-2.2-.5-3.5-1-4.2-.6-.6-1.2-1-2-1.1-.8-.2-1.8-.2-3-.2L7.6 0C3.6 0-.2 3-.2 7v29.6c0 1.9.6 3 1.4 4 1.2 1 3 1.5 5.6 1.5v-3.4l-2.3-.1a2 2 0 0 1-1-.5c-.3-.3-.3-.8-.3-1.5V7c0-2.2 2.2-3.6 4.4-3.6l25-.1h2.5l.1.5.1 1.6h3.4Z"
                  class="path-fill" fill="currentColor"/>
            <g>
                <path d="m48.3 36.4.4-20c0-4-2-7.1-6.1-7.1H17.9c-4 0-7.7 3-7.7 7l-.2 29c0 1.6.5 3 1.6 3.9 1 .9 2.4 1.5 4.6 1.5h22a1.7 1.7 0 0 0 0-3.4h-22c-1.1 0-1.9-.1-2.4-.6-.4-.3-.5-.8-.5-1.4l.2-29c0-2.1 2.2-3.6 4.4-3.6h24.7c2 0 2.7 1.7 2.7 3.6l-.3 20a1.7 1.7 0 0 0 3.3 0Z"
                      class="path-fill" fill="currentColor"/>
                <circle cx="31.9" cy="32.4" r="10.5" class="path-stroke" style="fill:none;stroke-width:4px" stroke="currentColor"/>
                <path d="M43.4 43.4 49 49" class="path-stroke" style="fill:none;stroke-width:4px" stroke="currentColor"/>
                <path d="M31.9 28c1.8 0 3.5.9 4.1 2"
                      class="path-stroke"
                      style="fill:none;stroke-width:2.67px" stroke="currentColor"/>
            </g>
        </g>

        <g class="singular-image">
          <path d="M47.6 33.5 48 8.7C48 4 45.6.3 41 .3L11.5.5c-4.7 0-9 3.4-9 8.2l-.2 35.9A6 6 0 0 0 4.1 49c1 1 2.8 1.8 5.3 1.8h26.4a1.7 1.7 0 0 0 0-3.4H9.4c-1.4 0-2.4-.2-3-.8-.6-.5-.7-1.2-.7-2l.2-36c0-2.8 2.7-4.8 5.6-4.8L41 3.7c2.6 0 3.6 2.3 3.6 5l-.4 24.7a1.7 1.7 0 0 0 3.4 0Z"
                class="path-fill" fill="currentColor"/>
            <ellipse cx="29" cy="30.3" rx="12" ry="11.9" class="path-stroke"
                     style="fill:none;stroke-width:4px" stroke="currentColor"/>
            <path d="m42.2 42.7 6.4 6.4" class="path-stroke" style="fill:none;stroke-width:4px" stroke="currentColor"/>
            <path d="M29 25.3c2.2 0 4 1 4.8 2.3" class="path-stroke" style="fill:none;stroke-width:2.67px" stroke="currentColor"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "TopicsSvg",
}
</script>
<style lang="scss">
.singular .multiple-image, .multiple .singular-image  {
    display: none;
}
.multiple .multiple-image, .singular .singular-image {
    display: block;
}
.light-variation {
    //.path-fill {
    //    fill: #fff;
    //}
    //
    //.path-stroke {
    //    stroke: #fff;
    //}
}
</style>
<style lang="scss" scoped>

//.path-fill {
//    fill: var(--steel-33)
//}
//
//.path-stroke {
//    stroke: var(--steel-33)
//}
</style>
