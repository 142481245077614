<template>
    <button type="button" class="text-nowrap" @click.prevent="deleteFilter">
        <i class="fa fa-delete-left"/> Delete
    </button>
</template>

<script>
export default {
    name: "DeleteFiltersButton",
    props: {
        filters: {},
    },
    methods: {
        async deleteFilter() {
            if (confirm(`Are you sure you want to delete the filter view ${this.filters.name}`)) {
                await this.$api.delete(`/filter-views/${this.filters.id}`).catch(e => console.error(`Failed to deleteFilter: ${e}`));
                await this.$store.dispatch('deleteFilterView', {filterView: this.filters.id});
                this.$emit('deleted')
            }
        }
    }
}
</script>

<style scoped>

</style>
