<template>
    <!--<upc-select v-model="market" :items="items"></upc-select>-->
    <div>
        <select v-model="culture" class="form-control form-select">
            <option v-for="l in items" :key="l.value" :value="l.value">
                {{l.description}}
            </option>
        </select>
    </div>
</template>

<style>

</style>

<script>
    // import UpcSelect from '@/components/Controls/UpcontentSelect';
    import {mapGetters} from 'vuex'
    export default {
        name: 'DefaultCultureSelect',
        props: { value: String },
        data() {
            return {
                items: [],
                culture: false
            }
        },
        computed: {
            ...mapGetters({
                userLanguageCulturePref: 'languageCulture'
            })
        },
        methods: {
            async load() {
                this.items = await this.$api.get('/language-markets/for-topic-search').then(x=>x.data);
                this.items = [{value: false, description: 'Use browser default'}, ...this.items]
                this.culture = this.userLanguageCulturePref;
            },
            updateUserPreference() {
                this.$store.dispatch('setUserPreference', {key : "languageCulture", value : this.culture});
            }
        },
        watch: {
            userLanguageCulturePref() {
                this.culture = this.userLanguageCulturePref;
            },
            culture() {
                this.updateUserPreference()
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
