export const
codeImports = (stage='qa')  => `
<script src="https://code.galleries.upcontent.com/${stage?stage+'/':''}v3/bower_components/webcomponentsjs/webcomponents-lite.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.22.2/moment.min.js"></script>
<script src="https://code.galleries.upcontent.com/${stage?stage+'/':''}v3/bundle.js"></script>`;

export const
inlineStyleElement = (stylesheet) => `
<style>
${stylesheet}
</style>`;

export const styleImport = (styleUrls) =>
    styleUrls.map(_=>`<link rel="stylesheet" href="${_}">`).join('\n');

export const galleryElement = (rootElement, galleryId, themeIds) =>
    `<${rootElement} gallery="${galleryId}" class="${themeIds.map(_=>`uc-theme--${_}`).join(' ')}"></${rootElement}>`;


export function htmlForGallery({stylesheet, rootElement, galleryId, styleUrls, themeIds}) {
    galleryId = galleryId || '3416b949-5d86-40e5-ab15-374774921126';
    let result =`
<html>
<head>
${codeImports(qa ? 'qa' : '')}
${styleImport(styleUrls||[])}
</head>   
<body>
${galleryElement(rootElement, galleryId, themeIds)}
${inlineStyleElement(stylesheet)}
</body> 
</html>
    `;

    return result;
}

export function iframeUrl({stylesheet, rootElement, galleryId, styleUrls, themeId, themeIds}) {
    return `data:text/html,${encodeURIComponent(htmlForGallery({stylesheet, rootElement, galleryId, styleUrls, themeIds: themeIds||[themeId]}))}`
}

export function rootElementForType(type) {
    return type === 'card' ? 'upcontent-suggestion-list' : 'upcontent-gallery'
}


export const defaultStyles = `

`


export const galleryBaseUrl = (g) => `https://galleries.upcontent.com/galleries/${g.id}`;

export const fetchJsonCode = (url) => `let data = await fetch(\`${url}\`).then(r => r.json())`

export function cardJs(g, collectionId='only') {
    return fetchJsonCode(`${galleryBaseUrl(g)}/collections/${collectionId}/selections.json`);
}
export function carouselJs(g) {
    return fetchJsonCode(`${galleryBaseUrl(g)}/preview.json`);
}

const env = process.env;
let qa = env['VUE_APP_API_BASE_URL'].includes('qa');
