<template>
    <accordion ref="cardAccordion">
        <!--<details open  v-if="val && val.config">-->
            <!--<summary>Config</summary>-->

        <!--</details>-->
        <!--<details v-if="val && val.text">-->
            <!--<summary>Text</summary>-->
        <text-style v-if="val && val.styles && val.type === 'text'" label="" v-model="val.styles"></text-style>
        <container-element-styles v-if="val && val.styles && val.type === 'container-lite'" v-model="val.styles"></container-element-styles>
        <!--</details>-->
        <!--<details v-if="val && val.container">-->
            <!--<summary>Container</summary>-->
        <!--</details>-->
    </accordion>
</template>

<style scoped>
    fieldset {
        border: none;
        padding-left: 0;
    }
    legend {
        font-size: 1.25em;
        border-bottom: 1px solid #463179;
        color: #463179;
    }
</style>

<script>
    import TextStyle from './TextStyle'
    import ContainerStyles from './ContainerStyles'
    import * as Controls from '@/components/Controls'
    import ContainerElementStyles from "@/components/Galleries/ContainerElementStyles";
    export default {
        components: {ContainerElementStyles, ContainerStyles,TextStyle, ...Controls},
        props: { value: {} },
        data() { return { val: this.value}},
        watch: {
            value() { this.val = this.value },
            val() { this.$emit('input', this.val)}
        }
    }
</script>
