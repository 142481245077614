<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    props: [],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            // planName: 'planName',
            // planFrequency: 'planFrequency',
        })
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        ...mapActions({

        }),

    },
    render(h) {
        return h()
    }
}
</script>
