<template>
    <div class="card-suggestion-wrapper d-flex" :class="{'in-collection-batch' : isInCollectionBatch}">

        <div class="in-other-collections">
            Currently in collections:
            <br/>
            <ul>
                <li v-for="collection in myCollections" :key="'existing-collection-'+collection.id">
                    {{ collection.name }}
                </li>
            </ul>
        </div>

        <div class="card suggestion" :style="style">
            <div class="hero-image-wrapper">
                <div class="hover-buttons">
                    <image-downloader :selection="card"
                                      link-class="text-white"
                                      class="edit-card dark-circle-button me-2">
                        <i class="fa fa-link"></i>
                    </image-downloader>
                    <span v-if="canEdit" class="edit-card dark-circle-button me-2" @click="editSelection()">
                        <i class="fa fa-edit"></i>
                    </span>
                    <span v-if=" ! isReviewerForHasSuggestions "
                          class="remove-card dark-circle-button"
                          @click="hideSuggestion">
                        <i class="fa fa-trash"></i>
                    </span>
                </div>
                <div v-if="features.selectionProcessingUi && isStillProcessing" class="flex-column d-flex justify-content-center align-items-center bg-light status-overlay" style="height: 115px">
                    <spinner size="35"></spinner>
                    <div v-if="card.processing_status === 'added'">Processing Content</div>
                    <div v-if="card.processing_status === 'pending'">Loading</div>
                </div>
                <div v-else-if="features.selectionProcessingUi && failedToProcess" class="flex-column d-flex justify-content-center align-items-center status-overlay" style="height: 115px">
                    May have failed to process content
                </div>
                <suggestion-image :display-missing="true" :url="card.image_url" height="115px"/>
            </div>
            <div class="card-body">
                <h2 class="card-title" :title="card.title">
                    <a :href="`#${urlFriendlyString(card.title)}`" @click.prevent="loadArticle" v-text="displayTitle"></a>
                </h2>

                <div class="card-text">
                    <div class="d-flex align-items-center text-nowrap">
                        <a :title="`View Original Source: ${card.publisher}`"
                           :href="$shortener.link(card)"
                           target="_blank"
                           class="text-truncate overflow-x-hidden"
                           style="max-width:100%"
                        >
                            <i class="fa fa-external-link top-1 me-1"></i>
                            <span v-if="card.publisher">
                            {{ card.publisher }}
                            </span>
                        </a>
                        <button v-if="card.publisher"
                                class="btn btn-link text-muted btn-sm d-flex align-items-center border-bottom-0 text-decoration-none ms-1"
                                title="Exclude Source" @click="excludeSource"
                        >
                            <i class="fa fa-minus-circle"></i>
                        </button>
                    </div>
                    <span class="text-nowrap text-dark d-block">
                        <span v-if="prettyDate">{{ prettyDate }}</span>
                        <a :href="card.amplify_link" v-if="card.amplify_link && !card.amplify_link.includes('None') && features.amplifyLinks"> • Go to Amplify</a>
                    </span>
                </div>
                <suggestion-tags :page="card"></suggestion-tags>
            </div>
            <div class="card-footer card-controls">
                <select aria-label="Suggestion Classification"
                        class="flex-grow"
                        @change="setClassification"
                        :value="card.classification"
                        style="flex:2">
                    <option value="blog">Blog</option>
                    <option value="news">News</option>
                    <option value="other">Other</option>
                </select>
                <div class="card-buttons flex-grow">
                    <a :title="favoritedByText" href="#" @click.prevent class="" @click="toggleFavorite()">
                    <span v-if="isFavorited && ! isFavoritedByCurrentUser">
                        <span class="text-primary"><i class="fa fa-star"></i></span>
                    </span>
                        <span v-if="isFavoritedByCurrentUser">
                        <span class="text-danger"><i class="fa fa-star"></i></span>
                    </span>
                        <span v-if="! isFavorited && ! isFavoritedByCurrentUser">
                        <i class="fa fa-star-o"></i>
                    </span>
                    </a>
                    <a v-if="card.notes"
                       href="#"
                       :title="'See Notes: (' + noteCount + ')'"
                       @click.prevent="loadArticleWithNotes"
                       class="">
                        <i class="fa fa-comment-o bottom-1" v-if="noteCount == 0"></i>
                        <i class="fa fa-comment bottom-1" v-else></i>
                    </a>
                    <share-link :suggestion="card" :type="containerType" class="text-center"/>
                    <a @click.prevent="toggleInCollectionBatch"
                       title="Add to collections"
                       href="#"
                       class=""
                       v-if="hasEditPrivileges">
                        <i v-if="isInCollectionBatch" class="fa fa-check-circle text-success"></i>
                        <i v-else class="fa fa-plus"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShareLink from './ShareLink'
import BaseCard from './Card'
import SuggestionImage from '@/components/Suggestions/HeroImage'
import Dropdown from '@/components/Controls/Dropdown'
import EditSelection from "@/components/Collections/EditSelection";
import Modal from "@/components/Controls/Modal";
import OkCancel from "@/components/Controls/OkCancel";
import SuggestionTags from "@/components/Suggestions/SuggestionTags";
import ImageDownloader from "@/components/ImageDownloader";
import {mapGetters} from "vuex";
import Spinner from "@/components/Controls/Spinner.vue";

export default {
    props: ['card'],
    data() {
        return {
            summaryLength: 120,
            titleLength: 50,
            publisherLength: 14,
            showShareSheet: false,
            filterByZest: false
        }
    },
    computed: {
        ...mapGetters({
            hideShare: 'hideShare',
            features: "accountFeatures"
        }),
        isStillProcessing() {
            return ['added', 'pending'].includes(this.card.processing_status)
        },
        minutesSinceUpdate() {
            console.log('* minutesSinceUpdate', Math.abs(this.card.seconds_since_update/60))
            return this.card ? this.card.seconds_since_update/60 : null
        },
        failedToProcess() {
            return ['added'].includes(this.card.processing_status) && this.minutesSinceUpdate > 20
        },
        style() {
            var style = {}

            if (this.myCollections.length && this.isInCollectionBatch) {
                style['transform'] = "translateX(-75%) scale(0.98)";
            }

            return style;
        }
    },
    mixins: [BaseCard],
    components: {
        Spinner,
        ImageDownloader,
        SuggestionTags,
        OkCancel,
        Modal,
        EditSelection,
        ShareLink, SuggestionImage, Dropdown
    },
    methods: {
        urlFriendlyString(string) {
            return this.$strings.shishKabob(string, 255);
        }
    }
}
</script>

<style scoped>

.card-suggestion-wrapper {
    background: rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.0);
    height: 100%;
}

.in-collection-batch {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background: rgba(255, 252, 234, 0.264);
    border-left: 1px solid rgba(155, 155, 155, 0.486);
}

.in-collection-batch .card {
    transform: scale(0.90);
}

.in-other-collections {
    position: absolute;
    z-index: 1;
    width: 75%;
    left: 25%;
    padding: 1em;
}

.card {
    transition: transform 80ms ease-in-out;
    position: relative;
    z-index: 20;
}

.publisher {
    color: #111;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.date {
    color: #535353;
}

p.card-text {
    font-size: var(--font-size-13);
}

h2.card-title {
    font-size: 16px;
    font-weight: 500;
}

.card-controls {
    /*display: grid;*/
    /*grid-template-columns: 3fr 4fr;*/
    /*grid-gap: 10px;*/
    /*justify-content: space-evenly;*/
    display: flex;
    align-items: center;
    position: relative;
}
.card-buttons>* {
    margin: 0 5px;
}
.card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 -5px 0 5px;
}

.card-controls a {
    font-size: 110%;
    text-align: center;
}

.card-controls select {
    padding: 0.8em;
}

.hover-buttons {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    display: flex;
    z-index: 100;
    opacity: 0;
    justify-content: flex-end;
}

.card.suggestion:hover .hover-buttons {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.status-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 99;
    opacity: .8;
}


.card.suggestion {
    width: 100%;
    height: 100%;
}

.card-tags .tag:first-child {
    margin-left: 0;
}

</style>
