import * as configcat from "configcat-js";
import {qa, dev} from './environment';


const qaApiKey = '14zYCBqTO0iC6QN5-rNt0Q/b9taua56aE6mI7mqmm5eVA';
const prodApiKey = '14zYCBqTO0iC6QN5-rNt0Q/xqabsWIj4kOLdzX_W6343g';

const apiKey = qa || dev ? qaApiKey : prodApiKey;

// let logger = configcat.createConsoleLogger(3); // Setting log level to 3 (Info)

export const configCatClient = configcat.createClientWithManualPoll(apiKey, {
    // logger: logger
});


export async function getFeatureConfig(user) {
    await configCatClient.forceRefreshAsync();
    let keys = await configCatClient.getAllKeysAsync();

    let flags = await Promise.all(keys.map(async key => ({[key]: await configCatClient.getValueAsync(key, null, user)})));

    return flags.reduce((flag, config) => ({...config, ...flag}), {})
}


export function configCatUser(user, account) {
    return {
        identifier: account.id,
        email: user.email,
        country: navigator.language,
        custom: {
            account: {id:account.id, name: account.name},
            user: {id:user.id, email: user.email},
            role: account.role,
            languages: navigator.languages,
            language: navigator.language
        }
    }
}

export const ConfigCatPlugin = {
    install: (Vue) => {
        Vue.prototype.$configCat = {
            getFeatureConfig, configCatUser, client: configCatClient
        };
    }
}
