<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-3">
                <b>Email Address</b>
            </div>
            <div class="col-12 col-md-9">
                <input v-model="email" class="form-control" placeholder="Enter an email address"/>
            </div>
        </div>

        <br>

        <div class="row">
            <div class="col-12 col-md-3">
                <b>Permission Level</b>
            </div>
            <div class="col-12 col-md-9">
                <select class="form-control form-select" v-model="role" ref="accountPermissionLevelSelect">
                    <option
                        v-for="role in roles"
                        :key="'role-option-'+role"
                        :value="role"
                    >
                        {{ toCapitalized(role) }}
                    </option>
                </select>
            </div>
        </div>

        <br>


        <div class="row" v-if="role">
            <div class="col-12 col-md-3">
                <b>Folder Assignment</b>
                <button v-if="assignments.length > 0 && canAddAnotherAssignment"
                        @click="addAssignment"
                        class="btn btn-sm mt-2 btn-primary full-width mb-2 d-none d-md-block">+ Add Assignment
                </button>
            </div>
            <div class="col-12 col-md-9">
                <button v-if="assignments.length == 0 && remainingFoldersToShare.length > 0"
                        @click="addAssignment"
                        class="btn btn-sm btn-primary">+ Add Folder Assignment
                </button>
                <div class="row mb-3"
                     v-else
                     v-for="(assignment, index) in assignments"
                     :key="index+'-assignment-creator'">
                    <div class="col-12 col-md-7">
                        <select v-model="assignment.folder" class="form-control form-select mb-2">
                            <option value="">No assignment</option>
                            <option :value="folder.id"
                                    v-for="folder in assignableFoldersForAssignment(assignment)"
                                    :key="folder.id+'-dropdown-select'">
                                {{ folder.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-4">
                        <select class="form-control form-select mb-2" v-model="assignment.role">
                            <option
                                v-for="role in availableAssignmentRoles"
                                :key="'role-option-'+role"
                                :value="role"
                            >
                                {{ toCapitalized(role) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-1">
                        <button class="btn btn-sm btn-white text-danger full-width" @click="removeAssignment(index)"><i
                            class="fa fa-times"></i> <span class="d-inline d-md-none">Remove Assignment</span></button>
                    </div>
                </div>

                <button v-if="assignments.length > 0 && canAddAnotherAssignment"
                        @click="addAssignment"
                        class="btn btn-sm mt-2 btn-primary full-width mb-2 d-block d-md-none">+ Add Assignment
                </button>

            </div>
            <div class="col-12 col-md-3">

            </div>
        </div>

        <br/>
        <button @click="cancel" class="btn btn-secondary btn-sm">Cancel</button>
        <button @click="sendInvitation"
                :disabled="!readyToSend"
                :class="{'disabled': !readyToSend}"
                class="btn btn-success btn-sm float-end">
            <i class="fa fa-paper-plane"></i> Send Invitation
        </button>

    </div>
</template>

<script>


import capitalize from "capitalize";

export default {
    props: ['folders', 'roles', 'team'],
    data() {
        return {
            assignments: [],
            email: '',
            role: '',
        }
    },
    watch: {
        privileges() {
            this.selectedAccountPrivilegesIndex = this.$refs.accountPermissionLevelSelect.selectedIndex;
        }
    },
    computed: {
        lastAssignmentIsEmpty() {
            return (this.assignments.length > 0 && !this.assignments[this.assignments.length - 1].role)
        },
        canAddAnotherAssignment() {
            return !this.lastAssignmentIsEmpty && this.remainingFoldersToShare.length;
        },
        remainingFoldersToShare() {
            return this.$sort.alphabetize(this.folders.filter(folder => {
                return !this.assignments.find(a => a.folder == folder.id) &&
                    !this.team.root.includes(folder.id)
            }))
        },
        availableAssignmentRoles() {
            let roles = this.$accessControl.rolesForRole(this.role);
            if (this.role == 'manager') {
                roles.push("administrator");
            }
            return roles;
        },
        readyToSend() {
            return this.email && this.role && !this.lastAssignmentIsEmpty
        }
    },
    mounted() {
    },
    methods: {
        addAssignment() {
            this.assignments.push({folder: '', role: ''})
        },
        removeAssignment(index) {
            this.assignments.splice(index, 1);
        },
        sendInvitation() {
            let invite = this.$data;
            invite.assignments = invite.assignments.filter(a => !!a.folder);

            if (this.role == 'reviewer') {
                this.$intercom.trackEvent('Invite reviewer', {invite});
            }

            this.$emit('send', invite);
        },
        cancel() {
            this.$emit('cancel');
        },
        assignableFoldersForAssignment(assignment) {
            return this.$sort.alphabetize(this.folders.filter(folder => {
                return (!this.assignments.find(a => a.folder == folder.id) || assignment.folder == folder.id) &&
                    !this.team.root.includes(folder.id);
            }))
        },
        toCapitalized(string) {
            return typeof string === 'string' ? capitalize(string) : '';
        }
    }
}
</script>

<style scoped>
.btn-success.disabled {
    color: #fff;
}
.btn-success.disabled:hover {
    cursor: not-allowed;
}
</style>
