<template>
    <div class="signup-flow-layout d-flex justify-content-between">

        <div class="d-flex justify-content-between flex-column flex-grow">
            <div class="flex-grow full-width d-flex align-items-center justify-content-center">
                <sign-up-flow></sign-up-flow>
            </div>

            <div class="signup-footer d-flex justify-content-start align-items-center p-4 font-size-14 col-margin-2">
                <div class="text-nowrap">
                    {{legalEntityFull}} &copy; {{ new Date().getFullYear() }}
                </div>
                <a class="btn btn-link p-0 text-nowrap font-size-14 ux-supl-privacy-link"
                   target="_blank"
                   :href="privacyPolicyUrl">
                    Privacy
                </a>
                <span class="ms-1 me-1 bottom-1">|</span>
                <a class="btn btn-link p-0 text-nowrap font-size-14 ux-supl-terms-link"
                   target="_blank"
                   :href="termsOfServiceUrl">
                    Terms
                </a>
                <template  v-if="hasAccount">
                    <span class="ms-1 me-1 bottom-1">|</span>
                    <router-link
                        class="btn btn-link text-nowrap p-0 font-size-14 ux-supl-log-out-link"
                        :to="{name : 'Logout'}">Log out
                    </router-link>
                </template>

            </div>
        </div>

        <div class="signup-end-bar background-water-50 d-none d-xxl-block" aria-hidden="true">
            <!-- This is an empty element that Intercom chills out in.  -->
        </div>

    </div>
</template>
<style lang="scss" scoped>
.signup-flow-layout {
    .signup-end-bar {
        width: 376px;
        box-sizing: content-box;
        padding: 1.75vw;
    }
}
</style>
<style lang="scss">
html > body {
    background: #fff;
}
</style>
<script>
import {mapGetters} from "vuex";
import SignUpFlow from "@/components/Auth/V2/SignUpFlow";

export default {
    name: 'SignUpFlowLayout',
    components: {
        SignUpFlow,
    },
    data() {
        return {
            networkActivity: null,
            networkError: null,
        }
    },
    computed: {
        ...mapGetters({
            signupPlan: 'signupPlan',
            signupPlanDuration: 'signupPlanDuration',
            currentAccountOverview: 'currentAccountOverview',
            appName: 'appName',
            legalEntityShort: 'legalEntityShort',
            legalEntityFull: 'legalEntityFull',
            termsOfServiceUrl: 'termsOfServiceUrl',
            privacyPolicyUrl: 'privacyPolicyUrl',
        }),
        hasAccount() {
            return this.currentAccountOverview && this.currentAccountOverview.id;
        },
    },
    watch: {
    },
    mounted() {

    },
    methods: {
    }
}
</script>
