<template>
    <tr>
        <td class="text-break">
            {{ invitation.email }}
        </td>
        <td>
            Sent
        </td>
        <td>
            {{ showRole }}
        </td>
        <td class="text-nowrap">
            {{ showCreated }}
        </td>
        <td class="text-nowrap">
            <span v-if=" invitation.expires">
                    {{ showExpires }}
            </span>
            <span v-else>
                in 7 days
            </span>

        </td>
        <td class="text-nowrap">
            <a class="btn btn-success btn-sm border" :href="invitation.url" @click.prevent="copyLink($refs.hiddenLink)"><i
                class="fa fa-copy"></i></a>
            <input type="text" ref="hiddenLink" :value="invitation.url" style="display: none"/>
            <button @click="resendInvitation(invitation)" class="btn btn-success btn-sm border">
                <i class="fa fa-paper-plane"></i></button>
            <button @click="rescindInvitation(invitation)" class="btn btn-danger btn-sm border">
                <i class="fa fa-times"></i></button>
        </td>
    </tr>
</template>

<script>

import BaseMixin from './Base';
import capitalize from "capitalize";
import moment from "moment";

export default {
    mixins: [BaseMixin],
    computed: {
        showRole() {
            return capitalize(this.invitation.role);
        },
        showCreated() {
            return this.prettyDate(this.invitation.created);
        },
        showExpires() {
            return this.prettyDate(this.invitation.expires);
        },
    },
    methods: {
        prettyDate(date) {
            return moment.utc(date).fromNow()
        }
    }
}
</script>

<style>
</style>
