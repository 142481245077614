<template>
    <div>
        <!--<div v-if="selectedTopics.length" class="d-flex flex-wrap mb-2">-->
            <!--<span class="label" v-for="topic in selectedTopics" :key="'amplify-topic-'+topic">{{topic}} <i class="fa fa-remove" @click="removeTopic(topic)"></i></span>-->
        <!--</div>-->

        <!--<input v-model="newTopic" class="form-control input-sm" placeholder="Enter a Hootsuite Amplify Topic Name..." @keyup.enter="addTopic" @change="addTopic" maxlength="20" />-->
        <v-select v-model="selectedTopics" multiple sync :options="topics"></v-select>
        <!--<span class="characters-remaining text-muted small float-end">{{(20 - newTopic.length)}}</span>-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {VueSelect} from 'vue-select'
export default {
    components: {vSelect: VueSelect},
    props : ['value', 'topics'],
    data(){
        return {
            newTopic : '',
            selectedTopics : this.value
        }
    },
    computed : {
        ...mapGetters({
            apis : 'externalApis',
        })
    },
    watch: {
        selectedTopics(){
            this.$emit('input', this.selectedTopics)
        }
    },
    mounted(){
        this.selectedTopics = this.value;
    },
    methods : {
        addTopic(){
            if (this.newTopic && this.selectedTopics.indexOf(this.newTopic)  == -1)
                this.selectedTopics.push(this.newTopic);
            this.newTopic = '';
        },
        removeTopic(topic){
            this.selectedTopics = this.selectedTopics.filter(t => t !== topic);
        }
    }
}
</script>

<style scoped>
    span.label {
        background: #75578e;
        color: white;
        margin-right: 4px;
        font-size: var(--font-size-13);
        padding: 0.1rem 0.5rem;
        border-radius: var(--border-radius);
        margin-bottom: 4px;
    }
    .fa.fa-remove {
        cursor: pointer;
    }
</style>
