<template>
    <label class="alert" :class="styles">
        <div class="">
            <input v-if="value" type="checkbox" v-model="model" :value="value">
            <input v-else type="checkbox" v-model="model">
        </div>
        <div class="highlight-checkbox-content">
            <slot ></slot>
        </div>
    </label>
</template>

<style scoped>
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    label.alert {
        cursor: pointer;
        position: relative;
    }
    .highlight-checkbox-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
</style>

<script>
    export default {
        model: {
            prop: 'checked',
            event: 'input'
        },
        props: {'value': {}, 'checked': {}, color: {default: 'primary'} },
        data() {
            return {
                model: this.checked
            }
        },
        computed: {
            isChecked() {
                return this.value ? this.model.includes(this.value) : this.model
            },
            styles() {
                return {
                    [`alert-${this.color}`]: this.isChecked,
                    [`border-default`]: !this.isChecked
            }
            }
        },
        watch: {
            model() {
                this.$emit('input', this.model)
            },
            checked() {
                this.model = this.checked;
            }
        }
    }
</script>
