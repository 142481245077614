<template>
    <div>
        <div>
            <pricing-plan-modal></pricing-plan-modal>
        </div>
        <div>
            <component v-bind:is="m.component" v-bind="m.props" v-for="m in openDynamicModals" :key="m.id">

            </component>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import PricingPlanModal from "@/components/Auth/V2/PricingPlanModal";

export default {
    name: "AuthModals",
    components: {
        PricingPlanModal
    },
    data() {
        return {
            openDynamicModals: []
        }
    },
    computed: {
        ...mapGetters({
            user: 'currentUser',
            accountFeatures: 'accountFeatures',
            features: 'features',
        }),
    },
    methods: {
        removeIntercom() {
            this.$intercom.shutdown()
        },
        loadIntercom() {
            this.$intercom.boot({
                user_id: this.user.id,
                name: this.user.name,
                email: this.user.email,
            });
        },
    },
    mounted() {
        window.Events.$on('showQueryBuilderModal', () => {
            let width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            if (width < 500) {
                this.removeIntercom();
            }
        })
        window.Events.$on('showNewWorkflowModal', () => {
            this.$refs.workflowModal.show();
        })
        window.Events.$on('dismissQueryBuilderModal', () => {
            this.loadIntercom();
        });
    }
}
</script>
