import {mapGetters} from "vuex";

/**
 * Map vuex getters to a target object, evaluating the getters result at call-time.
 * Can be used for optimizing performance eg. in the `created()` hook.
 * @param {Array|Object} mapping - The definition typically used with `mapGetters`.
 * @param {Object} target - The target object, eg. `this` in the context of a calling Vue instance.
 * @returns {undefined}
 */
export const mapGettersTo = (mapping, target) => {
    const mappedGetters = mapGetters(mapping)
    Object.keys(mappedGetters).forEach(key => {
        target[key] = mappedGetters[key].call(target)
    })
}