<template>
    <div class="toggle-bar container">
        <fieldset :id="id" class="row">
            <div v-for="(option, index) in options"
                 :key="option.description"
                 class="col text-nowrap toggle-bar-option"
                 :class="{'checked': option.value === value}">
                <input
                    :id="id + '-' + index"
                    :name="id"
                    type="radio"
                    v-model="currentValue"
                    :value="option.value"
                    class="toggle-bar-input"
                />
                <label
                    :for="id + '-' + index" class="toggle-bar-label d-flex justify-content-center align-items-center"
                    :class="uxLabelClass(option.description)">
                    <span>{{ option.description }}</span>
                    <span v-if="hasUpsell(option.upsell)" class="ms-1 font-size-12 toggle-bar-upsell">{{ option.upsell }}</span>
                </label>
            </div>
        </fieldset>
    </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
    name: 'ToggleBar',
    props: {
        options: {type: Array, required: true}, // [{value:'', description:''}]
        value: {required: true},
        showUpsell: {type: Boolean, default: false}
    },
    components: {},
    data() {
        return {
            id: this.$instanceId(),
            currentValue: this.value,
        }
    },
    computed: {
        ...mapGetters({}),
    },
    mounted() {

    },
    watch: {
        currentValue() {
            this.$emit('input', this.currentValue);
        },
        value() {
            this.currentValue = this.value;
        }
    },
    methods: {
        hasUpsell(upsell) {
            return this.showUpsell && upsell !== '';
        },
        uxLabelClass(description) {
            return 'ux-tgl-bar-' + this.$strings.shishKabob(description, 24);
        },
    }
}
</script>
<style lang="scss" scoped>

.toggle-bar:hover, .toggle-bar:focus {
    filter: saturate(120%);
}
.toggle-bar {
    .toggle-bar-option {
        border: 2px solid var(--primary-50);
        border-right-width: 1px;
        border-left-width: 1px;
        overflow: hidden;
        position: relative;
        padding: 0;
        text-overflow: ellipsis;
    }

    .toggle-bar-option:hover, .toggle-bar-option:focus {
        box-shadow: rgba(255,255,255, .95) 0 0 3px, var(--primary-50) 0 0 3px;
    }

    .toggle-bar-option:first-child {
        border-left-width: 2px;
        border-bottom-left-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
    }

    .toggle-bar-option:last-child {
        border-right-width: 2px;
        border-bottom-right-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }

    .toggle-bar-option {
        background: #fff;
        color: var(--primary-50);
        z-index: 1;
        transition: box-shadow 350ms ease-in-out;
    }

    .toggle-bar-option.checked {
        background: var(--primary-50);
        box-shadow: none!important;
        color: #fff;
        z-index: 2;
    }

    .toggle-bar-option {
        .toggle-bar-input {
            position: absolute;
            left: -999em;
            top: -999em;
        }

        .toggle-bar-label {
            text-align: center;
            padding: .05em .15em;
            margin: 0;
            width: 100%;

            cursor: pointer;
        }
    }
}
</style>
