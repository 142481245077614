<template>
    <div class="input-type-file" :title="title">
        <input type="file"
               :name="name"
               :id="$elementId('file-input')"
               :accept="accept"
               class="input-file"
               ref="inputTypeFile"
               @change="changed"
               :value="value"
        />
        <label :for="$elementId('file-input')" ref="labelForInputTypeFile">
            <template v-if="fileChosen">
                <span class="font-size-12">{{ fileName }}</span>
            </template>
            <template v-if="!fileChosen">
                <div class="upload-image-text d-flex align-items-center justify-content-center">
                    <i class="fa fa-upload me-2 bottom-1"/>
                    <span class="d-none d-md-block d-xl-none" aria-hidden="true">Upload {{ fileTypeLabel }}…</span>
                    <span class="d-md-none d-xl-block">Upload New {{ fileTypeLabel }}…</span>
                </div>
            </template>
        </label>
    </div>
</template>

<script>

// import {mapGetters} from 'vuex';

export default {
    name: 'InputTypeFile',
    props: {
        name: {type: String, required: true},
        accept: {type: String, default: ''},
        fileTypeLabel: {type: String, default: 'Image'}
        // example: {type: String, default: null},
    },
    components: {},
    data() {
        return {

        }
    },
    computed: {
        title() {
            return this.fileName !== '' ? this.fileName : 'Choose a file to upload.';
        }
        // ...mapGetters({
        //     example: 'example',
        // }),

    },
    mounted() {
        this.prepareInputTypeFile();
    },
    watch: {},
    methods: {
        changed(e) {
            const fileName = e.target.value.split('\\').pop();

            if (fileName) {
                this.fileName = fileName;
                this.fileChosen = true;
            } else {
                this.fileChosen = false;
            }
            this.$emit('changed', e);
        },
        prepareInputTypeFile() {
            const input = this.$refs.inputTypeFile;

            // Firefox bug fix
            input.addEventListener('focus', function () {
                input.classList.add('has-focus');
            });
            input.addEventListener('blur', function () {
                input.classList.remove('has-focus');
            });
        }
    }
}
</script>
<style lang="scss">
.input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.input-file + label {
    border-radius: var(--border-radius);
    width: 100%;
    font-size: var(--font-size-12);
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.input-file:focus + label,
.input-file.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.input-file + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}


/* Branding */
.input-file + label,
.form .input-file + label{
    color: #fff;
    background-color: var(--primary-50);
}

.input-file:focus + label,
.input-file.has-focus + label,
.input-file + label:hover {
    background-color: var(--primary-75);
}

.upload-image-text {
    font-size: var(--font-size-14)
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
    .upload-image-text {
        font-size: var(--font-size-12)
    }
}
</style>
