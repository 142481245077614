<template>
    <div>
        <div class="card">
            <div class="card-body">
                <progress-bar v-if="networkActivity"/>
                <table class="table table-striped table-condensed" v-if="assignments.length">
                    <thead>
                    <tr>
                        <th>Folder</th>
                        <th>Role</th>
                        <th v-if="canEditAssignments">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="assignment in assignments" :key="assignment.id+'-assignment'">
                        <td>
                            {{ assignment.folder && assignment.folder.name }}
                        </td>
                        <td>
                            {{ toCapitalized(assignment.role) }}
                        </td>
                        <td v-if="canEditAssignments">
                            <button class="btn btn-sm btn-danger" @click="removeAssignment(assignment)"><i
                                class="fa fa-times"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <hr/>

                <div v-if="availableFolders.length">
                    <div v-if="!networkActivity">
                        <h5>Create a new assignment</h5>

                        <div class="d-flex flex-wrap">
                            <select class="m-2" v-model="newAssignment.folder">
                                <option value="">Select a folder</option>
                                <option :value="folder.id"
                                        v-for="folder in availableFolders"
                                        :key="'select-option-'+folder.id">
                                    {{ folder.name }}
                                </option>
                            </select>
                            <select class="m-2" v-model="newAssignment.role">
                                <option value="">Select a role</option>
                                <option :value="role"
                                        v-for="role in availableAssignmentRoles"
                                        :key="'role-in-assignments-table-'+role">
                                    {{ toCapitalized(role) }}
                                </option>
                            </select>
                            <button
                                @click="addAssignment"
                                class="m-2 ms-md-auto btn btn-primary"
                                :disabled=" ! readyToAssign"
                            >
                                Assign Folder
                            </button>
                        </div>

                    </div>

                </div>
                <div v-else>
                    <h6 class="small"><em>All folders have been assigned</em></h6>
                </div>
                <div class="alert alert-danger" v-if="networkError">
                    {{ networkError }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ProgressBar from '@/utilities/ProgressBar';
import capitalize from "capitalize";

export default {
    props: ['user', 'team'],
    components: {
        ProgressBar
    },
    data() {
        return {
            myAssignments: {},
            newAssignment: {
                folder: '',
                role: '',
            },
            assignmentCache: [],
            networkActivity: false,
            networkError: false
        }
    },
    computed: {
        ...mapGetters({
            allFolders: 'folders',
            accountTeamMembers: 'accountTeamMembers'
        }),
        readyToAssign() {
            return this.newAssignment.folder !== '' && this.newAssignment.role !== ''
        },
        folders() {
            return this.allFolders.filter(f => f.account == this.team.id && !this.team.root.includes(f.id));
        },
        assignments() {
            if (this.myAssignments.outbound)
                return this.myAssignments.outbound
                    .map(assn => this.assignmentCache.find(a => assn.includes(a.id)))
                    .filter(a => a && a.assignee.includes(this.user.id) && a.folder)
                    .map(assn => {
                        let folder = this.allFolders.find(f => assn.folder.includes(f.id))
                        return {...assn, folder}
                    })
                    .filter(a => a.folder);
            return []
        },
        canEditAssignments() {
            return this.$accessControl.can(this.team.role, 'deleteFolderAssignments');
        },
        availableAssignmentRoles() {
            var roles = this.$accessControl.rolesForRole(this.team.role);
            if (this.user.role == 'reviewer') {
                roles = roles.filter(r => r == 'reviewer');
            }
            return roles
        },
        availableFolders() {
            return this.$sort.alphabetize(this.folders.filter(f => {
                return f &&
                    !this.assignmentCache.find(a => a.folder.includes(f.id) && a.assignee.includes(this.user.id));
            }))
        },
        accountrole() {
            let account = this.accountTeamMembers.find(a => a.id == this.team.id);
            if (account) {
                let member = account.members.find(m => m.id == this.user.id);
                if (member) {
                    return member.role
                }
            }
        },
    },
    watch: {
        myAssignments() {
            if (this.myAssignments.outbound) {
                this.myAssignments.outbound.forEach(url => {
                    if (!this.assignmentCache.find(a => url.includes(a.id))) {
                        this.networkActivity = true;
                        this.$api.get(url).then(response => {
                            this.networkActivity = false;
                            this.assignmentCache.push(response.data)
                        })
                    }
                })
            }
        }
    },
    mounted() {
        this.fetchAssignments()
    },
    methods: {
        addAssignment() {
            let url = `/accounts/${this.team.id}/assignments`
            let assignment = {
                assignee: this.user.id,
                folder: this.newAssignment.folder,
                role: this.newAssignment.role
            }
            this.networkActivity = true;
            this.$api.post(url, assignment).then(() => {
                this.networkActivity = false;
                this.fetchAssignments()
                this.$emit('changed', {assignment});
            }).catch(e => {
                if (e.response && e.response.data && e.response.data.description) {
                    this.networkError = e.response.data.description;
                    this.networkActivity = false;
                    setTimeout(() => {
                        this.networkError = false;
                    }, 5000)
                }
            })
        },
        fetchAssignments() {
            let url = `/accounts/${this.team.id}/assignments`
            this.networkActivity = true;
            this.$api.get(url)
                .then(response => {
                    this.networkActivity = false;
                    this.myAssignments = response.data
                    this.newAssignment = {folder: '', role: ''}
                })
        },
        removeAssignment(assignment) {
            let index = this.assignmentCache.indexOf(this.assignmentCache.find(a => a.id == assignment.id));
            this.assignmentCache.splice(index, 1);
            let url = `/assignments/${assignment.id}`;
            this.$api.delete(url);
            this.$emit('changed', {assignment});
        },
        toCapitalized(string) {
            return typeof string === 'string' ? capitalize(string) : '';
        }

    }
}
</script>

<style>
</style>
