<template>
    <div class="row-margin-8">

        <div>
            <div class="label-chill pb-1">Card Number</div>
            <div id="card-number" class="form-control cc-input card-number"></div>
        </div>

        <div class="d-flex justify-content-start col-margin-8">
            <div>
                <div class="label-chill pb-1">Expiration</div>
                <div id="card-expiry" class="form-control cc-input card-expiry"></div>
            </div>
            <div>
                <div class="label-chill pb-1">CVC</div>
                <div id="card-cvc" class="form-control cc-input card-cvc text-end"></div>
            </div>
        </div>

        <div>
            <div class="label-chill pb-1">Zip</div>
            <div id="postal-code" class="form-control cc-input card-zip"></div>
        </div>


        <div class="">
            <template v-if="networkError">
                <div id="card-error" ref="cardError" class="alert alert-danger">{{ networkError }}</div>
            </template>
            <template v-else-if="!showButton">
                <div class="alert" aria-hidden="true">&nbsp;</div>
            </template>
        </div>

        <div v-if="showButton" class="d-flex justify-content-start align-items-center">
            <button
                class="btn btn-success px-5  mt-2 ux-cc-entry-save-card"
                :class="{'disabled' : networkActivity}"
                @click="saveCard"
                :title="saveCardButtonTitle"
            >
                <span v-if="buttonText" v-text="buttonText"/>
                <span v-else>Save Card</span>
            </button>
        </div>

    </div>
</template>

<script>

export default {
    props: {
        account: {},
        buttonText: String,
        showButton: {type: Boolean, default: true},
    },
    components: {},
    data() {
        return {
            networkActivity: false,
            stripeKey: process.env.VUE_APP_STRIPE_KEY,
            networkError: false,

            token: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
        }
    },
    mounted() {

        const sharedStyles = {
            fontSize: '17px',
            fontFamily: '" roboto, arial,  sans-serif',
            color: '#3c4044',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#6c757d',
                fontWeight: '300',
                fontStyle: 'italic',
            },
        };
        const style = {
            empty: {
                ...sharedStyles,
            },
            base: {
                ...sharedStyles,
            },
            invalid: {
                ...sharedStyles,
                color: '#893439',
                iconColor: '#893439',
            },
            complete: {
                ...sharedStyles,
            }
        };
        this.cardNumber = this.stripeElements.create('cardNumber', {style, showIcon: true});
        this.cardNumber.mount('#card-number');
        this.cardExpiry = this.stripeElements.create('cardExpiry', {style});
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc = this.stripeElements.create('cardCvc', {style});
        this.cardCvc.mount('#card-cvc');

        this.postalCode = this.stripeElements.create('postalCode', {style});
        this.postalCode.mount('#postal-code');
    },
    beforeDestroy() {
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
        // this.postalCode.destroy();
    },
    computed: {
        stripeElements() {
            return this.$stripe.elements();
        },
        saveCardButtonTitle() {
            let title = '';

            if (this.networkActivity) {
                title = 'Saving...'
            }

            return title;
        }
    },
    methods: {
        async saveCard() {
            this.networkActivity = true;

            let response = await this.$stripe.createToken(this.cardNumber);

            if (response.error) {
                // handle error here
                this.networkError = response.error.message;
                return false;
            }

            if (this.account) {
                return this.payForPlan(response)
            }

            // Send an event up to Create.vue for creating additional accounts.
            this.$emit('cardResponseReceived', {response});
            return true;
        },
        payForPlan(data) {
            return this.$api.put(`/accounts/${this.account.id}/payment-method`, {token: data.token.id})
                .then(this.handlePaymentMethodResponse)
                .catch(this.handlePaymentMethodError)
        },
        handlePaymentMethodResponse(resp) {
            this.$emit('cardAdded', {card: resp.data});
            this.networkActivity = false;
            return true;
        },
        handlePaymentMethodError(e) {
            this.networkActivity = false;
            if (e.response) {
                this.networkError = e.response.data.description
            }

            this.$emit('cardError', {error: e});

            return false;
        },
    }
}
</script>

<style lang="scss" scoped>

.btn-success.disabled {
    color: #fff;
}

.btn-success.disabled:hover {
    cursor: not-allowed;
}

.cc-input {
    height: 2.25em;
    padding: .5em 1em;
}

//.card-number {
//    width: 15em;
//}

.card-expiry {
    width: 6.25em;
}

.card-cvc {
    width: 5em;
}

.card-zip {
    width: 6em;
}

</style>
