<template>
    <div id="suggestion-manager-card" role="menubar" class="mb-2">
        <slot name="title" v-if="showTitle" >
            <div class="suggestion-manager-card-title d-flex align-items-center suggestion-name p-2">
                <suggestion-title :use-light-variation="true" />
            </div>
        </slot>

        <div class="d-flex flex-wrap pb-1 pt-0 pt-sm-2 sidebar-section" :class="{'d-none': !accountFeatures.newCollectionUx}">
            <suggestion-manager-props />
        </div>
    </div>
</template>

<style lang="scss">
.suggestion-manager-card-title  {
    background: var(--primary-50);
    border-radius: var(--border-radius);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: var(--water-100)
}
</style>

<style lang="scss" scoped>
#suggestion-manager-card {
    .suggestion-name {
        font-size: var(--font-size-14);
    }


    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        .suggestion-name {
            font-size: var(--font-size-16);
        }
    }
}
</style>


<script>
import {mapGetters} from "vuex";
import SuggestionManagerProps from "@/components/Suggestions/ManageSuggestion/SuggestionManagerProps";
import {getIconClass} from "../../../services/Icons";
import SuggestionTitle from "@/components/Suggestions/ManageSuggestion/SuggestionTitle";

export default {
    name: "SuggestionManager",
    data() {
        return {}
    },
    props: {
        showTitle: {type: Boolean, default: true},
    },
    components: {
        SuggestionManagerProps,
        SuggestionTitle,
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            topic: 'currentTopic',
            hasSuggestions: 'hasSuggestions',
            canManageCollection: 'canManageCollection',
            accountFeatures: 'accountFeatures',
            suggestionViewerType: 'suggestionViewerType',
        }),
        icon() {
            return this.suggestionViewerType === 'collections' ? getIconClass(this.currentCollection.icon || 'bolt') :
                'fa fa-search'
        },
        name() {
            if (this.suggestionViewerType === 'topics')
                return this.topic && this.topic.name
            else
                return this.currentCollection.name
        }
    },
    mounted() {

    },
    methods: {
        isCollectionSource(type) {
            /* Checks if the provided string can be a source for a collection. */
            /* If 'custom' source type is added, then it will need to be added below as well */
            return typeof type === 'string' && /rss|collection|topic/.test(type);
        },
    },

}
</script>
