<script setup> //FIXME: add ts
import {ref, computed, onMounted} from "vue";
import store from "@/store";
import {apis} from "@/init/ApiInit";
import NestableModal from "@/components/Controls/NestableModal";
import ContentPolicy from "../ContentPolicy";
import ContentPoliciesCard from "@/components/Collections/V2/ContentPoliciesCard.vue";

const collectionId = ref(null);
const isOpen = ref(false);
const abstractLengthComponent = ref(null);
const mandatoryContentPolicy = ref({});

const accountFeatures = computed(() => store.getters.accountFeatures);
const currentCollection = computed(() => store.getters.currentCollection);

const loadAdvancedSettings = () => store.dispatch('loadAdvancedSettings');

const handleMandatoryPolicySelected = (newPolicy) => {
    mandatoryContentPolicy.value = newPolicy;
};

const save = async() => {
    if(abstractLengthComponent?.value) await abstractLengthComponent.value.save();

    if(mandatoryContentPolicy.value && mandatoryContentPolicy.value.id
        || mandatoryContentPolicy.value.name === 'No Content Policy' && mandatoryContentPolicy.value.id === null) {
        const resp = await apis.contentPolicy.collectionsCollectionIdContentPolicyPut(store.getters.currentCollection.id, {
            policies: {
                mandatory: mandatoryContentPolicy.value.id,
            }
        })
    }
    await loadAdvancedSettings();
    window.Events.$emit('close_collectionPolicy');
};

onMounted(() => {
    window.Events.$on('show_collectionPolicy', data => {
        console.log('show_collectionPolicy', data)
        collectionId.value = data && data.collectionId || currentCollection.value && currentCollection.value.id;
    });
});
</script>

<template>
    <NestableModal
        modal-name="collectionPolicy"
        size="md collectionAdvSettingsModal"
        class=""
        @shown="isOpen = true"
        @closed="isOpen = false"
    >
        <template v-slot:header><h5>Collection Policy</h5></template>
        <ContentPolicy v-if="isOpen" :collection-id="currentCollection.id" ref="abstractLengthComponent"></ContentPolicy>
        <ContentPoliciesCard
            v-if="isOpen"
            @mandatoryPolicySelected="handleMandatoryPolicySelected"
        />
        <template>
            <div class="buttons">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary ms-2" @click="save">Save</button>
            </div>
        </template>&nbsp;

    </NestableModal>
</template>

<style scoped>

</style>
