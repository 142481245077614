<template>
    <a :href="learnUrls[name]" v-if="learnUrls[name]" target="_blank" v-bind="$props">
        <slot/>
    </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "LearnLink",
    props: {
        name: {type: String, required: true}
    },
    computed: {
        ...mapGetters({
            learnUrls: 'learnUrls'
        })
    }
}
</script>

<style scoped>

</style>
