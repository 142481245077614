<template>
    <div>
        <div>
            <h4 class="subtitle">Content Type</h4>
            <p class="filter-item" v-for="thisType in availableContentTypes" :key="'content-types-'+thisType">
                <label @click.stop><input
                    @change="update()"
                    v-model="contentType"
                    type="radio"
                    class="form-check-input"
                    :value="thisType"
                >
                    {{thisType}}
                </label>
            </p>
        </div>
    </div>
</template>

<script>

import Base from './FilterMixin'
import {mapGetters} from 'vuex'

export default {
    mixins : [Base],
    computed: {
        ...mapGetters({
            features: 'features'
        }),
        availableContentTypes() {
            return  [
                'All',
                'News',
                'Blog',
                ...(this.features.otherContentType? [
                    'Other',
                    'All (including other)'
                ] : [])
            ]
        }
    },
    watch : {
        filters(){
            if (this.filters)
                this.contentType = this.filters.contentType
        }
    },
    data(){
        return {
            contentType: '',
        }
    },
    mounted(){
        if (this.filters)
            this.contentType = this.filters.contentType
    },
    methods : {
        update(){
            this.updateFilter('contentType', this.contentType);
        }
    }
}
</script>

<style>
</style>
