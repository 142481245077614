import {StringUtils} from "@/utilities/Strings";

export function highlightSearchText(targetText, searchText) {
    let output = StringUtils.escapeHtml(targetText);
    if (searchText){
        if (!RegExp.escape) {
            RegExp.escape = function(value) {
                // eslint-disable-next-line no-useless-escape
                return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&")
            };
        }

        var reg  = new RegExp(RegExp.escape(searchText), "ig");
        output = targetText.replace(reg, '<span class="highlight">'+searchText+'</span>')
    }
    return StringUtils.truncate(output)
}

