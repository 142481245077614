<template>
    <div class="dropdown" role="menuitem">
        <span  data-bs-toggle="dropdown">
            <slot name="toggle"/>
        </span>
        <div class="dropdown-menu dropdown-menu-right" role="menu">
            <slot name="dropdown" />
        </div>
    </div>
</template>

<style>

</style>

<script>
    export default {

    }
</script>
