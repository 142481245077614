<template>
    <div class="d-flex powerups-config-cards">
        <powerups-grid @change="currentPowerup = $event" class="flex-grow"></powerups-grid>
        <powerup-config :powerup="currentPowerup"></powerup-config>
    </div>
</template>

<script>
import PowerupsGrid from "./PowerupsGrid.vue";
import PowerupConfig from "./PowerupConfig.vue";
export default {
    name: "PowerupsV3",
    components: {PowerupConfig, PowerupsGrid},
    data() {
        return {
            currentPowerup: null
        }
    }
}
</script>

<style scoped>
.powerups-config-cards {
    max-height: 600px;
}
.powerups-config-cards .powerup-config {
    max-width: 600px;
    min-width: 600px;
}
</style>
