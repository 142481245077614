<!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: sanitization-->
<template>
    <popover-button
        v-show="excludedFilterViews.length"
        :title="`${excludedFilterViews.length} Usages`"
        :content="content"
        :html="true"
    >
        <i class="fa fa-times-circle"/>
        {{excludedFilterViews.length}}
        Excluded
    </popover-button>
</template>
<script>
import FilterUsagesData from "./FilterUsagesData";
import * as $ from 'jquery'
import PopoverButton from "../Controls/PopoverButton";
import {sanitizeHtml} from "../../utilities/utils";
import {validateForCollection, validateForTopic} from "../../services/Filters";
export function renderItems(excludedFilterViews) {
    let forEach = (items, map) => items.map(map).join('\n')
    return `
        <ul  style='max-height:500px; overflow:auto; margin-left:0; padding-left:0;'  class='font-size-12 mb-2'>
            ${forEach(excludedFilterViews, view => `
                <li class=''>
                    <div>
                        <div class="fw-semibold text-dark d-block">${sanitizeHtml(view.name)}</div>
                        <ul class='font-size-11 d-block'>
                            ${forEach(view.errors, error => `
                                <li class='mb-1 ms-0 ps-0 text-dark'>${sanitizeHtml(error)}</li>
                            `)}
                        </ul>
</div>
                </li>`)}
        </ul>`
}
export default {
    name: "ExcludedFiltersLink",
    components: {PopoverButton},
    props: {
        filters: {},
        hasContentType: {}
    },
    computed: {
        content() { return renderItems(this.excludedFilterViews)},
        excludedFilterViews() {
            let validator = this.hasContentType === 'topic' ? validateForTopic : validateForCollection
            return this.filters.map(f => ({...f, errors: validator(f.toApi())})).filter(f => f.errors.length > 0)
        },
    },
}
</script>

<style scoped>

</style>
