<template>
    <div role="radiogroup" :class="{ 'btn-group': true, 'btn-group-sm': small }">
        <slot />
    </div>
</template>

<style scoped>

</style>

<script>
    import ToggleItem from './ToggleItem'
    export default {
        props: ['value', 'small' ],
        components: { ToggleItem },
        data() { return {
            selectedKey: this.value,
            items: []
        }},
        watch: {
            selectedKey(){
                this.$emit('input', this.selectedKey)
            },
            value() {
                this.itemClicked(this.value)
            }
        },
        created() {
            this.items = this.$children;
        },
        mounted(){
            this.itemClicked(this.value);
        },
        methods: {
            itemClicked(key) {
                this.selectedKey = key;
                this.$children.forEach(item => {
                    item.selected = item.value === key
                });
            }
        }
    }
</script>
