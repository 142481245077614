<template>
    <div>
        <zapier-zap-templates
            v-if="!hideZapier"
            apps="upcontent"
            :limit='20'
            link-target="new-tab"
            presentation="row"
            theme="light"
        ></zapier-zap-templates>
    </div>
</template>

<script>
    // #4f2c82
    import {mapGetters} from "vuex";

    export default {
        name: "ZapierIntegrationList",
        computed: {
            ...mapGetters({
                hideZapier: 'hideZapier'
            })
        }
    }
</script>

<style scoped>

</style>
