<template>
    <div :class="{'tab-content': true, active: isActive }" :id="id">
        <slot />
    </div>
</template>

<style scoped>
    .tab-content {
        display: none;
    }
    .tab-content.active {
        display: block;
    }
</style>

<script>
    export default {
        props: {
            label: String,
            focus: { type:String, default: null },
            iconClass: String,
            active: Boolean,
            tag: String,
            visible: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isActive: this.active,
                id: this.$instanceId()
            }
        }
    }
</script>
