<template>
    <div class="sidebar-menu-layout--root">
        <div class="sidebar-menu-layout--container">
            <div class="sidebar-menu-layout--sidebar-container">
                <div class="sidebar-menu-layout--dropdown-container">
                    <div class="sidebar-menu-layout--dropdown-toggle ">
                        <button
                            type="button"
                            class="btn btn-secondary full-width mb-2"
                            @click="menuOpen = !menuOpen">
                            <slot name="sidebarToggle"></slot>
                        </button>
                    </div>
                    <div class="sidebar-menu-layout--dropdown-menu" :class="{open: menuOpen, closed: !menuOpen}">
                        <slot name="sidebar"></slot>
                    </div>
                </div>
            </div>
            <div class="sidebar-menu-layout--main" :class="{closed: menuOpen, open: !menuOpen}">
                <slot name="main"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SidebarMenuLayout",
    data() {
        return {
            menuOpen: false
        }
    }
}
</script>

<style scoped>
    .sidebar-menu-layout--root {
        container-type: inline-size;
        display: grid;
        grid-template-rows: 1fr;
    }
    @container (width > 600px) {
        .sidebar-menu-layout--container {
            display: grid;
            grid-template-columns: 250px 1fr;
            column-gap: 2rem;
        }
        .sidebar-menu-layout--dropdown-toggle {
            display: none;
        }
        .sidebar-menu-layout--main {
            display: grid;
            grid-template-rows: 1fr;
        }
    }
    @container  (max-width: 599.99px) {
        .sidebar-menu-layout--container {
            display: grid;
            grid-template-rows: max-content 1fr;
        }
        .sidebar-menu-layout--main {
            display: grid;
            grid-template-rows: 1fr;
        }
        .sidebar-menu-layout--main.closed {
            display: none;
        }
        .sidebar-menu-layout--dropdown-toggle {
            display: flex;
        }
        .sidebar-menu-layout--dropdown-container {
            display: grid;
            grid-template-rows: max-content 1fr;
        }
        .sidebar-menu-layout--dropdown-menu.closed {
            display: none;
        }
    }
</style>
