<template>
    <form>
        <label class="label-calm">
            Everyone Social Groups
        </label>
        <uc-select
            :items="groupOptions"
            v-model="groupId"
        >
        </uc-select>
        <div class="mt-2">
            <label class="">
                <input type="checkbox" v-model="isShareable"/> Allow sharing
            </label>
            <label class="">
                <input type="checkbox" v-model="isBranded"/> Branded
            </label>
        </div>

        <fieldset>
            <legend class="label-calm">
                Disclosures
            </legend>
            <div>
                <label class="d-flex">
                    <i class="fa fa-twitter social-network-icon-label" title="Twitter"></i>
                    <div class="screen-reader-only">Twitter</div>
                    <uc-select
                        class="flex-grow"
                        :items="disclosureOptions"
                        v-model="twitterDisclosureId"
                    >
                    </uc-select>
                </label>
                <label class="d-flex">
                    <i class="fa fa-linkedin social-network-icon-label" title="LinkedIn"></i>
                    <div class="screen-reader-only">LinkedIn</div>
                    <uc-select
                        class="flex-grow"
                        :items="disclosureOptions"
                        v-model="linkedinDisclosureId"
                    >
                    </uc-select>
                </label>
                <label class="d-flex">
                    <i class="fa fa-facebook social-network-icon-label" title="Facebook"></i>
                    <div class="screen-reader-only">Facebook</div>
                    <uc-select
                        class="flex-grow"
                        :items="disclosureOptions"
                        v-model="facebookDisclosureId"
                    >
                    </uc-select>
                </label>
                <label class="d-flex">
                    <i class="fa fa-instagram social-network-icon-label" title="Facebook"></i>
                    <div class="screen-reader-only">Facebook</div>
                    <uc-select
                        class="flex-grow"
                        :items="disclosureOptions"
                        v-model="instagramDisclosureId"
                    >
                    </uc-select>
                </label>
            </div>
        </fieldset>

        <div class="mt-2">
            <fieldset class="mt-2">
                <legend class="text-muted small">Post Text</legend>
                <label class="d-block"><input type="radio" class="form-check-input" v-model="shareCommentary" value="summary,title" name="everyoneSocialSummary"> User summary if entered, title as backup</label>
                <label class="d-block"><input type="radio" class="form-check-input" v-model="shareCommentary" value="summary,excerpt" name="everyoneSocialSummary"> User summary if entered, excerpt as backup</label>
                <label class="d-block"><input type="radio" class="form-check-input" v-model="shareCommentary" value="title" name="everyoneSocialSummary"> Title</label>
            </fieldset>
            <label class="">
                <input type="checkbox" v-model="imageAdded"/> Image should be added
            </label>
        </div>
        <label class="label-calm">
            Expires in (Days)
            <input type="number" v-model="expiresIn" step="1" min="1" class="form-control">
            <span class="small text-muted">Leave blank to never expire</span>
        </label>
        <label class="">
            <input type="checkbox" v-model="publish" disabled/> Publish immediately
        </label>
    </form>

</template>

<script>
import {UcSelect} from "@/components/Controls";

export default  {
    name: "EveryoneSocialCollectionSettings",
    components: {UcSelect},
    props: {
        savedSettings: { type: Object, default: () => ({})},
        disclosures: { type: Array, default: () => ([])},
        socialGroups: { type: Array, default: () => ([])},
    },
    data() {
        return {
            groupId: this.savedSettings && this.savedSettings.group_id || null, // string
            twitterDisclosureId: this.savedSettings && this.savedDisclosureIdForNetwork('twitter') || null, // string
            linkedinDisclosureId: this.savedSettings && this.savedDisclosureIdForNetwork('linkedin') || null,
            facebookDisclosureId: this.savedSettings && this.savedDisclosureIdForNetwork('facebook') || null,
            instagramDisclosureId: this.savedSettings && this.savedDisclosureIdForNetwork('instagram') || null,
            isBranded: this.savedSettings && this.savedSettings.everyone_social_settings.is_branded || false, // bool
            shareCommentary: this.savedSettings && this.savedSettings.share_commentary || 'summary,title', // string
            isShareable: this.savedSettings && this.savedSettings.everyone_social_settings.is_shareable || false, // bool
            imageAdded: this.savedSettings && this.savedSettings.image_added || false, // bool
            expiresIn: this.savedSettings && this.savedSettings.expires_in || null, // number
            publish: this.savedSettings && this.savedSettings.publish || true, // bool
        }
    },
    computed: {
        groupOptions() {
            return this.socialGroups.map(group => ({
                value: group.id,
                description: group.name
            }));
        },
        disclosureOptions() {
            return this.disclosures.map(disclosure => ({
                value: disclosure.disclosure_id,
                description: disclosure.title,
                subtitle: disclosure.title === disclosure.disclosure ? null : disclosure.disclosure
            }));
        },
    },
    watch: {
        savedSettings() {
            this.groupId = this.savedSettings.group_id;
            this.twitterDisclosureId = this.savedDisclosureIdForNetwork('twitter');
            this.linkedinDisclosureId = this.savedDisclosureIdForNetwork('linkedin');
            this.facebookDisclosureId = this.savedDisclosureIdForNetwork('facebook');
            this.instagramDisclosureId = this.savedDisclosureIdForNetwork('instagram');
            this.isBranded = this.savedSettings.everyone_social_settings.is_branded;
            this.shareCommentary = this.savedSettings.share_commentary;
            this.isShareable = this.savedSettings.everyone_social_settings.is_shareable;
            this.imageAdded = this.savedSettings.image_added;
            this.expiresIn = this.savedSettings.expires_in;
            this.publish = this.savedSettings.publish;
        },
    },
    methods: {
        savedDisclosureIdForNetwork(network) {
            if (!this.savedSettings || !this.savedSettings.everyone_social_settings.disclosure_list) return null;
            const disclosure = this.savedSettings.everyone_social_settings.disclosure_list.find(i => i.network === network);
            return disclosure && disclosure.disclosure_id;
        },
        constructSettingsApiObject() {
            return {
                group_id: this.groupId,
                share_commentary: this.shareCommentary,
                image_added: this.imageAdded,
                expires_in: this.expiresIn,
                publish: this.publish,
                everyone_social_settings: {
                    disclosure_list: [
                        {
                            disclosure_id: this.twitterDisclosureId,
                            network: 'twitter',
                        },
                        {
                            disclosure_id: this.linkedinDisclosureId,
                            network: 'linkedin',
                        },
                        {
                            disclosure_id: this.instagramDisclosureId,
                            network: 'instagram',
                        },
                        {
                            disclosure_id: this.facebookDisclosureId,
                            network: 'facebook',
                        },
                    ].filter(x=>x.disclosure_id),
                    is_branded: this.isBranded,
                    is_shareable: this.isShareable,
                }
            }
        }
    }
}
</script>

<style scoped>
    .social-network-icon-label {
        width: 2em;
        font-size: 1.5rem;
        vertical-align: baseline;
    }
</style>
