<template>
    <div class="btn-group align-items-start">
        <button :class="{'btn-primary' : displayType == 'list', 'btn-default' : displayType != 'list'}"
                @click="setDisplayType('list')"
                class="btn">
            <i class="fa fa-list"></i>
        </button>
        <button
                :class="{'btn-primary' : displayType == 'grid', 'btn-default' : displayType != 'grid'}"
                @click="setDisplayType('grid')"
                class="btn">
            <i class="fa fa-th"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: "SuggestionLayoutButtons",
    props:
        ['displayType'],
    methods: {
        setDisplayType(type) {
            this.$emit('displayTypeChange', type)
        },
    }
}
</script>
