
<script>
import {validateForCollection, validateForTopic} from "../../services/Filters";
import {sanitizeHtml} from "../../utilities/utils";
import {StringUtils} from '../../utilities/Strings'

class Usage {
    static fromApi(apiUsage) {
        let usage = new Usage();
        let displayData = apiUsage.display_data;
        Object.assign(usage, {
            ...apiUsage,
            entityId: apiUsage.entityId,
            fromType: displayData.source_type,
            fromName: displayData.source_name,
            toType: displayData.destination_type,
            toName: displayData.destination_name
        })
        return usage
    }
}

class UsageCategory {
    static oneFromApiUsageList(key, usageList) {
        let result = new UsageCategory();
        Object.assign(result, {
            key: key,
            name: StringUtils.capitalizeWords(key.split('_').join(' ')),
            usages: usageList.map(Usage.fromApi),
        })
        return result
    }

    static listFromApiUsageData(usageData) {
        const removeNameAttr = ([k, v]) => k !== 'name' && v && v.length;
        return Object.entries(usageData).filter(removeNameAttr).map(([k,v]) => UsageCategory.oneFromApiUsageList(k, v))
    }
}

const exceptCurrentEntity = entityId => usage => usage.entityId !== entityId || !tentityId;
const removeCurrentEntityUsages = entityId => category => ({
    ...category,
    usages: category.usages.filter(exceptCurrentEntity(entityId))
});

export default {
    name: "FilterUsagesData",
    props: {
        currentUsage: { default: null }
    },
    mounted() {
        this.loadFilterViewUsages()
    },
    data() {
        return {
            usageData: {},
        }
    },
    watch: {
        filterViewId() {
            this.loadFilterViewUsages()
        }
    },
    methods: {
        async loadFilterViewUsages() {
            if (this.filterViewId)
                this.usageData = await this.fetchUsages();
            else
                this.usageData = {}
        },
        async fetchUsages() {
            let response = await this.$api.get(`/filter-views/${this.filterViewId}/usages`);
            return response.data
        },
        validate(filters) {
            let topicFilters = this.usages.filter(u => u.topic_id).length;
            let collectionFilters = this.usages.filter(u => u.collection_id).length;

            let collectionMessages = collectionFilters > 0 ? validateForCollection(filters) : [];
            let topicMessages = topicFilters > 0 ? validateForTopic(filters) : []

            return [...collectionMessages, ...topicMessages];
        }
    },
    computed: {
        categories() {
            return UsageCategory
                .listFromApiUsageData(this.usageData)
                // .map(removeCurrentEntityUsages(this.currentUsage))
        },
        usages() {
            return this.categories.flatMap(c => c.usages)
        },
        usageCount() {
            return this.usages.length
        },
    }
}
</script>