<template>
    <form @submit.prevent.stop="save()" @reset.prevent.stop="cancel()">
        <fieldset class="p-0 no-borders small-fieldset" style="border: none">
            <legend class="label">HTML Content Campaign</legend>
            <content-campaign-selector
                ref="contentCampaignSelect"
                v-model="campaignId"
                :campaign="campaign"
                :collection="collection"
                @campaign-selected="campaignSelected"
                :required="true"
            >
                <!--<option v-for="c in campaigns" :key="c.id" :value="c.id">-->
                    <!--{{c.name}}-->
                <!--</option>-->
            </content-campaign-selector>
        </fieldset>
        <label>
            Campaign Name
            <input type="text" v-model="name" class="form-control form-control-sm">
        </label>
        <label>
            Subject Line
            <input type="text" v-model="subject" class="form-control form-control-sm">
        </label>
        <label>
            Select Recipients
        </label>
        <uc-select v-model="selectedLists" :multiple="true" :items="allLists">
        </uc-select>
        <label>
            From Name
            <input type="text" v-model="fromName" class="form-control form-control-sm">
        </label>
        <label>
            From Address
            <select v-model="fromAddress" class="form-control form-select form-control-sm">
                <option v-for="e in accountEmails" :key="e.email_address">{{e.email_address}}</option>
            </select>
        </label>
        <label>
            Reply-to Address
            <select v-model="replyTo" class="form-control form-select form-control-sm">
                <option v-for="e in accountEmails" :key="e.email_address">{{e.email_address}}</option>
            </select>
        </label>
        <button class="btn btn-secondary btn-sm" type="reset">Cancel</button>
        <button class="btn btn-primary btn-sm" type="submit">{{collection && collection.id ? 'Save' : 'Create' }}</button>
    </form>
</template>

<style>

</style>

<script>
    import UcSelect from "@/components/Controls/UpcontentSelect";
    import {mapGetters} from 'vuex';
    import {UpcCCCampaign} from './ConstantContactViewModels';
    import ContentCampaignSelector from "@/components/ContentTemplates/ContentCampaignSelector";
    import * as toastr from 'toastr'
    const probableAuthErrorNotify = action => () =>
        toastr.error(
            `Couldn't ${action}, this is usually due to authentication expiring, due to not being accessed within Constant Contact's expiration time period, to refresh go to Integrations and disconnect and reconnect`, `Couldn't ${action}`,  {timeOut: 10000}
        )
    export default {
        components: {ContentCampaignSelector, UcSelect},
        props: {
            collection: Object,
            campaign: Object,
        },
        data() {
            return {
                campaignId: this.campaign && this.campaign.contentCampaignId || null,
                campaigns: [],
                subject: this.campaign && this.campaign.subject || "What we're reading...",
                selectedLists: this.campaign && this.campaign.lists || [],
                fromName: this.campaign && this.campaign.fromName || '',
                fromAddress: this.campaign && this.campaign.fromAddress || '',
                replyTo: this.campaign && this.campaign.replyTo || '',
                accountEmails: [],
                name: this.campaign && this.campaign.name || this.collection && this.collection.name || '',
                allLists: []
            };
        },
        watch: {
            collection() {
                this.reset();
                this.loadServerData();
            },
        },
        computed: {
            ...mapGetters({
                currentAccountOverview: 'currentAccountOverview',
            }),
        },
        methods: {
            reset() {
                Object.assign(this, {
                    campaignId: this.campaign && this.campaign.contentCampaignId || null,
                    campaigns: [],
                    subject: this.campaign && this.campaign.subject || "What we're reading...",
                    selectedLists: this.campaign && this.campaign.lists || [],
                    fromName: this.campaign && this.campaign.fromName || '',
                    fromAddress: this.campaign && this.campaign.fromAddress || '',
                    replyTo: this.campaign && this.campaign.replyTo || '',
                    accountEmails: [],
                    name: this.campaign && this.campaign.name || this.collection && this.collection.name || '',
                    allLists: []
                });
            },
            campaignSelected(campaign) {
                if (campaign)
                    this.name = campaign && campaign.name
            },
            fetchContactLists() {
                return this.$api.get(`/accounts/${this.currentAccountOverview.id}/integrations/constant_contact/resources/contact-lists`)
            },
            fetchAccountEmails() {
                return this.$api.get(`/accounts/${this.currentAccountOverview.id}/integrations/constant_contact/resources/confirmed-emails?confirm_status=CONFIRMED`)
            },
            loadAccountEmails() {
                return this.fetchAccountEmails().then(resp =>
                    this.accountEmails = resp.data.map(x => x)
                ).catch(probableAuthErrorNotify('get account emails'))
            },
            loadContactLists() {
                return this.fetchContactLists().then(resp =>
                    this.allLists = resp.data.lists.map(x => ({value: x.list_id, description: x.name}))
                ).catch(probableAuthErrorNotify('get contacts'))
            },
            loadServerData() {
                this.loadContactLists()
                this.loadAccountEmails()
            },
            save() {
                this.$emit('save', UpcCCCampaign.fromVm(this))
            },
            cancel() {
                this.reset();
                this.$emit('cancel')
            }
        },
        mounted() {
            this.loadServerData()
        },

    }
</script>
