<template>
    <div class="source-checklist">
        <h4 class="subtitle d-flex">
            Sources
            <a v-if="showClear" href="#" @click.prevent.stop="clearSearch" class="sidebar-link ms-auto">All</a>
        </h4>

        <progress-bar v-if="isLoadingSources"></progress-bar>

        <div v-else>
            <input class="filter-search form-control" placeholder="Filter sources" v-model="sourcesSearch"
                   v-if="showSearchBar"/>

            <p class="filter-item d-flex align-items-start"
               v-for="publisher in pagedSources"
               :key="'publisher-'+publisher.source">
                <label @click.stop>
                    <input
                        @change="update()"
                        type="checkbox"
                        :value="publisher.source"
                        v-model="sources"
                        class="me-1"
                    >
                    <span v-text="$strings.truncate(publisher.source, 30)"/>

                </label>
                <span @click.stop="sources = [publisher.source]; update()"
                      class="ms-auto badge bg-secondary cursor-pointer"
                      :title="`${publisher.count} suggestions for this source. Click to select only this source.`">
                    {{ publisher.count }}</span>

                <span v-click-outside="handleClick" v-if="sourceShowingMessage == publisher" class="popover-box">
                    <a href="#" @click.prevent="removeSource(publisher)">
                        <i class="fa fa-times"></i> Never show me this source again
                    </a>
                </span>
            </p>
            <paginator :extraSmall="true"
                       :small="true"
                       :size="pageSize"
                       :items="filteredSources"
                       @update="pagedSources = $event"/>
        </div>

        <p class="small" v-if="showNoSourcesFound">
            No Sources Found
        </p>
    </div>
</template>

<script>

// @click.right.prevent="sourceShowingMessage = publisher" <-- Use this for the right-click-to-remove functionality

import {mapGetters} from 'vuex';

import Base from './FilterMixin'
import ProgressBar from "@/utilities/ProgressBar"
import Paginator from '@/utilities/Paginator'

export default {
    mixins: [Base],
    components: {ProgressBar, Paginator},
    props: [
        'value',
        'allSources',
        'isLoadingSources'
    ],
    data() {
        return {
            pageSize: 10,
            sources: [],
            excludedSources: [],
            sourceShowingMessage: false,
            sourcesSearch: '',
            pagedSources: []
        }
    },
    watch: {
        value() {
            this.excludedSources = this.value.excludedSources || [];
            this.sources = this.value.sources || this.calculatedSourceList.map(x => x.source) || [];
        },
        filteredSources() {
            this.updateSources()
        },
    },
    computed: {
        ...mapGetters({}),
        showClear() {
            return this.sources && this.allSources && this.sources.length < this.allSources.length
        },
        showNoSourcesFound() {
            return this.filteredSources.length == 0 && !this.sourcesSearch && !this.isLoadingSources
        },
        calculatedSourceList() {
            return this.allSourcesSorted.filter(source => {
                return this.filters && this.filters.excludedSources ?
                    this.excludedSources.indexOf(source.source) == -1 :
                    this.filters && this.filters.sources ? this.value.sources.indexOf(source.source) >= 0 :
                        false
            })
        },

        filteredSources() {
            var sources = this.allSourcesSorted;

            if (this.sourcesSearch) {
                sources = sources.filter(s => s.source.toLowerCase().includes(this.sourcesSearch.toLowerCase()));
            }

            return sources;
        },

        allSourcesSorted() {
            return [...this.allSources].sort((a, b) =>
                // First sort by count DESC, default to 0. Then by source name.
                (b.count || 0) - (a.count || 0)
                || a.source.toUpperCase().localeCompare(b.source.toUpperCase())
            );
        },

        showSearchBar() {
            return (this.allSources || []).length > this.pageSize
        }
    },
    mounted() {
        this.sources = this.calculatedSourceList.map(s => s.source);
    },
    methods: {
        update() {
            let excludedSources = this.allSourcesSorted.map(s => s.source).filter(s => this.sources.indexOf(s) === -1);
            let filters = {...this.value};
            filters.excludedSources = excludedSources;
            filters.sources = this.sources;
            this.$emit('input', filters);
        },
        removeSource(source) {
            console.log('remove ' + source)
            this.sourceShowingMessage = false;
        },
        handleClick() {
            if (this.sourceShowingMessage) {
                this.sourceShowingMessage = false;
            }
        },
        clearSearch() {
            this.sources = this.allSourcesSorted.map(s => s.source);
            this.update();
        },
        updateSources() {
            this.sources = this.calculatedSourceList.map(s => s.source);
        }
    }
}
</script>

<style scoped>
.source-checklist {
    /*margin: 5px 15px;*/
}
</style>
