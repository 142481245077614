<template>
    <div class="pt-4 ps-4 pe-4" id="galleries-page">
        <router-view></router-view>
    </div>
</template>

<style>

</style>

<script>
    import GalleryBuilder from "@/components/Galleries/GalleryBuilder"
    export default {
        components: {GalleryBuilder}
    }
</script>
