import _ from 'lodash'
export const SortBy = {
    alphabetize(array, field){
        if ( ! field) field = 'name';

        return _.sortBy(array,(item) => {
            if (typeof item[field] == 'string')
                return item[field].toLowerCase();

            return item[field];
        })
    },
    date(array, field){
        if ( ! field) field = 'updated';

        return _.sortBy(array, function (dateObj) {
            return new Date(dateObj[field]);
        });
    },
    count(array, field){
        if ( ! field) field = 'count';

        return _.sortBy(array, (item) => {
            return item[field];
        })
    }
}
export default {
    install: (Vue) => {
        Vue.prototype.$sort = SortBy
    }
}
