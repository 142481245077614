<template>
    <div>
        <ul class="has-notes-list" v-if="items.length > 0">
            <li 
                class="has-notes-item" 
                :class="{'active' : ! selectedId}"
                @click="selectedId = false"
            >
                All
            </li>
            <li 
                class="has-notes-item" 
                :class="{'active' : selectedId == item.id}"  
                v-for="item in pagedItems" 
                :key="item.id + '-item-display'"
                @click="selectedId = item.id"
            >
                <span v-text="$strings.truncate(item.title, 80)" />
                <br/>
                <small class="text-muted">{{item.notes.length}}</small>
            </li>
        </ul>
        <div v-else>
            <div v-if="isDownloadingNotes" class="d-flex">
                <spinner class="me-2" />
                <p class="small muted">Downloading notes ...</p>
            </div>
            <p v-else class="small muted">No notes in this {{hasSuggestionsType}}</p>
        </div>
        <paginator name="GroupPager" :small="true" :items="items" @update="pagedItems = $event" />
    </div>
</template>

<script>
import Spinner from '../../../Controls/Spinner.vue';
import BaseMixin from './Base'

export default {
    mixins : [BaseMixin],
    components: {Spinner},
    computed : {
        
        items(){
            return this.suggestions.filter(sug => {
                // NOTE(casio): during note fetching refactor, below code was raising with undefined `this.hasSuggestions`
                if(!this.hasSuggestions) return false;
                let isInHasSuggestions = sug.collection && sug.collection.includes(this.hasSuggestions.id) || sug.topic && sug.topic.includes(this.hasSuggestions.id);
                return isInHasSuggestions;
            }).map(sug => {
                let notes = this.notes.filter(note => {
                    return note.suggestion && note.suggestion.includes(sug.id);
                })
                
                return {...sug, name : sug.title, notes }
            })
            .filter(suggestionWithNotes => suggestionWithNotes.notes.length > 0)
        },
    }
}
</script>
