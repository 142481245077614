<template>
<svg    xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-miterlimit:1"
        viewBox="0 0 50 50">
    <g class="singular-image">
        <path   d="M44.3 6.6c0-3.2-.4-3.6-3.6-3.6H9.3c-3.2 0-3.6.4-3.6 3.6v36.8c0 3.2.4 3.6 3.6 3.6h31.4c3.2 0 3.5-.4 3.5-3.6V6.6ZM43 37.9c0-2-1.7-3.6-3.7-3.6h-4.8c-2 0-3.6 1.6-3.6 3.6v4.9c0 2 1.6 3.6 3.6 3.6h4.8c2 0 3.7-1.6 3.7-3.6v-4.9ZM19 38c0-2-1.6-3.6-3.6-3.6h-4.8c-2 0-3.7 1.6-3.7 3.6v4.9c0 2 1.7 3.6 3.7 3.6h4.8c2 0 3.6-1.6 3.6-3.6V38Zm12 0c0-2-1.6-3.6-3.6-3.6h-4.8c-2 0-3.6 1.6-3.6 3.6v4.9c0 2 1.6 3.6 3.6 3.6h4.8c2 0 3.6-1.6 3.6-3.6V38ZM19 23.4c0-2-1.6-3.6-3.6-3.6h-4.8c-2 0-3.7 1.6-3.7 3.6v4.9c0 2 1.7 3.6 3.7 3.6h4.8c2 0 3.6-1.6 3.6-3.6v-4.9Zm24 0c0-2-1.6-3.6-3.6-3.6h-4.8c-2 0-3.6 1.6-3.6 3.6v4.9c0 2 1.6 3.6 3.6 3.6h4.8c2 0 3.7-1.6 3.7-3.6v-4.9Zm-12 0c0-2-1.6-3.6-3.6-3.6h-4.8c-2 0-3.6 1.6-3.6 3.6v4.9c0 2 1.6 3.6 3.6 3.6h4.8c2 0 3.6-1.6 3.6-3.6v-4.9ZM43 9c0-2-1.6-3.6-3.6-3.6h-4.8C32.6 5.4 31 7 31 9v4.9c0 2 1.6 3.6 3.6 3.6h4.8c2 0 3.7-1.6 3.7-3.6v-5ZM19 9c0-2-1.6-3.6-3.6-3.6h-4.8C8.6 5.4 6.9 7 6.9 9v4.9c0 2 1.7 3.6 3.7 3.6h4.8c2 0 3.6-1.6 3.6-3.6v-5Zm12 0c0-2-1.6-3.6-3.6-3.6h-4.8C20.6 5.4 19 7 19 9v4.9c0 2 1.6 3.6 3.6 3.6h4.8c2 0 3.6-1.6 3.6-3.6v-5Z"
                style="stroke-width:4px"
                class="path-fill path-stroke" fill="currentColor" stroke="currentColor"/>
    </g>
    <g class="multiple-image">
        <path   d="M45 13c0-2.7-.3-3-3-3H15.8c-2.7 0-3 .3-3 3v32c0 2.7.3 3 3 3H42c2.7 0 3-.3 3-3V13Zm-1 27.2a3 3 0 0 0-3-3.2h-4a3 3 0 0 0-3 3.2v4.1a3 3 0 0 0 3 3.2h4a3 3 0 0 0 3-3.2v-4.1Zm-20.1 0a3 3 0 0 0-3-3.2h-4a3 3 0 0 0-3 3.2v4.1a3 3 0 0 0 3 3.2h4a3 3 0 0 0 3-3.2v-4.1Zm10 0a3 3 0 0 0-3-3.2h-4a3 3 0 0 0-3 3.2v4.1a3 3 0 0 0 3 3.2h4a3 3 0 0 0 3-3.2v-4.1ZM24 27.6a3 3 0 0 0-3-3.1h-4a3 3 0 0 0-3 3.1v4.2a3 3 0 0 0 3 3.1h4a3 3 0 0 0 3-3v-4.3Zm20.1 0a3 3 0 0 0-3-3.1h-4a3 3 0 0 0-3 3.1v4.2a3 3 0 0 0 3 3.1h4a3 3 0 0 0 3-3v-4.3Zm-10 0a3 3 0 0 0-3-3.1H27a3 3 0 0 0-3 3.1v4.2a3 3 0 0 0 3 3.1h4a3 3 0 0 0 3-3v-4.3Zm10-12.5a3 3 0 0 0-3-3.1h-4a3 3 0 0 0-3 3v4.3a3 3 0 0 0 3 3.1h4a3 3 0 0 0 3-3.1V15Zm-20.1 0a3 3 0 0 0-3-3.1h-4a3 3 0 0 0-3 3v4.3a3 3 0 0 0 3 3.1h4a3 3 0 0 0 3-3.1V15Zm10 0a3 3 0 0 0-3-3.1h-4a3 3 0 0 0-3 3v4.3a3 3 0 0 0 3 3.1h4a3 3 0 0 0 3-3.1V15Z"
                style="stroke-width:4px"
                class="path-fill path-stroke" fill="currentColor" stroke="currentColor"                                                               />
        <path   d="M8.6 42.6v-.1l-.1-.5V9.5c0-.9.2-1.5.5-2 .6-.7 1.6-.9 3-.9h24.8c.6 0 1.1 0 1.4.2.2.2.2.5.3.8l.1 1.9h2.7c0-2.2-.4-3.7-1.3-4.6-.6-.6-1.6-1-3.2-1H11.9c-2.2 0-4 .7-5 2-.6.8-1 2-1 3.6V42c0 1 .1 1.6.5 2.1.3.6.9 1 1.7 1.1.7.2 1.7.3 3.2.3v-2.7H9a4 4 0 0 1-.5-.2Z"
                class="path-fill" fill="currentColor"/>
        <path   d="M36.3 5c0-2-.4-3.2-1-3.9a3 3 0 0 0-1.7-1c-.7-.2-1.6-.2-2.7-.2L7.8 0c-2.3 0-4 .7-5 2-.7.8-1.1 2-1.1 3.5V35c0 2 .3 3.2 1 4 .6.8 1.7 1.2 3.9 1.2v-2.6l-1.4-.1-.6-.3-.2-.5V5.5c0-.8.1-1.4.5-1.9.6-.7 1.6-.9 2.9-.9h24.7l.8.1c.3.4.3 1 .3 2.1h2.7Z"
                class="path-fill" fill="currentColor"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "CollectionsSvg",
}
</script>
<style lang="scss">
.singular .multiple-image, .multiple .singular-image  {
    display: none;
}
.multiple .multiple-image, .singular .singular-image {
    display: block;
}
.light-variation {
}
</style>
<style lang="scss" scoped>
</style>
