
<script>
    import {iframeUrl} from "@/services/Gallery";
    const env = process.env;
    let qa = env['VUE_APP_API_BASE_URL'].includes('qa');
    export default {
        computed: {
        },
        methods: {
            formatCollectionsDisplay(g){
                let collections = g.collections || []
                let collectionListDisplay = collections.map(x => x.name).join(', ');
                return collectionListDisplay.length < 100 ? collectionListDisplay : `${collections.length} collections`
            },
            iframeUrl(gallery) {
                return iframeUrl({
                    themeIds: [gallery.carousel_theme_id, gallery.card_theme_id],
                    galleryId: gallery.id,
                    styleUrls: [
                        `https://galleries${qa?'.qa':''}.upcontent.com/themes/${gallery.carousel_theme_id}/style.css`,
                        `https://galleries${qa?'.qa':''}.upcontent.com/themes/${gallery.card_theme_id}/style.css`
                    ],
                    stylesheet:'',
                    rootElement: gallery.type === 'card' ? 'upcontent-suggestion-list' : 'upcontent-gallery'
                })
            },
        }
    }
</script>
