import pluralize from 'pluralize'
import capitalize from 'capitalize'

export default {
    install: (Vue) => {
        Vue.prototype.$strings = StringUtils;
    }
}

export const StringUtils = {
    truncate(text, size) {
        if (!size) size = 50
        if (!text) return;

        if (text.length > size) {
            text = text.substring(0, size) + "…"
        }
        return text
    },
    plural(string, count) {
        return pluralize(string, count);
    },
    capitalize(string) {
        return capitalize(string);
    },
    capitalizeWords(string) {
        return string.split(" ").map(w => capitalize(w)).join(" ");
    },
    replaceAll(target, search, replacement) {
        return target.replace(new RegExp(search, 'g'), replacement);
    },
    generateId(string = '', maxLength = 32, stamp = '') {

        // Ensure stamp will always have a value, otherwise fallback.
        stamp = stamp !== '' ? stamp : Date.now() + '';

        // Clean the provided string, otherwise fallback.
        let result = string == '' ? '' : string
            .substring(0, maxLength);

        result = this.shishKabob(result);

        // Handle an empty result by returning just a timestamp.
        return result.length > 0 ? result + '-' + stamp : stamp;

    },
    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    },
    shishKabob(string, maxLength = 0) {
        let result = string.replace(/[^a-zA-Z\d- ]/g, '')
            .replace(/\s\s+/g,' ')
            .replace(/ /g, '-')
            .replace(/-+/g, '-')
            .toLowerCase();

        if(maxLength > 0) {
            result = result.substring(0, maxLength)
        }

        return result;
    }
}
