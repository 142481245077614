<template>
    <div class="padding-0 m-0 font-size-14 force-inherit">
        <div class="row">
            <div class="col-12 col-md">
                <div class="mb-2">
                    <div class="row">
                        <label for="filters-form-name"
                               class="col-12 col-sm-4 align-self-end top-3">Filter&nbsp;Name</label>
                        <div class="col-12 col-sm">
                            <input id="filters-form-name" type="text" v-model="name" required
                                   class="form-control form-control-sm"/>
                        </div>
                    </div>
                </div>

                <content-filter class="mb-2"
                                :value="filters"
                                @input="update"
                                v-if="languageSupportsContentType"></content-filter>

                <sort-filter class="mb-2" :value="filters" @input="update" :type="type"></sort-filter>

                <age-filter class="mb-2" :value="filters" @input="update" :type="type"></age-filter>

                <div class="">
                    <div class="row  mb-2">
                        <label for="filters-form-limit" class="col-12 col-sm-4 align-self-end top-3">Limit</label>
                        <div class="col-12 col-sm">
                            <input type="number" id="filters-form-limit" v-model="limit" required min="1"
                                   max="100"
                                   class="form-control form-control-sm em-width-5"/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="filters-form-offset" class="col-12 col-sm-4 align-self-end top-3">Offset</label>
                        <div class="col-12 col-sm">
                            <input type="number" id="filters-form-offset" v-model="offset" min="1" max="100"
                                   class="form-control form-control-sm"/>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-md row-margin-8">
                <sources-filter :value="filters" @input="update" :type="type"
                                :filter-view-id="filters && filters.id"
                                :has-content-id="hasContentId"></sources-filter>
                <fieldset>
                    <!--<p class="filter-item">-->
                    <!--<label><input type="checkbox" @click="toggleFavorites" :value="filters.favorites"> Favorites Only</label>-->
                    <!--</p>-->
                    <!--<p class="filter-item">-->
                    <!--<label><input type="checkbox" v-model="required" :value="'image'"> Has Image</label>-->
                    <!--</p>-->
                    <tags :value="filters"
                          @input="update"
                          :has-content-id="hasContentId"
                          :has-content-type="type"></tags>
                </fieldset>

                <predicted-performance :value="filters"
                                       @input="update"
                                       :has-content-id="hasContentId"
                                       :has-content-type="type"
                                        v-if="accountFeatures.predictedPerformance"
                />
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.em-width-5 {
    width: 5em !important;
}
</style>
<script>
import {mapGetters} from 'vuex'

import SourcesFilter from './SuggestionFiltersUnbound/SourcesFilter'
import GeographyFilter from './SuggestionFiltersUnbound/Geography'
import AgeFilter from "./SuggestionFiltersUnbound/Age"
import SortFilter from './SuggestionFiltersUnbound/Sort'
import ContentFilter from './SuggestionFiltersUnbound/Content'
import Tags from "@/components/FilteringAndSorting/SuggestionFiltersUnbound/Tags";
import {languagesSupportingContentType} from "@/Constants";
import PredictedPerformance
    from "@/components/FilteringAndSorting/SuggestionFiltersUnbound/predictedPerformance";

// {
//     id: str,
//     type: str,
//     sort: str,
//     age: str,
//     limit: int,
//     offset: int,
//     favorited: bool,
//     undated: bool,
//     excludes: list,
//     required: list,
//     sources: list,
// }

export default {
    props: {
        value: Object,
        type: {type: String, default: null},
        hasContentId: {type: String, default: null}
    },
    // mixins: [RssFilterBase],
    components: {
        PredictedPerformance,
        Tags,
        SourcesFilter, GeographyFilter, AgeFilter, SortFilter, ContentFilter
    },
    computed: {
        ...mapGetters({
            features: 'features',
            topics: 'topics',
            accountFeatures: 'accountFeatures',
        }),
        excludedSources() {
            return this.filters && this.filters.excludedSources || [];
        },
        languageSupportsContentType() {
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },
        topicLanguage() {
            if (!this.hasSuggestions || this.hasSuggestions.type !== 'topic') return null;
            let culture = this.topics.find(x => x.id === this.hasSuggestions.id).culture;
            return Boolean(culture) && culture.split('-')[0]
        },
        isTopic() {
            return this.type === 'topic'
        }
    },
    methods: {
        update(value) {
            // console.log('FiltersForm.update', value)
            this.filters = value;
            this.updateOutside();
        },
        updateOutside() {
            // console.log('FiltersForm.updateOutside')
            let value = {
                ...this.filters,
                offset: this.offset,
                name: this.name,
                limit: this.limit,
            }
            if (JSON.stringify(this.value) !== JSON.stringify(value))
                this.$emit('input', value)
        }
    },
    data() {
        return {
            filters: this.value,
            // required: this.value && this.value.required || [],
            limit: this.value && this.value.limit || 100,
            offset: this.value && this.value.offset || null,
            name: this.value && this.value.name || null,
        }
    },
    mounted() {
    },
    watch: {
        value() {
            this.filters = this.value;
            this.limit = this.value && this.value.limit || 100;
            this.offset = this.value && this.value.offset || null;
            this.name = this.value && this.value.name || null;
        },
        name() {
            this.updateOutside()
        },
        offset() {
            this.updateOutside()
        },
        limit() {
            this.updateOutside()
        },
    },
}
</script>
