<template>
    <div class="suggestions-container">
        <h4>Oh no! Looks like you've entered a value that will result in a bad topic query.</h4>

        <p>The value "{{string}}" isn't going to play nice with our search engine. We recommend something more like this:</p>
        <ul>
            <li v-for="word in recommendations" :key="id+word">
                <label><input type="checkbox" v-model="selectedTerms" :value="word"/> {{word}}</label>
            </li>
        </ul>

        <button @click="$emit('useSuggestions', ({string, recommendations: selectedTerms}))" class="btn btn-success">Use these recommendations</button>

        <button @click="$emit('cancel')" class="btn btn-link btn-sm mt-3">No, I want to use what I wrote</button>
    </div>
</template>

<script>

import uuid from 'uuid'
import nlp from 'compromise'
import commonWords from './NLP/common-words'
import _ from 'lodash'

export default {
    props : ['string'],
    data(){
        return {
            id : uuid.v4(),
            selectedTerms : []
        }
    },
    computed : {
        recommendations(){
            return this.nouns.concat(this.verbs);
        },
        nouns(){
        return _.uniq(nlp(this.string).nouns().slice(0, 50).out('frequency')
                    .map(e => this.scrubbed(e.normal)
                ).filter(this.notInCommonWords))
        },
        verbs(){
        return _.uniq(nlp(this.string).verbs().slice(0, 50).out('frequency')
                    .map(e => this.scrubbed(e.normal)
                ).filter(this.notInCommonWords))
        },
        topics(){
        return _.uniq(nlp(this.string).topics().slice(0, 50).out('frequency')
                    .map(e => this.scrubbed(e.normal)
                ).filter(this.notInCommonWords))
        }
    },
    mounted(){
        this.selectedTerms = this.recommendations;
    },
    methods: {
        scrubbed(string){
            let regex = new RegExp('[^a-zA-Z0-9 -]', "g")
            let result = string.replace(regex, ' ');
            return result.trim();
        },
        notInCommonWords(word){
            return commonWords.indexOf(word) == -1;
        }
    }
}
</script>

<style scoped>
ul, li {
    list-style: none;
}
.suggestions-container {
    background: rgba(250, 254, 255, 1);
    padding: 1em;
    border: 1px solid rgba(230, 224, 245, 1);
    border-radius: 0.2rem;
}
</style>