<template>
    <div class="position-relative">
        <div class="collapse d-flex flex-wrap justify-content-between">
            <!-- summary class="d-none d-block-sm">Filters</summary> -->
            <div class="input-group btn-group font-size-12  flex-nowrap">
                <filter-view-editable-select  class="flex-grow filter-dropdown"  v-if="hasSuggestions.type"/>
                <dropdown class="">
                    <template #toggle>
                        <button type="button" class="btn btn-secondary dropdown-toggle" @click="showEdit" >
                            Edit Filters
                        </button>
                    </template>
                    <template #dropdown>
                        <filter-edit  class="flex-grow pb-1 filters-bar" v-if="filters" />
                    </template>
                </dropdown>
            </div>
        </div>
        <div v-if="loadingFilterBar" class="position-absolute top-0 bottom-0 start-0 end-0 bg-white text-center opacity-50">
            <spinner></spinner>
        </div>
    </div>
</template>
<style scoped>

#filtersSectionHorizontal {
    margin-left: -1em;
    margin-right: -1em;
}
.filter-dropdown {
    min-width: 15em;
    max-width: 900px;
    margin-left: 1em;
    margin-right: 1em;
    width: 20em;
}
.filters-bar{
    margin-left: 1em;
    margin-right: 1em;
}
#filtersSectionHorizontal.collapse.d-flex {
    display: none !important;
}
#filtersSectionHorizontal.collapse.show.d-flex {
    display: flex !important;
}
@media screen and (min-width: 768px) {
    #filtersSectionHorizontal.collapse.d-flex {
        display: flex !important;
    }
}
</style>
<style>
</style>
<script>
import { mapGetters } from 'vuex';
import {languagesSupportingContentType} from "@/Constants";
import FilterViewEditableSelect from "@/components/FilteringAndSorting/V2/FilterViewEditableSelect";
import FilterEdit from "@/components/FilteringAndSorting/V2/FilterEdit";
import Spinner from "../../Controls/Spinner";
import NestableModal from "../../Controls/NestableModal";
import Dropdown from "../../Controls/Dropdown";

export default {
    components : {
        Dropdown,
        NestableModal,
        Spinner,
        FilterEdit,
        FilterViewEditableSelect,
    },
    data(){
        return {
            active : false
        }
    },
    computed : {
        ...mapGetters({
            features: 'features',
            hasSuggestions: 'hasSuggestions',
            currentTopic: 'currentTopic',
            filters : 'currentFilters',
            loadingFilterBar: 'loadingFilterBar'
        }),
        languageSupportsContentType() {
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },
    },
    methods : {
        showEdit() {
            window.Events.$emit('show_editFilters')
        }
    }
}
</script>
