
import {SortBy} from "../../utilities/Sort";
import {currentFilterVersion} from "../../Constants";

export function migrateFilters(filters, {publishers}) {
    /* Migrate filters if the data format changes, usual major versions of the filter functionality */
    const mostRecentVersion = currentFilterVersion;
    let version = filters.version;
    let needsUpgrade = !version || version < mostRecentVersion; // We'll need to change something

    if (!version) {
        let sourcesJson = JSON.stringify(SortBy.alphabetize(filters.sources));
        let excludesJson = JSON.stringify(SortBy.alphabetize(filters.excludedSources));

        // For some reason post-filter views pre-actions-data-loading refactore
        // saved excludedSources wrong which was reason for 1 to 2 migration
        // but we also didn't realize we needed migrations initially so v2 didn't have a version set initially
        // so we key off of the bad data itself to tell whether v1 or v2
        // pre filter views just saved as excluded

        console.log('migrateFilters.version', {zero: filters.sources === undefined, one:sourcesJson === excludesJson, sourcesJson, excludesJson, filters })
        version =
            filters.sources === undefined ? 0 :
            sourcesJson === excludesJson ? 1 : 2
    }


    // if we don't have publishers we won't be able to do the v1->v2 migration so defer to parent and ask for publishers
    let awaitPublishers = version <= 1 && !publishers;
    console.log('migrateFilters.main', {needsUpgrade, version, awaitPublishers})
    if (awaitPublishers) return { filters, awaitPublishers }

    // We're going to be doing some migrations so make a copy and set the version
    if (needsUpgrade) filters = { ...filters, version: mostRecentVersion };

    // Do migrations for each version one at a time
    if (version === 0) migrateVersion0to2(filters, publishers)
    if (version === 1) migrateVersion1to2(filters, publishers)
    // for future migrations do like this
    // if (version < 3) migrateVersion2to3(filters, ...)
    // if (version < 4) migrateVersion3to4(filters, ...)

    console.log('migrateFilters.return', {filters, awaitPublishers})
    return {filters, awaitPublishers}
}

export function migrateVersion0to2(filters, publishers){
    console.log('migrateVersion0to2')
    filters.includeNewSources = true;
    let allSources = publishers.map(x => x.source);
    if (!filters.excludedSources) {
        filters.sources = allSources;
    } else {
        filters.sources = allSources.filter(x => !filters.excludedSources.includes(x));
    }
}
export function migrateVersion1to2(filters, publishers) {
    console.log('migrateVersion1to2', filters, publishers)
    // OG filters module saved excludedSources and sources both with the value that was supposed to be in sources
    if (filters.sources && filters.sources.length)
        filters.excludedSources = publishers.map(x => x.source).filter(x => !filters.sources.includes(x));
    else
        filters.excludedSources = [];

    if (filters.sources && !filters.sources.length)
        filters.sources = null;
}
