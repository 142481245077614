<template>
    <nestable-modal v-bind="{alert, size, canClose, noFooter, noHeader}" :modalName="modalName" ref="modal">
        <template v-slot:header>
            <slot name="header"/>
        </template>
        <div>
            <async-loader :show="open" :component="component" :props="properties" :render-key="key">
                <slot/>
            </async-loader>
        </div>
        <template v-slot:footer>
            <slot name="footer"/>
        </template>
    </nestable-modal>
</template>

<script>
import NestableModal from "./NestableModal";
import AsyncLoader from "./AsyncLoader";

export default {
    name: "AsyncModal",
    components: {AsyncLoader, NestableModal},
    props: {
        component: {type: Function},
        props: {
            type: Object, default: () => {
            }
        },
        modalName: {type: String, required: true},
        alert: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        },
        canClose: {
            type: Boolean,
            default: true
        },
        remountOnShow: {
            type: Boolean,
            default: true
        },
        noFooter: {
            type: Boolean,
            default: false
        },
        noHeader: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        open() {
            return this.loaded && (this.$refs.modal.state !== 'closed')
        }
    },
    data() {
        return {
            properties: this.props,
            key: 0,
            loaded: false
        }
    },
    watch: {
        props() {
            this.properties = this.props
        }
    },
    mounted() {
        this.loaded = true;
        window.Events.$on('show_' + this.modalName, (props) => {
            console.log('show_' + this.modalName, props)
            if (props && JSON.stringify(props) !== JSON.stringify(this.properties))
                this.properties = props

            if (this.remountOnShow) {
                this.key += 1;
            }
        })
    }
}
</script>

<style scoped>

</style>
