<template>
    <div
        type="button"
        class="card powerup-card">
        <div class="card-header">
            <i :class="iconClass" class="me-2"/>{{title}}
            <div class="font-size-11 card-tags m-0 p-0 float-end">
                <div v-if="active && isAuthenticated"
                     class="tag small bg-success text-white ms-0">
                    <i class="fa fa-circle"/> Active
                </div>
                <div v-else-if="isAuthenticated"
                     class="tag small bg-default text-dark ms-0">
                    <i class="fa fa-circle-o"/> Available
                </div>
                <div v-else-if="!isAuthenticated" class="tag small bg-default text-water-25 ms-0">
                    <i class="fa fa-unlock"/> Not Authenticated
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="font-size-11 text-muted">{{description}}</div>
            <a :href="learnUrl" target="_blank" rel="noopener" @click.stop class="ui-link font-size-12 d-block">Learn More</a>
        </div>
        <div class="card-footer">
            <div>
                <button v-if="active || (isAuthenticated && !usesToggle)"
                        @click="$emit('change')"
                        class="btn btn-primary full-width btn-sm ">
                    <i class="fa fa-edit"></i> Edit Settings
                </button>
                <button v-else-if="isAuthenticated && canTurnOnOff && usesToggle" @click="active=true" class="btn btn-primary full-width btn-sm">
                    <i class="fa fa-plug"/> Activate for Collection
                </button>
                <connect-disconnect-button v-else-if="!isAuthenticated" :service="service" :team="currentAccountOverview" :beforeAuth="() => {}" class="full-width"></connect-disconnect-button>

            </div>
        </div>
    </div>
</template>

<script>
import {getIconClass} from "../../../services/Icons";
import {mapGetters} from "vuex";
import PowerupAuth from "./PowerupAuth";
import PowerupMeta from "./PowerupMeta";
import ConnectDisconnectButton from "../../Settings/Team/ConnectDisconnectButton";
import ToggleSwitch from '../../Controls/ToggleSwitch'

export default {
    name: "PowerupListItem",
    components: {ToggleSwitch, ConnectDisconnectButton},
    mixins: [PowerupAuth, PowerupMeta],
    props: {
        title: String,
        name: String,
        isAuthenticated: Boolean,
        active: {},
    },
    data() {
        return {
            canTurnOnOff: true,
        }
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
        })
    }
}
</script>

<style scoped>
    /*.powerup-card {*/
    /*    min-width: 180px;*/
    /*    width: 270px;*/
    /*    max-width: 270px;*/
    /*    margin-right :1em;*/
    /*    margin-bottom: 1em;*/
    /*    flex: 1;*/
    /*}*/
</style>
