<template>
    <div class="suggestion-props full-width">
        <div class="full-width d-flex font-size-12 position-relative">
            <div class="me-2"
                 v-if="canManageCollection && suggestionViewerType === 'collections'">
                <button class="btn btn-link d-flex align-items-center ui-link font-size-12 p-0 m-0"
                   @click.prevent="showEditCollectionModal">
                    <i class="fa fa-edit me-1" />
                    <span>Edit<span class="large-sidebar-only"> Collection</span></span>
                </button>
            </div>

            <div v-if="suggestionViewerType === 'topics'" class="me-2">
                <button type="button" class="btn btn-link ui-link p-0 m-0 font-size-12" @click="editTopic">
                    <i class="fa fa-edit me-1" v-if="canManageTopic"/>
                    <span>Edit<span class="large-sidebar-only"> Topic</span></span>
                </button>
            </div>

            <suggestion-manager-notes-link class="ms-2 me-2"/>

            <div v-if="!hideFeedLink">
                <button  class="nav-link btn btn-link p-0 ms-2 font-size-12" href="#" role="button"
                         v-if="suggestionViewerType === 'collections' || features.topicRss"
                         title="RSS Feed" @click.prevent="showRssFeedModal">
                    <i class="fa fa-link me-1 bottom-1"></i>
                    <span>Feed</span>
                </button>
            </div>
        </div>
        <div class="mt-3 mb-3" v-if="suggestionViewerType === 'collections'">
            <div class="full-width d-flex justify-content-between align-items-center mb-2">
                <h4 class="subtitle font-size-15 fw-normal m-0">Sources</h4>
                <button class="ui-link manage-ui-link font-size-12" aria-label="Manage Collection Sources"
                        v-if="canManageCollection" @click.prevent="showEditCollectionSourcesModal">
                    Manage
                </button>
            </div>
            <div
                class="suggestion-sources d-xl-flex justify-content-between align-items-center position-relative font-size-12">
                <suggestion-manager-sources/>
            </div>
        </div>
        <div v-if="shouldShowPowerups">
            <div class="full-width d-flex justify-content-between align-items-center mb-1">
                <h4 class="subtitle font-size-15 fw-normal m-0">Powerups</h4>
                <button class="ui-link manage-ui-link font-size-12" aria-label="Manage Powerups"
                        v-if="canManageCollection" @click.prevent="showEditCollectionPowerupsModal">
                    Manage
                </button>
            </div>
            <power-ups-panel-simplified/>
        </div>
        <div v-if="suggestionViewerType === 'collections' && features.contentPolicyEnabled">
            <div class="full-width d-flex justify-content-between align-items-center mb-2">
                <h4 class="subtitle font-size-15 fw-normal m-0">Policy</h4>
                <button class="ui-link manage-ui-link font-size-12" aria-label="Manage Policy"
                        v-if="canManageCollection" @click.prevent="showCollectionPolicy">
                    Manage
                </button>
            </div>
        </div>
        <div v-if="suggestionViewerType === 'collections' && features.contentPolicy && !features.contentPolicyEnabled">
            <div class="full-width d-flex justify-content-between align-items-center mb-1">
                <h4 class="subtitle font-size-15 fw-normal m-0">Advanced</h4>
                <button class="ui-link manage-ui-link font-size-12" aria-label="Manage Policy"
                        v-if="canManageCollection" @click.prevent="showCollectionAdvSettings">
                    Manage
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
// Do not scope the following style with "scoped=true" or it won't work.
#suggestion-manager, #suggestion-manager-card {


    .suggestion-props {
        padding-bottom: .15em;
    }

    .suggestion-props {

        .nav-icon {
            .singular {
                visibility: hidden;
            }

            .multiple {
                visibility: visible;
            }
        }

        .nav-icon {
            //font-size: 1rem;
            margin-right: .25em;
        }

        svg.nav-icon {
            width: 1em;
            height: 1em;
            left: 1px;
        }
    }
}
</style>

<script>
import {mapGetters} from "vuex";
import SuggestionManagerNotesLink from "@/components/Suggestions/ManageSuggestion/SuggestionManagerNotesLink";
import PowerUpsPanelSimplified from "@/components/Collections/V2/PowerUpsPanelSimplified";
import SuggestionManagerSources from "@/components/Suggestions/ManageSuggestion/SuggestionManagerSources";

export default {
    name: "SuggestionManagerProps",
    data() {
        return {}
    },
    components: {
        SuggestionManagerSources,
        PowerUpsPanelSimplified,
        SuggestionManagerNotesLink,
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            topic: 'currentTopic',
            hasSuggestions: 'hasSuggestions',
            canManageCollection: 'canManageCollection',
            canManageTopic: 'canManageTopic',
            collectionSources: 'collectionSources',
            powerups: 'collectionPowerups',
            features: 'accountFeatures',
            suggestionViewerType: 'suggestionViewerType',
            hideIntegrations: "hideIntegrations",
            hideFeedLink: "hideFeedLink",
        }),
        isCollection() {
            return this.suggestionViewerType === 'collections';
        },
        shouldShowPowerups() {

            return this.isCollection && !this.hideIntegrations;
        }
    },
    watch: {},
    mounted() {

    },
    methods: {
        showEditCollectionModal() {
            window.Events.$emit('show_editCollectionModal', {onlyShow: null, collection: this.currentCollection});
        },
        showCollectionPolicy() {
            console.log('* show collection policy')
            window.Events.$emit('show_collectionPolicy', {collectionId: this.currentCollection.id})
        },
        showCollectionAdvSettings() {
            window.Events.$emit('show_collectionAdvSettings', {collectionId: this.currentCollection.id})
        },
        showEditCollectionSourcesModal() {
            window.Events.$emit('show_editCollectionSourcesModal', {onlyShow: null});
        },
        showEditCollectionPowerupsModal() {
            if (this.features.newPowerupsUx)
                window.Events.$emit('show_powerupsV3', {});
            else if (this.features.newPowerupsUxCards)
                window.Events.$emit('show_powerupsV4', {});
            else
                window.Events.$emit('show_editCollectionPowerupsModal', {});

        },
        showRssFeedModal() {
            window.Events.$emit('show_rssFeedModal', {});
        },
        editTopic() {
            window.Events.$emit('showQueryBuilderModal', {topic: this.topic, mode: 'advanced'});
        },
    },

}
</script>

