<template>
    <div class="padding-container">
       <div class="padding-border-box">
           <input class="padding-top-input form-control form-control-sm" type="number" v-model="top"/>
           <div class="padding-horizontal">
               <input class="padding-left-input form-control form-control-sm" type="number" v-model="left"/>
               <div class="pad-link-container">
                   <hr>
                   <icon-checkbox v-model="linkHorizontal" icon-class="fa-link"></icon-checkbox>
                   <hr>
               </div>
               <input class="padding-right-input form-control form-control-sm" :readonly="linkHorizontal" type="number" v-model="right"/>
           </div>
           <input class="padding-bottom-input form-control form-control-sm" type="number" v-model="bottom"/>
           </div>
    </div>
</template>

<style scoped>

    input {
        text-align: center !important;
        font-size: .75rem;
    }
    .padding-container {
        margin: 20px 35px;
    }
    .pad-link-container {
        flex-grow: 1;
        display: flex;
        vertical-align: center;
        margin: auto 0;
    }
    .pad-link-container hr {
         flex-grow: 1;
        margin: auto 0;
     }
    .padding-border-box {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: visible;
        border: 1px solid var(--bs-default);
        text-align: center;
        z-index: 4;
    }
    .padding-top-input {
        display: block;
        margin-top: -1.3em;
        margin-left: auto;
        margin-right: auto;
        width: 100px !important;;
    }
    .padding-bottom-input {
        display: block;
        margin-bottom: -1.3em;
        margin-left: auto;
        margin-right: auto;
        width: 100px !important;
        z-index: 3;
    }
    .padding-left-input {
        display: block;
        margin-right: auto;
        width: 70px !important;;
    }
    .padding-horizontal {
        display: flex;
        justify-content: space-between;
        margin: 0 -35px 0 -35px;
    }
    .padding-right-input {
        display: block;
        margin-left: auto;
        width: 70px !important;;
    }
</style>

<script>
    import IconCheckbox from "@/components/Controls/IconCheckbox";
    const defaultVals = {
        top: 1,
        bottom: 1,
        right: 1,
        left: 1,
    };
    export default {
        name: 'padding',
        components: {IconCheckbox},
        props: { value: {}},
        data() {
            return {
                ...defaultVals,
                changing: false,
                linkHorizontal: true,
                ...(this.value||{}),
            }
        },
        watch: {
            top() {
                let value = this.value || {};
                if (this.top !== value.top && !this.changing)
                    this.$emit('input', {...defaultVals,...value, top: this.top})
            },
            left() {
                let value = this.value || {};
                if (this.left !== value.left && !this.changing)
                    this.$emit('input', {...defaultVals, ...value, left: this.left, ...(this.linkHorizontal ? { right: this.left} : {})});
            },
            right() {
                let value = this.value || {};
                if (this.right !== value.right && !this.changing)
                    this.$emit('input', {...defaultVals, ...value, right: this.right})
            },
            bottom() {
                let value = this.value || {};
                if (this.bottom !== value.bottom && !this.changing)
                    this.$emit('input', {...defaultVals, ...value, bottom: this.bottom})
            },
            linkHorizontal() {
                if (this.linkHorizontal && !this.changing)
                    this.right = this.left;

            },
            value() {
                if (!this.value) {
                    this.changing = true;
                    Object.assign(this, {...defaultVals});
                    this.$emit('input', {...defaultVals});
                    this.linkHorizontal = this.left === this.right;
                    this.changing = false;
                } else {
                    this.changing = true;
                    Object.assign(this, {...defaultVals, ...this.value});
                    this.linkHorizontal = this.left === this.right;
                    this.changing = false;
                }
            }
        }
    }
</script>
