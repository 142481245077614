import * as types from '../mutation-types'

const state = {
    shareProviders: {},
    apisMeta: {},
    apis : {
        amplify : {},
        sniply: {},
        lately: {},
        hubspot: {},
        proofpoint: {},
        shutterstock: {},
        mailchimp: {},
        bee: {},
    },
    oauthActivity: {
        sniply: {
            networkActivity: false,
            networkError: false
        },
        lately: {
            networkActivity: false,
            networkError: false
        },
        hubspot: {
            networkActivity: false,
            networkError: false
        }
    },
    afterOauthData: false
}

function fetchAuth(accountId, apiName) {
    return window.$app.api.get(`/accounts/${accountId}/integrations/${apiName}/settings`).then(resp => resp.data)
}

const actions = {
    async loadIntegrationAuthCurrentAccount({dispatch, getters}, {apiName}) {
        let data = await fetchAuth(getters.currentAccountOverview.id);
        await dispatch('setPowerupDataForAccount', {data, account: getters.currentAccountOverview.id, powerup: apiName})
    },
    setIntegrationMeta: ({commit}, { integration }) => {
        commit('SET_INTEGRATION_META',{ integration } )
    },
    setIntegrationMetaAll: ({commit}, { integrations }) => {
        commit('SET_INTEGRATION_META_ALL', { integrations } )
    },
    setAmplifyDataForAccount : ({commit}, {data, account}) => {
        commit("SET_AMPLIFY_FOR_ACCOUNT", {data, account});
    },
    setPowerupDataForAccount: ({commit}, {data, account, powerup}) => {
        commit("SET_POWERUP_DATA_FOR_ACCOUNT", {data, account, powerup})
    },
    setOAuthActivity: ({commit}, {activity, api}) => {
        commit('SET_OAUTH_ACTIVITY', {activity, api})
    },
    setOAuthError: ({commit}, {error, api}) => {
        commit('SET_OAUTH_ERROR', {error, api})
    },
    setAfterOAuthData: ({commit}, {collectionId, actions, url}) => {
        commit("SET_AFTER_OAUTH_DATA", {collectionId, actions, url})
    }
};

const currentAccountIntegration = (name, currentAccount, apis) => {
    return currentAccount && currentAccount.id && apis[name] && apis[name][currentAccount.id];
}

const getters = {
    externalApis : state => state.apis,
    oauthActivity : state => state.oauthActivity,
    afterOauthData: state => state.afterOauthData,
    apisMeta: state => state.apisMeta,
    currentAccountIntegrations: (state, getters, rootState) => {
        let currentAccount = rootState.currentAccountOverview;
        let integrationsMeta = state.apisMeta;
        let apis = state.apis;
        return Object.values(integrationsMeta).map(i => currentAccountIntegration(i.name, currentAccount, apis))
    },
    shareProviders: (state, getters, rootState, rootGetters) =>
        getters.currentAccountIntegrations
            .filter(integration => integration && integration.share_feature && integration.share_feature.has_needed_scopes)
            .concat([
                { name: 'buffer', title: 'Buffer' },
                { name: 'hootsuite', title: 'Hootsuite' },
                { name:'funnelAmplified', title: 'Funnel Amplified' }
            ])
            .filter((integration, index, all) => {
                let vendor = rootGetters.vendor;
                let vendorHasShareProvider = all.some(integration => rootGetters.vendor === integration.name);
                return !vendorHasShareProvider || vendor === integration.name
            })
}

function ensureApiExists(state, api) {
    state.apis[api] = state.apis[api] || {};
    state.oauthActivity[api] = state.apis[api] || {
        networkActivity: false,
        networkError: false
    }
}

const mutations = {
    SET_INTEGRATION_META: (state, { integration }) => {
        state.apisMeta[integration.name] = integration
    },
    SET_INTEGRATION_META_ALL: (state, { integrations }) => {
        state.apisMeta = integrations.reduce((result, item) => ({ ...result, [item.name]: item}), {});
    },
    SET_AFTER_OAUTH_DATA: (state, {collectionId, actions, url}) => {
        state.afterOauthData = {collectionId, actions, url };
    },
    SET_AMPLIFY_FOR_ACCOUNT : (state, {data, account}) => {
        var updated = {...state.apis.amplify}
        updated[account.id] = data;
        state.apis.amplify = updated;
    },
    SET_POWERUP_DATA_FOR_ACCOUNT : (state, {data, account, powerup}) => {
        ensureApiExists(state, powerup);
        var updated = {...state.apis[powerup]}
        updated[account.id] = data;
        state.apis[powerup] = updated;
    },
    SET_OAUTH_ACTIVITY: (state, {activity, api}) => {
        ensureApiExists(state, api);
        state.oauthActivity[api].networkActivity = activity;
    },
    SET_OAUTH_ERROR: (state, {error, api}) => {
        ensureApiExists(state, api);
        state.oauthActivity[api].networkError = error;
    },
    [types.USER_DID_LOG_OUT](state){
        state.apis = {
            amplify : {

            }
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
