import * as types from "@/store/mutation-types";
import planList from '@/utilities/PlanList'
import {formatCents} from "@/services/money";

let state = {
    planName: null,  // "Sharer" | ...  see PlanList.js
    planFrequency: null, // "1m" | "12m"
    termsAndPrivacyAcceptedDate: null,
};

const getters = {
    signupPlanData: state =>
        state.planName && planList.find(x => x.name === state.planName),
    signupPlanDuration: (state, getters) =>
        getters.signupPlanData &&
        getters.signupPlanData.durations.find(x => x.id.endsWith(state.planFrequency)),
    signupPlanPrice: (state, getters) =>
        getters.signupPlanDuration && getters.signupPlanDuration.cost,
    signupPlanPriceFormatted: (state, getters) => formatCents(getters.signupPlanPrice),
    signupStripeKey: (state, getters) =>
        getters.signupPlanDuration && getters.signupPlanDuration,
    signupPlanIcon: (state, getters) => getters.signupPlanData && getters.signupPlanData.icon,
    signupPlanPer: state => state.planFrequency === '1m' ? 'Month' : state.planFrequency === '12m' ? 'Year' : '',
    planName: state => state.planName,
    planFrequency: state => state.planFrequency, // "1m" | "12m"
    termsAndPrivacyAcceptedDate: state => state.termsAndPrivacyAcceptedDate,
};

const actions = {
    setSignUpPlan: ({commit}, {planName}) => {
        commit(types.SET_SIGNUP_PLAN, {planName})
    },

    setTermsAndPrivacyAcceptedDate: ({commit}, {acceptedDate}) => {
        commit(types.SET_TERMS_AND_PRIVACY_ACCEPTED_DATE, {acceptedDate})
    },

    setSignUpFrequency: ({commit}, {planFrequency}) => {
        commit(types.SET_SIGNUP_FREQUENCY, {planFrequency})
    },

    setPlanAndFrequencyFromStripe: ({commit}, {stripeKey}) => {
        commit(types.SET_PLAN_AND_FREQUENCY_FROM_STRIPE_KEY, {stripeKey})
    },
};

const mutations = {
    SET_SIGNUP_PLAN(state, {planName}) {
        state.planName = planName
    },
    SET_TERMS_AND_PRIVACY_ACCEPTED_DATE(state, {acceptedDate}) {
        state.termsAndPrivacyAcceptedDate = acceptedDate
    },
    SET_SIGNUP_FREQUENCY(state, {planFrequency}) {
        state.planFrequency = planFrequency
    },
    SET_PLAN_AND_FREQUENCY_FROM_STRIPE_KEY(state, {stripeKey}) {
        let plan = planList.find(p => p.durations.some(d => d.id === stripeKey));
        let duration = plan.durations.find(d => d.id === stripeKey);
        state.planName = plan.name;
        let keyParts = duration.id.split('_');
        state.planFrequency = keyParts[keyParts.length - 1] // Array follows the format of 'uc_engager_1m'
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

