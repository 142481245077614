<template>
    <div class="suggestion-controls mb-1 d-flex justify-content-between align-items-center"
         :class="{'old-collection-ux': !accountFeatures.newCollectionUx}">

        <div class="me-3 text-truncate d-none d-sm-block flex-grow suggestion-title-group"
             :class="{'d-none' : collectionBatch.length}">
            <div class="d-flex align-items-center">
                <suggestion-title class="font-size-20 text-steel-25" :show-topic-icon="false"/>
                <suggestion-source-tag :suggestion-type="hasSuggestions && hasSuggestions.type ? hasSuggestions.type : ''"
                                       class="ms-2"/>
            </div>
        </div>

        <div class="d-flex d-sm-none suggestion-title-container me-3 align-items-center"
             :class="{'d-none' : collectionBatch.length}">

            <drop-panel :panel-name="'mobileSuggestionManager'"
                        :panel-title="'mobileSuggestionManager'"
                        :show-header="false"
                        :overlap="true"
                        class="mobile-suggestion-manager me-2">
                <template v-slot:link>
                    <suggestion-source-tag class="me-2 d-none d-md-block"
                                           :suggestion-type="hasSuggestions && hasSuggestions.type
                                           ? hasSuggestions.type
                                           : ''"/>
                    <suggestion-title class="font-size-14 text-primary-25 flex-grow text-truncate"/>
                    <i class="fa fa-caret-down ms-1 font-size-15 "></i>
                </template>
                <template v-slot:default>
                    <suggestion-manager v-if="accountFeatures.newCollectionUx">
                        <template v-slot:title>
                            <h6 class="mb-2 label-calm">
                                <suggestion-title class="font-size-14 text-primary-25 flex-grow text-truncate"/>
                            </h6>
                        </template>
                    </suggestion-manager>
                </template>
            </drop-panel>
        </div>
        <div class="flex-grow">
            <HorizontalCompact v-if="accountFeatures.newFilterDropdown"></HorizontalCompact>
        </div>

        <div class="d-flex justify-content-end align-items-center flex-grow">
            <suggestion-layout-buttons :display-type="displayType"
                                       @displayTypeChange="setDisplayType"
                                       class="d-none d-md-flex"
            />
            <add-url-to-collection-modal
                v-if="suggestionViewerType === 'collections' && currentCollection"
            />
            <filters-modal
                :show="showingFiltersModal"
                @closed="showingFiltersModal = false"
            />
            <div class="d-flex justify-content-end align-items-center flex-md-grow-0 flex-grow-1">


                <div v-if="collectionBatch.length"
                     class="send-to-collections-container  flex-md-grow-0 flex-grow-1 position-relative d-flex justify-content-end overflow-visible"
                     :class="{'ms-auto': !(suggestionViewerType === 'collections' && hasSuggestions.type)}"
                >
                    <button class="btn btn-secondary me-2 ms-md-3" @click="$emit('collectionBatchUsed')">
                        Cancel
                    </button>

                    <!-- Send Articles to Collections -->
                    <button @click="sendArticleToCollection"
                            :disabled="sendingArticles"
                            class="send-article-to-collection-button btn btn-success flex-md-grow-0 flex-grow-1">
                        Send {{ collectionBatch.length }}
                        {{ $strings.plural('Article', collectionBatch.length) }} to
                        Collections
                    </button>

                    <collection-popover
                        @close="showCollections = false"
                        v-if="showCollections"
                        :cards="collectionBatch"
                        :collections="accountCollections"
                        @completed="showCollections = false; $emit('collectionBatchUsed')"
                    />
                </div>
                <add-url-button v-else-if="hasSuggestions && hasSuggestions.type === 'collection'"
                                class="text-nowrap ms-2"/>


            </div>
        </div>
    </div>

</template>

<script>

import {mapGetters} from 'vuex';
import FiltersModal from '@/components/FilteringAndSorting/Modal'
import AddUrlToCollectionModal from "@/components/Collections/AddUrlModal"
import AddUrlButton from "@/components/Collections/AddUrlButton"
import CollectionPopover from "@/components/Collections/CollectionPopover"
import SuggestionLayoutButtons from "./SuggestionLayoutButtons";
import SuggestionTitle from "@/components/Suggestions/ManageSuggestion/SuggestionTitle";
import SuggestionManager from "@/components/Suggestions/ManageSuggestion/SuggestionManager";
import DropPanel from "@/components/Controls/DropPanel";
import SuggestionSourceTag from "@/components/Suggestions/ManageSuggestion/SuggestionSourceTag";
import HorizontalCompact from "../FilteringAndSorting/V2/HorizontalCompact";

export default {
    props:
        ['hideGridButton', 'displayType', 'suggestionViewerType', 'collectionBatch',],
    data() {
        return {
            searchText: '',
            showingFiltersModal: false,
            showCollections: false,
            sendingArticles: false,
        }
    },
    components: {
        HorizontalCompact,
        SuggestionSourceTag,
        SuggestionLayoutButtons,
        FiltersModal,
        AddUrlToCollectionModal,
        CollectionPopover,
        AddUrlButton,
        SuggestionTitle,
        SuggestionManager,
        DropPanel,
    },
    computed: {
        ...mapGetters({
            suggestionUrls: 'suggestions',
            suggestionsCache: 'suggestionsCache',
            showFavorites: 'showFavoriteSuggestions',
            hasSuggestions: 'hasSuggestions',
            readingSuggestion: 'readingSuggestion',
            collections: 'collections',
            currentCollection: 'currentCollection',
            currentAccountOverview: 'currentAccountOverview',
            accountFeatures: 'accountFeatures',
        }),
        suggestions() {
            return this.suggestionUrls.map(url => {
                return this.suggestionsCache.find(s => url.includes(s.id))
            }).filter(s => s)
        },
        placeholder() {
            return `Search ${this.suggestions.length} suggestions`;
        },
        accountCollections() {
            return this.collections.filter(c => c.account.includes(this.currentAccountOverview.id));
        }
    },
    watch: {
        searchText() {
            this.$emit('searchTextChange', this.searchText)
        },
    },
    mounted() {

    },
    methods: {
        toggleShowFavorites() {
            this.$store.dispatch('toggleShowFavorites')
        },

        setDisplayType(type) {
            this.$emit('displayTypeChange', type)
        },
        sendArticleToCollection() {
            this.collapsePanels();

            const disableThisFunction = true;
            if (!disableThisFunction && this.showCollections) {
                // Prevent bursts.
                if (this.sendingArticles) {
                    return;
                }
                this.sendingArticles = true;

                window.Events.$emit('sendArticleToCollectionConfirm');

                setTimeout(() => {
                    this.sendingArticles = false
                }, 500);
            } else {
                this.showCollections = true;
            }
        },
        collapsePanels() {
            // On screens smaller than 991px, contract the sidebar.
            // Both for browser compatibility.
            if (screen.width < 992 || window.innerWidth < 992) {
                window.Events.$emit('contractSidebar');
            }
            // On screens smaller than 576px close the article reader.
            // Both for browser compatibility.
            if (screen.width < 576 || window.innerWidth < 576) {
                window.Events.$emit('closeArticleReader');
            }
        },
    }
}
</script>
<style lang="scss">
// Do not scope this styles or they won't work

.old-collection-ux {
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        .suggestion-title-group {
            display: none;
        }
    }
}
</style>
<style lang="scss">
// Do not scope.
@media screen and (min-width: 576px) and (max-width: 768px) {
    // Not enough room to show the title + add article + sidebar.
    .sidebar-is-showing .suggestion-title-group .suggestion-title-text {
        display: none;
    }
}
@media (max-width: 575.98px) {
    .suggestion-title-container {
        max-width: calc(100% - 6.5em); // 6.5em = + Add Article button width on mobile devices.
    }
    .dp-container, .dp-button {
        width: 100%;
    }
    .dp-button {
        display: flex;
    }
    .send-to-collections-container {
        max-width: unset !important;
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 991.98px) {
    .send-to-collections-container {
        font-size: var(--font-size-13);
    }
    .send-to-collections-container > button {
        font-size: inherit !important;
    }
    .sidebar-is-showing {
        .send-to-collections-container {
            font-size: var(--font-size-12);
            max-width: 402px;
        }

        .send-to-collections-container > button {
            padding-left: .25em;
            padding-right: .25em;
        }
    }
    .send-to-collections-container > button {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width: 575.98px) {
    .send-to-collections-container {
        max-width: unset !important;
    }
}
</style>
