import Api from './Api.ts'
import ActionLimiter from "@/utilities/requests";


export default class LimitedApi extends Api {
    constructor(store, base) {
        super(store, base);
        this.limiter = new ActionLimiter();
    }
    post(url, body, options, priority=0) {
        return this.limiter.doAction(() => super.post(url, body, options), priority)
    }
    get(url, options, priority=0) {
        return this.limiter.doAction(() => super.get(url, options), priority, url)
    }
    put(url, body, options, priority=0){
        return this.limiter.doAction(() => super.put(url, body, options), priority)
    }
    delete(url, options, priority=0) {
        return this.limiter.doAction(() => super.delete(url, options), priority)
    }
}
