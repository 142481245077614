<template>
    <modal class="edit-selection-modal" size="large" ref="modal">
        <template v-slot:header>
            <h4>Edit Selection</h4>
        </template>
        <edit-selection :selection="s" :page="p" ref="editSelection" :collectionAbstractLimit="collectionAbstractLimit"></edit-selection>
        <div v-if="isStillProcessing" class="flex-row d-flex text-start mb-0" style="height: 30px">
            <spinner size="20" class="mx-2"></spinner>
            <div v-if="s.processing_status === 'added'">Processing Article</div>
            <div v-if="s.processing_status === 'pending'">Adding Article</div>
        </div>
        <template v-slot:footer v-if="loading">
            <progress-bar class="full-width"></progress-bar>
        </template>
        <template v-slot:footer v-else>
            <span class="me-auto">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </span>
            <span class="float-end">
            <button type="button"
                    :disabled="isStillProcessing"
                    class="btn btn-secondary ms-2"
                    @click="onRepublish"
                    title="This will re-push this article to all connected galleries and integrations, in the case of integrations this could lead to duplication">
                Save and Republish
            </button>
            <button type="button" :disabled="isStillProcessing" class="btn btn-primary ms-2" @click="onSave">Save</button>
            </span>
        </template>
    </modal>
</template>

<style>
@media (min-width: 576px) {
    .edit-selection-modal .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }
}

.edit-selection-modal .modal-footer {
    display: flex;
    justify-content: space-between;
}
</style>

<script>
import Modal from "@/components/Controls/Modal";
import {showSelectionEdit} from "@/events";
import EditSelection from "@/components/Collections/EditSelection";
import ProgressBar from "@/utilities/ProgressBar";
import {mapGetters} from "vuex";
import Spinner from "@/components/Controls/Spinner.vue";
import {ContentPoliciesService} from "@/services/contentPolicies";

export default {
    name: 'EditSelectionModal',
    components: {Spinner, ProgressBar, EditSelection, Modal},
    props: ['selection', 'page'],
    data() {
        return {
            s: this.selection,
            p: this.page,
            loading: false,
        }
    },
    watch: {
        selection() {
            this.s = this.selection;
        },
        page() {
            this.p = this.page;
        }
    },
    computed: {
        ...mapGetters({
            collectionAbstractLimit: "collectionAbstractLimit",
            features: "accountFeatures",
            currentCollection: 'currentCollection',
        }),
        isStillProcessing() {
            return this.s && ['added', 'pending'].includes(this.s.processing_status)
        },
        failedToProcess() {
            return this.s && ['added'].includes(this.s.processing_status) && this.minutesSinceUpdate > 20
        },
    },
    methods: {
        async onSave() {
            this.loading = true;

            let selection = await this.$refs.editSelection.uploadAndSave();
            await this.$api.put(`/selections/${selection.id}`, {quality_check: 'sync'});
            let selectionNew = await this.$api.get(`/selections/${selection.id}`).then(r => r.data);
            selection = Object.assign({}, selection, selectionNew);
            this.$store.dispatch('addSuggestionToCache', {suggestion: selection});
            this.$refs.modal.hide();
            this.loading = false;
        },
        async onRepublish() {
            this.loading = true;
            let selection = await this.$refs.editSelection.uploadAndSave();
            await this.$api.post(`/selections/${selection.id}`, {quality_check: 'sync'});
            let selectionNew = await this.$api.get(`/selections/${selection.id}`).then(r => r.data);
            selection = Object.assign({}, selection, selectionNew);
            this.$store.dispatch('addSuggestionToCache', {suggestion: selection});
            this.$refs.modal.hide();
            this.loading = false;
        },
        show() {
            if (this.$refs.editSelection) {
                this.$refs.editSelection.resetArticleEdit();
            }
            this.$refs.modal.show();
        },
    },
    mounted() {
        showSelectionEdit.$on(selection => {
            this.s = selection;
            this.show();
        })
    }
}
</script>
