<template>
    <div>
        <div>
            <h4 class="subtitle">Geography</h4>
            <p class="filter-item" v-for="thisType in availableGeographies" :key="'content-types-'+thisType">
                <label><input 
                    @change="update()" 
                    type="checkbox" 
                    :value="thisType"
                    v-model="geography"
                > 
                    {{thisType}}
                </label>
            </p>
        </div>
    </div>
</template>

<script>

import Base from './FilterMixin'

export default {
    props : [],
    data(){
        return {
            availableGeographies : [
                'US',
                'UK',
                'IN'
            ],
            geography : [],
            showAllGeos : false,                 
        }
    },
    mixins : [
        Base
    ],
    mounted(){
        this.geography = this.filters.geography
    },
    methods : {
        update(){
            this.updateFilter('geography', this.geography)
        }
    }
}
</script>

<style>
</style>