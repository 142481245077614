import {Api as API} from "dot-api";

export default {
    install: (Vue) => {
        Vue.prototype.$rest = createApi(Vue.prototype.$api);
    }
}

export const createApi = appApi => API('', {
    get: url => appApi.get(url),
    post: (url, data) => appApi.post(url, data),
    put: (url, data) => appApi.put(url, data),
    del: url => appApi.delete(url)
}, { structure:  apiStructure});

export const apiStructure =  {
    accounts: {
        $id: {
            subscription: { usage: {} },
            integrations: {
                shutterstock: {
                    actions: {
                        license: {}
                    },
                    resources: {
                        images: {}
                    }
                },
                $id: {
                    authentication: { },
                    actions: { $id: {} },
                    resources: { $id: {} }
                }
            },
        }
    },
    collections: {
        $id: {
            sources: {
                $id:{}
            },
            description: {},
            icon: {},
            integrations: {
                $id: {
                    actions: { $id: {} },
                    resources: { $id: {} }
                }
            },
        }
    },
    topics: {
        $id: {}
    },
    selections: {
        $id: {
            image: {},
            description: {}
        }
    },
    suggestions: {
        $id: {}
    }
}
