<template>
    <div class="">
        <div class="row">
            <div class="col-12">

                <div class="dropdown position-relative">
                    <label data-bs-toggle="dropdown"
                           aria-haspopup="true"
                           aria-label="Sources Dropdown"
                           class="full-width align-self-center top-3">
                        <button type="button" class="btn btn-sm btn-secondary font-size-14 d-block full-width"
                                role="button">
                            Select Sources
                            <span class="dropdown-toggle"></span>
                            <spinner class="spinner-inline" v-show="isLoadingSources"></spinner>
                        </button>
                    </label>
                    <div class="dropdown-menu dropdown-menu-center filter-form__sources-menu" role="listbox">
                        <select-sources :value="selectedSources"
                                        @input="updateSource"
                                        :all-sources="options"></select-sources>
                    </div>
                </div>
                <div class="font-size-10 float-end">
                    {{ selectedSources.length }}/{{ options.length }} of sources
                    selected.
                    <spinner v-show="isLoadingSources" class="small spinner-inline"></spinner>
                </div>
            </div>
        </div>

        <div class="row">
            <label @click.stop="includeAllSourcesClick" class="col align-self-center top-3">
                <input type="checkbox" class="margin-right-5" :checked="includeAllSources"
                       :disabled="includeAllSources"/>
                Include All &amp; New Sources
            </label>
        </div>
        <div class="row">
            <label @click.stop class="col align-self-center top-3">
                <input type="checkbox" class="margin-right-5" v-model="includeNewSources" :class="{'text-muted':
            includeAllSources}"/>
                Include New Sources
            </label>
        </div>
        <!--        <div class="row">-->
        <!--            <label @click.stop class="mt-1 mb-1">-->
        <!--                <input type="checkbox" v-model="useGlobal"/>-->
        <!--                Show all publishers for account-->
        <!--            </label>-->
        <!--        </div>-->
    </div>

</template>

<script>
import {mapGetters} from 'vuex'
import SelectSources from "./SelectSources";
import {hasValue} from "../../../utilities/utils";
import PublisherList from "@/components/FilteringAndSorting/PublisherList";
import {mergePublishers} from "../../../services/Publishers";
import Spinner from "../../Controls/Spinner";

export default {
    props: {
        value: Object,
        filterViewId: {type: String, default: null},
        type: {type: String, default: 'collection'},
        hasContentId: {type: String, default: null}
    },
    mixins: [PublisherList],
    components: {
        Spinner,
        SelectSources
    },
    computed: {
        ...mapGetters({features: 'features', topics: 'topics',}),
        selectedSources() {
            return this.filters && this.filters.sources || this.calculatedSourceList.map(x => x.source) || [];
        },
        excludedSources() {
            return this.filters && this.filters.excludedSources || [];
        },
        calculatedSourceList() {
            return this.sources.filter(source => {
                return this.filters && this.filters.excludedSources ?
                    this.excludedSources.indexOf(source.source) === -1 :
                    this.filters && this.filters.sources ? this.value.sources.indexOf(source.source) >= 0 :
                        false
            })
        },
        explicitlySpecifiedSources() {
            return Array.from(new Set([...(this.filters.sources || []), ...(this.filters.excludedSources || [])]))
        },
        options() {
            let explicitlySpecifiedSources = this.explicitlySpecifiedSources.map(x => ({source: x, count: 0, name: x}));
            return mergePublishers(this.sources, explicitlySpecifiedSources);
        },
        availableSources() {
            return this.sources ||
                [...this.selectedSources, ...this.excludedSources].map(x => ({source: x, count: null}));
        },
        isTopic() {
            return this.type === 'topic'
        },
        includeAllSources() {
            return this.selectedSources.length === this.sources.length && this.includeNewSources
        },
        hasContentType() {
            return this.type
        },
        publisherSource() {
            return this.getPublishersPath(
                {usGlobal: this.useGlobal, filterViewId: this.value && this.value && this.value.id}) || ''
        }
    },
    methods: {
        updateSource(value) {
            console.debug('SourcesFilter.updateSource', value)
            this.filters = Object.assign({}, this.filters, {
                excludedSources: this.includeNewSources ?
                    this.sources.filter(source => value.indexOf(source.source) === -1).map(x => x.source) : null,
                sources: !this.includeNewSources ? value : null
            });
            this.updateOutside();
        },
        updateOutside() {
            let value = {
                ...this.filters,
                includeNewSources: this.includeNewSources,
                includeAllSources: this.includeAllSources
            }
            // console.debug('SourcesFilter.updateOutside', value)
            if (JSON.stringify(this.value) !== JSON.stringify(value))
                this.$emit('input', value)
        },
        async fetchSources() {
            let params = {
                age: this.type === 'collection' ? -1 : 60,
                // count: true,
                // include: ["creator"],
                type: "all",
                undated: true,
                limit: 1000
            }
            // let url = `/suggestion-data?${this.type}=${this.hasContentId}`
            // return this.$api.post(url, params).then(response => response.data)
            let sources = await this.getPublishers({
                usGlobal: this.useGlobal,
                filterViewId: this.value && this.value && this.value.id,
                hasContentType: this.type,
                hasContentId: this.hasContentId
            })
            // console.log('got publishers', sources)
            return Object.keys(sources).map(source => ({source, count: sources[source], name: source}))
        },
        async loadSources() {
            // console.log('loadSources', this.type, this.hasContentId)
            if (this.hasContentId && this.type) {
                this.isLoadingSources = true;
                this.sources = await this.fetchSources();
                this.isLoadingSources = false;
            }
        },
        includeAllSourcesClick() {
            this.includeNewSources = true;
            this.filters = Object.assign({}, this.filters, {
                includeNewSources: true,
                excludedSources: [],
                sources: null
            });
            this.updateOutside();
        }
    },
    data() {
        // console.debug('SourcesFilter.data', {
        //     filters: this.value,
        //     sources: [],
        //     includeNewSources: this.value.includeNewSources || hasValue(this.value.excludedSources) ||
        //         !hasValue(this.value.sources),
        // })
        return {
            filters: this.value,
            sources: [],
            isLoadingSources: false,
            useGlobal: false,
            includeNewSources: this.value.includeNewSources || hasValue(this.value.excludedSources) ||
                !hasValue(this.value.sources),
        }
    },
    mounted() {
        this.loadSources();
        // console.log('fix sources mount', this.value, !hasValue(this.value.excludedSources),
        //     !hasValue(this.value.sources))
        if (this.value && !hasValue(this.value.excludedSources) && !hasValue(this.value.sources)) {
            console.log('fixing sources')
            this.updateSource([])
        }
    },
    watch: {
        hasContentId() {
            // console.debug('SourcesFilter.hasContentId.changed', this.hasContentId)
            this.loadSources();
        },
        publisherSource() {
            this.loadSources()
        },
        value() {
            // console.debug('SourcesFilter.value.changed', this.value)
            if (JSON.stringify(this.filters) !== JSON.stringify(this.value)) {
                if (this.value && !hasValue(this.value.excludedSources) && !hasValue(this.value.sources)) {
                    this.filters = {
                        ...this.value,
                        excludedSources: this.includeNewSources ? [] : null,
                        sources: !this.includeNewSources ? [] : null
                    }
                } else {
                    this.filters = this.value;
                    this.includeNewSources = hasValue(this.value.includeNewSources) ? this.value.includeNewSources :
                        (!hasValue(this.value.sources) || hasValue(this.value.excludedSources))
                }
            }
        },
        includeNewSources() {
            // console.log('SourcesFilter.includeNewSources.changed', this.includeNewSources)
            this.updateSource(this.selectedSources)
            this.updateOutside()
        }
    },
}
</script>


<style>

.filter-form__sources-menu {
    width: 100%;
    min-width: 19rem;
    font-size: 10px;
}

</style>

<style scoped>
.filter-section a {
    font-size: 10px;
    text-transform: uppercase;
}
</style>
