<template>
    <div class="expandable-sidebar"
         :class="{
                'is-expanded': isExpanded,
                'is-not-expanded': !isExpanded,
                'feature-flag-off': isNotNewCollectionUx,
                'is-initial-state': isInitialState,
                'is-not-initial-state': !isInitialState,
            }">

        <!-- CSS controls which button is currently displayed -->
        <div class="expandable-sidebar-button-container">
            <button class="expandable-sidebar-button  expandable-sidebar-button-expand"
                    @click="expandSidebar(true)"
                    title="Open Sidebar"
            >
                <i class="expandable-sidebar-button-icon fa fa-chevron-right"></i>
            </button>
            <button class="expandable-sidebar-button  expandable-sidebar-button-contract"
                    @click="contractSidebar(true)"
                    title="Close Sidebar"
            >
                <i class="expandable-sidebar-button-icon fa fa-chevron-left"></i>
            </button>
        </div>

        <div class="expandable-sidebar-content-wrapper">
            <div class="expandable-sidebar-content">
                <slot name="default"></slot>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
    name: 'ExpandableSidebar',
    props: {},
    components: {},
    data() {
        return {
            expand: true,
            userExpandPreference: true, // I dare you to put this in VUEX store.
            isInitialState: true,
        }
    },
    computed: {
        ...mapGetters({
            accountFeatures: 'accountFeatures',
        }),
        isNotNewCollectionUx() {
            return this.accountFeatures && !this.accountFeatures.newCollectionUx;
        },
        isExpanded() {
            return this.expand;
        }
    },
    mounted() {
        window.Events.$on('expandSidebar', this.expandSidebar);
        window.Events.$on('contractSidebar', this.contractSidebar);
    },
    watch: {},
    methods: {
        toggleSidebar(isUser = false) {
            if (this.expand) {
                // If open, then close.
                this.contractSidebar(isUser);
            } else {
                // Else open.
                this.expandSidebar(isUser);
            }
        },
        expandSidebar(isUser = false) {
            this.isInitialState = false;
            this.expand = true;
            if (isUser) {
                this.userExpandPreference = this.expand;
            }
        },
        contractSidebar(isUser = false) {
            this.expand = false;
            if (isUser) {
                this.userExpandPreference = this.expand;
            }
        },
        setSidebarToUserPreference() {
            this.expand = this.userExpandPreference;
        }
    }
}
</script>
<style lang="scss">
/* Feature flag handling */
// Do not scope styles or .suggestion-sidebar won't work.
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .expandable-sidebar.feature-flag-off {
        display: none!important;
    }
}

.expandable-sidebar.feature-flag-off {
    background: none !important;
    border-right: 0 !important;
}

.feature-flag-off {
    .expandable-sidebar-button {
        display: none!important;
    }

    .expandable-sidebar-content-wrapper {
        position: static !important;
    }

    .suggestion-sidebar {
        overflow-x: hidden;
    }


    .expandable-sidebar-content-wrapper {
        background: none !important;
    }
}

.large-sidebar-only {
    display: none;
}

// XL Only
@media (min-width: 1200px) {
    .large-sidebar-only {
        display: inline;
    }
}
</style>

<style lang="scss" scoped>
/* General base styling, nothing responsive */
.expandable-sidebar {
    position: relative;
    z-index: 100;
    margin-left: -1.5rem; // Need to subtract me-4 which is 1.5rem;
    margin-right: calc(1.5rem / 2);
    border-right: 1px solid var(--water-33);
}
.expandable-sidebar {
    box-shadow:  20px 0px 15px -10px var(--water-100);
    z-index: 1000;
}
.expandable-sidebar, .expandable-sidebar-content-wrapper {
    background: var(--water-50);
}

.expandable-sidebar-content-wrapper {
    overflow: hidden;
    transition: width 0.3s ease-in-out;

}


.expandable-sidebar-button-container {
    position: absolute;
    top: .8rem;
    right: -.95rem;
    border-radius: 100%;
    z-index: 103;
}

.expandable-sidebar-button {
    $sizing: 1.95rem;
    position: relative;
    border-radius: 100%;
    width: $sizing;
    height: $sizing;
    border-style: solid;
    border-width: 2px;
    border-color: var(--water-100);
    background: var(--primary-50);
    color: var(--water-100);
    transition: background .2s ease-in-out;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
}

.expandable-sidebar-button:hover {
    border-color: var(--primary-50);
    background: var(--water-100);
    color: var(--primary-50);
}

.expandable-sidebar-button-icon {
    position: relative;
    bottom: 1px;
    font-size: var(--font-size-14);
}

.expandable-sidebar-button-contract .expandable-sidebar-button-icon {
    padding-right: 2px;
}

.expandable-sidebar-button-expand .expandable-sidebar-button-icon {
    padding-left: 2px;
}

/* Mobile positioning */
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .expandable-sidebar-content-wrapper {
        position: absolute;
        z-index: 100;
    }
}
</style>
<style lang="scss" scoped>
/* +++ Expanded State +++ */
.is-expanded .expandable-sidebar-button-expand {
    display: none;
}

.is-expanded .expandable-sidebar-button-contract {
    display: block;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .is-expanded.is-initial-state .expandable-sidebar-button-expand {
        display: block;
    }
    .is-expanded.is-initial-state .expandable-sidebar-button-contract {
        display: none;
    }
    .is-expanded.is-not-initial-state .expandable-sidebar-button-container {
        z-index: 101;
        right: unset;
        left: calc(100vw - 2.5rem); // Manually recalculate if icon size changes.
    }
}
</style>
<style lang="scss" scoped>
/* --- Contracted State --- */
// Mixins for contracted styles.
@mixin wrapper-not-expanded {
    width: 0 !important;
}

@mixin sidebar-not-expanded {
    padding-left: 1.5rem;
    background: var(--water-33);
}

// Base state for contracted sidebar.
.is-not-expanded .expandable-sidebar-content-wrapper {
    @include wrapper-not-expanded;
}

.expandable-sidebar.is-not-expanded {
    @include sidebar-not-expanded;
}

// Initial mobile state for contracted sidebar.
@media (max-width: 575.98px) { // X-Small devices (portrait phones, less than 576px)
    .is-initial-state.is-initial-state .expandable-sidebar-content-wrapper {
        @include wrapper-not-expanded;
    }

    .expandable-sidebar.is-initial-state.is-initial-state {
        @include sidebar-not-expanded;
    }
}

// Button States
.is-not-expanded .expandable-sidebar-button-expand {
    display: block;
}

.is-not-expanded .expandable-sidebar-button-contract {
    display: none;
}
</style>

<style lang="scss" scoped>
/* Responsive Sizing */

.expandable-sidebar-content-wrapper, .expandable-sidebar-content {
    --width: calc(100vw - 1.5rem); // Need to subtract me-4 which is 1.5rem;
    width: var(--width);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .expandable-sidebar-content-wrapper, .expandable-sidebar-content {
        --width: 320px;
        width: var(--width);
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .expandable-sidebar-content-wrapper, .expandable-sidebar-content {
        --width: 250px;
        width: var(--width);
    }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .expandable-sidebar-content-wrapper, .expandable-sidebar-content {
        --width: 250px;
        width: var(--width);
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .expandable-sidebar-content-wrapper, .expandable-sidebar-content {
        --width: 250px;
        width: var(--width);
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .expandable-sidebar-content-wrapper, .expandable-sidebar-content {
        --width: 310px;
        width: var(--width);
    }
}
</style>
