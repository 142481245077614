<template>
    <span class="suggestion-title"
          :title="hasSuggestions.type === 'topic' ? `Topic: ${name}` : `Collection: ${name}`"
          :class="{'light-variation': useLightVariation, 'trim-title': trimTitle}">
        <i
            v-if="hasSuggestions.type === 'collection'"
           class="title-icon me-1"
           :class="iconClass"/>
        <topics-svg v-else-if="showTopicIcon && suggestionViewerType === 'topics'" class="icon-dynamic me-1 singular top-2"/>
        <span class="suggestion-title-text">{{ name }}</span>
    </span>
</template>

<style lang="scss" scoped>
.title-icon {
    min-width: 1em;
    min-height: 1em;
    text-align: center;
}
.trim-title .suggestion-title-text {
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap;
    max-width: 100%;
}
</style>

<script>
import {mapGetters} from "vuex";
import {getIconClass} from "../../../services/Icons";
import TopicsSvg from "../../../assets/svg/topics";

export default {
    name: "SuggestionTitle",
    props: {
        // Could take in a collection.id instead of using currentCollection...
        // collectionId: {type: String, default: null},
        useLightVariation: {type: Boolean, default: false},
        trimTitle: {type: Boolean, default: false},
        showTopicIcon: {type: Boolean, default: true},
    },
    data() {
        return {}
    },
    components: {
        TopicsSvg,
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            topic: 'currentTopic',
            hasSuggestions: 'hasSuggestions',
            canManageCollection: 'canManageCollection',
            accountFeatures: 'accountFeatures',
            suggestionViewerType: 'suggestionViewerType',
        }),
        iconClass() {
            return this.suggestionViewerType === 'collections'
                ? getIconClass(this.currentCollection && this.currentCollection.icon || 'bolt')
                : 'fa fa-search';
        },
        name() {
            if (this.suggestionViewerType === 'topics')
                return this.topic && this.topic.name
            else
                return this.currentCollection &&
                    this.currentCollection.name
        }
    },
    mounted() {

    },
    methods: {},

}
</script>
