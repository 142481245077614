<template>
    <nestable-modal ref="modal" modal-name="editCollectionSourcesModal" size="md-lg" @shown="shown">
        <template v-slot:header>
            <h5>
                Manage Sources
            </h5>
        </template>
        <div class="manage-collection-sources-panel auto-scroll">
            <collection-sources
                :collection="currentCollection"
                @saved="loadSources"
            />
        </div>
        <template v-if="networkActivity" v-slot:footer>
            <progress-bar style="height: 30px; width: 100%;"/>
        </template>
        <template v-slot:footer v-else>
            <div class="buttons">
                <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">OK</button>
            </div>
        </template>
    </nestable-modal>
</template>

<script>
import NestableModal from "@/components/Controls/NestableModal";
import {mapGetters} from "vuex";
import CollectionSources from "@/components/Collections/V2/CollectionSources";

export default {
    name: "EditCollectionSourcesModal",
    components: {CollectionSources, NestableModal},
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection'
        })
    },
    data() {
        return {
            collection: {},
            networkError: false,
            networkActivity: false,
            onlyType: null,
        }
    },
    methods: {
        loadSources() {
            console.log('loadSources()', 'do something');
        },
        shown() {
            console.log('shown()', this.onlyType);
        }
    },
    mounted() {
        window.Events.$on('show_editCollectionSourcesModal', data => {
            console.log('show_editCollectionSourcesModal', data)
            this.onlyType = data && data.onlyType
        });
    },
}
</script>

<style lang="scss" scoped>
.manage-collection-sources-panel {
    min-height: 26em;
}
</style>
