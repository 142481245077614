<template>
    <button
        role="button"
        type="button"
        data-bs-toggle="popover"
        data-bs-trigger="focus"
        :data-bs-content="content"
        :data-bs-html="html"
        @click.prevent
    >
        <slot />
    </button>
</template>

<script>
import {Popover} from "bootstrap";

export default {
    name: "PopoverButton",
    props: {
        title: String,
        content: String,
        html: { type: Boolean, default: false }
    },
    mounted() {
        this.popOver = new Popover(this.$el);
    },
    unmounted() {
        this.popOver.dispose();
    },
    watch: {
        title() {
            this.popOver = new Popover(this.$el, {title: this.title});
        },
        content() {
            this.popOver = new Popover(this.$el, {content: this.content});
        }
    }
}
</script>
