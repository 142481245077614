<template>
    <div class="d-inline-block">
        <div class="d-flex me-3">
            <!--{{channel.accountType}}-->
            <!--</div>-->
            <!--<div class="d-inline-block" v-if="channel.name || channel.username">-->
            <div class=" me-3">
                <div v-if="avatarUrl" class="position-relative d-inline-block">
                    <img v-if="avatarUrl" :src="avatarUrl" class="-avatar"/>
                    <i :class="icon" class="position-absolute channel-subscript"></i>
                </div>
                <i v-else :class="icon" style="font-size: 1.2em"></i>
            </div>
            <span class="d-inline-block">
                <div>
                    {{name||network}}
                </div>
                <div v-if="name&&network" class="text-muted small">
                    {{network}}
                </div>
            </span>
        </div>
        <!--<div class="small text-muted">{{channel.type}}</div>-->
    </div>
</template>


<style scoped>
    .-avatar {
        height: 32px;
        width: 32px;
        border-radius: 16px;
    }
    .channel-subscript {
        bottom: -.4em;
        right: -.4em;
        padding: .25em .4em;
        width: 1.6em;
        height: 1.6em;
        border-radius: .8em;
        background: var(--bs-dark);
        color: var(--bs-light)

    }
</style>

<script>
    export default {
        props: ['name', 'network', 'icon', 'avatarUrl']
    }
</script>
