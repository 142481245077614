<template>
    <div class="row suggestion">
        <div class="col-sm">
            <h6 class="card-title">
                <a href="#" @click.prevent="loadArticle">{{displayTitle}}</a>
            </h6>
            <h6 class="small d-flex">
                <a title="View Original Source" :href="$shortener.link(card)" target="_blank" class="">
                    <i class="fa fa-external-link me-1"></i>
                    <span v-if="card.publisher">{{card.publisher}}</span>
                    <span v-if="card.publisher && prettyDate" class="me-1 ms-1">•</span>
                    <span v-if="prettyDate">{{prettyDate}}</span>
                </a>
            </h6>
            <p class="card-text" v-text="displayText"></p>
            <br class="d-block d-md-none"/>

            <suggestion-tags :page="card"></suggestion-tags>
        </div>
        <div v-if="features.selectionProcessingUi" class="hero-image-wrapper col-sm-2">
            <div v-if="isStillProcessing" class="flex-column d-flex justify-content-center align-items-center bg-light status-overlay" style="height: 100%">
                <spinner size="35"></spinner>
                <div v-if="card.processing_status === 'added'">Processing Content</div>
                <div v-if="card.processing_status === 'pending'">Loading</div>
            </div>
            <div v-else-if="features.selectionProcessingUi && failedToProcess" class="flex-column d-flex justify-content-center align-items-center status-overlay" style="height: 100%">
                May have failed to process content
            </div>
            <suggestion-image :display-missing="true" :url="card.image_url" height="145px"/>
        </div>
        <div v-else class="hero-image-wrapper col-sm-2">
            <suggestion-image :display-missing="true" :url="card.image_url" height="145px"/>
        </div>
        <div class="col-sm-3">
            <div class="card-actions">
                <a :title="favoritedByText" href="#!" @click.prevent class="small" @click="toggleFavorite()">
                    <span v-if="isFavorited && ! isFavoritedByCurrentUser">
                        <span class="text-primary"><i class="fa fa-star"></i></span>
                    </span>
                    <span v-if="isFavoritedByCurrentUser">
                        <span class="text-danger"><i class="fa fa-star"></i></span>
                    </span>
                    <span v-if="! isFavorited && ! isFavoritedByCurrentUser">
                        <i class="fa fa-star-o"></i>
                    </span>
                    Favorite
                </a>
                <a v-if="card.notes" :title="'See Notes: (' + noteCount + ')'" href="#!" @click.prevent="loadArticleWithNotes" class="small">
                    <i class="fa fa-comment-o" v-if="noteCount == 0"></i>
                    <i class="fa fa-comment" v-else></i> Notes
                </a>
                <share-link :suggestion="card" :type="containerType" text="Share" class="small" style="width: fit-content" />
                <a @click.prevent="toggleInCollectionBatch" title="Add to collections" href="#" class="small" v-if="hasEditPrivileges">
                    <i v-if="isInCollectionBatch" class="fa fa-check-circle text-success"></i>
                    <i v-else class="fa fa-plus"></i> Add to Collections
                </a>

                <select aria-label="Suggestion Classification" :value="card.classification" class="form-control form-select classification">
                    <option value="blog">Blog</option>
                    <option value="news">News</option>
                    <option value="other">Other</option>
                </select>
                <a v-if="!isReviewerForHasSuggestions" href="#" class="small text-danger"  @click.prevent="hideSuggestion">
                    <i class="fa fa-trash"></i>
                    Remove Suggestion
                </a>
                <a v-if="canEdit" href="#" class="small text-primary"   @click.prevent="editSelection()">
                    <i class="fa fa-edit"></i>
                    Edit Suggestion
                </a>
                <image-downloader :selection="card" link-class="text-secondary"  class="small text-secondary">
                    <i class="fa fa-link"></i>
                    Download Image
                </image-downloader>
            </div>
        </div>

    </div>
</template>

<script>
import ShareLink from './ShareLink'
import BaseCard from './Card'
import SuggestionImage from '@/components/Suggestions/HeroImage'
import { mapGetters } from 'vuex';
import CollectionBatchControls from "@/components/Collections/BatchControlWidget"
import SuggestionTags from "@/components/Suggestions/SuggestionTags";
import ImageDownloader from "@/components/ImageDownloader";
import Spinner from "@/components/Controls/Spinner.vue";

export default {
    props : ['card'],
    data(){
        return {
            summaryLength : 320,
            titleLength : 100
        }
    },
    computed : {
        ...mapGetters({
            showImage : 'showImagesInListView',
            features: 'accountFeatures',
        }),
        isStillProcessing() {
            return ['added', 'pending'].includes(this.card.processing_status)
        },
        failedToProcess() {
            return ['added'].includes(this.card.processing_status) && this.minutesSinceUpdate > 20
        },
    },
    mixins : [BaseCard],
    components : {
        Spinner,
        ImageDownloader,
        SuggestionTags,
        ShareLink, SuggestionImage, CollectionBatchControls
    },
    mounted(){

    },
    methods : {

    }
}
</script>

<style scoped>

.card-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.classification {
    font-size: var(--font-size-13);
    height: 26px!important;
    padding-top: 1px;
    padding-bottom: 1px;
}

.image-container {
    overflow: hidden;
    display: flex;
    max-height: 150px;
    justify-content: center;
    align-content: center;
}

.image-container img {
    max-height: 100%;
    align-self: center;
}

.card-text {
    font-size: var(--font-size-13);
}

.hero-image-wrapper {
    position: relative;
}

.remove-card {
    color: white;
    display: block;
    position: absolute;
    right: 8px;
    top: -5px;
    cursor: pointer;
    z-index: 101;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.status-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 99;
    opacity: .8;
}

.suggestion:hover .remove-card {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}
</style>
