

<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            templates () { return this.contentTemplates || []; },
            ...mapGetters({
                contentTemplates: 'contentTemplates',
                features: 'features'
            })
        },
        methods: {
            async refresh() {
                console.log('ContentTemplates.refresh')
                let completion = this.$store.dispatch('refreshContentTemplates');
                if (this.templates === false) await completion;
            }
        }
    }
</script>
