<template>
    <modal v-bind="{alert, size, canClose, noFooter, noHeader}" ref="modal" @close="closed">
        <template v-slot:header>
            <slot name="header"/>
        </template>
        <div>
            <slot/>
        </div>
        <template v-slot:footer>
            <slot name="footer"/>
        </template>
    </modal>
</template>

<style>

</style>

<script>
import Modal from "@/components/Controls/Modal";

export default {
    components: {Modal},
    props: {
        modalName: String,
        alert: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        },
        canClose: {
            type: Boolean,
            default: true
        },
        noFooter: {
            type: Boolean,
            default: false
        },
        noHeader: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closed(data = {}) {
            this.state = 'closed';
            this.$emit('closed');
            window.Events.$emit('closed_' + +this.modalName, data);
            window.Events.$emit('restoreModal');
        },
        hide() {
            this.$refs.modal.hide()
        }
    },
    data() {
        return {
            state: 'closed'
        }
    },
    mounted() {
        var onShow, onMinimize, onRestore;

        onRestore = () => {
            window.Events.$on('minimizeModals', onMinimize);
            if(this.$refs.modal) {
                this.$refs.modal.show();
            }
        };

        onMinimize = () => {
            if (this.state === 'open')
                window.Events.$once('restoreModal', onRestore);
            this.state = 'minimized';
            this.$refs.modal.minimize();
        };

        window.Events.$on('minimizeModals', onMinimize);

        onShow = (data) => {
            if (data.target === 'modal' || !data.target) {
                this.state = 'open';
                console.log('show', this.modalName)
                window.Events.$on('minimizeModals', onMinimize);
                if(this.$refs.modal) {
                    this.$refs.modal.show();
                }
                this.$emit('shown', data)
            }
        };
        window.Events.$on('show_' + this.modalName, onShow);
        window.Events.$on('closed_' + this.modalName, () => {
            window.Events.$off(onRestore)
        });
        window.Events.$on('close_' + this.modalName, () => {
            this.$refs.modal.hide()
        });
    }
}
</script>
