<template>
    <div class="d-flex">
        <filter-display-compact
            :filters="filters"
            class="flex-grow">
        </filter-display-compact>
        <div class="d-flex flex-wrap justify-content-between" style="flex-direction: row-reverse">
            <div>
                <edit-filters-button
                    :filters="filters"
                    :has-content-id="hasContentId"
                    :has-content-type="hasContentType"
                    class="btn btn-primary btn-sm ms-3 mb-2"
                    @saved="$emit('changed', $event)">
                </edit-filters-button>
                <delete-filters-button
                    :filters="filters"
                    class="btn btn-primary btn-sm ms-3 mb-2"
                    @deleted="$emit('deleted', $event)">
                </delete-filters-button>
            </div>
            <div>
                <filter-usages-link
                    class="btn btn-link btn-sm me-0 pe-0"
                    :filter-view-id="filters && filters.id">
                </filter-usages-link>
            </div>
        </div>
    </div>
</template>

<script>
import FilterDisplayCompact from "./FilterDisplayCompact";
import EditFiltersButton from "./EditFiltersButton";
import DeleteFiltersButton from "./DeleteFiltersButton";
import FilterUsagesLink from "./FilterUsagesLink";
export default {
    name: "FilterViewRow",
    components: {FilterUsagesLink, FilterDisplayCompact, EditFiltersButton, DeleteFiltersButton},
    props: {
        filters: {},
        hasContentType: {},
        hasContentId: {},
    },
}
</script>

<style scoped>

</style>
