<template>
    <details :open="true" class="powerup">
        <summary class="mb-2">
            <span class="power-up-label d-flex ">
                <i class="fa fa-file-code-o icon-dynamic d-inline"/>
                <span class="ms-2">
                    HTML Email
                    <div class="text-muted small" >
                        Create beautiful newsletters that you can export for use with any email client
                        <a  :href="learnUrls.customHtmlEmail" v-if="learnUrls.customHtmlEmail"  target="_blank">Learn More</a>
                    </div>
                </span>
            </span>
        </summary>

        <button type="button" class="btn btn-primary btn-sm full-width mb-2" @click="add()">Add Campaign</button>
        <ul class="no-bullets ms-0 ps-0 list-group">
            <li v-for="campaign in campaigns" class="list-group-item" :key="campaign.id">
                {{campaign.name}}
                <a download="email.html"
                   class="btn btn-primary btn-sm float-end ms-2"
                   :href="`${apiHost}/collection-content-campaigns/${campaign.id}/content.html`"
                   aria-label="download"
                >
                    <i class="fa fa-download"></i>
                </a>
                <button class="btn btn-secondary btn-sm float-end ms-2" type="button" @click="edit(campaign)"  aria-label="edit">
                    <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-danger btn-sm float-end ms-2" type="button" @click="del(campaign)"  aria-label="delete">
                    <i class="fa fa-trash"></i>
                </button>
            </li>
        </ul>
        <collection-content-campaign
                v-if="editorIsVisible"
                @saved="hideEditor()"
                :collection-id="collectionId"
                :campaign="currentCampaign">
        </collection-content-campaign>
    </details>
</template>

<style scoped>
    .power-up-label {
        display: inline-block;
    }

    .power-up-label a {
        text-decoration: underline;
    }
    .power-up-label .icon-dynamic {
        vertical-align: top;
        margin-top: .2em;
    }

    .powerup-action {
        right: 0;
        left: auto;
        position: absolute;
        top: 0;
        bottom:0;
        margin: auto 10px auto auto;
        height: fit-content;
    }
    .powerup summary {
        background: var(--bs-light);
        border-bottom: 1px solid var(--bs-default);
        list-style: none;
        list-style-type: none;
        margin: -10px -10px -1px -10px;
        color: var(--bs-dark);
        position: relative;
    }
    details.powerup summary::-webkit-details-marker {
        display:none;
    }
    .powerup {
        border: 1px solid var(--bs-default);
        padding: 10px;
        padding-bottom: 0;
    }

    .powerup[open] {
        padding-bottom: 20px;
    }


    .powerup[open] summary {
        margin-bottom: 10px;
    }

    .hide {
        display: none;
    }

    .powerup:not(:last-child) {
        border-bottom: none;
    }
    .powerup-message {
        display: inline-block;
        color: var(--bs-danger);
        font-size: 0.7em;
        max-width: 360px;
        margin-left: 10px;
        vertical-align: middle;
        white-space: pre;
    }
    .powerup summary {
        vertical-align: middle;
    }
    .uc-select-sm {
        font-size: 0.875rem;
        color: var(--bs-dark)
    }
</style>

<script>
    import CollectionContentCampaign from "@/components/Collections/powerups/CollectionContentCampaign";
    import {mapGetters} from 'vuex'
    export default {
        components: {CollectionContentCampaign},
        props: {
            collectionId: String
        },
        computed: {
            ...mapGetters({
                apiHost: 'apiHost',
                learnUrls: 'learnUrls'
            })
        },
        data() {
            return {
                campaigns: [],
                currentCampaign: null,
                editorIsVisible: false
            }
        },
        watch: {
            collectionId() {
                this.load()
            }
        },
        methods: {
            async load() {
                this.currentCampaign = null;
                if (!this.collectionId) {
                    this.campaigns = [];
                    return;
                }
                let campaignsResponse = await this.$api.get(`/collections/${this.collectionId}/content-campaigns`);
                this.campaigns = campaignsResponse && campaignsResponse.data;
                this.editorIsVisible = false;
            },
            add() {
                this.currentCampaign = null;
                this.showEditor();
            },
            async del(campaign) {
                if (confirm(`Are you sure you want to delete the campaign ${campaign.name}`)) {
                    await this.$api.delete(`/collection-content-campaigns/${campaign.id}`);
                    let campaignsResponse = await this.$api.get(`/collections/${this.collectionId}/content-campaigns`);
                    this.campaigns = campaignsResponse && campaignsResponse.data;
                }
            },
            edit(campaign) {
                this.currentCampaign = campaign;
                this.showEditor();
            },
            showEditor() {
                this.editorIsVisible = true;
            },
            hideEditor() {
                console.log('ContentCampaigns.hideEditor')
                this.editorIsVisible = false;
                this.currentCampaign = false;
                this.load()
            }
        },
        mounted() { this.load() }
    }
</script>
