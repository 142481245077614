<script>
export default {
    data(){
        return {
            alls : [],
            ors : [],
            nots: []
        }
    },
    computed: {
        queryString(){
            return this.queryStringForSet({alls: this.alls, ors : this.ors, nots: this.nots});
        },
    },
    methods : {
        queryStringForSet({alls, ors, nots}){
            var string = '';

            if (alls.length){
                string += " ("+ alls.map(t => {
                    return this.preppedTerm(t);
                }).join(' AND ') +")"
            }

            if (ors.length){
                if (alls.length){
                    string += " AND"
                }
                string += " ("+ ors.map(t => {
                    return this.preppedTerm(t);
                }).join(' OR ') +")"
            }

            if (nots.length){
                if (ors.length || alls.length){
                    string += " NOT"
                }
                string += " ("+ nots.map(t => {
                    return this.preppedTerm(t);
                }).join(' OR ')+")"
            }

            return string.trim();
        },
        preppedTerm(term){
            term = term.toLowerCase().trim();
            /* eslint-disable */
            let regex = new RegExp(/[\(|\)|\~|\!|:|\.|\-|\=\s]/);
            /* eslint-enable */            
            if (regex.test(term)){
                return `"${term}"`;
            }
            return term;
        },
    }
}
</script>
