<template>
    <div>
        <h6>Deactivated Collections in {{currentAccountOverview.name}}</h6>

        <input class="form-control mb-3 input-sm" v-model="deactivatedCollectionsSearchText" placeholder="Search deactivated collections..." />

        <progress-bar v-if="loadingDeactivatedCollections" />
        
        <div class="list-group deactivated-collections small text-muted mb-3">
            <div class="list-group-item d-flex" v-for="collection in paginatedDeactivatedCollections" :key="'deactivated-collection-'+collection.id">
                <span>{{collection.name}}</span>
                <span class="ms-auto">
                <a href="#" aria-label="Reactivate collection" @click.prevent="reactivate(collection)" class="me-3">Reactivate</a>
                <a href="#" aria-lable="Delete collection" @click.prevent="deleteCollection(collection)">Delete</a>
                    
                </span>
            </div>
        </div>

        <paginator :stay-on-first-page="true" :size="20" :items="filteredDeactivatedCollections" @update="paginatedDeactivatedCollections = $event" />
    </div>
</template>

<script>
import Paginator from '@/utilities/Paginator'
import ProgressBar from '@/utilities/ProgressBar';
import {mapGetters} from "vuex";
export default {
    components : {
         Paginator, ProgressBar
    },
    data(){
        return {
            paginatedDeactivatedCollections : [],
            loadingDeactivatedCollections : false,
            deactivatedCollections : [],
            deactivatedCollectionsSearchText : ''            
        }
    },
    watch : {
        currentAccountOverview(){
            this.loadDeactivatedCollections()
        }
    },
    computed : {
        ...mapGetters({
            currentCollection: 'currentCollection',
            currentAccountOverview: 'currentAccountOverview',
            accountCollections : 'collections'
        }),
        filteredDeactivatedCollections(){
            return this.$sort.alphabetize(this.deactivatedCollections.filter(t => {
                if (this.deactivatedCollectionsSearchText){
                    return t.name.toLowerCase().includes(this.deactivatedCollectionsSearchText.toLowerCase());
                }
                return true;
            }))
        }
    },
    mounted(){
        this.loadDeactivatedCollections()
    },
    methods : {
        loadDeactivatedCollections(){
            this.deactivatedCollections = [];
            this.loadingDeactivatedCollections = true;
            this.$api.get('/accounts/'+this.currentAccountOverview.id+'/collections?deactivated=true', {ignoreCache : true}).then(response => {
                let deactivatedUrls = response.data

                if (deactivatedUrls.length == 0) this.loadingDeactivatedCollections = false;

                deactivatedUrls.forEach((url, index) => {
                    this.$api.get(url+"?only=name,id", {ignoreCache : true}).then(response => {
                        this.deactivatedCollections.push(response.data)
                        if(index + 1 == deactivatedUrls.length){
                            this.loadingDeactivatedCollections = false;
                        }
                    }).catch(() => {});
                })
            })
            .catch(() => {});
        },
        async reactivate(collection){
            this.$api.put('/active-collections/'+collection.id);

            await this.$store.dispatch('addCollection', {collection});
            this.deactivatedCollections.splice(this.deactivatedCollections.indexOf(collection), 1);
        },
        push(collection){
            this.deactivatedCollections.push(collection);
        },
        deleteCollection(collection){
            let x = confirm("Are you sure you want to permanently delete this collection?")
            if ( ! x ) return;
            
            this.deactivatedCollections.splice(this.deactivatedCollections.indexOf(collection), 1);

            this.$api.delete('/collections/'+collection.id).catch(async() => {
                await this.$store.dispatch('addCollection', {collection : collection})
            });
            
            if (collection == this.currentCollection){
                if (this.accountCollections.length > 0){
                    this.$store.dispatch("setCurrentCollection", {collection: this.accountCollections[0]});
                } else {
                    this.$store.dispatch("setCurrentCollection", {collection: false})
                }
            }
        }
    }
}
</script>

<style>
</style>
