import {ContentPolicyGetMany} from "@/api/swagger-codegen";

export default {
    "/accounts/insert_id_here/content-policies" : {
        get: [
            {
                id: "test",
                name: "Mocked Content Policy"
            } as ContentPolicyGetMany
        ],
        post: {
            id: "test"
        }
    }
}
