<template>
    <div>
        <div v-if="displayMissing">
            <div v-if="error" class="default-image" :style="{'height' : height}">
                <span></span>
            </div>
        </div>
        <div v-if=" ! error " class="hero-image-container" :style="{'height' : height}">
            <span class="blurred-background" :style="{'backgroundImage' : 'url(\''+url+'\')'}"></span>     
            <span class="hero-image" :style="{'backgroundImage' : 'url(\''+url+'\')'}"></span>
        </div>
    </div>
</template>

<script>
export default {
    props : ['url', 'height', 'displayMissing'],
    data(){
        return {
            error : false,
            image : ''
        }
    },
    watch : {
        url(){
            this.checkImage()
        }
    },
    mounted(){
        this.checkImage()
    },
    methods : {
        checkImage(){

            var img = new Image();
            img.onload = this.good; 
            img.onerror = this.bad;
            img.onabort = this.bad;
            img.src = this.url;
        },
        good(image){
            this.image = image;
            this.error = false;
        },
        bad(error){
            this.error = error;
        }
    }
}
</script>

<style>

.hero-image-container {
    width: 100%;
    background: white;
    position: relative;
    overflow: hidden;
    display: flex;
}

.hero-image-container .blurred-background {
        opacity: 0.2;
}

@supports (filter:blur()) {
    .hero-image-container .blurred-background {
        opacity: 1;
        position: absolute;
        width: 140%;
        height: 140%;
        top: -20%;
        left: -20%;
        background-size: cover;
        background-position: center center;
        filter :blur(2px) saturate(30%) brightness(50%);
        z-index: 1;
    }
}

.hero-image-container .blurred-background {
    position: absolute;
    width: 140%;
    height: 140%;
    top: -20%;
    left: -20%;
    background-size: cover;
    background-position: center center;
    filter :blur(2px) saturate(30%) brightness(50%);
    z-index: 1;
}

.hero-image-container .hero-image {
    background-size: contain;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
}

.default-image {
    background : #4f2c82;
    display: flex;
    align-items: center;
}

.default-image span {
    display:block;
    background-image : url('/src/assets/images/not-found-image-padded.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 80%;
    flex: 1;

}

</style>
