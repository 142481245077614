<script>
import {filterQueryString} from "@/services/RssLinks";
import {Filters} from "@/models/Filters";
import {mapGetters} from "vuex";

const env = process.env;

export default {
    name: "FeedUrls",
    computed: {
        ...mapGetters({
            feedsDomain: 'feedsDomain'
        })
    },
    methods: {
        async feedUrlForFilterView(hasContentType, hasContentId, filterViewId) {
            let filters = !filterViewId ? null : Filters.fromApi(
                await this.$api.get(`/filter-views/${filterViewId}`).then(r => r.data)
            );
            return this.feedUrlForFilters(hasContentType, hasContentId, filters);
        },
        filterQueryString(filters) {
            return filterQueryString(filters, !!filters.favorites, filters.includeAllSources, filters.includeNewSources,
                filters.limit);
        },
        feedUrlForFilters(hasContentType, hasContentId, filters) {
            if (hasContentType) {
                let domain = this.feedsDomain;

                let base = `https://${domain}/suggestions?${hasContentType}=${hasContentId}`;
                let filtersQueryString = filters ? `&${this.filterQueryString(filters)}` : "";

                return base + filtersQueryString;
            }
        },
    }
}
</script>
