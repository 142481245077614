import postTextAgents from "@/components/Settings/Team/PostTextAgents.vue";


let state = {
    postTextAgents: [],
}

const getters = {
    currentPostTextAgents: state => state.postTextAgents,
}

const actions = {
    async loadFilteredPostTextAgents({commit, getters})  {
        let response = await window.$app.api.get(`/accounts/${getters.currentAccountOverview.id}/agents`);
        const filteredAgents = response.data.filter(i => i.deleted === false);
        await commit(mutations.SET_POST_TEXT_AGENTS.name, {postTextAgents: filteredAgents})
        return filteredAgents;
    },
}

const mutations = {
    SET_POST_TEXT_AGENTS: (state, {postTextAgents}) => {
        state.postTextAgents = postTextAgents;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
