<template>
    <div class="ms-4 me-4">
        <designer :type="$route.query.type" :id="$route.params.id"></designer>
    </div>
</template>

<style scoped>
    .clickable {
        cursor: pointer;
    }

    .fa.fa-edit.small {
        font-size: 70%;
    }

    .width-unset {
        width: unset;
        display: inline-block;
    }

    .form-header h1 {
        border-bottom: 1px solid #463179;
        color: #463179;
        padding-bottom: 5px;
        margin-bottom: 20px;
        margin-top: 0px;
        font-size: 1.5em;
    }
    .form-header .form-footer-buttons .btn {
        font-size: 1rem;
    }
</style>

<script>
    import Designer from '@/components/Galleries/Designer'
    export default {
        components: { Designer},
        props:['id'],
        data() {
            return {
            }
        },
        methods: {
            edit() {
                this.editingName = true;
                setTimeout(() => this.$refs.name.focus(), 10)
            }
        }
    }
</script>
