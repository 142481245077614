<template>
    <div class="auth-container">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">Reset Password</h3>

                    <div class="form-group" style="position: relative">
                        <password-field v-model="newPassword" />
                    </div>

                    <div class="d-flex">
                        <button :disabled="networkActivity" @click="updatePassword" class="btn btn-primary">Update Password</button>
                    </div>

                    <div class="alert alert-blue" v-if="networkActivity">
                        {{networkActivity}}
                    </div>

                    <div class="alert alert-danger" v-if="errorMessage">
                        {{errorMessage}}
                    </div>

                </div>
            </div>
    </div>
    <div v-if="!isHearsay">
        <AuthFooter/>
    </div>
</template>

<script>

import PasswordField from '@/components/Auth/PasswordField'
import Base from './Base'
import AuthFooter from "@/components/Auth/AuthFooter.vue";
import {mapGetters} from "vuex";

export default {
    props : ['token'],
    components : {
        AuthFooter,
        PasswordField
    },
    mixins : [
        Base
    ],
    computed: {
        ...mapGetters({
            isHearsay: 'isHearsay',
        }),
    },
}
</script>
