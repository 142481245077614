

<script>
    import {powerups} from "@/Constants";
    import apiStore from '@/store/modules/third-party-apis'
    import {mapGetters} from 'vuex'
    import {OAuthConnection} from "@/services/OAuth";
    const apiActions = apiStore.actions;
    import SubscriptionUsageLoader from '@/components/Subscriptions/SubscriptionUsageLoader';

    export default {
        name: "IntegrationLoader",
        mixins: [SubscriptionUsageLoader],
        data() {
            return {
                p_integrationMetaResults: [],
                resourceUsages: []
            }
        },
        computed: {
            metaIntegrationsEnabled() { return this.features.metaIntegrations },
            ...mapGetters({
                currentAccountOverview: 'currentAccountOverview',
                apis : 'externalApis',
                metaIntegrations: 'apisMeta',
                features: 'accountFeatures',
                vendor: 'vendor',
                afterOauthData: 'afterOauthData',
                isHootsuite: 'isHootsuite',
                shareProvider: 'shareProviderForCurrentAccount',
                shareProviders: 'shareProviders'
            }),
            shutterstockUsage() { return this.resourceUsages.find(x => x.name === 'shutterstock_images')},
        },
        methods: {
            OAuth(name) { return new OAuthConnection(name, this); },
            authorize(integration) {
                let service = this.OAuth(integration.name);
                let iframed = this.vendor === 'hootsuite'
                setTimeout(() => service.connect(this.team, { iframed, afterOauthData: this.afterOauthData }), 5);
            },
            showNewCollection() {
                window.Events.$emit('showCollectionCreationModal');
            },
            async shareProviderChange(val) {
                await Promise.all([
                    this.$store.dispatch('setShareProvider', { account: this.team, shareProvider: val }),
                    this.$api.put(`/accounts/${this.team.id}/share-provider`, { share_provider: val })
                ]);
            },
            loadAccountPowerupSettings(powerup, account) {
                this.$api.get(`/accounts/${account.id}/${powerup}-settings`).then(response => {
                    let data = response && response.data;
                    let token =  data && data.access_token;
                    this.$store.dispatch('setPowerupDataForAccount', {data : token ? {token, ...data} : false, account, powerup});
                }).catch( () => {
                    //No Settings Found
                })
            },
            loadAllIntegrationAccountSettings(account) {
                this.p_integrationMetaResults.then(integrations => [
                    ...integrations.map(i => i.name),
                    ...powerups
                ].forEach(powerup => this.loadAccountPowerupSettings(powerup, account)));
            },
            async loadMetaIntegrations() {
                this.p_integrationMetaResults = this.fetchIntegrations();
                return { p_integrationMetaResults: this.p_integrationMetaResults }
            },
            async fetchIntegrations() {
                let resp = await this.$api.get('/integrations?meta=true');
                let integrations = resp.data;
                await this.$store.dispatch(apiActions.setIntegrationMetaAll.name, { integrations: integrations });
                return integrations;
            },
            reloadIntegrationData() {
                this.loadMetaIntegrations();
                this.loadAllIntegrationAccountSettings(this.currentAccountOverview);
                this.fetchUsageFromServer(this.account).then(data => {
                    this.resourceUsages = data.resources;
                });
            },
            integrationFeatures(integration) {
                let accountInt = this.apis[integration.name][this.account.id];
                let accountFeatures = [
                    ...accountInt.collection_features,
                    accountInt.share_feature,
                    accountInt.image_source_feature
                ]
                let featureMetas = [
                    ...integration.collection_features,
                    integration.share_feature,
                    accountInt.image_source_feature
                ];
                let features = featureMetas.filter(x => x&&x.name).map(f => {
                    let accountFeature = accountFeatures.find(ff => ff.name === f.name)
                    let icon = f.scoped_title.toLowerCase();
                    if (icon === 'email') icon = 'envelope';
                    if (icon === 'website') icon = 'globe';
                    if (icon === 'image library') icon = 'image';

                    return {
                        name: f.name,
                        active: accountFeature.activated,
                        icon: `fa fa-${icon}`,
                        scopedTitle: f.scoped_title,
                        isCollection: f.is_collection,
                        inUse: f.is_share ? this.shareProvider === integration.name : !!f.in_use
                    }
                });
                let used = this.$sort.alphabetize(features.filter(f => f.inUse));
                let active = this.$sort.alphabetize(features.filter(f => f.active && !f.inUse));
                let inactive = this.$sort.alphabetize(features.filter(f => !f.active))
                return [...inactive, ...active, ...used]//.reduce((res, item) => [item, ...res], [])
            },
            makeDefaultShare(integration) {
                this.shareProviderChange(integration.name)
            },
            isConnected(integration) {
                let service = this.OAuth(integration.name);
                return !!service && !!service.isConnected(this.account)
            },
            integrationEnabled(name) {
                return this.features[name]
            },
        }
    }
</script>
