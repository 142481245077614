<template>
    <div>
        <div v-if="currentCard">
            <div class="alert alert-green">
                <p>Success! <small>Enjoy your 14-day free trial.</small></p>
                <button class="btn btn-info" :disabled="networkActivity" @click="completeUpsell">
                    <i class="fa fa-check-circle me-1"></i>
                    Finish creating my topic</button>
                <p v-if="networkActivity">Saving...</p>
            </div>
        </div>
        <div v-else>
            <h4>Upgrade to create more topics </h4>
            <p>You have reached your plan's topic limit. Please select from one of the higher tier plans below to create more topics. </p>

            <p>We're happy to offer a <b class="text-danger">free 14-day trial</b>, so your card won't be charged right away. Cancel anytime 🤓</p>
            
            <div class="alert alert-blue small">
                <i class="fa fa-bell me-1"></i>
                If you aren't ready to upgrade, you can <a href="#" class="fw-semibold" @click.prevent="$emit('replaceExistingTopic')">replace your current topic with {{newTopicName}}</a>.
            </div>

            <div v-if="selectedPlan" id="add-payment-info">
                <h4>Add your payment information</h4>
                <br/>
                <credit-card 
                    class="credit-card-entry-box"
                    :account="account"
                    @cardAdded="cardAdded" 
                    @savingCard="networkActivity = true"
                    @cardError="handleError"
                    button-text="Start free trial" 
                    @focus="badCard = false;"
                />
                <br/>
                <div class="alert alert-danger" v-if="badCard">
                    {{badCard}}
                </div>
            </div>

            <plan-selector 
                :account="account" 
                :starting-group-index="1" 
                :hideFreePlan="true" 
                :hideChangeButton="true" 
                @planSelected="planSelected"
            />

           
        
        </div>

    </div>
</template>

<script>

import PlanSelector from '@/components/Subscriptions/PlanSelector'
import CreditCard from '@/components/Payments/CreditCardEntry'
import CreditCardDisplay from '@/components/Payments/CreditCardDisplay'


export default {
    props : ['account', 'newTopicName'],
    components : {
        PlanSelector, CreditCard, CreditCardDisplay
    },
    data(){
        return {
            networkActivity : false,
            selectedPlan : false,
            currentCard : false,
            badCard: false
        }
    },
    mounted(){

    },
    methods : {
        planSelected(plan){
            this.selectedPlan = plan;
        },
        cardAdded({card}){
            this.currentCard = card;
        },
        handleError({error}){
            this.networkActivity = false;
            if(! error.response){
                this.badCard = "We cannot process your card at this time"
            }

            this.currentCard = false
        },
        completeUpsell(){
            this.networkActivity = true;
            this.setSubscriptionPlan()
            .then(response => {
                this.networkActivity = false;
                let subscription = response.data;
                this.$store.dispatch('addSubscriptionToAccount', {subscription, account: this.account});
                this.$emit('complete');
            })
        },
        setSubscriptionPlan(){
            var planArray = this.selectedPlan.split('_');
            let interval = planArray.pop();
            let pckge = planArray.join('_');
            let request = { package : pckge, interval }

            return this.$api.put(`/accounts/${this.account.id}/subscription`, request)
        }

    }
}
</script>

<style>
</style>
