<template>
    <div>

        <h4>Subscription Plan for {{ account.name }}</h4>
        <div class="alert small" :class="'alert-'+activityMessageType" v-if="networkActivity">
            {{ networkActivity }}
        </div>

        <hr/>

        <div v-if="! networkActivity && !isAppSumo" class="current-plan-container  text-center">
            <div class="text-danger">
                Your Plan
            </div>
            <h4>
                {{ planName }}
                <span v-if="plan.status == 'trialing'">(trial)</span>
            </h4>
            <p>
                <span v-if="plan">
                    Your plan will {{planAction}} on <b>{{planActionDate}}</b> and
                    <span v-if="willBeDowngraded"><br/>you will be downgraded to a free personal account.</span>
                    <span v-else>you'll be charged <b>{{planAmountFormatted}}</b></span>
                </span>
            </p>
            <button @click="showSelector = ! showSelector" class="btn btn-primary">Change or Cancel</button>
        </div>
        <div v-else-if="! networkActivity" class="current-plan-container  text-center">
            <h4 class="text-danger">
                Your Plan
            </h4>
            <h4>
                {{ planName }}
            </h4>
            <button @click="showSelector = ! showSelector" class="btn btn-primary">Switch to Standard {{appName}} Plan
            </button>
            <div class="mt-1">
                <a href="https://appsumo.com/account/products/" class="text-muted">Change or Cancel AppSumo Plan</a>
            </div>
        </div>

        <h4 v-if="!showSelector" class="mt-3">Usage</h4>
        <hr>
        <subscription-usage v-if="!showSelector" v-model="usage"></subscription-usage>

        <div v-if="showCardEntry" class="my-3 credit-card-entry-container">
            <h4 class="text-success">We don't have a credit card on file for you.</h4>
            <small>Please add a card to continue.</small>
            <credit-card-entry
                class="my-3 credit-card-entry-box"
                :account="account"
                @cardError="handleCardError"
                @cardAdded="cardAdded"
            />
        </div>


        <plan-selector
            v-if="showSelector"
            ref="planSelector"
            :account="account"
            @change="fetchPlan"
            :hasCard="hasCardOnFile"
            @askForCard="askForCard"
        />

        <boosters v-if="!showSelector && plan && !networkActivity"
                  :account="account"
                  :subscription="plan"
                  :has-card-on-file="hasCardOnFile"
                  @boosterUpdated="update++">

        </boosters>


    </div>
</template>

<script>

import PlanSelector from '@/components/Subscriptions/PlanSelector'
import CreditCardEntry from '@/components/Payments/CreditCardEntry'
import moment from 'moment'
import Vue from 'vue';
import * as $ from 'jquery'
import SubscriptionUsage from "@/components/Subscriptions/SubscriptionUsage";
import Boosters from "@/components/Subscriptions/Boosters";
import SubscriptionUsageLoader from '@/components/Subscriptions/SubscriptionUsageLoader'
import capitalize from "capitalize";
import {mapGetters} from "vuex";

export default {
    props: ['account'],
    mixins: [SubscriptionUsageLoader],
    data() {
        return {
            networkActivity: false,
            activityMessageType: 'info',
            plan: false,
            hasCardOnFile: false,
            showCardEntry: false,
            showSelector: false,
            update: 0,
            usage: {}
        }
    },
    watch: {
        account() {
            this.fetchPlan()
            this.loadUsagesFromServer();
        },
        update() {
            this.fetchPlan()
            this.loadUsagesFromServer();
        },
    },
    components: {
        Boosters,
        SubscriptionUsage,
        PlanSelector, CreditCardEntry
    },
    mounted() {
        this.fetchPlan();
        this.loadUsagesFromServer()
        this.$intercom.trackEvent('Load subscriptions screen');
    },
    computed: {
        ...mapGetters({
            appName: 'appName'
        }),
        planAmountCents() {
            let planItems = this.plan && this.plan.plan_items || [];
            return planItems.reduce((sum, x) => sum + ((x.amount || 0) * (x.quantity || 1)), 0);
        },
        planAmountFormatted() {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(this.planAmountCents / 100);
        },
        willBeDowngraded() {
            return this.plan.cancel_at_period_end || (this.planAmountCents && !this.hasCardOnFile)
        },
        isAppSumo() {
            let planKey = this.plan && this.plan.plan_key || '';
            return planKey.startsWith('as_')
        },
        planActionDate() {
            if (!this.plan) return ''
            return moment.unix(this.plan.current_period_end).utc().set({'hour': 23, 'minute': 59, 'second': 59}).format("MM/DD/YYYY")
        },
        planAction() {
            if (!this.plan) return ''
            let action = this.plan.status === 'trialing' ? 'start' : 'automatically renew'
            if (this.plan.cancel_at_period_end) {
                action = 'end';
            }
            return action
        },
        planName() {
            if (this.plan) {
                return capitalize(this.plan.plan_name);
            } else {
                return capitalize("Free Forever");
            }
        }
    },
    methods: {
        askForCard() {
            this.showCardEntry = true;
            Vue.nextTick(() => {
                $('html, body').animate({'scrollTop': $('.credit-card-entry-container').offset().top - 40}, 500)
            })
        },
        async fetchPlan() {
            this.showSelector = false;
            this.networkActivity = 'Fetching plan...'
            await this.$api.put('/accounts/' + this.account.id + '/subscription/refresh').catch(console.warn)
            await this.$api.get('/accounts/' + this.account.id + '/subscription')
                .then(resp => {
                    this.networkActivity = 'Fetching payment method...';
                    let subscription = resp.data;
                    if (typeof subscription.plan_items === "string") {
                        subscription.plan_items = JSON.parse(subscription.plan_items)
                    }
                    this.plan = subscription;

                    this.$api.get(`/accounts/${this.account.id}/payment-method`)
                        .then(() => {
                            this.hasCardOnFile = true
                            this.networkActivity = false;
                        }).catch(() => {
                        this.hasCardOnFile = false;
                        this.networkActivity = false;
                    })
                }).catch(e => {
                if (e.response && e.response.status == 404) {
                    this.plan = false;
                    this.networkActivity = false;
                    this.showSelector = true;

                    this.$api.get(`/accounts/${this.account.id}/payment-method`)
                        .then(() => {
                            this.hasCardOnFile = true
                            this.networkActivity = false;
                        }).catch(() => {
                        this.hasCardOnFile = false
                        this.networkActivity = false;
                    })
                }
            });
        },
        handleCardError({error}) {
            this.networkActivity = false;
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        this.noCard = true;
                        break
                    case 422:
                        this.badCard = error.response.data.description
                        break;
                }
            } else {
                this.badCard = "We cannot process your card at this time :("
            }

            this.currentCard = false
        },
        cardAdded() {

            this.showCardEntry = false;
            this.hasCardOnFile = true;
            Vue.nextTick(() => {
                this.$refs.planSelector.requestSubscriptionChange();
            })
        },
        async loadUsagesFromServer() {
            this.usage = await this.fetchUsageFromServer(this.account);
        },
        daysUntil(time) {
            let utcMoment = moment.utc();
            return moment.unix(time).utc().diff(utcMoment, 'days');
        },
    },
}
</script>

<style scoped>
.current-plan-container {
    max-width: 650px;
    margin: 0 auto;
    padding: 15px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2),
    0px 2px 6px 3px rgba(0, 0, 0, 0.1);
    font-size: var(--font-size-13);
}

.current-plan-container h4 {
    font-size: 16px;
}

.current-plan-container .btn {
    font-size: 14px;
    padding: 4px 10px;
}
</style>
