import axios, {AxiosInstance, AxiosInterceptorManager, AxiosPromise, AxiosRequestConfig, AxiosResponse} from "axios"
import apiMocks from "@/api/api-mocks"

class CacheManager {
    private cache: any;

    constructor(){
        this.cache = JSON.parse(localStorage.getItem('requestCache') ?? '[]');
        if (! this.cache){
            this.cache = [];
        }
    }

    store(url, etag){
        let existing = this.cache.find(c => c.url == url);
        if (existing){
            let index = this.cache.indexOf(existing);
            this.cache.splice(index, 1, { url, etag });
        } else {
            this.cache.push({ url, etag })
        }
        localStorage.setItem('requestCache', JSON.stringify(this.cache));
    }

    check(url){
        let existing = this.cache.find(c => c.url == url);
        if (existing){
            return existing.etag
        }
    }

    remove(url){
        let existing = this.cache.find(c => c.url == url);
        if (existing){
            this.cache.splice(this.cache.indexOf(existing), 1);
            localStorage.setItem('requestCache', JSON.stringify(this.cache));
        }
    }

    destroy(){
        this.cache = [];
        localStorage.setItem('requestCache', JSON.stringify([]));
    }
}

function pathMock(url: string) {
    try {
        if (!url) return null;
        let path = url.includes('://') ? new URL(url).pathname : url;
        return apiMocks[path];
    } catch { return null; }
}
function formatMock<T extends {}>(data: T) {
    return {
        data,
        status: 200,
        statusText: 'OK' as any, headers: {} as any, config: {} as any
    }
}

export default class Api {
    private cache: CacheManager;
    private BASE_URL: string;
    private authToken: any;

    constructor(store, base){
        this.cache = new CacheManager();

        const scheme = process.env.VUE_APP_API_SCHEME || 'https';
        this.BASE_URL = `${scheme}://${base}`;

        this.authToken = store.getters.authToken;

        store.subscribe((mutation, payload) => {
            if (mutation.type == 'SET_AUTH_TOKEN'){
                this.authToken = payload.user.auth_token
            }
            if (mutation.type == 'USER_DID_LOG_OUT'){
                this.authToken = false;
                this.bustCache();
            }
        })

    }

    bustCache(){
        this.cache.destroy();
        this.cache = new CacheManager();
    }

    // Typically the calls won't have the base URL in them,
    // but sometimes they will. This will parse that out as well
    // as add the base URL to the front of each request.

    cleanUrl(url){
        url = url.replace(this.BASE_URL, '')
        return this.BASE_URL + url;
    }

    mergeOptions(existing, url?){

        var options = {} as any;

        if (existing){
            for (var key in existing){
                options[key] = existing[key]
            }
        }

        if (this.authToken){
            if ( ! options.headers ){
                options.headers = {}
            }

            options.headers.Authorization = "Bearer " + this.authToken
        }

        let etag = this.cache.check(url)

        if (etag && ! options.ignoreCache){
            options.headers['If-None-Match'] = etag;
            options.headers['Cache-Control'] = 'Nope';
        }

        return options
    }

    async post(url, body?, options?): Promise<any> {
        if (pathMock(url)?.post) return formatMock(pathMock(url).post);
        return axios.post(this.cleanUrl(url), body, this.mergeOptions(options));
    }


    async get(url, options?): Promise<any> {
        if (pathMock(url)?.get) return formatMock(pathMock(url).get);
        if (url === '/collections/845bd24e-7b6c-406c-8fde-215357ec7b04') console.log('get', url)
        let getUrl = this.cleanUrl(url);

        let request = axios.get(getUrl, this.mergeOptions(options, getUrl));
        request.then(response => {
            if (response.headers.etag){
                this.cache.store(getUrl, response.headers.etag)
            }
        }).catch(() => {

        })

        return request;
    }

    async put(url, body?, options?): Promise<any>{
        if (pathMock(url)?.put) return formatMock(pathMock(url).put);
        return axios.put(this.cleanUrl(url), body, this.mergeOptions(options));
    }

    async delete(url, options?): Promise<any> {
        if (pathMock(url)?.delete) return formatMock(pathMock(url).delete);
        return axios.delete(this.cleanUrl(url), this.mergeOptions(options));
    }

    refreshCache(){
        this.cache.destroy();
    }
}

export class ApiInstance implements AxiosInstance {
    public cache: CacheManager;
    public readonly BASE_URL: string;
    public authToken: any;

    constructor(store, base){
        this.cache = new CacheManager();

        const scheme = process.env.VUE_APP_API_SCHEME || 'https';
        this.BASE_URL = `${scheme}://${base}`;

        this.authToken = store.getters.authToken;

        store.subscribe((mutation, payload) => {
            if (mutation.type == 'SET_AUTH_TOKEN'){
                this.authToken = payload.user.auth_token
            }
            if (mutation.type == 'USER_DID_LOG_OUT'){
                this.authToken = false;
                this.bustCache();
            }
        })

    }

    bustCache(){
        this.cache.destroy();
        this.cache = new CacheManager();
    }

    // Typically the calls won't have the base URL in them,
    // but sometimes they will. This will parse that out as well
    // as add the base URL to the front of each request.

    cleanUrl(url){
        url = url.replace(this.BASE_URL, '')
        return this.BASE_URL + url;
    }

    mergeOptions(existing, url?){

        var options = {} as any;

        if (existing){
            for (var key in existing){
                options[key] = existing[key]
            }
        }

        if (this.authToken){
            if ( ! options.headers ){
                options.headers = {}
            }

            options.headers.Authorization = "Bearer " + this.authToken
        }

        let etag = this.cache.check(url)

        if (etag && ! options.ignoreCache){
            options.headers['If-None-Match'] = etag;
            options.headers['Cache-Control'] = 'Nope';
        }

        return options
    }

    refreshCache(){
        this.cache.destroy();
    }

    defaults: AxiosRequestConfig;
    interceptors: { request: AxiosInterceptorManager<AxiosRequestConfig>; response: AxiosInterceptorManager<AxiosResponse> };

    head(url: string, config?: AxiosRequestConfig): AxiosPromise {
        return axios.head(this.cleanUrl(url), this.mergeOptions(config));
    }

    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return axios.patch(this.cleanUrl(url), data, this.mergeOptions(config));
    }

    request<T = any>(config: AxiosRequestConfig): AxiosPromise<T> {
        return axios.request(this.mergeOptions(config));
    }

    async delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        if (pathMock(url)?.delete) return formatMock(pathMock(url).delete);
        return axios.delete(this.cleanUrl(url), this.mergeOptions(config));
    }

    async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        if (pathMock(url)?.get) return formatMock(pathMock(url).get);
        if (url === '/collections/845bd24e-7b6c-406c-8fde-215357ec7b04') console.log('get', url)
        let getUrl = this.cleanUrl(url);

        let request = axios.get(getUrl, this.mergeOptions(config, getUrl));
        request.then(response => {
            if (response.headers.etag){
                this.cache.store(getUrl, response.headers.etag)
            }
        }).catch(() => {

        })

        return request;
    }

    async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        if (pathMock(url)?.post) return formatMock(pathMock(url).post);
        return axios.post(this.cleanUrl(url), data, this.mergeOptions(config));
    }

    async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        if (pathMock(url)?.put) return formatMock(pathMock(url).put);
        return axios.put(this.cleanUrl(url), data, this.mergeOptions(config));
    }
}



