export default function defaultTextStyle(fontSize=100, props) {
    return {
        color: '',
        backgroundColor: '',
        fontSize: fontSize,
        presetFont: '',
        customFont: '',
        bold: false,
        underline: false,
        italics: false,
        allCaps: false,
        alignment: '',
        hideable: false,
        hidden: false,
        inline: null,
        lineHeight: null,
        fixedHeight: null,
        height: 3,
        padding: { top: 0, bottom: 0, left: 0, right: 0},
        margin: { top: 0, bottom: 0, left: 0, right: 0},
        ...(props||{})
    };
}
