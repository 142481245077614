export default {
    install: (Vue, { store }) => {
        Vue.prototype.$plan = {
            plan() {
                if (store && store.state && store.state.overviews && store.state.overviews.overview) {
                    return store.state.overviews.overview.plan
                }
            },
            isNonFree() {
                return this.plan();
            }
        }
    }
}
