<template>
    <div>
        <div class="mb-4">
        <add-content-source-form
            @addSource="addSource"
            :collectionId="collection && collection.id"
            :sources-busy="sourcesBusy"
            ref="addSourceForm"
            :onlyType="onlyType"
        ></add-content-source-form>
        </div>
        <div class="error mb-2">{{ sourceErrorMessage }}</div>
        <div v-if="sourcesBusy">
            <spinner class="list-spinner"></spinner>
        </div>
        <ul v-else class="list-group source-list" style="font-size: 90%">
            <collection-source-display v-bind="source" v-for="source in sources" :key="source.key"
                                       :source-id="source.id"
                                       @filtersUpdated="filtersUpdated($event, source.key)"
                                       :destinationCollection="collection"
                                       @deleteSource="() => deleteSource(source)"
            >
                <!--                               :lastChecked = 'source.lastChecked'
               :lastPublished = 'source.lastPublished'
               :url = 'source.url'
               :added = 'source.added'
               :name = 'source.name'
               :topic-id='source.topicId'
               :rss-feed-id='source.rssFeedId'
               :source-collection-id="source.sourceCollectionId"
               :failure-reason="source.failureReason"-->
            </collection-source-display>
        </ul>
    </div>
</template>

<style>

</style>

<script>
import {CollectionSourceViewModel} from "@/models/CollectionSourceViewModel";
import Spinner from '@/components/Controls/Spinner'
import CollectionSourceDisplay from "./CollectionSourceDisplay";
import AddContentSourceForm from "./AddContentSourceForm";

export default {
    components: {CollectionSourceDisplay, AddContentSourceForm, Spinner},
    props: {
        collection: Object,
        onlyType: {type: String, default: null},
    },
    data() {
        return {
            sourceErrorMessage: '',
            sourcesBusy: false,
            sources: [],
        }
    },
    mounted() {
        this.loadSources()
    },
    watch: {
        collection() {
            this.sources = [];
            this.loadSources()
            this.resetForm()
        }
    },
    methods: {
        async deleteSource(source) {
            if (this.collection && this.collection.id) {
                this.sourcesBusy = true;
                await this.$api.delete(`/collections/${this.collection.id}/sources/${source.id}`);
                await this.loadSources();
                this.sourcesBusy = false;
            } else {
                this.sources = this.sources.filter(s => s.key !== source.key)
            }
        },
        loadSources() {
            // Handle if collection is undefined (NOTE(casio): when creating a new collection?)
            if (!(typeof this.collection !== 'undefined' && this.collection.id)) {
                return Promise.resolve();
            }

            let collectionSources = this.$api.get(`/collections/${this.collection.id}/sources`)
                .then(result => {

                    let sources = result.data.map(CollectionSourceViewModel.fromApi);

                    this.sources = this.onlyType ?  sources.filter(f => f.type === this.onlyType) : sources;

                });

            return collectionSources;
        },
        async addSource({url, type, id, filters, filterViewId}) {
            if (type === 'rss') {
                let isDuplicate = this.sources.some(src => src.url && src.url.toLowerCase() === url.toLowerCase());
                if (isDuplicate) {
                    this.$refs.addSourceForm.setError('Feed already attached to collection');
                    return;
                }
            } else {
                let isDuplicate = this.sources.some(src => src.id === id);
                if (isDuplicate) {
                    this.$refs.addSourceForm.setError(
                        `${this.$strings.capitalize(type)} already attached to collection`
                    );
                    return;
                }
            }

            let vm = CollectionSourceViewModel.fromUi({url, type, id, filters, filterViewId});
            if (this.collection && this.collection.id) {
                await this.showSourceLoader(this.addSourceToServer(this.collection.id, vm).then(this.loadSources));
            } else {
                if (this.sources.length > 10) {
                    this.validate();
                    if (this.errorMessages.length) {
                        alert(
                            "Wow you really like sources, we'd really recommend you save the collection before continuing so you don't lose your work")
                    } else if (confirm(
                        "Wow you really like sources, we're going save your work for you if you don't mind. OK to save, Cancel to make some changes first")) {
                        this.createCollectionToServer();
                    }
                    return;
                }
                this.sources.push(vm);
            }

            this.$refs.addSourceForm.resetForm(false);
        },
        saveSourcesForNewCollection(collection) {
            return Promise.all(this.sources.map(src =>
                this.addSourceToServer(collection.id, src)
            ))
        },
        async showSourceLoader(promise) {
            this.sourcesBusy = true;
            try {
                await promise;
            } finally {
                this.sourcesBusy = false;
            }
        },
        async addSourceToServer(collectionId, vm) {
            try {
                await this.$api.post(`/collections/${collectionId}/sources`, vm.toApi());
            } catch (e) {
                console.log(e)
                if (e.response.status == 409) { // conflict
                    this.$refs.addSourceForm.setError(e.response.data.error);
                } else if (e.response.status == 400) {
                    this.$refs.addSourceForm.setError(e.response.data.error, e.response.data.suggestions || [],
                        e.response.data.recoverable || false);
                }
                throw e
            }
        },
        filtersUpdated({filterViewId, sourceId}, key) {
            let source = this.sources.find(s => s.key !== key)
            if (source) {
                source.filterViewId = filterViewId;
            }
        },
        resetForm() {
            this.$refs.addSourceForm.resetForm(true)
        }
    }
}
</script>
