<template>
    <div class="row">
        <div v-if="!enableMultiSite" class="text-muted small col col-12">Need more than one domain?
            <a href="javascript:void(0)"
               @click.stop.prevent="$intercom.showNewMessage('I\'m interested in more than one domain.')">Get in
                touch.</a></div>
        <div v-if="enableMultiSite" class="mb-4 col col-12 col-lg-3">
            <div class="mb-0 d-flex justify-content-between">
                <h4>Sites</h4>
                <div class="header-buttons">
                    <button @click.stop.prevent="addSite" class="btn btn-primary btn-sm small mb-0" type="button">Add
                    </button>
                </div>
            </div>
            <hr>
            <ul v-if="enableMultiSite" class="list-group">
                <li v-for="s in sites" :key="s.id" :data-x="sites" class="small no-bullets list-group-item">
                    <h5>{{ s.description || s.domain }}</h5>
                    <div class="text-muted">{{ s.description ? s.domain : '' }}</div>
                    <div class="">
                        <!--<button class="btn btn-sm btn-secondary" type="button">Preview</button>-->
                        <button class="btn btn-sm btn-danger" type="button" @click="deactivateSite(s)">Deactivate
                        </button>
                        <button class="btn btn-sm btn-secondary ms-2" type="button" @click="editSite(s)">Select</button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col col-12" :class="{'col-lg-9': enableMultiSite}">

            <h4 v-if="site" class="d-flex justify-content-between align-content-end mb-0">
                <div style="flex-grow: 1">{{
                        site.description ? `${site.description} (${site.domain})` : site.domain
                    }}
                </div>
                <div class="header-buttons">
                    <button class="btn btn-sm btn-primary float-end ms-2 mb-0"
                            type="button"
                            @click="showCode"
                            v-if="currentAccountOverview && accountFeatures && accountFeatures.gallery_builder">Download
                    </button>
                    <button class="btn btn-sm btn-secondary float-end ms-2 mb-0" type="button" @click="editSiteInfo">
                        Edit Info
                    </button>
                    <!--<button class="btn btn-sm btn-danger float-end ms-2 mb-0" type="button" @click="deactivateSite" v-if="account">Delete</button>-->
                </div>
            </h4>
            <hr class="mb-3">

            <ok-cancel ref="deactivateModal"
                       class="deactivate-modal"
                       :title="'Are you sure?'"
                       ok-text="Deactivate"
                       ok-class="btn-danger">
                <p>Are you sure you want to deactivate this Gallery?</p>
                <p>Doing so will cause the <strong>{{ itemToDelete.name || itemToDelete.description }}</strong> Gallery
                    on your site <strong>{{ site.domain }}</strong> to stop working.</p>
            </ok-cancel>

            <ok-cancel ref="deactivateSiteModal"
                       class="deactivate-modal"
                       :title="'Are you sure?'"
                       ok-text="Deactivate"
                       ok-class="btn-danger">
                <p>Are you sure you want to deactivate this Site?</p>
                <p>Doing so will cause the all galleries on your site <strong>{{ siteToDelete.domain }}</strong> to stop
                    working.</p>
            </ok-cancel>
            <modal ref="downloadModal" size="xl">
                <template v-slot:header>
                    <h5>Download Code</h5>
                </template>
                <gallery-download :themes="allThemes" :galleries="galleries"></gallery-download>
                <template v-slot:footer>
                    <button type="button"
                            class="btn btn-secondary float-end"
                            @click="$refs.downloadModal.hide()">Close
                    </button>
                </template>
            </modal>
            <modal size="xl" style="width: 100% !important;" ref="previewModal">
                <template v-slot:header>
                    <h5>Preview</h5>
                </template>
                <iframe :src="previewUrl" style="width: 100%;  height: calc(100vh - 200px);"></iframe>
            </modal>
            <ok-cancel
                ref="siteEditInfoModal"
                :title="`${site.id ? 'Edit' : 'Create'} Site`"
                :ok-text="`${site.id ? 'Save' : 'Create'} Site`"
                :can-close="Boolean(copyThemesFrom && copyThemesFrom.card && copyThemesFrom.carousel)"
            >
                <site-editor v-model="siteInfo">
                </site-editor>
                <copy-theme v-if="!site.id && sites && sites.length" v-model="copyThemesFrom" :sites="sites">

                </copy-theme>
            </ok-cancel>
            <div>
            <h4>Galleries</h4>
                <div class="small text-muted" style="font-size: small">Set the Collection (and related rules) to manage
                    which articles appear on your site.
                </div>

            </div>
            <div class="alert alert-green d-flex justify-content-between" v-if="!accountFeatures.gallery_builder">
                <div>
                    The plan for this account does not include Galleries.
                    <router-link to="/settings#subscription">Upgrade</router-link>
                    to create a Gallery.
                </div>
                <router-link class="btn btn-primary btn-sm" to="/settings#subscription">Upgrade</router-link>
            </div>
            <table class="table table-striped table-condensed d-none d-lg-table mb-5 table-sm"
                   v-if="currentAccountOverview && accountFeatures && accountFeatures.gallery_builder || (galleries && galleries.length)">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Collections</th>
                    <th>
                        <router-link :to="`/galleries/create?siteId=${site.id}`"
                                     v-if="currentAccountOverview && accountFeatures && accountFeatures.gallery_builder"
                                     @click.stop.prevent="createGallery"
                                     class="float-end">
                            <button ref="addGalleryBtn" class="btn btn-primary btn-sm">Add</button>
                        </router-link>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="g in galleries" :key="g.id" :data-x="galleries">
                    <td>{{ g.name }}</td>
                    <td>{{ $strings.capitalizeWords(g.type.replace('-', ' ')) }}</td>
                    <td>{{ formatCollectionsDisplay(g) }}</td>
                    <td>
                        <!--<router-link to="/galleries/create" class="float-end">-->

                        <!--</router-link>-->
                        <router-link :to="`/galleries/edit/${g.id}`" class="float-end ms-2">
                            <button class="btn btn-sm btn-primary" type="button">Edit</button>
                        </router-link>
                        <button @click="preview(g)" class="btn btn-sm btn-secondary float-end ms-2" type="button">
                            Preview
                        </button>
                        <button class="btn btn-sm btn-danger float-end ms-2" @click="deactivate(g)" type="button">
                            Deactivate
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="row">
                <section class="col col-12 col-lg-6">
                    <div class="d-flex justify-content-between ">
                        <div>
                            <h4>Carousel Themes</h4>
                            <div class="small text-muted" style="font-size: small; max-width: 200em;">Adjust the look
                                and feel of Carousels on your site
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-primary btn-sm me-1 mb-1" type="button" @click="addCarouselTheme">
                                Add
                            </button>
                        </div>
                    </div>
                    <table class="table table-striped table-condensed d-none d-lg-table table-sm">
                        <tbody>
                        <tr v-for="g in carouselThemes" :key="g.id">
                            <td>{{ g.name }}</td>
                            <td>
                                <router-link :to="`/gallery-theme-designer/${g.id||'a'}?type=carousel`"
                                             class="float-end ms-2">
                                    <button class="btn btn-sm btn-primary" type="button">Edit</button>
                                </router-link>
                                <button type="button"
                                        class="btn btn-sm btn-secondary float-end ms-2"
                                        @click="copyCarouselTheme(g)">Copy
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>
                <section class="col col-12 col-lg-6">
                    <div class="d-flex justify-content-between ">
                        <div class="d-inline">
                            <h4>Card Themes</h4>
                            <div class="small text-muted" style="font-size: small">Adjust the look and feel of Cards on
                                your site
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-primary btn-sm float-end me-1 mb-1"
                                    type="button"
                                    @click="addCardTheme">Add
                            </button>
                        </div>
                    </div>
                    <table class="table table-striped table-condensed d-none d-lg-table table-sm">
                        <tbody>
                        <tr v-for="g in cardThemes" :key="g.id">
                            <td>{{ g.name }}</td>
                            <td>
                                <router-link :to="`/gallery-theme-designer/${g.id||'a'}?type=card`"
                                             class="float-end ms-2">
                                    <button class="btn btn-sm btn-primary" type="button">Edit</button>
                                </router-link>
                                <button type="button"
                                        class="btn btn-sm btn-secondary float-end ms-2"
                                        @click="copyCardTheme(g)">Copy
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    </div>
</template>

<style scoped>
.deactivate-modal strong,
.delete-modal strong {
    color: var(--bs-danger);
}

.sites-table th {
    border-top: none;
}

.header-buttons {
    font-size: 1rem;
}
</style>

<script>
import GalleryPurchase from './GalleryPurchase'
import Modal from '@/components/Controls/Modal'
import {mapGetters} from 'vuex'
import SiteEditor from "@/components/Galleries/SiteEditor";
import OkCancel from "@/components/Controls/OkCancel";
import {newTheme, upgradeTheme} from "@/models/themes";
import {styleSheet} from "@/services/JsStylesToCss";
import {generateJsStyles} from "@/services/GalleryJsStyles";
import Toggle from "@/components/Controls/Toggle/Toggle";
import ToggleItem from "@/components/Controls/Toggle/ToggleItem";
import * as toastr from "toastr";
import CopyTheme from "@/components/Galleries/ThemeCopy";
import GalleryBase from './GalleryBase'
import GalleryDownload from "@/components/Galleries/GalleryDownload";

export default {
    components: {
        GalleryDownload,
        GalleryBase, CopyTheme, ToggleItem, Toggle, OkCancel, SiteEditor, GalleryPurchase, Modal
    },
    mixins: [GalleryBase],
    data() {
        return {
            itemToDelete: {},
            siteToDelete: {},
            cardThemes: [],
            carouselThemes: [],
            galleries: [],
            sites: [],
            site: {},
            previewUrl: 'javascript:void(0)',
            siteInfo: {},
            copyThemesFrom: {card: 'default', carousel: 'default'}
        }
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            accountFeatures: 'accountFeatures',
            lastLoadedGallerySite: 'currentGallerySiteId',
            subscriptionLimits: 'subscriptionLimits'
        }),
        enableMultiSite() {
            return (this.sites && this.sites.length > 1) || this.subscriptionLimits.gallery_sites > 1
        },
        allThemes() {
            return [...this.carouselThemes, ...this.cardThemes]
        }
    },
    async mounted() {
        this.initialLoad()
    },
    watch: {
        currentAccountOverview() {
            this.initialLoad()
        }
    },
    methods: {
        addSite() {
            if (this.subscriptionLimits.gallery_sites <= this.sites.length) {
                if (confirm("You are out of sites would you like to purchase another?")) {
                    this.$router.push('/settings#subscription')
                }
                return;
            }
            this.site = {id: '', domain: '', staging_domain: '', description: ''};
            this.editSiteInfo();
        },
        async initialLoad() {
            console.log('Loading gallery sites', {loadLast: this.lastLoadedGallerySite, sites: this.sites})
            try {
                if (this.currentAccountOverview) {
                    await this.loadSites();
                    if (this.sites && this.sites.length) {

                        console.log('Select gallery site', {loadLast: this.lastLoadedGallerySite, sites: this.sites})
                        let lastSiteLoaded = this.lastLoadedGallerySite &&
                            this.sites.find(s => s.id === this.lastLoadedGallerySite);
                        console.log('Last loaded gallery site', {lastSiteLoaded})
                        if (lastSiteLoaded)
                            await this.editSite(lastSiteLoaded)
                        else
                            await this.editSite(this.sites[0])
                    } else {
                        await this.editSiteInfo()
                    }
                }
            } catch (e) {
                console.error(e)
                if (e.response && e.response.status === 403) {
                    alert(
                        'You are not authorized to administer Galleries for this account.  Editing Galleries requires editor or higher permissions on the account.')
                    this.$router.push('/')
                } else {
                    toastr.error('Failed to load site');
                    this.$router.push('/')
                }
            }
        },
        showCode() {
            if (this.galleries && this.galleries.length) {
                this.$refs.addGalleryBtn.setCustomValidity('');
                this.$refs.downloadModal.show();
            } else {
                this.$refs.addGalleryBtn.focus();
                this.$refs.addGalleryBtn.setCustomValidity(
                    "Let us know how you'd like articles to appear by creating a gallery");
                this.$refs.addGalleryBtn.reportValidity();
            }
        },
        async editSiteInfo() {
            this.siteInfo = {...(this.site || {id: ''})};
            await this.$refs.siteEditInfoModal.awaitResult().then(async ({close}) => {
                try {
                    window.Events.$emit('startPageLoad')
                    if (this.site.id) {
                        await this.$api.put(`/galleries/sites/${this.site.id}`, this.siteInfo);
                    } else {
                        let resp = await this.$api.post(`/accounts/${this.currentAccountOverview.id}/galleries/sites`, this.siteInfo);
                        this.site = {id: resp.data.id}
                    }
                    await this.loadSites();
                    await this.editSite(this.sites.find(s => s.id = this.site.id));
                    close();
                } finally {
                    window.Events.$emit('finishPageLoad')
                }
            });
            this.copyThemesFrom = {card: 'default', carousel: 'default'}
        },
        deactivate(g) {
            if (!g) return;
            this.itemToDelete = g;
            console.log(this.$refs.deactivateModal);
            this.$refs.deactivateModal.awaitResult().then(async ({close, setLoading}) => {
                setLoading(true);
                await this.$api.delete(`/galleries/${g.id}`);
                setLoading(false);
                close()
                this.loadGalleries();
            })
            // this.$refs.deactivateModal.show();
        },
        deactivateSite(s) {
            if (!s) return;
            this.siteToDelete = s;
            console.log(this.$refs.deactivateSiteModal);
            this.$refs.deactivateSiteModal.awaitResult().then(async ({close, setLoading}) => {
                setLoading(true);
                await this.$api.delete(`/galleries/sites/${s.id}`);
                setLoading(false);
                close()
                this.loadSites();
            })
        },

        async loadSites() {
            let resp = await this.$api.get(`/accounts/${this.currentAccountOverview.id}/galleries/sites`);
            this.sites = resp.data;
        },

        async loadGalleries() {
            let resp = await this.$api.get(`/galleries/sites/${this.site.id}/galleries`);
            this.galleries = resp.data;
        },
        async addCardTheme() {
            let name = prompt('Enter theme name');
            if (!name) return;
            window.Events.$emit('startPageLoad');
            let resp = await this.createTheme('card', name);
            window.Events.$emit('finishPageLoad');
            this.goToTheme('card', resp.data.id);
            // this.cardThemes = await this.$api.get(`/galleries/sites/${this.site.id}/themes?type=card`).then(resp => resp.data)
        },
        async addCarouselTheme() {
            let name = prompt('Enter theme name');
            if (!name) return;
            window.Events.$emit('startPageLoad');
            let resp = await this.createTheme('carousel', name);
            window.Events.$emit('finishPageLoad');
            this.goToTheme('carousel', resp.data.id);
            // this.carouselThemes = await this.$api.get(`/galleries/sites/${this.site.id}/themes?type=carousel`).then(resp => resp.data)
        },
        async copyCardTheme(theme) {
            let name = prompt('Enter theme name');
            if (!name) return;
            let resp = await this.copyTheme('card', name, theme);
            this.goToTheme('card', resp.data.id);
            // this.cardThemes = await this.$api.get(`/galleries/sites/${this.site.id}/themes?type=card`).then(resp => resp.data)
        },
        async copyCarouselTheme(theme) {
            let name = prompt('Enter theme name');
            if (!name) return;
            let resp = await this.copyTheme('carousel', name, theme);
            this.goToTheme('carousel', resp.data.id);
            // this.carouselThemes = await this.$api.get(`/galleries/sites/${this.site.id}/themes?type=carousel`).then(resp => resp.data)
        },
        async createThemeRecord(type, name) {
            return await this.$api.post(`/galleries/sites/${this.site.id}/themes`, {name, type,});
        },
        async goToTheme(type, id) {
            this.$router.push(`/gallery-theme-designer/${id || 'new'}?type=${type}`)
        },
        async getDefaultThemeData(type) {
            let themeDataResp = await this.$api.get(`/galleries/themes/templates/${type}/default`);
            let themeData = (themeDataResp && themeDataResp.data && themeDataResp.data.styles) || newTheme(type);
            return themeData;
        },
        async getTheme(id) {
            let resp = await this.$api.get(`/galleries/themes/${id}`)
            return resp && resp.data;
        },
        async getTemplateThemeData(type) {
            let id = this.copyThemesFrom[type] || 'default';
            let themeDataResp = await this.$api.get(`/galleries/themes/templates/${type}/${id}`);
            let themeData = (themeDataResp && themeDataResp.data && themeDataResp.data.styles) || newTheme(type);
            return themeData;
        },
        async createTheme(type, name) {
            let resp = await this.createThemeRecord(type, name);
            let themeData = await this.getTemplateThemeData(type);
            await this.copyThemeData(type, themeData, resp.data.id);
            return resp;
        },
        async copyTheme(type, name, theme) {
            window.Events.$emit('startPageLoad');
            let resp = await this.createThemeRecord(type, name);
            theme = await this.getTheme(theme.id);
            let themeData = theme && theme.styles;
            await this.copyThemeData(type, themeData, resp.data.id);
            window.Events.$emit('finishPageLoad');
            return resp;
        },
        async copyThemeData(type, themeData, id) {
            await this.$api.put(`/galleries/themes/${id}/styles`, {
                data: themeData,
                stylesheet: styleSheet({
                    themeKey: id || 'default-theme',
                    layoutType: type,
                    pageContainerId: ''
                }, generateJsStyles(type, upgradeTheme(type, themeData)))
            });
            await this.$api.put(`/galleries/themes/${id}/staging`);
            await this.$api.put(`/galleries/themes/${id}/production`);
        },
        async loadThemes() {
            let [cards, carousels] = await Promise.all([
                this.$api.get(`/galleries/sites/${this.site.id}/themes?type=card`),
                this.$api.get(`/galleries/sites/${this.site.id}/themes?type=carousel`)
            ]).then(_ => _.map(resp => resp.data));

            if (!cards || !cards.length) {
                await this.createTheme('card', 'Default (Cards)');
                cards = await this.$api.get(`/galleries/sites/${this.site.id}/themes?type=card`).then(resp => resp.data)
            }

            if (!carousels || !carousels.length) {
                this.createTheme('carousel', 'Default (Carousels)')
                carousels =
                    await this.$api.get(`/galleries/sites/${this.site.id}/themes?type=carousel`).then(resp => resp.data)
            }

            this.cardThemes = cards;
            this.carouselThemes = carousels;
        },

        deleteGallery(g) {
            confirm(`Are you sure you want to delete the Gallery ${g.name}? This cannot be undone.`)
        },
        createGallery() {
            this.$router.push(`/galleries/create?siteId=${this.site.id}`)
        },
        edit(gallery) {
            this.$router.push(`/galleries/edit/${gallery.id}`)
        },
        editTheme() {

        },
        async editSite(site) {
            this.site = site;
            await Promise.all([
                this.loadGalleries(),
                this.loadThemes()
            ]);
            this.$store.dispatch('setCurrentGallerySite', {siteId: site.id})
        },
        preview(gallery) {
            this.previewUrl = this.iframeUrl(gallery);
            this.$refs.previewModal.show()
        },
        showPurchase() {
            this.$refs.purchase.show();
        },
        downloadOrPurchase(g) {
            if (g.isPurchased) {
                download('fake-gallery.txt', '');
            } else {
                this.showPurchase();
            }
        },
        deleteOrDeactivate(g) {
            if (g.isPurchased) {
                this.deactivate(g);
            } else {
                this.deleteGallery(g);
            }
        },
    }
}

function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}
</script>
