<template>
    <div class="powerups-list">
        <input type="search" class="form-control" v-model="searchText" placeholder="Search"/>
        <div class="mb-2 d-flex justify-content-between flex-wrap">
            <button v-for="x in searchTerms" type="button" class="btn btn-link font-size-11 p-0 me-2" @click="searchText=x.search" :key="x.search">
                {{ x.display }}
            </button>
        </div>
        <div class="list-group">
            <div class="list-group-item list-group-header">
                Active on Collection
            </div>
            <powerup-list-item v-for="i in active" :key="i.name" v-bind="i" @change="$emit('change', i)" v-show="powerupSearchMatches(i)"></powerup-list-item>
            <div class="list-group-item  list-group-header">
                Authenticated for Account
            </div>
            <powerup-list-item v-for="i in authenticated" :key="i.name" v-bind="i" @change="$emit('change', i)" v-show="powerupSearchMatches(i)"></powerup-list-item>
            <div class="list-group-item list-group-header">
                Additional Integrations
            </div>
            <powerup-list-item v-for="i in rest" :key="i.name" v-bind="i" @change="$emit('change', i)" v-show="powerupSearchMatches(i)"></powerup-list-item>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getIconClass} from "../../../../services/Icons";
import PowerupListItem from "../../V2/PowerupListItem.vue";
export default {
    name: "PowerupsList",
    components: {PowerupListItem},
    computed: {
        ...mapGetters({
            allIntegrations: 'apisMeta',
            powerups: 'collectionPowerups',
            powerupSearchTerms: 'powerupSearchTerms',
            powerupNameMap: 'powerupNameMap'
        }),
        filtered() {
            return this.powerups.filter(x => x)
        },
        active() {
            return this.$sort.alphabetize(this.powerups.filter(x => x.active), 'title')
        },
        authenticated() {
            return this.$sort.alphabetize(this.powerups.filter(x => !x.active && x.isAuthenticated), 'title')
        },
        rest() {
            return this.$sort.alphabetize(this.powerups.filter(x => !x.active && !x.isAuthenticated), 'title')
        }
    },
    methods: {
        powerupSearchMatches(i) {
            let searchableTerms = this.powerupSearchTerms[this.powerupNameMap[i.name]];
            if (!this.searchText || !searchableTerms) return true;
            let searchTerms = this.searchText.split(' ');
            searchableTerms = searchableTerms.split(' ');
            return searchTerms.every(
                term => searchableTerms.some(word => word.toLowerCase().includes(term.toLowerCase())));
        },
    },
    data() {
        return {
            searchText: ''
        }
    },
    created() {
        this.searchTerms = [
            { search: 'social', display: 'Social'},
            { search: 'email', display: 'Email'},
            { search: 'cta', display: 'CTA'},
            { search: 'employee advocacy', display: 'Employee Advocacy'},
            { search: 'quality', display: 'Content Quality'},
        ]
    }
}
</script>

<style scoped>
.powerups-list {
    min-width: 250px;
    width: 380px;
    max-width: 400px;
    max-height: 100% !important;
    overflow-y: auto;
}
.search-badge-button {
    border-radius: 1em;
    font-weight: var(--font-weight-semibold);
    padding: 0 1em;
    color: var(--primary);
    margin-right: .1em !important;
    margin-top: .5em;
    font-size: var(--font-size-11);
}
.list-group-header {
    font-weight: var(--font-weight-semibold);
    background: var(--water-33);
    color: var(--primary-25);
    font-size: var(--font-size-12);
    padding-bottom: .5em;
}
</style>
