export const psuedoExample = {
    status: ['error', 'aborted', 'chose_to_cancel', 'retained'],
    salvageAttemptResult: {
        decision: ['accepted', 'rejected'],
        resolution: ['accepted', 'rejected'],
        hasErrors: false,
    },
    salvageOfferResult: {
        decision: ['accepted', 'rejected'],
        hasErrors: false
    },
    additionalFeedback: 'string',
    cancelReason: 'string',
    satisfactionInsight: 'string',
    salvageAttemptIntended: ['pause_subscription', 'plan_switch', 'contact_support_email_notification',
        'contact_support_meeting_scheduler'],
    salvageAttemptUsed: ['pause_subscription', 'plan_switch', 'contact_support_email_notification',
        'contact_support_meeting_scheduler', null]
}

export const profitwellFauxResults = [
    {
        status: 'retained',
        salvageAttemptResult: {
            decision: 'accepted',
            resolution: 'accepted',
            hasErrors: false,
        },
        salvageOfferResult: {
            decision: 'accepted',
            hasErrors: false
        },
        additionalFeedback: 'string',
        cancelReason: 'dont use very much',
        satisfactionInsight: '',
        salvageAttemptIntended: 'plan_switch',
        salvageAttemptUsed: 'plan_switch',
    },
    {
        status: 'retained',
        salvageAttemptResult: {
            decision: 'accepted',
            resolution: 'accepted',
            hasErrors: false,
        },
        salvageOfferResult: {
            decision: 'accepted',
            hasErrors: false
        },
        additionalFeedback: 'string',
        cancelReason: 'too expensive',
        satisfactionInsight: '',
        salvageAttemptIntended: 'plan_switch',
        salvageAttemptUsed: 'plan_switch',
    },
    {
        status: 'chose_to_cancel',
        salvageAttemptResult: {
            decision: 'rejected',
            resolution: 'rejected',
            hasErrors: false,
        },
        salvageOfferResult: {
            decision: 'rejected',
            hasErrors: false
        },
        additionalFeedback: '',
        cancelReason: 'string',
        satisfactionInsight: '',
        salvageAttemptIntended: 'plan_switch',
        salvageAttemptUsed: null
    },
    {
        status: 'error',
        salvageAttemptResult: {
            decision: 'accepted',
            resolution: 'accepted',
            hasErrors: false,
        },
        salvageOfferResult: {
            decision: 'rejected',
            hasErrors: false
        },
        additionalFeedback: 'string',
        cancelReason: 'string',
        satisfactionInsight: 'string',
        salvageAttemptIntended: 'contact_support_meeting_scheduler',
        salvageAttemptUsed: null
    },
    {
        status: 'aborted',
        salvageAttemptResult: {
            decision: 'accepted',
            resolution: 'rejected',
            hasErrors: false,
        },
        salvageOfferResult: {
            decision: 'rejected',
            hasErrors: false
        },
        additionalFeedback: 'string',
        cancelReason: 'string',
        satisfactionInsight: 'string',
        salvageAttemptIntended: 'pause_subscription',
        salvageAttemptUsed: null
    },
]
