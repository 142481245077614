<template>
    <div>
        <div v-show="!editorIsVisible || editSelectedEvent">
            <uc-select v-model="selected"
                       class="input-group-prepend m-0 p-0 flex-grow"
                       :items="options"
                       :use-big-mode="useBigMode"
                       :required="required"
                       :placeholder="placeholder"
            >
            </uc-select>
            <div class="btn-group">
                <button class="btn btn-sm btn-link"
                        type="button"
                        @click="createNew()">
                    <i class="fa fa-plus me-1"></i>
                    Create New
                </button>
                <button class="btn btn-sm btn-link" type="button" @click="edit()" v-if="value">
                    <i class="fa fa-edit me-1"></i>
                    Edit
                </button>
                <slot name="actionsAndStatus"></slot>
            </div>
        </div>
        <valdiator :value="selected" :required="required"></valdiator>
        <div>
            <slot v-if="editorIsVisible">

            </slot>
        </div>
    </div>
</template>

<script>
import UcSelect from "@/components/Controls/UpcontentSelect";
import {mapGetters} from 'vuex';
import Valdiator from "@/components/Controls/Valdiator";
import NestableModal from "./NestableModal";

export default {
    components: {NestableModal, Valdiator, UcSelect},
    props: {
        useBigMode: {type: Boolean, default: false},
        value: String,
        items: Array,
        editSelectedEvent: String,
        editSelectedTarget: String,
        editProps: Object,
        required: {type: Boolean, default: false},
        map: Function,
        placeholder: String
    },
    data() {
        return {
            editorIsVisible: false,
            selected: this.value || null,
            current: this.getItem(this.value) || {}
        };
    },
    watch: {
        current() {
            if (this.current && this.getVal(this.current) && this.getVal(this.current) !== this.selected) {
                this.selected = this.getVal(this.current)
            }
        },
        selected() {
            if (this.items) {
                this.current = this.getItem(this.selected) || {};
                this.editorIsVisible = false;
                this.$emit('input', this.selected)
            }
        },
        value() {
            this.editorIsVisible = false;
            this.current = this.getItem(this.value) || {};
            this.selected = this.value;
        }
    },
    computed: {
        options() {
            return this.items && this.items.map(x => this.map(x))
        },
        ...mapGetters({}),
    },
    methods: {
        getItem(val) {
            return this.items.find(x => this.getVal(x) === val)
        },
        getVal(item) {
            return this.map(item).value
        },
        reset() {
            Object.assign(this, {
                editorIsVisible: false,
                selected: this.value || null,
                current: this.getItem(this.value) || {},
                items: [],
            });
        },
        createNew() {
            this.editorIsVisible = true;
            if (this.editSelectedEvent) {
                console.log('show_' + this.editSelectedEvent,
                    {target: this.editSelectedTarget, value: null, ...this.editProps})
                window.Events.$once('save_' + this.editSelectedEvent, result => {
                    this.selected = result;
                    this.$emit('saved', result)
                })
                window.Events.$emit('show_' + this.editSelectedEvent,
                    {target: this.editSelectedTarget, value: null, ...this.editProps})
            } else {
                this.editorIsVisible = true;
                this.current = {};
                this.$emit('edit', this.current)
            }
        },
        edit() {
            if (this.editSelectedEvent) {
                console.log('show_' + this.editSelectedEvent,
                    {target: this.editSelectedTarget, value: this.value, ...this.editProps})
                window.Events.$once('save_' + this.editSelectedEvent, result => {
                    this.selected = result;
                    this.$emit('saved', result)
                })
                window.Events.$emit('show_' + this.editSelectedEvent,
                    {target: this.editSelectedTarget, value: this.value, ...this.editProps})
            } else {
                this.editorIsVisible = true;
                this.current = this.getItem(this.selected);
                this.$emit('edit', this.current)
            }
        },
        closeEdit() {
            console.log('EditableSelect.closeEdit')
            this.editorIsVisible = false;
        },
        save() {
            console.log('EditableSelect.save')
            this.$emit('save');
            this.editorIsVisible = false;
        }
    },

}
</script>
