<template>
    <label class="icon-checkbox-label">
        <input type="checkbox"  aria-label="link-horizontal" v-model="v">
        <span role="presentation" aria-hidden="true" class="btn btn-link btn-sm"><i :class="`fa ${iconClass}`"></i></span>
    </label>
</template>

<style scoped>
    .icon-checkbox-label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        text-shadow: none !important;
        height: 1em;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
    }

    .btn { color: #9e9e9e; }
    input:checked~.btn { color: var(--bs-primary); }
</style>

<script>
    export default {
        name: 'icon-checkbox',
        props: {value:{type: Boolean}, iconClass: {type: String}},
        data() { return {v: this.value}},
        watch: {
            value() { if (this.v !== this.value) this.v = this.value;},
            v() { this.$emit('input', this.v)}
        }
    }
</script>
