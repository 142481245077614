<template>
    <div>
        <div>
            <h4 class="subtitle">Content Type</h4>
            <select @change="update()" class="form-select form-select-sm" v-model="contentType">
                <option
                        v-for="thisType in availableContentTypes" :key="'content-types-'+thisType"
                        :value="thisType">
                    {{thisType}}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

import Base from '../SuggestionFilters/FilterMixin'
import {mapGetters} from 'vuex'

export default {
    mixins : [Base],
    computed: {
        ...mapGetters({
            features: 'features'
        }),
        availableContentTypes() {
            return  [
                'All',
                'News',
                'Blog',
                ...(this.features.otherContentType? [
                    'Other',
                    'All (including other)'
                ] : [])
            ]
        }
    },
    watch : {
        filters(){
            if (this.filters)
                this.contentType = this.filters.contentType
        }
    },
    data(){
        return {
            contentType: '',
        }
    },
    mounted(){
        if (this.filters)
            this.contentType = this.filters.contentType
    },
    methods : {
        update(){
            this.updateFilter('contentType', this.contentType);
        }
    }
}
</script>

<style>
</style>
