<template>
    <div>
        <div
            class="card-header text-white c-pointer"
            :class="{
                'bg-primary' : currentAccount.id === team.id,
                'bg-secondary' : currentAccount.id !== team.id
            }"
            @click="$emit('setCurrentAccountOverview', team)"
        >
            <h3 class="card-title">{{team.name}} Settings</h3>
        </div>
        <div class="list-group list-group-flush" v-if="currentAccount.id === team.id">
            <a href="#"
               @click.prevent="setShowing('team-subscription-plan')"
               class="list-group-item" :key="team.name+'-subscription-plan'"
               v-if="isAdminOnly && !hideSubscription">
                <i class="fa fa-clipboard"></i> Subscription Plan
            </a>
            <a href="#"
               @click.prevent="setShowing('team-payment-method')"
               class="list-group-item" :key="team.name+'payment-method'"
               v-if="isAdminOnly && !hideSubscription">
                <i class="fa fa-credit-card"></i> Payment Method
            </a>
            <a v-if="isAdminOrManager && !hideIntegrations" href="#" @click.prevent="setShowing('integrations')" class="list-group-item" :key="team.name+'integrations'">
                <i class="fa fa-bolt"></i> Integrations
            </a>
            <a v-if="isAdminOrManager && !hideIntegrations && features.openaiIntegration" href="#" @click.prevent="setShowing('post-text-agents')" class="list-group-item" :key="team.name+'post-text-agents'">
                <i class="fa fa-language"></i> AI Post Text Generation
            </a>
            <a v-if="isAdminOrManager && !hideIntegrations && !features.contentPolicyEnabled" href="#" @click.prevent="setShowing('quality-checks')" class="list-group-item" :key="team.name+'quality-checks'">
                <i class="fa fa-check-circle-o"></i> Quality Checks
            </a>
            <a v-if="isAdminOrManager && features.contentPolicyEnabled" href="#" @click.prevent="setShowing('content-quality-page')" class="list-group-item" :key="team.name+'content-policies'">
                <i class="fa fa-check-circle-o"></i> Content Quality
            </a>
            <a v-if="isAdminOnly" href="#" class="list-group-item" @click="setShowing('team-profile')">
                <i class="fa fa-gear"></i> Additional Settings
            </a>
            <a v-if="isAdminOnly && !isPrimaryAccount" href="#" @click.prevent="$emit('deleteAccount', team)" class="list-group-item small text-danger" :key="team.id+'-delete'">
                <i class="fa fa-times"></i> Delete Account
            </a>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Vue from 'vue';

export default {
    props : ['team', 'currentAccount'],
    data(){
        return {
            isRenaming : false,
            newName : this.team.name
        }
    },
    watch : {
        isRenaming(){
            if (this.isRenaming){
                Vue.nextTick(() => {
                    this.$refs.accountNameField.select();
                }, 300)
            }
        }
    },
    computed : {
        ...mapGetters({
            currentUser : 'currentUser',
            hideSubscription: 'hideSubscription',
            hideIntegrations: 'hideIntegrations',
            features: 'accountFeatures',
        }),
        isPrimaryAccount(){
            return this.currentUser.default_account.includes(this.team.id);
        },
        isAdminOrManager(){
            return this.team.role === 'manager' || this.team.role === 'administrator';

        },
        isAdminOnly() {
            return this.team.role === 'administrator';
        }
    },
    methods : {
        setShowing(showing){
            this.$emit('setEditor', showing);
        }
    }
}
</script>

<style>
</style>
