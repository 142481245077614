<template>
    <details :open="authorized" :class="{powerup:true, hide: !show}">
        <summary @click.prevent>
            <div class="power-up-label d-flex justify-content-between align-items-center mb-2">
                <div class="text-nowrap">
                    <i :class="iconClass" class="me-1"/>
                    <span>{{ label }}</span>
                </div>
                <div v-if="!disableReason" class="powerup-action">
                    <connect-button v-if="!authorized"
                                    :service="service"
                                    :team="team"
                                    :beforeAuth="beforeAuth"></connect-button>
                </div>
            </div>
            <div class="text-muted small">
                <div class="mb-1">{{ description }}</div>
                <a @click.stop :href="learnUrls.mailchimp" v-if="learnUrls.mailchimp" target="_blank">Learn More</a>
            </div>
            <span class="error powerup-message" v-if="disableReason">{{ disableReason }}</span>

        </summary>
        <div :class="{hide: !(authorized && ! networkActivity)}">
            <button v-if="!showRssForm"
                    type="button"
                    class="btn btn-primary full-width-button"
                    @click.prevent="onShowRssForm">
                Create Mailchimp Email Campaign
            </button>
            <form @submit.prevent="finish" v-show="!loading">
                <scheduler v-if="showRssForm" v-model="scheduleData">
                </scheduler>
                <fieldset v-if="showRssForm">
                    <legend>Campaign Settings</legend>
                    <label>
                        Name
                        <input type="text" v-model="name" class="form-control form-control-sm" required/>
                    </label>
                    <label>
                        Subject
                        <input type="text" v-model="subject" class="form-control form-control-sm" required/>
                    </label>
                    <label>
                        List/Audience
                        <select class="form-control form-select form-select-sm"
                                v-model="selectedListId"
                                required>
                            <option v-for="item in lists" :key="item.id" :value="item.id">
                                {{ item.name }} ({{ item.stats.member_count }} Members)
                            </option>
                        </select>
                    </label>
                </fieldset>
                <fieldset v-show="showRssForm">
                    <legend>Collection Filter Settings</legend>
                    <select-filter-view
                        v-model="filterViewId"
                        has-content-type="collection"
                        :has-content-id="collection.id"
                        class="mb-2"
                        ref="filterViewEdit"
                    >
                    </select-filter-view>
                </fieldset>
                <button id="mailchimp-email-rss-finish"
                        type="submit"
                        :class="{hide: !showRssForm}"
                        class="btn btn-primary"
                        ref="finishButton">
                    Finish in Mailchimp
                </button>
            </form>
            <spinner class="" v-if="loadingCampaigns ||loading"></spinner>
            <div v-if="existingEmails&&existingEmails.length" class="mt-4 card">
                <h6 class="card-header" v-if="existingEmails&&existingEmails.length">Mailchimp Digests using
                                                                                     this Collection</h6>
                <ul class="ps-0 mb-0 list-group" v-if="!loading">
                    <li v-for="email in existingEmails" :key="email.id" class="list-group-item">
                        <a :href="mailchimpEditLink(email.web_id)" target="_blank">{{ email.settings.title }}
                                                                                   (Edit in Mailchimp)</a>
                        <div class="text-muted small" v-if="schedule(email)">{{ schedule(email) }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </details>
</template>

<script>
import {mapGetters} from 'vuex';
import ConnectButton from '@/components/Settings/Team/ConnectDisconnectButton'
import {OAuthConnection} from "@/services/OAuth";
import Scheduler from "@/components/Controls/Scheduler";
import UpcontentSelect from '@/components/Controls/UpcontentSelect'
import moment from 'moment';
import Spinner from "@/components/Controls/Spinner";
import {MailchimpConnection} from "@/services/Mailchimp";
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";
import FeedUrls from "@/utilities/FeedUrls";

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default {
    components: {
        Spinner,
        Scheduler,
        ConnectButton,
        UcSelect: UpcontentSelect,
        SelectFilterView
    },
    mixins: [FeedUrls],
    props: {
        collection: Object,
        team: Object,
        collectionName: String,
        beforeAuth: Function,
        dashboards: Array,
        dashboardsLoaded: Boolean,
        disableReason: String,
        //rssLink: String,
        visible: {type: Boolean, default: null},
        showRssForm: Boolean,
        createParent: Function
    },
    data() {
        return {
            open: this.authorized,
            iconClass: 'mailchimp-icon icon-dynamic',
            networkActivity: false,
            label: "Mailchimp Email",
            description: "Engage your audience with email digests.",
            learnMoreUrl: "http://learn.upcontent.com/en/articles/4449656-use-mailchimp-to-craft-a-curated-email-digest",
            existingEmails: [],
            lists: [],
            templates: [],
            selectedListId: 116,
            selectedTemplateId: null,
            templateHtml: this.defaultTemplateHtml,
            name: this.collection && this.collection.name || '',
            subject: "What We've Been Reading...",
            showCustomTemplate: false,
            loading: 0,
            loadingCampaigns: false,
            scheduleData: {
                frequency: 'monthly',
                dayOfWeek: 1,
                dayOfMonth: 1,
                daysToSend: [...Array(7).keys()].map(x => x + 1),
                time: '11:00',
                hour: 11,
                timeType: '24 Hour'
            },
            filterViewId: null,
            rssLink: null,
        };
    },
    watch: {
        showRssForm() {
            // setTimeout(() => document.getElementById('mailchimp-email-rss-finish').scrollIntoView(), 100)
        },
        team() {
            // this.open = this.val || this.authorized;
            if (this.authorized) {
                this.loadOptions();
                this.getEmails();
            }
        },
        async collection() {
            if (this.authorized) {
                await this.loadOptions();
                await this.getEmails();
                this.rssLink = await this.getRssLink();
            }
            await this.reset();
        },
        async filterViewId() {
            this.rssLink = await this.getRssLink();
        }
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            apis: 'externalApis',
            features: 'features',
            isHootsuite: 'isHootsuite',
            learnUrls: 'learnUrls'
        }),
        defaultTemplateHtml() {
            return `<span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif">*|FEEDBLOCK:${this.rssLink}|* *|FEEDITEMS:[$count=5]|*</span>\n` +
                '\n' +
                '<h2 class="mc-toc-title"><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"><a href="*|FEEDITEM:URL|*" target="_blank"><span style="color:#008000">*|FEEDITEM:TITLE|*</span></a></span></h2>\n' +
                '\n' +
                '<table border="0" cellpadding="0" cellspacing="0">\n' +
                '\t<tbody>\n' +
                '\t\t<tr>\n' +
                '\t\t\t<td><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"><em>*|FEEDITEM:AUTHOR|*</em></span></td>\n' +
                '\t\t\t<td style="text-align: right;"><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"><em>*|FEEDITEM:DATE:F d|*</em></span></td>\n' +
                '\t\t</tr>\n' +
                '\t\t<tr>\n' +
                '\t\t\t<td colspan="2"><br />\n' +
                '\t\t\t<span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif">*|feedITEM:CONTENT|*</span><br />\n' +
                '\t\t\t&nbsp;</td>\n' +
                '\t\t</tr>\n' +
                '\t</tbody>\n' +
                '</table>\n' +
                '<span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif"> *|END:FEEDITEMS|**|END:FEEDBLOCK|*</span>'
        },
        mailchimpAccountInfo() {
            return this.currentAccountOverview && this.apis && this.apis.mailchimp &&
                this.apis.mailchimp[this.currentAccountOverview.id]
        },
        dc() {
            return this.mailchimpAccountInfo && this.mailchimpAccountInfo.dc
        },
        mailchimpAccountData() {
            return this.apis && this.team && this.team.id && this.apis['mailchimp'] &&
                this.apis['mailchimp'][this.team.id];
        },
        service() {
            return new OAuthConnection('mailchimp', this)
        },
        show() {
            return this.features.mailchimpEmail && (this.visible !== false);
        },
        authorized() {
            return !!this.service && !!this.service.isConnected(this.team) && this.feature.activated
        },
        feature() {
            return this.mailchimpAccountData
                && this.mailchimpAccountData.collection_features
                && this.mailchimpAccountData.collection_features.find(f => f.name.includes('email'))
        },
        ready() {
            return this.service
        },
        list() {
            return this.lists.find(x => x.id === this.selectedListId);
        },
        template() {
            return this.lists.find(x => x.id === this.selectedTemplateId);
        }
    },
    methods: {
        dayDisplay: function (dailySendObj) {
            let days = Object
                .entries(dailySendObj)
                .filter(([k, v]) => v && k)
                .map(([k,]) => this.$strings.capitalize(k))
            let dayDisplay = days.map(day => day.substring(0, 3)).join(', ');
            return dayDisplay;
        },
        schedule(email) {
            if (!email.rss_opts) return '';
            let rss = email.rss_opts;
            let frequency = rss.frequency;
            let detail =
                frequency === 'daily' ? this.dayDisplay(rss.schedule.daily_send || {}) || '' :
                    frequency === 'weekly' ? this.$strings.capitalize(rss.schedule.weekly_send_day) || '' :
                        frequency === 'monthly' ?
                            moment().month('september').date(rss.schedule.monthly_send_date).format('Do') ||
                            '' : '';
            return `${this.$strings.capitalize(frequency || '')} (${detail})`
        },
        status(email) {
            return email.currentState &&
                this.$strings.capitalize(
                    email.currentState.toLowerCase().replace(/_/g, ' ').replace('Rss ', ''))
        },
        onShowRssForm() {
            this.$emit('showRssFormChange', true);
            this.reset();
        },
        getDailySend: function (selectedDays) {
            return selectedDays.reduce((result, day) => ({
                ...result,
                [days[day - 1]]: true
            }), days.reduce((r, x) => ({...r, [x]: false}), {}));
        },
        async finish() {
            if (!(this.collection && this.collection.id)) {
                await this.createParent();
            }
            let endpoint = `/collections/${this.collection.id}/integrations/mailchimp/actions/create-rss-campaign`;
            let frequency = this.scheduleData.frequency;

            // if (this.selectedTemplateId === 'new') {
            //     let templateCreateEP = `/collections/${this.collection.id}/integrations/mailchimp/actions/create-template`;
            //     let params = {
            //         name: `${this.name} Template`,
            //         html: this.templateHtml
            //     };
            //     console.log(params)
            //     let templateResp = await this.$api.post(templateCreateEP, params);
            //     this.selectedTemplateId = templateResp.data.id;
            // }
            this.loading++;
            try {
                let resp = await this.$api.post(endpoint, {
                    type: 'rss',
                    content: {
                        html: ''
                    },
                    settings: {
                        title: this.name,
                        subject_line: this.subject,
                        from_name: this.list && this.list.from_name,
                        reply_to: this.list && this.list.reply_to,
                        template_id: this.selectedTemplateId
                    },
                    recipients: {
                        list_id: this.selectedListId
                    },
                    rss_opts: {
                        feed_url: this.rssLink,
                        frequency,
                        schedule: {
                            weekly_send_day:
                                frequency === 'weekly' ? days[this.scheduleData.dayOfWeek - 1] : undefined,
                            monthly_send_date: frequency === 'monthly' ? this.scheduleData.dayOfMonth :
                                undefined,
                            daily_send:
                                frequency === 'daily' ? this.getDailySend(this.scheduleData.daysToSend) :
                                    frequency === 'weekly' ?
                                        this.getDailySend([this.scheduleData.dayOfWeek]) :
                                        this.getDailySend([1]),
                            hour: parseInt(this.scheduleData.time.split(':')[0])
                        },
                        constrain_rss_img: true
                    }
                });
                window.open(this.mailchimpEditLink(resp.data.web_id), '_blank')
                this.getEmails();
            } catch {
                alert('There was an error saving to mailchimp')
            } finally {
                this.loading--;
                this.$emit('showRssFormChange', false);
            }
        },
        mailchimpEditLink(id) {
            return `https://${this.dc || 'us18'}.admin.mailchimp.com/campaigns/wizard/neapolitan?id=${id}` // TODO: set the dc
        },
        async getLists() {
            if (this.team && this.team.id && this.collection && this.collection.id) {
                let endpoint = `/accounts/${this.team.id}/integrations/mailchimp/resources/lists`;
                let query = 'count=100';

                await this.$api.get(endpoint + '?' + query).then(resp => {
                    if (resp && resp.data) {
                        this.lists = resp.data.lists;
                        this.selectedListId = this.lists[0].id;
                    }
                })
            }
        },
        async getTemplate() {
            if (!this.team || !this.team.id) return;
            try {
                this.loading++;
                this.selectedTemplateId =
                    await new MailchimpConnection(this).ensureRssTemplateExists(this.team.id);
            } finally {
                this.loading--;
            }

        },
        // async getTemplates() {
        //     if (this.team && this.team.id && this.collection && this.collection.id) {
        //         let endpoint = `/accounts/${this.team.id}/integrations/mailchimp/resources/templates`;
        //         let query = 'count=100';
        //
        //         let baseUrl = `${endpoint}?${query}&type=base`;
        //         let userUrl = `${endpoint}?${query}&type=user`;
        //         let results = await Promise.all([
        //             this.$api.get(baseUrl),
        //             this.$api.get(userUrl)
        //         ])
        //
        //         let allTemplates = results.flatMap(resp => resp && resp.data && [...resp.data.templates]);
        //         this.templates = allTemplates
        //             .filter(t => t.type==='user' || t.name.startsWith('1 Column'))
        //             .filter(t => !t.drag_and_drop)
        //     }
        // },
        async getEmails() {
            if (this.team && this.team.id && this.collection && this.collection.id) {
                this.loadingCampaigns = true;
                let emailEndpoint = `/accounts/${this.team.id}/integrations/mailchimp/resources/campaigns`;// TODO: for some reason ?type=rss isn't working
                let query = 'count=100';

                try {
                    await this.$api.get(emailEndpoint + '?' + query).then(resp => {
                        if (resp && resp.data) {
                            let emails = resp.data.campaigns
                            let rssEmails = emails.filter(x => x.rss_opts && x.type === 'rss');
                            let relatedEmails = rssEmails.filter(x =>
                                x.rss_opts.feed_url && x.rss_opts.feed_url.includes(this.collection.id))
                            this.existingEmails = relatedEmails;
                        }
                    })
                } finally {
                    this.loadingCampaigns = false;
                }

            }
        },
        reset() {
            this.name = this.collection && this.collection.name || '';
            this.subject = "What We've Been Reading...";
            // this.selectedTemplateId = 116;
            this.selectedListId = this.lists[0] && this.lists[0].id;
            this.scheduleData = {
                frequency: 'monthly',
                dayOfWeek: 1,
                dayOfMonth: 1,
                daysToSend: [...Array(7).keys()].map(x => x + 1),
                time: '11:00'
            };
            this.showCustomTemplate = false;
            this.templateHtml = this.defaultTemplateHtml;
        },
        async loadOptions() {
            try {
                this.loading++;
                await Promise.all([
                    this.getLists(),
                    // this.getTemplates()
                ]);
                this.loading--;
            } catch {
                this.loading--;
            }
        },
        async getRssLink() {
            if (this.collection) {
                return await this.feedUrlForFilterView('collection', this.collection.id, this.filterViewId);
            }

            return null;
        }
    },
    mounted() {
        if (this.authorized) {
            this.loadOptions();
            this.getEmails();
            this.getTemplate();
        }
        this.reset();
    }
}
</script>
<style>
.powerup legend {
    font-size: 16px;
    margin-bottom: 5px;
}

.powerup fieldset {
    margin: 10px 0;
}
</style>

<style scoped>
.card > .list-group {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin: -1px;
}

.full-width-button {
    width: 100%;
}

.power-up-label .icon-dynamic {
    vertical-align: top;
    margin-top: .2em;
}

.power-up-label .amplify-icon, .power-up-label .sniply-icon {
    margin-top: .25em;
}

.power-up-label {
    display: inline-block;
}

.power-up-label a {
    text-decoration: underline;
}

.powerup-action {
}

.powerup summary {
    background: var(--bs-light);
    border-bottom: 1px solid var(--bs-default);
    list-style: none;
    list-style-type: none;
    color: var(--bs-dark);
    position: relative;
}

details.powerup summary::-webkit-details-marker {
    display: none;
}

.powerup {
    border: 1px solid var(--bs-default);
}

.powerup[open] {
    padding-bottom: 20px;
}


.powerup[open] summary {
    margin-bottom: 10px;
}

.hide {
    display: none;
}

.powerup:not(:last-child) {
    border-bottom: none;
}

.powerup-message {
    display: inline-block;
    color: var(--bs-danger);
    font-size: 0.7em;
    max-width: 360px;
    margin-left: 10px;
    vertical-align: middle;
    white-space: pre;
}

.powerup summary {
    vertical-align: middle;
}

.uc-select-sm {
    font-size: 0.875rem;
    color: var(--bs-dark)
}
</style>
