<template>
    <div class="list-group no-borders">
        <div class="list-group-item" v-if="changingPassword">
            <div class="row">
                <div class="col-sm-2">
                    Current Password
                </div>
                <div class="col-sm-10">

                    <input
                        v-if="changingPassword"
                        @focus="startedTypingPassword"
                        type="password"
                        class="form-control"
                        v-model="currentPassword"
                        ref="passwordField"
                    />
                    <span v-else>
                        ••••••••••••
                        <button @click="changePassword" class="btn btn-primary btn-sm float-end">Change</button>
                    </span>
                </div>
            </div>
        </div>
        <div class="list-group-item">
            <div class="row">
                <div class="col-sm-2">
                    <div v-if="changingPassword">
                        New Password
                    </div>
                    <span v-else>
                        Password
                    </span>
                </div>
                <div class="col-sm-10">

                    <div v-if="changingPassword">
                        <password-field @enterPressed="save" v-model="password"></password-field>
                        <br/>
                        <div class="alert alert-danger" v-if="passwordError">
                            {{ passwordError }}
                        </div>
                        <button v-else @click="save" :disabled=" ! passwordCanBeSaved" class="btn btn-success"
                                :class="{'disabled': !passwordCanBeSaved}">Save Password
                        </button>
                    </div>

                    <span v-else>
                        ••••••••••••
                        <button @click="changePassword" class="btn btn-primary btn-sm float-end">Change</button>
                    </span>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

import zxcvbn from 'zxcvbn'

import PasswordField from '@/components/Auth/PasswordField'

export default {
    props: [],
    data() {
        return {
            currentPassword: '',
            originalPassword: 'thisisjustjibberish',
            password: '',
            passwordError: '',
            changingPassword: false,
            passwordStrength: [],
            networkActivity: false
        }
    },
    components: {
        PasswordField
    },
    computed: {
        passwordScore() {
            return zxcvbn(this.password).score;
        },
        saveButtonClass() {
            if (!this.passwordCanBeSaved) {
                return "btn-default disabled"
            }
            return "btn-primary";
        },
        passwordCanBeSaved() {
            let badPassword = !this.currentPassword ||
                !this.password ||
                this.passwordScore < 2
                || this.networkActivity;

            return !(badPassword)
        },
    },

    mounted() {

    },
    methods: {
        save() {
            if (!this.passwordCanBeSaved) {
                return
            }

            if (!this.password) {
                this.cancel();
                return;
            }

            this.networkActivity = true;

            this.saveNewPasswordToApi().then(() => {
                this.cancel();
                this.networkActivity = false;

                this.password = '';
                this.currentPassword = '';
            })
            .catch((e) => {
                this.networkActivity = false;
                if (e.response){
                    console.log(e.response)
                    if (e.response.status == 400 || e.response.status == 401){
                        if (e.response.data.parameter === "old_password")
                            this.passwordError = 'The Current Password you entered is incorrect';
                        else
                            this.passwordError = e.response.data.description
                    }
                }
            });
        },
        startedTypingPassword() {
            this.passwordError = '';
        },
        cancel() {
            this.changingPassword = false;
            this.currentPassword = '';
        },
        changePassword() {
            this.changingPassword = true;
            Vue.nextTick(() => {
                this.$refs.passwordField.focus()
            })
        },
        saveNewPasswordToApi() {
            return this.$api.put('/password', {
                old_password: this.currentPassword,
                new_password: this.password
            })
        },
    }
}
</script>


<style scoped>
.btn-success.disabled {
    color: #fff;
}
.btn-success.disabled:hover {
    cursor: not-allowed;
}
</style>
