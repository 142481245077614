<template>
    <modal-no-x ref="modal" size="large" class="add-url-modal" v-if="currentCollection" :can-close="true">
                <template v-slot:header>
                    <h5 class="modal-title d-flex text-nowrap">
                        <span  class="d-md-none font-size-17">Add article to collection via URL</span>
                        <span class="text-truncate d-none d-md-block" :title="currentCollection.name">
                            Add Article to {{ currentCollection.name }} via URL
                        </span>
                    </h5>
                </template>
                <div>
                    <div v-if="networkError" class="mt-3">
                        <div class="alert alert-blue">
                            <strong>NETWORK ERROR:</strong> There was an error in trying to fetch the article add the
                            URL provided.
                        </div>
                    </div>

                    <edit-selection v-if="accountFeatures.customSelectionImageText && suggestion"
                                    ref="editSelection"
                                    :selection="suggestion"
                                    :auto-edit-mode="true"
                    ></edit-selection>
                    <template v-else>
                        <p  class="d-md-none">
                            Enter a URL to add to <span class="font-italic">{{ currentCollection.name }}</span>.
                        </p>
                    <input ref="urlEntry" class="form-control" placeholder="Enter URL here" v-model="url"/>
                    </template>
                    <progress-bar class="my-3" v-if="networkActivity"/>

                    <div class="alert alert-blue" v-if="networkActivity">{{ networkActivity }}</div>
                </div>
                <template v-slot:footer>

                    <button type="button" class="btn btn-secondary btn-sm" @click="cancel">Cancel</button>
                    <button type="button"
                            v-if="!suggestion"
                            class="ms-auto btn btn-primary"
                            :disabled="networkActivity"
                            @click="fetchUrlContents">Check URL
                    </button>
                    <button type="button"
                            v-if="suggestion"
                            class="ms-auto btn btn-primary"
                            :disabled="networkActivity"
                            @click="addToCollection">Add To Collection
                    </button>
                </template>
    </modal-no-x>
</template>

<script>
import * as $ from 'jquery';
import {mapGetters} from 'vuex';
import Vue from 'vue';
import ProgressBar from '@/utilities/ProgressBar'
import EditSelection from "@/components/Collections/EditSelection";
import {knownTags} from "@/Constants";
import RouteContext from '@/utilities/RouteContext'
import ModalNoX from "@/components/Controls/ModalNoX.vue";
import {ContentPoliciesService} from "@/services/contentPolicies";

export default {
    components: {
        ModalNoX,
        EditSelection,
        ProgressBar,
    },
    mixins: [RouteContext],
    props: {
        canClose: {type: Boolean, default: true},
    },
    data() {
        return {
            url: '',
            suggestion: false,
            networkError: false,
            suggestionUrl: false,
            tasks: [],
            selectionUrl: false,
            isShouldNotAddToCollection: false,
            collectionContentPolicies: null,
        }
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            collections: 'collections',
            suggestions: 'suggestionsCache',
            accountFeatures: 'accountFeatures',
            features: 'features',
        }),
        tags() {
            return this.suggestion.tags.map(t => {
                if (knownTags[t.key]) {
                    return Object.assign({}, t, knownTags[t.key])
                } else return t;
            })
        },
        networkActivity() {
            return this.tasks[0] || false;
        }
    },
    mounted() {
        window.Events.$on('showAddUrlToCollectionModal', url => {
            this.url = url || '';
            this.show();
        })

        this.$refs.modal.$el.addEventListener('shown.bs.modal', () => {
            Vue.nextTick(() => {
                $(this.$refs.urlEntry).focus();
            })
        })

        this.$refs.modal.$el.addEventListener('hidden.bs.modal', () => {
            this.url = '';
            this.suggestion = false;
            this.selectionUrl = false;
            this.suggestionUrl = '';
            this.networkError = false;
        })
    },
    methods: {
        cancel() {
            if (!this.suggestion ||!this.suggestion.id) {
                this.dismiss()
            } else {
                this.suggestion = false;
                this.$api.delete(this.suggestionUrl);
                this.suggestionUrl = false;
                this.selectionUrl = false;
            }
        },
        fetchUrlContents() {
            this.tasks = [
                "Fetching contents of URL from publisher...",
                "Processing results and formatting preview...."
            ];
            this.networkError = false;

            this.$api.post(`/collections/${this.currentCollection.id}/previews`, {url: this.url})
                .then(async response => {
                    this.tasks = this.tasks.filter(x => x !== "Fetching contents of URL from publisher...")
                    let suggestionUrl = response.data.suggestion;
                    let selectionUrl = response.data.selection;
                    await Promise.all([
                        this.loadSelection(suggestionUrl, selectionUrl),
                        this.qualityCheck(suggestionUrl, selectionUrl)
                    ]);
                    this.tasks = [];


                })
                .catch(e => {

                    this.suggestion = {
                        title: "",
                        summary: "",
                        publisher: "",
                        date_published: null,
                        url: this.url,
                        image_url: "",
                    };

                    this.tasks = [];
                    this.networkError = e.response.data.description;
                })


        },
        async qualityCheck(suggestionUrl, selectionUrl, dontReset = false) {
            this.tasks.push('Checking your organization\'s content policy...')
            let qualityChecks = await this.$api.get(`/collections/${this.currentCollection.id}/quality-checks`)
                .then(r => r.data)
            this.tasks = this.tasks.filter(x => x !== 'Checking your organization\'s content policy...')
            if (qualityChecks && qualityChecks.length) {
                this.tasks.push('Checking content against your organization\'s content policy...')
                await this.$api.post(selectionUrl + '/quality-check');
                await this.loadSelection(suggestionUrl, selectionUrl, dontReset);
                this.tasks =
                    this.tasks.filter(x => x !== 'Checking content against your organization\'s content policy...')
            }
        },
        async getCollectionContentPolicy(collectionId) {
            return await this.$apis.contentPolicy.collectionsCollectionIdContentPolicyGet(collectionId).then(resp => resp.data)
        },
        async contentPolicies(selection) {
            this.isShouldNotAddToCollection = false;
            this.collectionContentPolicies = await this.getCollectionContentPolicy(this.currentCollection.id);

            if(!selection.id && this.collectionContentPolicies.length > 0) {
                this.isShouldNotAddToCollection = true;
                alert('This collection has a content policy on it, and we were unable to crawl content to verify if it passes the policy')
                return;
            }

            if(this.collectionContentPolicies && this.collectionContentPolicies.length) {
                const mandatoryPolicy = this.collectionContentPolicies.find(p => p.enforcement_level === 'MANDATORY');
                const collectionMandatoryContentPolicyId = mandatoryPolicy.policy_id;
                const policyService = new ContentPoliciesService(window.$app.apis);
                const contentPolicyValidation = await policyService.checkOne(collectionMandatoryContentPolicyId, {contentItemId: selection.id});
                console.log('* Result for checking if content policy failed', contentPolicyValidation)
                if(contentPolicyValidation.failed_content_policy) {
                    const currentContentPolicyId = contentPolicyValidation.policy_id;
                    const contentPolicyForMessage = await this.$store.dispatch('loadContentPolicy', currentContentPolicyId);
                    this.isShouldNotAddToCollection = true;
                    await policyService.warnOne(selection, this.currentCollection, contentPolicyForMessage);
                }
            }
        },
        async loadSelection(suggestionUrl, selectionUrl, dontReset = false) {
            await this.$api.get(suggestionUrl).then(response => {
                response.data.dontReset = dontReset;
                this.suggestion = response.data;
                this.suggestionUrl = suggestionUrl;
                this.selectionUrl = selectionUrl;
            })
            this.tasks = this.tasks.filter(x => x !== "Processing results and formatting preview....")
        },
        show() {
            this.tasks = [];
            if (this.$refs.modal) {
                this.$refs.modal.show();
            }
        },
        dismiss() {
            this.tasks = [];
            if(this.$refs.editSelection) {
                this.$refs.editSelection.resetArticleEdit();
            }
            this.$refs.modal.hide();
        },
        async addToCollection() {
            await this.contentPolicies(this.suggestion);

            if(this.isShouldNotAddToCollection) {
                this.cancel()
                this.$refs.modal.hide();
                return; // short circuit early so article isn't added
            }
            this.tasks = ['Saving...']
            let selection = this.suggestion;

            if (!selection.id) {
                let suggestionUrl = {};
                let selectionUrl = {};

                await this.$api.post(`/collections/${this.currentCollection.id}/selections`,
                    this.$refs.editSelection.getInputData()).then(response => {
                    suggestionUrl = response.data.suggestion;
                    selectionUrl = response.data.selection;
                    this.tasks = ['Saving......'];
                });
                await Promise.all([
                    this.loadSelection(suggestionUrl, selectionUrl, true),
                    this.qualityCheck(suggestionUrl, selectionUrl, true)
                ]).then(() => {
                    selection = this.suggestion;
                    this.$refs.editSelection.selection.id = selection.id;
                    this.tasks = ['Saving....'];
                });
            }

            if (this.accountFeatures.customSelectionImageText) {
                selection = await this.$refs.editSelection.uploadAndSave();
            }

            await this.$api.post(this.selectionUrl, {quality_check: 'complete', generate_post_text: true});
            let selectionNew = await this.$api.get(this.selectionUrl).then(r => r.data);
            selectionNew.dontReset = true;
            selection = {...selection, ...selectionNew};

            this.$store.dispatch('addUrlToSuggestions', {url: this.suggestionUrl});
            this.$store.dispatch('addSuggestionToCache', {suggestion: selection});
            this.$store.dispatch('addSourceForSuggestion',
                {source: this.suggestion.publisher, hasSuggestionsId: this.currentCollection.id});
            this.tasks = []
            this.dismiss();

        }
    },
}
</script>

<style lang="scss">
@media (min-width: 576px) {
    .add-url-modal .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }
}
.modal-title {
    width: calc(100% - 1em); // Reduce by width of X close button on modal title bar.
}
</style>
