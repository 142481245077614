<template>
    <div class="d-flex powerups-config">
        <powerups-list @change="currentPowerup = $event" class=""></powerups-list>
        <powerup-config :powerup="currentPowerup" class="flex-grow"></powerup-config>
    </div>
</template>

<script>
import PowerupsList from "./PowerupsList.vue";
import PowerupConfig from "./PowerupConfig.vue";
export default {
    name: "PowerupsV3",
    components: {PowerupConfig, PowerupsList},
    data() {
        return {
            currentPowerup: null
        }
    }
}
</script>

<style scoped>
    .powerups-config {
        max-height: 600px;
    }

</style>
