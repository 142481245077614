<template>
    <div class="scheduler-main gap-2 justify-content-between d-flex flex-wrap">
            <label class="flex-grow"  :class="{'full-width': frequency ==='daily'}">
                Frequency
                <select v-model="frequency" class="form-control form-select form-select-sm" :class="{'full-width': frequency ==='daily'}" required>
                    <option v-for="option in frequencyOptions" :key="option" :value="option">
                        {{ $strings.capitalize(option) }}
                    </option>
                </select>
            </label>
            <label v-if="frequency==='weekly'" class="flex-grow">
                Day of Week
                <select v-model="dayOfWeek" class="form-control form-select form-select-sm" required>
                    <option v-for="day in daysOfWeek" :key="day.num" :value="day.num">
                        {{day.description}}
                    </option>
                </select>
            </label>
            <label v-if="frequency==='monthly'">
                Day of Month
                <select v-model="dayOfMonth" class="form-control form-select form-select-sm" required>
                    <option :value="0">Last Day of Month</option>
                    <option v-for="n in daysOfMonth" :key="n" :value="n">{{n}}</option>
                </select>
            </label>
            <label v-if="frequency==='daily'" class="flex-grow flex-shrink-0 full-width">
                Days to Send
                <ul class="no-bullets justify-content-between check-list-h ps-0 d-flex gap-2 flex-wrap flex-grow">
                    <li v-for="day in daysOfWeek" :key="day.num">
                        <highlight-checkbox v-model="daysToSend" :value="day.num" class="p-1 small">
                            {{day.description}}
                        </highlight-checkbox>
                    </li>
                </ul>
            </label>
            <label class="flex-grow">
                Time
                <input type="time" v-model="time" class="form-control form-control-sm" step="3600" required/>
            </label>
        </div>
</template>

<script>
    import moment from 'moment'
    import HighlightCheckbox from "./HighlightCheckbox";
    const frequencyOptions = ["daily", "weekly", "monthly"]
    export default {
        name: "Scheduler",
        components: {HighlightCheckbox},
        props: ['value', 'intHourTime'],
        data() {
            return {
                frequencyOptions,
                frequency: this.value&&this.value.frequency || "monthly",
                dayOfWeek: this.value&&this.value.dayOfWeek || 1,
                dayOfMonth: this.value&&this.value.dayOfMonth || 1,
                time: this.value&&this.value.time || '',
                daysOfWeek: [...Array(7).keys()].map(x => ({
                    num: x + 1,
                    description: moment().day(x).format('dddd')
                })),
                daysOfMonth: [...Array(31).keys()].map(x => x + 1),
                daysToSend: [...Array(7).keys()].map(x => x + 1),

            }
        },
        methods: {
            input(props) {
                this.$emit('input', {
                    ...this.value,
                    ...props,
                })
            },
        },
        watch: {
            dayOfWeek(val) {
                this.input({ dayOfWeek: val })
            },
            dayOfMonth(val) {
                this.input({ dayOfMonth: val })
            },
            daysToSend(val) {
                this.input({ daysToSend: val })
            },
            frequency(val) {
                this.input({ frequency: val })
            },
            time(val) {
                this.input({ time: val })
            },
            hour(val) {
                this.input({ time: val })
            },
            timeType(val) {
                this.input({ time: val })
            },
            value() {
                this.frequency = this.value&&this.value.frequency || "monthly";
                this.dayOfWeek = this.value&&this.value.dayOfWeek || 1;
                this.dayOfMonth = this.value&&this.value.dayOfMonth || 1;
                this.daysToSend = this.value&&this.value.daysToSend||[...Array(7).keys()].map(x => x + 1);
                this.time = this.value&&this.value.time || '';
            }
        }
    }
</script>

<style scoped lang="scss">
    .check-list-h {
        display: flex;
        label {
            text-align: center;
        }
    }
    @media screen and (max-width: 500px) {
        .check-list-h {
            display: block;
            .check-label {
                display: inline;
            }
            label {
                text-align: left;
            }
        }
    }
    .full-width {
        min-width: 100%;
    }
</style>
