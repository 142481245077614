<template>
    <div class="default-topics">
        <div class="alert alert-blue" v-if="networkActivity">
            <h3 class="text-center">{{networkActivity}}</h3>
        </div>

        <div class="alert alert-danger" v-if="networkError">
            {{networkError}}
        </div>

        <div class="default-topics-container" v-if="topics.length && ! networkActivity">
            <div
                class="default-topic-item"
                v-for="(topic) in topics"
                :key="topic.topic"
                :class="topic.image"
                :style="{ backgroundImage : 'url(\'/assets/images/welcome/'+topic.imagefile+'\')'}"
                @click="duplicateTopic(topic)"
            >
                <div class="gradient"></div>
                <h5>
                    {{topic.name}}
                </h5>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
    props : [],
    data(){
        return {
            topics : [],
            networkActivity : false,
            networkError : false
        }
    },
    computed : {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            folders : 'folders'
        }),
        folder(){
            return this.folders.find(f => this.currentAccountOverview.root.includes(f.id));
        }
    },
    mounted(){
        this.fetchStarterTopics()
    },
    methods : {
        fetchStarterTopics(){
            console.log('* DefaultTopics fetchStarterTopics ')
            this.networkActivity = "Fetching starter topics..."
            this.$api.get('/starter-topics')
            .then(response => {
                console.log('* DefaultTopics fetchStarterTopics this.topics', response.data)
                this.topics = response.data
                this.networkActivity = false;
            })
        },
        duplicateTopic(topic){
            this.networkError = false;
            this.networkActivity = "Fetching your topic..."
            this.$api.get(topic.topic, {ignoreCache : true}).then(resp => {
                let topic = resp.data;
                let queries = topic.queries;
                this.$api.post('/accounts/'+this.currentAccountOverview.id+'/topics', {
                    name : topic.name,
                    folder_id : this.folder.id,
                    queries
                }).then(async(response) => {
                    let topic = response.data
                    await this.$store.dispatch('addTopic', {topic, folderId : this.folder.id})
                    console.log('* DefaultTopic.vue duplicateTopic about to setCurrentTopic')
                    this.$store.dispatch('setCurrentTopic', {topic});
                    this.$router.push('/');
                    this.networkActivity = false;
                })
                .catch(e => {
                    this.networkActivity = false;
                    if (e.response){
                        this.networkError = e.response.data.description;
                    }
                })
            })
        },
        styleForTopic(topic){
            let url = '/assets/images/welcome/'+topic.image+'.jpg'
            return {
                'background' : 'url("'+url+'");'
            }
        }
    }
}
</script>

<style scoped>
.welcome-hero {
    text-align: center;
    padding: 130px;
    background-image: url('/src/assets/images/welcome/cover.jpg');
    background-size: cover;
    background-position: center;

    color: white;
    overflow: hidden;
    position: relative;

    margin-bottom: 2em;

}

.welcome-hero .btn {
    margin: 50px auto;
    display: block;
}

@media screen and (max-width: 500px) {
    .welcome-hero {
        text-align: left;
        padding: 50px;
    }

    .welcome-hero .btn {
        margin: 10px 0;
        display: block;
    }
}


.welcome-hero a {
    color: #ffffff !important;
}

.welcome-hero .text-container {
    position: relative;
    z-index: 2;
}

.gradient {
    content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to bottom right,#11547a,rgb(21, 45, 78));
    opacity: .6;
    height: 100%;
    width: 100%;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    filter: brightness(100%);
  }

  .default-topic-item:hover {
      transform: translate(-2px, -2px);
  }

  .default-topic-item:hover .gradient {
    opacity: 0.6;
    filter: brightness(70%);

  }

  .default-topic-item:hover h5 {
      transform: translate(2px, 2px);
      }

.default-topics-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(215px, 1fr));
    grid-gap: 30px;
    margin-bottom: 80px;
    cursor: pointer;
}

.default-topic-item {
    background-position: center;
    background-size: cover;
    height: 180px;
    display : flex;
    color: white;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.1em;
}

.default-topic-item h5 {
    text-align: center;
    flex: 1;
    position: relative;
    z-index: 2;
    transition: all 0.2s ease-in-out;
    padding: 10px 0;
}
</style>
