<template>
    <div>
        <div>
            <h4 class="subtitle">Sort By</h4>
            <select class="form-select-sm form-select" @change="update()" v-model="sortBy">
                <option
                    v-for="thisType in availableSortByTypes" :key="'content-types-'+thisType"
                    :value="thisType">
                    {{ thisType }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

import Base from '../SuggestionFilters/FilterMixin'
import {sortTypes} from "@/Constants";

export default {
    mixins: [Base],
    data() {
        return {
            sortBy: '',
        }
    },
    computed: {
        availableSortByTypes() {
            var types = sortTypes(this.hasSuggestions.type, this.topicLanguage,
                this.accountFeatures.predictedPerformance);

            return types;
        },
    },
    watch: {
        filters() {
            if (this.filters)
                this.sortBy = this.filters.sortBy;
        }
    },
    mounted() {
        this.sortBy = this.filters.sortBy
    },
    methods: {
        update() {
            this.updateFilter('sortBy', this.sortBy);
        }
    }
}
</script>

<style>
</style>
