let state = {
    currentGallerySiteId: false
}


let getters = {
    currentGallerySiteId: state => state.currentGallerySiteId
};

let actions = {
    setCurrentGallerySite: ({commit}, {siteId}) => {
        commit('SET_CURRENT_GALLERY_SITE',{siteId});
    }
};

let mutations = {
    SET_CURRENT_GALLERY_SITE: (state, {siteId}) => {
        state.currentGallerySiteId = siteId;
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
