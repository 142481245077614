import LimitedApi from "@/utilities/LimitedApi";
import store from "@/store";
import Api from "@/utilities/Api.ts";
import {ApiInstance} from "../utilities/Api.ts";
import {Apis} from "../api";

export let appApi = new Api(store, process.env.VUE_APP_API_BASE_URL);
export let limitedApi = new LimitedApi(store, process.env.VUE_APP_API_BASE_URL);
export let axiosAppApi = new ApiInstance(store, process.env.VUE_APP_API_BASE_URL);
export let apis = new Apis(axiosAppApi)
