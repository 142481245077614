<template>
<p>
    <label @click.stop class="truncate-publisher">
        <input
            @change="$emit('change', {publisher: publisher.source, checked: $event.target.checked, $event})"
            type="checkbox"
            :value="publisher.source"
            :checked="checked"
            class="me-1"
        >
        <span :title="publisher.name">{{ publisher.name }}</span>

    </label>
    <span @click.stop="$emit('selectOne', {publisher})" class="ms-auto badge bg-secondary cursor-pointer"
          :title="`${publisher.count} suggestions for this source. Click to select only this source.`">{{
            publisher.count
    }}</span>
</p>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "PublisherCheckbox",
    props: {
        publisher: Object,
    },
    computed: {
        ...mapGetters({
            selected: "selectedSources"
        }),
        checked() { return this.selected.includes(this.publisher.source)}
    }
}
</script>

<style scoped>

.truncate-publisher {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20em;
}
</style>
