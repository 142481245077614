<template>
    <div>
        <ul class="has-notes-list" v-if="items.length > 0">
            <li 
                class="has-notes-item" 
                :class="{'active' : ! selectedId}"
                @click="selectedId = false"
            >
                All
            </li>
            <li 
                class="has-notes-item" 
                :class="{'active' : selectedId == item.id}"  
                v-for="item in pagedItems" 
                :key="item.id + '-item-display'"
                @click="selectedId = item.id"
            >
                <span v-text="$strings.truncate(item.name, 80)" />
                <br/>
                <small class="text-muted">{{item.notes.length}}</small>
            </li>
        </ul>
        <div v-else>
            <div v-if="isDownloadingNotes" class="d-flex">
                <spinner class="me-2" />
                <p class="small muted">Downloading notes ...</p>
            </div>
            <p v-else class="small muted">No notes in this {{hasSuggestionsType}}</p>
        </div>
        <paginator name="GroupPager" :small="true" :items="items" @update="pagedItems = $event" />
    </div>
</template>

<script>
import Spinner from '../../../Controls/Spinner.vue';
import BaseMixin from './Base'

export default {
    mixins : [BaseMixin],
    components: {Spinner},
    computed : {
        items(){
            let authors = this.account.users.map(url => {
                return this.authors.find(author => url.includes(author.id));
            })
            return authors.filter(author => {
                return !!author
            }).map(author => {
                var notes = [];
                switch(this.hasSuggestionsType){
                    case "topic":
                        notes = this.notes.filter(note => {
                            let isInSelectedItem = note.topic && note.topic.includes(this.hasSuggestions.id)
                            let isByAuthor = note.user.includes(author.id);
                            return isInSelectedItem && isByAuthor;
                        })
                    break;
                    case "collection":
                        notes = this.notes.filter(note => {
                            let isInSelectedItem = note.collection && note.collection.includes(this.hasSuggestions.id)
                            let isByAuthor = note.user.includes(author.id);
                            return isInSelectedItem && isByAuthor;
                        })
                    break;
                }
                return {...author, notes}
            }).filter(author => author.notes.length > 0)
        }
    }
}
</script>
