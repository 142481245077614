<template>
    <div>
        <h4 class="subtitle d-flex">
            Sources
            <a v-if="sources && sources.length < allFullSources.length"
               href="#"
               @click.prevent.stop="selectAllPublishers"
               class="sidebar-link ms-auto">All</a>
        </h4>

        <progress-bar v-if="isLoadingSources"></progress-bar>

        <div v-else>
            <input class="filter-search form-control"
                   placeholder="Filter sources"
                   v-model="sourcesSearch"
                   v-if="showSearchBar"/>
            <div class="filter-item d-flex align-items-start mt-2">
                <label @click.stop>
                    <input type="checkbox"
                           :checked="includeNewSources"
                           @change="includeNewChanged({includeNew: !includeNewSources})"
                           class="include-new-sources-checkbox me-1">
                    <span>Include new sources</span>
                </label>
            </div>
            <hr class="mt-0"/>
            <publisher-checkbox class="filter-item d-flex align-items-start"
                v-for="publisher in pagedSources"
                :publisher="publisher" :selected="sources"
                @change="setPublisherChecked"
                @selectOne="selectOnlyPublisher"
                :key="'publisher-'+publisher.source">
            </publisher-checkbox>
            <paginator :extraSmall="true"
                       :small="true"
                       :size="pageSize"
                       :items="filteredSources"
                       @update="pagedSources = $event"/>
        </div>

        <p class="small" v-if="filteredSources.length == 0 && ! sourcesSearch && ! isLoadingSources">
            No Sources Found
        </p>
    </div>
</template>

<script>

// @click.right.prevent="sourceShowingMessage = publisher" <-- Use this for the right-click-to-remove functionality

import {mapActions, mapGetters} from 'vuex';

import Base from './FilterMixin'
import ProgressBar from "@/utilities/ProgressBar"
import Paginator from '@/utilities/Paginator'
import PublisherCheckbox from "../PublisherCheckbox";

export default {
    mixins: [Base],
    components: {PublisherCheckbox, ProgressBar, Paginator},
    data() {
        return {
            pageSize: 10,
            sources: [],
            sourcesSearch: '',
            pagedSources: []
        }
    },
    watch: {
        hasSuggestions() {
            this.sourcesSearch = '';
            this.sources = this.calculatedSourceList.map(s => s.source);
            // this.updateFilter('sources', this.sources);
        },
        filter() {
            let updatedSources = this.calculatedSourceList.map(s => s.source);
            if (JSON.stringify(this.sources) !== JSON.stringify(updatedSources)) {
                this.sources = this.calculatedSourceList.map(s => s.source);
                // this.updateFilter('sources', this.sources);
            }
        },
        filteredSources() {
            this.updateSources()
        },
        selectedSources() {
            this.sources = this.selectedSources;
            // this.updateFilter('sources', this.sources);
            // this.updateFilter('excludedSources', this.excludedSources);

        }
    },
    computed: {
        ...mapGetters({
            hasSuggestions: 'hasSuggestions',
            isLoadingSources: 'isLoadingSources',
            allFullSources: 'sources',
            includeNewSources: 'includeNewSources',
            selectedSources: 'selectedSources',
            excludedSources: 'excludedSources'
        }),
        calculatedSourceList() {
            return this.allSourcesSorted.filter(source => {
                return this.excludedSources.indexOf(source.source) == -1
            })
        },

        filteredSources() {
            let sources = this.allSourcesSorted;

            if (this.sourcesSearch) {
                sources = sources.filter(s => s.source.toLowerCase().includes(this.sourcesSearch.toLowerCase()));
            }

            return sources;
        },

        allSourcesSorted() {
            return [...this.allFullSources].sort((a, b) =>
                // First sort by count DESC, default to 0. Then by source name.
                (b.count || 0) - (a.count || 0)
                || a.source.toUpperCase().localeCompare(b.source.toUpperCase())
            );

        },

        showSearchBar() {
            return this.allFullSources.length > this.pageSize
        }
    },
    mounted() {
        this.sources = this.calculatedSourceList.map(s => s.source);
    },
    methods: {
        ...mapActions(['includeNewChanged', 'setPublisherChecked', 'selectOnlyPublisher', 'selectAllPublishers']),
        clearSearch() {
            this.sources = this.allSourcesSorted.map(s => s.source);
            this.update({includeNew: true});
        },
        updateSources() {
            this.sources = this.calculatedSourceList.map(s => s.source);
        },
    }
}
</script>
<style scoped>
</style>
