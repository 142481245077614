import {mergePublishers} from "../../services/Publishers";
import * as types from "../mutation-types";
import {jsonDiffers} from "../../utilities/json";
import {SET_INCLUDE_NEW} from "../mutation-types";

let state = {
    hasContentPublishers: [],
    publisherCancelTokens: [],
    isLoadingSources : false,
    selectedSources: null,
    excludedSources: [],
    includeNewSources: false
}

let getters = {
    currentPublishers: state => state.hasContentPublishers,
    publishers: (state, getters, rootState, rootGetters) =>
        mergePublishers(state.hasContentPublishers, rootGetters.filterViewPublishers, rootGetters.filterPublishers),
    sources: (state, getters) => getters.publishers,
    currentPublishersCancelTokens: state => state.publisherCancelTokens,
    isLoadingSources : state => state.isLoadingSources,
    selectedSources : (state, getters) => {
        if (!getters.includeNewSources && state.selectedSources && state.selectedSources) {
            return state.selectedSources
        }

        let excludedSources = state.excludedSources ? state.excludedSources : []

        if (getters.publishers && excludedSources)
            return getters.publishers.map(s => s.source).filter(source => excludedSources.indexOf(source) === -1)

        return []
    },
    excludedSources : (state, getters) => {
        if (getters.includeNewSources && state.excludedSources)
            return state.excludedSources

        let selectedSources = state.selectedSources? state.selectedSources : []

        if (getters.publishers && selectedSources)
            return getters.publishers.map(s => s.source).filter(source => selectedSources.indexOf(source) === -1)

        return []
    },
    includeNewSources: (state, getters, rootState) => state.includeNewSources !== false,
}
function publisherName(publisher) {
    if (typeof publisher === "string") return publisher;
    else if(typeof publisher === "object") return publisher.source;
    else return null;
}
let actions = {
    async loadPublishers({commit, dispatch, getters}) {
        let contentType = getters.hasSuggestions.type;
        let id = getters.hasSuggestions.id;
        let filters = getters.currentFilters;
        console.log('loadPublishers', {contentType, id, filters})
        if (!contentType) return;

        let age = filters.age;
        let type =
            !filters.contentType ? filters.contentType :
                filters.contentType === 'All (including other)' ? '' :
                    filters.contentType.toLowerCase();
        let undated = filters.evergreen;
        let body = {'include': ['creator'], 'count': true, age, type, undated};

        let cancelToken = await dispatch('getNewTokenAndCancelPreviousRequests', {category: 'loadPublishers'})

        await dispatch('setIsLoadingSources', true);
        try {
            console.log('loadPublishers.url', {url: `/suggestion-data?${contentType}=${id}`, contentType, id, filters})
            let response = await window.$app.api.post(`/suggestion-data?${contentType}=${id}`, body, {cancelToken}) // TODO: https://upcontent.atlassian.net/browse/DEV-1029
            let publishers = [];
            for (let key in response.data) {
                publishers.push({source: key, count: response.data[key], name: key}); // TODO: https://upcontent.atlassian.net/browse/DEV-1029
            }
            await commit(mutations.SET_PUBLISHERS.name, {publishers})
            await dispatch('loadPublishersFilters', {})
        } catch (e) {
            console.error(e)
        }
        finally {
            await dispatch('setIsLoadingSources', false)
        }
    },
    async updatePublisherFilterDependencies({commit, dispatch, getters}) {
        await dispatch('filtersChanged', {filters: {
            sources: !getters.includeNewSources ? getters.selectedSources : null,
            excludedSources: getters.includeNewSources ? getters.excludedSources : null,
            includeNewSources: getters.includeNewSources
        }})
    },
    async unselectAllPublishers({commit, dispatch, getters}, {}) {
        await commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
            excludedSources: getters.publishers.map(x=>x.source),
            selectedSources: [],
            includeNewSources: getters.includeNewSources
        })
        await dispatch('updatePublisherFilterDependencies')
    },
    async selectOnlyPublisher({commit, dispatch, getters}, {publisher}) {
        publisher = publisherName(publisher)
        await commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
            excludedSources: getters.publishers.map(x=>x.source).filter(x=>x!==publisher),
            selectedSources: [publisher],
            includeNewSources: false,
        })
        await commit(types.SET_INCLUDE_NEW, {includeNew: false});
        await dispatch('updatePublisherFilterDependencies')
    },
    async selectAllPublishers({commit, dispatch, getters}, {}) {
        await commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
            excludedSources:[],
            selectedSources: getters.publishers.map(x=>x.source),
            includeNewSources: true
        })
        await commit(types.SET_INCLUDE_NEW, {includeNew: true});
        await dispatch('updatePublisherFilterDependencies')
    },
    async togglePublisher({commit, dispatch, getters}, {publisher}) {
        publisher = publisherName(publisher)
        let publisherSelected = getters.selectedSources.includes(publisher);
        let action = publisherSelected ? actions.uncheckPublisher : actions.checkPublisher;
        await dispatch(action.name, {publisher})
    },
    async checkPublisher({commit, dispatch, getters}, {publisher}) {
        publisher = publisherName(publisher)
        await commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
            excludedSources: getters.excludedSources.filter(x => x !== publisher),
            selectedSources: [...getters.selectedSources, publisher],
            includeNewSources: getters.includeNewSources
        })
        await dispatch('updatePublisherFilterDependencies')
    },
    async setPublisherChecked({commit, dispatch, getters}, {publisher, checked}) {
        if (checked)
            await dispatch(actions.checkPublisher.name, {publisher})
        else
            await dispatch(actions.uncheckPublisher.name, {publisher})

    },
    async uncheckPublisher({commit, dispatch, getters}, {publisher}) {
        publisher = publisherName(publisher)
        await commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
            excludedSources: [...getters.excludedSources, publisher],
            selectedSources: getters.selectedSources.filter(x => x !== publisher),
            includeNewSources: getters.includeNewSources
        })
        await dispatch('updatePublisherFilterDependencies')
    },
    selectedPublisherListChanged({commit, dispatch, getters}, {publishers}) {
        commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
            excludedSources: getters.publishers.filter(x => publishers.includes(x)),
            selectedSources: publishers,
            includeNewSources: getters.includeNewSources
        })
    },
    async includeNewChanged({commit, dispatch, getters}, {includeNew}) {
        let filters = {
            sources: !includeNew ? getters.selectedSources : null,
            excludedSources: includeNew ? getters.excludedSources : null,
            includeNewSources: includeNew
        }
        console.log('includeNewChanged', filters)
        await commit(types.SET_INCLUDE_NEW, {includeNew})

        await dispatch('filtersChanged', {filters, forceLoadPublishers: true})
    },
    loadPublishersFilters({commit, getters}) {
        let filters = getters.currentFilters;
        let selected = filters.sources || getters.publishers.map(x=>x.source);
        let excluded = filters.excludedSources || [];
        let includeNewSources = filters.includeNewSources;
        console.log('loadPublishersFilters', {selected, excluded, includeNewSources, publishers: getters.publishers, filters})
        if (includeNewSources !== false) {
            commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
                excludedSources: excluded,
                selectedSources: getters.publishers.map(x => x.source).filter(x => !excluded.includes(x)),
                includeNewSources: filters.includeNewSources !== false
            })
        } else {
            commit(mutations.SET_SELECTED_PUBLISHER_DATA.name,  {
                excludedSources: getters.publishers.map(x => x.source).filter(x => !selected.includes(x)),
                selectedSources: selected,
                includeNewSources: filters.includeNewSources !== false
            })
        }
    }
};

let mutations = {
    SET_PUBLISHERS(state, {publishers}) {
        console.log('SET_PUBLISHERS', publishers && publishers.length, publishers && publishers.length && publishers[0])
        state.hasContentPublishers = publishers;
    },
    CLEAR_AND_SET_PUBLISHER_CANCEL_TOKENS(state, {newToken}) {
        state.publisherCancelTokens = [newToken]
    },
    SET_IS_LOADING_SOURCES : (state, bool) => {
        state.isLoadingSources = bool;
    },
    SET_SELECTED_PUBLISHER_DATA(state, {excludedSources, selectedSources, includeNewSources}) {
        console.log('SET_SELECTED_PUBLISHER_DATA', {excludedSources, selectedSources, includeNewSources}, {state})
        if (!jsonDiffers(state.excludedSources, excludedSources) && !jsonDiffers(state.selectedSources, selectedSources) && includeNewSources === state.includeNewSources)
            return;
        state.excludedSources = excludedSources;
        state.selectedSources = selectedSources;
        state.includeNewSources = includeNewSources;
    },
    SET_INCLUDE_NEW(state, {includeNew}) {
        state.includeNewSources = includeNew
    },
    [types.SET_SUGGESTION_SOURCE_LIST](state, {sources}){
        state.hasContentPublishers = sources;
    },
    [types.ADD_SOURCE_FOR_SUGGESTION](state, {source}){
        let sourceList = state.hasContentPublishers||[];
        let alreadyExists = sourceList.some(s => s.source === source);
        let incrementCount = () => {
            let sourceIndex = sourceList.findIndex(s => s.source === source)
            sourceList[sourceIndex].count++;
            return sourceList;
        };

        state.hasContentPublishers = alreadyExists
            ? incrementCount()
            : [...sourceList, { source, count: 1 }];

        // TODO Move this to actions so it can set both props
        if (getters.includeNewSources === false && !alreadyExists) {
            let selected = state.selectedSources
            state.selectedSources = selected.includes(source) ? selected :  [...selected, source]
        } else if (getters.excludedSources && !alreadyExists) {
            let selected = state.selectedSources
            state.selectedSources = selected.includes(source) ? selected :  [...selected, source]
        }
    },
    [types.EXCLUDE_SOURCE](state, { source, hasSuggestionsId }) {
        let excluded =state.excludedSources||[];
        state.excludedSources = excluded.includes(source) ? excluded : [source, ...excluded];

        let selected = state.selectedSources || []
        state.selectedSources =  selected.filter(x => x !== source);
    },
};
export default {
    state,
    getters,
    actions,
    mutations
}
