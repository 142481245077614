<template>
    <div>
        <details v-if="usages.length" class="card form-group-card">
            <summary class="card-header">Used in {{usageCount}} {{$strings.plural('location', usageCount)}}</summary>
            <div class="d-flex flex-wrap card-body">
                <div v-for="c in categories" :key="c.key" class="m-3">
                    <h6 class="text-dark">{{c.name}}</h6>
                    <ul class="list-group-flush mb-3 ps-0 pe-0">
                        <li v-for="u in c.usages" :key="u.entityId" class="list-group-item small p-2" style="min-width: 250px">
                            <div class="">
                                <span class="text-muted d-inline-block" style="width: 4em;">From:</span>
                                <span class="text-dark">{{u.fromName}}</span>
                                <span class="badge tag outline-secondary">{{u.fromType}}</span>
                            </div>
                            <div class="">
                                <span class="text-muted d-inline-block" style="width: 4em;">To:</span>
                                <span class="text-dark">{{u.toName}}</span>
                                <span class="badge tag outline-secondary">{{u.toType}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </details>
    </div>
</template>

<script>
import FilterUsagesData from "./FilterUsagesData";
import EntityBadge from "../Controls/EntityBadge";
export default {
    name: "FilterUsages",
    components: {EntityBadge},
    mixins: [FilterUsagesData],
    props: {
        filterViewId: { type: String }
    },
}
</script>

<style scoped>
    h6{
        font-size: .98rem;
    }
    /*summary {*/
    /*    background: var(--bs-light);*/
    /*    color: var(--bs-dark)*/
    /*}*/
    /*details {*/
    /*    border: var(--bs-secondary) 1px solid;*/
    /*}*/
    /*details[open] summary {*/
    /*    border-bottom: var(--bs-secondary) 1px solid;*/
    /*}*/
    legend.card-header {
        font-size: 1rem;
    }
    .form-group-card {
        margin-bottom: 1.5rem;
    }
</style>
