<template>
    <div class="filter-form">
        <div class="d-flex flex-wrap">
            <div class="filter-section flex-grow me-3 min25p max50p" v-if="languageSupportsContentType">
                <content-filter :value="filters" @input="update" class="mb-1"></content-filter>
                <!--<p class="filter-item">-->
                    <!--<label><input type="checkbox" @click="toggleFavorites" :value="filters.favorites"> Favorites Only</label>-->
                <!--</p>-->
                <!--<p class="filter-item">-->
                    <!--<label><input type="checkbox" v-model="required" :value="'image'"> Has Image</label>-->
                <!--</p>-->
            </div>
            <div class="filter-section flex-grow me-3 min25p max50p">
                <sort-filter :value="filters" @input="update" :type="type" ></sort-filter>
            </div>
            <div class="filter-section flex-grow me-3 min25p max50p">
                <age-filter :value="filters" @input="update" :type="type"></age-filter>
            </div>
            <div class="filter-section flex-grow me-3 min25p max50p">
                <label class="d-block">
                    Limit
                    <input type="number" v-model="limit" required min="1" max="100" class="form-control form-control-sm"/>
                </label>
            </div>
        </div>
        <div class="d-flex">
            <div class="filter-section flex-grow">
                <div class="dropdown" style="position: relative " >
                    <label data-bs-toggle="dropdown" aria-haspopup="true">
                        Sources
                        <button type="button" class="btn btn-sm btn-secondary d-block" role="button" >
                            {{selectedSources.length}}/{{availableSources.length}} Selected <span class="dropdown-toggle"></span>
                        </button>
                    </label>
                    <div class="dropdown-menu dropdown-menu-center filter-form__sources-menu" role="listbox" >
                        <sources-filter  :value="filters" @input="updateSource" :all-sources="sources"></sources-filter>
                    </div>
                </div>

                <label @click.stop class="mt-1 mb-1">
                    <input type="checkbox" v-model="includeAllSources"/>&nbsp;
                    Include All Sources (Including New)
                </label>
                <label @click.stop class="mt-1 mb-1">
                    <input type="checkbox" v-model="includeNewSources" :disabled="includeAllSources" :class="{'text-muted': includeAllSources}"/>&nbsp;
                    Include New Sources
                </label>
            </div>
            <div class="filter-section flex-grow" style="max-width: 40%">
                <tags :all-tags="allTags" :value="filters" @input="update"></tags>
            </div>
        </div>
    </div>
</template>

<script>


import SourcesFilter from './SuggestionFiltersUnbound/Sources'
import GeographyFilter from './SuggestionFiltersUnbound/Geography'
import AgeFilter from "./SuggestionFiltersUnbound/Age"
import SortFilter from './SuggestionFiltersUnbound/Sort'
import ContentFilter from './SuggestionFiltersUnbound/Content'
import {filterQueryString} from "@/services/RssLinks";
import Tags from "@/components/FilteringAndSorting/SuggestionFiltersUnbound/Tags";
import {languagesSupportingContentType} from "@/Constants";
import {mapGetters} from 'vuex'
// import RssFilterBase from '@/components/FilteringAndSorting/RssFilterBase'
// import * as $ from 'jquery'
const env = process.env;

export default {
    props: {
        sources: Array,
        defaultFilters: Object,
        hasSuggestions:{},
        value: Object,
        allTags: {type: Array, default: () => []},
        type: { type: String, default: 'collection'}
    },
    // mixins: [RssFilterBase],
    components : {
        Tags,
        SourcesFilter, GeographyFilter, AgeFilter, SortFilter, ContentFilter
    },
    computed: {
        ...mapGetters({topics: 'topics', feedsDomain: 'feedsDomain'}),
        selectedSources() {return this.filters && this.filters.sources || this.calculatedSourceList || []; },
        excludedSources() {return this.filters && this.filters.excludedSources || []; },
        calculatedSourceList(){
            return this.sources.filter(source => {
                return this.excludedSources.indexOf(source.source) == -1
            })
        },
        languageSupportsContentType() {
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },
        topicLanguage() {
            if (this.hasSuggestions.type !== 'topic') return null;
            let culture = this.topics.find(x => x.id === this.hasSuggestions.id).culture;
            return Boolean(culture) && culture.split('-')[0]
        },
        availableSources() { return this.sources || [...this.selectedSources, ...this.excludedSources]; },
        filterQueryString() {
            return filterQueryString(this.filters, !!this.filters.favorites, this.includeAllSources, this.includeNewSources, this.limit);
        },
        feedUrl() {
            if (this.hasSuggestions.type){
                let qa = env['VUE_APP_API_BASE_URL'].includes('qa');
                let domain = this.feedsDomain;
                return `https://${domain}/suggestions?${this.hasSuggestions.type}=${this.hasSuggestions.id}&${this.filterQueryString}`
            }
        },
        isTopic() {
            return this.type === 'topic'
        }
    },
    methods:{
        update(value) {
            this.filters = value;
            this.updateOutside();
        },
        toggleFavorites() {
            this.update({...this.filters, favorites: !this.filters.favorites })
        },
        updateSource(value) {
            this.filters = Object.assign({}, this.filters, {
                excludedSources: value.excludedSources,
                sources: value.sources
            });
            this.includeAllSources = (value.excludedSources.length === 0) && this.includeNewSources;
            this.updateOutside();
        },
        // openCloseDropdown(event) {
        //     $(event.target).next('.dropdown-menu').dropdown('toggle')
        // }
        // }
        updateOutside() {
            this.$emit('input', {
                filters: {
                    ...this.filters,
                    limit: this.limit,
                    // required: this.required,
                    includeNewSources: this.includeNewSources,
                    includeAllSources: this.includeAllSources
                },
                url: this.feedUrl
            })
        }
    },
    data(){
        return {
            filters: this.defaultFilters,
            // required: this.defaultFilters && this.defaultFilters.required || [],
            includeNewSources: false,
            includeAllSources: false,
            limit: this.defaultFilters && this.defaultFilters.limit || 100
        }
    },
    mounted() {
      if (this.filters && this.defaultFilters) {
          this.filters = this.defaultFilters;
          // this.required = this.filters && this.filters.required || [];
          this.limit = this.filters && this.filters.limit || 100;
          let sourceCount = this.filters && this.filters.sources && this.filters.sources.length || 0;
          let excludeCount = this.filters && this.filters.excludedSources && this.filters.excludedSources.length || 0;

          this.includeAllSources = excludeCount === 0;
          this.includeNewSources = excludeCount <= sourceCount || this.includeAllSources;
          this.updateOutside();
      }
    },
    watch: {
        defaultFilters() {
            console.log('defaultFilters', this.defaultFilters)
            this.filters = this.defaultFilters;
            // this.required = this.filters && this.filters.required || [];
            this.limit = this.filters && this.filters.limit || 100;
            let sourceCount = this.filters && this.filters.sources && this.filters.sources.length || 0;
            let excludeCount = this.filters && this.filters.excludedSources && this.filters.excludedSources.length || 0;

            this.includeAllSources = excludeCount === 0;
            this.includeNewSources = excludeCount <= sourceCount || this.includeAllSources;
            this.updateOutside();
        },
        limit() {
            this.updateOutside()
        },
        // required() {
        //     this.updateOutside()
        // },
        includeAllSources() {
            let sourceCount = this.filters && this.filters.sources && this.filters.sources.length || 0;
            let excludeCount = this.filters && this.filters.excludedSources && this.filters.excludedSources.length || 0;
            this.includeNewSources = excludeCount <= sourceCount || this.includeAllSources;
            this.updateOutside();
        },
        includeNewSources() {
            this.updateOutside()
        }
    },
}
</script>


<style>
    .form .filter-section label {
        margin-bottom: 0;
    }
    .filter-form__sources-menu {
        padding: 20px !important;
        min-width: 19rem;
        font-size: 10px;
    }
</style>

<style scoped>
    .filter-section a {
        font-size: 10px;
        text-transform: uppercase;
    }
</style>ß
