<template>
    <ul class="no-bullets ps-0">
        <li class="mb-2">
            <button type="button" class="btn btn-outline-primary d-block full-width" @click="$emit('input', 'topic')">
                <h5>
                    <i class="fa fa-search me-1"></i>
                    New Topic
                </h5>
                <small class="text-muted">Describe Topic</small>
            </button>
        </li>
        <li class="mb-2">
            <button type="button" class="btn btn-outline-primary full-width" @click="$emit('input', 'feeds')">
                <h5>
                    <i class="fa fa-feed me-1"></i>
                    New Feed Repository
                </h5>
                <small class="text-muted">Describe Feed Repository</small>
            </button>
        </li>
        <li class="mb-2">
            <button type="button" class="btn btn-outline-primary full-width" @click="$emit('input', 'automation')">
                <h5>
                    <i class="fa fa-bolt me-1"></i>
                    New Automation Step
                </h5>
                <small class="text-muted">Describe Automation</small>
            </button>
        </li>
        <li class="mb-2">
            <button type="button" class="btn btn-outline-primary full-width" @click="$emit('input', 'collaboration')">
                <h5>
                    <i class="fa fa-comments me-1"></i>
                    New Approval/Collaboration Stage
                </h5>
                <small class="text-muted">Describe Approval</small>
            </button>
        </li>
        <li class="mb-2">
            <button type="button" class="btn btn-outline-primary full-width" @click="$emit('input', 'destination')">
                <h5>
                    <i class="fa fa-share me-1"></i>
                    New Destination/Integration
                </h5>
                <small class="text-muted">Describe Destination</small>
            </button>
        </li>
    </ul>
</template>

<style>

</style>

<script>
    export default {
        name: 'WorkflowStageTypePicker',
        props: { value: String }
    }
</script>
