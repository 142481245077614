<template>
    <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: sanitized-->
    <span v-html="highlighted"></span>
</template>

<script>
import {highlightSearchText} from "../../services/SearchHighlighter";

export default {
    name: "SearchHighlighter",
    props: ['text', 'searchText'],
    computed: {
        highlighted() {
            return highlightSearchText(this.text, this.searchText)
        }
    }
}
</script>

<style scoped>

</style>
