<template>
    <li class="list-group-item flex-column align-items-start">
        <div class="d-flex justify-content-between mb-2">
            <h5>
                {{displayName}}
            </h5>
            <div class="">
                <button type="button" class="btn btn-link text-danger m-0 p-0 em1" @click="deleteSource">
                    <span class="fa fa-trash"></span> Remove
                </button>
            </div>
        </div>

        <div v-if="filters && filters.id" style="overflow: visible">
            <select-filter-view
                v-model="filterViewId"
                :currentUsage="id"
                has-content-type="collection"
                :has-content-id="collectionId"
                class="mb-2"
                ref="filterViewEdit"
            >

            </select-filter-view>
        </div>
    </li>
</template>

<style scoped>
    .learn-more {
        white-space: nowrap
    }
</style>

<script lang="js">
    import {mapGetters} from 'vuex'
    import * as Controls from '@/components/Controls'
    import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";

    export default {
        components:{SelectFilterView, ...Controls },
        props: {
            id: {default: null},
            name: { default: '' },
            collectionId: { default: null },
            filters: { Object, default: null },
        },
        data() {
            return {
                showEditForm: false,
                loadingFilters: false,
                allSources:[],
                validating: false,
                filterViewId: this.filters && this.filters.id || null,
            }
        },
        computed: {
            ...mapGetters({
                collections: 'collections',
                features: 'accountFeatures',
                configLimits: 'configLimits'
            }),
            collection() {
                return this.collections && this.collectionId && this.collections.find(c => c.id === this.collectionId)
            },
            displayName() {
                if (this.name) return this.name;
                if (this.collection) return this.collection.name;
                return '';
            },
        },
        watch: {
            async filterViewId() {
                let loadedAndReady = this.collectionId && this.filterViewId;
                let filtersChanged = this.filters && this.filterViewId !== this.filters.id
                if (loadedAndReady && filtersChanged) {
                    this.$emit('filtersUpdated', {filterViewId: this.filterViewId, id: this.id, collectionId: this.collectionId, filters: {id: this.filterViewId}})
                }
            }
        },
        methods: {
            deleteSource() {
                this.$emit('deleteSource')
            },
            refreshSource() {
                this.$emit('refreshSource')
                this.lastChecked = null;
            },
        }
    }
</script>
