<template>
    <div class="filter-suggestion-tags">
        <h4 class="subtitle d-flex">
            Show
            <a v-if="selectedTags && selectedTags.length < tags.length"
               href="#"
               @click.prevent.stop="clearSearch"
               class="sidebar-link ms-auto">All</a>
        </h4>

        <label class="filter-item d-block mb-1 text-primary">
            <input type="checkbox" :checked="favorites"  @change.prevent="toggleFavorites" />&nbsp;&nbsp;
            <span>
                    <i v-if="favorites" class="fa fa-star"></i>
                    <i v-else class="fa fa-star-o me-1"></i>
                    Favorites Only
            </span>
        </label>

        <div class="filter-item d-block text-primary mb-1">
            <input type="checkbox" :checked="excludePaywalled" @change.prevent="toggleExcludePaywalled"/>&nbsp;
            <span>
                <i class="fa fa-usd me-1"></i>
                Exclude Paywall Content
            </span>
        </div>

        <div class="filter-item d-block" v-if="features.imageOnlyInSuggestions">
            <label class="text-primary mb-1">
                <input type="checkbox" v-model="required" :value="'image'"/>&nbsp;
                <span>
                    <i class="fa fa-image me-1"></i>
                    Has Image *
                </span>
            </label>
            <div v-if="required.includes('image')" class="text-muted small mb-3" style="max-width: 25em">* Only include
                articles where the publisher has provided an associated image. The ability to display on various
                platforms will vary depending on image type and publisher restrictions.
            </div>
        </div>

        <div class="filter-item d-block" v-if="features.textLengthFilter">
            <label class="text-primary mb-1">
                <input type="checkbox" :checked="minFullTextLength" @change.prevent="toggleMinFullTextLength"/>&nbsp;
                <span>
                    <i class="fa fa-font me-1"></i>
                    Exclude Thin Content
                </span>
            </label>
        </div>

        <ul class="no-bullets ps-0" v-else>
            <li class="filter-item d-flex align-items-start" v-for="tag in pagedTags" :key="'publisher-'+tag.key">
                <label @click.stop style="color: var(--bs-primary)" class="mb-1">
                    <input
                        @change="update()"
                        type="checkbox"
                        :value="tag.key"
                        v-model="tags"
                        slot="before"

                    >&nbsp;
                    <i :class="`icon-dynamic ${tag.iconClass}`" class="me-1"></i>
                    {{ tag.filterLabel || ((tag.label || '') + ' Only') }}
                </label>
                <span class="ms-auto badge bg-secondary">{{ tag.count }}</span>
            </li>
        </ul>
        <paginator :extraSmall="true"
                   :small="true"
                   :size="pageSize"
                   :items="filteredTags"
                   @update="pagedTags = $event"/>

        <fieldset v-for="group in groupedTags" :key="group.groupKey" class="radio-group filter-item">
            <legend>{{ group.groupLabel }}</legend>
            <ul class="no-bullets ps-0">
                <li class="d-flex align-items-start" v-for="tag in group.tags" :key="'publisher-'+tag.key">
                    <label @click.stop
                           style="color: var(--bs-primary)"
                           class="mb-1"
                           :class="tag.tagClass && tag.tagClass.replace('bg', 'text') || ''">
                        <input
                            @change="update()"
                            type="checkbox"
                            :value="tag.key"
                            v-model="tags"
                            slot="before"

                        >&nbsp;
                        <i :class="`icon-dynamic ${tag.iconClass}`" class="me-1"></i>
                        {{ tag.filterLabel || (tag.label || '') }}
                    </label>
                    <span class="ms-auto badge bg-secondary">{{ tag.count }}</span>
                </li>
            </ul>
        </fieldset>
    </div>
</template>

<script>

// @click.right.prevent="tagShowingMessage = publisher" <-- Use this for the right-click-to-remove functionality

import {mapGetters} from 'vuex';

import Base from './FilterMixin'
import ProgressBar from "@/utilities/ProgressBar"
import Paginator from '@/utilities/Paginator'
import SuggestionTag from "@/components/Suggestions/SuggestionTag";

export default {
    mixins: [Base],
    components: {SuggestionTag, ProgressBar, Paginator},
    data() {
        return {
            tagsSearch: '',
            pageSize: 10,
            tags: [],
            tagShowingMessage: false,
            pagedTags: [],
            searchQuery: '',
            required: [],
        }
    },
    watch: {
        hasSuggestions() {
            this.tagsSearch = '';
            this.tags = this.selectedTags;
            this.updateFilter('tags', this.tags);
        },
        // filter(){
        //     let updatedTags = this.calculatedTagList.map(s => s.tag);
        //     if (JSON.stringify(this.tags) !== JSON.stringify(updatedTags)){
        //         this.tags = this.selectedTags;
        //         this.updateFilter('tags', this.tags);
        //     }
        // },
        globalRequired() {
            if (JSON.stringify(this.required) !== JSON.stringify(this.globalRequired))
                this.required = this.globalRequired;
        },
        required() {
            if (JSON.stringify(this.required) !== JSON.stringify(this.globalRequired))
                this.updateRequired()
        },
        filteredTags() {
            this.updateTags()
        },
        selectedTags() {
            this.tags = this.selectedTags;
            // this.updateFilter('tags', this.tags);
        }
    },
    computed: {
        ...mapGetters({
            hasSuggestions: 'hasSuggestions',
            // NOTE(casio): this getter is unknown - remove? TODO(all): also remove above bound progress-bar comp when resolved
            // isLoading: 'isLoading',
            allFullTags: 'tags',
            selectedTags: 'selectedTags',
            favorites: 'showFavoriteSuggestions',
            globalRequired: 'required',
            features: 'features',
            minFullTextLength: 'minFullTextLength',
            excludePaywalled: 'excludePaywalled',
        }),
        // calculatedTagList(){
        //     return this.allSoloTagsSorted.filter(tag => {
        //         return this.selectedTags.indexOf(tag.key) !== -1
        //     })
        // },

        filteredTags() {
            var tags = this.allSoloTagsSorted;

            if (this.searchQuery) {
                tags = tags.filter(s => s.label && s.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }

            return tags;
        },

        soloTags() {
            return this.allFullTags.filter(t => !t.group_key).filter(t => t.key !== 'zest_content')
        },

        allSoloTagsSorted() {
            return this.$sort.alphabetize(this.soloTags, 'label').reverse();
        },
        groupedTags() {
            let tagsWithGroup = this.allFullTags.filter(t => t.group_key)
            return tagsWithGroup.reduce((groups, tag) => {
                let group = groups.find(g => g.groupKey === tag.group_key);
                if (group) {
                    group.tags.push(tag)
                    return groups;
                } else {
                    return [
                        ...groups,
                        {
                            groupKey: tag.group_key,
                            groupLabel: tag.group_label,
                            tags: [tag]
                        }
                    ]
                }
            }, [])
        },

        showSearchBar() {
            return this.tags.length > this.pageSize
        }
    },
    mounted() {
        this.tags = this.selectedTags;
    },
    methods: {
        update() {
            // this.$store.dispatch('setSelectedTags', {tags: this.tags, hasSuggestionsId: this.hasSuggestions.id});
            this.updateFilter('tags', this.tags);
        },
        handleClick() {
            if (this.tagShowingMessage) {
                this.tagShowingMessage = false;
            }
        },
        clearSearch() {
            this.tags = this.allSoloTagsSorted.map(s => s.tag);
            this.update();
        },
        updateRequired() {
            // this.$store.dispatch('setRequired', {required: this.required, hasSuggestionsId: this.hasSuggestions.id})
            this.updateFilter('required', this.required);
        },
        // updateIsTextLength300Char() {
        //     this.updateFilter('minFullTextLength', this.isTextLength300Char ? 300 : 0);
        // },
        updateTags() {
            this.tags = this.selectedTags;
        },
        toggleFavorites() {
            this.$loggy.log('toggleFavorites BEFORE', this.favorites);
            this.$store.dispatch('toggleShowFavorites');
            this.$loggy.log('toggleFavorites AFTER', this.favorites);
        },
        toggleExcludePaywalled() {
            this.$loggy.log('toggleExcludePaywalled BEFORE', this.excludePaywalled);
            this.$store.dispatch('toggleExcludePaywalled');
            this.$loggy.log('toggleExcludePaywalled AFTER', this.excludePaywalled);
        },
        toggleMinFullTextLength() {
            this.$loggy.log('toggleMinFullTextLength BEFORE', this.minFullTextLength);
            this.$store.dispatch('toggleMinFullTextLength');
            this.$loggy.log('toggleMinFullTextLength AFTER', this.minFullTextLength);
        }

    }
}
</script>

<style scoped>
.tag input {
    font-size: 1.25em
}

.radio-group > legend {
    font-size: 1em;
    font-weight: var(--font-weight-semibold);
    margin-top: 1em;
    color: var(--bs-dark)
}
.filter-suggestion-tags {
    min-width: 240px;
}
label.filter-item {
    margin-bottom: .5rem;
}
</style>
