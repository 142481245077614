import * as types from '../mutation-types'

const state = {
    allFolders: [ ],
    currentFolder : false
}

const getters = {
    folders : state => state.allFolders,
    currentFolder : state => state.currentFolder
}

const mutations = {
    [types.ADD_FOLDER](state, {folder}){
        let existing = state.allFolders.find(f => f.id == folder.id)
        if ( ! existing){
            state.allFolders.push(folder)
        } else if(existing.updated < folder.updated) {
            let index = state.allFolders.indexOf(existing)
            state.allFolders.splice(index, 1, folder)
        }
    },
    [types.ADD_FOLDERS](state, {folders}){
        state.allFolders = state.allFolders.concat(folders.filter(t => !state.allFolders.find(e => e.id == t.id)));
    },
    [types.USER_DID_LOG_OUT](state) {
        state.allFolders = [];
        state.currentFolder = false;
    },
    [types.SET_FOLDER](state, {folder}){
        state.currentFolder = folder
    },
    [types.ADD_LOCAL_FOLDER_TO_ACCOUNT](state, { folder }) {
        state.allFolders.push(folder)
    },
    [types.UPDATE_TOPIC](state){
        state.allFolders = [...state.allFolders]
    },
    [types.DELETE_FOLDER](state, {folder}){
        if (folder)
            state.allFolders = state.allFolders.filter(f => f.id !== folder.id);
    },
    [types.ADD_FOLDER_TO_FOLDER](state, {folder, parent}){
        
        var folderToUpdate = state.allFolders.find(f => {
            return f.id == parent.id
        })

        if (folderToUpdate){
            let index = state.allFolders.indexOf(folderToUpdate)
            folderToUpdate.children.push(folder.id)
            state.allFolders.splice(index, 1, folderToUpdate)
        }

    },
    [types.MOVE_TOPICS_TO_FOLDER](state, {topics, folder}){
        topics.forEach(topic => {
            removeTopicFromFolder({topic, state})
            addTopicToFolder({topic, folder, state});
        })
    }
}

const removeTopicFromFolder = ({topic, state}) => {
    var folderToUpdate = state.allFolders.find(f => {
        return topic.folder.includes(f.id)
    })

    if (folderToUpdate) {
        let index = state.allFolders.indexOf(folderToUpdate)
        if ( ! folderToUpdate.topics) folderToUpdate.topics = [];
        
        folderToUpdate.topics = folderToUpdate.topics.filter( t => {
            return ! t.includes(topic.id)
        })
        state.allFolders.splice(index, 1, folderToUpdate)
    }
}

const addTopicToFolder = ({ topic, folder, state }) => {
    var folderToUpdate = state.allFolders.find(f => {
        return f.id == folder.id
    })

    if (folderToUpdate) {
        let index = state.allFolders.indexOf(folderToUpdate)
        if (folderToUpdate.topics.indexOf(topic.id) == -1){
            folderToUpdate.topics.push(topic.id)
            state.allFolders.splice(index, 1, folderToUpdate)
        }
    }
}

export default {
    state,
    getters,
    mutations
}