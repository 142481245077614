
export const fieldOptions = [
    {
        value: 'title',
        description: 'Title',
        type: 'text',
    },
    {
        value: 'all_text',
        description: 'All Text',
        type: 'multi-text',
    },
    {
        value: 'full_text',
        description: 'Article Body',
        type: 'text',
    },
    {
        value: 'summary',
        description: 'Abstract',
        type: 'text',
    },
    {
        value: 'publisher',
        description: 'Publisher Name',
        type: 'text',
    },
    {
        value: 'url',
        description: 'URL',
        type: 'text',
    }
];

export const operationOptions = [
    {
        value: "contains exact_word_match",
        description: "Contains word",
        types: ['text', 'multi-text'],
    },
    {
        value: "does_not_contain exact_word_match",
        description: "Does not contain word",
        types: ['text', 'multi-text'],
    },
    {
        value: 'does_not_contain',
        description: 'Does not contain text',
        types: ['text', 'multi-text'],
    },
    {
        value: 'contains',
        description: 'Contains text',
        types: ['text', 'multi-text'],
    },
    {
        value: 'starts_with',
        description: 'Starts with',
        types: ['text'],
    },
    {
        value: 'not_starts_with',
        description: 'Does not start with',
        types: ['text'],
    },
    {
        value: 'has_value',
        description: 'Has a value',
        types: ['text'],
    },
    {
        value: 'not_has_value',
        description: 'Does not have a value',
        types: ['text'],
    },
];
