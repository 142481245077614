<template>
    <div>
        <div class="stages-container">
            <div 
                v-for="(tStage, index) in stages" 
                :key="tStage" class="text-center" 
                :class="{'active' : tStage == stage, 'completed' : index < stageIndex}"
            >
                <span class="indicator">
                    <span v-if="index >= stageIndex">{{ index + 1 }}</span>
                    <span v-else><i class="fa fa-check"></i></span>
                </span>
                {{tStage}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : ['stage', 'stages', 'stageIndex'],
    data(){
        return {
            
        }
    },
    mounted(){

    },
    methods : {

    }
}
</script>

<style scoped>
.stages-container {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    font-size: var(--font-size-13);
    color : black;
    background: white;
}

.completed {
    color:  var(--success);
}

.stages-container .active {
    font-weight: 500;
    color : var(--primary);
}

.active .indicator {
    color: var(--primary-25);
    font-weight: bold;
    background: var(--primary-200)
}
.indicator {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 1.6em;
    height: 1.6em;
    border-radius: 1.6em;
    font-size: 18px;
    color: black;
    font-weight: 100;
    background: none;
    border: 3px solid var(--primary);
    margin: 0 auto 5px;
    padding: 1em;
}

.completed .indicator {
    background: var(--green-100);
    border-color: var(--success);
    color: white;
}
</style>
