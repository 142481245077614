<template>
        <p v-if="!isReviewer" ><button @click="showCollectionBuilder"
                                       class="btn btn-outline-danger btn-sm full-width mt-2">
            <i class="fa fa-plus"></i> New Collection</button>
        </p>
</template>
<script>
import Roles from '@/utilities/Roles'
import {mapGetters} from "vuex";

export default {
    name: "SidebarNewCollectionButton",
    mixins : [Roles],
    methods : {
        showCollectionBuilder(){
            if (this.accountFeatures.newCollectionUx) {
                window.Events.$emit('show_editCollectionModal', {collection: null});
            } else {
                window.Events.$emit('showCollectionCreationModal');
            }
        }
    },
    computed: {
        ...mapGetters({
            accountFeatures: 'accountFeatures'
        })
    }
}
</script>

