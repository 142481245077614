<template>
    <div class="topics">
        <div class="topic-search-field" v-if="accountTopics.length > 10">
                <a v-if="searchText" class="clear-search-text" href="#" @click.prevent.stop="searchText = ''">
                    <i class="fa fa-times-circle"></i>
                </a>
                <input 
                    class="form-control input-sm mb-2" 
                    v-model="searchText"
                    @focus="searchFocus" 
                    @blur="searchBlur($event)" 
                    placeholder="Search topics..." 
                    @click.stop.prevent
                />  
            </div>

        <folder 
            @searchFocus="searchFocus" 
            @searchBlur="searchBlur" 
            v-if="currentAccountOverview"
            :isRoot="true" 
            :level="0" 
            :folder-id="currentAccountOverview.root"
            :search-text="searchText"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Folder from './RecursiveFolderContainer';

export default {
    components : {
        Folder
    },
    data(){
        return {
            searchText : ''
        }
    },
    computed : {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
        }),
        accountTopics(){
            console.log('* TopicsByFolder accountTopics - need this.folders')
            //console.log('* TopicsByFolder accountTopics this.topics.filter(t => t.account.includes(this.currentAccountOverview.id))', this.topics.filter(t => t.account.includes(this.currentAccountOverview.id)))
            return this.currentAccountOverview.topics
        }
    },
    methods : {
        searchFocus(){
            window.Events.$emit('expandSidebar')
        },
        searchBlur(){
            window.Events.$emit('contractSidebar')
        }
    }
}
</script>

<style scoped>
    .topics {
        margin-bottom: 8px;
    }
</style>
