<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    computed : {
        ...mapGetters({
            features: 'features',
            filters: 'currentFilters',
            currentTopic: 'currentTopic',
            hasSuggestions: 'hasSuggestions',
            accountFeatures: 'accountFeatures',
        }),
        topicLanguage() {
            if (!this.hasSuggestions || this.hasSuggestions.type !== 'topic') return null;
            let culture = this.currentTopic.culture;
            return Boolean(culture) && culture.split('-')[0]
        },
    },
    methods : {
        ...mapActions({
            filterValueChange: 'filterValueChange',
            filtersChanged: 'filtersChanged'
        }),
        updateFilter(key, value){
            if (this.filters[key] !== value)
                this.filterValueChange({key, value});
        },
        updateFilters(data) {
            this.filtersChanged({filters: data})
        }
    }
}
</script>
