<template>
    <div class="powerup details" :class="{hide: visible === false, open}">
        <div class="summary">
            <div class="power-up-label d-flex justify-content-between align-items-center mb-2">
                <div class="text-nowrap">
                    <i :class="iconClass" class="me-1"/>
                    <span>{{ label }}</span>
                </div>
                <div v-if="!disableReason" class="powerup-action">
<!--                    <toggle-switch v-if="authorized && canTurnOnOff"-->
                    <toggle-switch v-if=hasApiKey
                                   v-model="val"
                                   class="powerup-toggle"
                                   :aria-label="`Connected to ${label}`"/>
                </div>
            </div>
            <div class="text-muted small text-wrap">
                <div class="mb-1">{{ description }}</div>
                <router-link v-if="!hasApiKey" to="/settings#integrations" target="_blank">Set API key</router-link>
            </div>
            <div class="error powerup-message" v-if="disableReason">{{ disableReason }}</div>
        </div>
        <div v-if="hasApiKey" v-show="open" class="ps-3 pe-3">
            <slot/>
        </div>
    </div>
</template>

<script>

import ToggleSwitch from "@/components/Controls/ToggleSwitch.vue";

export default {
    name: "ApiKeyIntegrationSettingsContainer",
    components: {ToggleSwitch},
    props: {
        accountData: {type: Object},
        label: String,
        description: String,
        iconClass: [String, Object],
        canTurnOnOff: {type: Boolean, default: true},
        visible: {type: Boolean, default: null},
        disableReason: {type: [String, Boolean], default: ''},
        value: Boolean,
    },
    data() {
        return {
            val: this.value,
            integrationsPageUrl: '' // TODO link to /settings#integrations route
        };
    },
    computed: {
        authorized() {
            return this.isAuthorized();
        },
        hasApiKey() {
            return this.accountData.openai_api_key;
        },
        open() {
            return this.value && this.authorized;
        }
    },
    watch: {
        value() {
            // console.log('powerup.active.changed', this.value, this.authorized)
            this.val = this.value;
        },
        val() {
            this.$emit('input', this.val);
        },
    },
    methods: {
        isAuthorized() {
            return !!this.accountData.openai_api_key
        },
    }
}

</script>

<style scoped>
.power-up-label .icon-dynamic {
    vertical-align: top;
    margin-top: .2em;
}

.power-up-label .amplify-icon, .power-up-label .sniply-icon {
    margin-top: .25em;
}

.power-up-label {
    display: inline-block;
}

.power-up-label a {
    text-decoration: underline;
}

.powerup-action {

}

.powerup .summary {
    background: var(--bs-light);
    border-bottom: 1px solid var(--bs-default);
    list-style: none;
    list-style-type: none;
    color: var(--bs-dark);
    position: relative;
}

.details.powerup .summary::-webkit-details-marker {
    display: none;
}

.powerup {
    border: 1px solid var(--bs-default);
}

.powerup.open {
    padding-bottom: 1em;
}


.powerup.open .summary {
    margin-bottom: 10px;
}

.powerup:not(:last-child) {
    border-bottom: none;
}

.powerup-message {
    display: inline-block;
    color: var(--bs-danger);
    font-size: 0.7em;
    max-width: 360px;
    margin-left: 10px;
    vertical-align: middle;
    white-space: pre;
}

.powerup .summary {
    vertical-align: middle;
}

.summary {
    padding: 0.5em;
}
</style>

