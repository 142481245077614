<template>
    <div class="d-flex me--1 social-selector flex-wrap">
        <div v-for="channel in allChannels" :key="channel.id" class="social-button">
            <highlight-checkbox v-if="multiple" v-model="selectedChannels" :value="channel.id" class="align-items-center inline-label full-width">
                <!--<div class="d-inline-block">-->
                <!--<input type="checkbox" v-model="selectedChannels" :value="channel.channelGuid">-->
                <!--</div>-->
                <social-profile :name="channel.profileName" :network="channel.networkName" :icon="channel.icon" :avatar-url="channel.avatarUrl"></social-profile>
            </highlight-checkbox>
        </div>
    </div>
</template>

<style>
    /* There is an unusual .inline-label class in this file that could have been impacted by filter view changes */
    /* Original CSS
    .inline-label {
    display: inline-block;
    color: map-get($theme-colors, primary);
    } */

    .social-selector .social-button {
        width: 15em;
        flex-grow: 1;
        margin-right: 1em;
    }
    .social-selector .social-button label {
        padding: .5em 1em;
        height: calc(100% - 1em);

    }
    .me--1 {
        margin-right: -1em;
    }
</style>

<script>
    import Modal from "@/components/Controls/Modal";
    import {mapGetters} from 'vuex'
    import SocialProfile from "@/components/Controls/SocialProfile";
    import HighlightCheckbox from "@/components/Controls/HighlightCheckbox";
    export default {
        components: {HighlightCheckbox, SocialProfile, Modal},
        props: {
            allChannels: {type: Array, required: true},
            value: {type: [Array, Boolean] },
            multiple: {type: Boolean, default: false},
        },
        watch: {
            selectedChannels() {
                this.$emit('input', this.selectedChannels);
            },
            value() {
                this.selectedChannels = this.value;
            }
        },
        methods: {
        },
        data() {
            return {
                selectedChannels: this.value,
            }
        },
    }
</script>
