<script setup> // FIXME: add ts
import {computed, onMounted, ref, toRefs, watch} from "vue";
import {UcSelect} from "@/components/Controls";
import {operationOptions, fieldOptions} from "@/constants/ContentPolicies/Constants"

const props = defineProps({
    rulesList: { type: Array, default: () => ([]) },
});

const currentRulesList = ref(props.rulesList || []);


const emits = defineEmits([
    'ruleChange',
    'removeRule',
]);

watch( () => props.rulesList, () => {
    console.log('* watch for rulesList props.rulesList', props.rulesList)
    currentRulesList.value = props.rulesList;
    console.log('* watch for rulesList currentRulesList.value', currentRulesList.value)
},   {deep: true});

const removeRule = (rule, index) => {
    emits('removeRule', rule, index);
};

const fieldChanged = (rule, updatedValue) => {
    console.log('* fieldChanged')
    emits('ruleChange', {...rule, field: updatedValue})
};
const operationChanged = (rule, updatedValue) => {
    console.log('* operationChanged')
    emits('ruleChange', {...rule, operation: updatedValue})
};

const ruleValueChanged = (rule, updatedValue) => {
    console.log('* ruleValueChanged')
    emits('ruleChange', {...rule, ruleValue: updatedValue})
};
</script>

<template>
    <table class="table">
        <thead>
        <tr>
            <th scope="col">Field</th>
            <th scope="col">Operation</th>
            <th scope="col">Value</th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="(rule, index) in rulesList" :key="index">
                <td class="ps-0">
                    <select
                        disabled
                        required
                        :placeholder="'Select a field...'"
                        :value="rule.field"
                        @change="event => fieldChanged(rule, event.target.value)"
                        class="d-block form-select form-select-sm"
                    >
                        <option v-for="field in fieldOptions" :key="field.value" :value="field.value">
                            {{field.description}}
                        </option>
                    </select>
                </td>
                <td class="ps-0">
                    <select
                        disabled
                        required
                        :placeholder="'Select an operation...'"
                        :value="rule.operation"
                        @change="event => operationChanged(rule, event.target.value)"
                        class="d-block form-select form-select-sm"
                    >
                        <option v-for="op in operationOptions" :key="op.value" :value="op.value">
                            {{op.description}}
                        </option>
                    </select>
                </td>
                <td class="ps-0">
                    <input
                        :disabled="rule.operation === 'has_value'"
                        required
                        type="text"
                        :value="rule.ruleValue"
                        @change="event => ruleValueChanged(rule, event.target.value)"
                        @keydown.enter.prevent
                        placeholder="Enter value"
                        class="form-control form-control-sm d-inline-block"
                    />
                </td>
                <td class="pe-0 ps-0">
                    <button
                        type="button"
                        @click="removeRule(rule, index)"
                        class="btn btn-danger pull-right btn-sm"
                    >
                        <i class="fa fa-trash"/>&nbsp;
                        <span class="d-none d-xl-inline">Remove</span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
