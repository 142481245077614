import _ from 'lodash';

export const limitRequests = (count, action) => {
    let actions=[], queue = [];
    return async x => {
        if (actions.length < count) {
            actions.push();
            await action(x);
            actions = actions.filter(a => a !== action);
            await limitRequests(count, action);
        } else {
            queue.push(actions)
        }
    }
};

export default class ActionLimiter {
    constructor(count=6) {
        this.count = count;
        this.actions=[];
        this.queue = [];
        setTimeout(() => { if (this.actions.length < this.count) this.doNext() }, 25)
    }

    doAction(action, priority=0, key=null) {
        let getPromise = () => {
            let resolve, reject;
            let promise = new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
            return { promise, resolve, reject } ;
        };

        let {promise, resolve, reject} = getPromise(action);
        this._limitActions(action, priority, key, resolve, reject);

        return promise;
    }

    doNext() {
        let resolveAll = resolvers => val => resolvers.forEach(resolve => resolve(val));
        let rejectAll = rejecters => val => rejecters.forEach(reject => reject(val));
        let next = this.queue.shift();
        if (next)
            this._limitActions(next.action, next.priority, next.key, resolveAll(next.resolvers), rejectAll(next.rejecters))
    }

    async _limitActions (action, priority, key, resolve, reject) {
        let success = false;
        try {
            if (this.actions.length < this.count) {
                this.actions.push(action);
                this.queue = this.queue.filter(a => a !== action);
                try {
                    let x = await action();
                    success = true;
                    resolve(x);
                } catch (e) {
                    reject(e)
                } finally {
                    this.actions = this.actions.filter(a => a !== action);
                    if (this.actions.length < this.count) this.doNext();
                }
            } else {
                this._addToQueue(action, priority, key, resolve, reject)
            }
        } catch(e) {
            setTimeout(() => { if (this.actions.length < this.count) this.doNext()}, 1)
            if (!success) reject(e)
        }
    }

    _addToQueue (action, priority, key, resolve, reject) {
        let existing = key && this.queue.find(x => x.key === key);
        if (existing) {
            existing.priority = Math.max(existing.priority, priority);
            existing.resolvers.push(resolve);
            existing.rejecters.push(reject);
        } else {
            this.queue.push({action, priority, key, resolvers: [resolve], rejecters: [reject]});
        }

        this.queue = _.sortBy(this.queue, x =>
            typeof x.priority === 'number' ? -x.priority :
                typeof x.priority === 'function' ? -x.priority() :
                    0
        )
    }
}



