<template>
    <div>
        <div>
            <h4 class="subtitle">Published Since</h4>
            <p class="filter-item">
                <label @click.stop class="undated-filter">
                    <input type="checkbox" v-model="evergreen" @change="updateEvergreen" />&nbsp;
                        Undated Suggestions
                    <span class="text-success" v-if="evergreen">
                        <i class="fa fa-tree ms-1"></i>
                    </span>
                </label>
            </p>
            <hr class="mt-0 mb-2" />
            <p class="filter-item" v-for="thisType in availableTimeTypes" :key="'content-types-'+thisType">
                <label @click.stop="customTime = ''">
                    <input
                        @change="event => timeChanged(event.target.value)"
                        type="radio" class="form-check-input"
                        :value="thisType"
                        :checked="time === thisType"
                        name="suggestions-filters-age"
                        @click.stop
                    >
                    {{thisType}}
                </label>
            </p>
            <input
                class="custom-time-input"
                @change="event => customTimeChanged(event.target.value)"
                @input="event => clearCustomTimeIfBlank(event.data)"
                :value="customTime"
                placeholder=" e.g. 1 year ago, 4 months ago "
            />
        </div>
    </div>
</template>

<script>


import {mapActions, mapGetters} from 'vuex';
import Base from './FilterMixin';

export default {
    mixins : [Base],
    data(){
        return {
            timeLocal: this.time,
            evergreen : false
        }
    },
    computed : {
        ...mapGetters({
            time: "filterTime",
            customTime: "filterCustomTime",
            availableTimeTypes: "availableTimeTypes",
        }),
    },
    watch: {
        time() {
            this.timeLocal = this.time;
        },
        filters() {
            if (this.filters) {
                this.evergreen = this.filters.evergreen
            }
        },
    },
    mounted(){
        this.evergreen = this.filters.evergreen
    },
    methods : {
        ...mapActions({
            timeChangedAction: "filtersTimeChanged",
            customTimeChangedAction: "filtersCustomTimeChanged",
        }),
        async timeChanged(value) {
            try {
                await this.timeChangedAction({time: value})
            } catch (error) {
                alert(error)
                /// IN Action
                /// return Promise.reject("Error Message")
            }
        },
        async customTimeChanged(value) {
            try {
                await this.customTimeChangedAction({customTime: value})
            } catch (error) {
                alert(error)
                /// IN Action
                /// return Promise.reject("Error Message")
            }
        },
        clearCustomTimeIfBlank(value) {
            if (!value) {
                this.customTimeChangedAction({customTime: ""})
            }
        },
        updateEvergreen() {
            this.updateFilter('evergreen', this.evergreen);
        },
    }
}
</script>

<style>
    .undated-filter {
        min-width: 13.5em; /* Using em so that if font size changes this still keeps it from wrapping, there is probably a better prop but none of the one's I know are working */
    }
</style>
