export default [
    {
        name: "Orchestrator",
        durations: [
            {
                name: "Monthly",
                id: 'uc_orchestrator_1m',
                cost: 26500
            },
            {
                name: "Annual",
                id: 'uc_orchestrator_12m',
                cost: 286200
            }
        ],
        description: `Bring structure to your complex curation strategy - and analyze impact. `,
        icon : '/assets/images/subscriptions/orchestrator.png',
        userCount: 'UNLIMITED',
        topicCount: 100,
        collectionCount: 9,
    },
    {
        name: "Engager",
        durations: [
            {
                name: "Monthly",
                id: 'uc_engager_1m',
                cost: 9500
            },
            {
                name: "Annual",
                id: 'uc_engager_12m',
                cost: 102600
            }
        ],
        description: `Deploy a curation strategy across multiple channels - in a way that converts. `,
        icon : '/assets/images/subscriptions/engager.png',
        userCount: 'UNLIMITED',
        topicCount: 50,
        collectionCount: 3,
    },
    {
        name: "Curator",
        durations: [
            {
                name: "Monthly",
                id: 'uc_curator_1m',
                cost: 4900
            },
            {
                name: "Annual",
                id: 'uc_curator_12m',
                cost: 52900
            }
        ],
        description: ` Showcase your curated perspectives on your website to become a trusted resource.`,
        icon : '/assets/images/subscriptions/curator.png',
        userCount: 'UNLIMITED',
        topicCount: 10,
        collectionCount: 1,
    },
    {
        name : "Sharer",
        durations : [
            {
                name : "Monthly",
                id : 'uc_sharer_1m',
                cost : 1500
            },
            {
                name : 'Annual',
                id : 'uc_sharer_12m',
                cost : 16200
            }
        ],
        description: `Source compelling, curated content for your digital marketing strategy.`,
        icon : '/assets/images/subscriptions/sharer.png',
        userCount: 'UNLIMITED',
        topicCount: 10,
        collectionCount: 1,
    },
    {
        name: "Enterprise",
        durations: [
            {
                name: "Monthly",
                id: 'uc_orchestrator_1m',
                cost: 50000
            },
            {
                name: "Annual",
                id: 'uc_orchestrator_12m',
                cost: 500000
            }
        ],
        description: `Bring structure to your complex curation strategy - and analyze impact. `,
        icon : '/assets/images/subscriptions/enterprise.png',
        userCount: 'NEGOTIABLE',
        topicCount: 'NEGOTIABLE',
        collectionCount: 'NEGOTIABLE',
    },
    {
        name: "Personal",
        durations : [],
        description: `<p>Great for the casual user</p>
                            <ul>
                                <li>1 topic</li>
                                <li>1 user</li>
                            </ul>`,
    }
]
