/* eslint-disable no-unused-vars */
import features from '@/Features'
import mapProps from "@/utilities/mapProps";

const env = process.env;
const qa = env.VUE_APP_API_BASE_URL.includes('qa');
const dev = window.location.hostname === 'localhost';
const prod = !qa && !dev;

const partnerDomains = ['hubspot.com', 'upcontent.com', 'mailchimp.com', 'rsglab.com', 'shutterstock.com', 'hootsuite.com']
const featureConditions = {
    qa: (state) =>  state.qa,
    upcontent: (state, getters, rootState, rootGetters) =>
        rootGetters.currentUser && rootGetters.currentUser.email &&
        rootGetters.currentUser.email.includes('@upcontent.com'),
    partner: (state, getters, rootState, rootGetters) =>
        rootGetters.currentUser && rootGetters.currentUser.email &&
        partnerDomains.some(x => rootGetters.currentUser.email.includes('@'+x)),
    dev: (state) => state.dev
};

const featureEnabled = (featureVal, state, getters, rootState, rootGetters) =>
    featureVal === true || (
        featureConditions[featureVal]
            ? featureConditions[featureVal](state, getters, rootState, rootGetters)
            : featureVal
    );

const state = {
    qa, prod, dev,
    apiDomain: env.VUE_APP_API_BASE_URL,
    features,
    featureToggles: features,
    learnUrls: {
        amplify: "http://learn.upcontent.com/en/articles/4839034-curate-by-upcontent-and-hootsuite-amplify",
        sniply: "http://learn.upcontent.com/en/articles/3938957-collections-and-sniply",
        lately: "http://learn.upcontent.com/en/articles/3938966-integrating-lately-with-collections",
        constantContact: "http://learn.upcontent.com/en/articles/6787194-use-constant-contact-to-craft-a-curated-email-digest",
        icebreaker: "http://learn.upcontent.com/en/collections/2886399-icebreaker-by-upcontent",
        hubspotEmail: "http://learn.upcontent.com/en/articles/4436043-use-hubspot-to-craft-a-curated-email-digest",
        mailchimp: "http://learn.upcontent.com/en/articles/4449656-use-mailchimp-to-craft-a-curated-email-digest",
        customHtmlEmail: "http://learn.upcontent.com/en/articles/4343932-how-to-create-a-custom-html-email-using-upcontent",
        qualityChecks: "http://learn.upcontent.com/en/collections/2579264-upcontent-quality-checks",
        howUpContentWorks: "http://learn.upcontent.com/en/articles/5606781-understanding-how-upcontent-works",
        rssErrors: "http://learn.upcontent.com/en/articles/3938965-rss-feed-errors-in-collections",
        filterViews: "http://learn.upcontent.com/en/collections/3361863-filter-views",
        whatMakesAGoodTopic: "http://learn.upcontent.com/en/articles/1761089-guided-vs-advanced-query-builder",
        everyoneSocial: "",
        contentPolicies: "http://learn.upcontent.com/en/collections/7851525-content-policies",
        openaiApiKey: "http://learn.upcontent.com/en/collections/7934969-post-text-generation",
    },
    searchTerms: {
        amplify: 'amplify hootsuite employee advocacy engagement social',
        everyoneSocial: 'everyone social employee advocacy',
        sniply: 'sniply call to action cta conversion',
        lately: 'lately social generation',
        constantContact: 'constant contact email',
        hubspotEmail: 'hubspot email digest',
        icebreaker: 'hubspot email icebreaker',
        mailchimp: 'mailchimp email digest',
        customHtmlEmail: 'html email digest',
        qualityChecks: 'proof point proofpoint quality check',
        openai: 'openai open ai machine learning generative',
    },
    descriptions: {
        amplify: "Empower your team and reach your audience.",
        everyoneSocial: "Your employee advocacy program.",
        sniply: "Drive conversions through content curation.",
        lately: "Create dozens of social posts in a single click.",
        constantContact: "Engage your audience with email digests.",
        hubspotEmail: "Engage your audience with email digests.",
        icebreaker: "Engage your contacts directly within HubSpot.",
        mailchimp: "Engage your audience with email digests.",
        customHtmlEmail: "Create beautiful newsletters that you can export for use with any email client",
        qualityChecks: "Check your content against various quality checks your organization has setup",
        openai: "Generative AI Post Text Configurations",
    },
    powerupNameMap: {
        amplify: 'amplify',
        everyone_social: 'everyoneSocial',
        sniply: 'sniply',
        lately: 'lately',
        constant_contact: 'constantContact',
        hubspot: 'hubspotEmail',
        icebreaker: 'icebreaker',
        mailchimp: 'mailchimp',
        mail: 'customHtmlEmail',
        proofpoint : 'qualityChecks',
        openai: 'openai',
    }
};

const actions = {
    setFeatureFlag : ({commit}, {name, value}) => {
        commit("SET_FEATURE_FLAG", {name, value});
    },
    switchAccount: async ({commit, getters, state, rootState, rootGetters}, {accountId}) => {
        let [account, user] = [rootGetters.account, rootGetters.currentUser];
        let accountMeta = {
            plan: account.plan,
            user_email: user.email,
            account_id: account.id,
            account_name: account.name,
            account_created: account.created
        }
    }
}

const getters = {
    features: (state, getters, rootState, rootGetters) =>
        mapProps(state.features, ({val}) =>
            !!featureEnabled(val, state, getters, rootState, rootGetters)
        ),
    userIsUpcontent: featureConditions.upcontent,
    userIsPartner: featureConditions.partner,
    apiDomain: state => state.apiDomain,
    apiHost: state => `https://${state.apiDomain}`,
    dev: state => !!state.dev,
    qa: state => !!state.qa,
    prod: state => !!state.prod,
    configLimits: (state, getters) => ({
        rssFeedRefresh: getters.userIsUpcontent ? 15 : 30
    }),
    learnUrls: (state, getters , rootState, rootGetters) =>
        rootGetters.whitelabelConfig && rootGetters.whitelabelConfig.learnUrls ?
            Object.keys(state.learnUrls).reduce((result, key) => ({...result, [key]: rootGetters.whitelabelConfig.learnUrls[key]||null}), {}) :
            state.learnUrls,
    powerupSearchTerms: state => state.searchTerms,
    powerupDescriptions: state => state.descriptions,
    powerupNameMap: state => state.powerupNameMap
}



const mutations = {
    SET_FEATURE_FLAG : (state, { name, value }) => {
        state.features[name] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
