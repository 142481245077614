<template>
    <copy-paste :value="value" ref="clipboard"></copy-paste>
</template>

<style>

</style>

<script>
    import CopyPaste from "@/components/Controls/CopyPaste";
    export default {
        components: {CopyPaste},
        mounted() {
            window.Events.$on('clipboardCopy', this.copy)
        },
        data() {
            return {value: ''}
        },
        methods: {
            copy(val) {
                this.value = val
                setTimeout(() => this.$refs.clipboard.copy(), 200)
            }
        }
    }
</script>
