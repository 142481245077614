<template>
    <div class="card">
        <div class="card-header">{{product_name||''}}</div>
        <div class="card-body">
            <h3>${{amount_disp[0]}}<sup class="small">.{{amount_disp[1]}} / {{$strings.capitalize(interval_disp||'')}}</sup></h3>
            <div style="font-size: 90%">For:</div>
            <ul class="no-bullets ps-0"  style="min-height: 3em; font-size: 90%">
                <li v-for="a in amountList" :key="a.name">
                    {{totalAmountPerPrice(a)}} {{displayUnits(a)}}
                </li>
            </ul>
        </div>
        <div class="card-footer">
            <progress-bar v-if="loading"></progress-bar>
            <form v-else-if="editing">
                <label class="full-width text-start">
                    Quantity
                    <select v-if="isDropdown" class="form-control form-select" v-model="quantity">
                        <option v-for="i in [1,2,3,4,5,6,7,9,10]" :key="i" :value="i*increment">{{optionLabel(i)}}</option>
                    </select>
                    <input v-else type="number" class="form-control" v-model="quantity">
                </label>
                <button class="btn btn-primary full-width mb-2" type="button" @click="updateBooster()">
                    Update Subscription
                </button>
                <button class="btn btn-secondary full-width" type="button" @click="cancel">
                    Cancel
                </button>
            </form>
            <div v-else-if="quantity > 0">
                <label>
                    <div class="small text-dark">You currently have</div>
                    <output class="">{{quantity/increment}} of this booster</output>
                </label>
                <label>
                    <div class="small text-dark">Including base plan and boosters this gives you:</div>
                    <output class="">
                        <ul class="no-bullets ps-0 ">
                            <li v-for="a in amountList" :key="a.name">
                                {{limits(a.name)}} {{displayUnits(a, limits(a.name))}}
                            </li>
                        </ul>
                    </output>
                </label>

                <button class="btn-primary btn full-width" type="button" @click="editing=true" >Change</button>
            </div>
            <div v-else>
                <button class="btn-primary btn full-width" type="button" @click="newSub" >Purchase</button>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
    import {mapGetters} from 'vuex';
    import ProgressBar from "@/utilities/ProgressBar";
    export default {
        name: 'Booster',
        components: {ProgressBar},
        props: { value: {}, account:{}, subscription:{} },
        computed:{
            ...mapGetters({
                features: 'features'
            }),
            amountList() {
                return Object.entries(this.amounts).map(([k, v]) => ({ name: k, ...v}))
            },
            isDropdown() {
                let hasSingleItem = this.amountList.length === 1;
                if (!hasSingleItem) return true;

                let amount = this.totalAmountPerPrice(this.amountList[0]);
                return amount >1;

            }
        },
        data() {
            return Object.assign({editing: false, loading: false}, this.value)
        },
        watch: {
            value() {
                Object.assign(this, this.value)
            },
        },
        methods: {
            async updateBooster() {
                this.loading = true;
                await this.$api.put(`/accounts/${this.account.id}/subscription/boosters/${this.id}`, {
                    quantity: this.quantity
                });
                this.editing = false;
                this.loading = false;
                this.$api.get('/accounts/'+this.account.id+'/subscription')
                    .then(resp => {
                        console.log({subscription : resp.data, account: this.account})
                        this.$store.dispatch('addSubscriptionToAccount', {subscription : resp.data, account: this.account});
                        this.$emit('updateComplete', resp.data)
                    })
                    .catch( () => {

                    });
            },
            newSub() {
                this.editing=true;
                this.quantity=1;
            },
            totalAmountPerPrice(a) {
                return this.increment * a.value * this.periodMultiplier(a.per_period)
            },
            periodMultiplier(per_period) {
                return per_period && this.interval_disp==='year' ? 12 : 1
            },
            displayPeriod(per_period) {
                return per_period ? this.interval_disp==='year' ? 'per year' : 'per month' : ''
            },
            displayUnits(a, amount=null) {
                let singular = (amount||this.totalAmountPerPrice(a)) === 1;
                let base = singular ? a.singular : a.plural;
                let period = this.displayPeriod(a.per_period);

                return [base, period].filter(Boolean).join(' ');
            },
            cancel() {
                this.editing=false;
                this.quantity = this.value.quantity || 0;
            },
            optionLabel(i) {
                let hasSingleItem = this.amountList.length === 1;
                if (hasSingleItem) {
                    let amount = this.totalAmountPerPrice(this.amountList[0])*i;
                    return `${amount} ${this.displayUnits(this.amountList[0], amount)}`
                } else {
                    return `${i} ${i === 1 ? 'booster' : 'boosters'}`
                }
            },
            limits(name) {
                return this.subscription.plan_items.reduce((sum, item) => {
                    if (sum == 'unlimited' || item[name] === 'unlimited') return 'unlimited';
                    return Number(sum) + Number(item[name]||0) * Number(item.quantity||1)
                }, 0)
            },
        }
    }
</script>
