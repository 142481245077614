<template>
    <div class="tabs-component" :class="{ vertical }">
        <tab-bar :tabs="tabs" ref="tabBar"></tab-bar>
        <slot />
    </div>
</template>

<style lang="scss">
    $grid-breakpoints: (
            xs: 0,
            sm: 576px,
            md: 768px,
            lg: 992px,
            xl: 1200px
    ) !default;
    @media (min-width: 992px) {
        .tabs-component.vertical {
            display: flex;
            .tab-bar {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: max-content;
                .tab {
                    flex-grow:  0;
                    text-align: left;
                    width: 100%;
                    align-items: flex-start;
                    .tab-bar-tab-content {
                        text-align: left;
                        width: max-content;
                        padding-right: 10px;
                    }
                    border-left: 10px solid transparent;
                }
                .tab.active {
                    border-left: 10px solid var(--bs-primary);
                    /*border-bottom: 1px solid var(--bs-primary);*/
                    /*border-top: 1px solid var(--bs-primary);*/
                    border-bottom: none;
                    .tab-bar-tab-content {
                        /*border-bottom: 2px solid var(--bs-primary);*/
                        color: var(--bs-primary)
                    }
                    .tab-label, .tab-icon {
                        /*text-decoration: underline;*/
                        font-weight: var(--font-weight-semibold);
                    }
                }
                min-height: 100%;
                margin-bottom: 0;
                margin-right: 10px;
            }
            .tab-content {
                width: 100%;
            }
        }
    }
</style>

<script>
    import TabBar from './TabBar'
    import {focusableQuery} from "@/Constants";
    export default {
        components: { TabBar },
        props: { vertical: { type: Boolean, default: false }},
        data() {
            return {
                tabs: [],
                tabContentElements:[]
            }
        },
        mounted() {
            let tabBar = this.$refs.tabBar;
            let tabContentElements = this.tabContentElements = this.$children.filter(tab => tab.label);

            tabBar.$on('tabChange', selectedTab => {
                tabContentElements.forEach(t => {
                    t.isActive = t.label === selectedTab.label;
                    if (t.label === selectedTab.label) {
                        let firstFocusable = t.$el.querySelector(focusableQuery);
                        window.setTimeout(() => {
                            if (t.focus === 'none') {
                                return;
                            } else if (t.focus) {
                                let el = t.$el.querySelector(t.focus)
                                if (el) el.focus()
                            } else if(firstFocusable) {
                                firstFocusable.focus()
                            }
                        },0)
                    }
                });
                this.tabs.forEach(t => {
                    t.active = t.label === selectedTab.label;
                })
            });

            this.initializeTabBarAndActive(tabContentElements);
        },
        methods: {
            resetActiveTab() {
                this.initializeTabBarAndActive(this.tabContentElements)
            },

            initializeTabBarAndActive(tabContentElements) {
                let tabs = tabContentElements.map(this.tabModel);

                tabContentElements.forEach(t => {
                    t.isActive = t.active;
                });

                if (!tabContentElements.some(tab => tab.active)) {
                    tabs[0].active = true;
                    tabContentElements[0].isActive = true;
                }

                this.tabs = tabs;
            },

            tabModel: tabComponent => ({
                label: tabComponent.label,
                focus: tabComponent.focus,
                iconClass: tabComponent.iconClass,
                active: tabComponent.active,
                tag: tabComponent.tag,
                id: tabComponent.id,
                visible: tabComponent.visible !== false
            }),
            goToTab(tabLabel) {
                let tabContentElements = this.tabContentElements = this.$children.filter(tab => tab.label);

                tabContentElements.forEach(t => {
                    t.isActive = t.label === tabLabel;
                    if (t.label === tabLabel) {
                        let firstFocusable = t.$el.querySelector(focusableQuery);
                        window.setTimeout(() => {
                            if (t.focus === 'none') {
                                return;
                            } else if (t.focus) {
                                let el = t.$el.querySelector(t.focus)
                                if (el) el.focus()
                            } else if (firstFocusable) {
                                firstFocusable.focus()
                            }
                        }, 0)
                    }
                })

                this.tabs.forEach(t => {
                    t.active = t.label === tabLabel;
                })
            }
        },
    }
</script>
