<script>

import { mapGetters } from 'vuex';

export default {
    props : ['member', 'team', 'roles', 'user', 'canManageTeam'],
    data(){
        return {

        }
    },
    computed : {
     ...mapGetters({
            accountMembers : 'accountTeamMembers'
        }),
        role(){
            let account = this.accountMembers.find(a => this.team.id == a.id);
            if (account){
                let member = account.members.find(m => m.id == this.member.id);
                if (member){
                    return member.role;
                }
            }
        }
    },
    methods : {
        editAssignments(){
            this.$emit('showAssignmentEditor', {member : this.member});
        },
        updatePrivileges(event){
            let newRole = event.target.value;
            if (this.$accessControl.highestRole([newRole, this.member.role]) == this.member.role){
                let x = confirm("Are you sure you want to reduce this user's privileges? Any folders this user assigned will now be your responsibility to manage.");
                if ( ! x) {
                    event.target.value = this.member.role
                    return;
                }
            }
            this.$emit('setRoleForMember', {role : newRole, member: this.member});
        },
        removeMember(){
            this.$emit('removeMember', {member : this.member});
        },
        isNotCurrentUser(member){
            return member.id !== this.user.id;
        }
    }
}
</script>

<style>
</style>