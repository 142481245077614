import RolePermissions from './RolePermissions'

export default {
    install: (Vue) => {
        Vue.prototype.$accessControl = {
            /**
             * 
             * @param {string} role The role we're comparing against 
             * @param {string|array} action The permission or 
             * permissions we're checking said role contains
             */
            can(role, action){

                function calculatedPermissions() {

                    var roles = [];

                    RolePermissions.forEach((role, index) => {
                        var permissions = RolePermissions
                            .map(r => r.permissions)
                            .slice(0, (index + 1))
                            .reduce((a, b) => {
                                return a.concat(b);
                            })
                        roles.push({ role: role.role, permissions });
                    })

                    return roles;
                }


                let roleInQuestion = calculatedPermissions().find(r => r.role == role);
                if (roleInQuestion){
                    if (Array.isArray(action)) {
                        return roleInQuestion.permissions.filter(p => action.indexOf(p) > -1).length;
                    }
                    return roleInQuestion.permissions.indexOf(action) > -1;
                }
                    
            },
            rolesForRole(role){
                let roleIndex = RolePermissions.indexOf(RolePermissions.find(r => r.role == role));
                return RolePermissions.slice(0, (roleIndex + 1)).map(r => r.role);
            },
            highestRole(rolesToCheck){
                let roles = RolePermissions.map(r => r.role);
                let sortedRoles = rolesToCheck.map(r => {
                    return {index : roles.indexOf(r), role : r};
                }).sort((a, b) => {
                    return a.index < b.index;
                });
                let highest = sortedRoles[0];
                if (highest)
                    return highest.role;
            }
        };
    }
};