
const state = {
    allContentTemplates: {}
};

const getters = {
    contentTemplates: (state, getters, rootState, rootGetters) =>
        state.allContentTemplates[rootGetters.currentAccountOverview.id]
};

const actions = {
     refreshContentTemplates: async ({commit, getters}) => {
        let accountId = getters.currentAccountOverview.id;
        try {
            let result = await window.$app.api.get(`/accounts/${accountId}/content-templates`);
            let templates = result.data;
            commit('SET_CONTENT_TEMPLATES', {accountId, templates})
        } catch (e) {
            console.error(e)
        }
    }
};

const mutations = {
    SET_CONTENT_TEMPLATES: (state, {accountId, templates}) =>
        state.allContentTemplates[accountId] = templates
};

export default {
    state,
    getters,
    actions,
    mutations
}
