<template>
    <div class="social-preview">
        <hero-image class="hero-image" :url="contentItem && contentItem.image_url" height="150px"></hero-image>
        <div v-if="contentItem" class="content ms-2">
            <h5><a :href="contentItem.link_url">{{ contentItem && contentItem.title }}</a></h5>
            <small>
                <span v-if="contentItem.publisher">{{ contentItem.publisher }}</span>
                <span v-if="contentItem.publisher && contentItem.date_published" class="me-1 ms-1">•</span>
                <span v-if="contentItem.date_published">{{ showDatePublished }}</span>
            </small>
            <div>
                <textarea v-model="postText" class="form-control" aria-label="Post Text"></textarea>
                <output class="small text-muted float-end" aria-label="Post Text Length">{{
                        postText && postText.length
                    }}
                </output>
            </div>
        </div>
    </div>
</template>

<style>
.social-preview .hero-image {
    width: 200px;
    flex-grow: 1;
}

.social-preview {
    display: flex;
    flex-wrap: wrap;
}

.social-preview .content {
    flex-grow: 30;
    width: 450px;
}

.social-preview textarea {
    height: 6em;
}
</style>

<script>
import HeroImage from "@/components/Suggestions/HeroImage";
import moment from "moment";

export default {
    components: {HeroImage},
    props: {
        value: String,
        contentItem: Object
    },
    watch: {
        value() {
            this.postText = this.value;
        },
        postText() {
            this.$emit('input', this.postText)
        }
    },
    data() {
        return {
            postText: this.value
        }
    },
    computed: {
        showDatePublished() {
            let time = this.contentItem && this.contentItem.date_published;
            return time ? moment.utc(time).fromNow() : '';
        }
    },
}
</script>
