<template>
    <div class="suggestion-sources">
        <div v-for="prop in collectionProps"
             :key="prop.id"
             class="position-relative">

            <template v-if="isCollectionSource(prop.onlyType)">
                <template v-if="sourceCount(prop.onlyType) === 0">
                    <div class="no-sources mb-1 mb-xl-0" :title="`There are no ${prop.onlyType} for this collection.`">
                        <collections-svg class="multiple top-1 nav-icon" v-if="prop.onlyType == 'collection'"/>
                        <topics-svg class="multiple top-1 nav-icon" v-if="prop.onlyType == 'topic'"/>
                        <i class="fa fa-rss nav-icon" v-if="prop.onlyType == 'rss'"></i>

                        <span class="ui-link-text">{{ prop.nameWithCount }}</span>
                    </div>
                </template>
                <drop-panel v-if="sourceCount(prop.onlyType) > 0"
                            class="source-dp"
                            :accordion-mode="false"
                            :panel-name="prop.name"
                            :panel-title="prop.onlyType"
                            :show-header="false">
                    <template v-slot:link>
                        <div class="mb-1 mb-xl-0 ui-link">
                            <collections-svg class="multiple top-1 nav-icon" v-if="prop.onlyType == 'collection'"/>
                            <topics-svg class="multiple top-1 nav-icon" v-if="prop.onlyType == 'topic'"/>
                            <i class="fa fa-rss nav-icon" v-if="prop.onlyType == 'rss'"></i>

                            <span class="ui-link-text">{{ prop.nameWithCount }}</span>
                        </div>
                    </template>
                    <template v-slot:default>
                        <ul class="list-unstyled m-0">
                            <li class="collection-source-list-item" v-for="source in prop.sources" :key="source.id">
                                <collection-source-display-compact :source="source"></collection-source-display-compact>
                            </li>
                        </ul>
                    </template>
                </drop-panel>
            </template>

            <template v-if="prop.onlyType === 'custom' && accountFeatures.customSources">
                <drop-panel :panel-name="prop.name" :panel-title="prop.onlyType" class="me-2">
                    <template v-slot:link>
                        <i class="fa fa-wrench nav-icon" v-if="prop.onlyType == 'custom'"></i>
                        <span>{{ prop.nameWithCount }}</span>
                    </template>
                    <template v-slot:default>
                        <p>Custom sources are not available at this time.</p>
                    </template>
                </drop-panel>
            </template>

        </div>
    </div>
</template>

<style lang="scss">
// Do not scope the SVG
.suggestion-sources {
    .collection-source-list-item:last-child {
        margin-bottom: 0!important;
    }
    .nav-icon, .source-icon {
        //font-size: 1rem;
        margin-right: .25em;
    }

    .nav-icon {
        .singular {
            visibility: hidden;
        }

        .multiple {
            visibility: visible;
        }

        //.path-fill {
        //    fill: var(--primary);
        //}
        //
        //.path-stroke {
        //    stroke: var(--primary);
        //}
    }

    svg.source-icon {
        .singular {
            visibility: visible;
        }

        .multiple {
            visibility: hidden;
        }
    }

    .nav-icon, .source-icon {
        width: 1em;
        height: 1em;
        left: 1px;
    }
    .source-dp {
        min-width: 150px;
        width: 250px;
    }

    .no-sources {
        color: var(--steel-33);
    }
    .no-sources {
        .path-fill {
        }

        .path-stroke {
        }
    }
}
</style>

<script>


import {mapGetters} from "vuex";
import DropPanel from "@/components/Controls/DropPanel";
import CollectionsSvg from "@/assets/svg/collections";
import TopicsSvg from "@/assets/svg/topics";
import CollectionSourceDisplayCompact from "./CollectionSourceDisplayCompact";

export default {
    name: "SuggestionManagerSources",
    data() {
        return {
            collectionProps: {
                collection: {
                    name: "Collections",
                    title: "Source Collections",
                    id: "collectionsDropdown",
                    onlyType: 'collection',
                    sources: [],
                    nameWithCount: 'Collections',
                    hasSources: true,
                    topic: 'currentTopic',
                },
                topic: {
                    name: "Topics",
                    title: "Source Collections",
                    id: "topicsDropdown",
                    onlyType: 'topic',
                    sources: [],
                    nameWithCount: 'Topics',
                    hasSources: true,
                },
                rss: {
                    name: "RSS",
                    title: "Source Collections",
                    id: "rssDropdown",
                    onlyType: 'rss',
                    sources: [],
                    nameWithCount: 'RSS',
                    hasSources: true,
                },
                custom: {
                    name: "Custom",
                    title: "Source Collections",
                    id: "customDropdown",
                    onlyType: 'custom',
                    sources: [],
                    nameWithCount: 'Custom',
                    hasSources: true,
                },
            },
        }
    },
    components: {
        CollectionSourceDisplayCompact,
        TopicsSvg,
        CollectionsSvg,
        DropPanel,
    },
    computed: {
        ...mapGetters({
            collectionSources: 'collectionSources',
            accountFeatures: 'accountFeatures',
            collections: 'collections',
        }),
    },
    watch: {
        collectionSources() {
            this.loadSources();
        },
    },
    mounted() {
        this.loadSources();
    },
    methods: {
        isCollectionSource(type) {
            /* Checks if the provided string can be a source for a collection. */
            /* If 'custom' source type is added, then it will need to be added below as well */
            return typeof type === 'string' && /rss|collection|topic/.test(type);
        },
        sourceCount(type) {
            const collection = this.collectionProps[type];

            if (!collection.hasSources) {
                return 0;
            }

            // Count them sources.
            const value = collection.sources && collection.sources.length;

            return value;

        },
        formatSourceName(type) {
            const collection = this.collectionProps[type];

            // Make sure this gets a source count.
            if (!collection.hasSources) {
                return collection.name;
            }

            // Count them sources.
            const value = this.sourceCount(type);

            // Add count to the display name, don't append (0) if there isn't any.
            return (value && Number.isInteger(value) && value > 0) ?
                `${value} ${collection.name}` : `0 ${collection.name}`;
        },
        // Seems like this could be a future feature request.
        // <button class="btn btn-link font-size-12" @click="setCurrentCollection(source.sourceCollectionId)"></button>
        // setCurrentCollection(collection) {
        //     this.$store.dispatch('setCurrentCollection', {collection});
        //     if (this.$router.currentRoute.path !== '/')
        //         this.$router.push("/");
        // },
        loadSources() {
            Object.values(this.collectionProps).forEach(collectionSource => {

                const sourceType = collectionSource.onlyType
                if (!this.isCollectionSource(sourceType)) {
                    return;
                }

                // Duplicate relevant source entries into the collection properties for things like source count.
                try {
                    this.collectionProps[sourceType].sources = !this.collectionSources ?
                        [] : this.collectionSources.filter(s => s.type === sourceType);
                } catch (error) {
                    console.error(error, sourceType);
                }

                // Update the displayed name of the collection source.
                this.collectionProps[sourceType].nameWithCount = this.formatSourceName(sourceType);
            })
        }
    },

}
</script>
