

export function elementStyle({
 element: {
     color,
     backgroundColor,
     borderColor,
     fontSize,
     presetFont,
     customFont,
     bold,
     underline,
     italics,
     allCaps,
     hidden,
     hideable,
     inline,
     lineHeight,
     fixedHeight,
     height,
     padding,
     margin,
     shadow,
     cornerRoundRadius,
     // alignment
 },
 base
}) {
    console.log('elementStyles.margin', margin)
    customFont = customFont || base.customFont
    presetFont = presetFont || base.presetFont;
    return {
        fontSize: `${fontSize||100}%`,
        ...(color? {color} : {}),
        ...(backgroundColor? {backgroundColor} : {}),
        fontFamily: [customFont, presetFont].filter(x=>x).join(', '),
        fontWeight: bold ? 'bold' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
        fontStyle: italics ? 'italic' : 'normal',
        textTransform: allCaps ? 'uppercase' : 'none',
        ..._if(padding, {padding: formatPadding(padding||{})}),
        ..._if(margin, {margin: formatPadding(margin||{})}),
        ...display(hidden, hideable, inline),
        ..._if(lineHeight, {lineHeight}),
        ..._if(fixedHeight !== null, {
            boxSizing: 'content-box',
            [fixedHeight ? 'height' : 'maxHeight']: `${height*1.2}em`
        }),
        ..._if(shadow, {boxShadow: boxShadow(shadow)}),
        ..._if(borderColor, {border: `1px solid ${borderColor}` || 'none'}),
        ..._if(cornerRoundRadius, {borderRadius: `${cornerRoundRadius}px`}),
        // alignment: text-
    }
}

function formatPadding({top, bottom, left, right}) {
    return `${top||0}px ${right||0}px ${bottom||0}px ${left||0}px`
}

function display (hidden, hideable, inline) {
    return hidden && hideable ? { display: 'none' } :
           inline ? { display: 'inline' } :
           {};
}

function _if(include, props) {
    return include && props ? props : {}
}


export function cardContainerStyle({layout: {verticalMargin, horizontalMargin, layout}}) {
    return {
        justifyContent: layout === 'grow' ? 'flex-start' : layout,
        margin: `-${verticalMargin}px -${horizontalMargin}px`,
    }
}

export function cardStyle({
  cardProps,
  layout: {verticalMargin, horizontalMargin, layout},
  base: {fontSize}
}) {
    return {
        fontSize: `${fontSize}px`,
        width: `${cardProps.size}px`,
        margin: `${verticalMargin}px ${horizontalMargin}px`,
        flexGrow: layout === 'grow' ? 1 : 0,
        boxShadow: boxShadow(cardProps.shadow),
        backgroundColor: cardProps.backgroundColor,
        border: `1px solid ${cardProps.borderColor}` || 'none',
        borderRadius: `${cardProps.cornerRoundRadius}px`,
        padding: `${cardProps.verticalPadding}px ${cardProps.horizontalPadding}px`
    }
}


function cardContentStyles(cardProps) {
    return {
        // ...(this.cardProps.alignContentBottom? {
        //     position: absolute
        // } : {}),
        paddingTop: `${cardProps.contentTopPadding}px`,
        paddingBottom: `${cardProps.contentBottomPadding}px`,
        paddingLeft: `${cardProps.contentHorizontalPadding}px`,
        paddingRight: `${cardProps.contentHorizontalPadding}px`,
    }
}

function boxShadow(shadow) {
    if (!shadow || shadow.toLowerCase() === 'none') return 'none';
    let [, size, shade ] = /(\d+)px, (.+)/.exec(shadow);
    return `${size}px ${size}px ${size}px ${
        shade === 'Dark' ? 'rgba(0,0,0,.4)' :
        shade === 'Light' ? 'rgba(0, 0, 0, 0.2)' :
        shade === 'White' ? 'rgba(255, 255, 255, 0.5)' : 'Black'
    }`

}

// const additionalStyles = {
//     carousel: {
//         publisher(base) {
//             return {
//
//             }
//         }
//     }
// }

export function propsToLower(o) {
    if (Array.isArray(o)) o = {};
    return Object.entries(o).reduce((result, [key, value]) => ({...result, [key.toLowerCase()]: value}), {})
}

export function generateJsStyles(type, {base, layout, cardProps, elements}) {
    try {
        console.log(elements)
        return {
            container: {
                ...cardContainerStyle({layout}),
                ...propsToLower(base.userStyles)
            },
            card: type === 'card' ? {
                ...cardStyle({cardProps: cardProps, layout, base}),
                ...propsToLower(cardProps.userStyles)
            } : null,
            carousel: type === 'carousel' ? {
                ...cardStyle({cardProps: cardProps, layout, base}),
                ...propsToLower(cardProps.userStyles)
            } : null,
            content: cardContentStyles(cardProps),
            elements: elements.map(e => ({
                className: e.className,
                styles: {...elementStyle({element: e.styles, base}), ...propsToLower(e.userStyles)}
            }))
        }
    }
    catch(e) {
        console.error(e)
    }
}
