<template>
    <!--<upc-select v-model="market" :items="items"></upc-select>-->
    <div class="position-relative">
        <select v-model="culture" class="form-control form-select form-control-sm" :readonly="readonly">
            <option v-for="l in items" :key="l.value" :value="l.value">
                {{l.description}}
            </option>
        </select>
        <button v-if="showMakeDefault" class="btn btn-link text-primary small btn-sm make-default-positioning"
                @click="updateUserPreference()">Make Default</button>
    </div>
</template>

<style scoped>
@media screen and (min-width: 576px) {
    .make-default-positioning {
        position:absolute;
        top: 100%;
    }

}
</style>

<script>
    // import UpcSelect from '@/components/Controls/UpcontentSelect';
    import {mapGetters} from 'vuex'
    export default {
        name: 'TopicLanguageSelect',
        props: { value: String, readonly: Boolean },
        // components: {UpcSelect},
        data() {
            return {
                items: [],
                culture: null
            }
        },
        computed: {
            ...mapGetters({
                userLanguageCulturePref: 'languageCulture'
            }),
            defaultLanguage() {
                if (this.userLanguageCulturePref) return this.userLanguageCulturePref;

                return this.items.some(x => x.value === window.navigator.language) ? window.navigator.language : 'en-US'
            },
            showMakeDefault() {
                return this.culture !== this.defaultLanguage;
            }
        },
        methods: {
            async load() {
                this.items = await this.$api.get('/language-markets/for-topic-search').then(x=>x.data);
                this.updateCulture(true)
            },
            updateCulture(initial=false) {
                if (this.items && this.items.length) {
                    let newVal = this.value || this.defaultLanguage;
                    let changed = this.culture !== newVal;
                    this.culture = newVal;

                    if (!changed && this.culture !== this.value) this.$emit('input', this.culture)
                }
            },
            updateUserPreference() {
                this.$store.dispatch('setUserPreference', {key : "languageCulture", value : this.culture});
            }
        },
        watch: {
            value() {
                this.updateCulture();
            },
            defaultLanguage() {
                this.updateCulture(true)
            },
            culture() {
                this.$emit('input', this.culture)
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
