import {StringUtils} from "./Strings";

export default {
    install: (Vue) => {
        Vue.prototype.$views = Views;
    }
}

export class View {
    constructor(name) {
        this.name = name
    }
    show({target, callbacks={}, ...args}) {
        Object.entries(callbacks).forEach(([k, v]) => {
            window.Events.$once(`${k}_${this.name}`,  v)
        })

        window.Events.$once('closed', () => {
            Object.entries(callbacks).forEach(([k, v]) => {
                window.Events.$off(`${k}_${this.name}`,  v)
            })
        })

        window.Events.$emit('show_' + this.name, {target, ...args})
    }
    showSaveCancel({target, ...args}) {
        return new Promise((resolve, reject) => {
            this.show({
                target,
                callbacks: {
                    save: resolve,
                    cancel: reject
                },
                ...args
            })
        })
    }
}

export let Views = {
    filterViewEdit: new View('filterViewEdit')
}
