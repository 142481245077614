<template>
    <div>
        <modal ref="modal" :size="size" :can-close="canClose" class="okCancel">
            <template v-slot:header>
                <h3>{{ title }}</h3>
            </template>
            <div>
                <form @submit.prevent="onOk" :id="$elementId('form')">
                    <slot/>
                </form>
            </div>
            <template v-slot:footer>
                <div v-if="waiting">
                    <progress-bar></progress-bar>
                </div>
                <div v-else class="buttons">
                    <button type="button" class="btn btn-secondary ms-auto me-3" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <!--<input type="submit" class="btn btn-success" :value="okText" />-->


                    <button type="submit" :class="`btn ${okClass}`" :value="okText" :form="$elementId('form')">
                        <i :class="okIconClass"></i>
                        {{ okText }}
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<style scoped>
.modal-footer .buttons {
    display: flex;
    justify-content: flex-end;

}
</style>

<style>
.okCancel .modal-footer {
    display: block;
}
</style>

<script>
import Modal from "@/components/Controls/Modal";
import ProgressBar from "@/utilities/ProgressBar";

export default {
    components: {ProgressBar, Modal},
    props: {
        size: {
            type: String,
            default: ''
        },
        canClose: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        okText: {
            type: String,
            default: 'OK'
        },
        okClass: {
            type: String,
            default: 'btn-success'
        },
        okIconClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            result: null,
            subs: [],
            waiting: false,
            closed: null
        }
    },
    methods: {
        onOk() {
            console.log('submit')
            this.result = true;
            this.subs.forEach(resolve =>
                resolve({
                    close: () => {
                        this.$refs.modal.hide();
                        return this.closed
                    }, setLoading: (val) => {
                        this.waiting = val;
                    }
                })
            )
        },
        awaitResult() {
            this.result = null;
            return new Promise((resolve, reject) => {
                this.closed = this.$refs.modal.awaitClose();
                this.closed.then(() => {
                    if (!this.result) return reject('canceled')
                })
                this.subs.push(resolve)
            })

        }
    }
}
</script>
