<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="#" @click.prevent="toggleImagesInListView" class="nav-link">Hide images in list view</a>                    
                </li>
                <li class="nav-item">
                    <a href="#" @click.prevent="showWizard" class="nav-link">Create new topic with BetaBuilder</a>                    
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    props : [],
    data(){
        return {

        }
    },
    computed : {
        ...mapGetters({
            showImages : 'showImagesInListView'
        })
    },
    mounted(){

    },
    methods : {
        toggleImagesInListView(){
            this.$store.dispatch('setListItemImageVisibility', ! this.showImages)
        },
        showWizard(){
            window.Events.$emit('showQueryBuilderModal', {mode : 'guided'});
        }
    }
}
</script>

<style>
</style>
