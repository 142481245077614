<template>
    <form class="add-content-group mb-2" @submit.prevent="addSource">
        <collection-topic-selector
            class="add-source--cts mb-2"
            :type="type"
            :cross-account="showSyndicatedAccountCollections"
            ref="collectionTopicSelector"
            @input="setTopicCollectionId"
        ></collection-topic-selector>

        <label v-if="accountsWithCrossAccount.length" class="mb-2">
            <input type="checkbox" v-model="showSyndicatedAccountCollections"/>&nbsp;
            Show collections from syndication account<span v-if="accountsWithCrossAccount.length>1">s</span>
        </label>

        <div v-if="id" class="pb-2 gallery-builder-form-row">

            <label>Filter View</label>

            <select-filter-view

                v-model="filterViewId"
                :has-content-type="type"
                :has-content-id="id"
                class="mb-2"
                ref="filterViewEdit"
            >
            </select-filter-view>

        </div>

        <input type="submit" v-if="id"
               class="btn btn-primary full-width" value="Add Source" :disabled="sourcesBusy">

        <!-- Add Feed / Add Source -->
        <div class="text-danger">
            {{ sourceErrorMessage }}
        </div>
    </form>
</template>
<style scoped>
.gallery-builder-form-row > label, .gallery-builder-form-section label {
    margin-bottom: 0;
    margin-left: 1px;
}

label {
    color: var(--bs-dark);
    display: block;
}
</style>

<script>
import ContentSourceTypeSelect from '@/components/Controls/ContentSourceTypeSelect'
import CollectionTopicSelector from "@/components/Controls/CollectionTopicSelector";
import HasFilters from '@/components/Collections/HasFilters';
import {mapGetters} from 'vuex'
import Spinner from '@/components/Controls/Spinner'
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";

export default {
    name: "AddContentSourceForm",
    mixins: [HasFilters],
    components: {SelectFilterView, CollectionTopicSelector, ContentSourceTypeSelect, Spinner},
    props: {
        sourcesBusy: {type: Boolean, default: false},
        collectionId: String,
        type: {type: String}
    },
    computed: {
        ...mapGetters({
            features: 'features',
            accountFeatures: 'accountFeatures',
            accountsWithCrossAccount: 'accountsWithCrossAccount'
        }),
    },
    data() {
        return {
            showSyndicatedAccountCollections: false,
            sourceErrorMessage: '',
            id: '',
            allTags: [],
            filterViewId: null,
        }
    },
    watch: {
        type() {
            this.id = '';
            this.$refs.collectionTopicSelector.clearSelected()
        },
        filterViewId() {
            console.log('filterViewId', {filterViewId: this.filterViewId})
            this.$emit('input', {filterViewId: this.filterViewId})
        }
    },
    methods: {
        async addSource() {
            console.log('emit addSource');
            if (this.id && this.filterViewId)
                this.$emit('addSource', {
                    type: this.type,
                    id: this.id,
                    filterViewId: this.filterViewId
                });
        },
        setError(error, suggestions = [], recoverable = false) {
            this.sourceErrorMessage = error;
        },
        clearFeed() {
        },
        resetForm(full = true) {
            this.clearFeed();
            this.resetErrors();
            this.id = '';
        },
        resetErrors() {
            this.sourceErrorMessage = '';
        },
        setTopicCollectionId(id) {
            this.id = id;
        },
    }
}
</script>

<style scoped>
hr {
    border: 1px solid var(--bs-dark);
}

.full-width {
    width: 100%;
}
</style>

<style lang="scss">
.add-content-group {
    .input-group.button-group {
        display: flex;
    }

    .filter-form {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .uc-select {
        width: auto;
    }

    .uc-select {
        input[type=search] {
            width: 0 !important;
        }

        &.open {
            input[type=search] {
                width: auto !important;
            }
        }
    }

    .content-source-type-select.uc-select {
        max-width: 125px;

        &.src-collection {
            max-width: 170px;
        }

        .icon-container {
            margin-right: .2em;
        }
    }

    .content-source-type-select.open {
        max-width: 120px;
    }

    .add-source--cts, .add-source--url {
        flex-grow: 1;
    }
}
</style>
