export class UpcCCCampaign {
    id = null;
    name = '';
    contentCampaignId = null;
    subject = null;
    lists = null;
    fromName = '';
    fromAddress = null;
    replyTo = null;

    toApi() {
        let self = {};
        self.id = this.id;
        self.campaign_name = this.name;
        self.collection_content_campaign_id = this.contentCampaignId;
        self.subject_line = this.subject;
        self.cc_list_ids = this.lists;
        self.from_name = this.fromName;
        self.from_address = this.fromAddress;
        self.reply_to_address = this.replyTo;
        return self;
    }

    static fromApi(c) {
        let self = new UpcCCCampaign();
        self.id = c.id;
        self.name = c.name || c.campaign_name;
        self.contentCampaignId = c.collection_content_campaign_id;
        self.subject = c.subject_line;
        self.lists = c.cc_list_ids;
        self.fromName = c.from_name;
        self.fromAddress = c.from_address;
        self.replyTo = c.reply_to_address;
        return self;
    }

    static fromVm(c) {
        let self = new UpcCCCampaign();
        self.id = c.campaign && c.campaign.id;
        self.name = c.name;
        self.contentCampaignId = c.campaignId;
        self.subject = c.subject;
        self.lists = c.selectedLists;
        self.fromName = c.fromName;
        self.fromAddress = c.fromAddress;
        self.replyTo = c.replyTo;
        return self;
    }
}
