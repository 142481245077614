<template>
    <div>
        <h6 class="mt-2">Paste following code into the head element of your site</h6>
        <div class="code-snippet">
            <code>{{headCode(themes)}}</code>
        </div>

        <h6 class="mt-5">Paste the following pieces of HTML into the appropriate places on your site</h6>
        <div v-for="g in galleries" :key="g.id">
            <label>
                <span>{{g.name}} ({{formatCollectionsDisplay(g)}})</span>
                <gallery-code-snippet :gallery="g"></gallery-code-snippet>
            </label>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import GalleryBase from './GalleryBase'
    import {codeImports, styleImport} from "@/services/Gallery";
    import GalleryCodeSnippet from "@/components/Galleries/GalleryCode";
    const env = process.env;
    let qa = env['VUE_APP_API_BASE_URL'].includes('qa');
    export default {
        components: {GalleryCodeSnippet},
        mixins: [GalleryBase],
        props: { themes: Array, galleries: Array },
        methods: {
            headCode(themes) {
                let themLines = styleImport(themes.map(t => `https://galleries${qa?'.qa':''}.upcontent.com/themes/${t.id}/style.css`));
                let codeLines = codeImports(qa?'qa':'');
                return [themLines, codeLines].join('\n')
            },
        },
        name: "GalleryDownload"
    }
</script>
