<template>
    <nestable-modal class="pricing-plan-modal"
                    ref="modal"
                    modal-name="pricingPlanModal"
                    size="lg"
                    @shown="shown"
                    @closed="closed">
        <template v-slot:header>
            <h5>
                Change Your Pricing Plan
            </h5>
        </template>

        <div class="d-flex justify-content-center mb-4">
            <toggle-bar :options="payFrequencies"
                        :value="newPlanFrequency"
                        :show-upsell="true"
                        @input="frequencyChanged($event)"
                        class="font-size-14 pricing-plan-modal-pay-frequency"></toggle-bar>
        </div>
        <div class="d-grid grid-columns-lg-4 grid-template-columns-lg gap-3">
            <subscription-plan v-for="plan in plans"
                               :key="plan.name"
                               :plan-name="plan.name"
                               :new-plan-frequency="newPlanFrequency"
                               @saved="setSignUpFrequency({planFrequency: newPlanFrequency})"
            />
        </div>

    </nestable-modal>
</template>
<style lang="scss">
.modal-header, .modal-footer {
    border: 0!important;
}
.modal-body {
    padding-top: 0!important;
}
.pricing-plan-modal-pay-frequency {
    max-width: 24em!important;
}
</style>
<script>
import {mapActions, mapGetters} from "vuex";
import PlanList from '@/utilities/PlanList'
import NestableModal from "@/components/Controls/NestableModal";
import SubscriptionPlan from "@/components/Subscriptions/V2/SubscriptionPlan";
import ToggleBar from "@/utilities/ToggleBar";
import BasePlan from "@/components/Auth/V2/BasePlan";
import {payFrequencies} from "@/Constants";

export default {
    name: "PricingPlanModal",
    mixins: [BasePlan],
    components: {SubscriptionPlan, NestableModal, ToggleBar},
    data() {
        return {
            showPlans: ['Orchestrator', 'Engager', 'Curator', 'Sharer',],
            plans: [],
            payFrequencies: payFrequencies,
            payFrequencyId: this.$strings.generateId('pay-frequency'),
            newPlanFrequency: null,
        }
    },
    computed: {
        ...mapGetters({
            planName: 'planName',
            planFrequency: 'planFrequency',
        }),
    },
    watch: {

    },
    mounted() {
        this.plans = PlanList.filter(plan => this.showPlans.some(p => p === plan.name));
        // window.Events.$on('show_editCollectionModal', data => {
        //     this.collection = data.collection || {};
        //     console.log('show_editCollectionModal', data)
        // });
    },
    methods: {
        ...mapActions({
            setSignUpFrequency: 'setSignUpFrequency',
        }),
        shown() {
            this.newPlanFrequency = this.planFrequency;
        },
        closed() {
            // console.log('shown()', this.collection);
        },
        frequencyChanged(e) {
            this.newPlanFrequency = e;
        }
    }
}
</script>
