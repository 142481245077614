<template>
    <div class="plan-selector">
        <div class="text-center my-5">
            <h4>Change Plans
                <br/>
                <small>All subscription plans have <i><b>unlimited</b></i> users</small>
            </h4>

            <div class="btn-group mt-2 period-selector">
                <button
                    class="btn btn-sm"
                    :class="{'btn-primary' : periodType == 'monthly', 'btn-secondary' : periodType != 'monthly'}"
                    @click="periodType = 'monthly'"
                >
                    Monthly
                </button>
                <button
                    class="btn btn-sm"
                    :class="{'btn-primary' : periodType == 'yearly', 'btn-secondary' : periodType != 'yearly'}"
                    @click="periodType = 'yearly'"
                >Yearly
                </button>
            </div>
        </div>
        <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: no user entered data-->
        <div class="alert alert-danger" v-if="networkError" v-html="networkError"></div>

        <div class="plans-container mt-3 d-md-flex justify-content-around">
            <subscription-plan
                @setPlan="selectedPlan = $event"
                @confirmPlan="requestSubscriptionChange"
                plan-name="Orchestrator"
                :current-plan="currentPlan"
                :selectedPlan="selectedPlan"
                :duration="periodType"
                :networkActivity="networkActivity"
            />
            <subscription-plan
                @setPlan="selectedPlan = $event"
                @confirmPlan="requestSubscriptionChange"
                plan-name="Engager"
                :current-plan="currentPlan"
                :selectedPlan="selectedPlan"
                :duration="periodType"
                :networkActivity="networkActivity"
            />
            <subscription-plan
                @setPlan="selectedPlan = $event"
                @confirmPlan="requestSubscriptionChange"
                plan-name="Curator"
                :current-plan="currentPlan"
                :selectedPlan="selectedPlan"
                :duration="periodType"
                :networkActivity="networkActivity"/>
            <subscription-plan
                @setPlan="selectedPlan = $event"
                @confirmPlan="requestSubscriptionChange"
                plan-name="Sharer"
                :current-plan="currentPlan"
                :selectedPlan="selectedPlan"
                :duration="periodType"
                :networkActivity="networkActivity"
            />
        </div>

        <div class="text-center small text-muted mb-3 mt-2" v-if="currentPlan">
            <button class="btn btn-sm btn-secondary mb-1"
                    @click="startCancellation"
                    :disabled="networkActivity"><i
                class="fa fa-remove"></i> Cancel My Subscription
            </button>
            <div v-if="!accountFeatures.profitwellCancellation">
                <i class="small">
                    * Cancelling your subscription will automatically
                    switch you to the free plan.
                </i>
            </div>
            <template v-if="accountFeatures.profitwellCancellation">
                <div v-if="profitwellResponse" class="alert alert-warning text-start mb-2">
                    <strong>Error, server said:</strong>
                    <pre>{{ profitwellResponse }}</pre>
                    <p v-if="downgradeResult">{{downgradeResult}}</p>
                </div>
            </template>
        </div>

        <custom-plan-creator/>
    </div>
</template>

<script>
import PlanGroups from '@/utilities/PlanList'
import SubscriptionPlan from './SubscriptionPlan'
import CustomPlanCreator from './CustomPlanCreator'
import {mapGetters} from 'vuex';
import * as $ from 'jquery';
import {profitwellFauxResults} from "./debug/profitwellDebugConstants.js";

export default {
    props: ['account', 'hasCard', 'hideFreePlan', 'hideChangeButton', 'startingGroupIndex'],
    data() {
        return {
            selectedPlan: false,
            networkActivity: false,
            networkError: false,
            periodType: 'monthly',
            profitwellResponse: null,
            downgradeResult: null,
        }
    },
    components: {
        SubscriptionPlan, CustomPlanCreator
    },
    watch: {
        plan() {
            if (this.plan) {
                this.selectedPlan = this.plan.package + "_" + this.plan.interval;
            } else {
                this.selectedPlan = false;
            }
        },
    },
    computed: {
        ...mapGetters({
            subscriptions: 'subscriptions',
            accountTeamMembers: 'accountTeamMembers',
            signupPlan: 'signupPlan',
            currentTopic: 'currentTopic',
            accountFeatures: 'accountFeatures',
        }),
        plan() {
            return this.account ? this.subscriptions.find(s => s.account_id == this.account.id) : null;
        },
        currentPlanPackage() {
            return this.account && this.plan ? this.plan.package : null;
        },
        currentPlanInterval() {
            return this.account && this.plan ? this.plan.interval : null;
        },
        currentPlan() {
            return this.account && this.plan ? this.plan.package + "_" + this.plan.interval : null;
        },
    },
    mounted() {
        if (this.currentPlan)
            this.selectedPlan = this.currentPlan

        $('body, html').animate({scrollTop: $('.plan-selector').offset().top - 40}, 500)

        if (this.signupPlan) {
            this.selectedPlan = this.signupPlan;
        }

        if (this.selectedPlan && this.selectedPlan.includes('12m')) {
            this.periodType = 'yearly';
        }

    },
    methods: {
        generateFauxResult() {
            const randomInt = Math.floor(Math.random() * 4);
            return profitwellFauxResults[randomInt];
        },
        requestSubscriptionChange() {

            if (!this.hasCardOnFile) {
                // Get the element
                const addPaymentInfoBlock = document.getElementById("add-payment-info");

                // Scroll if it exists
                if (addPaymentInfoBlock) {
                    addPaymentInfoBlock.scrollIntoView({behavior: 'smooth', block: 'start'});
                }
            }

            this.$emit('planSelected', this.selectedPlan);

            this.networkError = false;

            if (!this.hasCard) {
                this.$emit("askForCard");
                return;
            }

            if (!this.selectedPlan) {
                this.networkError = "You must select a plan first";
                return;
            }

            this.networkActivity = "Updating plan...";

            var planArray = this.selectedPlan.split('_');
            let interval = planArray.pop();
            let pckge = planArray.join('_');
            let request = {package: pckge, interval}

            this.$api.put(`/accounts/${this.account.id}/subscription`, request)
                .then(response => {
                    this.networkActivity = false;
                    let subscription = response.data;
                    this.$store.dispatch('addSubscriptionToAccount', {subscription, account: this.account});
                    this.$store.dispatch('updateAccount',
                        {account: {...this.account, plan: this.selectedPlan}});
                    this.fetchTeamMembers(() => {
                        this.$emit('change');
                    })

                    $('body, html').animate({scrollTop: 0}, 500)

                    this.$emit('change');

                    window.Events.$emit('ReloadUserAccounts');

                    if (this.signupPlan && !this.currentTopic) {
                        this.$store.dispatch('setUserSignUpPlan', {plan: false});
                        this.$router.push(`/welcome?success=true&plan=${pckge}`);
                    }

                }).catch(e => {
                if (e.response && e.response.data && e.response.data.description) {
                    this.networkError = e.response.data.description
                } else {
                    this.networkError = "Something went wrong processing your plan change";
                }
                this.networkActivity = false;
            })
        },
        fetchTeamMembers(done) {
            // I don't know that this `done` completion handler
            // is actually being used anymore.

            this.$api.get(`/accounts/${this.account.id}/users`)
                .then(response => {
                    let users = response.data;

                    let accountTeam = this.accountTeamMembers.find(a => a.id == this.account.id);
                    if (accountTeam) {
                        let missingUsers = accountTeam.members.filter(m => {
                            return users.filter(userUrl => userUrl.includes(m.id)).length == 0
                        });

                        missingUsers.forEach(user => {
                            this.$store.dispatch('removeTeamMemberFromAccount',
                                {user, account: this.account});
                        })

                        users.forEach((userUrl, index) => {
                            this.$api.get(userUrl).then(response => {
                                let user = response.data
                                this.$store.dispatch('addTeamMember', {user})
                                this.$store.dispatch('addTeamMemberToAccount', {user, account: this.account});
                                if (index + 1 >= users.length) {
                                    done()
                                }
                            })
                        });
                    }

                })
        },
        startCancellation() {
            if(!this.accountFeatures.profitwellCancellation) {
                this.switchToFree(true);
                return;
            }
            //... Any pre-cancel logic
            window.profitwell('init_cancellation_flow', {subscription_id: this.plan.id}).then(result => {
                this.profitwellResponse = result;

                this.$loggy.log('profitwell result', result);
                result = this.generateFauxResult();
                this.profitwellResponse = result;
                this.$loggy.log('faux result', result);

                if (result.status === 'aborted') {
                    // Do nothing.
                    return
                }

                if (result.status === 'retained') {

                    if(result.salvageAttemptUsed === 'plan_switch') {
                        this.downgradePlan();
                        return;
                    }

                    if(result.salvageAttemptUsed === 'pause_subscription') {
                        this.pauseSubscription();
                        return;
                    }

                    // Probably 'contact_support_email_notification', 'contact_support_meeting_scheduler'

                    return;
                }

                // Customer cancelled
                alert("Cancellation request received but cancellations are off while debugging.");
                // this.switchToFree(true);
            })
        },
        downgradePlan() {

            // This variable is for debugging.
            let downgradeMessage = `FROM: ${this.currentPlanPackage} `;

            // This variable we actually need.
            let downgradePlan = '';

            switch(this.currentPlanPackage) {
                case 'uc_enterprise':
                    downgradePlan = 'uc_orchestrator_' + this.currentPlanInterval;
                    break;
                case 'uc_orchestrator':
                    downgradePlan = 'uc_engager_' + this.currentPlanInterval;
                    break;
                case 'uc_engager':
                    downgradePlan = 'uc_curator_' + this.currentPlanInterval;
                    break;
                case 'uc_curator':
                    downgradePlan = 'uc_sharer' + this.currentPlanInterval;
                    break;
                case 'uc_sharer':
                    alert("Downgrading from 'uc_sharer' but cancellations are off while debugging.");
                    // this.switchToFree(true);
                    break;
                default:
                    // Fallback.
                    alert("Downgrading from 'default' but cancellations are off while debugging.");
                    // this.switchToFree(true);
                    return;
            }

            // For debugging.
            downgradeMessage += `TO: ${downgradePlan}`;
            this.downgradeResult = downgradeMessage;

            // Change the selected plan then request the subscription change.
            this.selectedPlan = downgradePlan;
            this.requestSubscriptionChange();
        },
        pauseSubscription() {
            // TODO: Need an API to enable pausing as
            // TODO: the front end has no way of starting and ending a pause.
            alert("Request to pause subscription was received but couldn't be processed. Contact support.");
            return;
        },
        switchToFree(confirmCancellation = false) {
            if(confirmCancellation) {
                const response = confirm("Are you sure you want to cancel your subscription?");
                if(!response) {
                    return;
                }
            }
            this.networkActivity = "Cancelling plan...";
            this.$api.delete('/accounts/' + this.account.id + '/subscription').then(() => {
                window.Events.$emit('ReloadUserAccounts');
                this.networkActivity = false;
                this.$intercom.trackEvent('Downgrade to personal');
                this.$intercom.update();
                this.$emit('change');
            })
        }
    }
}
</script>

<style scoped>

h4 {
    font-size: 1.1rem;
}

.period-selector .btn-sm {
    padding: 7px 18px;
    text-transform: uppercase;
    font-size: var(--font-size-13);
}

.period-selector .btn.btn-secondary {
    background: white;
    color: inherit;
}


</style>
