import * as Roles from './Roles';

/**
 * Please only ever add permissions in the form of CRUD verbs.
 * Also try and keep them in natural order of Create, 
 * Update, Read, and Delete actions
 */
export default [
    {
        role: Roles.Reviewer,
        permissions: [
            'createNotes',
            'readSuggestions',
            'readTopics',
            'readCollections',
            'readFolders',
            'updateSuggestionFavoriteState',
            'deleteNotes'
        ]
    },
    {
        role: Roles.Editor,
        permissions: [
            "createTopics",
            "updateTopics"
        ]
    },
    {
        role: Roles.Manager,
        permissions: [
            "createTeamMemberships",
            "createFolderAssignments",
            "updateFolderAssignments",
            "updateTopicLocation",
            "deleteFolderAssignments",
            "deleteTeamMemberships"
        ]
    },
    {
        role: Roles.Administrator,
        permissions: [
            "changeTopicOwner",
            "changeAccountOwner"
        ]
    }
]