<template>
    <button type="button" @click.stop.prevent="connectDisconnect" :class="{
                'float-end': true, btn:true, 'btn-sm': true,
                'btn-success': connected === false,
                'btn-danger': connected === true
            }">
        {{ connected ? 'Disconnect' : 'Connect' }}
    </button>
</template>

<style>

</style>

<script>
    import {mapGetters} from 'vuex'
    export default {
        props: ['service', 'team', 'beforeAuth'],
        computed: {
            connected() { return !!this.service && !!this.service.isConnected(this.team) },
            ...mapGetters({
                vendor: 'vendor',
                afterOauthData: 'afterOauthData'
            })
        },
        methods: {
            async connectDisconnect() {
                if (this.connected) {
                    this.service.disconnect(this.team);
                } else {
                    if (this.beforeAuth) await this.beforeAuth();
                    let iframed = this.vendor === 'hootsuite'
                    setTimeout(() => this.service.connect(this.team, { iframed, afterOauthData: this.afterOauthData }), 5);
                }
            }
        }
    }
</script>
