<template>
    <div>
        <authors-with-notes 
            @setItemsWithNotes="$emit('setItemsWithNotes', $event)" 
            v-if="hasNotesType == 'authors'" 
            :has-suggestions="selectedHasSuggestions" 
            :has-suggestions-type="hasSuggestionsType"
            :has-notes-type="hasNotesType"
        />
        <suggestions-with-notes 
            @setItemsWithNotes="$emit('setItemsWithNotes', $event)" 
            v-if="hasNotesType == 'suggestions'" 
            :has-suggestions="selectedHasSuggestions" 
            :has-suggestions-type="hasSuggestionsType"
            :has-notes-type="hasNotesType"
        />
    </div>
</template>

<script>
import AuthorsWithNotes from './HasNotesLists/Authors';
import SuggestionsWithNotes from './HasNotesLists/Suggestions'

export default {
    props : ['hasNotesType', 'selectedHasSuggestions', 'hasSuggestionsType'],
    components : {
        AuthorsWithNotes, SuggestionsWithNotes
    }
}
</script>

<style>
</style>