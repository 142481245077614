<template>
    <div class="groupable-searchable-list">
        <easy-search
            v-show="showSearch"
            :items="items"
            @search="results => itemsFiltered = results"
            v-model="searchText"
            :matches="searchMatches"
            class="mb-2"
            :placeholder="searchPlaceholder"
            :clasess="searchClasses"
        />
        <search-terms
            v-if="searchTerms && searchTerms.length"
            :search-terms="searchTerms"
            class="mb-2"
            @search="term => searchText = term"
        />
        <grouped-list-box
            v-if="grouper"
            :grouper="item => item.group"
            :can-collapse="canCollapseGroups"
            :collapse-all="collapseGroups"
            :clickable="clickableItems"
            :items="itemsFiltered"
        >
            <template v-slot:default="item">
                <slot v-bind="item"/>
            </template>
        </grouped-list-box>
        <ul v-else class="no-bullets list-group">
            <button
                v-if="clickableItems"
                v-for="item in itemsFiltered"
                type="button"
                class="list-group-item bg-water-100 list-group-item-action"
                @click="$emit('select', item)">
                <slot v-bind="item"/>
            </button>
            <div v-else
                 v-for="item in itemsFiltered"
                 class="list-group-item bg-water-100">
                <slot v-bind="item"/>
            </div>
        </ul>
    </div>
</template>

<script>
import EasySearch from "./EasySearch";
import GroupedListBox from "./GroupedListBox";
import SearchTerms from "./SearchTerms";
export default {
    name: "SearchableGroupableList",
    components: {SearchTerms, GroupedListBox, EasySearch},
    props: {
        grouper: {type: Function, default: null},
        items: {type: Array, default: () => []},
        clickableItems: {type: Boolean, default: true},
        collapseGroups: {type: Boolean, default: false},
        canCollapseGroups: {type: Boolean, default: true},
        groupIsOpen: {type: Function, default: () => true},
        showSearch: {type: Boolean, default: true},
        searchPlaceholder: {type: String, default: 'Search'},
        searchMatches: {type: Function},
        searchClasses: {type: String, default: ''},
        searchTerms: {type: Array, default: null},
    },
    data() {
        return {
            searchText: '',
            itemsFiltered: [...this.items]
        }
    },
    watch: {
    }
}
</script>

<style scoped>

</style>
