<template>
    <div class="list-group">

        <div class="list-group-item">
            <span v-if="newItem" class="typing-message">Press "Enter" to add</span>
            <input 
                class="form-control" 
                :placeholder="displayPlaceholder" 
                v-model="newItem" 
                @keyup.enter="enterPressed" 
                :ref="'inputField'+id"
            />
        </div>
    </div>
</template>

<script>

import uuid from 'uuid'

export default {
    props : ['dataItems', 'placeholder', 'dataSelectedItems'],
    data(){
        return {
            selectedItems : this.dataSelectedItems,
            items : this.dataItems,
            newItem : '',
            id : uuid.v4()
        }
    },
    watch:{
        selectedItems(updated){
            this.selectedItems = updated;
        },
        items(updated){
            this.items = updated;
        }
    },
    computed : {
        displayPlaceholder(){
            if (this.items.length){
                return "Press Enter to continue"
            }
            return this.placeholder
        }
    },
    mounted(){

    },
    methods : {
        enterPressed(){
            if (this.newItem){
                this.addItem()
            } else {
                this.$emit('emptyEnter')
            }
        },
        toggleItem(item){
            let index = this.selectedItems.indexOf(item)
            if (index > -1){
                this.selectedItems.splice(index, 1)
            } else {
                this.selectedItems.push(item)
            }
            this.$emit('change', this.selectedItems);
        },
        addItem(){
            let item = this.newItem
            if (item){
                if (this.items.indexOf(item) === -1){
                    this.items.push(item)
                }
                this.toggleItem(item)
            }
            this.newItem = '';
        },
        removeItem(item){
            this.items.splice(this.items.indexOf(item), 1)
        },
        focus(){
            this.$refs['inputField'+this.id].focus()
        }
    }
}
</script>

<style scoped>
.typing-message {
    display:block;
    position: absolute;
    right: 30px;
    top:23px;
    font-size: 10px;
    color: #aeaeae
}
</style>