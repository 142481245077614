<template>
    <div class="modal fade"
         ref="modal"
         tabindex="-1"
         :role="role"
         data-bs-backdrop="static"
         :aria-expanded="expanded"
         :aria-labelledby="$elementId('header')">
        <div :class="{ 'modal-dialog': true, [`modal-${size}`]: !!size }" role="document">
            <div class="modal-content">
                <header class="modal-header" v-if="!noHeader">
                    <div :id="$elementId('header')" class="flex-grow">
                        <slot name="header" />
                    </div>
                </header>
                <main class="modal-body">
                    <slot />
                </main>
                <footer class="modal-footer" v-if="!noFooter">
                    <slot name="footer"/>
                </footer>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.modal-header h5 {
    margin: 0;
}
.modal-full {
    min-width: calc(100vw - 30px);
}
.modal-header .btn-close {
    margin-left: 0;
}
.modal-content {
    top: .5em;
    max-height: calc(100vh - 1.5em);
    overflow-y: hidden;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .modal-content {
        max-height: calc(100vh - 70px);
        overflow-y: hidden;
    }
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .modal-content {
        //min-height: calc(75vh);
        overflow-y: hidden;
    }
}

@media(max-height: 800px) {
    .modal-content, .modal-body {
        // As of 2022-09-08 all modals have viewport scrolling, no internal scrolling.
        max-height: unset!important;
    }
    .modal-content {
        margin-bottom: 6em;
    }
}

.modal-body {
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
}
</style>

<style>
</style>

<script>
import * as $ from 'jquery';
import * as bootstrap from 'bootstrap'
import {mapGetters} from "vuex";
export default {
    props: {
        alert: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        },
        canClose: {
            type: Boolean,
            default: true
        },
        noFooter: {
            type: Boolean,
            default: false
        },
        noHeader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: false,
            awaits: [],
        }
    },
    computed: {
        ...mapGetters({
            user: 'currentUser',
            hideSupportApps: 'hideSupportApps'
        }),
        role() { return this.alert ? 'alertdialog' : 'dialog' },
    },
    methods: {
        show() {
            this.removeIntercom();
            this.expanded = true;
            this.init();
            this.bsModalObj.show();
        },
        minimize() {
            this.expanded = false;
            this.init();
            this.bsModalObj.hide();
            this.loadIntercom();
        },
        hide() {
            if (!this.canClose) return;
            this.expanded = false;
            this.init();
            this.bsModalObj.hide();
            this.awaits.forEach(x => {if (x) x();})
            this.$emit('close')
            this.loadIntercom();
        },
        awaitClose() {
            return new Promise((resolve) => {
                this.awaits.push(resolve)
                this.show();
            })
        },
        init() {
            if (!this.bsModalObj)
                this.bsModalObj = new bootstrap.Modal(this.$refs.modal, {});
        },
        removeIntercom(){
            let width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            if (width < 768){
                this.$intercom.shutdown();
            }
        },
        loadIntercom(){
            if (!this.hideSupportApps)
                this.$intercom.boot({
                    user_id: this.user.id,
                    name: this.user.name,
                    email: this.user.email,
                });
        },
    },
    mounted() {
        this.init()
    }
}
</script>
