import {Apis} from "@/api";
import {ContentPolicyValidationResult} from "@/api/swagger-codegen";

export class ContentPoliciesService {
    readonly apis: Apis
    constructor(apis: Apis) {
        this.apis = apis;
    }

    public async checkOne(
        policyId: string,
        {pageId, contentItemId}: {pageId?: string, contentItemId?: string}
    ): Promise<ContentPolicyValidationResult> {
        console.log('* checkOne')
        const resp = await this.apis.contentPolicy.contentPoliciesPolicyIdValidatePageGet(
            policyId, pageId, contentItemId
        )
        return resp.data;
    }
    public async checkMany(
        items: {policyId: string, pageId?: string, contentItemId?: string}[]
    ): Promise<ContentPolicyValidationResult[]> {
        return await Promise.all(items.map(async item => await this.checkOne(item.policyId, item)))
    }
    public formatArticleWarningText(selection, collection, policy) {
        return `TITLE ${selection.title} | COLLECTION ${collection.name} | POLICY ${policy.name}`
    }
    public warningTextOne(selection, collection, policy) {
        return 'Article cannot be added to your collection due to the following content policy exception:'
            + '\n'
            + this.formatArticleWarningText(selection, collection, policy);
    }
    public warningTextMany(results: {selection, collection, policy}[]) {
        return 'Article(s) cannot be added to your collection(s) due to the following content policy exceptions:'
            + '\n'
            + results
                .map(({selection, collection, policy}) =>
                    this.formatArticleWarningText(selection, collection, policy))
                .join('\n');
    }

    public warnOne(selection, collection, policy) {
        alert(this.warningTextOne(selection, collection, policy));
    }
    public warnMany(results: {selection, collection, policy}[]) {
        alert(this.warningTextMany(results));
    }
}
