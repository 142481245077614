<template>
    <div>
        <div class='notes-group-container' v-for="group in groups" :key="group.id+'-notes-group'">
            <note-group :group="group" />
        </div>
    </div>
</template>

<script>

import NoteGroup from './NoteGroup'

export default {
    components : {
        NoteGroup
    },
    props : ['groups'],
    data(){
        return {

        }
    },
    mounted(){

    },
    methods : {

    }
}
</script>

<style>
</style>