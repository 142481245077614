<template>
    <nestable-modal modal-name="powerupsV4" size="full powerupsv3Modal" class="">
        <template v-slot:header><h5>Mange Powerups</h5></template>
        <powerups-v3 class="powerups-config"></powerups-v3>
    </nestable-modal>
</template>

<script>
import NestableModal from "../../../Controls/NestableModal.vue";
import PowerupsV3 from "./PowerupsV4.vue";
export default {
    name: "PowerupsV3Modal",
    components: {PowerupsV3, NestableModal}
}
</script>

<style scoped>
.powerups-config {
    /*margin-left: -1em;*/
    /*position: relative;*/
    /*top: -2em;*/
    /*margin-bottom: -3em;*/
}
</style>
<style>
.powerupsv3Modal .modal-footer{
    border: none !important;
    height: 0;
    padding: 0;
}

.powerupsv3Modal .modal-header{
    /*background: var(--water-33);*/
}
.powerupsv3Modal .powerup-config {
    /*margin-top: 1em;*/
    flex: 1;
}
.powerupsv3Modal .powerups-config {
    max-height: calc(100vh - 190px);
    overflow-y: hidden;
}
.powerupsv3Modal .powerups-list{
    /*border-radius: 0;*/
    flex: 1
}
.powerupsv3Modal .powerups-list input{
    /*border-radius: 0;*/
}
</style>
