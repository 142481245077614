<template>
    <form class="row mb-4" ref="contentTemplateSections" @submit.stop.prevent="updateOutside">
        <div class="col col-lg-8 col-12">
            <table class="table">
                <thead>
                <tr>
                    <th class="small">
                        Remaining
                    </th>
                    <th class="small">
                        # Articles
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="split in sectionList" :key="split.index">
                    <td><input type="radio" class="form-check-input" name="templateSections" v-model="remaining" :value="split.index"/></td>
                    <!--<td class="small text-muted">{{split.index+1}}</td>-->
                    <td>
                        <input class="form-control form-control-sm" type="number" @change="update($event, split)" @input="intify(split)" required v-model="split.count" step="1" :min="1" :max="limit - total - 1" v-if="split.index!==remaining"/>
                        <span v-else class="text-small">Remaining Articles</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col col-lg-4 col-12">
            <ul class="no-bullets list-group">
                <li v-for="a in articlePreviews" class="list-group-item p-2" :key="a.key">
                    <div v-if="a.type === 'articles'" class="article">
                        <i class="fa fa-image article-image"></i>
                        <span class="d-inline-block small article-text">
                            <strong>Lorem Ipsum Dolor</strong>
                            <div class="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        </span>
                        <div v-if="a.rest">...</div>
                    </div>
                    <div v-else-if="a.type === 'split'">
                        <hr class="m-0">
                    </div>

                </li>
            </ul>
        </div>
        <button ref="submit" type="submit" class="hide"></button>
    </form>
</template>

<style scoped>
    .article {
        display: grid;
        grid-template-columns: 20px 1fr;
    }
    .article-text {
        font-size: 3.5pt;
    }
</style>

<script>
    import {Array as ArrayUtils} from 'sugar'
    export default {
        props: {
            sections: Array,
            limit: Number
        },
        watch: {
            sections() {
                this.sectionList = this.sections;
                this.remaining = (this.sections || []).find(x => !x.count).index
            },
            remaining(newVal, oldVal) {
                this.sectionList[newVal].count = null;
                this.sectionList[oldVal].count = 1
                this.updateOutside()
            }
            // sectionList() { this.$emit('input', this.sectionList)}
        },
        data() {
            return {
                sectionList: this.sections || [],
                remaining: ((this.sections || []).find(x => !x.count)||{}).index || 0
            }
        },
        computed: {
            articlePreviews() {
                let index = 0;

                let results = this.sectionList.flatMap((x, i) => {
                    let isLast = i === this.sectionList.length - 1;
                    let articles = x.count ? ArrayUtils.construct(x.count, () => ({type: "articles", key: index++, rest: false })) : [{type: "articles", key: index++, rest: true }];
                    return isLast ? articles : [...articles, {type: "split", key: index++}]
                });
                return results;
            },
            total() {
                return this.sectionList.reduce((sum, x) => sum + x.count, 0)
            }
        },
        methods: {
            remove(split) { return this.sectionList.splice(split.index)},
            update(event, split) {
                // console.log('split.count', split.count)
                // if ((!split.count || split.count === '0')&& split.index !== this.remaining) {
                //     split.count = 1;
                // } else {
                //     split.count = parseInt(split.count)
                // }
                console.log(event, split)
                this.$refs.contentTemplateSections.checkValidity()
                this.$refs.submit.click()
            },
            intify(split) { if (split.count) split.count = parseInt(split.count)},
            updateOutside() {
                this.$emit('input', this.sectionList)
            }
        }
    }
</script>
