<template>
    <div>
        <div>
            <query-builder-modal />
            <notes-reader-modal v-if="user" />
            <template v-if="currentAccountOverview">
                <collections-modal v-if="currentAccountOverview" />
            </template>
            <edit-selection-modal v-if="accountFeatures.customSelectionImageText && features.editSelection"></edit-selection-modal>
            <new-workflow-step-modal ref="workflowModal"></new-workflow-step-modal>
            <hubspot-share-modal></hubspot-share-modal>
            <photo-editor-modal ref="photoEditor"></photo-editor-modal>
            <edit-collection-modal></edit-collection-modal>
            <edit-collection-sources-modal></edit-collection-sources-modal>
            <edit-collection-powerups-modal></edit-collection-powerups-modal>
            <collection-advanced-settings-modal></collection-advanced-settings-modal>
            <filter-view-modal></filter-view-modal>
            <powerups-v3-modal v-if="accountFeatures.newPowerupsUx"></powerups-v3-modal>
            <powerups-v4-modal v-if="accountFeatures.newPowerupsUxCards"></powerups-v4-modal>
            <folder-assignments-modal></folder-assignments-modal>
            <CollectionPolicyModal/>
            <rss-feed-modal></rss-feed-modal>
        </div>
        <div>
            <component v-bind:is="m.component" v-bind="m.props" v-for="m in openDynamicModals" :key="m.id">

            </component>
        </div>
    </div>
</template>

<script>
import QueryBuilderModal from '@/components/QueryBuilder/Modal'
import NotesReaderModal from '@/components/Notes/Reader'
import CollectionsModal from '@/components/Collections/Modal';
import EditSelectionModal from "@/components/Collections/EditSelectionModal";
import NewWorkflowStepModal from "@/components/Workflows/NewWorkflowStepModal";
import HubspotShareModal from "@/components/Integrations/Hubspot/HubspotShareModal";
import {mapGetters} from "vuex";
import PhotoEditorModal from "../components/Integrations/PhotoEditor/PhotoEditorModal";
import FilterViewModal from "@/components/FilteringAndSorting/FilterViewModal";
import EditCollectionSourcesModal from "@/components/Collections/V2/EditCollectionSourcesModal";
import EditCollectionPowerupsModal from "@/components/Collections/V2/EditCollectionPowerupsModal";
import EditCollectionModal from "@/components/Collections/V2/EditCollectionModal";
import PowerupsV3Modal from "../components/Collections/powerups/V2/PowerupsV3Modal.vue";
import PowerupsV4Modal from "../components/Collections/powerups/V2/PowerupsV4Modal.vue";
import RssFeedModal from "@/components/Navigation/V2/RssModal";
import CollectionAdvancedSettingsModal from "../components/Collections/V2/CollectionAdvancedSettingsModal";
import FolderAssignmentsModal from "@/components/Folders/FolderAssignmentsModal.vue";
import CollectionPolicyModal from "@/components/Collections/V2/CollectionPolicyModal.vue";

export default {
    name: "Modals",
    components: {
        CollectionAdvancedSettingsModal,
        RssFeedModal,
        PowerupsV3Modal,
        PowerupsV4Modal,
        EditCollectionPowerupsModal,
        EditCollectionSourcesModal,
        EditCollectionModal,
        FilterViewModal,
        PhotoEditorModal,
        EditSelectionModal,
        QueryBuilderModal,
        NotesReaderModal,
        CollectionsModal,
        HubspotShareModal,
        NewWorkflowStepModal,
        FolderAssignmentsModal,
        CollectionPolicyModal,
    },
    data() {
        return {
            openDynamicModals: []
        }
    },
    computed : {
        ...mapGetters({
            user: 'currentUser',
            topic: 'currentTopic',
            currentAccountOverview: 'currentAccountOverview',
            accountFeatures: 'accountFeatures',
            features: 'features',
            collection: 'currentCollection'
        }),
    },
    methods: {

    },
    mounted() {

        window.Events.$on('showNewWorkflowModal', () => {
            this.$refs.workflowModal.show();
        })
    }
}
</script>

<style scoped>

</style>
