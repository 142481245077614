<template>
    <form @submit.stop.prevent="submit" ref="form">
        <div class="form-header">
            <h1>
                {{ id ? 'Edit' : 'Add' }} Gallery
                <span class="form-footer-buttons">
                    <button class="btn btn-secondary me-3" type="button" @click="cancel">Cancel</button>
                    <!--<button class="btn btn-secondary" type="button" @click="save">Save Draft</button>-->
                    <button class="btn btn-primary" type="button" @click="publish">Save</button>
                </span>
            </h1>
        </div>

        <div class="row">
            <div class="col col-12 col-lg-6">
                <fieldset class="form-group">
                    <fieldset class="toggle-label mb-2">
                        <legend class="">Select Gallery Type</legend>
                        <toggle class=" d-flex" v-model="type">
                            <toggle-item value="card" style="flex-grow: 1">
                                <i class="fa fa-th"/> Cards
                            </toggle-item>
                            <toggle-item value="carousel" style="flex-grow: 1">
                                <div><i class="fa fa-image"/> Carousel</div>
                            </toggle-item>
                            <toggle-item value="multiCarousel" style="flex-grow: 1">
                                <div><i class="fa fa-image"/> <i class="fa fa-image"/> Multi-carousel</div>
                            </toggle-item>
                        </toggle>
                    </fieldset>
                    <div class="gallery-builder-form-row mb-2">
                        <label for="gallery-builder-gallery-name">Gallery Name</label>
                            <input type="text"
                                   id="gallery-builder-gallery-name"
                                   required
                                   v-model="name"
                                   placeholder="This is just for you to keep track of which gallery"
                                   class="form-control mb-2"
                            />

                    </div>
                    <div class="gallery-builder-form-row mb-2">
                        <label>Card Theme</label>
                        <uc-select v-model="selectedCardThemeId" :items="cardOptions">

                        </uc-select>
                    </div>
                    <div class="gallery-builder-form-row mb-2">
                        <label v-if="type !== 'card'">Carousel Theme</label>
                        <uc-select v-model="selectedCarouselThemeId" :items="carouselOptions" v-if="type !== 'card'">
                        </uc-select>
                    </div>
                    <div class="gallery-builder-form-row mb-2">
                        <label for="gallery-builder-placeholder-url">Placeholder Image URL</label>
                            <input type="url" v-model="placeholder_url" class="form-control"
                                   id="gallery-builder-placeholder-url" />

                    </div>
                    <!--<label v-if="!multiCarousel">-->
                    <!--Collection-->
                    <!--</label>-->
                    <!--<collection-topic-selector v-if="!multiCarousel" v-model="collection" type="collection"></collection-topic-selector>-->

                </fieldset>
                <details v-if="features.galleryUtm">
                    <summary>
                        UTM Setup
                    </summary>
                    <utm-editor
                        default-from="collection"
                        :default-source="((site && (site.name || site.content)) || 'site name').replace(' ', '_')"
                        :default-content="`website_gallery__${name}`.replace(' ', '_')"
                    >
                    </utm-editor>
                </details>
            </div>
            <fieldset v-if="multiCarousel" class="col col-12 col-lg-6">
                <legend>Add Collections</legend>
                <gallery-collections-list class="mt-1"
                                      :required="true"
                                      :value="collections"
                                      @modifySource="modifyCollection"
                                      @addSource="addCollection"
                                      @deleteSource="deleteCollection"
                                      type="collection"
                                      :showUpdateInfo="false"></gallery-collections-list>
            </fieldset>
            <fieldset v-else class="col col-12 col-lg-6">
                <legend>Select a Collection</legend>
                <content-source-select class=""
                                       v-model="collection"
                                       :required="true"
                                       onlyType="collection"
                                       ref="contentSourceSel"></content-source-select>
            </fieldset>
        </div>
    </form>
</template>

<style scoped>
fieldset.form-group {
    border: none;
    padding-left: 0;
}

fieldset.form-group > legend {
    font-size: 1.25em;
    border-bottom: 1px solid #463179;
    color: #463179;
}

fieldset > legend {
    font-size: 1em;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
    color: var(--bs-dark);
    display: block;
}

h1 {
    border-bottom: 1px solid #463179;
    font-size: 1.7em;
    color: #463179;
    padding-bottom: 10px;
    padding-top: 10px;
}

.form-header {
    /*border-bottom: 1px solid #463179;*/
    margin-bottom: 20px;
}

.form-footer-buttons {
    float: right;
    margin-bottom: 10px;
    font-size: 1rem;
}

.theme-builder .accordion {
    width: 40%;
}
.gallery-builder-form-row > label, .gallery-builder-form-section label {margin-bottom:0; margin-left:1px;}
label {
    color: var(--bs-dark);
    display: block;
}

form {
    position: relative;
}
</style>

<script>
import {Toggle, ToggleItem} from "@/components/Controls/Toggle"
import GalleryCollectionsList from "@/components/Galleries/GalleryCollectionsList"
// import defaultTextStyle from "../../services/DefaultTextStyle";
import ContentSourceSelect from '@/components/Galleries/ContentSourceSelect'
import {mapGetters} from 'vuex'
import {Filters} from "@/models/Filters";
import UcSelect from "@/components/Controls/UpcontentSelect";
import {GalleryType} from "@/models/themes";
import * as toastr from "toastr";
import UtmEditor from "@/components/UtmEditor";

export default {
    components: {
        UtmEditor,
        UcSelect, Toggle, ToggleItem,
        GalleryCollectionsList,
        ContentSourceSelect,
    },
    props: ['value', 'site'],
    data() {
        return {
            selectedCardThemeId: 'api',
            selectedCarouselThemeId: 'api',
            ...this.propsFromVal(),
            selectedTextElement: '',
            collectionsToAdd: [],
            collectionsToModify: [],
            collectionsToDelete: [],
            allThemes: [],
            loading: false
        }
    },
    watch: {
        value() {
            Object.assign(this, this.propsFromVal())
            this.loadThemes()
        },
        allThemes() {
            if (!this.selectedCarouselThemeId) {
                let defaultTheme = this.carouselOptions.find(x => x.description === 'Default') ||
                    this.carouselOptions[1];
                this.selectedCarouselThemeId = defaultTheme ? defaultTheme.value : 'api';
            }
            if (!this.selectedCardThemeId) {
                let defaultTheme = this.cardOptions.find(x => x.description === 'Default') || this.cardOptions[1];
                this.selectedCardThemeId = defaultTheme ? defaultTheme.value : 'api';
            }
        },
    },
    computed: {
        ...mapGetters({
            features: 'features'
        }),
        cardOptions() {
            return [
                {value: 'api', description: 'API Only'},

                ...this.allThemes
                    .filter(x => x.type === 'card')
                    .map(x => ({value: x.id, description: x.name}))
            ]
        },
        carouselOptions() {
            return [
                {value: 'api', description: 'API Only'},
                ...this.allThemes
                    .filter(x => x.type === 'carousel')
                    .map(x => ({value: x.id, description: x.name}))
            ]
        },
        multiCarousel() {
            return this.type === 'multiCarousel'
        },
    },
    methods: {
        propsFromVal() {
            let collection = this.value && this.value.collections && this.value.collections[0];
            let filters = collection && collection.filters || {};
            let galleryId = this.value && this.value.id;
            let selectedCarouselThemeId = this.value && this.value.carousel_theme_id;
            let selectedCardThemeId = this.value && this.value.card_theme_id;
            selectedCarouselThemeId = selectedCarouselThemeId ? selectedCarouselThemeId : galleryId ? 'api' : null;
            selectedCardThemeId = selectedCardThemeId ? selectedCardThemeId : galleryId ? 'api' : null;

            return {
                ...(this.value || {
                    id: '',
                    type: GalleryType.card,
                    domain: '',
                    carouselLimit: 6,
                    name: '',
                    cta: '',
                    placeholder_url: '',
                    collection: null,
                    collections: []
                }),
                selectedCardThemeId,
                selectedCarouselThemeId,
                collection: collection && {
                    id: collection.collection_id,
                    filterViewId: filters.id,
                    hasSuggestionsId: collection.collection_id,
                    hasSuggestionsType: 'collection',
                    // filterModel: {filters: Filters.fromApi(filters), url: ''},
                    // unmodifiedFilters: Filters.fromApi(filters),
                } || null,
                collections: this.value && this.value.collections && this.value.collections.map(({id, collection_id, name, filters}) => ({
                    id,
                    collectionId: collection_id,
                    name,
                    filters,
                    filterViewId: collection.filter_view_id
                })) || [],
            }
        },
        cancel() {
            this.$router.push('/galleries');
        },
        backgroundColorChange(color) {
            this.backgroundColor = color;
        },
        selectElement(el /*'img'|'summary'|'title'|'date'|'source'*/) {
            let accordionIndex = ['title', 'summary', 'date', 'source'].indexOf(el) + 1;
            this.$refs.cardAccordion.open(accordionIndex);

        },
        modifyCollection({filterViewId, id, collectionId}) {
            let collection = this.collections.find(c => c.collectionId === collectionId);
            if (collection) {
                collection = {...collection}
                collection.filterViewId = filterViewId;
                collection.filters.id = filterViewId;
                let index = this.collections.indexOf(collection);
                let collections = [...this.collections];
                collections[index] = collection;
                this.collections = collections;
            }
            let collectionToAdd = this.collectionsToAdd.find(x => x.collection_id === collection.collectionId)
            let collectionToModify = this.collectionsToModify.find(x => x.collection_id === collection.collectionId)
            if (collectionToAdd) {
                collectionToAdd.filterViewId = filterViewId;
            } else if(collectionToModify) {
                collectionToModify.filterViewId = filterViewId;
            } else {
                this.collectionsToModify.push({filterViewId, id, collectionId})
            }
        },
        addCollection(collection) {
            if (this.collections.find(c => c.collectionId === collection.collectionId)) {
                alert("Collection already exists on gallery");
                return;
            }
            this.collections.push(collection);
            this.collectionsToAdd.push(collection);
        },
        deleteCollection(collection) {
            this.collections = this.collections.filter(x => x.collectionId !== collection.collectionId);
            if (this.collectionsToAdd.some(x => x.collection_id === collection.collectionId)) {
                this.collectionsToAdd = this.collectionsToAdd.filter(x => x.collection_id !== collection.collectionId);
            } else {
                this.collectionsToDelete.push(collection);
                if (this.collectionsToModify.some(x => x.collection_id === collection.collectionId)) {
                    this.collectionsToAdd = this.collectionsToModify.filter(x => x.collection_id !== collection.collectionId);
                }
            }
        },
        async loadThemes() {
            window.Events.$emit('startPageLoad')

            try {
                let {data} = await this.fetchThemes();
                this.allThemes = data;
            } finally {
                window.Events.$emit('finishPageLoad')
            }
        },
        async fetchThemes() {
            return await this.$api.get(`/galleries/sites/${this.site.id}/themes`)
        },
        validate() {
            if (!(this.collections && this.collections.length || this.collection)) {
                alert("Must select a collection");
                return false;
            }
            return true;
        },
        async save() {
            console.log('starting save gallery')
            if (!this.$refs.form.reportValidity()) throw "Validation"
            if (!this.validate()) throw "Validation";
            window.Events.$emit('startPageLoad')
            let isNew = !this.id;
            if (isNew) {
                await this.create();
            } else {
                await this.update();
            }
            await this.saveCollections();
            await this.$api.put(`/galleries/${this.id}/staging`);
            let editPath = `/galleries`;///edit/${this.id}`;
            //if (isNew)
            this.$router.push({path: editPath})
            toastr.success('Saved Successfully!')
        },

        async create() {
            this.id = await this.$api.post(`/galleries/sites/${this.site.id}/galleries`, this.dataToSave())
                .then(r => r.data.id);
        },

        async update() {
            await this.$api.put(`/galleries/${this.id}`, this.dataToSave())
        },

        async publish() {
            try {
                await this.save();
                await this.$api.put(`/galleries/${this.id}/production`)
            } finally {
                window.Events.$emit('finishPageLoad')
            }
        },
        async saveCollections() {
            try {
                if (this.type === 'multiCarousel') {
                    let getData = c => ({collection_id: c.collectionId, filter_view_id: c.filterViewId });
                    let modify = c => this.$api.put(`/galleries/${this.id}/collections/${c.collectionId}`, getData(c));
                    let del = c => this.$api.delete(`/galleries/${this.id}/collections/${c.collectionId}`);
                    let add = c => this.$api.post(`/galleries/${this.id}/collections`, getData(c))
                    await Promise.all(this.collectionsToModify.map(modify))
                    await Promise.all(this.collectionsToDelete.map(del));
                    await Promise.all(this.collectionsToAdd.map(add));
                } else {
                    await this.$api.put(`/galleries/${this.id}/collections`, {
                        filter_view_id: this.collection.filterViewId,
                        collection_id: this.collection.id
                    })
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        submit() {
            this.save()
        },
        dataToSave() {
            return {
                type: this.type,
                domain: this.domain,
                staging_domain: this.stagingDomain,
                carousel_theme_id: this.selectedCarouselThemeId !== 'api' ? this.selectedCarouselThemeId : null,
                card_theme_id: this.selectedCardThemeId !== 'api' ? this.selectedCardThemeId : null,
                placeholder_url: this.placeholder_url,
                name: this.name,
            }
        }
    }
}
//     function formatEmail(self) {
//         return `
// Name: ${self.name}
// Domain: ${self.domain}
// Selected Topics: ${self.selectedTopicsAndCollections}
// Sort: ${self.sort}
// Age: ${self.age}
// Carousel Limit: ${self.carouselLimit}
// Suggestion Limit: ${self.suggestionLimit}
// Call to Action: ${self.cta}
// //Background: ${self.backgroundColor}
// //Text: ${self.textColor}
// //Buttons: ${self.buttonColor}
// `;
//     }

</script>
