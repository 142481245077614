<template>
    <div class="list-group">
        <details class="grouped-listbox-group list-group-item p-0 border-0" v-for="group in groups" :open="!collapseAll && isOpen(group)">
            <summary class="list-group-item list-group-header" @click="headerClick">
                {{ group.name }}
            </summary>
            <button
                v-if="clickable"
                v-for="item in group.items"
                type="button"
                class="list-group-item bg-water-100 list-group-item-action"
                @click="$emit('select', item)">
                <slot v-bind="item"/>
            </button>
            <div v-else
                 v-for="item in group.items"
                 class="list-group-item bg-water-100">
                <slot v-bind="item"/>
            </div>
        </details>
    </div>
</template>

<script>
export default {
    name: "GroupedListBox",
    props: {
        grouper: {type: Function, default: () => ''},
        items: {type: Array, default: () => []},
        clickable: {type: Boolean, default: true},
        collapseAll: {type: Boolean, default: false},
        canCollapse: {type: Boolean, default: true},
        isOpen: {type: Function, default: () => true}
    },
    computed: {
        groups() {
            let groups = this.items.reduce((groups, item) => {
                let groupKey = this.grouper(item)
                let existingItemsInGroup = groups[groupKey]||[]
                let newGroup = {[groupKey]: [...existingItemsInGroup, item]}
                return {...groups, ...newGroup}
            }, {});
            return Object.entries(groups).map(([k, v]) => ({name: k, items: v}))
        }
    },
    methods: {
        headerClick(e) {
            if (!this.canCollapse) {
                e.preventDefault()
            }
        }
    }
}
</script>

<style scoped>

.list-group-header {
    font-weight: var(--font-weight-semibold);
    background: var(--water-33);
    color: var(--primary-25);
    font-size: var(--font-size-12);
    padding-bottom: .5em;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-top-width: 0;
}
.grouped-listbox-group:first-child>.list-group-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-width: 1px;
}
</style>
