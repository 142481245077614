const camelToKebab = (string) => {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}

const prefixClassName = (className) => `uc-gallery--${className}`

export function cssForElement(rootSelector, {className, styles}) {
    let kebabProps = Object.entries(styles).map(([k, v]) => [camelToKebab(k), v]);
    let textProps = kebabProps.map(x => x.join(': ')+';').join('\n  ');
    let classSelector = className ? `.${className}` : '';
    let selector = [rootSelector, classSelector].filter(x=>x).join(' ')
    return `
${selector} {
  ${textProps}
}
`
}

export function stylesheetForElements(rootSelector, elements) {
    return elements.map(e => cssForElement(rootSelector, e)).join('\n')
}

export function styleSheet({themeKey, layoutType, pageContainerId}, {container, card, carousel, elements, content}) {
    let allElements = [
        { className: '', styles: container },
        ...(card? [{ className: prefixClassName('card'), styles: card}] :[]),
        ...(carousel? [{className:prefixClassName('collection'), styles: carousel}] :[]),
        { className: prefixClassName('content'), styles: content||{} },
        ...elements.map(x => ({...x, className: prefixClassName(x.className)}))
    ]
    let rootSelector = `.uc-theme--${themeKey} .uc-gallery--${layoutType}s`;
    if (pageContainerId) rootSelector = `#${pageContainerId} ${rootSelector}`
    return stylesheetForElements(rootSelector, allElements)
}
