<template>
    <nestable-modal modal-name="actionBar" @shown="shown" size="medium" ref="modal">
        <template v-slot:header>Actions</template>
        <div class="hold-space">
            <uc-select id="action-bar-select" :items="options" v-model="value" :searchable="true" :filterable="true"></uc-select>
        </div>
    </nestable-modal>
</template>

<script>
import UcSelect from "../Controls/UpcontentSelect";
import {mapActions, mapGetters} from "vuex";
import {hasNonWhitepsaceValue, hasValue} from "../../utilities/utils";
import PathsMixin from "../Folders/PathsMixin";
import {getIconClass} from "../../services/Icons";
import NestableModal from "../Controls/NestableModal";
const allowedPages = [
    '/',
    '/team-members',
    '/galleries',
    '/galleries/',
    '/settings',
    '/integrations',
    '/collections',
    '/topics'
]
export default {
    name: "ActionBar",
    components: {NestableModal, UcSelect},
    mixins: [PathsMixin],
    computed: {
        ...mapGetters({
            collections: 'collections',
            topics: 'topics',
            accounts: 'allAccounts',
            currentCollection: 'currentCollection',
            currentTopic: 'currentTopic'
        }),
        options() {
            return [
                {value:'new:collection', description: 'New Collection', iconClass: 'fa fa-plus'},
                {value:'new:topic', description: 'New Topic', iconClass: 'fa fa-plus'},
                // {value:'edit:collection', description: 'Edit Current Collection', iconClass: 'fa fa-edit'},
                // {value:'edit:topic', description: 'Edit Current Topic', iconClass: 'fa fa-edit'},
                ...this.topics.map(x => ({
                    value: `goto:topic:${x.id}`,
                    description: x.name,
                    path: this.path(x),
                    iconClass: 'fa fa-search'
                })),
                ...this.collections.map(x => ({
                    value: `goto:collection:${x.id}`,
                    description: x.name,
                    path: this.path(x),
                    iconClass: this.icon(x)
                })),
                ...(this.accounts.length > 1 ?
                    this.accounts.map(x => ({
                        value: `goto:account:${x.id}`,
                        description: x.name,
                        iconClass: 'fa fa-users'
                    })) : []),
                ...this.$router.options.routes.filter(x => allowedPages.includes(x.path)).map(x => ({
                    value: `page:${x.path}`,
                    description: x.name || (x.path && this.$strings.capitalize(x.path.replace('/', ''))),
                    iconClass: 'fa fa-file'
                }))
            ]
        }
    },
    data() {
        return {
            value: ''
        }
    },
    watch: {
        value() {
            this.doTheThing(this.value)
        }
    },
    methods: {
        ...mapActions({
            goToCollection: 'setCurrentCollection',
            goToTopic: 'setCurrentTopic',
            changeAccount: 'changeAccount',
        }),
        shown() {
              setTimeout(() => {
                  let el = document.querySelector('#action-bar-select .vs__search');
                  console.log(el)
                  el.focus()
              }, 600)
        },
        showCollectionModal(collection=null) {
            window.Events.$emit('show_editCollectionModal', {collection});
        },
        showTopicModal(topic=false) {
            window.Events.$emit('showQueryBuilderModal', {topic});
        },
        icon(collection) {
            return getIconClass(collection.icon || 'bolt')
        },
        async doTheThing(x) {
            if (!x) return;
            let parts = x.split(':')
            let action = parts[0];
            let targetType = parts[1];
            let id = parts[parts.length - 1];
            console.log('doing action', action, targetType, id, x)
            if (action === 'new') {
                if (targetType === 'collection') {
                    this.showCollectionModal()
                } else if (targetType === 'topic') {
                    this.showTopicModal()
                }
            } else if (action === 'edit') {
                if (targetType === 'collection') {
                    this.showCollectionModal({collection: this.currentCollection})
                } else if (targetType === 'topic') {
                    this.showTopicModal({topic: this.currentTopic})
                }
            } else if (action === 'goto') {
                if (targetType === 'topic') {
                    console.log('* ActionBar.vue doTheThing about to setCurrentTopic')
                    this.goToTopic({topic: this.topics.find(x => x.id === id), switchTo: true})
                } else if (targetType === 'collection') {
                    this.goToCollection({collection: this.collections.find(x => x.id === id), switchTo: true})
                } else if (targetType === 'account') {
                    const accountToSet = this.accounts.find(x => x.id === id)
                    console.log(`* ActionBar doTheThings about to dispatch setCurrentAccount with:`, accountToSet)
                    await this.changeAccount({ overview: accountToSet})
                }
            } else if (action === 'page') {
                this.$router.push(targetType)
            }
            this.$refs.modal.hide()
        }
    }
}
</script>

<style scoped>
    .hold-space {
        min-height: 400px;
    }
</style>
