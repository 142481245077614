<template>
    <div>
        <div v-if="networkError" class="alert alert-danger">
            {{networkError}}
        </div>
        
        <div class="list-group d-block d-lg-none">
            <div class="list-group-item" v-for="member in sortedMembers" :key="member.id+'-team-member-div'">
                <mini-member-editor 
                    :member="member"
                    :team="team"
                    :roles="availableAccountRoles"
                    :canManageTeam="canManageTeam"
                    :user="currentUser"
                    @setRoleForMember="updateRoleForMember" 
                    @showAssignmentEditor="editAssignmentsForTeamMember"
                    @removeMember="removeMember"
                />
            </div>
        </div>
        <table class="table table-striped table-condensed d-none d-lg-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th v-if="canManageTeam">Assignments</th>
                    <th>Role</th>
                    <th v-if="canManageTeam">Actions</th>                            
                </tr>
            </thead>
            <tbody>
                <table-member-editor 
                    :key="member.id+'-team-member-table'" 
                    v-for="member in sortedMembers" 
                    :member="member"
                    :team="team"
                    :roles="availableAccountRoles"
                    :canManageTeam="canManageTeam"
                    :user="currentUser"
                    @setRoleForMember="updateRoleForMember" 
                    @showAssignmentEditor="editAssignmentsForTeamMember"
                    @removeMember="removeMember"
                />
            </tbody>
        </table>
    </div>
</template>

<script>

import {mapGetters} from 'vuex';
import TableMemberEditor from './MemberEditors/Table'
import MiniMemberEditor from './MemberEditors/Mini'

export default {
    props : ['members', 'team'],
    data(){
        return {
            networkError: false
        }
    },
    components : {
        TableMemberEditor, MiniMemberEditor
    },
    computed : {
        ...mapGetters({
            currentUser : 'currentUser',
            accountTeamMembers : 'accountTeamMembers'
        }),
        canManageTeam(){
            return this.$accessControl.can(this.team.role, 'createTeamMemberships');
        },
        availableAccountRoles(){
            var roles = this.$accessControl.rolesForRole(this.team.role);
            
            if (this.team.plan.includes('professional')){
                roles = ['reviewer'];
            }

            return roles;
        },
        sortedMembers(){
            /* eslint-disable */
            return this.members.sort((a, b) => {
                if( ! a.name || ! b.name) return false;
                let aNames = a.name.split(" ");
                let bNames = b.name.split(" ");

                return aNames.pop() > bNames.pop();
            })
            /* eslint-enable */
        }
    },
    mounted(){

    },
    methods : {
        privilegesForUserInAccount(user, account){
            let accountTeam = this.accountTeamMembers.find(a => a.id == account.id)
            if (accountTeam){
                let member = accountTeam.members.find(m => m.id == user.id);
                if (member){
                    return member.privileges
                }
            }
        },
        removeMember({member}){
            let x = confirm("Are you sure you want to remove this team member? You'll have to re-invite them if you want them back");
            if ( ! x ) return;
            
            this.$store.dispatch('removeTeamMemberFromAccount', { user : member, account: this.team});
            let url = `/accounts/${this.team.id}/users/${member.id}`
            this.$api.delete(url)
            .then(() => {
                window.Events.$emit('RefreshCurrentAccountFoldersAndTopics');                         
            })
            .catch(e => {
                if (e.response && e.response.body){
                    this.networkError = e.response.body.description;
                }
                if ( !(e.response && e.response.status == 404)){
                    //If it's a 404, this thing doesn't exist and doesn't belong in memory
                    this.$store.dispatch('addTeamMemberToAccount', { user : member, account: this.team});
                }
            });
            
        },
        updateRoleForMember({member, role}){
            let fallback = {...member};
            let user = {...member, role}
            this.$store.dispatch('updateTeamMemberInAccount', {user, account : this.team})
            this.$api.put(`/accounts/${this.team.id}/users/${user.id}/privileges`, {role})
            .then(() => {
                window.Events.$emit('RefreshCurrentAccountFoldersAndTopics');
            })
            .catch( (e) => {
                if (e.response.data){
                    alert(e.response.data.description)
                }
                this.$store.dispatch('updateTeamMemberInAccount', {user : fallback, account : this.team})
            });

        },
        editAssignmentsForTeamMember({member}){
            this.$emit('showAssignmentEditor', {teamMember : member});
        },
        roleNameForUserInAccount(user, account){
            return "Where do we find this?" + user.role + account.role;
        },
    }
}
</script>

<style scoped>

</style>
