<template>
    <span>
        <span v-if="isHearsay">
            <img src="../../assets/images/whitelabels/hearsay/hearsay-content-plus.svg" height="32"/>
        </span>
        <span v-else-if="appByUpcontent" class="d-flex align-items-top">
            <upcontent-logo style="top: 3px"/>
            <div class="d-inline-block" style="font-size: .9em; line-height: 1.2">
                <div>{{ appByUpcontent }}</div>
                <div class="small" style=" font-size: .6em">by UpContent</div>
            </div>
        </span>
        <span v-else>
            <upcontent-logo/>
            <span>{{appName}}</span>
        </span>
    </span>
</template>

<script>
import {mapGetters} from 'vuex';
import UpcontentLogo from '@/components/Navigation/Logo'
import RouteContext from '@/utilities/RouteContext'
export default {
    name: "AppLogo",
    components: {
        UpcontentLogo
    },
    mixins: [RouteContext],
    computed: {
        ...mapGetters({
            isDealerVenom: 'isDealerVenom',
            isHearsay: 'isHearsay',
            isSocialAdvisors: 'isSocialAdvisors',
            vendor: 'vendor',
            subvendor: 'subvendor',
            appByUpcontent: 'appByUpcontent',
            isAmplify: 'isAmplify',
            isEmbed: 'isEmbed',
            appName: 'appName',
        }),
    }
}
</script>

<style scoped>

</style>
