<template>
    <div class="user-navigation-items position-relative-xs justify-content-end">
        <template v-if="accountFeatures.newCollectionUx">
            <suggestions-global-navigation v-if="showContentWorkflow"></suggestions-global-navigation>
        </template>
        <template v-if="!accountFeatures.newCollectionUx">

            <nav-suggestions-manager class="d-none d-md-block me-3" v-if="showContentWorkflow"/>

            <nav-rss-feed :topic="topic" v-if="topic && rssEnabled && showContentWorkflow"
                          class="me-3 me-lg-0"/>

            <div v-if="isNonFreeAccount"  class="me-4 me-lg-0">
                <router-link to='/team-members'
                             class="nav-link"
                             aria-label="team members"
                             v-show="showAccountsMenu"
                             title="Team Members"><i class="fa fa-users"></i></router-link>
            </div>

            <div v-if="topic"  class="me-4 me-lg-0">
                <a class="nav-link"
                   title="Notes"
                   :class="{'active' : showingNotes}"
                   href="#"
                   @click.prevent="toggleNotes"
                   v-show="showContentWorkflow"
                   aria-label="notes"><i class="fa fa-comments"></i></a>
            </div>
        </template>

        <nav-accounts v-show="showAccountsMenu" style="max-width: calc(100vw - 5em - 4rem);"/>
    </div>
</template>

<script>
import NavAccounts from '@/components/Navigation/AccountsDropdown'
import NavRssFeed from "@/components/Navigation/RSSLink"
import NavSuggestionsManager from "@/components/Navigation/SuggestionsManager"
import {mapGetters, mapActions} from 'vuex';
import RouteContext from '@/utilities/RouteContext'
import SuggestionsGlobalNavigation from "@/components/Navigation/V2/SuggestionsGlobalNavigation";

export default {
    replace: false,
    mixins: [RouteContext],
    props: ['topic', 'isNonFreeAccount'],
    components: {
        SuggestionsGlobalNavigation,
        NavAccounts,
        NavRssFeed,
        NavSuggestionsManager
    },
    computed: {
        ...mapGetters({
            showingNotes: 'showAccountNotesReader',
            suggestionViewerType: "suggestionViewerType",
            accountFeatures: 'accountFeatures',
        }),
        rssEnabled() {
            return !!this.accountFeatures.rss;
        }
    },
    methods: {
        ...mapActions({
            toggleNotes: 'toggleAccountNotesReader'
        }),
    }
}
</script>
