<template>
    <div class="pricing-plan">

        <!-- Non-mobile Devices -->
        <div class="d-none d-sm-block ">
        <div class="card">
            <div class="card-body py-1 p-sm-3">

                <div class="row-margin-12">
                    <h3 class="fw-light font-size-15">Your pricing plan:</h3>
                    <div class="d-flex justify-content-center align-items-center col-margin-4 text-blue-50">
                        <div class="signup-current-plan-icon-wrapper d-flex justify-content-center align-items-center ">
                            <img alt=""
                             role="presentation"
                             :src="signupPlanIcon"
                             class="signup-current-plan-icon"/>
                        </div>
                        <div>{{ planName }}</div>
                    </div>

                    <div class="font-size-13">
                        {{ plan.description }}
                    </div>
                    <div class="d-flex justify-content-around align-items-center">
                        <div class="font-size-15 text-steel-25 text-center">
                            <div class="font-size-12 text-steel-50"><span class="text-steel-25">14</span> days free then</div>
                            <div>
                                <span>${{ signupPlanPriceFormatted }}</span>
                                <span class="font-size-13 text-steel-50">/{{ signupPlanPer }}</span>
                            </div>

                        </div>
                        <div class="font-size-12 text-steel-50 text-left">
                            <div>
                                <span class="text-steel-25">{{ plan.collectionCount }}</span>
                                {{ $strings.plural('collection', plan.collectionCount) }}
                            </div>
                            <div>
                                <span class="text-steel-25">{{ plan.topicCount }}</span>
                                {{ $strings.plural('topic', plan.topicCount) }}
                            </div>
                        </div>
                    </div>
                    <toggle-bar :options="payFrequencies"
                                :value="planFrequency"
                                @input="setSignUpFrequency({planFrequency: $event})"
                                class="font-size-14 ps-2 pe-2 m-0"></toggle-bar>

                    <div class="font-size-12 font-italic mt-1 row-margin-4">
                        <div class="text-steel-66 text-center">
                            <template v-if="planFrequency === '12m'">Saving 10% with annual billing!
                            </template>
                            <template v-else>Save 10% with annual billing.</template>
                        </div>
                        <p class="text-steel-33">Billing begins after your <span class="text-nowrap">14-day</span> trial ends.</p>
                    </div>

                </div>

            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-link  font-size-14 ux-pp-change-plan ux-pp-change-plan-desktop" @click.prevent="showPricingPlanModal">
                <span class="btn-link-text me-1">Change Plan</span>
                <i class="fa fa-chevron-right  font-size-12"></i>
            </button>
        </div>
        </div>

        <!-- Mobile Devices -->
        <div class="d-sm-none mb-2 form-group">
            <label class="label-chill">Your Pricing Plan</label>
            <button class="btn btn-select font-size-14 d-flex justify-content-between
                           align-items-center ux-pp-change-plan ux-pp-change-plan-mobile"
                    @click.prevent="showPricingPlanModal">
            <span class="d-flex align-items-baseline col-margin-1">
                <img alt=""
                     aria-hidden="true"
                     role="presentation"
                     :src="signupPlanIcon"
                     class="signup-current-plan-icon-mobile top-1"/>
                <span class="font-size-18 text-steel-33">{{ planName }} </span>
                <span  class="font-size-14 text-steel-33 bottom-1 font-italic">
                    <span class="bottom-1">@</span>
                    ${{ signupPlanPriceFormatted }} /{{ signupPlanPer }}
                </span>
            </span>

                <i class="fa fa-chevron-down  font-size-12"></i>
            </button>
            <div class="text-end font-italic text-steel-50 font-size-12 pt-1">
                Billing begins after your <span class="text-nowrap">14-day</span> trial ends.
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.signup-current-plan-icon-wrapper {
    width: 2em;
    height: 2em;
}

.signup-current-plan-icon {
    width: 2em;
}

.signup-current-plan-icon-mobile {
    height: 2ex;
}
</style>
<script>
import {mapActions, mapGetters} from "vuex";
import ToggleBar from "@/utilities/ToggleBar";
import BasePlan from "@/components/Auth/V2/BasePlan";
import {payFrequencies} from "@/Constants";
import PlanGroups from "@/utilities/PlanList";
import {getDecimalAmount, getDollarAmount} from "@/services/money";

export default {
    name: 'PricingPlan',
    mixins: [BasePlan],
    components: {ToggleBar},
    data() {
        return {
            payFrequencyId: this.$strings.generateId('pay-frequency'),
            payFrequencies: payFrequencies,
        }
    },
    computed: {
        ...mapGetters({
            planName: 'planName',
            signupPlanIcon: 'signupPlanIcon',
            signupPlanPer: 'signupPlanPer',
            signupPlanPriceFormatted: 'signupPlanPriceFormatted',
            planFrequency: 'planFrequency',
        }),
        planDuration() {
            return this.plan && this.plan.durations.find(x => x.id.includes(this.planFrequency))
        },
        plan() {
            return PlanGroups.find(p => p.name === this.planName)
        },
        cost() {
            return this.planDuration && this.planDuration.cost;
        },
        planCost() {
            if (!this.cost) {
                return 0;
            }
            const result = this.planFrequency === '12m' ? this.cost / 12 : this.cost;

            // Convert to decimal amount.
            return result / 100;
        },
        planDollarAmount() {
            return this.planCost && this.planCost > 0 ? getDollarAmount(this.planCost) : '';
        },
        planCentAmount() {
            return this.planCost && this.planCost > 0 ? getDecimalAmount(this.planCost) : '';
        },
    },
    watch: {},
    mounted() {
    },
    methods: {
        ...mapActions({
            setSignUpFrequency: 'setSignUpFrequency',
        }),
        showPricingPlanModal() {
            window.Events.$emit('show_pricingPlanModal', '');
        },
        requestSubscriptionChange() {

            this.$emit('planSelected', this.selectedPlan);

            this.networkError = false;

            this.networkActivity = "Updating plan...";

            let planArray = this.selectedPlan.split('_');
            let interval = planArray.pop();
            let planPackage = planArray.join('_');
            let request = {package: planPackage, interval}

            this.$api.put(`/accounts/${this.account.id}/subscription`, request)
                .then(response => {
                    this.networkActivity = false;
                    let subscription = response.data;
                    this.$store.dispatch('addSubscriptionToAccount', {subscription, account: this.account});
                    this.$store.dispatch('updateAccount',
                        {account: {...this.account, plan: this.selectedPlan}});

                    // this.fetchTeamMembers(() => {
                    //     this.$emit('change');
                    // })

                    // window.Events.$emit('ReloadUserAccounts');

                    if (this.signupPlan && !this.currentTopic) {
                        this.$store.dispatch('setUserSignUpPlan', {plan: false});
                        this.$router.push(`/welcome?success=true&plan=${planPackage}`);
                    }

                }).catch(e => {
                if (e.response && e.response.data && e.response.data.description) {
                    this.networkError = e.response.data.description
                } else {
                    this.networkError = "Something went wrong processing your plan selection";
                }
                this.networkActivity = false;
            })
        },
    }
}
</script>
