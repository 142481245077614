import {StringUtils} from '@/utilities/Strings'
import {OAuthConnection} from "@/services/OAuth";
export class AmplifyConnection extends OAuthConnection {
    constructor(context) {
        super('amplify', context);
    }
    fetchTopics(team){
        return this.$api.get(`/accounts/${team.id}/amplify/topics`);
    }
    fetchCollectionSettings(collection) {
        return this.$api.get('/collections/'+collection.id+'/integrations/amplify/settings')
    }
    connectUrl(){
        let uri = StringUtils.replaceAll(process.env.VUE_APP_HOOTSUITE_AMPLIFY_REDIRECT_URI, "%2F", "/");
        return `https://platform.hootsuite.com/oauth2/auth?client_id=${process.env.VUE_APP_HOOTSUITE_AMPLIFY_CLIENT_ID}&response_type=code&scope=offline&redirect_uri=${this.returnUrl()}`
    }
}
