<template>
    <div class="old-rss-link dropdown" ref="root">
        <a  class="nav-link p-0 top-2" href="#" role="button" aria-label="RSS Link" data-bs-toggle="dropdown"
            aria-haspopup="true" title="RSS Feed">
            <i class="fa fa-link me-1"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-center topic-rss-feed-container" aria-labelledby="TopicRssFeed" @click.prevent.stop>
            <h2>Copy and paste this link into your RSS feed reader of choice:</h2>
            <textarea @click.prevent.stop id="feed-container-text-old"
            rows="5" :value="feedUrl"></textarea>
<!--            <label @click.stop>-->
<!--                <input type="checkbox" v-model="includeAllSources"/>-->
<!--                Include All Sources (Including New)-->
<!--            </label>-->
<!--            <label @click.stop>-->
<!--                <input type="checkbox" v-model="includeNewSources" :disabled="includeAllSources" :class="{'text-muted': includeAllSources}"/>-->
<!--                Include New Sources-->
<!--            </label>-->
            <br/>
            <p class="text-muted small">To change filter parameters change the filters for the current collection</p>
            <button class="old-rss-link-btn btn btn-link" type="button"
                @click.prevent.stop="copyFeedUrl($event)"
            >
                 <span v-if="copiedText"><i class="fa fa-check-circle"></i> Copied to Clipboard</span>
                 <span v-else><i class="fa fa-clipboard"></i> Copy RSS Feed URL</span>
            </button>
        </div>
    </div>

</template>

<script>

import { mapGetters } from 'vuex';
import * as $ from 'jquery'
import RssFilterBase from '@/components/FilteringAndSorting/RssFilterBase'
import {Dropdown} from "bootstrap"

export default {
    props : ['topic'],
    mixins: [RssFilterBase],
    data(){
        return {
            copiedText : false
        }
    },
    computed : {
        ...mapGetters({
            showingFavoriteSuggestions : "showFavoriteSuggestions",
            filters : 'currentFilters',
            selectedSources: 'selectedSources',
            excludedSources: 'excludedSources',
            includeNewSources: 'includeNewSources',
            hasSuggestions : 'hasSuggestions',
            accountFeatures: 'accountFeatures',
                   
        }),
    },
    mounted(){

    },
    methods : {
        copyFeedUrl(event){
            var copyText = document.getElementById("feed-container-text-old");
            copyText.select();
            document.execCommand("Copy");
            this.copiedText = true;
            setTimeout(() => {
                this.copiedText = false;
                let dd = new Dropdown(this.$refs.root);
                dd.hide()
            }, 5000)
        },
    }
}
</script>

<style>
.topic-rss-feed-container {
    padding: 20px !important;
    min-width: 19rem;
    font-size: 10px;
}

.topic-rss-feed-container h2 {
    font-size: 14px;
}

.topic-rss-feed-container textarea {
    border: none;
    font-size: 10px !important;
    width: 100%;
    resize: none;
}

.topic-rss-feed-container textarea:focus {
    outline: none;
}
</style>
