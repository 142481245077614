import defaultTextStyle from "@/services/DefaultTextStyle";

export function newTheme(type) {
    return type === 'card' ? newCardTheme() : newCarouselTheme()
}

const newCardTheme = () => ({
    rootElement: 'upcontent-suggestion-list',
    cardProps: cardProps('card'),
    base: baseProps(),
    layout: layoutProps(),
    elements: [
        {
            name: 'Headline',
            className: 'headline',
            userStyles: [],
            styles: defaultTextStyle(150, { fixedHeight: false }),
            type: 'text'
        },
        {
            name: 'Summary',
            className: 'summary',
            userStyles: [],
            styles: defaultTextStyle(100, { fixedHeight: false }),
            type: 'text'
        },
        {
            name: 'Date',
            className: 'date',
            userStyles: [],
            styles: defaultTextStyle(90),
            type: 'text'
        },
        {
            name: 'Source',
            className: 'source',
            userStyles: [],
            styles: defaultTextStyle(90),
            type: 'text'
        },
        {
            name: 'Image',
            className: 'image',
            styles: {},
            userStyles: [],
            type: 'image'
        }
    ]
});
const newCarouselTheme = () => ({
    rootElement: 'upcontent-gallery',
    cardProps: cardProps('carousel'),
    base: baseProps(),
    layout: layoutProps(),
    elements: [
        {
            name: 'Headline Container',
            className: 'headline',
            userStyles: [],
            styles: defaultTextStyle(120, {hideable: true, shadow: 'None', borderColor: '', cornerRoundRadius: 0}),
            type: 'container-lite',
        },
        {
            name: 'Headline Text',
            className: 'headline-inner',
            userStyles: [],
            styles: defaultTextStyle(120, { hideable: true, lineHeight: 1.5, inline: true, backgroundColor: '#911cff', color: '#ffffff', }),
            type: 'text'
        },
        {
            name: 'Title',
            className: 'title',
            userStyles: [],
            styles: defaultTextStyle(150, {inline: false}),
            type: 'text'
        },
        {
            name: 'Count',
            className: 'count',
            userStyles: [],
            styles: defaultTextStyle(90, {hideable: true, inline: false}),
            type: 'text'
        },
        {
            name: 'Source',
            className: 'source',
            userStyles: [],
            styles: defaultTextStyle(90, {backgroundColor: '#43d5ff', color: '#ffffff'}),
            type: 'text'
        },
        {
            name: 'Image',
            className: 'image',
            styles: {},
            userStyles: []
        }
    ]
});


// function con (props) {
//     return {
//         backgroundColor: '',
//         inline: null,
//         lineHeight: null,
//         fixedHeight: null,
//         height: 3,
//         padding: { top: 0, bottom: 0, left: 0, right: 0},
//         ...(props||{})
//     }
// }


const cardProps = (type) => ({
    size: type === 'carousel' ? 450 : 300,
    horizontalPadding: type === 'carousel' ? 0 : 10,
    verticalPadding: type === 'carousel' ? 0 : 10,
    cornerRoundRadius: type === 'carousel' ? 0 : 5,
    backgroundColor: '#ffffff',
    borderColor: '#000000',
    contentHorizontalPadding: 0,
    contentTopPadding: 0,
    contentBottomPadding: 0,
    userStyles: {}
})

const layoutProps = () => ({
    horizontalMargin: 10,
    verticalMargin: 10,
    layout: 'flex-start',
})

const baseProps = () => ({
    fontSize: 13,
    backgroundColor: '#ffffff',
    brandColor: '#5042f4',
    textColor: '#000000',
    presetFont: 'Helvetica, Arial, sans-serif',
    customFont: '',
    userStyles: {},
});

export const GalleryType = {
    card: 'card',
    carousel: 'carousel',
    multiCarousel: 'multiCarousel'
};
export const ThemeType = {
    card: 'card',
    carousel: 'carousel'
};

export const shadowColors = [
    { description: 'Dark', value: 'rgba(0,0,0,.4)'},
    { description: 'Light', value: 'rgba(0, 0, 0, 0.2)'},
    { description: 'White', value: 'rgba(255, 255, 255, 0.5)'}
];

export const shadowSizes = [3, 5, 10];

// export const shadowOptions =
//     shadowColors.flatMap(c =>
//         shadowSizes.map(s =>
//             `${s}px, ${c.description}`
//         )
//     );


export function upgradeTheme(type, theme) {
    let defaultTheme = newTheme(type);
    return {
        ...defaultTheme,
        ...theme,
        elements: defaultTheme.elements.map(de => {
            let te = theme.elements.find(te => te.className === de.className);
            return {
                ...de,
                userStyles: te.userStyles,
                styles: { ...de.styles, ...te.styles}
            }
        })
    }
}
