<template>

</template>

<script>
import {mapGetters} from "vuex";
import CollectionDataEditor from "./CollectionDataEditor";

export default {
    name: "ContentPolicyBase",
    mixins: [CollectionDataEditor],
    props: {
        collectionId: String
    },
    computed: {
        ...mapGetters({
            accountFeatures: 'accountFeatures'
        }),
        defaultValues() {
            return {
                abstractLimit: null,
                userBehavior: 'warn',
                automationBehavior: 'truncate',
                userAbstractTruncate: true
            }
        }
    },
    data() {
        return {
            abstractLimit: null,
            userBehavior: 'warn',
            automationBehavior: 'truncate',
            userAbstractTruncate: true
        }
    },
    methods: {
    },
    created() {
        this.metadataKey = 'policy';
    }
}
</script>

<style scoped>

</style>
