<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mb-3">
                <h5 class="text-dark">Add a Source</h5>
                <div class="error mb-2 mt-2" v-if="sourceErrorMessage">{{ sourceErrorMessage }}</div>
                <add-content-source-form
                    @addSource="addSource"
                    :collectionId="collection && collection.id"
                    :sources-busy="sourcesBusy"
                    ref="addSourceForm"
                    :onlyType="onlyType"
                ></add-content-source-form>
            </div>
            <div class="col-12">
                <h5 class="text-dark" v-if="filteredSources.length > 0">Sources</h5>
                <div v-if="sourcesBusy">
                    <spinner class="list-spinner"></spinner>
                </div>
                <ul v-else class="list-group source-list" style="font-size: 90%">
                    <collection-source-display v-bind="source" v-for="source in filteredSources" :key="source.key"
                                               :source-id="source.id"
                                               @filtersUpdated="filtersUpdated($event, source.key)"
                                               :destinationCollection="collection"
                                               @deleteSource="() => deleteSource(source)"
                    >
                        <!--                               :lastChecked = 'source.lastChecked'
                       :lastPublished = 'source.lastPublished'
                       :url = 'source.url'
                       :added = 'source.added'
                       :name = 'source.name'
                       :topic-id='source.topicId'
                       :rss-feed-id='source.rssFeedId'
                       :source-collection-id="source.sourceCollectionId"
                       :failure-reason="source.failureReason"-->
                    </collection-source-display>
                </ul>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
import {CollectionSourceViewModel} from "@/models/CollectionSourceViewModel";
import Spinner from '@/components/Controls/Spinner'
import AddContentSourceForm from "@/components/Collections/AddContentSourceForm";
import CollectionSourceDisplay from "@/components/Collections/CollectionSourceDisplay";
import {mapActions, mapGetters} from "vuex";
import * as toastr from "toastr";

export default {
    components: {CollectionSourceDisplay, AddContentSourceForm, Spinner},
    props: {
        collection: Object,
        onlyType: {type: String, default: null},
    },
    data() {
        return {
            sourceErrorMessage: '',
            sourcesBusy: false,
            filteredSources: [],
            toastOptions:  {
                timeOut: 5000,
                closeOnHover: false,
                preventDuplicates: true,
            }
        }
    },
    computed: {
        ...mapGetters({
            collectionSources: 'collectionSources',
        })
    },
    mounted() {
        this.loadCollectionSources();
    },
    watch: {
        collection() {
            this.resetForm()
        },
        collectionSources() {
            this.filteredSources = this.onlyType ?
                this.collectionSources.filter(f => f.type === this.onlyType)
                : this.collectionSources
        }
    },
    methods: {
        ...mapActions([
            'loadCollectionSources',
        ]),
        async deleteSource(source) {
            this.$refs.addSourceForm.resetErrors();

            if (this.collection && this.collection.id) {
                this.sourcesBusy = true;
                await this.$api.delete(`/collections/${this.collection.id}/sources/${source.id}`);
                await this.loadCollectionSources();
                this.sourcesBusy = false;

                this.$emit('saved');

                const message = `The ${source.type} source '${source.name}' has been removed from this collection.`

                toastr.info(message, 'Removed');
            }
        },
        async addSource({url, type, id, filters, filterViewId}) {
            if (type === 'rss') {
                let isDuplicate = this.collectionSources.some(src => src.url && src.url.toLowerCase() ===
                    url.toLowerCase());
                if (isDuplicate) {
                    this.$refs.addSourceForm.setError('RSS Feed already attached to collection');
                    await this.$refs.addSourceForm.focusOnAddRssTextBox(true);
                    return;
                }
            } else {
                let isDuplicate = this.collectionSources.some(src => src.id === id);
                if (isDuplicate) {
                    this.$refs.addSourceForm.setError(
                        `${this.$strings.capitalize(type)} already attached to collection`
                    );
                    return;
                }
            }

            let vm = CollectionSourceViewModel.fromUi({url, type, id, filters, filterViewId});
            if (this.collection && this.collection.id) {
                await this.showSourceLoader(this.addSourceToServer(this.collection.id, vm));
            }

            try {
                await this.loadCollectionSources()
            } catch (e) {
                console.error('loadCollectionSources()', e);
            }
            
            if(type !== 'rss') {
                this.confirmSourceAdded(id)
            }
            else {
                this.confirmRssSourceAdded(url.toLowerCase())
                await this.$refs.addSourceForm.focusOnAddRssTextBox();
            }

            this.$refs.addSourceForm.resetForm(false);

            this.$emit('saved');
        },
        confirmSourceAdded(id) {
            const source = this.collectionSources.find(f => f.sourceCollectionId === id);
            const message = `Your new ${source.type} source '${source.name}' for this collection has been saved!`

            toastr.success(message, 'Saved');
        },
        confirmRssSourceAdded(url) {
            const source = this.collectionSources.find(f => f.url && f.url === url);
            const message = `Your new ${source.type} source '${source.name}' has been saved for this collection!`

            toastr.success(message, 'Saved');
        },
        async showSourceLoader(promise) {
            this.sourcesBusy = true;
            try {
                await promise;
            } finally {
                this.sourcesBusy = false;
            }
        },
        async addSourceToServer(collectionId, vm) {
            try {
                await this.$api.post(`/collections/${collectionId}/sources`, vm.toApi());
            } catch (e) {
                console.log(e)
                if (e.response.status == 409) { // conflict
                    this.$refs.addSourceForm.setError(e.response.data.error);
                } else if (e.response.status == 400) {
                    this.$refs.addSourceForm.setError(e.response.data.error, e.response.data.suggestions || [],
                        e.response.data.recoverable || false);
                }
                throw e
            }
        },
        filtersUpdated({filterViewId}, key) {
            let source = this.filteredSources.find(s => s.key !== key)
            if (source) {
                source.filterViewId = filterViewId;
            }
        },
        resetForm() {
            this.$refs.addSourceForm.resetForm(true)
        },
    }
}
</script>
