<template>
    <div class="progress">
        <div class="progress-bar" role="progressbar" :style="{width: `${percent}%`}" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100">{{percent}}%</div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        percent: {type: Number}
    }
}
</script>

<style scoped>

</style>
