<template>
    <div class="privacy-and-terms-agreement">
        <div class="text-steel-33 d-table ms-sm-auto me-sm-auto ms-md-auto me-md-0">
            <label class="d-table-row ux-privacy-terms-label">
                <span class="d-table-cell">
                <input type="checkbox"
                       v-model="isTermsAndPrivacyAccepted"
                       @change="termsAndPrivacyChanged"
                       class="top-1 me-1 agreement-checkbox ux-privacy-terms-input"
                       required="required"
                />
                </span>
                <span class="d-table-cell text-start ps-1">
                    I agree with {{ legalEntityShort }}'s
                    <a target="_blank" :href="termsOfServiceUrl"
                       class="ux-privacy-terms-link" @click.stop>Terms &amp; Conditions </a>
                    and
                    <a target="_blank" :href="privacyPolicyUrl"
                       class="ux-privacy-privacy-link" @click.stop>Privacy Statement</a>.
                </span>
            </label>
        </div>
        <div v-if="validate &&  !isTermsAndPrivacyAccepted"
             class="alert alert-warning d-table ms-auto me-auto ms-md-auto me-md-0 mt-2 text-center">
            You need to agree to the <span class="text-nowrap">Terms &amp; Conditions</span>
            <span class="text-nowrap"> and Privacy Statement.</span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "PrivacyAndTermsAgreement",
    data() {
        return {
            validate: false,
            isTermsAndPrivacyAccepted: false,
        }
    },
    computed: {
        ...mapGetters({
            termsAndPrivacyAcceptedDate: 'termsAndPrivacyAcceptedDate',
            appName: 'appName',
            legalEntityShort: 'legalEntityShort',
            legalEntityFull: 'legalEntityFull',
            termsOfServiceUrl: 'termsOfServiceUrl',
            privacyPolicyUrl: 'privacyPolicyUrl',
        }),
    },
    mounted() {
        window.Events.$on('resetTermsAndPrivacy', this.resetTermsAndPrivacy);
        window.Events.$on('validateTermsAndPrivacyAgreement', this.validateTermsAndPrivacyAgreement);
        this.isTermsAndPrivacyAccepted = this.termsAndPrivacyAcceptedDate !== null;
    },
    methods: {
        ...mapActions({
            setTermsAndPrivacyAcceptedDate: 'setTermsAndPrivacyAcceptedDate',
        }),
        resetTermsAndPrivacy() {
            this.setTermsAndPrivacyAcceptedDate({acceptedDate: null});
        },
        validateTermsAndPrivacyAgreement() {
            this.validate = true;
        },
        termsAndPrivacyChanged() {
            this.validate = false;
            const value = this.isTermsAndPrivacyAccepted ? Date.now() : null;
            this.setTermsAndPrivacyAcceptedDate({acceptedDate: value});
        }
    },
}
</script>

<style lang="scss" scoped>
.agreement-checkbox {
    $sizing: 1.25em;
    width: $sizing;
    height: $sizing
}
</style>
