
<script>
import {mapGetters} from "vuex";
import {getIconClass} from "../../../services/Icons";

export default {
    name: "PowerupMeta",
    computed: {
        ...mapGetters({
            powerupDescriptions: 'powerupDescriptions',
            learnUrls: "learnUrls",
            powerupNameMap: 'powerupNameMap'
        }),
        learnUrl() { return this.learnUrls[this.powerupNameMap[this.name]] },
        description() { return this.powerupDescriptions[this.powerupNameMap[this.name]] },
        iconClass() {
            return getIconClass(this.name)
        },
    }
}
</script>

<style scoped>

</style>
