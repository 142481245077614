<script>
import moment from 'moment';

export default {
    props : ['invitation'],
    data(){
        return {

        }
    },
    filters : {
        prettyDate(date){
            return moment.utc(date).fromNow()
        }
    },
    mounted(){

    },
    methods : {
        resendInvitation(){
            this.$emit('resend', this.invitation);
        },
        rescindInvitation(){
            this.$emit('rescind', this.invitation);
        },
        copyLink(el) {
            // let el = event.target
            console.log('el.value', el.value)
            el.style.display = 'block'
            el.select();
            document.execCommand("Copy");
            el.style.display = 'none'

            // el.display = 'none'
            alert('Link copied')
        }
    }
}
</script>

<style>
</style>
