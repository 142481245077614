<template>
    <div class="">
        <div class="row">
            <label for="filter-form-content-type" class="col-12 col-sm-4 align-self-end top-3">Content&nbsp;Type</label>
            <div class="col-12 col-sm">
                <select id="filter-form-content-type"
                        class="filter-item form-control form-select form-control-sm"
                        v-model="contentType"
                        @change="update()">
                    <option v-for="thisType in availableContentTypes"
                            :key="'content-types-'+thisType"
                            :value="thisType">
                        {{ thisType }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>

import Base from './FilterMixin'
import {mapGetters} from 'vuex'

export default {
    mixins: [Base],
    computed: {
        ...mapGetters({
            features: 'features'
        }),
        availableContentTypes() {
            return [
                'All',
                'News',
                'Blog',
                ...(this.features.otherContentType ? [
                    'Other'
                ] : [])
            ]
        }
    },
    watch: {
        filters() {
            if (this.filters)
                this.contentType = this.filters.contentType || 'All'
        }
    },
    data() {
        return {
            contentType: 'All',
        }
    },
    mounted() {
        if (this.filters)
            this.contentType = this.filters.contentType
    },
    methods: {
        update() {
            this.updateFilter('contentType', this.contentType);
        }
    }
}
</script>

<style scoped>
.filter-item label {
    display: inline;
    margin-right: 10px;
}
</style>
