<template>
    <div>
        <ul class="cards-list-container list-group no-bullets p-0"
            v-if="displayType == 'list' && ! isReading">
            <li
                class="list-group-item suggestion-list-group-item"
                v-for="card in suggestions"
                :key="'list-'+card.id+id"
                :id="'suggestion-'+card.id"
                role="listitem details"
                :aria-label="card.title"
                @mouseover="setActiveSuggestion(card)"
                @mouseout="unsetActiveSuggestion"
            >
                <card-list
                    class="suggestion-card"
                    :card="card"
                    :collection-batch="collectionBatch"
                    @toggleCardInCollectionBatch="toggleCardInCollectionBatch"
                    :option-key-is-pressed="optionKeyIsPressed"
                    :collections="collections"
                    @collectionBatchUsed="collectionBatch = []"
                    :containerType="containerType"
                    @toggleFavorite="toggleFavorite"
                ></card-list>
            </li>
        </ul>
        <ul class="cards-grid-container no-bullets p-0" v-else>
            <li v-for="card in suggestions"
                class="suggestion-card"
                :key="'grid-'+card.id+id"
                :id="'suggestion-'+card.id"
                @mouseover="setActiveSuggestion(card)"
                @mouseout="unsetActiveSuggestion"
            >
                <card-grid
                    :option-key-is-pressed="optionKeyIsPressed"
                    :card="card"
                    :collection-batch="collectionBatch"
                    @toggleCardInCollectionBatch="toggleCardInCollectionBatch"
                    :collections="collections"
                    @collectionBatchUsed="collectionBatch = []"
                    :containerType="containerType"
                    @toggleFavorite="toggleFavorite"
                ></card-grid>
            </li>
        </ul>
    </div>
</template>

<script>

import uuid from 'uuid';

import CardList from './SuggestionCards/Card-List'
import CardGrid from './SuggestionCards/Card-Grid'
import {mapGetters} from 'vuex';

export default {
    props: ['suggestions', 'displayType', 'containerType', 'collectionBatch'],
    components: {
        CardList, CardGrid
    },
    data() {
        return {
            id: uuid.v4(),
            optionKeyIsPressed: false,
            activeSuggestion: false
        }
    },
    computed: {
        ...mapGetters({
            isReading: 'readingSuggestion',
            collections: 'collections',
            user: 'currentUser'
        })
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode == 18) {
                this.optionKeyIsPressed = true;
            }
        })

        document.addEventListener('keyup', (e) => {
            if (e.keyCode == 18) {
                this.optionKeyIsPressed = false;
            }

            if (e.keyCode == 67 && this.activeSuggestion) {
                this.$emit('toggleCardInCollectionBatch', {card: this.activeSuggestion});
            }
        })
    },
    methods: {
        toggleFavorite({card}) {

            if (!card) {
                return;
            }

            let favoritedIndex = card.favorited_by.map(d => d.id).indexOf(this.user.id);
            let isFavoritedByCurrentUser = favoritedIndex > -1;

            var suggestion = JSON.parse(JSON.stringify(card));

            if (isFavoritedByCurrentUser) {
                this.$api.delete('/favorite-suggestions/' + card.id);
                suggestion.favorited_by.splice(favoritedIndex, 1)
                this.$intercom.trackEvent('Unfavorite suggestion', {suggestion: card.id});
            } else {
                this.$api.put('/favorite-suggestions/' + card.id);
                suggestion.favorited_by.push(this.user)
                this.$intercom.trackEvent('Favorite suggestion', {suggestion: card.id});
            }

            this.$store.dispatch('updateSuggestion', {suggestion});
        },
        toggleCardInCollectionBatch({card}) {
            this.$emit('toggleCardInCollectionBatch', {card});
        },
        setActiveSuggestion(card) {
            this.activeSuggestion = card
        },
        unsetActiveSuggestion() {
            this.activeSuggestion = false
        }
    }
}
</script>

<style lang="scss" scoped>
// Okay so minmax(220px) is the smallest acceptable min-width for the card.
// When the article reader is showing, we'll allow for a smaller minmax value.
$narrowest-min-width: 200px;
.cards-grid-container {
    .suggestion-card {
        max-width: 365px;
    }
}

.cards-grid-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(220px, 1fr));
    grid-gap: .75em;
    font-size: var(--font-size-13);
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .cards-grid-container {
        grid-template-columns: repeat( auto-fit, minmax($narrowest-min-width, 1fr));
    }
    .suggestion-card {
        max-width: unset;
    }
}

@media screen and (max-width: 991.98px) {
    .cards-grid-container {
        grid-gap: .5em;
    }
}

@media (min-width: 992px) {
    .narrow .cards-grid-container {
        grid-template-columns: repeat( auto-fit, minmax($narrowest-min-width, 1fr));
    }
}

@media (min-width: 1200px) {
    .narrow .cards-grid-container {
        grid-template-columns: repeat( auto-fit, minmax(220px, 1fr));
    }
}

@media (min-width: 1400px) {
    .cards-grid-container {
        grid-template-columns: repeat( auto-fit, minmax(235px, 1fr));
    }
}

.suggestion-list-group-item:hover {
    background: rgb(235, 232, 236);
}
</style>
