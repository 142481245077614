import Sugar from 'sugar'
import moment from 'moment'

const actions = {
    async filtersTimeChanged({dispatch}, {time}) {
        let age = daysSelection(time);

        let errorMessage = validateAge(age);
        if (errorMessage) {
            throw errorMessage
        }

        // TODO: any additional logic

        await dispatch("filtersChanged", {filters: { time, age, customTime: "" }});
    },
    async filtersCustomTimeChanged({dispatch}, {customTime}) {
        let age = customDays(customTime);

        let errorMessage = validateAge(age);
        if (errorMessage) {
            throw errorMessage
        }

        // TODO: any additional logic
        if (!customTime) {
            await dispatch("filtersChanged", {filters: { time: "Day", age, customTime: "" }});
        } else {
            await dispatch("filtersChanged", {filters: { time: "", age, customTime }});
        }
    },
};

function validateAge(age) {
    if (isNaN(age)){
        return 'The time frame you entered is not supported'
    }
    let upperLimit = 8760;
    upperLimit = Math.round(upperLimit / 24)

    if (age > upperLimit){
        return'You cannot search further than 1 year ago';
    }
}

function customDays(customTime) {
    console.log('customDays().customTime', customTime)
    if (!customTime) return 1;
    if ( ! customTime.includes('ago')) customTime += ' ago'; //TypeError: customTime.includes is not a function
    let date = Sugar.Date.create(customTime)
    let now = moment();
    let hours = now.diff(date, 'hours')
    return Math.round(hours / 24)

}
function daysSelection(time) {
    if (time === 'Day'){
        return 1;
    } else if (time === 'Week'){
        return 7;
    } else if (time === 'Month'){
        return 30;
    } else if (time === '2 Months'){
        return 60;
    } else if (time === 'Forever'){
        return  -1;
    } else {
        return NaN;
    }
}

const getters = {
    availableTimeTypes: (state, getters, rootState, rootGetters) => {
        const types =  [
            'Day',
            'Week',
            'Month',
            '2 Months'
        ];

        if (rootGetters.suggestionViewerType === 'collections'){
            types.unshift('Forever')
        }

        return types;
    },
    filterTime(state, getters, rootState, rootGetters) {
        return rootGetters.currentFilters && rootGetters.currentFilters.time;
    },
    filterCustomTime (state, getters, rootState, rootGetters) {
        return rootGetters.currentFilters && rootGetters.currentFilters.customTime;
    },
    filterAge (state, getters, rootState, rootGetters) {
        return rootGetters.currentFilters && rootGetters.currentFilters.age;
    },
};

export default {
    actions, state: {}, mutations: {}, getters
};
