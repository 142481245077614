<template>
  <div id="app" :class="[vendor, subvendor, whitelabelState]">
    <main-layout/>
  </div>
</template>

<script>
import MainLayout from './layouts/Main'
import {mapGetters} from 'vuex'

export default {
  name: 'UpContentAppContainer',
  components: {
    MainLayout
  },
  computed: {
      ...mapGetters({
          vendor: 'vendor',
          subvendor: 'subvendor',
          isDealerVenom: 'isDealerVenom'
      }),
      whitelabelState() {
          return this.vendor && this.vendor.toLowerCase() !== 'upcontent' ? 'whitelabel' : 'no-whitelabel'
      }
  },
  mounted(){
    if (this.isDealerVenom) {
        (function() {
            var l = document.querySelector("link[rel*='icon']") || document.createElement('link');
            l.type = 'image/x-icon';
            l.rel = 'shortcut icon';
            l.href = 'https://bucket.dealervenom.com/assets/dv-brands/V-logo-256.png?auto=compress%2Cformat&ixlib=php-1.2.1&width=250';
            document.getElementsByTagName('head')[0].appendChild(l);
        })();
        document.title = 'Dealer Venom Curation';
    }

    this.$store.dispatch('resetVendor')
  }
}
</script>
