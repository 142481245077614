<script setup> // FIXME: ts issues
import store from '@/store'
import {ref, computed, onMounted, watch} from "vue";
import SidebarMenuLayout from "@/components/Layouts/SidebarMenuLayout.vue";
import SearchableGroupableList from "@/components/Controls/SearchableGroupableList.vue";
import ClassifierEditor from "@/components/Settings/Team/ClassifierEditor.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const currentClassifier = ref(null);
const classifiersList = ref([]);
const isLoadingClassifiersList = ref(false);
const addingNewClassifier = ref(false);

const emits = defineEmits([
    'updatePolicyTab',
]);
const loadClassifiersFromStore = () => store.dispatch('loadAccountClassifiers');

const loadClassifiers = async () => {
    isLoadingClassifiersList.value = true;
    classifiersList.value = await loadClassifiersFromStore();
    isLoadingClassifiersList.value = false;
};

const search = (searchText, item) => {
    return !searchText || item.name.toLowerCase().includes(searchText);
};

const selectClassifierItem = (classifierItem) => {
    currentClassifier.value = classifierItem;
    addingNewClassifier.value = false;
};

const add = () => {
    currentClassifier.value = {
        name: '',
        rule_combination_operation: '',
    }
    addingNewClassifier.value = true;
};

const handleUpdateClassifierList = () => {
    addingNewClassifier.value = false;
    loadClassifiers();
    currentClassifier.value = null;
    emits('updatePolicyTab');
};

onMounted(() => {
    loadClassifiers();
});
</script>

<template>
    <div>
        <h4>
            Classifiers
        </h4>
        <SidebarMenuLayout>
            <template #sidebar>
                <div v-if="isLoadingClassifiersList">
                    <Spinner/>
                </div>
                <SearchableGroupableList
                    v-if="!isLoadingClassifiersList"
                    :items="classifiersList"
                    @select="selectClassifierItem"
                    :search-matches="search"
                    style="max-height: calc(100vh - 250px); overflow-y: scroll"
                >
                    <template v-slot:default="item">
                        <div>{{item.name}}</div>
                    </template>
                </SearchableGroupableList>
                <div>
                    <button class="btn-primary btn full-width" type="button" @click="add">Add Classifier</button>
                </div>
            </template>
            <template #main>
                <div>
                    <ClassifierEditor
                        v-if="currentClassifier"
                        :saved-classifier="currentClassifier"
                        :adding-new-classifier="addingNewClassifier"
                        @updateClassifierList="handleUpdateClassifierList"
                    />
                </div>
            </template>
        </SidebarMenuLayout>
    </div>
</template>

<style scoped>

</style>
