<template>
    <div>
        <label :for="textareaId"><i class="fa fa-comment"></i> Leave a note <span v-if="target">on {{
                target.name
                                                                                                }}</span></label>
        <div class="row">
            <div class="col-sm">
                <textarea @keydown.enter="checkForSave($event)"
                          class="form-control new-note"
                          v-model="note"
                          :id="textareaId"></textarea>
            </div>
            <div class="col-sm-3" v-if="note.length">
                <button @click="save" class="btn btn-sm full-width btn-success">Post note</button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {

    props: ['target', 'noteText'],
    data() {
        return {
            note: '',
            textareaId: this.$strings.generateId('leave-note-for'),
        }
    },
    watch: {
        noteText() {
            this.note = this.noteText;
        }
    },
    mounted() {
        if (this.noteText) {
            this.note = this.noteText
        }
    },
    methods: {
        checkForSave(event) {
            if (event.metaKey || event.shiftKey) {
                this.save()
            }
        },
        save() {
            this.$emit('noteAdded',
                {note: {text: this.note, created: moment.utc().format()}, target: this.target});
            this.note = '';
        }
    }
}
</script>

<style scoped>
h4 {
    font-size: 14px;
    color: #666;
    font-weight: normal;
}

textarea.new-note {
    margin-bottom: 10px;
}
</style>
