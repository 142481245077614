<template>
    <v-select v-model="val" :options="options" :label="label" @input="change">
    </v-select>
</template>

<script>
    import {VueSelect} from 'vue-select'
    export default {
        name: "SelectId",
        components: {vSelect: VueSelect},
        props: {
            options: Array,
            label: String,
            multiple: Boolean,
            value: [String, Boolean],
            getId: {
                type: Function,
                default: () => x => x.id
            }
        },
        computed: {
            selected() {
                return this.multiple ? this.getSelectedOptions() : (this.firstSelectedOption() || false);
            },
        },
        data() {
            return {
                val: this.campaign
            }
        },
        watch: {
            value() { this.val = this.selected; },
            options() { this.val = this.selected; }
        },
        methods: {
            change(val) {
                this.$emit('input', val&&this.getId(val))
            },
            getSelectedOptions() {
                return this.options.filter(x => this.getId(x) === this.value);
            },
            firstSelectedOption() {
                return this.options.find(x => this.getId(x) === this.value);
            }
        }
    }
</script>

<style scoped>

</style>