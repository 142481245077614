<template>
    <div class="term-entry-field">
        <input :placeholder="placeholder" class="form-control" v-model="item" @change="addItem" />
        
        <div class="terms-container" v-if="value.length">
            <span class="term" v-for="term in value" :key="term+'-'+Math.random(100)">
                <i class="fa fa-times-circle remove-term me-1" @click="removeItem(term)"></i>
                {{term}}
            </span>
            <p class="help-text" v-if="item.length">Press <b>Enter</b> to add <i>{{item}}</i></p>            
        </div>

        <p class="help-text" v-if="item.length && ! value.length">Press <b>Enter</b> to add <i>{{item}}</i></p>
        
        
    </div>
</template>

<script>
export default {
    props : ['value', 'placeholder'],
    data(){
        return {
            item : ''
        }
    },
    mounted(){

    },
    methods : {
        addItem(){
            if (this.item){
                var newValue = [...this.value];
                if (newValue.indexOf(this.item) === -1){
                    newValue.push(this.item)
                    this.$emit('input', newValue)
                }
                this.item = '';
            }
        },
        removeItem(item){
            var newValue = [...this.value];
            let index = newValue.indexOf(item);
            newValue.splice(index, 1)
            this.$emit('input', newValue)
        }
    }
}
</script>

<style scoped>

.help-text {
    display: inline-block;
    font-size: 10px;
    margin: 5px 0;
}

.term-entry-field {
    margin-bottom: 1em;
}
.terms-container {
    padding: .5rem 0 0;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.term {
    display: inline-block;
    margin-bottom: 0.4em;
    border-radius: var(--vs-border-radius);
    padding: 0 5px;
    font-size: var(--font-size-13);
    background: #636363;
    color: white;
    margin-right: 10px;
}
.remove-term {
    cursor: pointer;
}
</style>
