
export const trueIfUndef = x => x !== undefined ? x : true;
export const hasValue = x => x !==undefined && x !== null;
export const hasNonWhitepsaceValue = x => Boolean(hasValue(x) && (!(typeof x === 'string') || x.trim()));
export function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

export const arrayHasValues = x => Boolean(hasValue(x) && x.length);

export function sanitizeHtml (str) {
    var temp = document.createElement('div');
    temp.textContent = str;
    return temp.innerHTML;
};