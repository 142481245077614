<template>
    <div>
        <div>
            <div>
                <h4 class="">
                    Quality Checks
                </h4>
                <hr class="mb-4">
                <!--<div class="col col-4 align-right text-end">-->
                    <!--<button class="btn btn-primary">Add</button>-->
                <!--</div>-->
            </div>
            <div v-if="canCreateProofpointQualityChecks">
                <div v-for="c in checks" :key="c.id" >
                    <form @submit.prevent.stop="save(c)">
                        <label class="d-block">
                            Quality Check Name
                            <input v-model="c.name" type="text" class="form-control">
                        </label>
                        <label class="d-block"><input type="checkbox" v-model="c.default"> Default Proofpoint Check </label>
                        <label class="d-block">
                            Proofpoint Config External API ID
                            <input v-model="c.meta.external_ids.success_flag" class="form-control"/>
                            <small class="text-muted">Will use the config set as default for external APIs in Proofpoint if this field is left blank.</small>
                        </label>
                        <table class="table" v-if="c.statuses">
                            <thead>
                            <tr>
                                <th>Style/Icon</th>
                                <!--<th>Key</th>-->
                                <th>Name</th>
                                <th>Severity</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="s in c.statuses.filter(x => x.severity !== 6)" :key="s.id">
                                <td>
                            <span class="tag" :class="s.tag_class">
                                <i :class="s.icon_class"></i>
                            </span>
                                </td>
                                <!--<td>{{s.status_key}}</td>-->
                                <td><input v-model="s.status_label" class="form-control form-control-sm"/></td>
                                <td>{{getSeverity(s.severity)}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <button type="submit" class="btn btn-success" :disabled="networkActivity">
                            <spinner v-if="networkActivity" class="d-inline-block"></spinner>
                            Save
                        </button>
                    </form>
                </div>
            </div>
            <ul v-else class="integration-list">
                <integration-meta-item  :integration="integrationByName('proofpoint')" :team="account"></integration-meta-item>
            </ul>
        </div>
    </div>
</template>

<style>

    .em1 {
        font-size: inherit !important;
    }
    .integration-list {
        list-style-type: none;
        padding-left: 0;
    }
    .integration-list .btn {
        margin-left: 10px;
    }

</style>

<script>
    import HasGlobalQualityCheckList from '@/components/QualityChecks/HasGlobalQualityCheckList'
    import Spinner from "@/components/Controls/Spinner";
    import * as toastr from "toastr";
    import ConnectDisconnectButton from "@/components/Settings/Team/ConnectDisconnectButton";
    import IntegrationMetaItem from "@/components/Settings/Team/IntegrationMetaItem";
    import IntegrationLoader from '@/components/DataManagement/IntegrationLoader'
    import {mapGetters} from 'vuex'

    const Severity = {
        PASS : {key: 0, label: 'PASS'},
        FLAG : {key: 1, label: 'FLAG'},
        FAIL : {key: 2, label: 'FAIL'},
        INCONCLUSIVE :{key:  5, label: 'INCONCLUSIVE'},
        ERROR : {key: 6, label: 'ERROR'},
    };
    Severity.list =  [
        Severity.PASS,
        Severity.FLAG,
        Severity.FAIL,
        Severity.INCONCLUSIVE,
        Severity.ERROR
    ];
    Severity.lookup =  Severity.list.reduce((all, severity) => ({...all, [severity.key]: severity.label}), {});

    export default {
        components: {IntegrationMetaItem, ConnectDisconnectButton, Spinner},
        mixins: [HasGlobalQualityCheckList, IntegrationLoader],
        props: { account: Object },
        async mounted() {
            console.log(Severity);
            console.log(this);
            this.load();
        },
        computed: {
            ...mapGetters({
            }),
            promptProofpointAuthentication() {
                return !this.authenticatedProofpoint && this.featuresForAccount.proofpoint && this.featuresForAccount.qualityChecks
            },
            promptSales() {
                return !this.featuresForAccount.proofpoint || !this.featuresForAccount.qualityChecks
            }

        },
        data() {
            return {
                checks: [],
                networkActivity: false
            }
        },
        watch: {
            account() {
                this.load()
            }
        },
        methods: {
            async load() {
                this.reloadIntegrationData();
                this.checks = this.$sort.alphabetize(await this.fetchChecksAndInitializeDoNotExist(), 'created').map(c => ({...c, statuses: this.$sort.alphabetize(c.statuses, 'severity')}))
            },
            getSeverity(key) {
                console.log(Severity)
                return Severity.lookup[key]|| '';
            },

            integrationByName(name) {
                return this.metaIntegrations[name]
            },
            async save(check) {
                this.networkActivity = 'Saving...';
                let error = check.statuses.find(x => x.severity === Severity.ERROR.key);
                let inconclusive = check.statuses.find(x => x.severity === Severity.INCONCLUSIVE.key);
                if (error && inconclusive) {
                    error.status_label = inconclusive.status_label;
                }
                await this.$api.put(`/quality-checks/${check.id}`, check);
                await this.$api.put(`/quality-checks/${check.id}/statuses`, check)
                this.networkActivity = false;
                toastr.success("Saved quality check successfully!")
            }
        }
    }
</script>
