<template>
    <button
        type="button"
        class="list-group-item bg-water-100 list-group-item-action"
        @click="$emit('change')">
        <i :class="iconClass" class="me-2"/>{{title}}
        <div class="font-size-11 text-muted">{{description}}</div>
    </button>
</template>

<script>
import {getIconClass} from "../../../services/Icons";
import {mapGetters} from "vuex";

export default {
    name: "PowerupListItem",
    props: {
        title: String,
        name: String,
    },
    computed: {
        ...mapGetters({
            powerupDescriptions: 'powerupDescriptions',
            learnUrls: "learnUrls",
            powerupNameMap: 'powerupNameMap'
        }),
        iconClass() {
            return getIconClass(this.name)
        },
        learnUrl() { return this.learnUrls[this.powerupNameMap[this.name]] },
        description() { return this.powerupDescriptions[this.powerupNameMap[this.name]] }
    }
}
</script>

<style scoped>

</style>
