<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         xml:space="preserve"
         style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2"
         viewBox="0 0 250 250">
        <g class="spin-circle-group">
            <path d="M375 125c0-118-102-250-250-250v250h250Z" class="spin-circle-1"/>
            <path d="M125 375c118 0 250-102 250-250H125v250Z" class="spin-circle-2"/>
            <path d="M-125 125c0 118 102 250 250 250V125h-250Z" class="spin-circle-3"/>
            <path d="M125-125a257 257 0 0 0-250 250h250v-250Z" class="spin-circle-4"/>
        </g>
</svg>
</template>

<script>
export default {
    name: "spinCircle"
}
</script>

<style lang="scss" scoped>

</style>
