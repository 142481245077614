<template>
    <span class="d-inline-block">
        <span style="padding-right: inherit;">
            <i :class="icon"/>
            {{name}}</span><span style="border-color: inherit; border-left: 1px solid; padding-left: inherit; font-size: smaller; vertical-align: text-bottom">{{typeDisplay}}
        </span>
    </span>
</template>

<script>
import {mapGetters} from "vuex";
import {getEntityIcon, getIconClass} from "../../services/Icons";

export default {
    name: "EntityBadge",
    props: {
        type:{},
        name: {},
        id: {}
    },
    computed: {
        ...mapGetters({
            collections: 'collections'
        }),
        icon() {
            return getEntityIcon(this.type, this.id, this.$store)
        },
        typeDisplay() {
            if (!this.type) return ''
            return this.$strings.capitalizeWords(this.type.split('_').join(' '))
        }
    }
}
</script>

<style scoped>

</style>