<template>
    <div class="gal-card" role="presentation" :style="cardStyle">
        <div class="card-container" :style="cardContainerStyle">
            <div class="img-container">
                <div class="image" @click="elementSelected('image', $event)" :style="imageStyle">
                    <div class="img-text">Image</div>
                </div>
            </div>
            <div class="content" :style="contentStyle">
                <h3 class="headline"  @click="elementSelected('headline', $event)" :style="headlineStyle">
                    Headline. {{index}} Lorem ipsum dolor sit amet.
                    <span class="make-longer">Consectetur adipiscing elit.</span>
                </h3>
                <div class="spacer"></div>
                <div class="meta">
                    <div class="date" :style="dateStyle" @click="elementSelected('date', $event)">Jun 17, 2018</div>
                    <div class="source" :style="sourceStyle" @click="elementSelected('source', $event)">Publisher</div>
                </div>
                <p class="summary" @click="elementSelected('summary', $event)" :style="summaryStyle">
                    Summary. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
            </div>
        </div>
    </div>
</template>

<style>
    .make-longer { display: none; }
    .card:first-child .make-longer {
        display: inline;
    }
</style>

<style scoped>
    .gal-card {
        /*border: 1px solid black;*/
    }
    .img-text {
        position: absolute;
        top: 30%; left: 0; bottom: 0; right: 0;
        padding: 30px auto;
        text-align: center;
    }
    .img-container {
        width: 100%;
    }
    .image {
        width: 100%;
        padding-bottom: 75%;
        background: gold;
        font-size: 30px;
        display: block;
        background: gray;
        color: lightgray;
        text-align: center;
        position: relative;
    }
    .content {
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /*width: 100%;*/
        overflow: visible;
    }
    .meta {
        display: block;
        display: grid;
        /*width: 100%;*/
        grid-template-columns: 50% 50%;
    }
    /*.spacer {*/
        /*flex-grow: 1;*/
    /*}*/
    .headline, .summary  { text-overflow: ellipsis;}
    .headline {
        border-bottom: none;
        font-size: 1.2em;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
   .summary {
       font-size: .8em;
       display: block;
       /*width: 100%;*/
       flex-grow: 1;
       overflow: hidden;
       text-overflow: ellipsis;
    }
    .date, .source {
        font-size: .75em;
        grid-row: 1;
    }
    .date {
        grid-column: 1;
    }
    .source {
        grid-column: 2;
        text-align: right;
    }
    /*.source { float: right; }*/
    .headline:hover, .summary:hover, .date:hover, .source:hover, .image:hover, .selected {
        /*border: 1px solid mediumpurple;*/
        box-shadow: mediumpurple 0 0 10px;
    }
</style>

<script>
    export default {
        props: ['index', 'elementStyles'],
        computed: {
            headlineStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='headline')||{}).styles },
            summaryStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='summary')||{}).styles},
            dateStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='date')||{}).styles},
            sourceStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='source')||{}).styles},
            imageStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='image')||{}).styles},
            cardStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='cardStyle')||{}).styles},
            contentStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='content')||{}).styles},
            cardContainerStyle(){return (this.elementStyles&&this.elementStyles.find(c => c.className ==='cardContainerStyle')||{}).styles},
        },
        methods: {
            elementSelected(el) {
                this.$emit('selectElement', el);
                Array.from(document.querySelectorAll('.gal-card .selected'))
                    .forEach(e => e.classList.toggle('selected', false));

                Array.from(document.querySelectorAll('.'+el))
                    .forEach(e => e.classList.toggle('selected', true));
            }

        },
    }
</script>
