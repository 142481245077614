<template>

</template>

<style>

</style>

<script>
    export default {
        methods: {
            messageParent(event, data, targetOrigin="*") {
                if (window.parent) {
                    window.parent.postMessage({
                        event, data
                    }, targetOrigin)
                }
            }
        }
    }
</script>
