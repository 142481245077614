<template>
    <div class="">
        <div class="row">
            <label class="col-12 col-sm-4 align-self-end top-3">Time</label>
            <div class="col-12 col-sm-8">
                <select @change="update()" v-model="time" class="form-control form-select form-control-sm full-width">
                    <option v-for="thisType in availableTimeTypes"
                            :value="thisType == 'Other' ? 'custom' : thisType"
                            :key="thisType">
                        {{ thisType }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row" v-show="time=='custom'">
            <div class="offset-4 col-8">
                <input
                    class="form-control-sm form-control custom-time-input"
                    @change="update()"
                    v-model="customTime"
                    @keyup="checkCustomTime"
                    placeholder=" e.g. 1 year ago, 4 months ago "
                />
            </div>
        </div>
        <div class="row">
            <div class="col offset-4">
            <label @click.stop>
                <input type="checkbox" v-model="evergreen" @change="update"/>
                <span class="font-size-12 ignore-inherit">&nbsp;Include undated results.</span>
                <span class="text-success" v-if="evergreen">
                        <i class="ms-1 fa fa-tree"></i>
                </span>
            </label>
            </div>
        </div>

    </div>
</template>

<script>


import Sugar from 'sugar'
import moment from 'moment'

import Base from './FilterMixin'

export default {
    mixins: [Base],
    props: {type: String},
    computed: {
        isCollection() {
            return this.type === 'collection';
        },
        availableTimeTypes() {
            const types = [
                'Day',
                'Week',
                'Month',
                '2 Months',
                'Other'
            ];

            if (this.isCollection) {
                return ['Forever', ...types]
            }

            return types;
        },
        age() {

            var hours = 0;

            if (this.customTime) {
                var customTime = this.customTime
                if (!this.customTime.includes('ago')) customTime += ' ago';
                let date = Sugar.Date.create(customTime)
                let now = moment();
                hours = now.diff(date, 'hours')
            }

            if (this.time == 'Day') {
                hours = 24;
            } else if (this.time == 'Week') {
                hours = 168
            } else if (this.time == 'Month') {
                hours = 730
            } else if (this.time == '2 Months') {
                hours = 730 * 2 - 20 //for some reason if we don't subtract some time we get 61 hours
            } else if (this.time == 'Forever') {
                hours = -24;
            }

            if (this.useDays) {
                hours = Math.round(hours / 24)
            }

            return hours;
        }
    },
    watch: {
        type() {
            this.setContextAppropriateAge()
        },
        customTime(changed) {
            if (changed) {
                this.time = 'custom'
                this.update(true);
            }
        },
        time(changed) {
            if (changed != 'custom') {
                this.customTime = '';
                this.update(true);
            }
        },
        filters() {
            if (this.filters) {
                this.time = this.filters.time
                this.customTime = this.filters.customTime
                this.evergreen = this.filters.evergreen
            }
        }
    },
    data() {
        return {
            tempTime: '',
            time: this.type === 'topic' ? '2 Months' : 'Forever',
            customTime: '',
            useDays: true,
            evergreen: false
        }
    },
    mounted() {
        this.time = this.filters.time
        if (this.time !== 'custom') {
            this.customTime = this.filters.customTime
        } else {
            this.customTime = '';
        }

        this.evergreen = this.filters.evergreen

        this.setContextAppropriateAge();
    },
    methods: {
        checkCustomTime() {
            if (this.customTime == '') this.time = 'Day'
        },
        setContextAppropriateAge() {
            if (this.type == 'topics' && this.time == 'Forever') {
                this.time = '2 Months'
                this.update();
            }
            if (this.type == 'collections') {
                this.time = 'Forever';
                this.update();
            }
        },
        update(dontValidate) {
            if (isNaN(this.age) && dontValidate !== true) {
                alert('The time frame you entered is not supported');
                return;
            }

            var upperLimit = 8760;
            if (this.useDays) {
                upperLimit = Math.round(upperLimit / 24)
            }

            if (this.age > upperLimit) {
                alert("You cannot search further than 1 year ago");
                return;
            }
            this.$emit('input', {
                ...this.filters,
                evergreen: this.evergreen,
                age: this.age,
                customTime: this.customTime,
                time: this.time
            });
        }
    }
}
</script>

<style scoped>
.custom-time-input::placeholder {
    position:relative;
    bottom:-2px;
    display:block;
    font-size: var(--font-size-12)!important;
    color:#ccc!important;}
</style>
