<template>
    <div class="d-flex justify-content-between flex-wrap" v-if="searchTerms">
        <button v-for="x in searchTerms" type="button" class="btn btn-link font-size-11 p-0 me-2" @click="$emit('search', x.search)" :key="x.search">
            {{ x.display }}
        </button>
    </div>
</template>

<script>
export default {
    name: "SearchTerms",
    props: {
        searchTerms: {type: Array, default: null},
    },

}
</script>

<style scoped>

</style>
