
<script>
    import moment from 'moment';
    const descriptions = {
    };
    const deprecated = ['users', 'galleries', 'reviewers', 'queries']
    export default {
        methods: {
            async fetchUsageFromServer(account) {
                console.log('loading')
                let resp = await this.$rest.accounts(account.id).subscription.usage.get();

                let resources = resp.data.resources
                    .filter(r => !deprecated.includes(r.name))
                    .map(r => ({
                        ...r,
                        name: r.name,
                        used: r.used,
                        limit: r.limit,
                        remaining: r.remaining,
                        perPeriod: r.per_period,
                        description: this.description(r)
                    }));
                let currentPeriodEnd = moment.unix(resp.data.current_period_end).utc().local();
                let currentPeriodStart = moment.unix(resp.data.current_period_start).utc().local()

                let periodDays = currentPeriodEnd.diff(currentPeriodStart, 'days');
                let period = periodDays > 32 ? "Year" : "Month";

                let periodStartDisplay = currentPeriodStart.format('L');
                let periodEndDisplay = currentPeriodEnd.format('L');
                return {
                    currentPeriodEnd, currentPeriodStart, period, periodEndDisplay, periodStartDisplay, resources
                }
            },
            description(resource) {
                let description = resource.meta && resource.meta.plural;
                let name  = resource.name.replace('-', ' ').replace('_', ' ');
                return descriptions[resource.name] || this.$strings.capitalizeWords(description || name)
            }
        }
    }
</script>
