
<script>
export default {
    // requires:
    methods: {
        getPublishers({usGlobal, accountId, filterViewId, hasContentId, hasContentType}, filters) {
            return this.$api.get(
                this.getPublishersPath({usGlobal, accountId, filterViewId, hasContentId, hasContentType}),
                filters
            ).then(resp => resp.data)
        },
        getPublishersPath({usGlobal, accountId, filterViewId, hasContentId, hasContentType}) {
            if (this.account && !accountId) accountId = this.account.id
            if (this.hasContentType && !hasContentType) hasContentType = this.hasContentType
            if (this.hasContentId && !hasContentId) hasContentId = this.hasContentId
            if (this.filterViewId && !filterViewId) filterViewId = this.filterViewId

            if (usGlobal && accountId) {
                return `/accounts/${accountId}/publishers`
            } else if (hasContentId && hasContentType && hasContentType !== 'account') { // TODO: https://upcontent.atlassian.net/browse/DEV-1029
                return `/${hasContentType}s/${hasContentId}/publishers`
            }  else if (filterViewId) {
                return `/filter-views/${filterViewId}/publishers`
            }else if (accountId) {
                return `/accounts/${accountId}/publishers`
            } else {
                return null
            }
        }
    }
}
</script>

<style scoped>

</style>
