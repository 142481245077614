<template>
    <div class="plan-container p-3 row-margin-2 d-flex flex-column justify-content-between"
         :class="{'current' : isSelected}">

        <div class="row-margin-8">
            <div class="d-flex justify-content-center align-items-center col-margin-4">

                <img alt=""
                     aria-hidden="true"
                     v-if="plan.icon && plan.icon.includes('/')"
                     :src="plan.icon"
                     class="plan-icon"/>

                <h4 class="m-0 p-0 text-blue-50 font-size-13">{{ plan.name }}</h4>

            </div>

            <div>
                <div class="cost d-flex align-items-start justify-content-center text-steel-25 fw-normal pt-2">
                    <span class="font-size-13 me-1 top-5">$</span>
                    <span class="font-size-30 bottom-2" aria-label="">{{ planDollarAmount }}</span>
                    <span class="font-size-13 me-1 top-5 text-steel-50">
                    <span v-if="planCentAmount !== ''">{{ planCentAmount }}</span>
                    <span class="ms-1">/month</span>
                </span>
                </div>

                <div class="font-size-13 font-italic text-steel-50 text-center fw-light">
                    <template v-if="newPlanFrequency === '12m'">${{ planFullCost }} billed annually</template>
                    <template v-else>&nbsp;</template>
                </div>
            </div>

            <div class="plan-description text-steel-33 font-size-14 fw-light text-start">
                {{ plan.description }}
            </div>

            <div class="feature-list d-flex justify-content-around align-items-start d-lg-block mt-4 mt-lg-0">

                <div>
                    <ul class="primary-feature-list font-size-13 text-steel-25 fw-light text-start">
                        <li><strong>{{ plan.userCount }}</strong> {{
                                $strings.plural('user', plan.userCount)
                                                                  }}
                        </li>
                        <li><strong>{{ plan.topicCount }}</strong> {{
                                $strings.plural('topic', plan.topicCount)
                                                                   }}
                        </li>
                        <li><strong>{{ plan.collectionCount }}</strong> {{
                                $strings.plural('collection', plan.collectionCount)
                                                                        }}
                        </li>
                    </ul>
                </div>

                <div>
                    <ul class="secondary-feature-list font-size-12 text-steel-33 fw-light text-start">
                        <li v-if="!sharer">RSS Feeds</li>
                        <li v-if="!sharer">All Integrations</li>
                        <li v-if="sharer">Select Integrations</li>
                        <li v-if="!sharer">Website Gallery</li>
                        <li v-if="level >= levels.Engager">Custom Call-to-Actions</li>
                        <li v-if="level >= levels.Engager">Custom Article Imagery</li>
                    </ul>
                </div>

            </div>

        </div>

        <div>
            <div class="d-flex justify-content-center">
                <button v-if="!isSelected" class="btn btn-primary w-100"
                        @click.prevent="selectSignUpPlan">
                    <span class="btn-content">Select</span>
                    <!-- <template v-if="newPlanFrequency === '12m'">Annual</template>-->
                    <!-- <template v-else>Monthly</template>-->
                </button>
                <button v-if="isSelected"
                        disabled="disabled"
                        class="btn background-water-0 border-radius text-water-100 text-center w-100">
                    Current Plan
                </button>
            </div>
        </div>

    </div>
</template>

<script>

import PlanGroups from '@/utilities/PlanList'
import {mapActions, mapGetters} from "vuex";
import {formatCents, getDecimalAmount, getDollarAmount} from "@/services/money";

let levels = ['Sharer', 'Curator', 'Engager', 'Orchestrator', 'Enterprise'].reduce(
    (levels, name, index) => ({...levels, [name]: index}), {});

export default {
    name: 'SubscriptionPlan',
    props: ['planName', 'newPlanFrequency'],
    data() {
        return {
            levels,
        }
    },
    computed: {
        ...mapGetters({
            selectedPlanName: 'planName',
            selectedPlanFrequency: 'planFrequency',
            signupPlanPriceFormatted: 'signupPlanPriceFormatted',
        }),
        planDuration() {
            return this.plan && this.plan.durations.find(x => x.id.includes(this.newPlanFrequency))
        },
        isSelected() {
            return this.planName === this.selectedPlanName
                && this.newPlanFrequency === this.selectedPlanFrequency;
        },
        plan() {
            return PlanGroups.find(p => p.name === this.planName)
        },
        cost() {
            return this.planDuration && this.planDuration.cost;
        },
        planCost() {
            if (!this.cost) {
                return 0;
            }
            const result = this.newPlanFrequency === '12m' ? this.cost / 12 : this.cost;

            // Convert to decimal amount.
            return result / 100;
        },
        planFullCost() {
            if (!this.cost) {
                return '';
            }
            return formatCents(this.cost);
        },
        planDollarAmount() {
            return this.planCost && this.planCost > 0 ? getDollarAmount(this.planCost) : '';
        },
        planCentAmount() {
            return this.planCost && this.planCost > 0 ? getDecimalAmount(this.planCost) : '';
        },
        sharer() {
            return this.planName === 'Sharer'
        },
        curator() {
            return this.planName === 'Curator'
        },
        level() {
            return levels[this.planName];
        },
        enterprise() {
            return this.planName === 'Enterprise'
        }
    },
    methods: {
        ...mapActions({
            setSignUpPlan: 'setSignUpPlan',
            setSignUpFrequency: 'setSignUpFrequency',
        }),
        selectSignUpPlan() {
            this.setSignUpPlan({planName: this.planName});
            this.$emit("saved");
            setTimeout(() => window.Events.$emit('close_pricingPlanModal'), 500);
        }

    }
}
</script>

<style lang="scss" scoped>


.plan-container {
    border: 1px solid var(--steel-100);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 6px 3px rgba(0, 0, 0, 0.1);
}

//
//@media screen and (min-width: 964px) {
//    .plan-container {
//        width: 190px;
//    }
//}
//
//@media screen and (max-width: 767px) {
//    .plan-container {
//        width: 100%;
//    }
//}

.plan-container.current {
    background: var(--water-100);
    border-color: var(--water-25);
    box-shadow: 0 2px 3px 0 var(--water-33),
    0 2px 6px 3px var(--water-50),
    0 0 0 1px var(--primary-100) inset;
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .plan-description {
        min-height: 6.25em;
    }
}


.plan-container h4 {
    text-transform: uppercase;
}


.plan-container .cost {
    font-weight: var(--font-weight-semibold);
}

.plan-icon {
    width: 1.5em;
    height: auto;
}

.primary-feature-list, .secondary-feature-list {
    padding: 0;
    list-style: none;
}

.secondary-feature-list li::before {
    content: "+";
    margin-right: .25em;
    font-weight: var(--font-weight-bold);
}


/*.btn {*/
/*    padding: 5px 10px;*/
/*    font-size: var(--font-size-13);*/
/*    text-transform: uppercase;*/
/*}*/

/*.btn.btn-secondary {*/
/*    background: white;*/
/*    color: inherit;*/
/*}*/
</style>
