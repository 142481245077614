<script setup>
import {fieldOptions, operationOptions} from "@/constants/ContentPolicies/Constants";
import UcSelect from "@/components/Controls/UpcontentSelect.vue";
import FormGroupCard from "@/components/Controls/FormGroupCard.vue";
import {ref, defineEmits, computed} from "vue";
const emits = defineEmits([
    'addRule',
]);
let id = 0;
const newRule = ref({
    localId: `new:${id++}`,
    field: "all_text",
    operation: "contains",
    ruleValue: '',
});
const valueInput = ref(null);

function useClassifierFieldOptions(rule) {
    const ruleOp = computed(() => operationOptions.find(op => op.value === rule.value.operation));
    const correctFieldOptions = computed(() => {
        if (!ruleOp.value)
            return fieldOptions;
        return fieldOptions.filter(x => ruleOp.value.types.includes(x.type));
    });
    return {correctFieldOptions};
}

function useClassifierOperationOptions(rule) {
    const ruleField = computed(() => fieldOptions.find(op => op.value === rule.value.field));
    const correctOperationOptions = computed(() => {
        if (!ruleField.value)
            return operationOptions;
        return operationOptions.filter(x => x.types.includes(ruleField.value.type));
    });
    return {correctOperationOptions};
}

const {correctFieldOptions} = useClassifierFieldOptions(newRule);
const {correctOperationOptions} = useClassifierOperationOptions(newRule);

const addRule = () => {
    console.log('* add to rules list', newRule.value)
    emits('addRule', newRule.value);
    // reset row for next new rule
    newRule.value = {
        localId: `new:${id++}`,
        field: newRule.value.field,
        operation: newRule.value.operation,
        ruleValue: '',
    };
    valueInput.value.focus()
};
</script>

<template>
    <form @submit.prevent="addRule" style="min-width: 250px;" class="flex-grow new-rule-form">
        <FormGroupCard title="New Rule">
            <div  class="new-rule-section">
                <label class="label-calm new-rule-field">
                    Field
                    <UcSelect
                        required
                        :items="correctFieldOptions"
                        :placeholder="'Select a field...'"
                        v-model="newRule.field"
                        class="d-block"
                    >
                    </UcSelect>
                </label>
                <label class="label-calm new-rule-operation">
                    Operation
                    <UcSelect
                        required
                        :items="correctOperationOptions"
                        :placeholder="'Select an operation...'"
                        v-model="newRule.operation"
                        class="d-block"
                    >
                    </UcSelect>
                </label>
                <label class="label-calm  new-rule-value">
                    Value
                    <input
                        :disabled="newRule.operation === 'has_value'"
                        required
                        ref="valueInput"
                        type="text"
                        v-model="newRule.ruleValue"
                        placeholder="Enter value"
                        class="form-control form-control-sm d-block"
                    />
                </label>
                <div class="new-rule-btn-container">
                    <button type="submit" class="btn btn-primary full-width mt-4">Add rule</button>
                </div>
            </div>
        </FormGroupCard>
    </form>
</template>


<style lang="scss" scoped>
.new-rule-form {
    container-type: inline-size;
}
@container (min-width: 400px) {
    .new-rule-field, .new-rule-operation {
        //width: calc(50% - 10px)
        flex-grow: 1;
    }
    .new-rule-field {
        margin-right: 10px;
    }
    .new-rule-section {
        display: flex;
        flex-wrap: wrap;
        align-items: end;
    }
    .new-rule-value, .new-rule-btn-container {
        min-width: 100%;
        flex-grow: 10;
    }
    .new-rule-section .label-calm {
        margin-bottom: 0;
    }
}
@container (min-width: 500px) {
    .new-rule-value, .new-rule-btn-container {
        min-width: unset;
    }
    .new-rule-value {
        min-width: unset;
        flex-grow: 3;
        margin-right: 10px;
    }
    .new-rule-operation {
        margin-right: 10px;
    }
    .new-rule-btn-container {
        min-width: unset;
        flex-grow: 0;
    }
    .new-rule-btn-container>.btn {
        margin-top: 0 !important;
    }
}
</style>
